export const UserProfileContextReducer = (state, action) => {
    switch(action.type) {
        case 'INIT_USER': 
            return action.payload
        case 'SET_USER_PROFILE':
            // console.log('action.payload.user.user_information.user_subscribers', action.payload.user.user_information.user_subscribers);
            // console.log('action.payload.user.user_information.user_followers', action.payload.user.user_information.user_followers);
            return {
                ...state,
                profile_user_id: action.payload.user.user_id,
                username: action.payload.user.username,
                userType: action.payload.user.userType,
                handleName: action.payload.user.handlename,
                devices: action.payload.user.devices,
                isFollow: action.payload.user.user_information.isFollow,
                isSubscribe: action.payload.user.user_information.isSubscribe,
                user_information_id: action.payload.user.user_information._id,
                user_description: action.payload.user.user_information.user_description,
                user_fname: action.payload.user.user_information.user_fname,
                user_followers: action.payload.user.user_information.user_followers,
                user_following: action.payload.user.user_information.user_following,
                user_house_link: action.payload.user.user_information.user_house_link,
                user_house_name: action.payload.user.user_information.user_house_name,   
                user_image_profile: action.payload.user.user_information.user_image_profile,
                user_link_profile: action.payload.user.user_information.user_link_profile,
                user_lname: action.payload.user.user_information.user_lname,
                user_mname: action.payload.user.user_information.user_mname,
                user_moods: action.payload.user.user_information.user_moods,
                user_socials: [], //action.payload.user_information.user_socials,
                user_subscribers: action.payload.user.user_information.user_subscribers,
                user_subscriptions: action.payload.user.user_information.user_subscriptions,
                user_suffix: action.payload.user.user_information.user_suffix
            }
        case 'RESET_USER': 
            return {
                ...state
            }    
        case 'SET_USER_VIDEO': 
            return {
                ...state,
                user_vidoes: action.payload.user_vidoes
            }
        default:
            return state    
    }
}