import axios from 'axios';
import { VersionInitialState } from "../constants/VersionInitialState";
const versionApiNumber = VersionInitialState.versionApiNumber;

export async function UpdateUserInformationService(objData,userId,authToken) {
    const data = await axios.post(
        `/api/${versionApiNumber}/users/userInformation/${userId}`, 
        objData,
        {
            headers: {
                'Authorization': `Bearer ${authToken}` 
            }
        }
    );
    return data;
}

export async function UpdateUserInformationMoodsService(objData,userId,authToken) {
    const data = await axios.post(
        `/api/${versionApiNumber}/users/userInformation/moods/${userId}`, 
        objData,
        {
            headers: {
                'Authorization': `Bearer ${authToken}` 
            }
        }
    );
    return data;
}

export async function UpdateUserInformationSocialsService(objData,userId,authToken) {
    const data = await axios.post(
        `/api/${versionApiNumber}/users/userInformation/socials/${userId}`, 
        objData,
        {
            headers: {
                'Authorization': `Bearer ${authToken}` 
            }
        }
    );
    return data;
}