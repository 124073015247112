import React, {useState, useContext, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';

import { SearchContext } from '../contexts/SearchContext'; 

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: "100%",
    height: "40px",
    backgroundColor: "rgba(241,241,241,1)"
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  searchIconButton: {
    margin: "10px",
    padding: "10px",
    height: "20px",
    width: "20px",
    borderRadius: "20px"
  },
  searchIcon: {
    color: "rgba(41,41,41,1)",
    "&:hover": {
      backgroundColor: "unset"
    }
  },
  clearIconButton: {
    margin: "10px",
    padding: "10px",
    height: "20px",
    width: "20px",
    borderRadius: "20px",
    backgroundColor: "rgba(204,204,204,1)"
  },
  clearIcon: {
    color: "rgba(241,241,241,1)",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "unset"
    }
  }
}));

export default function SearchTagInput(props) {
  const classes = useStyles();
  const { SearchContextState, SearchContextDispatch } = useContext(SearchContext);
  const { itemPeoplePerPage, itemHashTagVideoPerPage, itemVideoPerPage, itemLocationPerPage, searchAllVideosStr="", searchAll=false, searchAllVideos=false } = SearchContextState;
  const [ search, setSearch ] = useState(searchAllVideosStr)
  const { label="Search", searchType=null, action=() => {} } = props;

  const handleSearch = (e) => {
    // console.log("handleSearch", e.target.value, e.target.value.length, searchType)
    setSearch(e.target.value);
    SearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading : false} });
    SearchContextDispatch({ type: "RESET_RESULT_LOADED", payload: { isLoading : false} });
    SearchContextDispatch({ type: "RESET_LOADNEXT" });
    if (e.target.value.length !== 0) {
      switch(searchType) {
        case 'people':
          SearchContextDispatch({ type: "SET_SEARCH_PEOPLE", payload: { people: e.target.value, itemPerPage: 10, usernameOnly: true } });
          break;
        case 'video':
          SearchContextDispatch({ type: "SET_SEARCH_VIDEO", payload: { video: e.target.value, itemPerPage: 10, hashOnly: false } });
          break;
        case 'location':
          SearchContextDispatch({ type: "SET_SEARCH_LOCATION", payload: { location: e.target.value, itemPerPage: 20 } });
          break;
        default:
      }
    } else {  
      if (e.target.value.length === 0) {
        switch(searchType) {
          case 'people':
            SearchContextDispatch({ type: "RESET_SEARCH_PEOPLE" });
            break;
          case 'video':
            SearchContextDispatch({ type: "RESET_SEARCH_VIDEO" });
            break;
          case 'location':
            SearchContextDispatch({ type: "RESET_SEARCH_LOCATION" });
            break;
          default:
        }
      }
    } 
  }

  const handleClear = (e) => {    
    // console.log("handleClear",e.target.value)
    e.preventDefault();
    setSearch("");
    SearchContextDispatch({ type: "RESET_SEARCH_INFO" });
    SearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading : false } });
    SearchContextDispatch({ type: "RESET_LOADNEXT" });
  }

  useEffect(() => {
    SearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading : false} });
    SearchContextDispatch({ type: "RESET_RESULT_LOADED", payload: { isLoading : false} });
    SearchContextDispatch({ type: "RESET_LOADNEXT" });
  }, [])

  const handleEnter = (e) => {
    // console.log("handleEnter", e, e.keyCode);    
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  }

  return (
    <Paper component="form" className={classes.root} elevation={0} onKeyDown={(e) => handleEnter(e)}>
      <IconButton className={classes.searchIconButton} aria-label="search">
        <SearchIcon classes={{ root: classes.searchIcon }} />
      </IconButton>
      <InputBase
        className={classes.input}
        placeholder={label}
        inputProps={{ 'aria-label': label }}
        value={search}
        onChange={(e) => handleSearch(e)}
      />
      {
        search !== ""
        ?
          <IconButton type="button" className={classes.clearIconButton} aria-label="clear" onClick={(e) => handleClear(e)}> 
            <ClearRoundedIcon classes={{ root: classes.clearIcon }} />
          </IconButton>
        :
          null
      }      
    </Paper>
  );
}
