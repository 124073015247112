import React, { useContext, useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";

import { Container, Grid } from '@material-ui/core';
import { makeStyles, withStyles  } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import Box from '@material-ui/core/Box';

import { containerStyleWhite, formGridStyle3, formGridButtonSubmit, headerDefaultStyle, inputReadOnly, bodyDefaultStyle, bodyDefaultStyle3, bodyDefaultStyle5 } from '../../styles/defaultStyles.2';
import { ErrorTextStyle, ErrorTextContainerStyle } from '../../styles/defaultErrorStyles';
import { inputUnderline, arrowIcon, arrowActiveIcon } from '../../styles/defaultInput';
import { gridItemHeaderWrapper } from '../../styles/defaultGridStyles';

import HelmetComponent from '../../components/HelmetComponent';
import SubmitButton from '../../components/SubmitButton.2';
import EditButton from '../../components/EditButton.2';
import CloseButton from '../../components/CloseButton.2';
import { FramerContext } from '../../contexts/FramerContext';
import { FormErrorContext } from '../../contexts/FormErrorContext';
import { AuthContext } from '../../contexts/AuthContext';
import { GlobalContext } from '../../contexts/GlobalContext';
import { useSnackbar } from 'notistack';

import { UpdateUserMonetizationInformationService } from '../../services/UserMonetizationInformationServices';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  containerStyleWhite: containerStyleWhite, 
  formGridStyle3: formGridStyle3, 
  formGridButtonSubmit: formGridButtonSubmit, 
  headerDefaultStyle: headerDefaultStyle, 
  inputReadOnly: inputReadOnly, 
  bodyDefaultStyle: bodyDefaultStyle,
  bodyDefaultStyle3: bodyDefaultStyle3,
  bodyDefaultStyle5: bodyDefaultStyle5,
  ErrorTextStyle: ErrorTextStyle, 
  ErrorTextContainerStyle: ErrorTextContainerStyle,
  inputUnderline: inputUnderline,
  gridItemHeaderWrapper: gridItemHeaderWrapper,
  arrowIcon: arrowIcon,
  arrowActiveIcon: arrowActiveIcon,
  borderTop: {
    margin: '20px 0 0',
    borderTop: '1px solid rgba(0, 0, 0, 0.2)'
  }
}));

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 50,
    height: 25,
    padding: 0,
    display: 'flex',
    float: 'right',
    top: '-3px',
    position: 'relative'
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(25px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 21,
    height: 21,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 25 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

export default function AccountMonetizationInformation() {
  const classes = useStyles();
  let history = useHistory();
  const { GlobalContextState, GlobalContextDispatch } = useContext(GlobalContext);
  const { isPwaMobile } = GlobalContextState;
  const { AuthContextState } = useContext(AuthContext);
  const { authToken, userId, registerFlow, upgradeFlow } = AuthContextState;   
  const { FormErrorContextState, FormErrorContextDispatch } = useContext(FormErrorContext);  
  const { requestError } = FormErrorContextState;
  const { toggleDirection } = useContext(FramerContext);
  const { enqueueSnackbar } = useSnackbar();
  
  const [isEdit, setIsEdit] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [price, setPrice] = useState('');

  const [state, setState] = React.useState({
    activateSubscription: false,
    chatOnline: false,
    appointmentOnly: false,
  });

  useEffect(() => {    
    // if (!isSubmitted) {
      
    // } 

    if (isSubmitted) {
      const data = {
        activateSubscription: state.activateSubscription,  
        montlySubscriptionPrice: price,
        activateOnlineChat: state.chatOnline,
        activateAppointmentOnly: state.appointmentOnly,
      } 
      UpdateUserMonetizationInformationService(data,userId,authToken).then(result => {
         toggleDirection(true,true);
        if (registerFlow || upgradeFlow) {
          history.push('/account/payout-information');
        } else {
          enqueueSnackbar('Update successful', { variant: 'default', autoHideDuration: 3000 });
          GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
          setIsActive(false);
          setIsSubmitted(false);
          setIsEdit(false);
        }        
      }).catch(err => {
        console.log("catch",err.response.data,err.response.status);
        FormErrorContextDispatch({ type: "REQUEST_ERROR", payload: err.response.data });          
        GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});         
        setIsActive(false);
        setIsSubmitted(false);
        setIsEdit(false);
      })
    }
  }, [isSubmitted])

  const handleSubmit = (e) => {    
    e.preventDefault();
    setIsSubmitted(true);
    if (!registerFlow && !upgradeFlow) {
      GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : true }}); 
    }  
  }

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  }

  const handleEditClick = (e) => {
    e.preventDefault();
    setIsEdit(true);
  }

  const handleCloseClick = (e) => {
    e.preventDefault();
    setIsEdit(false);
  }

  const handleEditResetClick = (e) => {
    e.preventDefault();
  }

  return (
    <>
      <HelmetComponent title={"DTF - Account monetization"} />      
      <Container classes={{ root: classes.containerStyleWhite }} className={isPwaMobile ? " pwaMobile" : ""}>
        <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid 
            container
            justify="flex-start"
            direction="column"
            spacing={0}
            classes={{ root: classes.formGridStyle3 }}
          >
            {
              registerFlow || upgradeFlow
              ?
                <>
                  <Grid item>
                    <Typography variant={"h1"} classes={{ root: classes.headerDefaultStyle }}>
                      Monetize
                    </Typography>   
                  </Grid>
                  <Grid item classes={{ root: classes.gridItemHeaderWrapper }}> 
                    <Typography classes={{ root: classes.bodyDefaultStyle }}>
                      {/* Lorem ipsum dolor eset sim artun */}
                    </Typography> 
                  </Grid>
                </>
              :                
                <>
                  <Grid item>
                    <Typography variant={"h1"} classes={{ root: classes.headerDefaultStyle }}>
                      Monetization
                    </Typography>   
                  </Grid>
                  <Grid item classes={{ root: classes.gridItemHeaderWrapper }}> 
                    <Typography classes={{ root: classes.bodyDefaultStyle }}>
                      {/* Lorem ipsum dolor eset sim artun */}
                    </Typography> 
                  </Grid>
                </>
            }            
            <Grid 
              item 
              container 
              justify="center"
              alignItems="center"
              spacing={2}
            >              
              <Grid item xs={12}> 
                <Typography classes={{ root: classes.bodyDefaultStyle3 }}>
                  Activate Subscription for your Fans <AntSwitch checked={state.activateSubscription} onChange={handleChange} name="activateSubscription" />
                </Typography>
                <Box borderTop={1} classes={{ root: classes.borderTop }}></Box>
              </Grid>
              <Grid item xs={12}> 
                <Typography classes={{ root: classes.bodyDefaultStyle3 }}>
                  Select Monthly Subscription Price
                </Typography>
                <Typography classes={{ root: classes.bodyDefaultStyle5 }}>
                  Your subscribers will be billed monthly
                </Typography>
                <Box borderTop={1} classes={{ root: classes.borderTop }}></Box>
              </Grid>   
              <Grid item xs={12}> 
                <Typography classes={{ root: classes.bodyDefaultStyle3 }}>
                  Activate Subscription for your Fans <AntSwitch checked={state.chatOnline} onChange={handleChange} name="chatOnline" />
                </Typography>
                <Box borderTop={1} classes={{ root: classes.borderTop }}></Box>
              </Grid>                   
              <Grid item xs={12}> 
                <Typography classes={{ root: classes.bodyDefaultStyle3 }}>
                  Activate Tipping for your Fans <AntSwitch checked={state.appointmentOnly} onChange={handleChange} name="appointmentOnly" />
                </Typography>
                <Box borderTop={1} classes={{ root: classes.borderTop }}></Box>
              </Grid>      
              {
                requestError.error
                ?
                  <Grid item xs={12} classes={{ root: classes.ErrorTextContainerStyle }}>
                    <Typography variant="body1" classes={{ root: classes.ErrorTextStyle }}>
                      { requestError.message }
                    </Typography>               
                  </Grid>
                :
                  null
              }                
            </Grid>
            {
              registerFlow || upgradeFlow
              ?
                <Grid container item direction="row" justify="center" alignItems="flex-end" classes={{ root: classes.formGridButtonSubmit }}>
                  <SubmitButton isActive={isActive} />
                </Grid>
              :
                <Grid container item direction="row" justify="center" alignItems="flex-end" classes={{ root: classes.formGridButtonSubmit }}>
                  {
                    isEdit
                    ?
                      <>
                        <CloseButton isActive={true} handleCloseClick={handleCloseClick} />
                        <SubmitButton isActive={isActive} />
                      </>  
                    :
                      <EditButton isActive={true} handleEditClick={handleEditClick} />
                  }                  
                </Grid>
            }
          </Grid>
        </form>
      </Container>
    </>
  );
}
