export const defaultLink = {
  color: "rgba(98,74,251,1)",
  '&:hover, &:focus, &:active': {
    color: "rgba(98,74,251,1)"
  }
};

export const whiteLink = {
  textDecoration: "none",
  color: "rgba(255,255,255,1)",
  '&:hover, &:focus, &:active': {
    color: "rgba(255,255,255,1)"
  }
};

export const blackLink = {
  textDecoration: "none",
  color: "rgba(0,0,0,1)",
  '&:hover, &:focus, &:active': {
    color: "rgba(0,0,0,1)"
  }
};

export const inheritLink = {
  textDecoration: "none",
  color: "inherit",
  '&:hover, &:focus, &:active': {
    color: "inherit"
  }
};