
import jwt_decode from "jwt-decode";

export function GetToken(token) {
  try {
    const decodeToken = jwt_decode(token);
    return decodeToken;
  } catch (error) {
    return false;
  }
}