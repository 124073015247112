import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { videoEditImageElement, videoImagePlayIconElement } from '../styles/defaultVideoWrapperStyles'; 
import PlayCircleOutlineRoundedIcon from '@material-ui/icons/PlayCircleOutlineRounded';
import CameraIcon from './CameraIcon.2';

const useStyles = makeStyles((theme) => ({
    videoEditImageElement: videoEditImageElement,
    videoImagePlayIconElement: videoImagePlayIconElement,

}));

export default function ImageVideoOverlay(props) {
    const { image="", alt="", imageInfo=null} = props;    
    const classes = useStyles(); 

    if (imageInfo) {
        if (imageInfo.video_information) {
            if (imageInfo.video_information.resource_type === "video") {
                return (
                    <PlayCircleOutlineRoundedIcon classes={{root: classes.videoImagePlayIconElement}} />
                )     
            }
        }    
    }
    
    return (        
        <CameraIcon />     
    );
}
