import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { defaultGridWrapper, defaultGrid, defaultGridApp, backBtn, titleWhite, titleBlack, titleUserName, titleUserNameWhite, titleUserNameBlack } from '../styles/defaultGlobalTopBarStyles';

import SearchPageInput from './SearchPageInput.2';
import LinkText from './LinkText.5';
import Menu from './Menu.3';
import BackArrowBlack from './svg/BackArrowBlack';
import BackArrowWhite from './svg/BackArrowWhite';
import DotGreen from './svg/DotGreen';
import HouseSmallPurple from './svg/HouseSmallPurple';

import { AuthContext } from '../contexts/AuthContext';
import { FramerContext } from '../contexts/FramerContext';
import { GlobalTopBarContext } from '../contexts/GlobalTopBarContext';
import { GlobalContext } from '../contexts/GlobalContext';
import HamburgerDotBlack from './svg/HamburgerDotBlack';
import HamburgerDotWhite from './svg/HamburgerDotWhite';

const useStyles = makeStyles((theme) => ({
  defaultGridWrapper : defaultGridWrapper,
  defaultGrid: defaultGrid,
  defaultGridApp: defaultGridApp,
  titleWhite : titleWhite,
  titleBlack : titleBlack,
  titleUserName: titleUserName,
  titleUserNameWhite: titleUserNameWhite,
  titleUserNameBlack: titleUserNameBlack,
  backBtn : backBtn,
  ArrowBackIosIcon: {
    lineHeight: "50px",
    height: "50px",
    display: "block",
    margin: "auto"
  },
  HamburgerDot: {
    lineHeight: "50px",
    display: "block"
  },
  dotIcon: {
    top: "3px",
    left: "-8px",
    position: "relative",
    width: "8px",
    height: "8px",
    "& > svg": {
      width: "inherit"
    }
  },
  linkHouseIcon: {
    top: "1px",
    position: "relative"
  },
  doneButton: {
    textTransform: "none",
    fontSize: "15px",
    color: "rgba(98,74,251,1)"
  }
}));

export default function GlobalTopBackButton() {
  const classes = useStyles();
  let history = useHistory();

  const { state, toggleDirection } = useContext(FramerContext);
  const { AuthContextState } = useContext(AuthContext);
  const { registerFlow } = AuthContextState;   
  const { GlobalContextState } = useContext(GlobalContext);
  const { isPwaMobile } = GlobalContextState;
  const { GlobalTopBarContextState, GlobalTopBarContextDispatch } = useContext(GlobalTopBarContext);
  const { themeColor="white", hideTopBar=true, hideTopBarBorder=false, bgTransparent=false, showBackButton=true, showMenuIcon=true, showActionIcon=false, actionText="", actionMethod=null, showSearch=false, showTitle=true, title="", showUserName=false, showGreenIcon=false, showHouse=false, house="" } = GlobalTopBarContextState;
  const [ menuButton, setMenuButton ] = useState(false);
  const [ menuDrawer, setMenuDrawer ] = useState(false);
  const [ actionButton, setActionButton ] = useState(false);
  const [ searchBar, setSearchBar ] = useState(false);

  const toggleMenuDrawer = (bool) => (event) => {
    setMenuDrawer(bool);
  };

  const handleClick = (td) => {
    td(false, true);
    history.goBack();
  }

  const handleMethod = (cb) => {
    if (cb) {
      cb();
    }    
  }

  useEffect(() => {
    // let hideTopBar = true;
    // let showMenuIcon = false;
    // let showTitle = false;
    // let stateColor = "white";
    // let showBackButton = false;
    // let showSearch = false;
    // console.log('showSearch',showSearch,showMenuIcon)
    if (showSearch) {
      setSearchBar(true)
    } else {
      setSearchBar(false)
    }

    if (showMenuIcon) {
      setMenuButton(true)
    } else {
      setMenuButton(false)
    }

    if (showActionIcon) {
      setActionButton(true)
    } else {
      setActionButton(false)
    }
}, [showSearch, showMenuIcon, showActionIcon])

  return (
    <div className={classes.defaultGridWrapper + (hideTopBar ? ' hideTopBar' : '') + (isPwaMobile ? ' pwa': '') + (bgTransparent ? ' bgTransparent' : '' ) + (hideTopBarBorder || registerFlow ? ' borderWhite' : '')}>
      <Grid 
        container
        justify="flex-start"
        alignItems="center"
        alignContent="center"
        direction="row"
        spacing={0}
        classes={{ root: (isPwaMobile) ? classes.defaultGridApp : classes.defaultGrid }}
        className={ (themeColor === 'white' ? ' whiteBar' : ' blackBar' ) + (bgTransparent ? ' bgTransparent' : '' )}       
      >
        <Grid item xs={2} align='left'>
          {
            showBackButton
            ? 
              <Button classes={{ root: classes.backBtn }} onClick={(e) => handleClick(toggleDirection)}>
                {
                  themeColor === 'white'
                  ?
                    <BackArrowBlack />
                  :
                    <BackArrowWhite />
                }    
              </Button>
            :
              null
          }                   
        </Grid>
        <Grid item xs={8} align='center'>

          
          {/* {
            showSearch
            ?
              showSearchType === "discovery"
              ?
                <SearchDiscoveryInput />
              :
                <SearchPageInput />
            :
              <Typography variant={"h1"} classes={{ h1: classes.titleWhite }}>
              { 
                showGreen
                ?
                  <>
                    <FiberManualRecordIcon classes={{ root: classes.dotIcon }} /> {title}
                  </>
                :
                  title
              }             
              </Typography>
          } */}
          {
            searchBar
            ?
              <SearchPageInput />
            :
              showTitle && title !== ""
              ?
                <>
                  <Typography variant={"h1"} classes={{ h1: showUserName ? themeColor === 'white' ? classes.titleUserName : classes.titleUserNameWhite : themeColor === 'white' ? classes.titleBlack : classes.titleWhite }}>
                    { 
                      showGreenIcon
                      ?
                        <span className={classes.dotIcon}><DotGreen /></span> 
                      :
                        null
                    }    
                    {title}
                  </Typography>
                  { 
                    showHouse && house !== ""
                    ?
                      <>
                        <span className={classes.linkHouseIcon}><HouseSmallPurple /></span> <LinkText url={"/house/comingsoon"}>House of {house}</LinkText>
                      </>
                    :
                      null
                  } 
                </>
              :
                null
          }
        </Grid>
        <Grid item xs={2} align='center'>
          {
            menuButton && !registerFlow
            ?
              <>
                <div onClick={toggleMenuDrawer(true)} className={ classes.HamburgerDot }>
                {
                  themeColor === 'white'
                  ?
                    <HamburgerDotBlack />
                  :
                    <HamburgerDotWhite />
                }                
                </div>  
                <Menu open={menuDrawer} handleToggle={toggleMenuDrawer} />
              </>  
            :
              actionButton
              ?
                <Button classes={{ label: classes.doneButton }} onClick={(e) => handleMethod(actionMethod)}>
                  {actionText}
                </Button>
              :
                null
          }             
        </Grid>
      </Grid>
    </div>
  );
}
