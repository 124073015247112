import axios from 'axios';
import { VersionInitialState } from "../constants/VersionInitialState";
const versionApiNumber = VersionInitialState.versionApiNumber;

export async function GetAllLatestVideoService(objData) {
    const { pageNo=0, itemPerPage=3, filter="", videoId="" } = objData
    let endpoint = `/api/${versionApiNumber}/video/all/${pageNo}/${itemPerPage}`;
    
    if (filter !== "") {
        if (videoId !== "") {
            endpoint = `/api/${versionApiNumber}/video/all/${pageNo}/${itemPerPage}/${filter}/${videoId}`;
        } else {
            endpoint = `/api/${versionApiNumber}/video/all/${pageNo}/${itemPerPage}/${filter}`;   
        }
    }

    const data = await axios.get(endpoint);
    return data;
}


export async function GetAllPublicLatestVideoService(objData) {
    const data = await axios.get(
        `/api/${versionApiNumber}/video/public/${objData.pageNo}`
    );
    return data;
}

export async function GetAllPremiumLatestVideoService(objData) {
    const data = await axios.get(
        `/api/${versionApiNumber}/video/premium/${objData.pageNo}`
    );
    return data;
}

export async function GetActiveVideoByVideoIdService(objData) {
    const data = await axios.get(
        `/api/${versionApiNumber}/video/id/${objData.videoId}`
    );
    return data;
}

export async function GetAllLatestVideoByUserIdService(objData) {
    const data = await axios.get(
        `/api/${versionApiNumber}/video/user/${objData.userId}/${objData.pageNo}/${objData.itemPerPage}`,
        {
            headers: {
                'Authorization': `Bearer ${objData.authToken}` 
            }
        }
    );
    return data;
}

export async function GetAllLatestVideoByUserIdServiceWithFilters(objData) {
    const data = await axios.get(
        `/api/${versionApiNumber}/video/user/${objData.userId}/${objData.pageNo}/${objData.itemPerPage}/${objData.filterType}/${objData.deleteCount ? objData.deleteCount : 0}`,
        {
            headers: {
                'Authorization': `Bearer ${objData.authToken}` 
            }
        }
    );
    return data;
}

export async function GetAllLatestVideoByUserIdServiceWithFiltersPublic(objData) {

    console.log('GetAllLatestVideoByUserIdServiceWithFiltersPublic',objData.videoId, typeof objData.videoId)

    let endpoint = `/api/${versionApiNumber}/video/public/user/${objData.userId}/${objData.pageNo}/${objData.itemPerPage}/${objData.filter}`;
    
    if (objData.videoId !== "" && objData.videoId !== null && typeof objData.videoId !== "undefined") {
        endpoint = `/api/${versionApiNumber}/video/public/user/${objData.userId}/${objData.pageNo}/${objData.itemPerPage}/${objData.filter}/${objData.videoId}`;
    }

    const data = await axios.get(
        endpoint,
        {
            headers: {
                'Authorization': `Bearer ${objData.authToken}` 
            }
        }
    );
    return data;
}

export async function GetVideoByUserIdAndVideoIdService(objData) {
    const data = await axios.get(
        `/api/${versionApiNumber}/video/edit/${objData.userId}/${objData.videoId}`,
        {
            headers: {
                'Authorization': `Bearer ${objData.authToken}` 
            }
        }
    );
    return data;
}

export async function GetVideoByUserIdAndVideoIdServicePublic(objData) {
    const data = await axios.get(
        `/api/${versionApiNumber}/video/public/view/${objData.userId}/${objData.videoId}`
    );
    return data;
}

export async function UploadVideoByUserIdAndVideoIdService(objData) {
    const data = await axios.post(
        `/api/${versionApiNumber}/video/edit/upload/${objData.userId}/${objData.videoId}`,
        objData.data,
        {
            headers: {
                'Authorization': `Bearer ${objData.authToken}` 
            }
        }
    );
    return data;
}

export async function UpdateVideoByUserIdAndVideoIdService(objData) {
    const data = await axios.post(
        `/api/${versionApiNumber}/video/edit/${objData.userId}/${objData.videoId}`,
        objData.data,
        {
            headers: {
                'Authorization': `Bearer ${objData.authToken}` 
            }
        }
    );
    return data;
}

export async function DeleteVideoByUserIdAndVideoIdService(objData) {
    const data = await axios.delete(
        `/api/${versionApiNumber}/video/delete/${objData.userId}/${objData.videoId}`,
        {
            headers: {
                'Authorization': `Bearer ${objData.authToken}` 
            }
        }
    );
    return data;
}

export async function GetCommentVideo(objData, authToken) {
    const data = await axios.get(
        `/api/${versionApiNumber}/video/comment/${objData.videoId}/${objData.pageNo}/${objData.itemPerPage}`,
        {
            headers: {
                'Authorization': `Bearer ${authToken}` 
            }
        }
    );
    return data;
}

//public to update count for views
export async function UpdateVideoViewsByVideoId(objData, authToken) {
    const data = await axios.post(
        `/api/${versionApiNumber}/video/views/${objData.videoId}`,
        objData,
        {
            headers: {
                'Authorization': `Bearer ${authToken}` 
            }
        }
    );
    return data;
}