import React from 'react';

export default class SearchIconBigPurple extends React.Component {
    render() {
        return (
            <svg id="Search-alt-1" xmlns="http://www.w3.org/2000/svg" width="46.138" height="46.127" viewBox="0 0 46.138 46.127">
                <path id="Path_5" data-name="Path 5" d="M20.055,40.11A20.055,20.055,0,1,1,40.11,20.055,20.055,20.055,0,0,1,20.055,40.11Zm0-36.1A16.044,16.044,0,1,0,36.1,20.055,16.044,16.044,0,0,0,20.055,4.011Z" fill="#624afb"/>
                <path id="Path_6" data-name="Path 6" d="M28.329,30.334a2.006,2.006,0,0,1-1.424-.582L16.3,19.144A2.014,2.014,0,1,1,19.144,16.3L29.753,26.885a2.026,2.026,0,0,1-1.424,3.449Z" transform="translate(15.792 15.792)" fill="#624afb"/>
            </svg>        
        )
    }
}