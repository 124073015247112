export const GlobalBottomBarDefault = {
    color: "rgba(0,0,0,1)",
    position: "fixed",
    bottom: "0px",
    height: "80px",
    width: "100vw", 
    zIndex: "100",
    backgroundColor: "rgba(255,255,255,1)",
    // "-webkit-box-shadow": "0px 0px 25px 10px rgba(0,0,0,0.5)",
    // boxShadow: "0px 0px 25px 10px rgba(0,0,0,0.5)",
    padding: "0",
    transition: "all 100ms linear",
    "-webkit-transition": "all 100ms linear",
    "-moz-transition": "all 100ms linear",
    "-o-transition": "all 100ms linear",
    "&.hideBar": {
        bottom: "-120px"
    },
    "&.whiteBar": {
        color: "rgba(0,0,0,1)",
        backgroundColor: "rgba(255,255,255,1)",
        borderTop: "1px solid rgba(192,192,192,1)"
    },
    "&.blackBar": {
        color: "rgba(255,255,255,1)",
        backgroundColor: "rgba(0,0,0,1)",
        borderTop: "1px solid rgba(0,0,0,1)"
    }
}

export const GridItemBottomBarDefault = {
    paddingTop: "15px !important"
} 

export const GlobalBottomBar = {
    color: "rgba(255,255,255,1)",
    position: "fixed",
    bottom: "0px",
    height: "60px",
    width: "100vw",
    zIndex: "3",
    backgroundColor: "transparent",
    padding: "0",
    transition: "all 100ms linear",
    "-webkit-transition": "all 100ms linear",
    "-moz-transition": "all 100ms linear",
    "-o-transition": "all 100ms linear",
    "&.hideBar": {
        bottom: "-80px"
    }
}

export const GlobalBottomBarWhite = {
    color: "rgba(0,0,0,1)",
    position: "fixed",
    bottom: "0px",
    height: "60px",
    width: "100vw", 
    zIndex: "3",
    backgroundColor: "rgba(255,255,255,1)",
    "-webkit-box-shadow": "0px 0px 25px 10px rgba(0,0,0,0.5)",
    boxShadow: "0px 0px 25px 10px rgba(0,0,0,0.5)",
    padding: "0",
    transition: "all 100ms linear",
    "-webkit-transition": "all 100ms linear",
    "-moz-transition": "all 100ms linear",
    "-o-transition": "all 100ms linear",
    "&.hideBar": {
        bottom: "-80px"
    }
}

export const GlobalBottomBarBlack = {
    color: "rgba(0,0,0,1)",
    position: "fixed",
    bottom: "0px",
    height: "60px",
    width: "100vw", 
    zIndex: "3",
    backgroundColor: "rgba(0,0,0,1)",
    "-webkit-box-shadow": "0px 0px 25px 10px rgba(0,0,0,0.5)",
    boxShadow: "0px 0px 25px 10px rgba(0,0,0,0.5)",
    padding: "0",
    // transition: "all 100ms linear",
    // "-webkit-transition": "all 100ms linear",
    // "-moz-transition": "all 100ms linear",
    // "-o-transition": "all 100ms linear",
    "&.hideBar": {
        bottom: "-80px"
    }
}

export const plusIcon = {
    background: "rgba(98,74,251,1)",
    borderRadius: "14px",
    padding: "0px 12px",
    width: "50px",
    height: "35px",
    color: "rgba(255,255,255,1)"
}
export const GridBottomBarDefault = {
    margin: "-15px 0 0 0",
    width: "100%",
    height: "inherit"
}

// export const marginCustom = {
//     margin: "-4px 0 0 -14px"
// }

export const  margin0 = { 
    margin: "0" 
}

export const fontSize25 = { 
    fontSize:"25px" 
}

export const fontSize25Black = {
    color: "rgba(0,0,0,1)", 
    fontSize:"25px" 
}

export const fontSize25White = { 
    color: "rgba(255,255,255,1)",
    fontSize:"25px" 
}

export const fontSize25Active = { 
    color: "rgba(98,74,251,1)",
    fontSize:"25px" 
}

export const messageDot = {
    width: "8px",
    height: "8px",
    backgroundColor: "rgba(251,78,179,1)",
    display: "inline-block",
    position: "absolute",
    borderRadius: "8px",
    marginTop: "-3px",
    marginLeft: "15px"
}

export const messageTextWrapper = {
    
    backgroundColor: "rgba(251,78,179,1)",
    display: "inline-block",
    position: "relative",
    borderRadius: "5px",
    float: "left",
    marginTop: "-40px",    
    overflow: "visible",
    "& .messageText": {
        color: "rgba(255,255,255,1)",
        fontSize: "14px",
        fontWeight: "300",
        width: "auto",
        maxWidth: "100px",
        height: "30px",
        lineHeight: "30px",
        padding: "0px 10px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        marginLeft: "auto",
        marginRight: "auto"
    },
    "& .messageArrow": {
        position: "absolute",
        top: "80%",
        left: "15px",
        width: "0px",
        borderTop: "5px solid rgba(251,78,179,1)",
        borderRight: "5px solid rgba(251,78,179,1)",
        borderBottom: "5px solid rgba(251,78,179,1)",
        borderLeft: "5px solid rgba(251,78,179,1)",
        transform: "rotate(45deg)",
        "&.pwa": {
            left: "17px",
        }
    }
}