export const DrawerShareContextReducer = (state, action) => {
    switch(action.type){
        case 'SHOW_DRAWER':
            return { 
                ...state,
                videoId: action.payload.videoId,
                currentIndex: action.payload.currentIndex,
                showDrawerShare: true
            }
        case 'HIDE_DRAWER':
            return {
                ...state,
                currentIndex: null,
                showDrawerShare: false
            } 
        case 'POST_SHARE':
            return {
                ...state,
                share: action.payload.share,
                videoId: action.payload.videoId,
                post: action.payload.post
            } 
        case 'RESET_POST_COMMENT':
            return {
                ...state,
                share: "",
                videoId: "",
                post: false
            } 
        case 'DELETE_SHARE':
            return {
                ...state,
                shareId: action.payload.shareId,
                videoId: action.payload.videoId
            }   
        default:
            return state    
    }
}