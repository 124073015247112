export const FormErrorContextReducer = (state, action) => {
    let obj = { 
        message: '',
        error: false, 
    }
    switch(action.type){
        case 'RESET_ERRORS':
            return {
                ...state,
                usernameError: { 
                    message: '',
                    error: false, 
                },
                handlenameError: {
                    message: '',
                    error: false     
                },
                emailError: { 
                    message: '',
                    error: false, 
                },
                passwordError: { 
                    message: '',
                    error: false, 
                },
                phoneError: { 
                    message: '',
                    error: false, 
                },
                codeError: { 
                    message: '',
                    error: false, 
                },
                requestError: {
                    message: '',
                    error: false
                }
            }
        case 'REQUEST_ERROR':
            const { message, success, error=false, isDuplicate=false, field='' } = action.payload;
            if (isDuplicate) {
                switch(field){
                    case "usernameError":
                        obj.error = true;
                        obj.message = message;
                        break;
                    case "handlenameError":
                        obj.error = true;
                        obj.message = message;
                        break;
                    case "phoneError":
                        obj.error = true;
                        obj.message = message;
                        break;
                    case "emailError":
                        obj.error = true;
                        obj.message = message;
                        break;    
                    default:
                }
                return { 
                    ...state,
                    [field]: obj
                }
            } else {
                obj.error = ((error !== false) ? true : (typeof success !== "undefined") ? (success === 0) ? true : false : false);
                obj.message = ((error !== false) ? 'We are experiencing technical difficulties. Please try again later.' : (typeof message !== "undefined") ? message : 'We are experiencing technical difficulties. Please try again later.');
                return { 
                    ...state,
                    requestError: obj
                }
            }
            
        case 'VALIDATE_EMAIL':
            if (!action.payload.trim()) {
                obj.error = true;
                obj.message = 'Email required';
            } else if (!/\S+@\S+\.\S+/.test(action.payload)) {
                obj.error = true;
                obj.message = 'Email address is invalid';
            }
            return { 
                ...state,
                emailError: obj
            }    
        case 'VALIDATE_PHONE':
            // https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript
            // (123) 456-7890
            // (123)456-7890
            // 123-456-7890
            // 123.456.7890
            // 1234567890
            // +31636363634
            // 075-63546725
            if (!action.payload.trim()) {
                obj.error = true;
                obj.message = 'Phone required';     
            } else if (!/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(action.payload.toString())) {
                obj.error = true;
                obj.message = 'Phone is invalid';
            }
            return { 
                ...state,
                phoneError: obj
            }
        case 'VALIDATE_PASSWORD':
            if (!action.payload.trim()) {
                obj.error = true;
                obj.message = 'Password is required';     
            } else if (action.payload.length < 6) {
                obj.error = true;
                obj.message = 'Password needs to be 6 characters or more';
            }
            return { 
                ...state,
                passwordError: obj
            }            
        case 'VALIDATE_USERNAME':
            if (!action.payload.trim()) {
                obj.error = true;
                obj.message = 'Username is required';     
            }
            return { 
                ...state,
                usernameError: obj
            }
        case 'VALIDATE_HANDLENAME':
            if (!action.payload.trim()) {
                obj.error = true;
                obj.message = 'Handle name is required';     
            } else {
                if (action.payload.trim().length < 6) {
                    obj.error = true;
                    obj.message = 'Handle name is too short';     
                }
            }
            return { 
                ...state,
                handlenameError: obj
            }
        case 'VALIDATE_CODE':
            let data = action.payload.trim()
            if (data.length <= 5) {
                obj.error = true;
                obj.message = 'Validation code is required';     
            }
            return { 
                ...state,
                codeError: obj
            }         
        default:
            return { ...state }  
    }
}