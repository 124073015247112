import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LinkButton from './LinkButton';
import { videoWrapper, videoImageElement } from '../styles/defaultVideoWrapperStyles';
import { iconHeart, iconLike, iconView, premiumLock, premiumLockWrapper } from '../styles/defaultIconStyles.2';
import { convertCountToString } from '../utils/CommonUtil';
import LockWhiteCirclePink from '../components/svg/LockWhiteCirclePink';
import ImageVideoItem from '../components/ImageVideoItem';
import EyeWhiteBorder from '../components/svg/EyeWhiteBorder';
import HeartSmallBorderWhite from '../components/svg/HeartSmallBorderWhite';

const useStyles = makeStyles((theme) => ({  
  videoWrapper: videoWrapper,
  videoImageElement: videoImageElement,
  iconHeart: iconHeart,
  iconLike: iconLike,
  iconView: iconView,
  iconWrapper: {    
    width: "98%",
    position: "absolute",
    left: "0px",
    bottom: "0px",
    display: "inline-block",
    height: "30px",
    fontSize: "12px",
    fontWeight: "600",
    color: "rgba(255,255,255,1)",
  },
  iconLeftWrapper: {    
    position: "relative",
    width: "50%",
    textAlign: "center",
    display: "inline-block"
  },
  iconRightWrapper: {    
    position: "relative",
    width: "50%",
    textAlign: "center",
    display: "inline-block"
  },
  iconText: {    
    position: "relative",
    top: "-3px",
    left: "3px",
    textShadow: "1px 1px rgb(0,0,0,0.5)"
  },
  premiumLockWrapper: premiumLockWrapper,
  premiumLock: premiumLock
}));

export default function SearchDiscoveryPeopleItem(props) {
  const classes = useStyles();
  const { dataItem, index, searchType="people", searchStr="" } = props;  
  const { video, user_information=null } = dataItem;

  const handleClickLike = (e, item) => {
    // e.preventDefault();
    // console.log('handleClickLike',item);   
  }

  return (
    <Grid item xs={4} key={"video-grid-"+index}>
      <div className={ classes.videoWrapper }>
        <LinkButton url={"/videos/discovery/"+searchType+"/"+searchStr+"/"+video._id} directionBool={true}>
          <ImageVideoItem image={video.video_src} alt={""} isVideo={video.video_information ? video.video_information.resource_type === "video" ? true : false : false} />
        </LinkButton>
        {
          video.premium
          ?
            // <div className={ classes.premiumLockWrapper }>
            //   <LockOutlinedIcon classes={{ root: classes.premiumLock }} />                               
            // </div>
            <div className={ classes.premiumLockWrapper }>
              <span className={ classes.premiumLock + (user_information && user_information.isSubscribe ? " subscribed" : "")}>
                <LockWhiteCirclePink />
              </span>                              
            </div>
          :
            null
        }                          
        <div className={ classes.iconWrapper }>
          <div className={ classes.iconLeftWrapper } onClick={(e) => handleClickLike(e,video)}>
            {/* <FavoriteBorderIcon classes={{ root: classes.iconLike }} /> */}
            <span className={ classes.iconLike }>
              <HeartSmallBorderWhite />
            </span>
            <span className={ classes.iconText }>{convertCountToString(video.likes)}</span>   
          </div>
          <div className={ classes.iconRightWrapper }>      
            {/* <VisibilityOutlinedIcon classes={{ root: classes.iconHeart }} />   */}
            <span className={ classes.iconView }>
              <EyeWhiteBorder />
            </span>
            <span className={ classes.iconText }>{convertCountToString(video.views)}</span>                               
          </div>
        </div>                      
      </div>
      </Grid>   
  );
}