import React, { useContext, useState, useEffect } from 'react';
import { useHistory, useParams, Redirect } from "react-router-dom";

import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Typography from '@material-ui/core/Typography';

import { containerStyleWhite2, formGridStyle, formGridButtonSubmit, headerDefaultStyle } from '../../styles/defaultStyles.2';
import { ErrorTextStyle, ErrorTextContainerStyle } from '../../styles/defaultErrorStyles';
import { inputDefault, inputUnderline, eyeIcon, eyeActiveIcon } from '../../styles/defaultInput';
import { gridItemHeaderWrapper } from '../../styles/defaultGridStyles';

import SubmitButton from '../../components/SubmitButton.2';
import HelmetComponent from "../../components/HelmetComponent";

import { FormErrorContext } from '../../contexts/FormErrorContext';
import { FramerContext } from '../../contexts/FramerContext';
import { GlobalContext } from '../../contexts/GlobalContext';

import { ResetPasswordValidationCodeService } from "../../services/RegistrationServices";
import { GetToken } from "../../utils/TokenUtil";

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    }    
  },
  containerStyleWhite2: containerStyleWhite2, 
  formGridStyle: formGridStyle,
  headerDefaultStyle: headerDefaultStyle,
  formGridButtonSubmit: formGridButtonSubmit,
  gridItemHeaderWrapper: gridItemHeaderWrapper,
  ErrorTextStyle: ErrorTextStyle,
  ErrorTextContainerStyle: ErrorTextContainerStyle,
  inputDefault: inputDefault,  
  inputUnderline: inputUnderline,
  eyeIcon: eyeIcon,
  eyeActiveIcon: eyeActiveIcon
}));

export default function ResetPassword() {
  const classes = useStyles();
  let history = useHistory();
  const { token } = useParams();
  const decodeToken = GetToken(token);
  const tokenContact = (typeof decodeToken.contact !== 'undefined' ? decodeToken.contact : '');
  const tokenUserType = (typeof decodeToken.userType !== 'undefined' ? decodeToken.userType : 'user');
  const tokenUserId = (typeof decodeToken.userId !== 'undefined' ? decodeToken.userId : '');
  const tokenExpDate = (typeof decodeToken.exp !== 'undefined' ? decodeToken.exp : '');
  const nowDate = new Date();

  const { toggleDirection } = useContext(FramerContext);
  const { FormErrorContextState, FormErrorContextDispatch } = useContext(FormErrorContext);  
  const { passwordError, requestError } = FormErrorContextState;
  const { GlobalContextDispatch } = useContext(GlobalContext);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (!isSubmitted) {
      if (password.length > 0) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }  
      if (passwordError.error || requestError.error) {
        FormErrorContextDispatch({ type: "RESET_ERRORS" })
      }  
    }

    if (!passwordError.error && password.length !== 0 && isSubmitted) {
      const data = {
        contact: tokenContact,
        userId: tokenUserId,
        password: password,
      }
      ResetPasswordValidationCodeService(data).then(result => {
        toggleDirection(true,true); 
        history.push('/login');
        GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
      }).catch(err => {
        console.log("catch",err.response.data,err.response.status);
        FormErrorContextDispatch({ type: "REQUEST_ERROR", payload: err.response.data });  
        GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});      
        setIsActive(false);
        setIsSubmitted(false);
      })
    } else {
      if (isSubmitted) {
        GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
        setIsSubmitted(false);
      }
    }
    
  }, [password, passwordError])

  const handleSubmit = (e) => {    
    e.preventDefault();
    GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : true }});
    FormErrorContextDispatch({ type: "VALIDATE_PASSWORD", payload: password });
    setIsSubmitted(true);  
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  }

  if (tokenExpDate !== '') {
    if (nowDate > tokenExpDate) {
      return <Redirect to={"/forgot-password/expired"} />
    } else {
      return (
        <>
          <HelmetComponent title={"DTF - Reset Password"} />
          <Container classes={{ root: classes.containerStyleWhite2 }}>
            <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Grid
                container
                direction="column"
                spacing={0}
                classes={{ root: classes.formGridStyle }}
              >
                <Grid item classes={{ root: classes.gridItemHeaderWrapper }}>
                  <Typography variant={"h1"} classes={{ root: classes.headerDefaultStyle }}>
                    Reset Password
                  </Typography>   
                </Grid>
                <Grid 
                  item 
                  container 
                  justify="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12}>   
                    {/* <Input type="password" classes={{ root: classes.inputDefault, underline: classes.inputUnderline }} fullWidth={true} placeholder="password" error={passwordError.error} inputProps={{ 'aria-label': 'Password' }} value={password} onChange={(e) => setPassword(e.target.value)} /> */}
                    <Input
                      classes={{ root: classes.inputDefault, underline: classes.inputUnderline }} 
                      fullWidth={true} 
                      placeholder="password" 
                      id="standard-adornment-password"
                      error={passwordError.error} 
                      inputProps={{ 'aria-label': 'Password' }}
                      type={showPassword ? 'text' : 'password'}
                      value={password}
                      onChange={(e) => setPassword(e.target.value.trim())}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            classes={{ root: (password.length !== 0 ? classes.eyeActiveIcon : classes.eyeIcon) }}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Grid>
                  {
                    passwordError.error
                    ?
                      <Grid item xs={12} classes={{ root: classes.ErrorTextContainerStyle }}>
                        <Typography variant="body1" classes={{ root: classes.ErrorTextStyle }}>
                          { passwordError.message }
                        </Typography>               
                      </Grid>
                    :
                      null
                  }    
                  {
                    requestError.error
                    ?
                      <Grid item xs={12} classes={{ root: classes.ErrorTextContainerStyle }}>
                        <Typography variant="body1" classes={{ root: classes.ErrorTextStyle }}>
                          { requestError.message }
                        </Typography>               
                      </Grid>
                    :
                      null
                  }
                </Grid> 
                <Grid container item direction="row" justify="center" alignItems="flex-end" classes={{ root: classes.formGridButtonSubmit }}>
                  <SubmitButton isActive={isActive} />
                </Grid>  
              </Grid>
            </form>
          </Container>
        </>
      );
    }
  } else {
    return <Redirect to={"/forgot-password/expired"} />
  }  
}