export const SwipeSearchInitialState = {   
    peopleType: "", // followers, following, subscribers, subscriptions
    profileId: "",
    peopleResult: [], 
    peopleResultExtract: [], 
    people: "",
    pageNo: 0,  
    pageCount: 0,
    itemPerPage: 10,
    initLoad: false,
    loadNext: false,
    loadNextType: '',
    isLoading: false
}