import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FramerContext } from '../contexts/FramerContext';

export default function LinkButton(props) {
  const { url="/", children="", directionBool=true, classes="", callbackHandler=null, callbackValue="" } = props;
  const { toggleDirection } = useContext(FramerContext);

  const clickHanlder = (e) => {    
    if (callbackHandler) {
      callbackHandler(e, callbackValue);
    }
     toggleDirection(directionBool,true);
  }
  
  return (
    <Link to={url} onClick={(e) => clickHanlder(e)} style={{ textDecoration: "none" }} className={classes}>
      { children }
    </Link>
  );
}
