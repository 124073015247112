import React from 'react';

export default class BackArrowWhite extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="11.276" height="19.724" viewBox="0 0 11.276 19.724">
                <path id="Path_26" data-name="Path 26" d="M3421.117,317.617l-8.448,8.448,8.448,8.448" transform="translate(-3411.255 -316.203)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
            </svg>        
        )
    }
}