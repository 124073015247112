export const feedElement = { 
  position: "absolute",
  width: "100vw",
  height: "inherit",
  objectFit: 'contain',
  backgroundColor: "transparent",
  pointerEvents: "none",
  zIndex: 1
}

export const feedElementCover = {
  objectFit: "cover",
  width: "100%",
  height: "100%",
  minHeight: "100%",
  position: "absolute"
}

export const feedVideoFloatElement = { 
  position: "fixed",
  top: "0px",
  right: "0px",
  width: "0px",
  height: "0px",
  objectFit: 'contain',
  backgroundColor: "transparent",
  pointerEvents: "none",
  zIndex: 0
}

// export const feedVideoFloatElement = { 
//   position: "fixed",
//   top: "0px",
//   right: "0px",
//   width: "100%",
//   height: "calc(100vh - 80px)",
//   objectFit: 'contain',
//   backgroundColor: "transparent",
//   pointerEvents: "none",
//   zIndex: 1
// }

export const feedVideoElement = { 
  position: "relative",
  width: "100%",
  height: "inherit",
  objectFit: 'contain',
  backgroundColor: "transparent",
}

export const feedImageElement = { 
  position: "absolute",
  width: "100vw",
  height: "auto",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",  
  "-webkit-transform": "translate(-50%, -50%)",
  "-moz-transform": "translate(-50%, -50%)",
  backgroundColor: "rgba(0,0,0,1)",
  pointerEvents: "none",
  zIndex: 1,
  "&.facingMode": {
    // transform: 'scale(-1,1)',
    transform: "translate(-50%, -50%) rotateY(180deg)",
    "-webkit-transform": "translate(-50%, -50%) rotateY(180deg)",
    "-moz-transform": "translate(-50%, -50%) rotateY(180deg)"
  }
}

export const feedImageElementCover = { 
  position: "absolute",
  width: "100vw",
  height: "calc(100vh - 80px)",
  objectFit: "cover",
  // top: "50%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  backgroundColor: "rgba(0,0,0,1)",
  pointerEvents: "none",
  zIndex: 1
}

export const feedImageElementCover2 = { 
  objectFit: "cover",
  width: "100vw",
  height: "inherit",
}

export const feedImageElementCover3 = {
  position: "absolute",
  width: "100%",
  height: "100%",
  objectFit: "cover",
  pointerEvents: "none",
  zIndex: 1,
  "&.facingMode": {
    // transform: 'scale(-1,1)',
    transform: "rotateY(180deg)",
    "-webkit-transform": "rotateY(180deg)",
    "-moz-transform": "rotateY(180deg)"
  }  
}

export const feedImageElementBackground = { 
  position: "absolute",
  width: "100vw",
  height: "100vh",
  objectFit: "cover",
  filter: "blur(8px)",
  "-webkit-filter": "blur(8px)",  
  backgroundColor: "rgba(0,0,0,1)",
  pointerEvents: "none",
  zIndex: 0,
  "&.facingMode": {
    // transform: 'scale(-1,1)',
    transform: "rotateY(180deg)",
    "-webkit-transform": "rotateY(180deg)",
    "-moz-transform": "rotateY(180deg)"
  }
}