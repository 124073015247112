export const DrawerShareInitialState = {
    initialShareLoad: true,
    showDrawerShare: false,    
    share: "",
    shareId: "",
    videoId: "",
    deleteShare: false,
    post: false,
    get: false,
    getInfinite: false,
    shares: [],
    pageNo: 0,
    itemPerPage: 10,
    shareCount: 0,
    pageCount: 0,
    currentIndex: null,
    indexUpdateShareCount: []    
}