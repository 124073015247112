export const iconHeart = {
  fontSize: "16px",
  color: "rgba(255,255,255,1)",
  position: "relative"
};

// export const iconLike = {
//   fontSize: "16px",
//   color: "rgba(255,255,255,1)",
//   position: "relative"
// };

export const iconView = {
  top: "1px",
  position: "relative",
  "& > svg": {
    height: "inherit"
  }
};

export const iconLike = {
  top: "-2px",
  position: "relative",
  "& > svg": {
    height: "inherit"
  }
};

export const premiumLockWrapper = {
    position: "absolute",
    color: "rgba(255,255,255,1)",
    backgroundColor: "transparent",
    borderRadius: "10px",
    height: "22px",
    width: "22px",
    right: "10px",
    top: "10px",
    textAlign: "center",
    display: "inline-block"
}

export const premiumLock = {
  fontSize: "16px",
  verticalAlign: "middle",
  "& > svg": {
    height: "inherit"
  },
  "&.subscribed svg circle": {
    fill: "rgba(27,183,112,1)"
  }
}