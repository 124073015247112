
// core
import React, { useState, useContext, useEffect } from "react";

// material ui
import { Container, CssBaseline } from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import theme from "./themes/theme.2";

// contexts
import RegistrationContextProvider from './contexts/RegistrationContext';
import UserContextProvider from './contexts/UserContext';
import SearchContextProvider from "./contexts/SearchContext";
import DrawerSignUpContextProvider from './contexts/DrawerSignUpContext';
import GlobalTopBarContextProvider from "./contexts/GlobalTopBarContext";
import GlobalBottomBarContextProvider from "./contexts/GlobalBottomBarContext";
import VideosContextProvider from './contexts/VideosContext';
import VideoServiceContextProvider from './contexts/VideoServiceContext';
import ContentContextProvider from './contexts/ContentContext';
import TagContextProvider from "./contexts/TagContext";
import MessageServiceContextProvider from "./contexts/MessageServiceContext";
import { GlobalContext } from './contexts/GlobalContext';
import { AuthContext } from './contexts/AuthContext';
import { VersionContext } from './contexts/VersionContext';

import DrawerCommentContextProvider from './contexts/DrawerCommentContext';
import DrawerShareContextProvider from './contexts/DrawerShareContext';
import DrawerContentContextProvider from './contexts/DrawerContentContext';

// routes
import Routes from "./pages/Routes.2";

// components
import IosInstaller from "./components/IosInstaller";
import CircleSpinner from "./components/CircleSpinner.7";
import CustomBackdrop from "./components/CustomBackdrop";
import GlobalTopBar from './components/GlobalTopBar';
import GlobalBottomBar from "./components/GlobalBottomBar.3";

import DrawerSignUp from "./components/DrawerSignUp";
import DrawerSubscribe from "./components/DrawerSubscribe";
import DrawerOneOnOne from "./components/DrawerOneOnOne";
import DrawerComment from './components/DrawerComment.3';
import DrawerShare from './components/DrawerShare';
import DrawerContent from './components/DrawerContent';

import { vw, vh, dpr } from "./constants/DeviceConstants";
import { isPWA, checkInstallerIOS, isMobile, getBrowserName } from "./utils/CommonUtil";

const useStyles = makeStyles((theme) => ({
  mainContainer : {
    height: "auto",
    position: 'relative',
    padding: '0',
    "&.isoInstaller" : {
      overflow: 'hidden'
    }
    // height: "100vh",
    // padding: "0",
    // width: "100vw",
    // overflow: "hidden auto"
  },
  versionBox: {
    fontSize: '10px',
    textAlign: 'left',
    position: 'fixed',
    bottom: '0',
    padding: '0 20px',
    color: 'rgba(255,255,255,1)',
    backgroundColor: 'rgba(0,0,0,0.5)',
    width: '100vw',
    zIndex: '10000',
    display: 'none'
  }
}));

function App() {
  const classes = useStyles();
  const { VersionContextState, VersionContextDispatch } = useContext(VersionContext);
  const { versionNumber, newVersion } = VersionContextState;
  const { AuthContextState } = useContext(AuthContext);
  const { isAuthenticated, userType } = AuthContextState;
  const { GlobalContextState, GlobalContextDispatch } = useContext(GlobalContext);
  const { iOSInstaller } = GlobalContextState; 
  const [ installerIOS, setInstallerIOS ] = useState(0);
  const [ pwa, setPwa ] = useState(0);
  const [ mobile, setMobile ] = useState(0);
  const [ browserName, setBrowserName ] = useState('default');

  useEffect(() => {
    
    document.body.style.height = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) + "px";
    if (!isPWA()) {
      if (isMobile()) {
        document.body.classList.add('mobile-browser-view');
        setMobile(1);
      } else {
        document.body.classList.add('desktop-browser-view');
        setMobile(0);
      }
      setPwa(0);
    } else {
      if (isMobile()) {
        document.body.classList.add('mobile-app-view');
        setMobile(1);
      } else {
        document.body.classList.add('desktop-app-view');
        setMobile(0);
      }
      setPwa(1);
    }

    document.body.classList.add('browser-'+getBrowserName());
    setBrowserName(getBrowserName());

    if (checkInstallerIOS()) {
      setInstallerIOS(1)
    } else {
      setInstallerIOS(0)
    }
  }, [])
    
  return ( 
    <GlobalBottomBarContextProvider>
      <GlobalTopBarContextProvider>
        <RegistrationContextProvider>  
          <UserContextProvider>
            <MessageServiceContextProvider>
              <SearchContextProvider>    
                <VideosContextProvider>
                  <VideoServiceContextProvider>
                    <ContentContextProvider>
                      <TagContextProvider>
                        <DrawerContentContextProvider>
                        
                          <ThemeProvider theme={theme}>
                            
                              <DrawerSignUpContextProvider> 
                                <DrawerShareContextProvider>
                                  <DrawerCommentContextProvider>
                                    <CssBaseline /> 

                                    <GlobalTopBar />      
                                    <Container className={classes.mainContainer + (iOSInstaller === true ? " isoInstaller" : "")}>
                                      <Routes />
                                    </Container>                        
                                    {
                                      window.DTF_APP.SHOW_VERSION === true || window.DTF_APP.SHOW_VERSION === "true"
                                      ?
                                        <div className={classes.versionBox}>
                                          v: {versionNumber} - {window.innerWidth} - {window.innerHeight} (w:h) - {dpr} (dpr) - b: {window.DTF_APP.BOX_NAME} - p: {pwa} - I: {installerIOS} - m: {mobile} - b: {browserName}
                                        </div>
                                      :
                                        null
                                    }

                                    <GlobalBottomBar />
                                    {
                                      !isAuthenticated
                                      ?
                                        <DrawerSignUp />
                                      :
                                        <>
                                          {
                                            userType === "creator"
                                            ?
                                              <DrawerContent />
                                            :
                                              null
                                          }
                                          <DrawerComment />
                                          <DrawerSubscribe />
                                          <DrawerOneOnOne />
                                          <DrawerShare />
                                        </>  
                                    }  
                                    <CircleSpinner />
                                    <CustomBackdrop />  

                                    <IosInstaller />

                                  </DrawerCommentContextProvider>
                                </DrawerShareContextProvider>  
                              </DrawerSignUpContextProvider> 

                          </ThemeProvider>
                        </DrawerContentContextProvider>                
                      </TagContextProvider>
                    </ContentContextProvider>
                  </VideoServiceContextProvider>
                </VideosContextProvider>
              </SearchContextProvider>
            </MessageServiceContextProvider>
          </UserContextProvider>         
        </RegistrationContextProvider>    
      </GlobalTopBarContextProvider>
    </GlobalBottomBarContextProvider>
  );
}

export default App;