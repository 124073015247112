import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    width: '100vw',
    height: '100vh',
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: '1000',
    backgroundColor: 'rgba(255,255,255,0.3)'
  },
  childSpinner: {
    position: 'relative',
    top: '45%',
    left: '45%'
  },
  textWrapper: {
    display: "block",
    margin: "auto",
    position: "relative",
    width: "auto",
    height: "auto",
    textAlign: "center",
    fontSize: "100px",
    fontWeight: "900",
    color: "rgba(255,255,255,1)",
    textShadow: "1px 1px 10px rgb(0 0 0)"
  }
}));

export default function StartRecordCounter(props) {
  const { handleNext=()=>{}, countDown=2, startCount=false } = props;
  const classes = useStyles();
  const [progress, setProgress] = useState(countDown);
  
  useEffect(() => {
    if (startCount) {
      const timer = setInterval(() => {
        // console.log('setInterval timer');
        setProgress((oldProgress) => {
          if (oldProgress === 0) {
            clearInterval(timer);            
            handleNext();
            return 0;
          }
          // const diff = Math.random() * 10;
          // return Math.min(oldProgress + diff, 100);  
          // console.log('timer',(parseInt((oldProgress + 1 / maxSeconds) * 100)));
          // return parseInt((oldProgress + 1 / maxSeconds) * 100);
          // console.log('StartRecordCounter oldProgress',oldProgress);
          return oldProgress - 1;
        });
      }, 1000);
  
      return () => {
        clearInterval(timer);
      };
    }
  }, [startCount]);
  
  if (progress > 0) {
    return (
      <div className={classes.root}>
        <div className={classes.textWrapper}>
          {progress}
        </div>
      </div>
    );
  } else {
    return null
  }
}