import React from 'react';

export default class HouseSmallPurple extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="11.013" height="10.523" viewBox="0 0 11.013 10.523">
                <g id="Group_3345" data-name="Group 3345" transform="translate(0 0)">
                    <g id="Group_3344" data-name="Group 3344">
                    <path id="Path_711" data-name="Path 711" d="M12.867,7.855,7.86,3.1a.526.526,0,0,0-.7,0L2.152,7.855a.551.551,0,0,0-.025.726.526.526,0,0,0,.726,0L3,8.456v4.281a.726.726,0,0,0,.751.751h7.51a.726.726,0,0,0,.751-.751V8.456l.15.125a.5.5,0,0,0,.35.15.5.5,0,0,0,.35-.876Zm-1.852,4.631H4V7.505l3.5-3.329,3.5,3.329Z" transform="translate(-2.003 -2.965)" fill="#624afb"/>
                    </g>
                    <path id="Path_712" data-name="Path 712" d="M17.5,19.5v1h-1a.5.5,0,0,0,0,1h1v1a.5.5,0,0,0,1,0v-1h1a.5.5,0,0,0,0-1h-1v-1a.5.5,0,0,0-1,0Z" transform="translate(-12.496 -14.986)" fill="#624afb"/>
                </g>
            </svg>   
        )
    }
}