export const feedContainerStyle = {
  backgroundColor: "rgba(0,0,0,1)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0",
  width: "100vw",
  // height: "100vh",
  height: "calc(100vh - 80px)",
  scrollSnapAlign:"start",
  overflow: "hidden",
  position: "relative",
};

export const feedContainerStyle2 = {
  backgroundColor: "rgba(0,0,0,1)",
  display: "block",
  justifyContent: "center",
  alignItems: "center",
  padding: "0",
  width: "100vw",
  // height: "100vh",
  height: "calc(100vh - 80px)",
  scrollSnapAlign:"start",
  overflow: "hidden",
  position: "relative",
};