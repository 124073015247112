import React from 'react';

export default class StopIcon extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="125" height="125" viewBox="0 0 125 125">
                <g id="Group_3344" data-name="Group 3344" transform="translate(-124.999 -628)">
                    <path id="Subtraction_4" data-name="Subtraction 4" d="M-13739.5-135a62.114,62.114,0,0,1-24.328-4.911,62.3,62.3,0,0,1-19.867-13.394,62.29,62.29,0,0,1-13.395-19.867A62.1,62.1,0,0,1-13802-197.5a62.089,62.089,0,0,1,4.912-24.327,62.288,62.288,0,0,1,13.395-19.866,62.3,62.3,0,0,1,19.867-13.394A62.115,62.115,0,0,1-13739.5-260a62.1,62.1,0,0,1,24.326,4.911,62.3,62.3,0,0,1,19.867,13.394,62.308,62.308,0,0,1,13.394,19.866A62.111,62.111,0,0,1-13677-197.5a62.119,62.119,0,0,1-4.911,24.329,62.31,62.31,0,0,1-13.394,19.867,62.3,62.3,0,0,1-19.867,13.394A62.1,62.1,0,0,1-13739.5-135Zm.5-110c-26.466,0-48,21.308-48,47.5s21.532,47.5,48,47.5,48-21.31,48-47.5S-13712.532-245-13739-245Z" transform="translate(13927 888)" fill="#624afb" opacity="0.65"/>
                    <rect id="Rectangle_79" data-name="Rectangle 79" width="38" height="38" rx="11" transform="translate(169 672)" fill="#624afb"/>
                </g>
            </svg>     
        )
    }
}