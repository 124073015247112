export const inputDefault = {
  fontSize: "16px",
  color: "rgba(0,0,0,1)",
  height: "60px",
  padding: "0",
  '&::placeholder': {
    fontSize: "16px",
    color: "rgba(0,0,0,0.2)",
  } 
}

export const inputDefault2 = {
  fontSize: "16px",
  color: "rgba(0,0,0,1)",
  height: "30px",
  padding: "0",
  '&::placeholder': {
    fontSize: "14px",
    color: "rgba(0,0,0,0.2)",
  } 
}

export const inputSocialDefault = {
  fontSize: "16px",
  color: "rgba(0,0,0,1)",
  height: "30px",
  padding: "0",
  '&::placeholder': {
    fontSize: "14px",
    color: "rgba(0,0,0,0.5)",
  } 
}

export const inputMoodDefault = {
  fontSize: "16px",
  color: "rgba(0,0,0,1)",
  height: "24px",
  lineHeight: "24px",
  padding: "0",
  textAlign: "center",
  '&::placeholder': {
    fontSize: "16px",
    color: "rgba(0,0,0,0.5)",
  } 
}

export const inputHouseDefault = {
  fontSize: "16px",
  color: "rgba(0,0,0,1)",
  height: "auto",
  padding: "0",
  '&::placeholder': {
    fontSize: "16px",
    color: "rgba(0,0,0,0.2)",
  },
  '& input' : {
    padding: "0px"
  }
}

export const inputUnderline = {
  '&::before': {
    borderBottom: "1px solid rgba(0, 0, 0, 0.2)"
  }
}

export const inputNoUnderline = {
  '&::before': {
    borderBottom: "1px solid rgba(0, 0, 0, 0)"
  }
}

export const areaCodeInputhWidth = {
  width: "20%"
}

export const inputCountryCodeDefault = {
  fontSize: "16px",
  color: "rgba(0,0,0,1)",
  height: "60px",
  padding: "0 22px 0 0",
  width: 'auto',
  justifyContent: 'flex-end',
  alignItems: 'center',
  display: 'flex',
  '&::placeholder': {
    fontSize: "16px",
    color: "rgba(0,0,0,0.2)",
  }  
}

export const inputPhoneDefault = {
  fontSize: "16px",
  color: "rgba(0,0,0,1)",
  height: "60px",
  padding: "0",
  width: 'calc(100vw - 38vw)',
  '&::placeholder': {
    fontSize: "16px",
    color: "rgba(0,0,0,0.2)",
  }
}

export const inputPhoneWithAreaCodeDefault = {
  fontSize: "16px",
  color: "rgba(0,0,0,1)",
  height: "60px",
  padding: "0",
  width: '80%',
  '&::placeholder': {
    fontSize: "16px",
    color: "rgba(0,0,0,0.2)",
  }
}

export const inputPhoneUnderline = {
  '&::before': {
    borderBottom: "1px solid rgba(0, 0, 0, 0.2)"
  }
}

export const inputHouseUnderline = {
  '&::before': {
    display: "none"
  },
  '&::after': {
    display: "none"
  }
}

export const eyeActiveIcon = {
  color: "rgba(0,0,0,1)"
}

export const eyeIcon = {
  color: "rgba(0,0,0,0.20)"
}

export const atActiveIcon = {
  color: "rgba(0,0,0,1)"
}

export const atIcon = {
  color: "rgba(0,0,0,0.2)"
}

export const arrowActiveIcon = {
  // color: "rgba(0,0,0,1)",
  color: "rgba(98,74,251,1)",
  float: "right",
  padding: "0px",
  right: "0px",
  top: "-4px",
  display: "block"
}

export const arrowIcon = {
  // color: "rgba(0,0,0,1)",
  color: "rgba(98,74,251,1)",
  float: "right",
  padding: "0px",
  right: "0px",
  top: "-4px",
  display: "block"
}