export const FollowContextReducer = (state, action) => {
    switch(action.type){
        case 'SET_INIT_FOLLOWERS': 
            return {
                count: action.payload.count,
                followers: action.payload.followers
            }
        case 'UPDATE_FOLLOW':
            return { 
                ...state,
                followers : action.payload
            }
        default:
            return state    
    }
}