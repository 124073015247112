export const listText = {
  fontSize: "14px",
  fontWeight: "400",
  color: "rgba(51,51,51,1)",
  lineHeight: "20px",
  "& span": {
    color: "rgba(153,153,153,1)",
    fontSize: "12px",
    marginLeft: "5px"
  }
};

export const listTextBold = {
  fontSize: "14px",
  fontWeight: "600",
  color: "rgba(51,51,51,1)",
  lineHeight: "20px"
};

export const listTextDate = {
  fontSize: "14px",
  fontWeight: "400",
  color: "rgba(153,153,153,1)",  
  lineHeight: "20px"
};

export const listTextNew = {
  fontSize: "14px",
  fontWeight: "700",
  color: "rgba(51,51,51,1)",
  lineHeight: "20px",
  "& span": {
    color: "rgba(153,153,153,1)",
    fontSize: "12px",
    marginLeft: "5px"
  }
};

export const listTextBoldNew = {
  fontSize: "14px",
  fontWeight: "900",
  color: "rgba(51,51,51,1)",
  lineHeight: "20px"
};