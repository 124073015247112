import axios from 'axios';
import { VersionInitialState } from "../constants/VersionInitialState";
const versionApiNumber = VersionInitialState.versionApiNumber;

export async function LoginEmailService(objData) {
    // fetch data from a url endpoint
    const data = await axios.post(`/api/${versionApiNumber}/users/login/email`, objData);
    return data;
}

export async function LoginService(objData) {
    // fetch data from a url endpoint
    const data = await axios.post(`/api/${versionApiNumber}/users/login`, objData);
    return data;
}

export async function CreateUserService(objData) {
    // fetch data from a url endpoint
    const data = await axios.post(`/api/${versionApiNumber}/users/registration`, objData);
    return data;
}

export async function VerifyEmailService(objData) {
    // fetch data from a url endpoint
    const data = await axios.post(`/api/${versionApiNumber}/users/verify-email`, objData);
    return data;
}

export async function VerifyContactService(objData) {
    // fetch data from a url endpoint
    const data = await axios.post(`/api/${versionApiNumber}/users/verify-contact`, objData);
    return data;
}

export async function ValidateContactService(objData) {
    // fetch data from a url endpoint
    const data = await axios.post(`/api/${versionApiNumber}/users/validate-contact`, objData);
    return data;
}

export async function ResetPasswordValidateContactService(objData) {
    // fetch data from a url endpoint
    const data = await axios.post(`/api/${versionApiNumber}/users/reset-password-validate-contact`, objData);
    return data;
}

export async function ValidateContactLoginService(objData) {
    // fetch data from a url endpoint
    const data = await axios.post(`/api/${versionApiNumber}/users/validate-contact-login`, objData);
    return data;
}

export async function ForgotPasswordService(objData) {
    // fetch data from a url endpoint
    const data = await axios.post(`/api/${versionApiNumber}/users/forgot-password`, objData);
    return data;
}

export async function ResetPasswordService(objData) {
    // fetch data from a url endpoint
    const data = await axios.post(`/api/${versionApiNumber}/users/reset-password`, objData);
    return data;
}

export async function ResetPasswordValidationCodeService(objData) {
    // fetch data from a url endpoint
    const data = await axios.post(`/api/${versionApiNumber}/users/reset-password`, objData);
    return data;
}