import axios from 'axios';
import { VersionInitialState } from "../constants/VersionInitialState";
const versionApiNumber = VersionInitialState.versionApiNumber;

export async function subscribeService(objData, authToken) {
    const { profile_user_id = "", user_information_id = ""  } = objData;
    const data = await axios.post(
        `/api/${versionApiNumber}/action/subscribe/${profile_user_id}/${user_information_id}`,
        objData,
        {
            headers: {
                'Authorization': `Bearer ${authToken}` 
            }
        }
    );
    return data;
}

export async function UnsubscribeService(objData, authToken) {
    const { profile_user_id = "", user_information_id = ""  } = objData;
    const data = await axios.post(
        `/api/${versionApiNumber}/action/unsubscribe/${profile_user_id}/${user_information_id}`,
        objData,
        {
            headers: {
                'Authorization': `Bearer ${authToken}` 
            }
        }
    );
    return data;
}

export async function FollowService(objData, authToken) {
    const { profile_user_id = "", user_information_id = "" } = objData;
    const data = await axios.post(
        `/api/${versionApiNumber}/action/follow/${profile_user_id}/${user_information_id}`,
        objData,
        {
            headers: {
                'Authorization': `Bearer ${authToken}` 
            }
        }
    );
    return data;
}

export async function UnfollowService(objData, authToken) {
    const { profile_user_id = "", user_information_id = ""  } = objData;
    const data = await axios.post(
        `/api/${versionApiNumber}/action/unfollow/${profile_user_id}/${user_information_id}`,
        objData,
        {
            headers: {
                'Authorization': `Bearer ${authToken}` 
            }
        }
    );
    return data;
}

export async function LikeService(objData, authToken) {
    const { videoId = "" } = objData;
    const data = await axios.post(
        `/api/${versionApiNumber}/action/like/${videoId}`,
        objData,
        {
            headers: {
                'Authorization': `Bearer ${authToken}` 
            }
        }
    );
    return data;
}

export async function UnlikeService(objData, authToken) {
    const { videoId = "" } = objData;
    const data = await axios.post(
        `/api/${versionApiNumber}/action/unlike/${videoId}`,
        objData,
        {
            headers: {
                'Authorization': `Bearer ${authToken}` 
            }
        }
    );
    return data;
}

export async function PostCommentVideo(objData, authToken) {
    const data = await axios.post(
        `/api/${versionApiNumber}/action/comment`,
        objData,
        {
            headers: {
                'Authorization': `Bearer ${authToken}` 
            }
        }
    );
    return data;
}

export async function DeleteCommentVideo(objData, authToken) {
    const data = await axios.post(
        `/api/${versionApiNumber}/action/comment/delete`,
        objData,
        {
            headers: {
                'Authorization': `Bearer ${authToken}` 
            }
        }
    );
    return data;
}

export async function LikeCommentVideo(objData, authToken) {
    const data = await axios.post(
        `/api/${versionApiNumber}/action/comment/like`,
        objData,
        {
            headers: {
                'Authorization': `Bearer ${authToken}` 
            }
        }
    );
    return data;
}

export async function UnLikeCommentVideo(objData, authToken) {
    const data = await axios.post(
        `/api/${versionApiNumber}/action/comment/unlike`,
        objData,
        {
            headers: {
                'Authorization': `Bearer ${authToken}` 
            }
        }
    );
    return data;
}

export async function GetFollowers(objData) {
    const { profile_user_id = "", pageNo=0, itemPerPage=10, searchStr="" } = objData;
    const data = await axios.get(
        `/api/${versionApiNumber}/action/followers/${profile_user_id}/${pageNo}/${itemPerPage}/${searchStr}`
    );
    return data;
}

export async function GetFollowing(objData) {
    const { profile_user_id = "", pageNo=0, itemPerPage=10, searchStr="" } = objData;
    const data = await axios.get(
        `/api/${versionApiNumber}/action/following/${profile_user_id}/${pageNo}/${itemPerPage}/${searchStr}`
    );
    return data;
}

export async function GetSubscribers(objData) {
    const { profile_user_id = "", pageNo=0, itemPerPage=10, searchStr="" } = objData;
    const data = await axios.get(
        `/api/${versionApiNumber}/action/subscribers/${profile_user_id}/${pageNo}/${itemPerPage}/${searchStr}`
    );
    return data;
}

export async function GetSubscriptions(objData) {
    const { profile_user_id = "", pageNo=0, itemPerPage=10, searchStr="" } = objData;
    const data = await axios.get(
        `/api/${versionApiNumber}/action/subscriptions/${profile_user_id}/${pageNo}/${itemPerPage}/${searchStr}`
    );
    return data;
}


export async function GetSwipeSearch(objData) {
    console.log('GetSwipeSearch',objData);
    const { profile_user_id = "", pageNo=0, itemPerPage=10, searchStr="", peopleType="" } = objData;
    const data = await axios.get(
        `/api/${versionApiNumber}/action/${peopleType}/${profile_user_id}/${pageNo}/${itemPerPage}/${searchStr}`
    );
    return data;
}