export const GlobalBottomBarContextReducer = (state, action) => {
    switch(action.type){
        case 'HIDE_BOTTOM_BAR':
            return { 
                ...state,
                hideBottomBar: true
            }
        case 'SHOW_BOTTOM_BAR':
            return {
                ...state, 
                hideBottomBar: false
            }
        case 'SET_ACTIVE_STATE':
            return {
                ...state, 
                activeState: action.payload.activeState,
                themeColor: action.payload.themeColor,
                hideBottomBar: action.payload.hideBottomBar
            }
        case 'RESET_ACTIVE_STATE':
            return {
                ...state, 
                activeState: 'home',
                themeColor: 'black',
                hideBottomBar: true
            }          
        default:
            return state    
    }
}