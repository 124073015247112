import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { GlobalContext } from '../contexts/GlobalContext';
import Spinner from './svg/Spinner';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    width: '100vw',
    height: '100vh',
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: '1000',
    backgroundColor: 'rgba(255,255,255,0.3)'
  },
  childSpinner: {
    color: 'rgba(98,74,251,1)',
    position: 'relative',
    width: '30px',
    height: '30px',
    margin: 'auto',
    'webkit-animation': '$rotation 2s linear infinite',
    '-moz-animation': '$rotation 2s linear infinite',
    '-o-animation': '$rotation 2s linear infinite',
    animation: '$rotation 2s linear infinite',
    animationDelay: "0s",
    '& > svg': {
      width: 'inherit',
      height: 'inherit'
    }
  },
  "@keyframes rotation": {
    "from": {
      transform: "rotate(0deg)"
    },
    "to": {
      transform: "rotate(359deg)"
    }
  }
}));

export default function CircleSpinner() {
  const classes = useStyles();
  const { GlobalContextState } = useContext(GlobalContext);
  const { showCircleSpinner } = GlobalContextState;  
  
  if (showCircleSpinner === true) {
    return (
      <div className={classes.root}>
        <div className={classes.childSpinner}>
          <Spinner />
        </div>
      </div>
    );
  } else {
    return null
  }
  
}