import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import { containerStyle, containerGridStyle } from '../styles/defaultStyles';
import { gridItemWrapper } from '../styles/defaultGridStyles';
import { defaultBtn, defaultLabelBtn, defaultActiveBtn } from '../styles/defaultButtonStyles.2';
import { listWrapper } from '../styles/defaultListStyles';
import { listText, listTextBold } from '../styles/defaultTextStyles';
import { checkOjectExistById } from '../utils/CommonUtil';

import { TagContext } from '../contexts/TagContext';

const useStyles = makeStyles((theme) => ({  
  containerGridStyle: containerGridStyle,
  containerStyle: containerStyle,
  gridItemWrapper: gridItemWrapper,
  listWrapper: listWrapper,
  listText: listText,
  listTextBold: listTextBold,
  defaultBtn: defaultBtn,
  defaultLabelBtn: defaultLabelBtn,
  defaultActiveBtn: defaultActiveBtn,
  paddingRightLeft20: {
    paddingRight: "20px",
    paddingLeft: "20px"
  }
}));

export default function SearchLocationTagItem(props) {
  const classes = useStyles();
  const { data, index } = props;
  const { _id="", city="", city_ascii="", state_name="", lat="", lng="" } = data;
  const { TagContextState, TagContextDispatch } = useContext(TagContext);
  const { taggedLocation } = TagContextState;
  const [ tag, setTag ] = useState(false);

  const handleUntaggedClick = (e, item) => {   
    setTag(false);
    TagContextDispatch({ type: "REMOVE_TAG_LOCATION", payload: { taggedLocation : item }});    
  }

  const handleTagClick = (e, item) => {
    setTag(true);
    TagContextDispatch({ type: "ADD_TAG_LOCATION", payload: { taggedLocation : item }});    
  }

  return (
      <ListItem key={"location-tag-" + _id} classes={{ root: classes.paddingRightLeft20 }}>
        <ListItemText 
          primary={city_ascii} 
          secondary={state_name} 
          classes={{
            primary: classes.listTextBold,
            secondary: classes.listText, 
          }}
        />
        {
          checkOjectExistById(taggedLocation, data)
          ?
            <Button variant="outlined" color={ "default" } classes={ { root: classes.defaultActiveBtn, label: classes.defaultLabelBtn } }  onClick={(e) => handleUntaggedClick(e, data)}>
              Untagged
            </Button>
          :
            <Button variant="outlined" color={ "primary" } classes={ { root: classes.defaultBtn, label: classes.defaultLabelBtn } }  onClick={(e) => handleTagClick(e, data)}>
              Tag
            </Button>
        }        
      </ListItem>
  );
}