import React from 'react';

export default class VideoIconCirclePurple extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="66" height="66" viewBox="0 0 66 66">
                <g id="Group_3345" data-name="Group 3345" transform="translate(-104 -654)">
                    <circle id="Ellipse_132" data-name="Ellipse 132" cx="33" cy="33" r="33" transform="translate(104 654)" fill="#624afb"/>
                    <g id="hm_icons_video-cam" transform="translate(128.102 680.347)">
                    <path id="Path_371" data-name="Path 371" d="M18.549,5.544V14.8a.693.693,0,0,1-.389.622c-.078,0-.156.078-.233.078-.233,0-.389-.078-.389-.233l-4.121-3.188v1.711a2.921,2.921,0,0,1-2.955,2.877H3.154a3.148,3.148,0,0,1-2.1-.855A2.823,2.823,0,0,1,.2,13.785V6.555a3,3,0,0,1,.855-2.1,3.148,3.148,0,0,1,2.1-.855h7.153a2.921,2.921,0,0,1,2.955,2.954v1.71l4.121-3.188a.672.672,0,0,1,.466-.233.286.286,0,0,1,.233.078.591.591,0,0,1,.467.622Z" transform="translate(-0.2 -3.6)" fill="#fefefe"/>
                    </g>
                </g>
            </svg>    
        )
    }
}