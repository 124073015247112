export const AuthContextReducer = (state, action) => {
    switch(action.type){
        case 'SIGN_ON':
            return { 
                ...state,
                isAuthenticated: action.payload.isAuthenticated,
                authToken: action.payload.authToken,                
                userId: action.payload.userId,
                userType: action.payload.userType,
                registerFlow: action.payload.registerFlow
            }
        case 'SIGN_OFF':
            return { 
                ...state,
                isAuthenticated: false,
                authToken: null,
                userId: null,
                userType: 'user'
            }
        case 'UPDATE_USER_TYPE':
            return { 
                ...state,
                userType: action.payload.userType,
                authToken: action.payload.authToken,
            }    
        case 'UPDATE_REGISTRER_FLOW':
            return { 
                ...state,
                registerFlow: action.payload.registerFlow
            }
        case 'UPDATE_UPGRADE_FLOW':
            return { 
                ...state,
                upgradeFlow: action.payload.upgradeFlow
            }  
        default:
            return state    
    }
}