import React, { useContext, useState, useEffect, useRef }  from 'react';

import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { containerStyleWhiteSearch } from '../../styles/defaultStyles.2';

import HelmetComponent from '../../components/HelmetComponent';
import PaperList from '../../components/PaperList.2';
import InfiniteScroll from "../../components/InfiniteScroll";
import SearchPeopleItem from '../../components/SearchPeopleItem.2';
import SearchHistoryItem from '../../components/SearchHistoryItem';
import SearchHashTagVideoItem from '../../components/SearchHashTagVideoItem';
import SearchHouseTagVideoItem from '../../components/SearchHouseTagVideoItem';
import LinkText from '../../components/LinkText.5';

import { GlobalContext } from '../../contexts/GlobalContext';
import { AuthContext } from '../../contexts/AuthContext';
import { SearchContext } from '../../contexts/SearchContext';
import { DrawerSignUpContext } from '../../contexts/DrawerSignUpContext';

import { GetAllLatestVideoService } from '../../services/VideoServices';
import { GetSearchHistory } from '../../services/SearchServices';

const useStyles = makeStyles((theme) => ({  
  containerStyleWhiteSearch: containerStyleWhiteSearch, 
  resultContainer: {
    padding: "0px 20px 20px",
    overflowX: "hidden",
    overflowY: "auto",
  },
  bannerWrapper: {
    position: "relative",
    display: "block",
    whiteSpace: "nowrap",
    overflow: "hidden auto",
    height: "175px",
    width: "100%",
    border: "0px solid rgba(0,0,0,1)"
  },
  itemBannerWrapper: {
    position: "relative",
    display: 'inline-block',
    width: "96%",
    height: "145px",
    margin: "10px"
  },
  overlayGradientTop: {
    width: "100%",
    top: "100px",
    height: "50px",
    position: "fixed",
    background: "linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))",
    zIndex: "1"
  },
  overlayGradientBottom: {
    width: "100%",
    bottom: "0px",
    height: "50px",
    position: "fixed",
    background: "linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))",
    zIndex: "1"
  },
  searchHeaderTitle: {
    fontSize: "16px",
    marginTop: "25px",
    marginLeft: "10px",
    fontWeight: "500"
  },
  linkWrapper: {
    position: "absolute",
    right: "20px",
    display: "inline-block"
  }
}));

export default function Search() {
  const classes = useStyles();
  const containerRef = useRef();

  const { GlobalContextState } = useContext(GlobalContext);
  const { isPwaMobile } = GlobalContextState;

  const { DrawerSignUpContextDispatch } = useContext(DrawerSignUpContext);

  const { AuthContextState } = useContext(AuthContext);
  const { isAuthenticated, authToken } = AuthContextState;

  const { SearchContextState, SearchContextDispatch } = useContext(SearchContext);
  const { isFocus=false, houseTagVideo="", hashTagVideo="", people="", video="", location="", hashTag="", houseTagVideoResult=[], hashTagVideoResult=[], peopleResult=[], videoResult=[], locationResult=[], searchHistoryResult=[], searchPageCount=0, pageNo=0, resultLoaded=false, isLoading=false, searchAllVideosStr="", searchAllStr="" } = SearchContextState;
  
  const [ videos, setVideos ] = useState([]);
  const [ videoCount, setVideoCount ] = useState(0);  

  const [ likedVideos, setLikedVideos ] = useState([]);
  const [ likedVideoCount, setLikedVideoCount ] = useState(0);

  const [ viewedVideos, setViewedVideos ] = useState([]);
  const [ viewedVideoCount, setViewedVideoCount ] = useState(0);

  const handleLoadNext = () => {
    if (!isLoading && (peopleResult.length !== 0 || hashTagVideoResult.length !== 0 || houseTagVideoResult.length !== 0))  {
      SearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading: true, searchType: null } });
      SearchContextDispatch({ type: "LOADNEXT", payload: { loadNext: true, searchType: null } });
    }    
  }

  const showDrawerClick = (e) => {
    DrawerSignUpContextDispatch({ type: "SHOW_DRAWER", payload: true });
}

  useEffect(() => {
    GetAllLatestVideoService({pageNo: 0, itemPerPage: 6}).then(result => {
      if (result.data.success === 1) {
        setVideos(result.data.videos.videos);
        setVideoCount(result.data.videos.count);
      }
    }).catch(err => {
        console.log("catch GetAllLatestVideoByUserIdServiceWithFilters",err);
    });

    GetAllLatestVideoService({pageNo: 0, itemPerPage: 6, filter: "liked"}).then(result => {
      if (result.data.success === 1) {
        setLikedVideos(result.data.videos.videos);
        setLikedVideoCount(result.data.videos.count);
      }
    }).catch(err => {
        console.log("catch GetAllLatestVideoByUserIdServiceWithFilters",err);
    });

    GetAllLatestVideoService({pageNo: 0, itemPerPage: 6, filter: "viewed"}).then(result => {
      if (result.data.success === 1) {
        setViewedVideos(result.data.videos.videos);
        setViewedVideoCount(result.data.videos.count);
      }
    }).catch(err => {
        console.log("catch GetAllLatestVideoByUserIdServiceWithFilters",err);
    });

    if (isAuthenticated) {
      GetSearchHistory({pageNo: 0, itemPerPage: 2 }, authToken).then(result => {
        if (result.data.success === 1) {
          SearchContextDispatch({ type: "SET_SEARCH_HISTORY_RESULT", payload: result.data.search });
        }
      }).catch(err => {
          console.log("catch GetSearchHistory",err);
      });
    }

    //reset search type for discovery flow tabs and videos
    SearchContextDispatch({ type: "RESET_SEARCH_TYPE" })

    //reset search type for discovery videos
    SearchContextDispatch({ type: "RESET_SEARCH_ALL_VIDEOS_RESULT" })
    
  }, [])

  useEffect(() => {
    if (peopleResult.length !== 0 || hashTagVideoResult.length !== 0 || houseTagVideoResult.length !== 0) {
      if (!resultLoaded) {
        SearchContextDispatch({ type: "RESULT_LOADED" });
      }
    }
  }, [peopleResult, hashTagVideoResult, houseTagVideoResult])

  return (
    <>
      <HelmetComponent title={"DTF - Search"} />                      
      <Container classes={{ root: classes.containerStyleWhiteSearch }} className={isPwaMobile ? " pwaMobile" : ""}>
        {
          peopleResult.length !== 0 || locationResult.length !== 0 || hashTagVideoResult.length !== 0 || houseTagVideoResult.length !== 0 && (isFocus && searchHistoryResult.length !== 0)
          ?
            <Grid 
              container
              direction="column"  
              classes={{ root: classes.resultContainer }} 
              ref={containerRef}    
            >  
                {/* <div className={classes.overlayGradientTop}></div> */}

                {
                  searchHistoryResult.length !== 0
                  ?
                    <Typography variant={"h1"} classes={{ root: classes.searchHeaderTitle }}> 
                      Search History   
                    </Typography>
                  :
                    null
                }
                {
                  searchHistoryResult.map((item) => {
                    return <SearchHistoryItem data={item} />
                  })
                }  

                {
                  hashTagVideoResult.length !== 0
                  ?
                    <Typography variant={"h1"} classes={{ root: classes.searchHeaderTitle }}> 
                      Hashtags
                      <div className={classes.linkWrapper}>
                        <LinkText url={"/search/discovery/hashtags/" + searchAllStr} underline={false}>See All</LinkText>  
                      </div>      
                    </Typography>
                  :
                    null
                }
                {
                  hashTagVideoResult.map((item) => {
                    return <SearchHashTagVideoItem data={item} />
                  })
                }  

                {
                  peopleResult.length !== 0
                  ?
                    <Typography variant={"h1"} classes={{ root: classes.searchHeaderTitle }}>              
                      Profiles   
                      <div className={classes.linkWrapper}>
                        <LinkText url={"/search/discovery/people/" + searchAllStr} underline={false}>See All</LinkText>  
                      </div>       
                    </Typography>
                  :
                    null
                }
                {
                  peopleResult.map((item) => {
                    return <SearchPeopleItem data={item} />
                  })
                }

                {
                  houseTagVideoResult.length !== 0
                  ?
                    <Typography variant={"h1"} classes={{ root: classes.searchHeaderTitle }}> 
                      Houses
                      <div className={classes.linkWrapper}>
                        <LinkText url={"/search/discovery/houses/" + searchAllStr} underline={false}>See All</LinkText>  
                      </div>      
                    </Typography>
                  :
                    null
                }
                {
                  houseTagVideoResult.map((item) => {
                    return <SearchHouseTagVideoItem data={item} />
                  })
                } 
                {
                  resultLoaded && !isLoading && (peopleResult.length !== 0 || hashTagVideoResult.length !== 0 || houseTagVideoResult.length !== 0)
                  ?
                    <InfiniteScroll parentRef={containerRef} method={handleLoadNext} />
                  :
                    null
                }
                {/* <div className={classes.overlayGradientBottom}></div> */}
            </Grid>
          :
            people.length !== 0 || video.length !== 0 || location.length !== 0 || hashTag.length !== 0 && !isLoading
            ?
              <Grid 
                container
                direction="column"  
                classes={{ root: classes.resultContainer }}
              >  
                <Grid item xs={12}>
                  <Typography variant={"body2"} component={"span"} align="center" classes={{ root: classes.headerLabel }}>
                    No results found...
                  </Typography>
                </Grid>
              </Grid>
            :     
              <Grid 
                container
                direction="column"  
                classes={{ root: classes.resultContainer }} 
                ref={containerRef}    
              > 
                <Grid item xs={12}>
                  <PaperList name={"latestVideos"} url="/videos/latest" elevation={0} header={"Latest Videos"} subHeader={""} count={videoCount} items={videos} />
                </Grid>
                <Grid item xs={12}>
                  <PaperList name={"mostLikedVideos"} url="/videos/liked" elevation={0} header={"Most Liked Videos"} count={likedVideoCount} items={likedVideos} />
                </Grid>
                <Grid item xs={12}>
                  <PaperList name={"topViewedVideos"} url="/videos/viewed" elevation={0} header={"Top Viewed Videos"} subHeader={""} count={viewedVideoCount} items={viewedVideos} />
                </Grid>
              </Grid>
        }
      </Container>          
    </>
  );
}