import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import StopIcon from './svg/StopIcon';
import { defaultVideoButton } from '../styles/defaultButtonStyles.2';

const useStyles = makeStyles((theme) => ({
  defaultVideoButton: defaultVideoButton
}));

export default function StopRecordingButton(props) {
  const { handleClick = (e) => { }, isActive=false, id="", isRecording=false } = props;
  const classes = useStyles();
  if (isActive) {
    return (    
      <button type="button" id={id} className={classes.defaultVideoButton} onClick={(e) => handleClick(e)}>
        <StopIcon />
      </button>
    )
  } else {
    return (    
      <button type="button" id={id} className={classes.defaultVideoButton} disabled>
        <StopIcon />
      </button>
    )
  }
  
}