import React, { useContext, useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import { CloudinaryContext, Image, Transformation } from "cloudinary-react";
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Avatar from '@material-ui/core/Avatar';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';

import { containerStyleWhite, formGridStyle3, formGridButtonSubmit, headerDefaultStyle, inputReadOnly, bodyDefaultStyle, bodyFormDefaultStyle, bodyFormDefaultStyle2 } from '../../styles/defaultStyles.2';
import { ErrorTextStyle, ErrorTextContainerStyle } from '../../styles/defaultErrorStyles';
import { inputDefault, inputUnderline, inputHouseDefault, inputHouseUnderline, arrowIcon, arrowActiveIcon } from '../../styles/defaultInput';
import { textareaDefault, textareaUnderline } from '../../styles/defaultTextarea';

import { defaultChip } from '../../styles/defaultChip'
import { gridItemHeaderWrapper } from '../../styles/defaultGridStyles';
import { profileAvatar } from '../../styles/defaultAvatarStyles.2'; 

import ImageProfileAvatar from '../../components/ImageProfileAvatar';
import HelmetComponent from '../../components/HelmetComponent';
import SubmitButton from '../../components/SubmitButton.2';
import EditButton from '../../components/EditButton.2';
import CloseButton from '../../components/CloseButton.2';
import { FramerContext } from '../../contexts/FramerContext';
import { FormErrorContext } from '../../contexts/FormErrorContext';
import { AuthContext } from '../../contexts/AuthContext';
import { GlobalContext } from '../../contexts/GlobalContext';
import { UserContext } from '../../contexts/UserContext';
import { useSnackbar } from 'notistack';

// import { Houses } from '../../constants/Houses';
import { HousesObj } from '../../constants/HousesObj';

import { UpdateUserInformationService } from '../../services/UserInformationServices';
import { UploadImageService, DeleteImageService } from '../../services/UploadServices';
import { photoLimitSize, photoFileExtension } from '../../constants/FileSizeConstants';

import { GetToken } from "../../utils/TokenUtil";

const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  containerStyleWhite: containerStyleWhite, 
  formGridStyle3: formGridStyle3, 
  formGridButtonSubmit: formGridButtonSubmit, 
  headerDefaultStyle: headerDefaultStyle, 
  inputReadOnly: inputReadOnly, 
  bodyDefaultStyle: bodyDefaultStyle,
  bodyFormDefaultStyle: bodyFormDefaultStyle,
  bodyFormDefaultStyle2: bodyFormDefaultStyle2,
  ErrorTextStyle: ErrorTextStyle, 
  ErrorTextContainerStyle: ErrorTextContainerStyle,
  inputDefault: inputDefault, 
  inputUnderline: inputUnderline,
  inputHouseDefault: inputHouseDefault,
  inputHouseUnderline: inputHouseUnderline,
  textareaDefault: textareaDefault,
  textareaUnderline: textareaUnderline,
  gridItemHeaderWrapper: gridItemHeaderWrapper,
  defaultChip: defaultChip,
  arrowIcon: arrowIcon,
  arrowActiveIcon: arrowActiveIcon,
  marginWrapper: {
    margin: "20px auto 0"
  },
  autoCompleteMargin: {
    marginTop: "0px",
    marginBottom: "0px"
  },
  autoCompleteLineHeight: {
    height: "48px"
  },
  formHelperText: {
    position: "absolute",
    right: "20px"
  },
  lineHeight30: {
    lineHeight: "30px"
  },
  profileAvatar: profileAvatar,
  photoIconCloud: {
    color: "rgba(98,74,251,1)",
    backgroundColor: "rgba(98,74,251,0.03)",
    display: "block",
    margin: "auto auto 15px",
    width: "100px",
    height: "100px",
    borderRadius: "60px",
    border: "0px solid black",
    padding: "30px"
  },
  photoLabel: {
    // width: "100%",
    width: "auto",
    display: "block",
    // backgroundColor: "rgba(98,74,251,0.2)",
    textAlign: "center",
    // border: "2px dashed rgba(98,74,251,0.5)",
    // padding: "30px",
    borderRadius: "5px"
  },
  photoText: {
    color: "rgba(98,74,251,1)",
    fontWeight: "700",
    fontSize: "16px",
    fontFamily: "inherit"
  },
  deleteIcon: {
    fontSize: "25px",
    position: "absolute",
    top: "10px",
    right: "-5px",    
    color: "rgba(255,255,255,1)",
    background: "rgba(98,74,251,1)",
    borderRadius: "15px",
    border: "2px solid rgba(255,255,255,1)",
    padding: "3px"
  },
  photoGrid: {
    position: "relative",
    // margin: "20px auto 0"
    margin: "auto",
    minHeight: "155px"
  },
  uploadPhotoWrapper: {
    width: "100px",
    display: "block",
    height: "100px",
    position: "relative",
    margin: "auto"
  }
}));

export default function AccountProfileInformation() {
  const classes = useStyles();
  let history = useHistory();
  const { GlobalContextState, GlobalContextDispatch } = useContext(GlobalContext);  
  const { isPwaMobile } = GlobalContextState;
  const { AuthContextState, AuthContextDispatch } = useContext(AuthContext);  
  const { UserContextState, UserContextDispatch } = useContext(UserContext);
  const { user_name, user_description, user_house_name, user_image_profile=null } = UserContextState;
  const { authToken, userId, registerFlow } = AuthContextState;   
  const decodeToken = GetToken(authToken);
  const tokenUserType = (typeof decodeToken.userType !== 'undefined' ? decodeToken.userType : 'user');

  const { FormErrorContextState, FormErrorContextDispatch } = useContext(FormErrorContext);  
  const { requestError } = FormErrorContextState;
  const { toggleDirection } = useContext(FramerContext);
  const { enqueueSnackbar } = useSnackbar();
  
  const [isEdit, setIsEdit] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [description, setDescription] = useState('');
  const [houseOf, setHouseOf] = useState({title: ''}); 
  const [imageProfile, setImageProfile] = useState(user_image_profile || null);
  const [imageProfileInformation, setImageProfileInformation] = useState('');
  const [deleteImage, setDeleteImage] = useState(false);
  const [deleteImageName, setDeleteImageName] = useState('');

  const deleteImageFromServer = () => {
    if (imageProfile !== '' && user_image_profile !== imageProfile && imageProfile) {
      const data = {
        fileName: imageProfile
      }    
      DeleteImageService(data,userId,authToken); 
    }
  }

  useEffect(() => {
    if (user_image_profile) {
      setImageProfile(user_image_profile)
    }
  }, [user_image_profile])
  
  useEffect(() => {
    if (!isSubmitted) {
      if ((description.length > 0 || houseOf.title.length > 0 || (imageProfile || imageProfile !== '')) && (description !== user_description || houseOf.title !== user_house_name || imageProfile !== user_image_profile)) {
        setIsActive(true);      
      } else {
        setIsActive(false);
      }      
    } 
    if (isSubmitted) {
      const data = {
        description: description,
        houseName: (houseOf ? houseOf.title : ''),
        houseLink: '',
        imageProfile: imageProfile,
        imageProfileInformation: imageProfileInformation,
        deleteImage: deleteImage,
        deleteImageName: deleteImageName
      }      
      if (user_image_profile !== null && user_image_profile !== '' && user_image_profile !== imageProfile && !deleteImage && !registerFlow) {
        data.deleteImage = true;
        data.deleteImageName = user_image_profile;
      }
      UpdateUserInformationService(data,userId,authToken).then(result => {
        toggleDirection(true,true);
        UserContextDispatch({ type: "UPDATE_USER_INFORMATION", payload: result.data });
        if (registerFlow) {          
          // history.push('/account/mood'); 
          if (tokenUserType === "creator") {
            history.push('/account/social-information');
          } else {
            AuthContextDispatch({ type: "UPDATE_REGISTRER_FLOW", payload: { registerFlow: false } });
            history.push('/account/profile');
          }
        } else {
          enqueueSnackbar('Update successful', { variant: 'default', autoHideDuration: 3000 });
          GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
          setIsActive(false);
          setIsSubmitted(false);
          setIsEdit(false);
          setFileInputState('');
          // setImageProfile('');      
          setDeleteImage(false);
          setDeleteImageName('');
        }
      }).catch(err => {
        console.log("catch",err.response.data,err.response.status);
        FormErrorContextDispatch({ type: "REQUEST_ERROR", payload: err.response.data });          
        GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});         
        setIsActive(false);
        setIsSubmitted(false);
        setIsEdit(false);
        setFileInputState(''); 
        // setImageProfile('');     
        setDeleteImage(false);
        setDeleteImageName('');
      })
    }
  }, [isSubmitted, houseOf, description, imageProfile, imageProfileInformation])

  const handleSubmit = (e) => {    
    e.preventDefault();
    setIsSubmitted(true);
    if (!registerFlow) {
      GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : true }});
    }   
  }
  
  const handleEditClick = (e) => {
    e.preventDefault();
    setDescription(user_description);
    setHouseOf({title: user_house_name});
    setImageProfile(user_image_profile);
    setIsEdit(true);
  }

  const handleCloseClick = (e) => {
    e.preventDefault();    
    try {  
      deleteImageFromServer();
    } catch (err) {
      console.log("catch1",err.response.data,err.response.status);    
    }
    setIsEdit(false);
    setFileInputState('');
    setImageProfile('');
    setDeleteImage(false);
    setDeleteImageName('');      
  }
  
  const [fileInputState, setFileInputState] = useState('');
  const handleFileInputChange = (e) => {
    GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : true }});
    setFileInputState(e.target.value);

    try {
      const file = e.target.files[0];
      if (!photoFileExtension.test(file.name)) {
        setFileInputState('');
        enqueueSnackbar('File format is not supported', { variant: 'default', autoHideDuration: 3000 });
        GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
        return;
      } 

      if (file.size > photoLimitSize) {
        setFileInputState('');
        enqueueSnackbar('File size limit is 10MB', { variant: 'default', autoHideDuration: 3000 });
        GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
        return;
      } 

      const fullFileName = file.name;
      const fileNameOnly = fullFileName.substr(0, fullFileName.lastIndexOf('.')) || fullFileName;
      const tempImage = window.DTF_APP.IMAGE_PATH+"public/image/users/"+userId+"/"+fileNameOnly;      

      if (user_image_profile === tempImage) {
        setFileInputState('');
        enqueueSnackbar('File already exists', { variant: 'default', autoHideDuration: 3000 });
        GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
        return;
      } 

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {        
        const objData = {
          data: reader.result,
          fileName: fileNameOnly
        } 
 
        deleteImageFromServer();
        
        UploadImageService(objData,userId,authToken).then(result => {
          if (!registerFlow) {
            setDescription(user_description);
            setHouseOf({title: user_house_name});
            setIsEdit(true);
          }
          setImageProfile(result.data.uploadedResponse.public_id);
          setImageProfileInformation(result.data.uploadedResponse);
          setFileInputState('');
          GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
        }).catch(err => {
          setFileInputState('');
          FormErrorContextDispatch({ type: "REQUEST_ERROR", payload: err.response.data });          
          GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }}); 
        })
      }     
    } catch (error) {
      console.log('catch',error);
      setFileInputState('');
      GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
    }
  };

  const deleteProfileImage = (e) => {
    //delete in edit mode 
    e.preventDefault();
    setFileInputState('');
    try {   
      if (user_image_profile !== '' && imageProfile !== '' && user_image_profile === imageProfile) {
        setDeleteImage(true);
        setDeleteImageName(imageProfile || user_image_profile);
      }  
      deleteImageFromServer();
    } catch (err) {
      console.log("catch3",err.response.data,err.response.status);    
    }  
    setImageProfile('');
    setImageProfileInformation('');     
  }   
  
  const deletePhotoEditMood = (e) => {
    e.preventDefault();
    setDescription(user_description);
    setHouseOf({title: user_house_name});
    setFileInputState('');
    setImageProfile('');
    setImageProfileInformation('');
    setDeleteImage(true);
    if(user_image_profile && user_image_profile !==''){
      setDeleteImageName(user_image_profile);
    }
    setIsEdit(true);
  }    

  return (
    <>
      <HelmetComponent title={"DTF - Basic Information"} />      
      <Container classes={{ root: classes.containerStyleWhite }} className={isPwaMobile ? " pwaMobile" : ""}>
        <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid 
            container
            justify="flex-start"
            direction="column"
            spacing={0}
            classes={{ root: classes.formGridStyle3 }}
          >
            {
              registerFlow
              ?
                <>
                  <Grid item>
                    <Typography variant={"h1"} classes={{ root: classes.headerDefaultStyle }}>
                      We need a few more basics
                    </Typography>   
                  </Grid>
                  <Grid item classes={{ root: classes.gridItemHeaderWrapper }}> 
                    <Typography classes={{ root: classes.bodyDefaultStyle }}>
                      {/* Lorem ipsum dolor eset sim artun */}
                    </Typography> 
                  </Grid>
                </>
              :
                <>
                  <Grid item>
                    <Typography variant={"h1"} classes={{ root: classes.headerDefaultStyle }}>
                      Profile Information
                    </Typography>   
                  </Grid>
                  <Grid item classes={{ root: classes.gridItemHeaderWrapper }}> 
                    <Typography classes={{ root: classes.bodyDefaultStyle }}>
                      {/* Lorem ipsum dolor eset sim artun */}
                    </Typography> 
                  </Grid>
                </>
            }            
            <Grid 
              item 
              container 
              justify="center"
              alignItems="center"
              spacing={2}
            > 
              <Grid item xs={12} classes={{ root: classes.photoGrid }}>
              { 
                imageProfile 
                ?
                  <div className={ classes.uploadPhotoWrapper }>
                    {/* <CloudinaryContext cloudName={"dtf2021"}>   
                      <Avatar classes={{ root: classes.profileAvatar }}>
                          <Image publicId={imageProfile} loading="lazy" alt={user_name} secure="true">
                            <Transformation gravity="face" height="100" width="100" crop="thumb" />
                          </Image>
                      </Avatar>
                    </CloudinaryContext> */}

                    <ImageProfileAvatar image={imageProfile} /> 
                    <ClearRoundedIcon classes={{ root: classes.deleteIcon }} onClick={(e) => deletePhotoEditMood(e)} />
                  </div>
                :
                  <>
                    <input
                      id="icon-button-photo"
                      type="file"
                      name="image"
                      accept="image/*"
                      onChange={handleFileInputChange}
                      value={fileInputState}
                      className="form-input"
                      hidden
                    />
                    <label htmlFor="icon-button-photo" className={classes.photoLabel}>
                      <CloudUploadOutlinedIcon color={"primary"} classes={{ root: classes.photoIconCloud }} />
                      <Typography variant="body1" classes={{ root: classes.photoText }}>
                          Upload Profile Photo
                      </Typography>
                    </label>
                  </> 
              }               
              </Grid>
              <Grid item xs={12}>    
                {/* <Typography classes={{ root: classes.bodyDefaultStyle }}>
                  Profile Description (300 characters max)
                </Typography> */}
                {
                  registerFlow || isEdit
                  ?
                    <TextField
                      fullWidth
                      placeholder="Profile Description (300 characters max)"
                      multiline
                      rows={1}
                      rowsMax={10}
                      classes={{ root: classes.textareaDefault, underline: classes.textareaUnderline }}
                      InputProps={{ className: classes.lineHeight30 }}
                      value={description} 
                      onChange={(e) => setDescription(e.target.value)}

                    />
                    // <Input type="text" classes={{ root: classes.inputDefault, underline: classes.inputUnderline }} fullWidth={true} placeholder="Profile description" inputProps={{ 'aria-label': 'Profile description', 'maxLength': '300' }} value={description} onChange={(e) => setDescription(e.target.value)} />
                  :                    
                    <Typography classes={{ root: classes.bodyFormDefaultStyle }} className={(user_description.length === 0 ? ' null' : '')}>
                      { user_description.length === 0 ? 'Profile Description (300 characters max)' : user_description }
                    </Typography>
                }
                <FormHelperText id="component-description-helper" classes={{ root: classes.formHelperText }}>({ registerFlow || isEdit ? description.length : user_description.length })</FormHelperText> 
              </Grid>
              <Grid item xs={12} classes={{ root: classes.marginWrapper }}> 
                <Typography classes={{ root: classes.bodyDefaultStyle }}>
                  What house do you belong to? (optional)
                </Typography>
                {
                  registerFlow || isEdit
                  ?
                    <Autocomplete
                      classes={{ inputRoot: classes.autoCompleteLineHeight }}
                      value={houseOf}
                      id="freeSolo-houseOf"
                      freeSolo
                      onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                          setHouseOf({
                            title: newValue,
                          });
                        } else if (newValue && newValue.inputValue) {
                          // Create a new value from the user input
                          setHouseOf({
                            title: newValue.inputValue,
                          });
                        } else {
                          if (newValue === null) return; 
                          setHouseOf(newValue);
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                
                        // Suggest the creation of a new value
                        if (params.inputValue !== '') {
                          filtered.push({
                            inputValue: params.inputValue,
                            title: `Add House "${params.inputValue}"`,
                          });
                        }
                
                        return filtered;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      options={HousesObj}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        // Regular option
                        return option.title;
                      }}
                      renderOption={(option) => option.title}
                      renderInput={(params) => <TextField {...params} />}
                    /> 
                  :                    
                    <Typography classes={{ root: classes.bodyFormDefaultStyle2 }}>
                      { user_house_name }
                    </Typography>
                } 
                
              </Grid>                           
              { 
                // user_image_profile !== '' && !isEdit && !registerFlow                       
                // ?
                //   <>
                //     <Grid item xs={4} classes={{ root: classes.photoGrid }}>
                //       <CloudinaryContext cloudName={"dtf2021"}>   
                //         <Avatar classes={{ root: classes.profileAvatar }}>
                //             <Image publicId={user_image_profile} alt={user_name} secure="true">
                //                <Transformation gravity="face" height="100" width="100" crop="thumb" />
                //             </Image>
                //         </Avatar>
                //       </CloudinaryContext>
                //       <ClearRoundedIcon classes={{ root: classes.deleteIcon }} onClick={(e) => deletePhotoEditMood(e)} />        
                //     </Grid>
                //     <Grid item xs={8} classes={{ root: classes.photoGrid }}> 
                //     </Grid>                    
                //   </> 
                // :
                //   null
              }
              {
                requestError.error
                ?
                  <Grid item xs={12} classes={{ root: classes.ErrorTextContainerStyle }}>
                    <Typography variant="body1" classes={{ root: classes.ErrorTextStyle }}>
                      { requestError.message }
                    </Typography>               
                  </Grid>
                :
                  null
              }                
            </Grid>        
            {
              registerFlow
              ?
                <Grid container item direction="row" justify="center" alignItems="flex-end" classes={{ root: classes.formGridButtonSubmit }}>
                  <SubmitButton isActive={isActive} />
                </Grid>
              :
                <Grid container item direction="row" justify="center" alignItems="flex-end" classes={{ root: classes.formGridButtonSubmit }}>
                  {
                    isEdit
                    ?
                      <>
                        <CloseButton isActive={true} handleCloseClick={handleCloseClick} />
                        <SubmitButton isActive={isActive} />
                      </>  
                    :
                      <EditButton isActive={true} handleEditClick={handleEditClick} />
                  }                  
                </Grid>
            } 
          </Grid>
        </form>
      </Container>
    </>
  );
}
