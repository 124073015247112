import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { feedImageAvatar2 } from '../styles/defaultAvatarStyles.2'; 

const useStyles = makeStyles((theme) => ({
    feedImageAvatar2: feedImageAvatar2
}));

export default function FeedImageAvatar(props) {
    const { image="", alt="", letter=false } = props;    
    const classes = useStyles();    

    if (letter === true) {
        return (
            <Avatar classes={{ root: classes.feedImageAvatar2 }}>{alt.charAt(0).toUpperCase()}</Avatar>
        )
    } else {
        return (        
            // <CloudinaryContext cloudName={"dtf2021"} className={ classes.feedAvatarWrapper }>   
            //     <Avatar classes={{ root: classes.feedImageAvatar }}>
            //         <Image publicId={user_image_profile} loading="lazy" alt={handlename}>
            //             {/* <Transformation rawTransformation={"c_thumb,h_50,r_50,w_50"} /> */}
            //             <Transformation gravity="face" height="50" width="50" crop="thumb" />
            //         </Image>
            //     </Avatar>
            // </CloudinaryContext>
            
            <Avatar classes={{ root: classes.feedImageAvatar2 }}>
                <img src={"/dtf2021/image/upload/c_thumb,g_face,h_40,w_40/v1/" + image} loading="lazy" alt={alt} />
            </Avatar>        
        );
    }    
}
