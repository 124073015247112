import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import VolumeOffRoundedIcon from '@material-ui/icons/VolumeOffRounded';
import VolumeUpRoundedIcon from '@material-ui/icons/VolumeUpRounded';

import { defaultVideoButton, defaultVideoIconButton } from '../styles/defaultButtonStyles.2';

const useStyles = makeStyles((theme) => ({
  defaultVideoButton: defaultVideoButton,
  defaultVideoIconButton: defaultVideoIconButton,
  MuteButton: {
    display: "block"
  }
}));

export default function MuteButton(props) {
  const { handleClick = (e) => { }, isActive=false, id="", muted=true } = props;
  const classes = useStyles();
  if (isActive) {
    return (    
      <button type="button" id={id} className={classes.MuteButton + " " + classes.defaultVideoButton} onClick={(e) => handleClick(e)}>
        {
          muted
          ?
            <VolumeOffRoundedIcon classes={{ root: classes.defaultVideoIconButton }} className={"active"} />
          :
            <VolumeUpRoundedIcon classes={{ root: classes.defaultVideoIconButton }} className={"active"} />
        }        
      </button>
    )
  } else {
    return (    
      <button type="button" id={id} className={classes.MuteButton + " " + classes.defaultVideoButton} disabled>
        {
          muted
          ?
            <VolumeOffRoundedIcon classes={{ root: classes.defaultVideoIconButton }} />
          :
            <VolumeUpRoundedIcon classes={{ root: classes.defaultVideoIconButton }} />
        }
      </button>
    )
  }  
}