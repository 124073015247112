// framer support for grid container
export const loginContainerStyle = {
  padding: "0",
  width: "100vw",
  height: "100vh",
  backgroundColor : "rgba(255,255,255,1)"
};

//containerStyle - account profile 3, user profile 3, search 2

export const containerStyle = {
  padding: "70px 0px 60px",
  width: "100vw",
  // height: "100vh",
  overflow: "hidden auto",
  // backgroundColor : "rgba(255,255,255,1)",
  color: "rgba(0,0,0,1)",
  boxSizing: "inherit",
  position: "relative",
  zIndex: "0",
  "&.pwaMobile": {
    // padding: "110px 0px"
    padding: "110px 0px 60px"
  }
};


export const swipeContainerStyle = {
  padding: "0px",
  width: "100vw",
  // height: "100vh",
  overflow: "hidden auto",
  // backgroundColor : "rgba(255,255,255,1)",
  color: "rgba(0,0,0,1)",
  boxSizing: "inherit",
  position: "relative",
  zIndex: "0",
  // "&.pwaMobile": {
  //   padding: "110px 0px 70px"
  // }
};

export const containerStyleWhite = {
  padding: "70px 0px 80px",
  width: "100vw",
  height: "100vh",
  overflow: "hidden auto",
  backgroundColor : "rgba(255,255,255,1)",
  color: "rgba(0,0,0,1)",
  boxSizing: "inherit",
  position: "relative",
  zIndex: "0",
  "&.pwaMobile": {
    padding: "110px 0px 80px"
  }
};

// containerStyleWhite2 - all files under registration folder

export const containerStyleWhite2 = {
  padding: "70px 0px 60px",
  width: "100vw",
  height: "100vh",
  // height: "auto",
  // minHeight: "calc(100vh)",
  overflow: "hidden auto",
  backgroundColor : "rgba(255,255,255,1)",
  color: "rgba(0,0,0,1)",
  boxSizing: "inherit",
  position: "relative",
  zIndex: "0",
  "&.pwaMobile": {
    // padding: "110px 0px"
    padding: "110px 0px 60px"
  }
};

// containerStyleWhite3 - Discovery page

export const containerStyleWhite3 = {
  padding: "90px 0px 80px",
  width: "100vw",
  height: "100vh",
  // height: "auto",
  // minHeight: "100vh",
  overflow: "hidden auto",
  backgroundColor : "rgba(255,255,255,1)",
  color: "rgba(0,0,0,1)",
  boxSizing: "inherit",
  position: "relative",
  zIndex: "0",
  "&.pwaMobile": {
    // padding: "110px 0px"
    padding: "142px 0px 80px"
  }
};

export const containerStyleWhiteSearch = {
  padding: "60px 0px 80px",
  width: "100vw",
  height: "100vh",
  overflow: "hidden auto",
  backgroundColor : "rgba(255,255,255,1)",
  color: "rgba(0,0,0,1)",
  boxSizing: "inherit",
  position: "relative",
  zIndex: "0",
  "&.pwaMobile": {
    padding: "110px 0px 80px"
  }
};

export const containerStyleWhiteMessages = {
  padding: "100px 0px 80px",
  width: "100vw",
  height: "100vh",
  // height: "auto",
  // minHeight: "100vh",
  overflow: "hidden auto",
  backgroundColor : "rgba(255,255,255,1)",
  color: "rgba(0,0,0,1)",
  boxSizing: "inherit",
  position: "relative",
  zIndex: "0",
  "&.pwaMobile": {
    // padding: "110px 0px"
    padding: "142px 0px 80px"
  }
};

export const containerGridStyle = {
  width: "100vw", 
  height: "100vh", 
  padding: "100px 0 0", 
  margin: "0"
}

// export const containerGridStyle2 = {
//   width: "100vw", 
//   height: "100vh", 
//   padding: "110px 0 0", 
//   margin: "0"
// }

export const videosContainerStyle = {
  // padding: "0 0 62px",
  // width: "100vw",
  // height: "100vh",
  overflow: "hidden",
  // backgroundColor : "rgba(0,0,0,1)",
  // color: "rgba(0,0,0,1)",
  // boxSizing: "inherit",
  // position: "relative",
  // zIndex: "0"
  position: "relative",
  width: "100vw",
  height: "100vh",
  scrollSnapType: "y mandatory",
  overflowY: "scroll",
  padding: "0",

}

export const videosCaptureContainerStyle = {
  overflow: "hidden",
  position: "relative",
  width: "100vw",
  height: "100vh",
  padding: "0",
}

export const subContainerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0",
  width: "100vw",
  // height: "100vh",
  height: "calc(100vh - 80px)",
  scrollSnapAlign:"start",
  overflow: "hidden",
  position: "relative",
};

// export const subContainerStyle = {
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
//   padding: "0",
//   width: "100vw",
//   height: "100%",
//   scrollSnapAlign:"start",
//   overflow: "hidden",
//   position: "relative",
// };

export const subContainerStyle2 = {
  width: "100vw",
  height: "calc(100vh - 75px)"
}

export const subContainerStyle3 = {
  minWidth: "100%", 
  minHeight: "100%",
  width: "auto",
  height: "auto",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
}

export const subItemContainerStyle = {
  padding: "0",
  width: "100vw",
  height: "auto",
  overflow: "hidden",
  backgroundColor : "rgba(255,255,255,1)",
  color: "rgba(0,0,0,1)",
  boxSizing: "inherit",
  position: "relative",
  zIndex: "0"
};

export const containerBlackStyle = {
  padding: "0",
  width: "100vw",
  height: "100vh",
  backgroundColor : "rgba(0,0,0,1)",
  color: "rgba(255,255,255,1)",
  boxSizing: "inherit",
  position: "relative",
  zIndex: "0"
};

export const splashContainerStyle = {
  padding: "0",
  width: "100vw",
  height: "100vh",
  backgroundColor : "rgba(0,0,0,1)"
};

export const inviteContainerStyle = {
  padding: "0",
  width: "100vw",
  height: "100vh",
  backgroundColor : "rgba(0,0,0,1)"
};

export const formGridStyle = {
  // height: "100vh", 
  height: "100%", 
  padding: "40px 20px 10px", 
  margin: "0"
}

export const formGridStyle2 = {
  height: "100vh",
  padding: "0px", 
  margin: "0"
}

export const formGridStyle3 = {
  // height: "100vh",
  height: "auto",
  padding: "20px", 
  margin: "0"
}

export const formGridButtonSubmit = {
  // position: "absolute", 
  // bottom: "calc(100vh - 100vh)", 
  // right: "0px"
  position: "relative",
  margin: "20px auto",
  justifyContent: "right",
  right: "-20px"
}

export const inputReadOnly = {
  color: "rgba(100,100,100,1)"
}

export const headerDefaultStyle = {
  color: "rgba(10,10,10,1)",
  fontWeight: "700",
  fontSize: "22px"
}

export const headerUpgradeStyle = {
  color: "rgba(10,10,10,1)",
  fontWeight: "700",
  fontSize: "22px",
  width: "auto",
  margin: "auto 30px",
  textAlign: "center"
}

export const bodyDefaultStyle = {
  fontSize: "16px",
  color: "rgba(60,60,60,1)",
  fontWeight: "400"
}

export const bodyFormDefaultStyle = {
  fontSize: "16px",
  color: "rgba(0,0,0,1)",
  fontWeight: "400",
  lineHeight: "30px",
  padding: "6px 0 7px",
  "&.null": {
    color: "rgba(60,60,60,0.5)"
  }
}

export const bodyFormDefaultStyle2 = {
  fontSize: "16px",
  color: "rgba(0,0,0,1)",
  fontWeight: "400",
  height: "auto",
  lineHeight: "35px",
  padding: "6px 0 7px"
}

export const bodyDefaultStyle2 = {
  fontSize: "16px",
  color: "rgba(60,60,60,1)",
  fontWeight: "400",
  display: "block",
  width: "inherit",
  textAlign: "center",
  margin: "20px auto"
}

export const bodyDefaultStyle3 = {
  fontSize: "16px",  
  color: "rgba(0,0,0,1)",
  fontWeight: "600"
}

export const bodyDefaultStyle4 = {
  fontSize: "16px",
  color: "rgba(112,112,112,1)",
  fontWeight: "400",
  margin: "5px 0 0 0"
}

export const bodyDefaultStyle5 = {
  fontSize: "14px",
  color: "rgba(0,0,0,1)",
  fontWeight: "400",
  "&.grayItalic": {
    color: "rgba(112,112,112,1)",
    fontStyle: "italic"
  }
}

export const bodyDefaultStyle6 = {
  fontSize: "16px",
  lineHeight: "30px",
  color: "rgba(0,0,0,1)",
  fontWeight: "400"
}

export const topNavigationDefaultStyle = {
  color: "rgba(60,60,60,1)",
  fontWeight: "200"
}

export const formGridMarginContent = {
  margin: "20px 0"
}

// export const imgCoverStyle = {
//     display: "block",
//     width: "100vw",
//     height: "100vh",
//     objectFit: "cover"
// }

export const imgCoverStyle = {
  top: "50%",
  left: "50%",
  minHeight: "100%",
  minWidth: "100%",
  position: "absolute",
  transform: "translate(-50%,-50%)",
  zIndex: "-1"
}

export const imgContainStyle = {
  display: "block",
  width: "100vw",
  height: "100vh",
  objectFit: "contain"
}

export const desciptionBar = {
  bottom: "90px",
  bottom: "105px",
  left: "0px",
  color: "rgba(255,255,255,1)",
  width: "80vw",
  height: "auto",
  maxHeight: "30vh",
  position: "absolute",
  backgroundColor: "transparent",
  overflow: "hidden"
}

export const desciptionBarNoBottomBar = {
  bottom: "30px",
  left: "0px",
  color: "rgba(255,255,255,1)",
  width: "80vw",
  height: "auto",
  maxHeight: "30vh",
  position: "absolute",
  backgroundColor: "transparent",
  overflow: "hidden"
}

export const sideBar = {
  padding: "0",
  margin: "0",
  color: "rgba(0,0,0,1)", 
  position: "absolute", 
  top: "0px", 
  right: "0px", 
  height: "auto", 
  width: "65px",
  zIndex: "1", 
  backgroundColor: "transparent",
  transition: "all 100ms linear",
  "-webkit-transition": "all 100ms linear",
  "-moz-transition": "all 100ms linear",
  "-o-transition": "all 100ms linear",
  "&.hideSideBar": {
    right: "-75px"
  }
}

export const sideBarWhite = {
  padding: "0",
  margin: "0",
  color: "rgba(255,255,255,1)", 
  position: "absolute", 
  top: "50px", 
  left: "0px", 
  height: "auto", 
  width: "100vw",
  zIndex: "1",
  backgroundColor: "transparent",
  transition: "all 100ms linear",
  "-webkit-transition": "all 100ms linear",
  "-moz-transition": "all 100ms linear",
  "-o-transition": "all 100ms linear",
  "&.hideSideBar": {
    right: "-75px"
  }
}

export const topBar = {
  padding: "0px",
  margin: "0",
  color: "rgba(0,0,0,1)", 
  position: "absolute", 
  top: "26px", 
  left: "0px", 
  height: "auto", 
  width: "100vw",
  zIndex: "2", 
  backgroundColor: "transparent",
  transition: "all 100ms linear",
  "-webkit-transition": "all 100ms linear",
  "-moz-transition": "all 100ms linear",
  "-o-transition": "all 100ms linear",
  "&.hideTopBar": {
    top: "-100px"
  }
}

export const topBarApp = {
  padding: "0px",
  margin: "0",
  color: "rgba(0,0,0,1)", 
  position: "absolute", 
  top: "0px", 
  left: "0px", 
  height: "auto", 
  width: "100vw",
  zIndex: "2", 
  backgroundColor: "transparent",
  transition: "all 100ms linear",
  "-webkit-transition": "all 100ms linear",
  "-moz-transition": "all 100ms linear",
  "-o-transition": "all 100ms linear",
  "&.hideTopBar": {
    top: "-100px"
  }
}

export const topBarWhite = {
  padding: "0px",
  margin: "0",
  color: "rgba(255,255,255,1)", 
  position: "absolute", 
  top: "26px", 
  right: "0px", 
  height: "auto", 
  width: "65px", 
  zIndex: "2",
  backgroundColor: "transparent",
  transition: "all 100ms linear",
  "-webkit-transition": "all 100ms linear",
  "-moz-transition": "all 100ms linear",
  "-o-transition": "all 100ms linear",
  "&.hideTopBar": {
    top: "-100px"
  }
}

export const topBarAppWhite = {
  padding: "0px",
  margin: "0",
  color: "rgba(255,255,255,1)", 
  position: "absolute", 
  top: "0px", 
  right: "0px", 
  height: "auto", 
  width: "65px", 
  zIndex: "2",
  backgroundColor: "transparent",
  transition: "all 100ms linear",
  "-webkit-transition": "all 100ms linear",
  "-moz-transition": "all 100ms linear",
  "-o-transition": "all 100ms linear",
  "&.hideTopBar": {
    top: "-100px"
  }
}

export const textShadow = {
  textShadow: "2px 2px rgba(0,0,0,1)"
}

export const textShadowBold = {
  textShadow: "2px 2px rgba(0,0,0,1)",
  fontWeight: "700"
}

export const textLink = {
  fontSize: "14px",
  textDecoration: "none"
}

export const textBoldLink = {
  fontSize: "14px",
  fontWeight: "700",
  textDecoration: "none"
}

export const deleteVideoWrapper = {
  position: "relative",
  height: "50px",
  background: "rgba(255,255,255,1)",
  display: "block",
  width: "inherit",
  textAlign: "center"
}

export const deleteTextButton = {
  textTransform: "none",
  fontSize: "15px",
  color: "rgba(98, 74, 251, 1)"
}

export const actionBottom = {
  position: "fixed",
  bottom: "0px",
  width: "100vw",
  borderTop: "1px solid rgba(174,175,178,1)",
  backgroundColor: "rgba(255,255,255,1)"
}

export const actionBottomWrapper = {
  position: "relative",
  height: "auto",
  background: "rgba(255,255,255,1)",
  display: "inline-block",
  width: "50%",
  padding: "15px",
  textAlign: "center"
}

export const actionButton = {
  border: "1px solid rgba(98, 74, 251, 1)",
  width: "100%",
  margin: "auto"
}

export const actionTextButton = {
  textTransform: "none",
  fontSize: "15px",
  color: "rgba(98, 74, 251, 1)"
}