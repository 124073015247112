import React, { useContext, useState, useEffect, useRef }  from 'react';
import { useHistory } from "react-router-dom";

import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import { swipeContainerStyle, containerGridStyle, formGridStyle } from '../styles/defaultStyles.2';
import { defaultGradientPurpleBtn } from '../styles/defaultButtonStyles.2';

import TopNavigation from './TopNavigation.2';
import SearchTagInput from './SearchTagInput';
import SearchLocationTagItem from './SearchLocationTagItem.3';
import InfiniteScroll from "./InfiniteScroll";

import { AuthContext } from '../contexts/AuthContext';
import { SearchContext } from '../contexts/SearchContext';
import { GlobalContext } from '../contexts/GlobalContext';

const useStyles = makeStyles((theme) => ({
  swipeContainerStyle: swipeContainerStyle,
  searchWrapper: {
    position: "fixed",
    top: "50px",
    width: "100vw",
    padding: "20px",
    background: "rgba(255,255,255,1)",
    zIndex: "10",
    "&.pwaMobile": {
      top: "90px"
    }    
  },
  resultContainer: {
    padding: "140px 0px 70px",
    overflowX: "hidden",
    overflowY: "auto",
    position: "relative",
    "&.pwaMobile": {
      padding: "180px 0px 70px"
    },
    "&.profile": {
      padding: "140px 0px 70px",
      "&.pwaMobile": {
        padding: "180px 0px 70px"
      }
    }
  },

  bannerWrapper: {
    position: "relative",
    display: "block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    height: "175px",
    width: "100%",
    border: "0px solid rgba(0,0,0,1)"
  },
  itemBannerWrapper: {
    position: "relative",
    display: 'inline-block',
    width: "96%",
    height: "145px",
    margin: "10px"
  },
  containerGridStyle: containerGridStyle,
  swipeContainerStyle: swipeContainerStyle, 
  formGridStyle: formGridStyle,
  defaultGradientPurpleBtn: defaultGradientPurpleBtn, 
  labelBtn: {
    fontWeight: '500',
    textTransform: 'none'
  },
  headerLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: 'rgba(20,24,34,1)',
    display: 'block',
    margin: '0 auto'
  },
  paragraphText: {
    display:'block',
    margin: '10px auto',
    fontSize: '14px',
    color: 'rgba(139,140,144,1)'
  },
  overlayGradientTop: {
    width: "100%",
    top: "140px",
    height: "50px",
    position: "fixed",
    background: "linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))",
    zIndex: "1"
  },
  overlayGradientBottom: {
    width: "100%",
    bottom: "0px",
    height: "50px",
    position: "fixed",
    background: "linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))",
    zIndex: "1"
  },
  padding20: {
    padding: "20px"
  }
}));

export default function SwipeTagLocation(props) {
  const classes = useStyles();
  const containerRef = useRef();
  const { GlobalContextState } = useContext(GlobalContext);
  const { isPwaMobile } = GlobalContextState;
  const { AuthContextState } = useContext(AuthContext);
  const { isAuthenticated } = AuthContextState;

  const { SearchContextState, SearchContextDispatch } = useContext(SearchContext);
  const { locationResult=[], searchPageCount=0, pageNo=0, resultLoaded=false, isLoading=false,  } = SearchContextState;

  const { isOwner=true, locationAnchor='right', locationBool=false, variant='temporary', onCloseHandler=()=>{} } = props;

  useEffect(() => {    
    // console.log('TagLocation RESET_SEARCH_INFO')
    SearchContextDispatch({ type: "RESET_SEARCH_INFO" });
  }, [])

  useEffect(() => {
    // console.log('locationResult',locationResult)
    if (locationResult.length !== 0) {
      if (!resultLoaded) {
        SearchContextDispatch({ type: "RESULT_LOADED" });
      }
    }
  }, [locationResult])

  const handleTag = (data) => {
    // console.log("handleTag",data);
  }

  const handleClick = (e) => {
    // console.log("handleClick",e);
    e.preventDefault();
  }

  const handleLoadNext = () => {
    // console.log('handleLoadNext',isLoading);
    if (!isLoading) {
      SearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading: true } });
      SearchContextDispatch({ type: "LOADNEXT", payload: { loadNext: true, searchType: 'location' } });
    }    
  }

  const [drawerState, setDrawerState] = useState(false);

  const closeDrawer = () => {
    // console.log('closeDrawer');
    onCloseHandler();
    setDrawerState(false);
  }

  useEffect(() => {    
    // console.log('useEffect locationBool', locationBool)   
    if (locationBool) {
      setDrawerState(true);
    }
  }, [locationBool])

  return (
    <SwipeableDrawer
      variant={variant} 
      anchor={locationAnchor}
      open={drawerState}
    >                     
      <Container classes={{ root: classes.swipeContainerStyle }} className={isPwaMobile ? " pwaMobile" : ""}> 
        <TopNavigation title={"Tag Location"} closeHandler={closeDrawer} />
        <div className={classes.searchWrapper + " " + (isPwaMobile ? " pwaMobile" : "")}>
          <SearchTagInput searchType={"location"} action={handleTag} />
        </div>
        <Grid 
          container
          direction="column"  
          classes={{ root: classes.resultContainer }} 
          ref={containerRef}    
          className={(isOwner ? " profile" : "") + (isPwaMobile ? " pwaMobile" : "")}
        >  
          {
            locationResult.length !== 0
            ?
              <>
                {
                  locationResult.map((item) => {
                    return <SearchLocationTagItem data={item} />
                  })
                }
                {
                  resultLoaded && !isLoading 
                  ?
                    <InfiniteScroll parentRef={containerRef} method={handleLoadNext} />
                  :
                    null
                }              
              </>
            :
              <Grid item xs={12} className={classes.padding20}>
                <Typography variant={"body2"} component={"span"} align="center" classes={{ root: classes.headerLabel }}>
                  Sea Places Near You
                </Typography>
                <Typography variant={"body2"} component={"span"} align="center" classes={{ root: classes.paragraphText }}>
                  To include nearby place, turn on Location Services.
                </Typography>
                <Button variant="outlined" color="primary" classes={{ root: classes.defaultGradientPurpleBtn, label: classes.labelBtn }} onClick={(e) => handleClick(e)}>
                  Turn on location services
                </Button>
              </Grid>
          }
          
        </Grid>
      </Container>          
    </SwipeableDrawer>
  );
}