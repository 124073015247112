import React, {useState, useContext, useEffect} from 'react';
import { useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import SendIcon from '@material-ui/icons/Send';
import InsertEmoticonRoundedIcon from '@material-ui/icons/InsertEmoticonRounded';
import FacePurple from './svg/FacePurple';
import SendPurple from './svg/SendPurple';
import SendGray from './svg/SendGray'
import CloseCircleGray from './svg/CloseCircleGray';
import { DrawerCommentContext } from '../contexts/DrawerCommentContext'; 

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "10px 0 20px",
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "inherit",
    height: "40px",
    backgroundColor: "transparent",
  },
  inputWrapper: {
    flex: 1,
    backgroundColor: "rgba(241,241,241,1)",
    position: "relative"
  },
  input: {
    marginLeft: theme.spacing(1),
    padding: "0px",
    width: "85%"
  },
  iconButton: {
    padding: 10,
  },
  faceIconButton: {
    margin: "10px",
    padding: "10px",
    height: "20px",
    width: "20px",
    borderRadius: "20px",
    "& > span": {
      width: "inherit",
      height: "inherit"
    }
  },
  clearIconButton: {
    margin: "0 0 0 5px",
    padding: "0",
    height: "20px",
    width: "20px",
    borderRadius: "20px",
    backgroundColor: "rgba(204,204,204,1)",
    "& > span": {
      width: "inherit",
      height: "inherit"
    }
  },
  sendIconButton: {
    margin: "10px",
    padding: "10px",
    height: "20px",
    width: "20px",
    borderRadius: "20px",
    "& > span": {
      width: "31px",
      height: "31px",
      position: "relative",
      top: "-3px"
    }
  },
  sendIconSVG: {
    top: '-3px',
    position: 'relative'
  }
}));


export default function CommentInput(props) {
  const classes = useStyles();
  const { DrawerCommentContextState, DrawerCommentContextDispatch } = useContext(DrawerCommentContext);
  const { videoId = "", currentIndex = null } = DrawerCommentContextState;
  const [ comment, setComment ] = useState("")
  const { label="Say something nice" } = props;

  const handleComment = (e) => { 
    console.log("handleComment", comment, comment.length);   
  }

  const handleClear = (e) => {    
    e.preventDefault();
    setComment("");
  }

  const handleEnter = (e) => {    
    // console.log("handleEnter", e, e.keyCode);    
    // console.log("handleEnter comment", comment, comment.length);  
    if (e.keyCode === 13) {
      e.preventDefault();
      // console.log('videoId', videoId);
      DrawerCommentContextDispatch({ type: "POST_COMMENT", payload: { comment: comment, videoId: videoId, post: true }});
      DrawerCommentContextDispatch({ type: "ADD_COMMENT", payload: { currentIndex: currentIndex, isAdd: true }});
      setComment("");      
    }
  }

  const handleClickButton = (e) => { 
    e.preventDefault();
    DrawerCommentContextDispatch({ type: "POST_COMMENT", payload: { comment: comment, videoId: videoId, post: true }});
    DrawerCommentContextDispatch({ type: "ADD_COMMENT", payload: { currentIndex: currentIndex, isAdd: true }});
    setComment("");
  }

  return (
    <Paper component="form" className={classes.root} elevation={0} onKeyDown={(e) => handleEnter(e)}>
      <IconButton className={classes.faceIconButton} aria-label="comment">
        <FacePurple />
        {/* <img src={"/svg/face-purple.svg"} alt={'Comment'} /> */}
      </IconButton>
      <div className={ classes.inputWrapper }>
        <InputBase
          className={classes.input}
          placeholder={label}
          inputProps={{ 'aria-label': label }}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        {
          comment !== ""
          ?
            <IconButton type="button" className={classes.clearIconButton} aria-label="clear" onClick={(e) => handleClear(e)}> 
              <CloseCircleGray />
              {/* <img src={"/svg/close-circle-gray.svg"} alt={'Comment'} /> */}
            </IconButton>
          :
            null
        }   
      </div>   
      <IconButton type="button" className={classes.sendIconButton} aria-label="submit" onClick={(e) => handleClickButton(e)}>
        {
          comment !== ""
          ?
            <SendPurple />
            // <img src={"/svg/send-purple.svg"} alt={'Comment'} className={ classes.sendIconSVG } />
          :
            <SendGray />
            // <img src={"/svg/send-gray.svg"} alt={'Comment'} className={ classes.sendIconSVG } />
        }        
      </IconButton>
    </Paper>
  );
}
