import React from 'react';

export default class Spinner extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="54.408" height="54.408" viewBox="0 0 54.408 54.408">
                <g id="loader-outline" transform="translate(-2 -2)">
                    <g id="Layer_2" data-name="Layer 2" transform="translate(2 2)">
                        <g id="loader">
                            <path id="Path_31" data-name="Path 31" d="M13.72,2A2.72,2.72,0,0,0,11,4.72v5.441a2.72,2.72,0,1,0,5.441,0V4.72A2.72,2.72,0,0,0,13.72,2Z" transform="translate(13.484 -2)" fill="#624afb"/>
                            <path id="Path_32" data-name="Path 32" d="M26.161,11H20.72a2.72,2.72,0,1,0,0,5.441h5.441a2.72,2.72,0,1,0,0-5.441Z" transform="translate(25.527 13.484)" fill="#624afb"/>
                            <path id="Path_33" data-name="Path 33" d="M12.882,13.72A2.72,2.72,0,0,0,10.161,11H4.72a2.72,2.72,0,1,0,0,5.441h5.441A2.72,2.72,0,0,0,12.882,13.72Z" transform="translate(-2 13.484)" fill="#624afb"/>
                            <path id="Path_34" data-name="Path 34" d="M9.156,5.476a2.752,2.752,0,0,0-3.781,4l3.917,3.781a2.826,2.826,0,0,0,3.945-.082,2.72,2.72,0,0,0,0-3.836Z" transform="translate(2.324 2.685)" fill="#624afb"/>
                            <path id="Path_35" data-name="Path 35" d="M18.636,13.923a2.72,2.72,0,0,0,1.877-.762L24.43,9.38a2.72,2.72,0,0,0-3.673-4L16.84,9.244a2.769,2.769,0,0,0,1.8,4.679Z" transform="translate(22.17 2.78)" fill="#624afb"/>
                            <path id="Path_36" data-name="Path 36" d="M13.72,18A2.72,2.72,0,0,0,11,20.72v5.441a2.72,2.72,0,1,0,5.441,0V20.72A2.72,2.72,0,0,0,13.72,18Z" transform="translate(13.484 25.527)" fill="#624afb"/>
                            <path id="Path_37" data-name="Path 37" d="M20.647,16.623a2.722,2.722,0,0,0-3.781,3.917L20.783,24.4a2.741,2.741,0,0,0,3.836-3.917Z" transform="translate(22.145 21.844)" fill="#624afb"/>
                            <path id="Path_38" data-name="Path 38" d="M9.256,16.571,5.339,20.353a2.754,2.754,0,0,0,3.781,4l3.917-3.781a2.722,2.722,0,0,0-3.781-3.917Z" transform="translate(2.36 21.895)" fill="#624afb"/>
                        </g>
                    </g>
                </g>
            </svg>     
        )
    }
}