import React, { useContext, useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from "react-router-dom";
import HelmetComponent from "../../components/HelmetComponent";
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { AuthContext } from '../../contexts/AuthContext';
import { videosContainerStyle } from '../../styles/defaultVideoStyles';
import FeedVideoItem from "../../components/FeedVideoItem.2";
import FeedItem from  "../../components/FeedItem.7";
import InfiniteScroll from "../../components/InfiniteScroll";
import { DrawerCommentContext } from '../../contexts/DrawerCommentContext';
import { vh } from '../../constants/VideoSettings';
import { GetAllLatestVideoByUserIdServiceWithFiltersPublic, GetActiveVideoByVideoIdService } from '../../services/VideoServices';

const useStyles = makeStyles((theme) => ({
  videosContainerStyle: videosContainerStyle
}));

function FeedsPublicListVideos() {
  const classes = useStyles();
  const videosContainerRef = useRef();  
  const feedVideoRef = useRef();
  const { userProfileId="", videoId=null, filter="all",  } = useParams();
  const { AuthContextState } = useContext(AuthContext);
  const { isAuthenticated, userId, authToken } = AuthContextState;
  const { DrawerCommentContextState } = useContext(DrawerCommentContext);
  const { indexUpdateCommentCount = [] } = DrawerCommentContextState;
  const [ videos, setVideos ] = useState([]);
  const [ pageCount, setPageCount ] = useState(1);
  const [ pageNo, setPageNo ] = useState(0);
  const [ itemPerPage, setItemPerPage ] = useState(2); 
  const [ userClick, setUserClick ] = useState(false);
  const [ isMuted, setIsMuted ] = useState(true);
  const [ currentSrc, setCurrentSrc ] = useState(null);
  const [ isCover, setIsCover ] = useState(null);
  const [ toggleLike, setToggleLike ] = useState(false);

  const toggleSound = (bool) => {
    console.log('toggleSound', bool);
    if (!userClick) {
      console.log('toggleSound1', bool);
      setIsMuted(bool, setUserClick(true));
    } else {
      console.log('toggleSound2', bool);
      setIsMuted(bool);
    }    
  }

  const loadNext = () => {
    console.log("loadNext", pageCount, pageNo, videos.length);
    if (pageCount > pageNo && videos.length !== 0) {
      console.log("loadNext - calling", pageCount, pageNo, videos.length);
      GetAllLatestVideoByUserIdServiceWithFiltersPublic({userId: userProfileId, pageNo: pageNo, itemPerPage: itemPerPage, filter: filter, videoId: videoId, authToken: authToken}).then(result => {
        if (result.data.success === 1) {
          setPageCount(result.data.userVideos.pageCount);
          setVideos(videos.concat(result.data.userVideos.videos));
        }
        setPageNo(pageNo + 1); 
      }).catch(err => {
          console.log("catch GetAllLatestVideoByUserIdServiceWithFiltersPublic",err);
      });
    }
  }

  useEffect(() => {
    
    if (videoId !== "") {
      GetActiveVideoByVideoIdService({videoId: videoId}).then(result => {
        setVideos(result.data.userVideos.videos);          
      }).catch(err => {
          console.log("catch GetActiveVideoByVideoIdService",err);
      })  
    } else {
      GetAllLatestVideoByUserIdServiceWithFiltersPublic({userId: userProfileId, pageNo: pageNo, itemPerPage: itemPerPage, filter: filter, videoId: videoId, authToken: authToken}).then(result => {
        if (result.data.success === 1) {
          setVideos(result.data.userVideos.videos);
          setPageCount(result.data.userVideos.pageCount);
        }
        setPageNo(pageNo + 1); 
      }).catch(err => {
          console.log("catch GetAllLatestVideoByUserIdServiceWithFiltersPublic",err);
      })
    }   
  }, [])  

  return (
    <>
      <HelmetComponent title={"DTF - Videos"} />
      <Container ref={videosContainerRef} classes={{ root: classes.videosContainerStyle }}>   
        {
          videos.length !== 0
          ?
            videos.map((item, index) => {
              return (
                <FeedItem setToggleLike={setToggleLike} toggleLike={toggleLike} setIsCover={setIsCover} setCurrentSrc={setCurrentSrc} itemRef={feedVideoRef} isMuted={isMuted} userClick={userClick} hideBackButton={false} indexUpdateCommentCount={indexUpdateCommentCount.length !== 0 ? indexUpdateCommentCount : []} key={'video-'+index} video={item} index={index} firstItem={index===0 ? true : false} parentRef={videosContainerRef} />
              )
            })
          :
            null            
        }
        <FeedVideoItem itemRef={feedVideoRef} source={currentSrc} toggleSound={toggleSound} setToggleLike={setToggleLike} toggleLike={toggleLike} />
        <InfiniteScroll parentRef={videosContainerRef} method={loadNext} margin={vh + "px"} />
      </Container> 
    </>
  );
}

export default FeedsPublicListVideos;