export const VideosInitialState = {
    videos: {
        premiumCount: 0,
        count: 0,
        videos: []
    },
    publicVideos: {
        premiumCount: 0,
        count: 0,
        videos: []
    },
    premiumVideos: {
        premiumCount: 0,
        count: 0,
        videos: []
    }
}
