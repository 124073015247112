export const videoTopBar = {
  padding: "0px",
  margin: "0",
  color: "rgba(0,0,0,1)", 
  position: "absolute", 
  top: "0px", 
  left: "0px", 
  height: "auto", 
  width: "100vw",
  zIndex: "2", 
  backgroundColor: "transparent",
  transition: "all 100ms linear",
  "-webkit-transition": "all 100ms linear",
  "-moz-transition": "all 100ms linear",
  "-o-transition": "all 100ms linear",
  "&.hideTopBar": {
    top: "-100px"
  },
  "&.pwa": {
    top: "40px"
  },
  "&.hideTopBar.pwa": {
    top: "-100px"
  },
}