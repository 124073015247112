export const accessCamera = async (MediaStreamConstraints) => {
    let stream = null;
    try {
        console.log('MediaStreamConstraints',MediaStreamConstraints);
        //check media devices
        if (navigator.mediaDevices === undefined) {
            navigator.mediaDevices = {};
            navigator.mediaDevices.getUserMedia = function(videoSettings) {
                let getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
                if (!getUserMedia) {
                    return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
                }
                return new Promise(function(resolve, reject) {
                    getUserMedia.call(navigator, videoSettings, resolve, reject);
                });
            }
        } else {
            // navigator.mediaDevices.enumerateDevices()
            // .then(devices => {
            //     devices.forEach(device=>{
            //         console.log(device.kind.toUpperCase(), device.label);
            //         //, device.deviceId
            //     })
            // })
            // .catch(err=>{
            //     console.log(err.name, err.message);
            // })
        }

        stream = await navigator.mediaDevices.getUserMedia( MediaStreamConstraints );
        return stream;
  
    } catch(err) {
        console.log("Error getUserMedia");
        return stream;
    }
}