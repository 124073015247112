import React, {useContext, useEffect, useRef, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { subContainerStyle } from '../styles/defaultStyles';
import { feedImageElement, feedImageElementCover3, feedImageElementBackground } from '../styles/defaultFeedWrapperStyles';
import { AuthContext } from '../contexts/AuthContext';
import { useFeedScrollItem } from '../utils/useFeedScrollItem.2';
import { UpdateVideoViewsByVideoId } from '../services/VideoServices';

const useStyles = makeStyles((theme) => ({
  subContainerStyle: subContainerStyle,
  feedImageElement: feedImageElement,
  feedImageElementCover3: feedImageElementCover3,
  feedImageElementBackground: feedImageElementBackground,
  videoInit: {
    // width: "100vw",
    // height: "calc(100vh - 80px)",
    width: "100%",
    height: "100%",
    minHeight: "100%",
    opacity: 0,
    zIndex: 2,
    position: "absolute",
    visibility: "hidden",
    transition: "opacity 100ms",
    "&.showVideo": {
      opacity: 1,
      visibility: "visible"
    },
    "& > video": {
      position: "absolute",
      transform: "translate(-50%, -50%)",
      top: "50%",
      left: "50%",
      width: "100vw",
      height: "auto",
      zIndex: 3
    },
    "&.cover > video": {
      objectFit: "cover",
      // width: "100vw",
      // height: "100%"
      width: "100%",
      height: "100%",
      minHeight: "100%",
      position: "absolute",
      transform: "unset",
      top: "unset",
      left: "unset",
      zIndex: 3
    },
    "&.facingMode": {
      // transform: 'scale(-1,1)',
      transform: "rotateY(180deg)",
      "-webkit-transform": "rotateY(180deg)",
      "-moz-transform": "rotateY(180deg)"
    }
  },
  iframeDom: {
    width: "100vw",
    height: "calc(100vh - 80px)",
    overflow: "hidden",
    border: "none"
  }
}));

function FeedSource(props) {
  const classes = useStyles();
  const iframeRef = useRef();
  const { userInformation={}, itemRef=null, isCoverElement=false, setCurrentPosterSrc=null, setCurrentSrc=null, video, parentRef, firstItem, index=0, useRefVideo=null, toggleSound=null, isMuted=true, userClick=false, videoElem=null } = props; 
  const { AuthContextState } = useContext(AuthContext);
  const { isAuthenticated, authToken } = AuthContextState;  
  
  const userInformationPlaceHolder = { _id: "", isFollow: false, isSubscribe: false, user_image_profile: "", isPlaceHodler: true };
  const { _id: userInfoId, isFollow=false, isSubscribe=false, user_image_profile="", isPlaceHodler=false } = userInformation || userInformationPlaceHolder;
    
  const [ showVideo, setShowVideo ] = useState(false);

  const srcVideoImageUrl = "/dtf2021/video/upload/so_0/v1/" + video.video_src + '.jpg';
  const srcImageUrl = "/dtf2021/image/upload/so_0/v1/" + video.video_src + '.jpg';
  // const srcUrl = "/dtf2021/video/upload/c_limit,h_1024,w_768/v1/" + video.video_src + '.mp4';
  const srcUrl = "/dtf2021/video/upload/c_limit,h_1024,w_768/" + (!isAuthenticated || !isSubscribe && video.premium ? "du_10/" : "") + "v1/" + video.video_src + ".mp4";
  // const srcUrl = "/dtf2021/video/upload/b_blurred:1000,c_pad,h,h_1024,w_768/v1/" + video.video_src + '.mp4';
  // const srcUrl = "/dtf2021/video/upload/b_blurred:1000,c_pad,h,h_1024,w_768/" + (isAuthenticated ? "du_10/" : "") + "v1/" + "video.video_src + '.mp4';
  // console.log('props.children 2', props.children)
  
  const updateViewCount = () => {
    if (isAuthenticated) {
      UpdateVideoViewsByVideoId({videoId: video._id}, authToken).then(result => {
      }).catch(err => {
          console.log("catch UpdateVideoViewsByVideoId",err);
      });
    }    
  }
  
  const inView = () => {    
    if (!showVideo) {
      try {                
        updateViewCount();
        setCurrentSrc(srcUrl, setShowVideo(true));
        // console.log('srcVideoImageUrl', srcVideoImageUrl);        
        // setCurrentPosterSrc(srcVideoImageUrl, setCurrentSrc(srcUrl));

        const wrapperVid = iframeRef.current;        
        const vid = itemRef.current;
        if (wrapperVid) {
          wrapperVid.appendChild(vid);
          const timer = setTimeout(() => {          
            try {           
              vid.play();
            } catch (err) {
              console.log('err onPlaying', err)
            } 
          }, 500);
          return () => clearTimeout(timer); 
        }

        
      } catch (err) {
        console.log('err', err)
      } 
    }
  }

  const notInView = () => {    
    if (showVideo) {
      try { 
        console.log('notInView removing', video._id, index, firstItem, showVideo)
        setShowVideo(false);
        const wrapperVid = iframeRef.current;
        wrapperVid.classList.remove('showVideo');
        if (wrapperVid) {          
          wrapperVid.innerHTML = '';
        }
      } catch (err) {
        console.log('err', err)
      }   
    } 
  }

  const imageView = () => {
    console.log('imageView', video._id, index, firstItem, showVideo);
    // if (showVideo) {
      try { 
        updateViewCount();
        setCurrentSrc(null);
        setCurrentPosterSrc(null);
        // if (video.video_information.height > video.video_information.width) {
        //   setIsCover(true);
        //   setIsCoverElement(true);
        // } else {
        //   setIsCover(false);
        //   setIsCoverElement(false);
        // }
      } catch (err) {
        console.log('err', err)
        // setIsCover(false);
        // setIsCoverElement(false);
      }   
    // } 
  }

  const listener = useFeedScrollItem(parentRef, iframeRef, () => inView(), () => notInView(), () => imageView(), "0px", firstItem, video.video_information ? video.video_information.resource_type : null);
  return (
    <>
      <img
        className={ classes.feedImageElementBackground + (video.isFacingMode ? " facingMode" : "")}  
        src={ video.video_information && video.video_information.resource_type === "video" ? srcVideoImageUrl : srcImageUrl }
      />
      <img
        className={ isCoverElement ? classes.feedImageElementCover3 : classes.feedImageElement + (video.isFacingMode ? " facingMode" : "") }  
        src={ video.video_information && video.video_information.resource_type === "video" ? srcVideoImageUrl : srcImageUrl }
      />    
      <div ref={iframeRef} className={ classes.videoInit + (isCoverElement ? " cover" : "") + (video.isFacingMode ? " facingMode" : "") } /> 
      
      {/* <img
        className={ classes.feedImageElement }  
        src={ video.video_information.resource_type === "video" ? srcVideoImageUrl : srcImageUrl }
      /> */}
      {/* <div ref={iframeRef} className={ classes.videoInit + " parentWrapper" } />     */}

      {/* { 
        video.video_information.resource_type === "video" 
        ?
          <div ref={iframeRef} className={ classes.videoInit + (showVideo ? " showVideo" : "" ) } /> 
        :
          <img
            ref={iframeRef}
            className={ classes.feedImageElement }  
            src={ video.video_information.resource_type === "video" ? srcVideoImageUrl : srcImageUrl }
          />
      } */}
      
    </>        
  )  
}

export default React.memo(FeedSource);