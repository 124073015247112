export const DrawerCommentInitialState = {
    initialCommnentLoad: true,
    showDrawerComment: false,    
    comment: "",
    commentId: "",
    videoId: "",
    deleteComment: false,
    unLikeComment: false,
    likeComment: false,
    post: false,
    get: false,
    getInfinite: false,
    comments: [],
    pageNo: 0,
    itemPerPage: 10,
    commentsCount: 0,
    pageCount: 0,
    currentIndex: null,
    indexUpdateCommentCount: []    
}