import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import LinkText from './LinkText.2';
import { containerStyle, containerGridStyle } from '../styles/defaultStyles';
import { gridItemWrapper } from '../styles/defaultGridStyles';
import { searchItemAvatar } from '../styles/defaultAvatarStyles'; 
import { defaultBtn, defaultLabelBtn, defaultActiveBtn } from '../styles/defaultButtonStyles';
import { listWrapper } from '../styles/defaultListStyles';
import { listText, listTextBold } from '../styles/defaultTextStyles';
import { CloudinaryInfo } from "../constants/CloudinaryInfo";
import ImageAvatar from '../components/ImageAvatar.2';

const useStyles = makeStyles((theme) => ({  
  containerGridStyle: containerGridStyle,
  containerStyle: containerStyle,
  gridItemWrapper: gridItemWrapper,
  searchItemAvatar: searchItemAvatar,
  listWrapper: listWrapper,
  listText: listText,
  listTextBold: listTextBold,
  defaultBtn: defaultBtn,
  defaultLabelBtn: defaultLabelBtn,
  defaultActiveBtn: defaultActiveBtn,
  searchItemContainer: {
    padding: "10px",
    cursor: "pointer"
  },
  searchItemTextContainer: {
    margin: "6px 10px"
  }
}));

export default function SearchPeopleItem(props) {
  const classes = useStyles();
  const { data, index } = props;  
  const { _id="", username="", handlename="", user_information="" } = data;  
  const user_information_placeholder = { user_image_profile: "", isPlaceHodler: true };
  const { user_image_profile="", isPlaceHodler=false } = user_information || user_information_placeholder;

  return (
    <> 
      <LinkText url={`/user/${handlename}`} underline={false}>
        <ListItem key={"people-item-" + _id} classes={{ root: classes.searchItemContainer }}>
          <ListItemAvatar>
            {
              user_image_profile !== "" && user_image_profile !== null
              ?
                <ImageAvatar image={user_image_profile} alt={username} />
              :
                <ImageAvatar letter={true} alt={handlename} />
            }
          </ListItemAvatar>
          <ListItemText 
            primary={username} 
            secondary={"@" + handlename} 
            classes={{
              root: classes.searchItemTextContainer,
              primary: classes.listTextBold,
              secondary: classes.listText, 
            }}
          /> 
        </ListItem>
      </LinkText>
    </>   
  );
}