import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import SearchIconBigPurple from './svg/SearchIconBigPurple';
import { nullWrapper, nullHeader, nullSubHeader, nullLinkText } from '../styles/defaulTabNullCase';

const useStyles = makeStyles((theme) => ({
  nullWrapper: nullWrapper,
  nullHeader: nullHeader, 
  nullSubHeader: nullSubHeader, 
  nullLinkText: nullLinkText
}));

export default function ActivitiesNullCase(props) {
  const classes = useStyles();
  const { isOwner=false, text="You have 0 activties"} = props; 
  return (
    <Grid item xs={12} key={"video-grid-null"}>
      <div className={ classes.nullWrapper }>
        <SearchIconBigPurple />
        <Typography variant={"h2"} align="center" classes={{ root: classes.nullHeader }}>
          {text}
        </Typography>
      </div>
    </Grid>
  );
}
