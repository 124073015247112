export const VideoServiceContextReducer = (state, action) => {
    switch(action.type){
        case 'SET_INIT_VIDEO_DATA': 
            return {
                ...state,
                data: action.payload.data,
                fileName: action.payload.fileName,
                isPrivate: action.payload.isPrivate,
                videoId: action.payload.videoId,
                isVideo: action.payload.isVideo,
                isUploading: action.payload.isUploading  
            }
        case 'SET_VIDEO_DATA':
            return { 
                ...state,
                data: action.payload.data,
                fileName: action.payload.fileName,
                isPrivate: action.payload.isPrivate,
                videoId: action.payload.videoId,
                isVideo: action.payload.isVideo,
                isUploading: action.payload.isUploading 
            }
        case 'UPDATE_VIDEO_DATA_IS_UPLOADING':
            return { 
                ...state,
                isUploading: action.payload.isUploading 
            }    
        default:
            return state    
    }
}