import React from 'react';
import CameraWhite from './svg/CameraWhite';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  default : {
    width: "20px",
    display: "inline-block",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    opacity: "0.75",
    "& > svg": {
      width: "inherit",
      height: "auto"
    }
  }
}));

export default function CameraIcon(props) {
  const classes = useStyles();
  return (
    <span className={classes.default}>
        <CameraWhite />
    </span>
  );
}