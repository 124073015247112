import React, { Component, createContext } from "react";

export const FramerContext = createContext();

class FramerContextProvider extends Component {
    state = {
        pageLoadInitial: true,
        pageDirection: true,
        pageDefualt: {
            initial: {
                opacity: 1,
                x: 0,
                overflowX: "hidden",
                overflowY: "auto"
                // scale: 0.8
            },
            in: {
                opacity: 1,
                x: 0,
                transitionEnd: {
                  overflowX: "hidden",
                  overflowY: "auto"
                }
                // scale: 1
            },
            out: {
                opacity: 1,
                x: 0,
                overflowX: "hidden",
                overflowY: "auto"
                // scale: 1.2
            }
        },  
        pageLtr: {
            initial: {
                opacity: 1,
                x: "100vw",
                overflowX: "hidden",
                overflowY: "hidden"
                // scale: 0.8
            },
            in: {
                opacity: 1,
                x: 0,
                transitionEnd: {
                  overflowX: "hidden",
                  overflowY: "auto"
                }
                // scale: 1
            },
            out: {
                opacity: 1,
                x: "-100vw",
                overflowX: "hidden",
                overflowY: "hidden"
                // scale: 1.2
            }
        },    
        pageRtl: {
            initial: {
                opacity: 1,
                x: "-100vw",
                overflowX: "hidden",
                overflowY: "hidden"
                // scale: 0.8
            },
            in: {
                opacity: 1,
                x: 0,
                transitionEnd: {
                  overflowX: "hidden",
                  overflowY: "auto"
                }
                // scale: 1
            },
            out: {
                opacity: 1,
                x: "100vw",
                overflowX: "hidden",
                overflowY: "hidden"
                // scale: 1.2
            }
        },       
        pageTransition: {
            type: "tween",
            ease: "anticipate",
            duration: 0.35
        },          
        pageStyle: {
            position: "absolute"
        }
    }

    toggleDirection = (bool, animateBool=false) => {
        console.log("toggleDirection",bool,animateBool,this.state)          
        let that = this;
        if (animateBool) {
            if(this.state.pageLoadInitial) {
                this.setState({ 
                    pageDirection: bool, 
                    pageLoadInitial: false 
                })
            } else {
                this.setState({ 
                    pageDirection: bool 
                })
            }
        }
    }

    changePageLoadInitial = (bool = false) => {
        this.setState({ pageLoadInitial: bool })
    }

    render(){
        return (
            <FramerContext.Provider value={{ state: this.state, toggleDirection: this.toggleDirection, changePageLoadInitial: this.changePageLoadInitial }}>
                {this.props.children}
            </FramerContext.Provider>
        );
    }
}
export default FramerContextProvider;