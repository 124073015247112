import React, { useContext, useState } from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import ReportOutlinedIcon from '@material-ui/icons/ReportOutlined';
import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import LinkText from "./LinkText.4";
import MenuLinkText from "./MenuLinkText";
import LinkTextForwardArrow from "./LinkTextForwardArrow";
import DialogModal from "./DialogModal";
import MenuTopBar from "./MenuTopBar";

import ForwardArrowBlack from './svg/ForwardArrowBlack';

import { FramerContext } from '../contexts/FramerContext';
import { GlobalContext } from '../contexts/GlobalContext';
import { AuthContext } from '../contexts/AuthContext';
import { UserContext } from '../contexts/UserContext';
import { VersionContext } from '../contexts/VersionContext';
import { DestroySession } from '../services/AuthServices';
import { DeleteUserService } from '../services/UserServices';

import { isPWA, isMobile } from "../utils/CommonUtil";

const useStyles = makeStyles((theme) => ({
  list: {
    width: "100vw",
    marginBottom: "50px"
  },
  displayFlex: {
    display: 'flex'
  },
  colorBlack: {
    color: 'rgba(0,0,0,1)'
  },
  logout: {
    color: 'rgba(0,0,0,1)',
    transform: 'scale(-1,1)'
  },
  colorPinkLogout: {
    color: 'rgba(0,0,0,1)'
  },
  topBrowser: {
    top: "0px"
  },
  topApp: {
    top: "40px"
  },
  paddingTop60: {
    paddingTop: "60px"
  },
  fontSize16: {
    fontSize: "16px"
  }
}));

export default function Menu(props) {
  const classes = useStyles();
  let history = useHistory();
  const { open=false, handleToggle=() => {} } = props;
  const { state, toggleDirection } = useContext(FramerContext); 
  const { AuthContextState, AuthContextDispatch } = useContext(AuthContext);
  const { UserContextState, UserContextDispatch } = useContext(UserContext);
  const { isAuthenticated, authToken, userId, userType } = AuthContextState;     
  const { GlobalContextDispatch } = useContext(GlobalContext); 
  const { VersionContextState, VersionContextDispatch } = useContext(VersionContext);
  const { versionNumber, newVersion } = VersionContextState; 
  const [ dialogOpen, setDialogOpen ] = useState(false);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const deleteAccount = (e) => {
    GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : true }});  
    DeleteUserService(userId,authToken).then(result => {
      AuthContextDispatch({ type: "SIGN_OFF" });   
      UserContextDispatch({ type: "SIGN_OFF" });     
      toggleDirection(true,true);    
      history.push('/videos');
      GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }}); 
    }).catch(err => {
      console.log('logout error',err);
      setDialogOpen(false);
      GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});   
    })
  }

  const handleDeleteAccountClick = (e) => {
    setDialogOpen(true);
  }

  const handleLogOutClick = (e) => {
    GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : true }});  
    DestroySession(authToken).then(result => {
      // console.log("DestroySession",result.data);
      AuthContextDispatch({ type: "SIGN_OFF" });   
      UserContextDispatch({ type: "SIGN_OFF" });    
      toggleDirection(true,true);    
      history.push('/videos');
      GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }}); 
    }).catch(err => {
      console.log('logout error',err);
      // FormErrorContextDispatch({ type: "REQUEST_ERROR", payload: err.response.data });  
      GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});   
    })
  }

  const list = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={handleToggle(false)}
      onKeyDown={handleToggle(false)}
    >
      <MenuTopBar title={(isAuthenticated ? "Settings" : "")} closeHandler={handleToggle} />
      <List classes={{ root: classes.paddingTop60 }}>  
        {/* <ListItem button key={'home'}>
          <MenuLinkText url={"/"} underline={false}><ListItemIcon classes={{ root: classes.displayFlex }}><HomeOutlinedIcon classes={{ root: classes.colorBlack }} /></ListItemIcon></LinkText>
          <MenuLinkText url={"/"} underline={false}><ListItemText primary={'Home'} /></MenuLinkText>
        </ListItem>   
        <ListItem button key={'home'}>
          <MenuLinkText url={"/videos"} underline={false}><ListItemIcon classes={{ root: classes.displayFlex }}><VideoLibraryRoundedIcon classes={{ root: classes.colorBlack }} /></ListItemIcon></LinkText>
          <MenuLinkText url={"/videos"} underline={false}><ListItemText primary={'Videos'} /></MenuLinkText>
        </ListItem>     
        <ListItem button key={'search'}>
          <MenuLinkText url={"/search"} underline={false}><ListItemIcon classes={{ root: classes.displayFlex }}><SearchOutlinedIcon classes={{ root: classes.colorBlack }} /></ListItemIcon></LinkText>
          <MenuLinkText url={"/search"} underline={false}><ListItemText primary={'Search'} /></MenuLinkText>
        </ListItem> */}
        {
          isAuthenticated 
          ?
            <>
              {/* <ListItem button key={'createVideo'}>
                <MenuLinkText url={"/create/video"} underline={false}><ListItemIcon classes={{ root: classes.displayFlex }}><VideocamRoundedIcon classes={{ root: classes.colorBlack }} /></ListItemIcon></LinkText>
                <MenuLinkText url={"/create/video"} underline={false}><ListItemText primary={'Create Video'} /></MenuLinkText>
              </ListItem> */}
              {/* <ListItem button key={'message'}>
                <MenuLinkText url={"/account/messages"} underline={false}><ListItemIcon classes={{ root: classes.displayFlex }}><MailIcon classes={{ root: classes.colorBlack }} /></ListItemIcon></LinkText>
                <MenuLinkText url={"/account/messages"} underline={false}><ListItemText primary={'Messages'} /></MenuLinkText>
              </ListItem> */}
              <ListItem button key={'profile'}>
                <LinkText url={"/account/profile"} underline={false}><ListItemIcon classes={{ root: classes.displayFlex }}><PersonOutlineOutlinedIcon classes={{ root: classes.colorBlack }} /></ListItemIcon></LinkText>
                <MenuLinkText url={"/account/profile"} underline={false}><ListItemText classes={{ primary: classes.fontSize16 }} primary={'My Profile'} /></MenuLinkText>
                <LinkTextForwardArrow url={"/account/profile"} underline={false} customClass={classes.forwardArrow}><ListItemIcon classes={{ root: classes.displayFlex }}><ForwardArrowBlack /></ListItemIcon></LinkTextForwardArrow>
              </ListItem>
              <ListItem button key={'messages'}>
                <LinkText url={"/account/messages"} underline={false}><ListItemIcon classes={{ root: classes.displayFlex }}><ChatBubbleOutlineOutlinedIcon classes={{ root: classes.colorBlack }} /></ListItemIcon></LinkText>
                <MenuLinkText url={"/account/messages"} underline={false}><ListItemText classes={{ primary: classes.fontSize16 }} primary={'Messages'} /></MenuLinkText>
                <LinkTextForwardArrow url={"/account/messages"} underline={false} customClass={classes.forwardArrow}><ListItemIcon classes={{ root: classes.displayFlex }}><ForwardArrowBlack /></ListItemIcon></LinkTextForwardArrow>
              </ListItem>
              <ListItem button key={'profileInformation'}>
                <LinkText url={"/account/information"} underline={false}><ListItemIcon classes={{ root: classes.displayFlex }}><InfoOutlinedIcon classes={{ root: classes.colorBlack }} /></ListItemIcon></LinkText>
                <MenuLinkText url={"/account/information"} underline={false}><ListItemText classes={{ primary: classes.fontSize16 }} primary={'Profile Info'} /></MenuLinkText>
                <LinkTextForwardArrow url={"/account/information"} underline={false} customClass={classes.forwardArrow}><ListItemIcon classes={{ root: classes.displayFlex }}><ForwardArrowBlack /></ListItemIcon></LinkTextForwardArrow>
              </ListItem>
              
              {
                userType === 'creator'
                ?
                  <>
                    <ListItem button key={'social'}>
                      <LinkText url={"/account/social-information"} underline={false}><ListItemIcon classes={{ root: classes.displayFlex }}><FaceOutlinedIcon classes={{ root: classes.colorBlack }} /></ListItemIcon></LinkText>
                      <MenuLinkText url={"/account/social-information"} underline={false}><ListItemText classes={{ primary: classes.fontSize16 }} primary={'Social Connect'} /></MenuLinkText>
                      <LinkTextForwardArrow url={"/account/social-information"} underline={false} customClass={classes.forwardArrow}><ListItemIcon classes={{ root: classes.displayFlex }}><ForwardArrowBlack /></ListItemIcon></LinkTextForwardArrow>
                    </ListItem>
                    <ListItem button key={'monetization'}>
                      <LinkText url={"/account/monetization-information"} underline={false}><ListItemIcon classes={{ root: classes.displayFlex }}><MonetizationOnOutlinedIcon classes={{ root: classes.colorBlack }} /></ListItemIcon></LinkText>
                      <MenuLinkText url={"/account/monetization-information"} underline={false}><ListItemText classes={{ primary: classes.fontSize16 }} primary={'Monetization'} /></MenuLinkText>
                      <LinkTextForwardArrow url={"/account/monetization-information"} underline={false} customClass={classes.forwardArrow}><ListItemIcon classes={{ root: classes.displayFlex }}><ForwardArrowBlack /></ListItemIcon></LinkTextForwardArrow>
                    </ListItem>
                    <ListItem button key={'payout'}>
                      <LinkText url={"/account/payout-information"} underline={false}><ListItemIcon classes={{ root: classes.displayFlex }}><AccountBalanceOutlinedIcon classes={{ root: classes.colorBlack }} /></ListItemIcon></LinkText>
                      <MenuLinkText url={"/account/payout-information"} underline={false}><ListItemText classes={{ primary: classes.fontSize16 }} primary={'Payout'} /></MenuLinkText>
                      <LinkTextForwardArrow url={"/account/payout-information"} underline={false} customClass={classes.forwardArrow}><ListItemIcon classes={{ root: classes.displayFlex }}><ForwardArrowBlack /></ListItemIcon></LinkTextForwardArrow>
                    </ListItem>
                  </>
                :
                  null
              }
              
              <ListItem button key={'deleteAccount'}>
                <ListItemIcon classes={{ root: classes.displayFlex }} onClick={(e) => handleDeleteAccountClick()}><ReportOutlinedIcon classes={{ root: classes.colorBlack }} /></ListItemIcon>
                <ListItemText primary={'Delete Account'} classes={{ root: classes.colorPinkLogout, primary: classes.fontSize16 }} onClick={(e) => handleDeleteAccountClick()} />
              </ListItem>
              <Divider />
            </>
          :
            null
        }
        {/* <ListItem button key={'faqs'}>
          <LinkText url={"/faqs"} underline={false}><ListItemIcon classes={{ root: classes.displayFlex }}><HelpOutlineOutlinedIcon classes={{ root: classes.colorBlack }} /></ListItemIcon></LinkText>
          <MenuLinkText url={"/faqs"} underline={false}><ListItemText primary={'FAQs'} /></MenuLinkText>
        </ListItem>
        <ListItem button key={'support'}>
          <LinkText url={"/support"} underline={false}><ListItemIcon classes={{ root: classes.displayFlex }}><ContactSupportOutlinedIcon classes={{ root: classes.colorBlack }} /></ListItemIcon></LinkText>
          <MenuLinkText url={"/support"} underline={false}><ListItemText primary={'Support'} /></MenuLinkText>
        </ListItem>
        <ListItem button key={'privacyPolicy'}>
          <LinkText url={"/privacy-policy"} underline={false}><ListItemIcon classes={{ root: classes.displayFlex }}><ContactSupportOutlinedIcon classes={{ root: classes.colorBlack }} /></ListItemIcon></LinkText>
          <MenuLinkText url={"/privacy-policy"} underline={false}><ListItemText primary={'Privacy Policy'} /></MenuLinkText>
        </ListItem>
        <ListItem button key={'termsOfUse'}>
          <LinkText url={"/terms-of-use"} underline={false}><ListItemIcon classes={{ root: classes.displayFlex }}><ContactSupportOutlinedIcon classes={{ root: classes.colorBlack }} /></ListItemIcon></LinkText>
          <MenuLinkText url={"/terms-of-use"} underline={false}><ListItemText primary={'Terms of Use'} /></MenuLinkText>
        </ListItem>
        <ListItem button key={'disclaimer'}>
          <LinkText url={"/disclaimer"} underline={false}><ListItemIcon classes={{ root: classes.displayFlex }}><ContactSupportOutlinedIcon classes={{ root: classes.colorBlack }} /></ListItemIcon></LinkText>
          <MenuLinkText url={"/disclaimer"} underline={false}><ListItemText primary={' Disclaimer'} /></MenuLinkText>
        </ListItem> */}
        
        {
          isAuthenticated 
          ?
            <ListItem button key={'logout'}>
              <ListItemIcon classes={{ root: classes.displayFlex }} onClick={(e) => handleLogOutClick()}><ExitToAppOutlinedIcon classes={{ root: classes.logout }} /></ListItemIcon>
              <ListItemText primary={'Log out'} classes={{ root: classes.colorPinkLogout }} onClick={(e) => handleLogOutClick()} />
            </ListItem>
          :
            <>
              <ListItem button key={'creatorSignUp'}>
                <LinkText url={"/registration/verify-contact/creator"} underline={false}><ListItemIcon classes={{ root: classes.displayFlex }}><ExitToAppOutlinedIcon classes={{ root: classes.colorBlack }} /></ListItemIcon></LinkText>
                <MenuLinkText url={"/registration/verify-contact/creator"} underline={false}><ListItemText classes={{ primary: classes.fontSize16 }} primary={'Be a Creator'} /></MenuLinkText>
              </ListItem>
              <ListItem button key={'userSignUp'}>
                <LinkText url={"/registration/verify-contact/user"} underline={false}><ListItemIcon classes={{ root: classes.displayFlex }}><ExitToAppOutlinedIcon classes={{ root: classes.colorBlack }} /></ListItemIcon></LinkText>
                <MenuLinkText url={"/registration/verify-contact/user"} underline={false}><ListItemText classes={{ primary: classes.fontSize16 }} primary={'Sign up'} /></MenuLinkText>
              </ListItem>
              <ListItem button key={'login'}>
                <LinkText url={"/login"} underline={false}><ListItemIcon classes={{ root: classes.displayFlex }}><ExitToAppOutlinedIcon classes={{ root: classes.colorBlack }} /></ListItemIcon></LinkText>
                <MenuLinkText url={"/login"} underline={false}><ListItemText classes={{ primary: classes.fontSize16 }} primary={'Log in'} /></MenuLinkText>
              </ListItem>
            </>
        }  
      </List>
    </div>
  );

  return (    
    <>
      <SwipeableDrawer classes={{ paper: (isMobile() === true && isPWA() !== false) ? classes.topApp : classes.topBrowser }} variant={'temporary'} anchor={'right'} open={open} onClick={handleToggle(false)} onClose={handleToggle(false)} onOpen={handleToggle(true)}>
        {list()}
      </SwipeableDrawer>   
      {
        isAuthenticated
        ?
          <DialogModal 
            methodSubmit={deleteAccount} 
            methodCancel={handleDialogClose} 
            openDialog={dialogOpen} 
            titleText={"Delete Account"} 
            contentText={"Are you sure?"} 
            cancelText={"cancel"} 
            methodText={"delete"} 
          />
        :
          null
      }     
    </>
  );
}
