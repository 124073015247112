export const imageWrapper = {
  position: "relative",
  height: "200px",
  overflow: "hidden",
  boxSizing: "border-box",
  borderRight: "1px solid rgba(255,255,255,1)",
  borderBottom: "1px solid rgba(255,255,255,1)",
  "&:nth-child(3n+3)": {
    borderRight: "0px solid rgba(255,255,255,1)",
  }
};

export const imageElement = { 
  position: "absolute", 
  top: "50%", 
  left: "50%", 
  transform: "translate(-50%, -50%)" 
}