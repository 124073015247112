import React, { createContext, useReducer, useEffect } from "react";
import { ContentContextReducer } from '../reducers/ContentContextReducer';
import { ContentInitialState } from "../constants/ContentInitialState";

export const ContentContext = createContext();
const initialState = ContentInitialState;

const ContentContextProvider = (props) => {
    const [ContentContextState, ContentContextDispatch] = useReducer(ContentContextReducer,initialState);

    useEffect(() => {
    }, [])

    return (
        <ContentContext.Provider value={{ContentContextState, ContentContextDispatch}}>
            {props.children}
        </ContentContext.Provider>
    );
}

export default ContentContextProvider;