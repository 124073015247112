import React from 'react';

export default class HeartSmallPink extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="13.964" height="12.423" viewBox="0 0 13.964 12.423">
                <g id="heart-2" transform="translate(-1.009 -5)">
                    <path id="Path_12" data-name="Path 12" d="M13.828,6.146a3.911,3.911,0,0,0-5.838.345,3.912,3.912,0,1,0-5.838,5.187l5.679,5.679a.225.225,0,0,0,.319,0l5.679-5.679A3.916,3.916,0,0,0,13.828,6.146Z" fill="#fe004f"/>
                </g>
            </svg>    
        )
    }
}