import React from 'react';

export default class AlbumIcon extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="33" height="33" viewBox="0 0 33 33">
                <defs>
                    <clipPath id="clip-path">
                    <rect id="Rectangle_60" data-name="Rectangle 60" width="29" height="29" rx="5" transform="translate(287 643)" fill="#fff"/>
                    </clipPath>
                </defs>
                <g id="Group_3342" data-name="Group 3342" transform="translate(-297 -670)">
                    <g id="Group_2844" data-name="Group 2844" transform="translate(12 29)">
                    <g id="Rectangle_59" data-name="Rectangle 59" transform="translate(287 643)" fill="#fff" stroke="#fff" stroke-width="2">
                        <rect width="29" height="29" rx="5" stroke="none"/>
                        <rect x="-1" y="-1" width="31" height="31" rx="6" fill="none"/>
                    </g>
                    <g id="Mask_Group_1" data-name="Mask Group 1" clip-path="url(#clip-path)">
                        <g id="Group_2844-2" data-name="Group 2844" transform="translate(15.293 5.618)">
                        <path id="Path_362" data-name="Path 362" d="M12529.988,948.382l17.629-17.628,17.628,17.628Z" transform="translate(-12267.999 -282)" fill="#fe004f"/>
                        <path id="Path_363" data-name="Path 363" d="M12529.988,944.422l13.669-13.669,13.669,13.669Z" transform="translate(-12251.505 -278.04)" fill="#03e8e1"/>
                        </g>
                    </g>
                    <circle id="Ellipse_133" data-name="Ellipse 133" cx="3.5" cy="3.5" r="3.5" transform="translate(298 647)" fill="#f3df24"/>
                    </g>
                </g>
            </svg>   
        )
    }
}