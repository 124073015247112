import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { GlobalContext } from '../contexts/GlobalContext';
import SystemUpdateAltRoundedIcon from '@material-ui/icons/SystemUpdateAltRounded';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';

import { isPWA, checkInstallerIOS, isMobile, getBrowserName } from "../utils/CommonUtil";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    textAlign: 'center',
    color: 'rgba(255,255,255,1)',
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: '0',
    opacity: '0',
    zIndex: '10000',
    backgroundColor: 'rgba(0,0,0,0.5)',
    '-webkit-backdrop-filter': 'blur(5px)',
    backdropFilter: 'blur(5px)',
    transition: "all 1000ms linear",
    "-webkit-transition": "all 1000ms linear",
    "-moz-transition": "all 1000ms linear",
    "-o-transition": "all 1000ms linear",
    "&.show" : {
      opacity: '1',
    }
  },
  previewButton: {
    fontSize: '14px',
    position: 'absolute',
    right: '10px',
    top: '10px',
    fontWeight: '300'
  },
  title: {
    margin: '50% auto 0',
    letterSpacing: '-5px',
    fontSize: '100px'
  },
  installTextWrapper: {
    position: 'absolute',
    bottom: '180px',
    margin: 'auto',
    width: '100%'
  },
  installText: {
    fontSize: '20px',
    fontWeight: '300'
  },
  installImage: {
    top: '5px',
    position: 'relative',
    margin: '0 5px'
  },
  installDownArrowWrapper: {
    position: 'absolute',
    bottom: '120px',
    margin: 'auto',
    width: '100%'
  },
  installDownArrow: {
    width: '50px',
    height: '50px'
  }
}));

export default function IosInstaller() {
  const classes = useStyles();
  const { GlobalContextState, GlobalContextDispatch } = useContext(GlobalContext);
  const { iOSInstaller } = GlobalContextState;  
  const [ show, setShow ] = useState(false);
  
  const continueHandler = (e) => {
    GlobalContextDispatch({ type: "HIDE_ISO_INSTALLER" })
  }

  useEffect(() => {
    if (!isPWA() && isMobile() && getBrowserName() === 'safari' && window.location.pathname.indexOf('password') === -1 && window.location.pathname.indexOf('login') === -1) {
      GlobalContextDispatch({ type: "SHOW_ISO_INSTALLER" })
      const timer = setTimeout(() => {
        setShow(true);
      }, 1000);
      return () => clearTimeout(timer); 
    }
  }, [])

  if (iOSInstaller === true) {
    return (
      <div className={classes.root + (show ? " show" : "")}>
        <div onClick={(e) => continueHandler(e)} className={classes.previewButton}>Preview in browser</div>
        <div className={classes.title}>GLOSS</div>
        <div className={classes.installTextWrapper}>
          <div className={classes.installText}>To install tap <SystemUpdateAltRoundedIcon className={classes.installImage} /> and choose</div>
          <div className={classes.installText}>Add to Home Screen</div>
        </div>
        <div className={classes.installDownArrowWrapper}><ArrowDownwardRoundedIcon className={classes.installDownArrow} /></div>
      </div>
    );
  } else {
    return null
  }
  
}