import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    width: '100%',
    height: '100%',
    fontSize: '30px',
    color: 'rgba(255,255,255,1)',
    textAlign: 'center',
    backgroundColor: 'rgba(98,74,251,1)',
    paddingTop: '20%'
  }
}));

export default function ComingSoon() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      Coming Soon
    </div>
  )  
}