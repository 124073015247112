import React, {useContext, useEffect, useState, useRef, createRef, useLayoutEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useScroll } from '../utils/useScrollItem.2';

const useStyles = makeStyles((theme) => ({
    infiniteScroll: {
        height: "0px",
        width: "100%",
        position: "relative",
        border: "1px solid rgba(0,0,0,0.0)"
    }    
}));

export default function InfiniteScroll(props) {
  const classes = useStyles();
  const scrollRef = useRef();  
  const { parentRef=null, method=()=>{}, method2=null, margin="0px" } = props;
  
  const listener = useScroll(parentRef, scrollRef, method, method2, margin);

  // useEffect(() => {
  //   console.log('infinite scroll loaded')
  // }, [])

  return (
    <div ref={scrollRef} className={classes.infiniteScroll} /> 
  );
}
