import React, { createContext, useReducer, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { GlobalBottomBarContextReducer } from '../reducers/GlobalBottomBarContextReducer';

export const GlobalBottomBarContext = createContext();

const initialState = {
    hideBottomBar: true,
    activeState: "home",
    themeColor: 'black'
}

const GlobalBottomBarContextProvider = (props) => {
    const [GlobalBottomBarContextState, GlobalBottomBarContextDispatch] = useReducer(GlobalBottomBarContextReducer,initialState);
    const location = useLocation();

    // useEffect(() => {
    //     console.log('bar1',location.pathname)        
    //     let stateStr = "";
    //     let stateColor = "black";
    //     if (location.pathname === "/") {
    //         stateStr = "home";
    //         stateColor = "black";
    //         GlobalBottomBarContextDispatch({ type: "SET_ACTIVE_STATE", payload: { activeState : stateStr, themeColor : stateColor, hideBottomBar: false }})
    //     } else if (/^\/(search|account\/profile|account\/messages|videos|public|user|house)/.test(location.pathname)) {            
    //         if (/^\/(videos|public)/.test(location.pathname)) {
    //             stateStr = "home";
    //             stateColor = "black";
    //         }    
    //         if (/^\/search/.test(location.pathname)) {
    //             stateStr = "search";
    //             stateColor = "white";
    //         }
    //         if (/^\/account\/messages/.test(location.pathname)) {
    //             stateStr = "messages"
    //             stateColor = "white";
    //         }
    //         if (/^\/account\/profile/.test(location.pathname)) {
    //             stateStr = "profile"
    //             stateColor = "white";
    //         }
    //         if (/^\/(user|house)/.test(location.pathname)) {
    //             stateStr = ""
    //             stateColor = "white";
    //         }
    //         GlobalBottomBarContextDispatch({ type: "SET_ACTIVE_STATE", payload: { activeState : stateStr, themeColor : stateColor, hideBottomBar: false }});
    //     } else {
    //         GlobalBottomBarContextDispatch({ type: "SET_ACTIVE_STATE", payload: { activeState : stateStr, themeColor : stateColor, hideBottomBar: true }});
    //     }
    // }, [])

    useEffect(() => {
        let stateStr = "";
        let stateColor = "black";
        if (location.pathname === "/") {
            stateStr = "home";
            stateColor = "black";
            GlobalBottomBarContextDispatch({ type: "SET_ACTIVE_STATE", payload: { activeState : stateStr, themeColor : stateColor, hideBottomBar: false }})
        } else if (/^\/(search|account\/profile|account\/messages|videos|user|house|public\/list\/videos)/.test(location.pathname)) {            
            if (/^\/videos/.test(location.pathname)) {
                stateStr = "home";
                stateColor = "black";
            }    
            if (/^\/search/.test(location.pathname)) {
                stateStr = "search";
                stateColor = "white";
            }
            if (/^\/account\/messages/.test(location.pathname)) {
                stateStr = "messages"
                stateColor = "white";
            }
            if (/^\/account\/profile/.test(location.pathname)) {
                stateStr = "profile"
                stateColor = "white";
            }
            if (/^\/(user|house)/.test(location.pathname)) {
                stateStr = ""
                stateColor = "white";
            }
            GlobalBottomBarContextDispatch({ type: "SET_ACTIVE_STATE", payload: { activeState : stateStr, themeColor : stateColor, hideBottomBar: false }});
        } else {
            GlobalBottomBarContextDispatch({ type: "SET_ACTIVE_STATE", payload: { activeState : stateStr, themeColor : stateColor, hideBottomBar: true }});
        }
        
    }, [location])

    return (
        <GlobalBottomBarContext.Provider value={{GlobalBottomBarContextState, GlobalBottomBarContextDispatch}}>
            {props.children}
        </GlobalBottomBarContext.Provider>
    );
}
export default GlobalBottomBarContextProvider;