import React from 'react';

export default class HeartSmallBorderGray extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="14.964" height="13.423" viewBox="0 0 14.964 13.423">
                <g id="heart-2" transform="translate(-0.509 -4.5)">
                    <path id="Path_12" data-name="Path 12" d="M13.828,6.146a3.911,3.911,0,0,0-5.838.345,3.912,3.912,0,1,0-5.838,5.187l5.679,5.679a.225.225,0,0,0,.319,0l5.679-5.679A3.916,3.916,0,0,0,13.828,6.146Z" fill="none" stroke="#999" stroke-width="1"/>
                </g>
            </svg>   
        )
    }
}