import React, { createContext, useReducer, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { UserContextReducer } from '../reducers/UserContextReducer';
import { GlobalContext } from '../contexts/GlobalContext';
import { GlobalTopBarContext } from '../contexts/GlobalTopBarContext';
import { AuthContext } from '../contexts/AuthContext';
// import { User } from '../testData/User';
import { GetUserService } from "../services/UserServices";
import { UserInitialState } from "../constants/UserInitialState";

export const UserContext = createContext();

const initialState = UserInitialState;

const UserContextProvider = (props) => {
    const location = useLocation();
    const { AuthContextState } = useContext(AuthContext);
    const { isAuthenticated, authToken, userId } = AuthContextState;    
    const { GlobalContextState } = useContext(GlobalContext);
    const { GlobalTopBarContextDispatch } = useContext(GlobalTopBarContext);
    const { isLoading, initialLoad } = GlobalContextState;
    const [UserContextState, UserContextDispatch] = useReducer(UserContextReducer,initialState);

    useEffect(() => {
        if (isAuthenticated && authToken && userId && !isLoading) {
            GetUserService(userId,authToken).then(result => {
                if (result.data.success === 1) {
                    UserContextDispatch({ type: "SET_USER", payload: result.data });
                } 
            }).catch(err => {
                console.log("catch GetUserService",err.response.data,err.response.status);
            });
        }     
    }, [isAuthenticated, isLoading])

    useEffect(() => {
        // console.log('UserContext location', location, UserContextState.handleName, UserContextState.user_house_name)
        if (/^\/account\/profile/.test(location.pathname)) {            
            GlobalTopBarContextDispatch({ type: "SET_TITLE", payload: { title: UserContextState.handleName } });  
            GlobalTopBarContextDispatch({ type: "SET_HOUSE_NAME", payload: { house: UserContextState.user_house_name } });
        }     
    }, [location])

    return (
        <UserContext.Provider value={{UserContextState, UserContextDispatch}}>
            {props.children}
        </UserContext.Provider>
    );
}

export default UserContextProvider;