// export const CountryCode = [
//     {
//         value: '+1',
//         label: 'US +1',
//     },
//     {
//         value: '+44',
//         label: 'UK +44',
//     },
//     {
//         value: '+52',
//         label: 'MX +52',
//     },
//     {
//         value: '+81',
//         label: 'JP +81',
//     },
//     {
//         value: '+1-868',
//         label: 'TT +1-868',
//     },
//     {
//         value: '+263',
//         label: 'ZW +263',
//     }
// ];

export const CountryCode = [
    {
        value: '+1',
        label: 'US +1',
    }
];