import React, { useContext, useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { FramerContext } from '../../contexts/FramerContext';
import { FormErrorContext } from '../../contexts/FormErrorContext';
import { AuthContext } from '../../contexts/AuthContext';

import HelmetComponent from "../../components/HelmetComponent";
import PlayButton from '../../components/PlayButton.2';
import StopButton from '../../components/StopButton.2';
import MuteButton from '../../components/MuteButton.2';
import CloseVideoButton from '../../components/CloseVideoButton';
import { videosCaptureContainerStyle, containerGridStyle } from '../../styles/defaultStyles';
import { backTransparentBtn } from '../../styles/defaultButtonStyles.2';
import { videoElement, imageElement, imageElementBackground } from '../../styles/defaultVideoWrapperStyles';

import { GetVideoByUserIdAndVideoIdService } from '../../services/VideoServices';

const useStyles = makeStyles((theme) => ({  
  containerGridStyle: containerGridStyle,
  videosCaptureContainerStyle: videosCaptureContainerStyle,
  backTransparentBtn: backTransparentBtn,
  videoElement: videoElement,
  imageElement: imageElement,
  imageElementBackground: imageElementBackground,
  videoActions: {
    position: 'fixed',
    bottom: '10%',
    zIndex: '110',
    display: 'flex',
    width: '100vw',
    justifyContent: 'center',
    alignContent: 'center',
    zIndex: '125'
  },
  backButtonWrapper: {
    color: "rgba(255,255,255,1)",
    display: "block",
    position: "absolute",
    top: "100px",
    right: "0px",
    zIndex: "115",
    display: "block",
    "&.isPlaying" : {
      display: "none"
    }
  }
}));

function PreviewVideo() {
  const classes = useStyles();
  let history = useHistory();
  const { videoId=null } = useParams();  
  const { AuthContextState } = useContext(AuthContext);  
  const { authToken, userId, registerFlow } = AuthContextState; 
  const { toggleDirection } = useContext(FramerContext);
  const { FormErrorContextState, FormErrorContextDispatch } = useContext(FormErrorContext);  
  const { requestError } = FormErrorContextState;
  const [ isPlaying, setIsPlaying ] = useState(true);
  const [ isMuted, setIsMuted ] = useState(true);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ videoData, setVideoData ] = useState(null);
  const videoRef = useRef();   
  
  const handleCloseClick = async () => {
    toggleDirection(false,true);
    history.goBack();
  }

  const videoPlay = (e) => {
    const videoPlayer = videoRef.current; 
    if (videoPlayer !== null) {
      if (videoPlayer.paused) {
        videoPlayer.play();
        videoPlayer.muted = false;
        setIsPlaying(true);
      }
    }
  }

  const videoStop = (e) => {
    const videoPlayer = videoRef.current; 
    if (videoPlayer !== null) {
      if (!videoPlayer.paused) {
        videoPlayer.pause();
        setIsPlaying(false);
      }
    }
  }

  const muteToggle = (e) => {
    const videoPlayer = videoRef.current; 
    if (videoPlayer !== null) {
      videoPlayer.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  }

  useEffect(() => {     
    GetVideoByUserIdAndVideoIdService({userId: userId, videoId: videoId, authToken: authToken}).then(result => {
      if (result.data.success === 1) {
        // UserContextDispatch({ type: "APPEND_USER_VIDEOS", payload: result.data });
        setVideoData(result.data.userVideos.videos[0]);
      }
    }).catch(err => {
        console.log("catch GetVideoByUserIdAndVideoIdService",err);
    });
  }, []);

  useEffect(() => {
    console.log('useEffect isLoading ------------------------------------',isLoading)
    const video = videoRef.current; 
    if (!isLoading && video) {
    }
  }, [isLoading]);

  return (
    <>
      <HelmetComponent title={"DTF - Preview Video"} />                      
      <Container classes={{ root: classes.videosCaptureContainerStyle }}> 
        
        <div className={classes.videoActions}>   
          { 
            videoData !== null
            ?
              videoData.video_information.resource_type === "video"
              ?
                <>
                  <MuteButton isActive={true} handleClick={muteToggle} muted={isMuted} />
                  {
                    isPlaying
                    ?
                      <StopButton isActive={true} handleClick={videoStop} />
                    :
                      <PlayButton isActive={true} handleClick={videoPlay} />
                  }
                </>
              :
                null
            :
              null
          }          
          <CloseVideoButton isActive={true} handleClick={handleCloseClick} />
        </div>
  
        { 
          videoData !== null
          ?
            videoData.video_information.resource_type === "video"
            ?
              <>
                <img
                  className={ classes.imageElementBackground + (videoData.isFacingMode ? " facingMode" : "") }  
                  src={"/dtf2021/video/upload/so_0/v1/" + videoData.video_src + ".jpg"}
                />
                <video 
                  ref={videoRef}
                  loop 
                  muted
                  autoPlay 
                  playsInline               
                  className={classes.videoElement + (videoData.isFacingMode ? " facingMode" : "")}
                  onLoadEnd={() => setIsLoading(false)}
                >
                  <source src={"/dtf2021/video/upload/c_limit,h_1024,w_768/v1/" + videoData.video_src + ".mp4"} /> 
                </video>
              </>              
            :       
              <>
                <img
                  className={ classes.imageElementBackground + (videoData.isFacingMode ? " facingMode" : "") }  
                  src={"/dtf2021/image/upload/so_0/v1/" + videoData.video_src + ".jpg"}
                />
                <img
                  className={ classes.imageElement + (videoData.isFacingMode ? " facingMode" : "") }  
                  src={"/dtf2021/image/upload/so_0/v1/" + videoData.video_src + ".jpg"}
                />
              </> 
          :
            null
        }

      </Container>          
    </>
  );
}

export default PreviewVideo;