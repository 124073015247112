import React, { createContext, useReducer } from "react";
import { FormErrorContextReducer } from '../reducers/FormErrorContextReducer'; 

export const FormErrorContext = createContext();

const initialState = {
    usernameError: { 
        message: '',
        error: false, 
    },
    emailError: { 
        message: '',
        error: false, 
    },
    passwordError: { 
        message: '',
        error: false, 
    },
    phoneError: { 
        message: '',
        error: false, 
    },
    codeError: {
        message: '',
        error: false
    },
    handlenameError: {
        message: '',
        error: false     
    },
    requestError: {
        message: '',
        error: false
    }
}

const FormErrorContextProvider = (props) => {    
    const [FormErrorContextState, FormErrorContextDispatch ] = useReducer(FormErrorContextReducer,initialState);

    return (
        <FormErrorContext.Provider value={{FormErrorContextState, FormErrorContextDispatch}}>
            {props.children}
        </FormErrorContext.Provider>
    );
}

export default FormErrorContextProvider;