import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { backBtn } from '../styles/defaultButtonStyles.2';
import { isPWA, isMobile } from "../utils/CommonUtil";
import BackArrowBlack from './svg/BackArrowBlack';

const useStyles = makeStyles((theme) => ({
  default : {
    position: "fixed", 
    width: "100vw",
    height: "auto",
    backgroundColor: "rgba(0,0,0,1)",
    borderBottom: "2px solid rgba(230,230,230,1)",
    zIndex: "1000"
  },
  defaultGrid: { 
    marginTop: "0px", 
    height: "50px", 
    backgroundColor: "rgba(255,255,255,1)" 
  },
  defaultGridApp: { 
    marginTop: "40px", 
    height: "50px", 
    backgroundColor: "rgba(255,255,255,1)" 
  },
  title : {
    fontWeight: "600",
    color: "#141822",
    fontSize: "16px",
    letterSpacing: "0.5px",
    width: "inherit",
    display: "inline-block"
  },
  backBtn : backBtn,
  doneButton: {
    textTransform: "none",
    fontSize: "15px",
    color: "rgba(98, 74, 251, 1)"
  }  
}));

export default function MenuTopBar(props) {
  const classes = useStyles();  
  const { title="", closeHandler=()=>{}, closeHandlerText="" } = props
  const handleClose = (e) => {
    closeHandler(false);
  }

  return (
    <div className={classes.default}>
      <Grid 
        container
        justify="flex-start"
        alignItems="center"
        alignContent="center"
        direction="row"
        spacing={0}
        className={(isMobile() === true && isPWA() !== false) ? classes.defaultGridApp : classes.defaultGrid}
      >
        <Grid item xs={2} align='left'>
          <Button classes={{ root: classes.backBtn }} onClick={(e) => handleClose(e)}>
            <BackArrowBlack />
          </Button>         
        </Grid>
        <Grid item xs={8} align='center'>          
          <Typography variant={"h1"} classes={{ h1: classes.title }}>              
            { title }         
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}