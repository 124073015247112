import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom"; //https://dev.to/projectescape/programmatic-navigation-in-react-3p1l

import { Container, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import { containerStyleWhite2, formGridStyle, formGridButtonSubmit, headerDefaultStyle } from '../../styles/defaultStyles.2';
import { ErrorTextStyle, ErrorTextContainerStyle } from '../../styles/defaultErrorStyles';
import { inputDefault, inputUnderline, inputCountryCodeDefault, inputPhoneDefault, inputPhoneUnderline } from '../../styles/defaultInput';
import { gridItemHeaderWrapper } from '../../styles/defaultGridStyles';

import SubmitButton from '../../components/SubmitButton.2';
import HelmetComponent from "../../components/HelmetComponent";

import { RegistrationContext } from '../../contexts/RegistrationContext';
import { FormErrorContext } from '../../contexts/FormErrorContext';
import { FramerContext } from '../../contexts/FramerContext';
import { GlobalContext } from '../../contexts/GlobalContext';

import { VerifyContactService } from "../../services/RegistrationServices";

import { CountryCode } from "../../constants/CountryCode";

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    '& .Mui-focused': {
      '& .MuiSelect-root': {
        backgroundColor: 'rgba(255,255,255,1)'       
      }      
    },
    '& .MuiSelect-root': inputCountryCodeDefault,
    '& .MuiInput-underline': {
      '&::before': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.2)'
      }  
    }   
  },  
  terms: {
    color: "rgba(146, 147, 151, 1)",
    fontSize: "0.8rem",
    padding: "10px 0"
  },
  buttonGridWrapper: {
    margin: "0 auto 30px",
    border: "1px solid rgba(241,241,241,1)"
  },
  defaultButtonStyles: {
    width: "50%",
    backgroundColor: "rgba(241,241,241,1)",
    border: "none !important"
  },
  defaultButtonActiveStyles: {
    width: "50%",
    backgroundColor: "rgba(255,255,255,1)",
    border: "none !important"
  },
  defaultButtonLabelActiveStyle: {    
    fontSize: "16px",
    fontWeight: "600",
    color: "rgba(0,0,0,1)"
  },
  defaultButtonLabelStyle: {
    fontSize: "16px",
    fontWeight: "600",
    color: "rgba(175,175,179,1)"
  },
  containerStyleWhite2: containerStyleWhite2,
  gridItemHeaderWrapper: gridItemHeaderWrapper, 
  formGridStyle: formGridStyle, 
  formGridButtonSubmit: formGridButtonSubmit, 
  headerDefaultStyle: headerDefaultStyle,
  ErrorTextStyle: ErrorTextStyle, 
  ErrorTextContainerStyle: ErrorTextContainerStyle,
  inputDefault: inputDefault, 
  inputUnderline: inputUnderline, 
  inputCountryCodeDefault: inputCountryCodeDefault,
  inputPhoneDefault: inputPhoneDefault,  
  inputPhoneUnderline: inputPhoneUnderline
}));

export default function VerifyContact() {
  const classes = useStyles();  
  let history = useHistory();
  const { GlobalContextDispatch } = useContext(GlobalContext);
  const { tokenExpired, registerType } = useParams();
  const tokenBoolean = ((tokenExpired === 'expired') ? true : false);
  const type = ((registerType === 'creator') ? registerType : 'user');
  const { toggleDirection } = useContext(FramerContext);
  const { FormErrorContextState, FormErrorContextDispatch } = useContext(FormErrorContext);
  const { phoneError, emailError, requestError } = FormErrorContextState;
  const { RegistraionContextDispatch } = useContext(RegistrationContext);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [active, setActive] = useState('phone');
  const [isActive, setIsActive] = useState(false);
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [value, setValue] = useState(0);
  const [countryCode, setCountryCode] = React.useState('+1');

  const handleChangeSelect = (event) => {
    setCountryCode(event.target.value);
  };

  useEffect(() => {
    if (!isSubmitted) {
      if (phone.length > 0 || email.length > 0) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    }
    if (((!phoneError.error && phone.length !== 0) || (!emailError.error && email.length !== 0)) && isSubmitted) {
      const data = {
        contact: (active === "phone" ? (countryCode.toString() + phone.toString()) : email),
        active: active,
        type: type 
      }      
      VerifyContactService(data).then(result => {
        if (result.status === 200) {
          if (result.data.success === 1) {
            setIsActive(true)      
            RegistraionContextDispatch({ type: "SEND_VALIDATION_CODE", payload: data})        
            toggleDirection(true,true);
            history.push('/registration/validate-contact/'+type)
            GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
          } else {
            FormErrorContextDispatch({ type: "REQUEST_ERROR", payload: result.data});
            GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
            setIsActive(false); 
            setIsSubmitted(false);
          }
        } else {
          FormErrorContextDispatch({ type: "REQUEST_ERROR", payload: result.data});
          GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
          setIsActive(false); 
          setIsSubmitted(false);
        }
      }).catch(err => {
        FormErrorContextDispatch({ type: "REQUEST_ERROR", payload: err.response.data });  
        GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
        setIsActive(false);
        setIsSubmitted(false);
      })
    } else {
      if (isSubmitted) {
        setIsActive(false);
        setIsSubmitted(false);
        GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
      }
    }
  }, [email, emailError, phone, phoneError])
  
  const handleSubmit = (e) => {
    e.preventDefault();
    GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : true }});
    switch(active) {
      case 'phone':
        FormErrorContextDispatch({ type: "VALIDATE_PHONE", payload: countryCode.toString() + phone.toString() });
        break;
      case 'email':
        FormErrorContextDispatch({ type: "VALIDATE_EMAIL", payload: email });
        break;
      default:  
    }  
    
    setIsSubmitted(true);    
  }

  const handleChange = (value) => {
    setValue(value);
    setActive(value === 0 ? 'phone' : 'email');
    setPhone('');
    setEmail('');
    setIsActive(false);
    FormErrorContextDispatch({ type: "RESET_ERRORS" });
  }

  return (
    <>
      <HelmetComponent title={"DTF - Verification"} />   
      <Container classes={{ root: classes.containerStyleWhite2 }}>     
        <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid 
            container
            justify="flex-start"
            direction="column"
            spacing={0}
            classes={{ root: classes.formGridStyle }}
          >  
            { 
              tokenBoolean 
                ? 
                  <Grid item classes={{ root: classes.gridItemHeaderWrapper }}>
                    <Typography variant={"h1"} classes={{ root: classes.headerDefaultStyle }}> 
                      Your registration process has expired, please verify your contact again.
                    </Typography>   
                  </Grid>
                : 
                  null
            }
            <Grid item container classes={{ root: classes.buttonGridWrapper }}>
              <ButtonGroup disableElevation fullWidth={true} variant="contained">
                <Button color={"default"} classes={active !== "phone" ? { root: classes.defaultButtonStyles, label: classes.defaultButtonLabelStyle } : { root: classes.defaultButtonActiveStyles, label: classes.defaultButtonLabelActiveStyle }}onClick={(e) => handleChange(0)}>Phone</Button>
                <Button color={"default"} classes={active !== "email" ? { root: classes.defaultButtonStyles, label: classes.defaultButtonLabelStyle } : {root: classes.defaultButtonActiveStyles, label: classes.defaultButtonLabelActiveStyle }} onClick={(e) => handleChange(1)}>Email</Button>
              </ButtonGroup>
            </Grid> 
            <Grid 
              item 
              container 
              justify="center"
              alignItems="center"
              spacing={2}
            > 
              <Grid item xs={12}>
                {
                  value === 0
                  ?
                    <>
                      <TextField
                        id="country-code"
                        select
                        label=""
                        value={countryCode}
                        onChange={handleChangeSelect}
                        helperText=""
                      >
                        {CountryCode.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Input type="phone" classes={{ root: classes.inputPhoneDefault, underline: classes.inputPhoneUnderline }} fullWidth={true} placeholder="Phone Number" error={phoneError.error} inputProps={{ 'aria-label': 'Phone number' }} value={phone} name="phone" onChange={(e) => setPhone(e.target.value.trim())} />
                    </>
                  :
                    <Input type="email" classes={{ root: classes.inputDefault, underline: classes.inputUnderline }} fullWidth={true} placeholder="xxxxx@email.com" error={emailError.error} inputProps={{ 'aria-label': 'Email' }} value={email} name="email" onChange={(e) => setEmail(e.target.value.trim())} />  
                }                
              </Grid>
              {
                emailError.error
                ?
                  <Grid item xs={12} classes={{ root: classes.ErrorTextContainerStyle }}>
                    <Typography variant="body1" classes={{ root: classes.ErrorTextStyle }}>
                      { emailError.message }
                    </Typography>               
                  </Grid>
                :
                  null
              }
              {
                phoneError.error
                ?
                  <Grid item xs={12} classes={{ root: classes.ErrorTextContainerStyle }}>
                    <Typography variant="body1" classes={{ root: classes.ErrorTextStyle }}>
                      { phoneError.message }
                    </Typography>               
                  </Grid>
                :
                  null
              }
              {
                requestError.error
                ?
                  <Grid item xs={12} classes={{ root: classes.ErrorTextContainerStyle }}>
                    <Typography variant="body1" classes={{ root: classes.ErrorTextStyle }}>
                      { requestError.message }
                    </Typography>               
                  </Grid>
                :
                  null
              } 
            </Grid>
            
            <Grid item>      
              <Typography variant="body1" classes={{ root: classes.terms }}>
                By signing up, you confirm that you agree to our Terms of Use and have read and understood our Privacy Policy.  You will receive { active === "phone" ? "a SMS" : "an email" } to confirm your { active === "phone" ? "phone number. SMS fee my apply." : "email." }
              </Typography>
            </Grid>
            <Grid container item direction="row" justify="center" alignItems="flex-end" classes={{ root: classes.formGridButtonSubmit }}>
              <SubmitButton isActive={isActive} />
            </Grid>
          </Grid>   
        </form>       
      </Container>
    </>
  );
}