import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { backBtn, backTransparentBtn } from '../styles/defaultButtonStyles';
import { isPWA, checkInstallerIOS, isMobile } from "../utils/CommonUtil";

const useStyles = makeStyles((theme) => ({
  default : {
    position: "fixed", 
    width: "100vw",
    height: "auto",
    backgroundColor: "rgba(0,0,0,1)",
    borderBottom: "2px solid rgba(230,230,230,1)",
    zIndex: "1000"
  },
  defaultGrid: { 
    marginTop: "0px", 
    height: "50px", 
    backgroundColor: "rgba(255,255,255,1)" 
  },
  defaultGridApp: { 
    marginTop: "40px", 
    height: "50px", 
    backgroundColor: "rgba(255,255,255,1)" 
  },
  defaultTransparent : {
    position: "fixed", 
    width: "100vw",
    height: "auto",
    backgroundColor: "transparent",
    borderBottom: "0px solid rgba(112,112,112,0.10)",
    zIndex: "1000"
  },
  defaultGridTransparent: { 
    marginTop: "0px", 
    height: "50px", 
    backgroundColor: "transparent" 
  },
  defaultGridAppTransparent: { 
    marginTop: "40px", 
    height: "50px", 
    backgroundColor: "transparent" 
  },
  title : {
    fontWeight: "500",
    color: "#141822",
    fontSize: "16px",
    letterSpacing: "0.5px",
    width: "inherit",
    display: "inline-block"
  },
  backBtn : backBtn,
  backTransparentBtn: backTransparentBtn,
  ArrowBackIosIcon: {
    lineHeight: "50px",
    height: "50px",
    display: "block",
    margin: "auto"
  },
  doneButton: {
    textTransform: "none",
    fontSize: "15px",
    color: "rgba(98, 74, 251, 1)"
  }
}));

export default function TopNavigation(props) {
  const classes = useStyles();  
  const { title="", closeHandler=()=>{}, closeHandlerText="Done", defaultTransparent=false } = props
  const handleClose = (e) => {
    closeHandler(e);
  }

  return (
    <div className={(defaultTransparent) ? classes.defaultTransparent : classes.default}>
      <Grid 
        container
        justify="flex-start"
        alignItems="center"
        alignContent="center"
        direction="row"
        spacing={0}
        className={(defaultTransparent) ? (isMobile() === true && isPWA() !== false) ? classes.defaultGridAppTransparent : classes.defaultGridTransparent : (isMobile() === true && isPWA() !== false) ? classes.defaultGridApp : classes.defaultGrid}
      >
        <Grid item xs={2} align='left'>
          <Button classes={{ root: ((defaultTransparent) ? classes.backTransparentBtn : classes.backBtn) }} onClick={(e) => handleClose(e)}>
            <ArrowBackIosIcon classes={{ root: classes.ArrowBackIosIcon }} />
          </Button>         
        </Grid>
        <Grid item xs={8} align='center'>          
          <Typography variant={"h1"} classes={{ h1: classes.title }}>              
            { title }         
          </Typography>
        </Grid>
        <Grid item xs={2} align='center'>
          <Button classes={{ label: classes.doneButton }} onClick={(e) => handleClose(e)}>
            { closeHandlerText }
          </Button>    
        </Grid>
      </Grid>
    </div>
  );
}