import axios from 'axios';
import { VersionInitialState } from "../constants/VersionInitialState";
const versionApiNumber = VersionInitialState.versionApiNumber;

export async function GetSearchPeople(objData) {
    const { strSearch="", pageNo=0, itemPerPage=10 } = objData
    const data = await axios.post(
        `/api/${versionApiNumber}/search/people/${pageNo}/${itemPerPage}`,
        objData
    );
    return data;
}

export async function GetSearchLocation(objData) {
    const { strSearch="", pageNo=0, itemPerPage=10 } = objData;
    const data = await axios.post(
        `/api/${versionApiNumber}/search/location/${pageNo}/${itemPerPage}`,
        objData
    );
    return data;
}

export async function GetSearchVideo(objData) {
    const { strSearch="", pageNo=0, itemPerPage=10 } = objData
    const data = await axios.post(
        `/api/${versionApiNumber}/search/video/${pageNo}/${itemPerPage}`,
        objData
    );
    return data;
}

export async function GetSearchHouseTagVideo(objData) {
    const { strSearch="", pageNo=0, itemPerPage=10 } = objData
    const data = await axios.post(
        `/api/${versionApiNumber}/search/house/video/${pageNo}/${itemPerPage}`,
        objData
    );
    return data;
}

export async function GetSearchHashTagVideo(objData) {
    const { strSearch="", pageNo=0, itemPerPage=10 } = objData
    const data = await axios.post(
        `/api/${versionApiNumber}/search/hash/video/${pageNo}/${itemPerPage}`,
        objData
    );
    return data;
}

export async function GetSearchHashTagLocationVideo(objData) {
    const { strSearch="", pageNo=0, itemPerPage=10 } = objData
    const data = await axios.post(
        `/api/${versionApiNumber}/search/hashLocation/video/${pageNo}/${itemPerPage}`,
        objData
    );
    return data;
}

export async function GetSearchHashTagPeopleVideo(objData) {
    const { strSearch="", pageNo=0, itemPerPage=10 } = objData
    const data = await axios.post(
        `/api/${versionApiNumber}/search/hashPeople/video/${pageNo}/${itemPerPage}`,
        objData
    );
    return data;
}

export async function GetSearchAll(objData) {
    const { strSearch="", pageNo=0, itemPerPage=10 } = objData
    const data = await axios.post(
        `/api/${versionApiNumber}/search/all/${pageNo}/${itemPerPage}`,
        objData
    );
    return data;
}

export async function GetSearchAllVideos(objData) {
    const { strSearch="", type="", pageNo=0, itemPerPage=10, videoId="" } = objData
    const data = await axios.post(
        `/api/${versionApiNumber}/search/allVideos/${pageNo}/${itemPerPage}/${videoId}`,
        objData
    );
    return data;
}

export async function GetSearchDiscovery(objData) {
    const { strSearch="", pageNo=0, itemPerPage=10 } = objData
    const data = await axios.post(
        `/api/${versionApiNumber}/search/discovery/${pageNo}/${itemPerPage}`,
        objData
    );
    return data;
}

export async function SaveSearchHistory(objData, authToken) {
    const data = await axios.post(
        `/api/${versionApiNumber}/search/history`,
        objData,
        {
            headers: {
                'Authorization': `Bearer ${authToken}` 
            }
        }
    );
    return data;
}

export async function GetSearchHistory(objData, authToken) {
    const { pageNo=0, itemPerPage=10 } = objData
    const data = await axios.get(
        `/api/${versionApiNumber}/search/history/${pageNo}/${itemPerPage}`,
        {
            headers: {
                'Authorization': `Bearer ${authToken}` 
            }
        }
    );
    return data;
}

export async function SaveSearchHistoryLoggedOut(objData) {
    const data = await axios.post(
        `/api/${versionApiNumber}/search/history/0`,
        objData
    );
    return data;
}