export const VideoServiceInitialState = {
    data: null,
    fileName: null,
    isPrivate: false,
    videoId: null,
    isVideo: false,
    isUploading: false
}


