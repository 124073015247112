export const GlobalTopBarContextReducer = (state, action) => {
    switch(action.type){
        case 'HIDE_TOP_BAR':
            return { 
                ...state,
                hideTopBar: true
            }
        case 'SHOW_TOP_BAR':
            return {
                ...state, 
                hideTopBar: false
            }
        case 'SET_ACTIVE_STATE':
            return {
                ...state, 
                themeColor: action.payload.themeColor,
                hideTopBar: action.payload.hideTopBar,
                hideTopBarBorder: action.payload.hideTopBarBorder,
                bgTransparent: action.payload.bgTransparent, 
                showMenuIcon: action.payload.showMenuIcon,
                showActionIcon: action.payload.showActionIcon,
                showTitle: action.payload.showTitle,
                showBackButton: action.payload.showBackButton,
                showSearch: action.payload.showSearch,
                showGreenIcon: action.payload.showGreenIcon, 
                showHouse: action.payload.showHouse,
                showUserName: action.payload.showUserName,
                searchDiscovery: action.payload.searchDiscovery
            }
        case 'SET_TITLE':
            
            return {
                ...state, 
                title: action.payload.title
            }  
        case 'RESET_TITLE':
            return {
                ...state, 
                title: ""
            }
        case 'SET_HOUSE_NAME':
            
            return {
                ...state, 
                house: action.payload.house
            }
        case 'RESET_HOUSE_NAME':
            return {
                ...state, 
                house: ""
            } 
        case 'SET_ACTION':
            
            return {
                ...state, 
                actionText: action.payload.actionText,
                actionMethod: action.payload.actionMethod
            }
        case 'RESET_ACTION':
            return {
                ...state, 
                actionText: "",
                actionMethod: null
            }
        case 'RESET_ACTIVE_STATE':
            return {
                ...state, 
                themeColor: 'white',
                hideTopBar: true,
                hideTopBarBorder: false,
                showBackButton: false,
                showMenuIcon: true,
                showActionIcon: false,
                showSearch: false,
                showTitle: true,
                title: "",
                showHouse: false,
                house: "",
                showUserName: false,
                showSearch: false,
                showGreenIcon: false,
                searchDiscovery: false
            }          
        default:
            return state    
    }
}