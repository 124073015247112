import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import Button from '@material-ui/core/Button';
import PaperItem from './PaperItem.2';
import LinkButton from './LinkButton';
import LinkText from './LinkText.3';

const useStyles = makeStyles((theme) => ({
  listWrapper: {
    position: "relative",
    display: "block",
    whiteSpace: "nowrap",
    overflowY: "hidden",
    overflowX: "auto",
    height: "175px",
    width: "100vw",
    border: "0px solid rgba(0,0,0,1)"
  },
  itemHeader: {  
      fontSize: "16px",
      color: "rgba(20,24,34,1)"
  },
  itemSubHeader: {  
    fontSize: "14px",
    color: "rgba(20,24,34,0.5)"
  },
  sectionWrapper: {
    position: "relative",
    width: "auto",
    margin: "20px auto"
  },
  headerSectionWrapper: {
    minHeight: "50px"
  },
  countButton: {
    position: "absolute",
    top: "0px",
    right: "0px",
    fontSize: "12px",
    height: "30px",
    backgroundColor: "#e0e0e0",
    color: "rgba(0, 0, 0, 0.87)",
    minWidth: "64px",
    boxSizing: "border-box",
    padding: "6px 16px",
    fontWeight: "500",
    lineHeight: "1.75",
    borderRadius: "4px"
  },
  countButtonIcon: {
    fontSize: "10px",
    top: "0px",
    position: "relative",
    margin: "0 0 0 5px"
  },
  itemWrapper: {
    position: "relative",
    display: 'inline-block',
    width: "100px",
    height: "145px",
    margin: "5px",
    overflow: "hidden",
    marginRight: "50px",
    textAlign: "center",
    padding: "60px 10px",
    cursor: "pointer"
  }
}));

export default function PaperList(props) {
  const classes = useStyles();
  const {name="list", header=null, subHeader=null, count=null, items=null, elevation=0, url="/" } = props;

  return (
    <div className={classes.sectionWrapper}>
        <div className={classes.headerSectionWrapper}>
            {
                header
                ?
                    <Typography variant={"h6"} classes={{root: classes.itemHeader}}>
                        {header}
                    </Typography> 
                :
                    null
            }
            {
                subHeader
                ?
                    <Typography variant={"subtitle1"} classes={{root: classes.itemSubHeader}}>
                        {subHeader}
                    </Typography>
                :
                    null
            }
            {
                count
                ?
                    <LinkButton url={url} classes={classes.countButton}>
                      {count} <ArrowForwardIosRoundedIcon classes={{root: classes.countButtonIcon}} />
                    </LinkButton>
                    // <Button disableElevation={true} classes={{root: classes.countButton}} variant="contained">{count} <ArrowForwardIosRoundedIcon classes={{root: classes.countButtonIcon}} /></Button> 
                :
                    null
            }
        </div>
        {
            items
            ?
                <div className={classes.listWrapper}>
                    {
                        items.map((item, index) => {
                            return <PaperItem key={name+'-'+index} item={item} url={url} elevation={elevation} />
                        })
                    }
                    <Paper classes={{root: classes.itemWrapper}} elevation={elevation}>
                      <LinkText url={url} underline={false}>
                        View more
                      </LinkText>
                    </Paper> 
                </div>
            :
                null
        }
        
    </div>
  );
}