import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { FramerContext } from '../contexts/FramerContext';
import { blackLink, whiteLink } from '../styles/defaultLinkStyles.2';

const useStyles = makeStyles((theme) => ({
  blackLink: blackLink,
  whiteLink: whiteLink,
  underline: {
    textDecoration: 'underline',
    fontSize: '12px'
  },
  noUnderline: {
    textDecoration: 'none',
    fontSize: '12px'
  },
  forwardArrow: {
    position: "absolute",
    right: "15px",
    width: "10px",
    display: "inline-block"
  }
}));

export default function LinkTextForwardArrow(props) {
  const { toggleDirection } = useContext(FramerContext);
  const { url="/", children="", directionBool=true, classLinkName="blackLink", underline=false} = props;
  const classes = useStyles();
  return (
    <Link to={url} className={classes.forwardArrow + ' ' + (classLinkName === "whiteLink" ? classes.whiteLink : classes.blackLink) + ' ' + (underline ? classes.underline : classes.noUnderline)} onClick={(e) =>  toggleDirection(directionBool,true)}>
      {children}
    </Link>
  );
}
