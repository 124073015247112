import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { GlobalContext } from '../contexts/GlobalContext';
import Spinner from './svg/Spinner';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0',
    margin: 'auto',
    zIndex: '1000',
    backgroundColor: 'rgba(255,255,255,0.3)'
  },
  childSpinner: {
    color: 'rgba(98,74,251,1)',
    position: 'relative',
    width: '30px',
    height: '30px',
    margin: 'auto',
    'webkit-animation': '$rotation 2s linear infinite',
    '-moz-animation': '$rotation 2s linear infinite',
    '-o-animation': '$rotation 2s linear infinite',
    animation: '$rotation 2s linear infinite',
    animationDelay: "0s",
    '& > svg': {
      width: 'inherit',
      height: 'inherit'
    }
  },
  "@keyframes rotation": {
    "from": {
      transform: "rotate(0deg)"
    },
    "to": {
      transform: "rotate(359deg)"
    }
  }
}));

export default function CircleSpinner() {
  const classes = useStyles();
  const { GlobalContextState } = useContext(GlobalContext);
  const { showCircleSpinner } = GlobalContextState;    
  return (
    <div className={classes.root}>
      <div className={classes.childSpinner}>
        <Spinner />
      </div>
      {/* <img src={"/svg/spinner.svg"} alt={'Loading...'} className={classes.childSpinner + " " + classes.rotateImage} /> */}
    </div>
  )  
}