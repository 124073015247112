export default {
    onUpdate: registration => {
        console.log('onUpdate registration', registration)
        // registration.waiting.postMessage('SKIP_WAITING')
        // console.log('registration RELOAD')
        // registration.unregister().then(() => {
        //     console.log('registration reload')
        //     window.location.reload(true)
        // })
    },
}