import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  commnetOverlayGradientTop: {
    marginTop: "-1px",
    width: "100%",
    height: "25px",
    position: "fixed",
    background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0))",
    zIndex: "1"
  },
  commnetOverlayGradientBottom: {
    width: "100%",
    bottom: "70px",
    height: "25px",
    position: "fixed",
    background: "linear-gradient(to top, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0))",
    zIndex: "1"
  },
}));

export default function CommentOverlayGradient(props) {  
  const classes = useStyles();
  const { isTop=true } = props;
  return (
    <div className={isTop ? classes.commentOverlayGradientTop : classes.commentOverlayGradientBottom}></div> 
  );
}
