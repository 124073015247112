import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { videoImageElement } from '../styles/defaultVideoWrapperStyles'; 

const useStyles = makeStyles((theme) => ({
    videoImageElement: videoImageElement
}));

export default function ImageProfileItem(props) {
    const { image="", alt="" } = props;    
    const classes = useStyles(); 

    if (image === "") {
        return (
            <div className={ classes.videoImageElement }>{alt.charAt(0).toUpperCase()}</div>
        )
    } else {
        return (        
            <img src={"/dtf2021/image/upload/w_25,c_fill,ar_1:1,g_auto,r_max/v1/" + image + '.jpg'} loading="lazy" alt={alt}  className={ classes.videoImageElement } />     
        );
    }
}
