import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import { defaultGradientPurpleBtn, defaultTransparentBtn } from '../styles/defaultButtonStyles.2';
import { DrawerSignUpContext } from '../contexts/DrawerSignUpContext';
import { AuthContext } from '../contexts/AuthContext';
import LinkButton from './LinkButton';

const useStyles = makeStyles((theme) => ({  
  defaultGradientPurpleBtn: defaultGradientPurpleBtn,
  defaultTransparentBtn: defaultTransparentBtn, 
  image: {
    top: "50%",
    left: "50%",
    minHeight: "100%",
    minWidth: "100%",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    zIndex: "-1"
  },
  paperAnchorBottom: {
    padding: "20px",
    // borderTopRightRadius: "20px",
    // borderTopLeftRadius: "20px"
  },
  headerLabel: {
    fontSize: '20px',
    fontWeight: '600',
    color: 'rgba(20,24,34,1)',
    display: 'block',
    margin: '0'
  },
  headerLabelPink: {
    fontSize: '20px',
    fontWeight: '600',
    color: 'rgba(253,45,85,1)'
  },
  subText: {
    display:'block',
    margin: '0 0 10px',
    fontSize: '16px',
    color: 'rgba(139,140,144,1)'
  },
  text: {
    fontSize: '14px',
    fontWeight: '500',
    color: 'rgba(20,24,34,1)'
  },
  labelBtn: {
    fontWeight: '300',
    textTransform: 'none'
  },
  closeIcon: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    fontSize: '18px',
    color: 'rgba(0,0,0,1)'
  }
}));

function DrawerSubscribe(props) {
  const classes = useStyles();
  const { AuthContextState } = useContext(AuthContext);
  const { isAuthenticated } = AuthContextState;
  const { DrawerSignUpContextState, DrawerSignUpContextDispatch } = useContext(DrawerSignUpContext);
  const { showSubscribeDrawer=false , subscribeName="" } = DrawerSignUpContextState;
 
  const handleClick = (e) => {    
    DrawerSignUpContextDispatch({ type: "HIDE_SUBSCRIBE_DRAWER" });
  }

  return (    
    <Drawer classes={{ paperAnchorBottom: classes.paperAnchorBottom }} transitionDuration={{ enter: 100, exit: 100 }} anchor={'bottom'} open={showSubscribeDrawer}>
        {/* <CloseIcon classes={{ root: classes.closeIcon }}  onClick={(e) => handleClick(e)}/> */}
        <Typography variant={"body2"} component={"span"} classes={{ root: classes.headerLabel }}>
          Subscirbe
        </Typography>
        <Typography variant={"body2"} component={"span"} classes={{ root: classes.subText }}>
          {subscribeName} would love for you to become part of her inner circle.
        </Typography>
        <LinkButton url={"/account/wallet"} directionBool={true}>
          <Button onClick={(e) => handleClick(e)} variant="outlined" color="primary" classes={{ root: classes.defaultGradientPurpleBtn, label: classes.labelBtn }}>
            Subscribe
          </Button>
        </LinkButton>
        <Button onClick={(e) => handleClick(e)} variant="outlined" color="primary" classes={{ root: classes.defaultTransparentBtn, label: classes.labelBtn }}>
          Cancel
        </Button>
    </Drawer>
  );
}

export default DrawerSubscribe;