export const videosContainerStyle = {
  overflow: "hidden",
  position: "relative",
  width: "100vw",
  height: "100vh",
  scrollSnapType: "y mandatory",
  overflowY: "scroll",
  padding: "0 0 80px 0",
  "&.pwa": {
    height: "100vh"
  },
  "&.scrollY": {
    height: "100vh"
  }
}

// export const videosContainerStyle = {
//   overflow: "hidden",
//   position: "relative",
//   width: "100vw",
//   height: "100%",
//   scrollSnapType: "y mandatory",
//   overflowY: "scroll",
//   padding: "0",
//   "&.pwa": {
//     height: "100%"
//   },
//   "&.scrollY": {
//     height: "100%"
//   }
// }