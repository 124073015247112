import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { videoEditImageElement } from '../styles/defaultVideoWrapperStyles'; 

const useStyles = makeStyles((theme) => ({
    videoEditImageElement: videoEditImageElement
}));

export default function ImageVideoItem(props) {
    const { image="", alt="", imageInfo=null, isFacingMode=false} = props;    
    const classes = useStyles(); 
    return (        
        <img src={"/dtf2021/" + (imageInfo ? imageInfo.video_information ? imageInfo.video_information.resource_type === "video" ? "video" : "image" : "image" : "image") + "/upload/v1/" + image + '.jpg'} loading="lazy" alt={alt}  className={ classes.videoEditImageElement + (isFacingMode ? " facingMode" : "") } />     
    );
}
