import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { defaultFormButton, defaultFormIconButton } from '../styles/defaultButtonStyles.2';

const useStyles = makeStyles((theme) => ({
  defaultFormButton: defaultFormButton,
  defaultFormIconButton: defaultFormIconButton
}));

export default function SubmitButton(props) {
  const classes = useStyles();
  if (props.isActive) {
    return (    
      <button type="submit" className={classes.defaultFormButton}>
        <ArrowForwardIcon classes={{ root: classes.defaultFormIconButton }} className={(props.isActive ? "active" : "")} />
      </button>
    )
  } else {
    return (    
      <button type="submit" className={classes.defaultFormButton} disabled>
        <ArrowForwardIcon classes={{ root: classes.defaultFormIconButton }} className={(props.isActive ? "active" : "")} />
      </button>
    )
  }
}