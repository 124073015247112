import React, { useContext, useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";

import { Container, Grid } from '@material-ui/core';
import { makeStyles, withStyles  } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Switch from '@material-ui/core/Switch';

import { containerStyleWhite, formGridStyle3, formGridButtonSubmit, headerDefaultStyle, inputReadOnly, bodyDefaultStyle, bodyDefaultStyle3, bodyDefaultStyle4 } from '../../styles/defaultStyles.2';
import { ErrorTextStyle, ErrorTextContainerStyle } from '../../styles/defaultErrorStyles';
import { inputSocialDefault, inputUnderline, inputHouseDefault, inputHouseUnderline, arrowIcon, arrowActiveIcon, eyeIcon, eyeActiveIcon } from '../../styles/defaultInput';

import { defaultChip } from '../../styles/defaultChip'
import { gridItemHeaderWrapper } from '../../styles/defaultGridStyles';

import HelmetComponent from '../../components/HelmetComponent';
import SubmitButton from '../../components/SubmitButton.2';
import EditButton from '../../components/EditButton.2';
import CloseButton from '../../components/CloseButton.2';

import { FramerContext } from '../../contexts/FramerContext';
import { FormErrorContext } from '../../contexts/FormErrorContext';
import { AuthContext } from '../../contexts/AuthContext';
import { GlobalContext } from '../../contexts/GlobalContext';
import { UserContext } from '../../contexts/UserContext';
import { useSnackbar } from 'notistack';

import { UpdateUserInformationSocialsService } from '../../services/UserInformationServices';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  containerStyleWhite: containerStyleWhite, 
  formGridStyle3: formGridStyle3, 
  formGridButtonSubmit: formGridButtonSubmit, 
  headerDefaultStyle: headerDefaultStyle, 
  inputReadOnly: inputReadOnly, 
  bodyDefaultStyle: bodyDefaultStyle,
  bodyDefaultStyle3: bodyDefaultStyle3,
  bodyDefaultStyle4: bodyDefaultStyle4,
  ErrorTextStyle: ErrorTextStyle, 
  ErrorTextContainerStyle: ErrorTextContainerStyle,
  inputSocialDefault: inputSocialDefault, 
  inputUnderline: inputUnderline,
  inputHouseDefault: inputHouseDefault,
  inputHouseUnderline: inputHouseUnderline,
  gridItemHeaderWrapper: gridItemHeaderWrapper,
  defaultChip: defaultChip,
  arrowIcon: arrowIcon,
  arrowActiveIcon: arrowActiveIcon,
  eyeIcon: eyeIcon,
  eyeActiveIcon: eyeActiveIcon
}));

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 50,
    height: 25,
    padding: 0,
    display: 'flex',
    float: 'right',
    top: '-3px',
    position: 'relative'
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(25px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 21,
    height: 21,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 25 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

export default function AccountSocialInformation() {
  const classes = useStyles();
  let history = useHistory();
  const { GlobalContextState, GlobalContextDispatch } = useContext(GlobalContext);
  const { isPwaMobile } = GlobalContextState;
  const { AuthContextState } = useContext(AuthContext);
  const { authToken, userId, registerFlow, upgradeFlow } = AuthContextState;  
  const { FormErrorContextState, FormErrorContextDispatch } = useContext(FormErrorContext);  
  const { passwordError, requestError } = FormErrorContextState;
  const { toggleDirection } = useContext(FramerContext);
  const { enqueueSnackbar } = useSnackbar();
  
  const [isEdit, setIsEdit] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isActive, setIsActive] = useState(true);

  const [facebookUrl, setFacebookUrl] = useState('');
  const [instagramUrl, setInstagramUrl] = useState('');
  const [twitterUrl, setTwitterUrl] = useState('');
  const [facebookUsername, setFacebookUsername] = useState('');
  const [instagramUsername, setInstagramUsername] = useState('');
  const [twitterUsername, setTwitterUsername] = useState('');
  const [passwordFacebook, setPasswordFacebook] = useState('');
  const [passwordInstagram, setPasswordInstagram] = useState('');
  const [passwordTwitter, setPasswordTwitter] = useState('');
  const [showSocial, setShowSocial] = useState(true);
  const [showPasswordFacebook, setShowPasswordFacebook] = useState(false);
  const [showPasswordInstagram, setShowPasswordInstagram] = useState(false);
  const [showPasswordTwitter, setShowPasswordTwitter] = useState(false);

  const [state, setState] = React.useState({
    checkedFacebook: false,
    checkedInstagram: false,
    checkedTwitter: false,
  });

  useEffect(() => {    
    // if (!isSubmitted) {
      
    // }
    
    if (isSubmitted) {
      const data = {
        socials: [
          {'facebookUrl': facebookUrl, 'facebookUsername': facebookUsername, 'facebookPassword': passwordFacebook },
          {'twitterUrl': twitterUrl, 'twitterUsername': twitterUsername, 'twitterPassword': passwordTwitter },
          {'instagramUrl': instagramUrl, 'instagramUsername': instagramUsername, 'instagramPassword': passwordInstagram }
        ]
      } 
      UpdateUserInformationSocialsService(data,userId,authToken).then(result => {
         toggleDirection(true,true);
        if (registerFlow || upgradeFlow) {
          history.push('/account/monetization-information');
        } else {
          enqueueSnackbar('Update successful', { variant: 'default', autoHideDuration: 3000 });
          GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
          setIsActive(false);
          setIsSubmitted(false);
          setIsEdit(false);
        }
      }).catch(err => {
        console.log("catch",err.response.data,err.response.status);
        FormErrorContextDispatch({ type: "REQUEST_ERROR", payload: err.response.data });          
        GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});         
        setIsActive(false);
        setIsSubmitted(false);
        setIsEdit(false);
      })
    }
  }, [isSubmitted])

  const handleSubmit = (e) => {    
    e.preventDefault();
    setIsSubmitted(true);
    if (!registerFlow && !upgradeFlow) {
      GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : true }}); 
    }    
  }

  const handleClickShowSocial = () => {
    setShowSocial(!showSocial);    
  }

  const handleMouseDownSocial = (event) => {
    event.preventDefault();
  }  

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  }

  const handleClickShowPasswordFacebook = () => {
    setShowPasswordFacebook(!showPasswordFacebook);
  }

  const handleMouseDownPasswordFacebook = (event) => {
    event.preventDefault();
  }

  const handleClickShowPasswordInstagram = () => {
    setShowPasswordInstagram(!showPasswordInstagram);
  }

  const handleMouseDownPasswordInstagram = (event) => {
    event.preventDefault();
  }

  const handleClickShowPasswordTwitter = () => {
    setShowPasswordTwitter(!showPasswordTwitter);
  }

  const handleMouseDownPasswordTwitter = (event) => {
    event.preventDefault();
  }

  const handleEditClick = (e) => {
    e.preventDefault();
    setIsEdit(true);
  }

  const handleCloseClick = (e) => {
    e.preventDefault();
    setIsEdit(false);
  }

  const handleEditResetClick = (e) => {
    e.preventDefault();
  }

  return (
    <>
      <HelmetComponent title={"DTF - Add social information"} />      
      <Container classes={{ root: classes.containerStyleWhite }} className={isPwaMobile ? " pwaMobile" : ""}>      
        <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid 
            container
            justify="flex-start"
            direction="column"
            spacing={0}
            classes={{ root: classes.formGridStyle3 }}
          >
            {
              registerFlow || upgradeFlow
              ?
                <>
                  <Grid item>
                    <Typography variant={"h1"} classes={{ root: classes.headerDefaultStyle }}>
                      Verify Identity/Add your Followers
                    </Typography>   
                  </Grid>
                  <Grid item classes={{ root: classes.gridItemHeaderWrapper }}> 
                    <Typography classes={{ root: classes.bodyDefaultStyle }}>
                      {/* Lorem ipsum dolor eset sim artun */}
                    </Typography> 
                  </Grid>
                </>
              :                
                <>
                  <Grid item>
                    <Typography variant={"h1"} classes={{ root: classes.headerDefaultStyle }}>
                      Social Accounts
                    </Typography>   
                  </Grid>
                  <Grid item classes={{ root: classes.gridItemHeaderWrapper }}> 
                    <Typography classes={{ root: classes.bodyDefaultStyle }}>
                      {/* Lorem ipsum dolor eset sim artun */}
                    </Typography> 
                  </Grid>
                </>
            }            
            <Grid 
              item 
              container 
              justify="center"
              alignItems="center"
              spacing={2}
            >              
              <Grid item xs={12}> 
                <Typography classes={{ root: classes.bodyDefaultStyle3 }}>
                  Add Social Accounts
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowSocial}
                    onMouseDown={handleMouseDownSocial}
                    classes={{ root: classes.arrowActiveIcon }}
                  >
                    {
                      showSocial
                      ?
                        <KeyboardArrowDownIcon classes={{ root: classes.arrowActiveIcon }} />
                      :
                        <ChevronRightIcon classes={{ root: classes.arrowActiveIcon }} />
                    }                    
                  </IconButton>
                </Typography>                  
              </Grid>
              {
                showSocial
                ?                  
                  <>
                    <Grid item xs={12}> 
                      <Typography classes={{ root: classes.bodyDefaultStyle4 }}>
                        Facebook <AntSwitch checked={state.checkedFacebook} onChange={handleChange} name="checkedFacebook" />
                      </Typography>
                    </Grid>
                    {
                      state.checkedFacebook
                      ?
                        <>
                          <Grid item xs={12}>              
                            <Input type="text" classes={{ root: classes.inputSocialDefault, underline: classes.inputUnderline }} fullWidth={true} placeholder="URL" error={requestError.error} inputProps={{ 'aria-label': 'Facebook URL' }} value={facebookUrl} onChange={(e) => setFacebookUrl(e.target.value)} />
                          </Grid>
                          <Grid item xs={12}>              
                            <Input type="text" classes={{ root: classes.inputSocialDefault, underline: classes.inputUnderline }} fullWidth={true} placeholder="Username or Email" error={requestError.error} inputProps={{ 'aria-label': 'Username or Email' }} value={facebookUsername} onChange={(e) => setFacebookUsername(e.target.value)} />
                          </Grid>
                          <Grid item xs={12}>              
                            <Input
                              classes={{ root: classes.inputSocialDefault, underline: classes.inputUnderline }} 
                              fullWidth={true} 
                              placeholder="password" 
                              id="standard-adornment-password-facebook"
                              error={passwordError.error} 
                              inputProps={{ 'aria-label': 'Password' }}
                              type={showPasswordFacebook ? 'text' : 'password'}
                              value={passwordFacebook}
                              onChange={(e) => setPasswordFacebook(e.target.value.trim())}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPasswordFacebook}
                                    onMouseDown={handleMouseDownPasswordFacebook}
                                    classes={{ root: (passwordFacebook.length !== 0 ? classes.eyeActiveIcon : classes.eyeIcon) }}
                                  >
                                    {showPasswordFacebook ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          </Grid>
                        </>
                      :
                        null
                    }
                    <Grid item xs={12}> 
                      <Typography classes={{ root: classes.bodyDefaultStyle4 }}>
                        Instagram <AntSwitch checked={state.checkedInstagram} onChange={handleChange} name="checkedInstagram" />
                      </Typography>
                    </Grid>
                    {
                      state.checkedInstagram
                      ?
                        <>
                          <Grid item xs={12}>              
                            <Input type="text" classes={{ root: classes.inputSocialDefault, underline: classes.inputUnderline }} fullWidth={true} placeholder="URL" error={requestError.error} inputProps={{ 'aria-label': 'Facebook URL' }} value={instagramUrl} onChange={(e) => setInstagramUrl(e.target.value)} />
                          </Grid>
                          <Grid item xs={12}>              
                            <Input type="text" classes={{ root: classes.inputSocialDefault, underline: classes.inputUnderline }} fullWidth={true} placeholder="Username or Email" error={requestError.error} inputProps={{ 'aria-label': 'Username or Email' }} value={instagramUsername} onChange={(e) => setInstagramUsername(e.target.value)} />
                          </Grid>
                          <Grid item xs={12}>              
                            <Input
                              classes={{ root: classes.inputSocialDefault, underline: classes.inputUnderline }} 
                              fullWidth={true} 
                              placeholder="password" 
                              id="standard-adornment-password-instagram"
                              error={passwordError.error} 
                              inputProps={{ 'aria-label': 'Password' }}
                              type={showPasswordInstagram ? 'text' : 'password'}
                              value={passwordInstagram}
                              onChange={(e) => setPasswordInstagram(e.target.value.trim())}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPasswordInstagram}
                                    onMouseDown={handleMouseDownPasswordInstagram}
                                    classes={{ root: (passwordInstagram.length !== 0 ? classes.eyeActiveIcon : classes.eyeIcon) }}
                                  >
                                    {showPasswordInstagram ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          </Grid>
                        </>
                      :
                        null
                    } 
                    <Grid item xs={12}> 
                      <Typography classes={{ root: classes.bodyDefaultStyle4 }}>
                        Twitter <AntSwitch checked={state.checkedTwitter} onChange={handleChange} name="checkedTwitter" />
                      </Typography>
                    </Grid>
                    {
                      state.checkedTwitter
                      ?
                        <>
                          <Grid item xs={12}>              
                            <Input type="text" classes={{ root: classes.inputSocialDefault, underline: classes.inputUnderline }} fullWidth={true} placeholder="URL" error={requestError.error} inputProps={{ 'aria-label': 'Facebook URL' }} value={twitterUrl} onChange={(e) => setTwitterUrl(e.target.value)} />
                          </Grid>
                          <Grid item xs={12}>              
                            <Input type="text" classes={{ root: classes.inputSocialDefault, underline: classes.inputUnderline }} fullWidth={true} placeholder="Username or Email" error={requestError.error} inputProps={{ 'aria-label': 'Username or Email' }} value={twitterUsername} onChange={(e) => setTwitterUsername(e.target.value)} />
                          </Grid>
                          <Grid item xs={12}>              
                            <Input
                              classes={{ root: classes.inputSocialDefault, underline: classes.inputUnderline }} 
                              fullWidth={true} 
                              placeholder="password" 
                              id="standard-adornment-password-twitter"
                              error={passwordError.error} 
                              inputProps={{ 'aria-label': 'Password' }}
                              type={showPasswordTwitter ? 'text' : 'password'}
                              value={passwordTwitter}
                              onChange={(e) => setPasswordTwitter(e.target.value.trim())}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPasswordTwitter}
                                    onMouseDown={handleMouseDownPasswordTwitter}
                                    classes={{ root: (passwordTwitter.length !== 0 ? classes.eyeActiveIcon : classes.eyeIcon) }}
                                  >
                                    {showPasswordTwitter ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          </Grid>
                        </>
                      :
                        null
                    } 
                  </>  
                :
                  null
              }              
              {
                requestError.error
                ?
                  <Grid item xs={12} classes={{ root: classes.ErrorTextContainerStyle }}>
                    <Typography variant="body1" classes={{ root: classes.ErrorTextStyle }}>
                      { requestError.message }
                    </Typography>               
                  </Grid>
                :
                  null
              }                
            </Grid>
            {
              registerFlow || upgradeFlow
              ?
                <Grid container item direction="row" justify="center" alignItems="flex-end" classes={{ root: classes.formGridButtonSubmit }}>
                  <SubmitButton isActive={isActive} />
                </Grid>
              :
                <Grid container item direction="row" justify="center" alignItems="flex-end" classes={{ root: classes.formGridButtonSubmit }}>
                  {
                    isEdit
                    ?
                      <>
                        <CloseButton isActive={true} handleCloseClick={handleCloseClick} />
                        <SubmitButton isActive={isActive} />
                      </>  
                    :
                      <EditButton isActive={true} handleEditClick={handleEditClick} />
                  }                  
                </Grid>
            } 
          </Grid>
        </form>
      </Container>
    </>
  );
}
