export const VideosContextReducer = (state, action) => {
    switch(action.type){
        case 'SET_INIT_VIDEOS': 
            return {
                ...state,
                videos: action.payload.videos,   
            }
        case 'VIDEO_LIKE':
            return { 
                // isAuthenticated: action.payload.isAuthenticated,
                // authToken: action.payload.token
            }
        case 'VIDEO_UNLIKE':
            return { 
                // isAuthenticated: action.payload.isAuthenticated,
                // authToken: action.payload.token
            }    
        case 'VIDEO_COMMENT':
            return { 
                // isAuthenticated: false,
                // authToken: null
            }
        case 'VIDEO_DELETE_COMMENT':
            return { 
                // isAuthenticated: false,
                // authToken: null
            }    
        default:
            return state    
    }
}