import React, { createContext, useReducer } from "react";
import { RegistrationContextReducer } from '../reducers/RegistrationContextReducer';

export const RegistrationContext = createContext();

const initialState = {
    forgotPasswordEmail: "",
    preRegisterEmail: "",
    preRegisterPhone: "",
    preRegisterActive: "phone",
    preRegisterType: "",
}

const RegistrationContextProvider = (props) => {
    const [RegistrationContextState, RegistraionContextDispatch ] = useReducer(RegistrationContextReducer,initialState);
    
    return (
        <RegistrationContext.Provider value={{RegistrationContextState, RegistraionContextDispatch}}>
            {props.children}
        </RegistrationContext.Provider>
    );
}

export default RegistrationContextProvider;