import React from 'react';

export default class EyeWhiteBorder extends React.Component {
    render() {
        return (
            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                <g id="invisible_box" data-name="invisible box">
                    <rect id="Rectangle_92" data-name="Rectangle 92" width="18" height="18" fill="none"/>
                </g>
                <g id="icons_Q2" data-name="icons Q2" transform="translate(0.775 4.215)">
                    <path id="Path_718" data-name="Path 718" d="M18.606,15.253h0c-.8-1-3.794-4.253-8.162-4.253s-7.357,3.257-8.162,4.253a1.15,1.15,0,0,0,0,1.456c.8,1,3.794,4.253,8.162,4.253s7.357-3.257,8.162-4.253A1.15,1.15,0,0,0,18.606,15.253ZM10.444,19.43c-3.372,0-5.863-2.376-6.782-3.449.92-1.073,3.41-3.449,6.782-3.449s5.863,2.376,6.782,3.449C16.306,17.054,13.816,19.43,10.444,19.43Z" transform="translate(-2.022 -11)" fill="#fff"/>
                    <circle id="Ellipse_147" data-name="Ellipse 147" cx="2" cy="2" r="2" transform="translate(6.225 2.785)" fill="#fff"/>
                </g>
            </svg>   
        )
    }
}