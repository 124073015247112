export const nullWrapper = {
  position: "relative",
  height: "auto",
  minHeight: "375px",
  overflow: "hidden",
  boxSizing: "border-box",
  display: "block",
  padding: "115px 20px",
  "& > svg": {
    display: "block",
    margin: "auto",
    marginBottom: "30px"
  }
}

export const nullHeader = {
  position: "relative",
  color: "rgba(98,74,251,1)",
  fontSize: "16px",
  fontWeight: "500"
}

export const nullSubHeader = {
  position: "relative",
  color: "rgba(138,139,144,1)",
  fontSize: "12px"
}

export const nullLinkText = {
  position: "relative",
  color: "rgba(98,74,251,1)",
  fontSize: "12px",  
  margin: "5px auto"
}