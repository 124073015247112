//https://reactjs.org/docs/hooks-reference.html#usereducer
import React, { createContext, useReducer, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';

import { VersionContextReducer } from '../reducers/VersionContextReducer';
import { VersionInitialState } from "../constants/VersionInitialState";
import { GetVersion } from '../services/AuthServices';
import { GlobalContext } from './GlobalContext';

export const VersionContext = createContext();

const initialState = VersionInitialState;

const VersionContextProvider = (props) => {
    const [VersionContextState, VersionContextDispatch] = useReducer(VersionContextReducer,initialState);    
    const location = useLocation();
    const { versionNumber, newVersion } = VersionContextState;
    const { GlobalContextState, GlobalContextDispatch } = useContext(GlobalContext);  
    const { showBackdrop } = GlobalContextState;
    const { enqueueSnackbar } = useSnackbar(); 

    const refreshAction = (key) => { //render the snackbar button
        return (
            <Button onClick={(e)=>{ window.location.reload(true) }}>      
                {"Update"}
            </Button>
        );
    };

    useEffect(() => {
        if ("onLine" in navigator) {
            // console.log('navigator.onLine1',navigator.onLine)
            window.addEventListener('online', onlineHanlder);
            window.addEventListener('offline', offlineHandler);
            // cleanup this component
            // return () => {
            //     window.removeEventListener('online', onlineHanlder);
            //     window.removeEventListener('offline', offlineHandler);
            // };
        }  

        if (newVersion === false) {
            const timer = setTimeout(() => {
                GetVersion().then((res) => {                    
                    if (versionNumber < res.data.version) {
                        console.log('new version');
                        VersionContextDispatch({ type: "SET_NEW_VERSION_AND_NUMBER", payload: { newVersion : true, versionNumber: res.data.version }});
                    } else {
                        console.log('no new version');
                    }
                }).catch(err => {
                    console.log("catch GetVersion",err.response.data,err.response.status);            
                });
            }, 1000);
            return () => {
                clearTimeout(timer);
                if ("onLine" in navigator) {
                    window.removeEventListener('online', onlineHanlder);
                    window.removeEventListener('offline', offlineHandler);
                }
            };
        } else {
            return () => {
                if ("onLine" in navigator) {
                    window.removeEventListener('online', onlineHanlder);
                    window.removeEventListener('offline', offlineHandler);
                }
            };
        }         
    },[location])

    const onlineHanlder = () => {
        if ("onLine" in navigator) {
            GlobalContextDispatch({ type: "SHOW_BACKDROP", payload: { showBackdrop : false }});  
        }
    }

    const offlineHandler = () => {
        if ("onLine" in navigator) {
            enqueueSnackbar('You are offline', { variant: 'default', open: showBackdrop, autoHideDuration: 6000, anchorOrigin: { vertical: 'top', horizontal: 'center' } });
        }
    }

    // const idleHandler = (idleDetector) => {
    //     if ('IdleDetector' in window) {
    //         const { userState, screenState } = idleDetector;
    //         if (userState === 'idle') {
    //             console.log('idle',userState,screenState)
    //         }
    //         if (screenState === 'locked') {
    //             console.log('screenState',screenState,userState)
    //         }   
    //     } 
    // }

    useEffect(() => {
        if (newVersion) {
            VersionContextDispatch({ type: "SET_NEW_VERSION", payload: { newVersion : false }});
            GlobalContextDispatch({ type: "SHOW_BACKDROP", payload: { showBackdrop : true }});  
            // enqueueSnackbar('New app version is available', { variant: 'default', action: refreshAction(), autoHideDuration: null, anchorOrigin: { vertical: 'bottom', horizontal: 'center' } });
            enqueueSnackbar('New app version is available...restarting', { variant: 'default', autoHideDuration: null, anchorOrigin: { vertical: 'bottom', horizontal: 'center' } });
            const timer = setTimeout(() => {
                window.location.reload(true);
            }, 3000);
        }        
    }, [versionNumber, newVersion])

    return (
        <VersionContext.Provider value={{VersionContextState, VersionContextDispatch}}>
            {props.children}
        </VersionContext.Provider>
    )
}
export default VersionContextProvider;