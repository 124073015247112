export const feedBottomBar = {
  // bottom: "90px",
  bottom: "140px",
  left: "0px",
  color: "rgba(255,255,255,1)",
  width: "100%",
  height: "auto",
  // maxHeight: "30vh",
  position: "absolute",
  backgroundColor: "transparent",
  overflow: "hidden",
  zIndex: "10",
  transition: "all 100ms linear",
  "-webkit-transition": "all 100ms linear",
  "-moz-transition": "all 100ms linear",
  "-o-transition": "all 100ms linear",  
  "&.pwa": {
    bottom: "20px"
  },
  "&.hideVideoBottomBar": {
    left: "-100vw"
  }
}

export const feedDescriptionWrapper = {
  padding: "0 0 0 15px",
  bottom: "15px",
  left: "0px",
  color: "rgba(255,255,255,1)",
  width: "100%",
  height: "auto",
  position: "absolute",
  backgroundColor: "transparent",
  overflow: "hidden",
  zIndex: "10",
  transition: "all 100ms linear",
  "-webkit-transition": "all 100ms linear",
  "-moz-transition": "all 100ms linear",
  "-o-transition": "all 100ms linear",
  "&.mobile.not-pwa": {
    bottom: "125px"
  },
  "&.pwa": {
    bottom: "15px"
  },
  "&.hideVideoBottomBar": {
    left: "-100vw"
  }
}

export const feedDescriptionText = {
  fontSize: "14px",
  fontWeight: "300",
  color: "rgba(255,255,255,1)",
  textShadow: "rgb(0 0 0) 1px 1px",
  position: "relative",
  height: "auto",
  width:  "100%",
  padding: "0 50px 0 0",
  overflow: "hidden",
  display: "block",
  maxHeight: "5vh",
  "&.show": {
    maxHeight: "unset"
  }
}

export const feedButtonIcon = {
  width: "100%",
  background: "none",
  border: "none",
  color: "rgba(255,255,255,1)",
  margin: "0",
  padding: "0"
}

export const feedArrowActiveIcon = {
  position: "absolute",
  color: "rgba(255,255,255,1)",
  padding: "0px",
  bottom: "5px",
  right: "9px",
  display: "block"
}

export const feedAvatarName = {
  // height: "50px",
  lineHeight: "40px",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  display: "inline-block",
  width: "auto",
  paddingLeft: "50px",
  textShadow: "rgba(0, 0, 0, 1) 1px 1px",
  fontSize: "14px",
  fontWeight: "600",
  position: "relative"
}

export const feedFollowButton = {
  // height: "50px",
  lineHeight: "10px",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  display: "inline-block",
  width: "auto",
  padding: "10px 15px",
  textShadow: "rgba(0, 0, 0, 0) 1px 1px",
  fontSize: "14px",
  fontWeight: "400",
  position: "relative",
  margin: "5px 10px",
  border: "1px solid rgba(255,255,255,1)",
  borderRadius: "5px"
}