import React, { useState, useContext, useEffect } from 'react';
import { Redirect, useHistory, useParams } from "react-router-dom";

import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';

import { containerStyleWhite2, formGridStyle, formGridButtonSubmit, headerDefaultStyle, bodyDefaultStyle } from '../../styles/defaultStyles.2';
import { ErrorTextStyle, ErrorTextContainerStyle } from '../../styles/defaultErrorStyles';
import { inputDefault, inputUnderline } from '../../styles/defaultInput';
import { gridItemHeaderWrapper2, gridCodeWrapper, gridItemCodeWrapper } from '../../styles/defaultGridStyles';

import SubmitButton from '../../components/SubmitButton.3';
import HelmetComponent from "../../components/HelmetComponent";
import LinkText from "../../components/LinkText.3";

import { RegistrationContext } from '../../contexts/RegistrationContext';
import { FramerContext } from '../../contexts/FramerContext';
import { GlobalContext } from '../../contexts/GlobalContext';
import { FormErrorContext } from '../../contexts/FormErrorContext';

import { ValidateContactService } from "../../services/RegistrationServices";

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  inputCodeClass : {
    fontSize: "30px",
    textAlign: "center",
    letterSpacing: "4vw",
    "&::placeholder": {
      fontSize: "16px",
      letterSpacing: "0px"
    }
  },
  inputClassWrapper : {
    width: "100%"
  },
  textCenter: {
    textAlign: "center"
  },
  resendText: {
    display:'block',
    margin: '20px auto',
    fontSize: '16px',
    color: 'rgba(139,140,144,1)'
  },
  containerStyleWhite2: containerStyleWhite2, 
  formGridStyle: formGridStyle, 
  formGridButtonSubmit: formGridButtonSubmit, 
  headerDefaultStyle: headerDefaultStyle, 
  bodyDefaultStyle: bodyDefaultStyle,
  gridItemHeaderWrapper2: gridItemHeaderWrapper2,
  gridCodeWrapper: gridCodeWrapper,
  gridItemCodeWrapper: gridItemCodeWrapper,
  inputDefault: inputDefault,
  inputUnderline: inputUnderline,
  ErrorTextStyle: ErrorTextStyle, 
  ErrorTextContainerStyle: ErrorTextContainerStyle,
  blackText: {
    color: "rgba(0,0,0,1)"
  }
}));

export default function ValidateContact() {
  const classes = useStyles();
  let history = useHistory();
  const { tokenExpired, registerType } = useParams();
  const type = ((registerType === 'creator') ? registerType : 'user');
  const { GlobalContextDispatch } = useContext(GlobalContext);
  const { toggleDirection } = useContext(FramerContext);
  const { RegistrationContextState, RegistraionContextDispatch } = useContext(RegistrationContext);
  const { FormErrorContextState, FormErrorContextDispatch } = useContext(FormErrorContext);
  const { codeError, requestError } = FormErrorContextState;  
  const { preRegisterPhone, preRegisterEmail, preRegisterActive } = RegistrationContextState;
  const [isActive, setIsActive] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [codeStr, setCodeStr] = useState('');
  
  useEffect(() => {
    if (!isSubmitted) {
      if (codeStr.length > 0) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    }

    if (!codeError.error && isSubmitted && codeStr.length !== 0) {
      const data = {
        contact: (preRegisterActive === "phone" ? preRegisterPhone : preRegisterEmail),
        active: preRegisterActive,
        code: (codeStr.toString()),
        type: type 
      }     
      ValidateContactService(data).then(result => {
        if (result.status === 200) {
          if (result.data.success === 1) {
            setIsActive(true);    
            RegistraionContextDispatch({ type: "RESET_ALL" });      
            toggleDirection(true,true);
            history.push('/registration/'+result.data.userType+"/"+result.data.userToken);
            GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }}); 
          } else {
            FormErrorContextDispatch({ type: "REQUEST_ERROR", payload: result.data});
            GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
            setIsActive(false); 
            setIsSubmitted(false);
          }
        } else {
          FormErrorContextDispatch({ type: "REQUEST_ERROR", payload: result.data});
          GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
          setIsActive(false); 
          setIsSubmitted(false);
        }
      }).catch(err => {
        FormErrorContextDispatch({ type: "REQUEST_ERROR", payload: err.response.data });  
        GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
        setIsActive(false);
        setIsSubmitted(false);
      })
    } else {
      if (isSubmitted) {
        setIsActive(false);
        setIsSubmitted(false);
        GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
      }
    }
  }, [codeStr, codeError])
  
  const handleSubmit = (e) => {
    e.preventDefault();
    GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : true }});
    FormErrorContextDispatch({ type: "VALIDATE_CODE", payload: codeStr.toString() });
    setIsSubmitted(true); 
  }

  return (
    <>
      { preRegisterPhone === '' && preRegisterEmail === '' ? <Redirect to="/" /> : null }

      <HelmetComponent title={"DTF - Verify Code"} />
      <Container classes={{ root: classes.containerStyleWhite2 }}>
        <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid
            container
            justify="flex-start"
            direction="column"
            spacing={0}
            classes={{ root: classes.formGridStyle }}
          >
            <Grid item classes={{ root: classes.gridItemHeaderWrapper2 }}>
              <Typography variant={"h1"} classes={{ root: classes.headerDefaultStyle }}>
                Verify { preRegisterActive === "phone" ? "Phone Number" : "Email Address"}
              </Typography>   
            </Grid>
            <Grid item> 
              <Typography classes={{ root: classes.bodyDefaultStyle }}>
                Verification code has been sent to <span className={classes.blackText}>{ preRegisterActive === "phone" ? preRegisterPhone : preRegisterEmail}</span>.
              </Typography> 
            </Grid> 
            <Grid                  
              container 
              justify="center"
              alignItems="center"
              spacing={1}
              classes={{ root: classes.gridCodeWrapper }}
            >                
              <Grid item xs={12} classes={{ root: classes.gridItemCodeWrapper }}> 
                <Input type="text" name="vcode" placeholder={"Enter code"} error={codeError.error} classes={{ root: classes.inputClassWrapper, input : classes.inputCodeClass }} inputProps={{ 'aria-label': 'verification code', 'maxLength': 6, 'tabIndex': 1 }} value={codeStr} onChange={(e) => setCodeStr(e.target.value.trim())} />
              </Grid>              
              {
                codeError.error
                ?
                  <Grid item xs={12} classes={{ root: classes.ErrorTextContainerStyle }}>
                    <Typography variant="body1" classes={{ root: classes.ErrorTextStyle }}>
                      { codeError.message }
                    </Typography>               
                  </Grid>
                :
                  null
              } 
              {
                requestError.error
                ?
                  <Grid item xs={12} classes={{ root: classes.ErrorTextContainerStyle }}>
                    <Typography variant="body1" classes={{ root: classes.ErrorTextStyle }}>
                      { requestError.message }
                    </Typography>               
                  </Grid>
                :
                  null
              }
            </Grid> 
            <Grid 
              item 
              container 
              justify="center"
              alignItems="center"
              spacing={2}
            >  
              <Typography variant={"body2"} component={"span"} align="center" classes={{ root: classes.resendText }}>
                Didn't get the code? <LinkText url={"/registration/verify-contact/"+type} directionBool={false}>Resend code</LinkText>
              </Typography>
            </Grid>
            <Grid container item direction="row" justify="center" alignItems="flex-end" classes={{ root: classes.formGridButtonSubmit }}>
              <SubmitButton isActive={isActive} />
            </Grid>  
          </Grid>
        </form>
      </Container>
    </>
  );
}
