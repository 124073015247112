import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from './svg/CloseIcon';
import { defaultVideoButton } from '../styles/defaultButtonStyles.2';

const useStyles = makeStyles((theme) => ({
  defaultVideoButton: defaultVideoButton
}));

export default function CloseCameraButton(props) {
  const { handleClick = (e) => { }, isActive=false, id="", facingMode='user', isRecording=false } = props;
  const classes = useStyles();
  if (isActive) {
    return (    
      <button type="button" id={id} className={classes.defaultVideoButton} onClick={(e) => handleClick(e)}>
        <CloseIcon />      
      </button>
    )
  } else {
    return (    
      <button type="button" id={id} className={classes.defaultVideoButton} disabled>
        <CloseIcon />
      </button>
    )
  }  
}