import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import FormErrorContextProvider from "../contexts/FormErrorContext";

const PublicPageRoute = ({ isAuth: isAuth, isLoading: isLoading, initialLoad: initialLoad, userType: userType, componentName: Component, ...rest }) => {
    return (
        <Route {...rest} render={(props) => {
            if (isAuth === false && isLoading === false) {
                return (<FormErrorContextProvider><Component {...props} /></FormErrorContextProvider>)           
            } else {
                if (!initialLoad && isAuth === true && isLoading === false) {
                    return <Redirect to={{ pathname: '/account/profile', state: { from: props.location } }} />                   
                } else {   
                    return null;
                }
                // return <Redirect to={{ pathname: '/account/profile', state: { from: props.location } }} /> 
            }
        }} />
    );
}

export default PublicPageRoute;