import React, { useState, useEffect, useContext } from 'react';
import { Container, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { feedSideBar  } from '../styles/defaultFeedSideBarStyles';
import { convertCountToString } from '../utils/CommonUtil';
import FeedHeartPinkIcon from './svg/FeedHeartPinkIcon';
import FeedHeartIcon from './svg/FeedHeartIcon';
import FeedCommentIcon from './svg/FeedCommentIcon';
import FeedShareIcon from './svg/FeedShareIcon';
import FeedLockIcon from './svg/FeedLockIcon';
import FeedHollaIcon from './svg/FeedHollaIcon';
import { DrawerSignUpContext } from '../contexts/DrawerSignUpContext';
import { DrawerCommentContext } from '../contexts/DrawerCommentContext';
import { DrawerShareContext } from '../contexts/DrawerShareContext';
import { GlobalContext } from '../contexts/GlobalContext';

import { AuthContext } from '../contexts/AuthContext';
import { LikeService, UnlikeService, subscribeService } from '../services/UserActionsServices';

const useStyles = makeStyles((theme) => ({    
    feedSideBar: feedSideBar,
    gridItemStyles: {
        padding: "0px 15px 30px !important",
        "&.holla": {
            padding: "0px 15px 38px !important",
        },
        "&.vip": {
            padding: "0px 15px 35px !important",
        }
    },
    gridItemStyles2: {
        padding: "0px 15px 20px !important"
    },
    iconLabelStyle: {
        fontSize: "14px",
        lineHeight: "14px",
        fontWeight: "300",
        textShadow: "rgb(0 0 0) 1px 1px",
        position: "relative",
        "&.share": {
            fontWeight: "300"
        }
    },
    buttonIcon: {
        background: "none",
        border: "none",
        color: "rgba(255,255,255,1)",
        margin: "0",
        padding: "0",
        width: "30px",
        height: "30px",
        "& > svg": {
            // width: "inherit",
            // height: "inherit"
        }
    },
    soundWrapper: {
        display: "block",
        width: "26px",
        height: "20px",
        textAlign: "center",
        border: "1px solid rgba(255,255,255,1)",
        borderRadius: "3px",
        padding: "3px 0px",
        margin: "10px auto 5px",
        boxSizing: "border-box"
    },
    soundStroke: {
        display: "inline-block",
        position: "relative",
        background: "rgba(255,255,255,1)",
        height: "100%",
        width: "1px",
        borderRadius: "1px",
        margin: "0px 1px",
        'webkit-animation': '$animateStroke 1.2s linear infinite',
        '-moz-animation': '$animateStroke 1.2s linear infinite',
        '-o-animation': '$animateStroke 1.2s linear infinite',
        animation: "$animateStroke 1.2s linear infinite",
        "&:nth-child(1)": {
            animationDelay: "0.2s"
        },
        "&:nth-child(2)": {
            animationDelay: "0.5s"
        },
        "&:nth-child(3)": {
            animationDelay: "1s"
        },
        "&:nth-child(4)": {
            animationDelay: "0.3s"
        },
        "&:nth-child(5)": {
            animationDelay: "7s"
        },
        "&:nth-child(6)": {
            animationDelay: "5s"
        }
        
    },
    "@keyframes animateStroke": {
        "50%": {
          height: "20%"
        },
        "80%": {
            height: "80%"
        }
      }
}));

function FeedSideBar(props) {
    const classes = useStyles();

    const { userInformation={}, toggleLike=false, setToggleLike=null, itemRef=null, noBottomBar=false, isSideBarWhite=false, item, user, indexUpdateCommentCount=[], index: activeIndex, toggleSound=null, isMuted=null } = props;
    const { _id = "", comments = 0, likes = 0, isLike = false, video_information = null } = item;
    const { _id: userId, username = "", userType = "user", handlename = "" } = user;
    
    const userInformationPlaceHolder = { _id: "", isFollow: false, isSubscribe: false, user_image_profile: "", isPlaceHodler: true };
    const { _id: userInfoId, isFollow=false, isSubscribe=false, user_image_profile="", isPlaceHodler=false } = userInformation || userInformationPlaceHolder;
    
    const { DrawerSignUpContextState, DrawerSignUpContextDispatch } = useContext(DrawerSignUpContext);
    const { showDrawer, showSubscribeDrawer, showOneOnOneDrawer, subscribeName } = DrawerSignUpContextState;
    
    const { DrawerCommentContextState, DrawerCommentContextDispatch } = useContext(DrawerCommentContext);
    const { showDrawerComment } = DrawerCommentContextState;

    const { DrawerShareContextState, DrawerShareContextDispatch } = useContext(DrawerShareContext);
    const { showDrawerShare } = DrawerShareContextState;
    
    const { GlobalContextState, GlobalContextDispatch } = useContext(GlobalContext);
    const { iOSInstaller, isPwaMobile, isMobile } = GlobalContextState; 

    const { AuthContextState } = useContext(AuthContext);
    const { isAuthenticated, authToken } = AuthContextState;
    const [ like, setLike ] = useState(isLike || false);
    const [ likeCount, setLikeCount ] = useState(likes);
    const [ commentsCount, setCommentsCount ] = useState(comments || 0);
    const [ muteText, setMuteText ] = useState("Unmute");
    const [ isSubscribed, setIsSubscribed ] = useState(isSubscribe)
    const showDrawerClick = (e) => {
        DrawerSignUpContextDispatch({ type: "SHOW_DRAWER" });
    }

    const showDrawerCommentClick = (e) => {
        DrawerCommentContextDispatch({ type: "SHOW_DRAWER", payload: { videoId: _id, currentIndex: activeIndex }});
    }

    const showDrawerShareClick = (e) => {
        if (navigator.share) {
            navigator.share({
              title: 'GLOSS',
              text: `Check out @${handlename}${handlename.charAt(handlename.length - 1) === "s" || handlename.charAt(handlename.length - 1) === "S" ? "'": "'s"} ${video_information && video_information.resource_type === "image" ? "picture" : "video"}` ,
              url: window.location.origin + "/videos/latest/" + _id,
            })
            .then(() => console.log('Successful share'))
            .catch((error) => console.log('Error sharing', error));
        }
        // DrawerShareContextDispatch({ type: "SHOW_DRAWER", payload: { videoId: _id, currentIndex: activeIndex }});
    }

    const likeClick = (e) => {
        //update ui to avoid delay on ui
        setLike(true);
        setLikeCount(likeCount + 1);

        // console.log('likeClick item',item);
        const objData = {
            videoId: item._id
        }
        LikeService(objData, authToken).then(result => {    
            // console.log('result.data', result.data)
            // setLike(true);
            // setLikeCount(likeCount + 1);
        }).catch(err => {
            // console.log("catch LikeService",err);
        })  
    }

    const unlikeClick = (e) => {
        // console.log('likeClick item',item);

        //update ui to avoid delay on ui
        setLike(false);
        setLikeCount(likeCount - 1);

        const objData = {
            videoId: item._id
        }
        UnlikeService(objData, authToken).then(result => {    
            // console.log('result',result)
            // setLike(false);
            // setLikeCount(likeCount - 1);
        }).catch(err => {
            // console.log("catch LikeService",err);
        })  
    }

    const subscribeClick = (e) => {
        e.preventDefault();

        //update ui to avoid delay on ui
        setIsSubscribed(true)

        const objData = {
            profile_user_id: userId,
            user_information_id: userInfoId
        }
        subscribeService(objData, authToken).then(result => {
            if (result.data.success === 1) {
                // setIsSubscribed(true)
            }
            return true;
        }).catch(err => {
            console.log("catch subscribeService",err);
        });
    }

    const toggleMute = (e) => {
        console.log('sidebar toggleMute', isMuted)
        try {
            const vid = itemRef.current;
            console.log('sidebar vid', vid)
            if (vid !== null) {                 
                vid.muted = !vid.muted;
            } 
            
        } catch(err) {
            console.log('toggleMute sidebar err', err)
        }

        if (isMuted) {            
            toggleSound(false);
        } else {
            toggleSound(true);
        }      
    }

    useEffect(() => {
        if (indexUpdateCommentCount.length !== 0){
            indexUpdateCommentCount.map(item => {
                if (item.index === activeIndex) {
                    if (item.isAdd) {
                        setCommentsCount(parseInt(commentsCount) + 1);                        
                    } else {
                        setCommentsCount(parseInt(commentsCount) - 1);
                    }
                    DrawerCommentContextDispatch({ type: "RESET_INDEX_UPDATE_COMMENT_COUNT" });                  
                }
            })  
        }
    }, [indexUpdateCommentCount]);    

    useEffect((e) => {
        if (toggleLike) {
            if(isAuthenticated) {
                if (like) {
                    unlikeClick();
                } else {
                    likeClick();
                }
            } else {
                showDrawerClick();
            }                
        }
        setToggleLike(false);
    }, [toggleLike])

    return (
        <Container classes={{ root: classes.feedSideBar }} className={(isMobile ? " mobile" : "") + (isPwaMobile ? " pwa" : " not-pwa") + (showDrawer || showSubscribeDrawer || showOneOnOneDrawer || showDrawerComment || showDrawerShare || iOSInstaller ? " hideSideBar" : "")}>
            <div className={classes.gridItemStyles  + " holla"}>                    
                { 
                    isAuthenticated
                    ?                       
                        <button className={classes.buttonIcon}>
                            <FeedHollaIcon />
                            <Typography variant={"body2"} align="center" classes={{ root: classes.iconLabelStyle }}>
                                Holla
                            </Typography>
                        </button>                                
                    :
                        <button className={classes.buttonIcon} onClick={(e) => showDrawerClick(e)}> 
                            <FeedHollaIcon />
                            <Typography variant={"body2"} align="center" classes={{ root: classes.iconLabelStyle }}>
                                Holla
                            </Typography>
                        </button>
                }                    
            </div>                    
            { 
                isAuthenticated
                ?                       
                    isSubscribed
                    ?
                        null
                    :
                        <div className={classes.gridItemStyles + " vip"}> 
                            <button className={classes.buttonIcon} onClick={(e) => subscribeClick(e)}>
                                <FeedLockIcon />
                                <Typography variant={"body2"} align="center" classes={{ root: classes.iconLabelStyle }}>
                                    VIP
                                </Typography>
                            </button> 
                        </div>                                
                :
                    <div className={classes.gridItemStyles + " vip"}> 
                        <button className={classes.buttonIcon} onClick={(e) => showDrawerClick(e)}> 
                            <FeedLockIcon />
                            <Typography variant={"body2"} align="center" classes={{ root: classes.iconLabelStyle }}>
                                VIP
                            </Typography>
                        </button>
                    </div>
            }                   
            <div className={classes.gridItemStyles}>                    
                { 
                    isAuthenticated
                    ?
                        like
                            ?
                                <button className={classes.buttonIcon} onClick={(e) => unlikeClick(e)}>
                                    <FeedHeartPinkIcon />
                                    <Typography variant={"body2"} align="center" classes={{ root: classes.iconLabelStyle }}>
                                        {convertCountToString(likeCount)}
                                    </Typography>
                                </button>
                            :
                                <button className={classes.buttonIcon} onClick={(e) => likeClick(e)}>
                                    <FeedHeartIcon />
                                    <Typography variant={"body2"} align="center" classes={{ root: classes.iconLabelStyle }}>
                                        {convertCountToString(likeCount)}
                                    </Typography>
                                </button>
                                
                    :
                        <button className={classes.buttonIcon} onClick={(e) => showDrawerClick(e)}> 
                            <FeedHeartIcon />
                            <Typography variant={"body2"} align="center" classes={{ root: classes.iconLabelStyle }}>
                                {convertCountToString(likeCount)}
                            </Typography>
                        </button>
                }                    
            </div>                
            <div className={classes.gridItemStyles}>
                { 
                    isAuthenticated
                    ?
                        <button className={classes.buttonIcon} onClick={(e) => showDrawerCommentClick(e)}>
                            <FeedCommentIcon />
                            <Typography variant={"body2"} align="center" classes={{ root: classes.iconLabelStyle }}>
                                {convertCountToString(commentsCount)}
                            </Typography>
                        </button>
                    :
                        <button className={classes.buttonIcon} onClick={(e) => showDrawerClick(e)}>
                            <FeedCommentIcon />
                            <Typography variant={"body2"} align="center" classes={{ root: classes.iconLabelStyle }}>
                                {convertCountToString(commentsCount)}
                            </Typography>
                        </button>
                }
            </div>           
            <div className={classes.gridItemStyles2}>
                { 
                    isAuthenticated
                    ?
                        <button className={classes.buttonIcon} onClick={(e) => showDrawerShareClick(e)}>
                            <FeedShareIcon />
                            <Typography variant={"body2"} align="center" classes={{ root: classes.iconLabelStyle }} className={"share"}>
                                Share
                            </Typography>
                        </button>
                    :
                        <button className={classes.buttonIcon} onClick={(e) => showDrawerShareClick(e)}>
                            <FeedShareIcon />
                            <Typography variant={"body2"} align="center" classes={{ root: classes.iconLabelStyle }} className={"share"}>
                                Share
                            </Typography>
                        </button>
                }
            </div>
            {/* {
                video_information
                ?
                    video_information.resource_type === "video"
                    ?
                        <div className={classes.gridItemStyles2}>
                            <button className={classes.buttonIcon} onClick={(e) => toggleMute(e)}>
                                <div className={classes.soundWrapper}>
                                    <div className={classes.soundStroke}></div>
                                    <div className={classes.soundStroke}></div>
                                    <div className={classes.soundStroke}></div>
                                    <div className={classes.soundStroke}></div>
                                    <div className={classes.soundStroke}></div>
                                    <div className={classes.soundStroke}></div>
                                </div>    
                                <Typography variant={"body2"} align="center" classes={{ root: classes.iconLabelStyle }}>
                                    {
                                        isMuted === true
                                        ?
                                            "Unmute"
                                        :
                                            ""
                                    }
                                    {
                                        isMuted === false
                                        ?
                                            "Mute"
                                        :
                                            ""
                                    }
                                </Typography>
                            </button>
                        </div>
                    :
                        null
                :
                    null
            }    */}
        </Container>
    );
}

export default React.memo(FeedSideBar);