import React, { useContext, useState, useEffect } from 'react';
import { useHistory, useParams, Redirect } from "react-router-dom";

import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SubmitButton from '../../components/SubmitButton.2';
import HelmetComponent from "../../components/HelmetComponent";
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import { containerStyleWhite2, formGridStyle, formGridButtonSubmit, headerDefaultStyle, inputReadOnly, bodyDefaultStyle } from '../../styles/defaultStyles.2';
import { ErrorTextStyle, ErrorTextContainerStyle } from '../../styles/defaultErrorStyles';
import { inputDefault, inputUnderline, eyeIcon, eyeActiveIcon, atIcon, atActiveIcon, inputCountryCodeDefault, inputPhoneDefault, inputPhoneUnderline } from '../../styles/defaultInput';
import { gridItemHeaderWrapper } from '../../styles/defaultGridStyles';
import { FormErrorContext } from '../../contexts/FormErrorContext';
import { FramerContext } from '../../contexts/FramerContext';
import { GlobalContext } from '../../contexts/GlobalContext';
import { AuthContext } from '../../contexts/AuthContext';
import { DrawerSignUpContext } from '../../contexts/DrawerSignUpContext';

import { CreateUserService } from "../../services/RegistrationServices";
import { GetToken } from "../../utils/TokenUtil";

import { CountryCode } from "../../constants/CountryCode";

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    '& .Mui-focused': {
      '& .MuiSelect-root': {
        backgroundColor: 'rgba(255,255,255,1)'       
      }      
    },
    '& .MuiSelect-root': inputCountryCodeDefault,
    '& .MuiInput-underline': {
      '&::before': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.2)'
      }  
    }
  },
  containerStyleWhite2: containerStyleWhite2, 
  formGridStyle: formGridStyle, 
  formGridButtonSubmit: formGridButtonSubmit, 
  headerDefaultStyle: headerDefaultStyle, 
  inputReadOnly: inputReadOnly, 
  bodyDefaultStyle: bodyDefaultStyle,
  ErrorTextStyle: ErrorTextStyle, 
  ErrorTextContainerStyle: ErrorTextContainerStyle,
  inputDefault: inputDefault, 
  inputUnderline: inputUnderline,
  inputCountryCodeDefault: inputCountryCodeDefault,
  inputPhoneDefault: inputPhoneDefault,  
  inputPhoneUnderline: inputPhoneUnderline, 
  eyeIcon: eyeIcon,
  eyeActiveIcon: eyeActiveIcon,
  atIcon: atIcon,
  atActiveIcon: atActiveIcon, 
  gridItemHeaderWrapper: gridItemHeaderWrapper
}));

export default function Registration() {
  const classes = useStyles();
  let history = useHistory();
  const { token } = useParams();
  const decodeToken = GetToken(token);
  const tokenContact = (typeof decodeToken.contact !== 'undefined' ? decodeToken.contact : '');
  const tokenContactType = (typeof decodeToken.contactType !== 'undefined' ? decodeToken.contactType : '');
  const tokenUserType = (typeof decodeToken.userType !== 'undefined' ? decodeToken.userType : 'user');
  const tokenExpDate = (typeof decodeToken.exp !== 'undefined' ? decodeToken.exp : '');
  const nowDate = new Date(); 
  const { AuthContextState, AuthContextDispatch } = useContext(AuthContext);
  const { isAuthenticated } = AuthContextState;     
  const { GlobalContextDispatch } = useContext(GlobalContext);
  const { toggleDirection } = useContext(FramerContext);
  const { FormErrorContextState, FormErrorContextDispatch } = useContext(FormErrorContext);  
  const { usernameError, emailError, phoneError, passwordError, handlenameError, requestError } = FormErrorContextState;
  const { DrawerSignUpContextState, DrawerSignUpContextDispatch } = useContext(DrawerSignUpContext);
  const { showDrawer } = DrawerSignUpContextState;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [handlename, setHandlename] = useState('');  
  const [registerEmail, setRegisterEmail] = useState((tokenContactType === 'email' ? tokenContact : ''));
  const [emailVerified] = useState((tokenContactType === 'email' ? true : false));
  const [registerPhone, setRegisterPhone] = useState((tokenContactType === 'phone' ? tokenContact : ''));
  const [phoneVerified] = useState((tokenContactType === 'phone' ? true : false));  
  const [showPassword, setShowPassword] = useState(false);
  const [countryCode, setCountryCode] = useState('+1');

  const handleChangeSelect = (event) => {
    setCountryCode(event.target.value);
  };

  useEffect(() => {
    if (showDrawer) {
      DrawerSignUpContextDispatch({ type: "HIDE_DRAWER", payload: false });    
    }    
  }, [])

  useEffect(() => {
    
    if (!isSubmitted) {
      if (username.length > 0 || password.length > 0 || registerEmail.length > 0 || registerPhone.length > 0 || handlename.length > 0) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
  
      if (usernameError.error || passwordError.error || handlenameError.error || requestError.error) {
        FormErrorContextDispatch({ type: "RESET_ERRORS" })
      }  
    }

    if (!usernameError.error && username.length !== 0 && !passwordError.error && password.length !== 0 && !handlenameError.error && handlename.length !== 0 && isSubmitted) {
      const data = {
        username: username.trim(),
        handlename: handlename,
        email: registerEmail,
        emailVerified: emailVerified,
        password: password,
        // phone: (tokenContactType !== 'phone' ? countryCode.toString() + registerPhone.toString() : registerPhone.toString()),
        // NOPHONE-213423424324 sample due to DB field is unique
        phone: registerPhone === "" ? "NOPHONE-" + Date.now() : registerPhone,
        phoneVerified: phoneVerified,
        userType: tokenUserType,
        active: tokenContactType,
        token: token 
      }
      CreateUserService(data).then(result => {
        AuthContextDispatch({ type: "SIGN_ON", payload: { isAuthenticated: true, authToken: result.data.token, userId: result.data.userId, registerFlow: true, userType: result.data.userType }});    
        toggleDirection(true,true);    
        history.push('/account/information');
        GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
      }).catch(err => {
        FormErrorContextDispatch({ type: "REQUEST_ERROR", payload: err.response.data });  
        GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});     
        setIsActive(false);
        setIsSubmitted(false);
      })
    } else {
      if (isSubmitted) {
        GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
        setIsSubmitted(false);
      } 
    }
    
  }, [username, password, registerEmail, registerPhone, handlename, usernameError, emailError, phoneError, passwordError, handlenameError])

  const handleSubmit = (e) => {    
    e.preventDefault();
    GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : true }});
    FormErrorContextDispatch({ type: "VALIDATE_USERNAME", payload: username.trim() });
    // if (tokenContactType !== 'email') {
    //   FormErrorContextDispatch({ type: "VALIDATE_EMAIL", payload: registerEmail });
    // }
    // if (tokenContactType !== 'phone') {
    //   FormErrorContextDispatch({ type: "VALIDATE_PHONE", payload: countryCode.toString() + registerPhone.toString() });
    // }    
    FormErrorContextDispatch({ type: "VALIDATE_HANDLENAME", payload: handlename });
    FormErrorContextDispatch({ type: "VALIDATE_PASSWORD", payload: password });
    setIsSubmitted(true);  
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  }
  
  if (isAuthenticated === true && isSubmitted === false) {
    return <Redirect to={"/account/profile"} />
  } else {
    if (tokenExpDate !== '') {
      if (nowDate > tokenExpDate) {
        return <Redirect to={"/registration/verify-contact/" + tokenUserType + (nowDate > tokenExpDate ? "/expired" : "")} /> 
      } else {
        return (   
          <>
            <HelmetComponent title={"DTF - Registration"} />
            <Container classes={{ root: classes.containerStyleWhite2 }}>        
              <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid
                  container
                  direction="column"
                  spacing={0}
                  classes={{ root: classes.formGridStyle }}
                >
                  <Grid item>
                    <Typography variant={"h1"} classes={{ root: classes.headerDefaultStyle }}>
                      Lets get started
                    </Typography>   
                  </Grid>
                  <Grid item classes={{ root: classes.gridItemHeaderWrapper }}> 
                    <Typography classes={{ root: classes.bodyDefaultStyle }}>
                      {/* Lorem ipsum dolor eset sim artun */}
                    </Typography> 
                  </Grid>
                  <Grid 
                    item 
                    container 
                    justify="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item xs={12}>              
                      <Input type="text" classes={{ root: classes.inputDefault, underline: classes.inputUnderline }} fullWidth={true} placeholder="Page Name/Queen Name" error={usernameError.error} inputProps={{ 'aria-label': 'Username', 'tabIndex': 1 }} value={username} onChange={(e) => setUsername(e.target.value)} />
                    </Grid>
                    
                    <Grid item xs={12}>              
                      {/* <Input type="text" classes={{ root: classes.inputDefault, underline: classes.inputUnderline }} fullWidth={true} placeholder="@dtfhandle" error={handlenameError.error} inputProps={{ 'aria-label': 'Hanldename' }} value={handlename} onChange={(e) => setHandlename(e.target.value.trim())} /> */}
                      <Input                      
                        classes={{ root: classes.inputDefault, underline: classes.inputUnderline }} 
                        fullWidth={true} 
                        placeholder="dtfhandle" 
                        id="standard-adornment-handlename"
                        error={handlenameError.error} 
                        inputProps={{ 'aria-label': 'Handle Name', 'tabIndex': 2 }}
                        type={'text'}
                        value={handlename}
                        onChange={(e) => setHandlename(e.target.value.trim())}
                        startAdornment={
                          <InputAdornment position="start">
                            <AlternateEmailIcon classes={{ root: (handlename.length !== 0 ? classes.atActiveIcon : classes.atIcon) }} />
                          </InputAdornment>
                        }
                      />
                    </Grid>
                    {/* {
                      tokenContactType !== 'email'
                      ?
                        <Grid item xs={12}>   
                          <Input type="email" classes={{ root: classes.inputDefault, underline: classes.inputUnderline }} fullWidth={true} placeholder="xxxxx@email.com" error={emailError.error} inputProps={{ 'aria-label': 'Email', 'tabIndex': 3 }} value={registerEmail} onChange={(e) => setRegisterEmail(e.target.value.trim())} />
                        </Grid>
                      :
                        null
                    } */}
                    {/* {
                      tokenContactType !== 'phone'
                      ?
                          <Grid item xs={12}>
                            <TextField
                            id="country-code"
                            select
                            label=""
                            value={countryCode}
                            onChange={handleChangeSelect}
                            helperText=""
                          >
                            {CountryCode.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                          <Input type="phone" classes={{ root: classes.inputPhoneDefault, underline: classes.inputPhoneUnderline }} fullWidth={true} placeholder="Phone Number" error={phoneError.error} inputProps={{ 'aria-label': 'Phone number', 'tabIndex': 3  }} value={registerPhone} name="phone" onChange={(e) => setRegisterPhone(e.target.value.trim())} /> 
                        </Grid>
                      :
                        null
                    }                   */}
                    <Grid item xs={12}>   
                      {/* <Input type="password" classes={{ root: classes.inputDefault, underline: classes.inputUnderline }} fullWidth={true} placeholder="password" error={passwordError.error} inputProps={{ 'aria-label': 'Password' }} value={password} onChange={(e) => setPassword(e.target.value)} /> */}
                      <Input
                        classes={{ root: classes.inputDefault, underline: classes.inputUnderline }} 
                        fullWidth={true} 
                        placeholder="password" 
                        id="standard-adornment-password"
                        error={passwordError.error} 
                        inputProps={{ 'aria-label': 'Password', 'tabIndex': 4 }}
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value.trim())}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              classes={{ root: (password.length !== 0 ? classes.eyeActiveIcon : classes.eyeIcon) }}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </Grid>
                    {
                      usernameError.error
                      ?
                        <Grid item xs={12} classes={{ root: classes.ErrorTextContainerStyle }}>
                          <Typography variant="body1" classes={{ root: classes.ErrorTextStyle }}>
                            { usernameError.message }
                          </Typography>               
                        </Grid>
                      :
                        null
                    }
                    {
                      handlenameError.error
                      ?
                        <Grid item xs={12} classes={{ root: classes.ErrorTextContainerStyle }}>
                          <Typography variant="body1" classes={{ root: classes.ErrorTextStyle }}>
                            { handlenameError.message }
                          </Typography>               
                        </Grid>
                      :
                        null
                    } 
                    {/* {
                      emailError.error && tokenContactType !== 'email'
                      ?
                        <Grid item xs={12} classes={{ root: classes.ErrorTextContainerStyle }}>
                          <Typography variant="body1" classes={{ root: classes.ErrorTextStyle }}>
                            { emailError.message }
                          </Typography>               
                        </Grid>
                      :
                        null
                    } */}
                    {/* {
                      phoneError.error && tokenContactType !== 'phone'
                      ?
                        <Grid item xs={12} classes={{ root: classes.ErrorTextContainerStyle }}>
                          <Typography variant="body1" classes={{ root: classes.ErrorTextStyle }}>
                            { phoneError.message }
                          </Typography>               
                        </Grid>
                      :
                        null
                    } */}
                    {
                      passwordError.error
                      ?
                        <Grid item xs={12} classes={{ root: classes.ErrorTextContainerStyle }}>
                          <Typography variant="body1" classes={{ root: classes.ErrorTextStyle }}>
                            { passwordError.message }
                          </Typography>               
                        </Grid>
                      :
                        null
                    } 
                    {
                      requestError.error
                      ?
                        <Grid item xs={12} classes={{ root: classes.ErrorTextContainerStyle }}>
                          <Typography variant="body1" classes={{ root: classes.ErrorTextStyle }}>
                            { requestError.message }
                          </Typography>               
                        </Grid>
                      :
                        null
                    }                
                  </Grid> 
                  <Grid container item direction="row" justify="center" classes={{ root: classes.formGridButtonSubmit }}>
                    <SubmitButton isActive={isActive} />
                  </Grid>
                </Grid>
              </form>
            </Container>
          </>
        ) 
      }
    } else {
      return <Redirect to={"/registration/verify-contact/user/expired"} /> 
    }    
  }
  
}