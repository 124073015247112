import React from 'react';

export default class CloseIcon extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="28.116" height="28.115" viewBox="0 0 28.116 28.115">
                <g id="Group_2613" data-name="Group 2613" transform="translate(-344.387 -238.379)">
                    <path id="Path_17" data-name="Path 17" d="M0,0,23.872,23.872" transform="translate(370.381 240.5) rotate(90)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="3"/>
                    <path id="Path_247" data-name="Path 247" d="M0,0,23.872,23.872" transform="translate(370.382 264.372) rotate(180)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="3"/>
                </g>
            </svg>   
        )
    }
}