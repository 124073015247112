import React, { useContext, useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";

import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';

import { containerStyleWhite2, formGridStyle, formGridButtonSubmit, headerDefaultStyle } from '../../styles/defaultStyles.2';
import { ErrorTextStyle, ErrorTextContainerStyle } from '../../styles/defaultErrorStyles';
import { inputDefault, inputUnderline, eyeIcon } from '../../styles/defaultInput';
import { gridItemHeaderWrapper } from '../../styles/defaultGridStyles';

import SubmitButton from '../../components/SubmitButton.2';
import HelmetComponent from "../../components/HelmetComponent";

import { RegistrationContext } from '../../contexts/RegistrationContext';
import { FormErrorContext } from '../../contexts/FormErrorContext';
// import { FramerContext } from '../../contexts/FramerContext';
import { GlobalContext } from '../../contexts/GlobalContext';

import { ForgotPasswordService } from "../../services/RegistrationServices";

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  containerStyleWhite2: containerStyleWhite2, 
  formGridStyle: formGridStyle,
  formGridButtonSubmit: formGridButtonSubmit,
  headerDefaultStyle: headerDefaultStyle,
  gridItemHeaderWrapper: gridItemHeaderWrapper,
  ErrorTextStyle: ErrorTextStyle,
  ErrorTextContainerStyle: ErrorTextContainerStyle,
  inputDefault: inputDefault,  
  inputUnderline: inputUnderline
}));

export default function ForgotPassword() {
  const classes = useStyles();
  let history = useHistory();
  const { tokenExpired } = useParams();
  const tokenBoolean = ((tokenExpired === 'expired') ? true : false);
  // const { toggleDirection } = useContext(FramerContext);
  const { GlobalContextDispatch } = useContext(GlobalContext);
  const { RegistraionContextDispatch } = useContext(RegistrationContext);
  const { FormErrorContextState, FormErrorContextDispatch } = useContext(FormErrorContext);
  const { emailError, requestError } = FormErrorContextState;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [email, setEmail] = useState('');
  
  useEffect(() => {
    if (!isSubmitted) {
      if (email.length > 0) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
      if (emailError.error) {
        FormErrorContextDispatch({ type: "RESET_ERRORS" })
      } 
    }
    if (!emailError.error && email.length !== 0 && isSubmitted) {
      const data = {
        email: email
      } 
      ForgotPasswordService(data).then(result => {        
        RegistraionContextDispatch({ type: "SEND_FORGOT_PASSWORD", payload: { email: email }})
        // toggleDirection(true,true);
        // history.push('/forgot-password/email-sent');
        history.push('/forgot-password/validate-contact')
        GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
      }).catch(err => {
        console.log("catch",err.response.data,err.response.status);
        FormErrorContextDispatch({ type: "REQUEST_ERROR", payload: err.response.data }); 
        GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});              
        setIsActive(false);
        setIsSubmitted(false);
      })
    } else {
      if (isSubmitted) {
        GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
        setIsSubmitted(false);
      }
    }
  }, [email, emailError]);

  const handleSubmit = (e) => {    
    e.preventDefault();
    GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : true }})    
    FormErrorContextDispatch({ type: "VALIDATE_EMAIL", payload: email });
    setIsSubmitted(true);  
  }

  return (
    <>
      <HelmetComponent title={"DTF - Forgot Password"} />       
      <Container classes={{ root: classes.containerStyleWhite2 }}>
        <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid
            container
            direction="column"
            spacing={0}
            classes={{ root: classes.formGridStyle }}
          >
            <Grid item classes={{ root: classes.gridItemHeaderWrapper }}>
              <Typography variant={"h1"} classes={{ root: classes.headerDefaultStyle }}>
                { 
                  tokenBoolean 
                    ? 
                      'Your time to reset your password has expired. Please enter your email again.' 
                    : 
                      'Forgot Password'
                }          
              </Typography>   
            </Grid>
            <Grid 
              item 
              container 
              justify="center"
              alignItems="center"
              spacing={2}
            >          
              <Grid item xs={12} classes={{ root: classes.gridItemInputWrapper }}>   
                <Input type="email" classes={{ root: classes.inputDefault, underline: classes.inputUnderline }} fullWidth={true} placeholder="xxxxx@email.com" error={emailError.error} inputProps={{ 'aria-label': 'Email' }} value={email} onChange={(e) => setEmail(e.target.value.trim())} />
              </Grid>
              {
                emailError.error
                ?
                  <Grid item xs={12} classes={{ root: classes.ErrorTextContainerStyle }}>
                    <Typography variant="body1" classes={{ root: classes.ErrorTextStyle }}>
                      { emailError.message }
                    </Typography>               
                  </Grid>
                :
                  null
              }    
              {
                requestError.error
                ?
                  <Grid item xs={12} classes={{ root: classes.ErrorTextContainerStyle }}>
                    <Typography variant="body1" classes={{ root: classes.ErrorTextStyle }}>
                      { requestError.message }
                    </Typography>               
                  </Grid>
                :
                  null
              }          
            </Grid>         
            <Grid container item direction="row" justify="center" alignItems="flex-end" classes={{ root: classes.formGridButtonSubmit }}>
              <SubmitButton isActive={isActive} />
            </Grid>  
          </Grid>
        </form>
      </Container>
    </>
  );
}
