import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App.3";
import * as serviceWorker from "./serviceWorker";
import serviceWorkerConfig from "./serviceWorkerConfig";
import reportWebVitals from './reportWebVitals';
import { SnackbarProvider } from 'notistack';
import GlobalContextProvider from './contexts/GlobalContext';
import AuthContextProvider from './contexts/AuthContext';
import FramerContextProvider from './contexts/FramerContext';
import VersionContextProvider from './contexts/VersionContext';

ReactDOM.render(
  <BrowserRouter>
    <SnackbarProvider
      preventDuplicate  
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      maxSnack={3}
      classes={{
        variantInfo: {
          backgroundColor: 'rgba(253,45,85,1)'
        }
      }}
    > 
      <GlobalContextProvider>
        <VersionContextProvider>                    
          <FramerContextProvider>
            <AuthContextProvider>
              <App />
            </AuthContextProvider>         
          </FramerContextProvider>
        </VersionContextProvider>
      </GlobalContextProvider>
    </SnackbarProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register(serviceWorkerConfig);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
