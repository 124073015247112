import React, { useContext, useState, useEffect, useRef }  from 'react';
import { useParams } from "react-router-dom";

import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { containerStyleWhiteMessages } from '../../styles/defaultStyles.2';
import { tabs, tabsSelected, tabs50, tabs50Selected, tabsContainerFixed, tabsWrapper2, muiTabWrapper, muiTabWrapperLast, muiTabWrapperV2, muiTabWrapperV2Last, muiTabWrapperSelect } from '../../styles/defaultTabsStyles.2';

import HelmetComponent from '../../components/HelmetComponent';
import InfiniteScroll from '../../components/InfiniteScroll';
import ActivityItem from '../../components/ActivityItem.3';
import ActivitiesNullCase from '../../components/ActivitiesNullCase';

import { GlobalContext } from '../../contexts/GlobalContext';
import { AuthContext } from '../../contexts/AuthContext';

import { GetUserActivitiesService, GetUserNotificationVapIdService, PostUserNotificationRegisterService } from '../../services/UserServices';

import { urlBase64ToUint8Array } from '../../utils/CommonUtil';
import { vh } from '../../constants/VideoSettings';

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    padding: "0px 20px",
    position: "absolute",
    top: "95px",
    left: "0",
    bottom: "75px",
    overflow: "hidden auto",
    width: "100vw"
  },
  bannerWrapper: {
    position: "relative",
    display: "block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    height: "175px",
    width: "100%",
    border: "0px solid rgba(0,0,0,1)"
  },
  itemBannerWrapper: {
    position: "relative",
    display: 'inline-block',
    width: "96%",
    height: "145px",
    margin: "10px"
  },
  containerStyleWhiteMessages: containerStyleWhiteMessages,
  resultContainer: {
    padding: "0px",
    overflowX: "hidden",
    overflowY: "auto",
  },
  resultGridContainer: {
    height: "100%"
  },
  overlayGradientTop: {
    width: "100%",
    top: "100px",
    height: "25px",
    position: "fixed",
    background: "linear-gradient(to bottom, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0))",
    zIndex: "1"
  },
  overlayGradientBottom: {
    width: "100%",
    bottom: "0px",
    height: "25px",
    position: "fixed",
    background: "linear-gradient(to top, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0))",
    zIndex: "1"
  },
  searchHeaderTitle: {
    fontSize: "16px",
    marginTop: "25px",
    marginLeft: "10px"
  },
  tabs: tabs,
  tabsSelected: tabsSelected,
  tabs50: tabs50,
  tabs50Selected: tabs50Selected,
  tabsWrapper2: tabsWrapper2,
  muiTabWrapper: muiTabWrapper,
  muiTabWrapperLast: muiTabWrapperLast,
  muiTabWrapperV2: muiTabWrapperV2,
  muiTabWrapperV2Last: muiTabWrapperV2Last,
  muiTabWrapperSelect: muiTabWrapperSelect,
  tabsContainerFixed: tabsContainerFixed,
  
}));

export default function SearchDiscovery() {
  const classes = useStyles();
  const containerRef = useRef();
  const { AuthContextState } = useContext(AuthContext);
  const { isAuthenticated, authToken, userId } = AuthContextState;
  const { GlobalContextState, GlobalContextDispatch } = useContext(GlobalContext);
  const { isPwaMobile, isNewActivities } = GlobalContextState;
  const [ tabValues, setTabValues ] = useState(0);
  const [ tabStrValues, setStrTabValues ] = useState("activities");
  const [ activities, setActivities ] = useState([]);
  const [ pageCount, setPageCount ] = useState(0);
  const [ itemCount, setItemCount ] = useState(0);
  const [ itemNewCount, setItemNewCount ] = useState(0);
  const [ itemNewMessagesCount, setItemNewMessagesCount ] = useState(0);
  const [ pageNo, setPageNo ] = useState(0);
  const [ itemPerPage, setItemPerPage ] = useState(10);
  const [ isLoading, setIsLoading ] = useState(true);  
  const [ isLoaded, setIsLoaded ] = useState(false);
  const [ filterType, setFilterType ] = useState(0);
  
  const loadNext = () => {
    
    if (pageCount > pageNo && userId !== "" && activities.length !== 0 && !isLoading) { 
      setIsLoading(true);
      console.log('handleLoadNext true')
      const objData = {userId: userId, pageNo: pageNo, itemPerPage: itemPerPage, filter: tabStrValues, authToken: authToken};
      GetUserActivitiesService(objData).then(result => {
        if (result.data.success === 1) {
          setActivities(activities.concat(result.data.data.activities));
          setPageNo(pageNo + 1); 
        }
        setIsLoading(false);          
      }).catch(err => {
        setIsLoading(false);  
          console.log("catch GetSubscriptions",err);
      });
    }
  }

  const handleChange = (event, value) => {
    event.preventDefault();
    // SearchContextDispatch({ type: "RESET_SEARCH_INFO" });
    if (filterType !== value) {
      setTabValues(value);
      setFilterType(value);      
      switch(value) {
        case 0:        
          setStrTabValues("activities");          
          getData('activities');
          // SearchContextDispatch({ type: "SET_SEARCH_ALL_VIDEOS", payload: { searchAllVideosStr: searchAllVideosStr, searchAllVideosType: "people", itemPerPage: 12, searchAll: false, searchAllVideos: true, hashOnly: false, usernameOnly: false } });
          break;
        case 1:
          setStrTabValues("messages");
          getData('messages');
          // SearchContextDispatch({ type: "SET_SEARCH_ALL_VIDEOS", payload: { searchAllVideosStr: searchAllVideosStr, searchAllVideosType: "houses", itemPerPage: 12, searchAll: false, searchAllVideos: true, hashOnly: false, usernameOnly: false } });
          break;    
        default:
          // setTabValues(2);
          // setStrTabValues("activities");
          // SearchContextDispatch({ type: "SET_SEARCH_ALL_VIDEOS", payload: { searchAllVideosStr: searchAllVideosStr, searchAllVideosType: "hashtags", itemPerPage: 12, searchAll: false, searchAllVideos: true, hashOnly: false, usernameOnly: false } });
      }
    }
    
    return true;
  }

  const getData = (value) => {
    const objData = {userId: userId, pageNo: 0, itemPerPage: itemPerPage, filter: value, authToken: authToken};    
    setIsLoading(true);
    setIsLoaded(false);
    setActivities([]); 
    setPageNo(0);
    setPageCount(0);
    setItemCount(0);
    GetUserActivitiesService(objData).then(result => {
      if (result.data.success === 1) {
        console.log('result.data.data',result.data.data)
        setActivities(result.data.data.activities);
        setPageCount(result.data.data.pageCount);
        setItemCount(result.data.data.count);
        setItemNewCount(result.data.data.newCount);
        setItemNewMessagesCount(result.data.data.newCountMessages);
      }
      setIsLoading(false);
      setIsLoaded(true);
      setPageNo(1); 
    }).catch(err => {
      setIsLoading(false);
      setIsLoaded(true);
      console.log("catch GetUserActivitiesService",err);
    });
  }

  const initNotificationRegistration = () => {
    navigator.serviceWorker.ready.then(function(registration) {
      // Use the PushManager to get the user's subscription to the push service.
      return registration.pushManager.getSubscription().then(async function(subscription) {
          // If a subscription was found, return it.
          if (subscription) {
            console.log('show subscription', subscription)
            return subscription;
          }          
          // Get the server's public key
          console.log('get vapidPublicKey')
          // const response = await fetch('/api/1.0/notifications/vapidPublicKey', {
          //   method: 'get',
          //   headers: {
          //       'Authorization': `Bearer ${authToken}`, 
          //       'Content-type': 'application/json'
          //   },
          // });
          // const vapidPublicKey = await response.text();

          const objData = {
            authToken: authToken
          }
          const response = await GetUserNotificationVapIdService(objData);
          const vapidPublicKey = response.data;

          console.log('vapidPublicKey',vapidPublicKey)
          // Chrome doesn't accept the base64-encoded (string) vapidPublicKey yet
          // urlBase64ToUint8Array() is defined in /tools.js
          const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);
          console.log('subscribe the user')  
          // Otherwise, subscribe the user (userVisibleOnly allows to specify that we don't plan to
          // send notifications that don't have a visible effect for the user).
          return registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: convertedVapidKey
          });
      });
    }).then(function(subscription) {
        console.log('register the user subscription', subscription)
        // Send the subscription details to the server using the Fetch API.
        // fetch('/api/1.0/notifications/register', {
        //     method: 'post',
        //     headers: {
        //         'Authorization': `Bearer ${authToken}`, 
        //         'Content-type': 'application/json'
        //     },
        //     body: JSON.stringify({
        //         subscription: subscription
        //     }),
        // })

        const objData2 = {
          data: subscription,
          authToken: authToken
        }

        PostUserNotificationRegisterService(objData2).then(function(result) {
          console.log('PostUserNotificationRegisterService', result)
        });
    });
  }

  const initNotificationRegistrationSafari = () => {
    navigator.serviceWorker.ready.then(function(registration) {
      // Use the PushManager to get the user's subscription to the push service.
      console.log('show registration', registration)
      return window.safari.pushNotification.getSubscription(async (subscription) => {
          // If a subscription was found, return it.
          if (subscription) {
            console.log('show subscription', subscription)
            return subscription;
          }          
          // Get the server's public key
          console.log('get vapidPublicKey')
          // const response = await fetch('/api/1.0/notifications/vapidPublicKey', {
          //   method: 'get',
          //   headers: {
          //       'Authorization': `Bearer ${authToken}`, 
          //       'Content-type': 'application/json'
          //   },
          // });
          // const vapidPublicKey = await response.text();

          const objData = {
            authToken: authToken
          }
          const response = await GetUserNotificationVapIdService(objData);
          const vapidPublicKey = response.data;

          console.log('vapidPublicKey',vapidPublicKey)
          // Chrome doesn't accept the base64-encoded (string) vapidPublicKey yet
          // urlBase64ToUint8Array() is defined in /tools.js
          const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);
          console.log('subscribe the user')  
          // Otherwise, subscribe the user (userVisibleOnly allows to specify that we don't plan to
          // send notifications that don't have a visible effect for the user).
          return registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: convertedVapidKey
          });
      });
    }).then(function(subscription) {
        console.log('register the user subscription', subscription)
        // Send the subscription details to the server using the Fetch API.
        // fetch('/api/1.0/notifications/register', {
        //     method: 'post',
        //     headers: {
        //         'Authorization': `Bearer ${authToken}`, 
        //         'Content-type': 'application/json'
        //     },
        //     body: JSON.stringify({
        //         subscription: subscription
        //     }),
        // })

        const objData2 = {
          data: subscription,
          authToken: authToken
        }

        PostUserNotificationRegisterService(objData2).then(function(result) {
          console.log('PostUserNotificationRegisterService', result)
        });
    });
  }

  useEffect(() => {
    getData(0,0);
    try {
      Notification.requestPermission().then((result) => {
        console.log('result', result)
        if (result === 'granted') {
          console.log('granted', result);
          try {
            initNotificationRegistration();
          } catch (err) {
            console.log('err', err)
          }
        }
      });
    } catch (err) {      
      if (err instanceof TypeError) {
        try {         
          Notification.requestPermission((result) => {
            console.log('result', result)
            if (result === 'granted') {
              console.log('safari granted', result);
              initNotificationRegistrationSafari();
            }          
          });
        } catch (err) {
          console.log('err', err)
        }
      } else {
        console.log('err', err)
      }
    }   
  }, [])


  useEffect(() => {
    console.log('itemNewCount', itemNewCount)
    console.log('itemNewMessagesCount', itemNewMessagesCount)

    if (itemNewCount === 0 && itemNewMessagesCount === 0) {
      console.log('NO_NEW_ACTIVITIES')
      GlobalContextDispatch({ type: "NO_NEW_ACTIVITIES" });
    }
  }, [itemNewCount, itemNewMessagesCount]);

  const buttonTab = (props) => {
    return (
      <button {...props}>
        <span {...props.children[0][0].props}>{props.children[0][0].props.children[1]} {itemNewCount !== 0 ? <sup>{itemNewCount < 100 ? itemNewCount : 99}</sup> : ""}</span>
        {/* <span {...props.children[1].props}></span> */}
      </button>
    )
  }

  const buttonTabMessages = (props) => {
    return (
      <button {...props}>
        <span {...props.children[0][0].props}>{props.children[0][0].props.children[1]} {itemNewMessagesCount !== 0 ? <sup>{itemNewMessagesCount < 100 ? itemNewMessagesCount : 99}</sup> : ""}</span>
        {/* <span {...props.children[1].props}></span> */}
      </button>
    )
  }

  return (
    <>
      <HelmetComponent title={"DTF - Notifications"} />                      
      <Container classes={{ root: classes.containerStyleWhiteMessages }} className={isPwaMobile ? " pwaMobile" : ""} ref={containerRef}>    
        <div className={ classes.tabsContainerFixed + " " + (isPwaMobile ? " pwaMobile" : "") }>
          <Tabs
            value={tabValues}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"            
            classes={{ root: classes.tabsWrapper2 }}
          >
            {
              itemNewCount !== -1
              ?
                <Tab label="Notifiations" component={buttonTab} value={0} selected={(tabValues === 0) ? true : false} classes={{ root: (tabValues === 0) ? classes.tabs50Selected : classes.tabs50, wrapper: classes.muiTabWrapperV2 }} />  
              :
                null
            }
            {
              itemNewMessagesCount !== -1
              ?
                <Tab label="Messages" component={buttonTabMessages} value={1} selected={(tabValues === 1) ? true : false} classes={{ root: (tabValues === 1) ? classes.tabs50Selected : classes.tabs50, wrapper: classes.muiTabWrapperV2Last }} />
              :
                null
            }
          </Tabs>
        </div>
        <div className={ classes.resultContainer }>
          {/* <div className={classes.overlayGradientTop}></div>   */}
          <Grid 
            container
            direction="row"   
          >  
            <Grid item container xs={12} className={ classes.resultGridContainer }>              
              {      
                activities.map((item, index) => {
                    return <ActivityItem itemNewCount={itemNewCount} setItemNewCount={setItemNewCount} dataItem={item} index={index} sessionUserId={userId} template={0} parentRef={containerRef} />                 
                })
              }   
            </Grid>
            {
              activities.length === 0 && isLoaded
              ?
                <ActivitiesNullCase text={"You have 0 " + (tabValues === 1 ? "messages" : "activities")} />
              :
                null
            }            
          </Grid>      
        </div>  
        <InfiniteScroll parentRef={containerRef} method={loadNext} margin={vh + "px"} />
      </Container>          
    </>
  );
}