import React, { createContext, useReducer, useEffect } from "react";
import { VideosContextReducer } from '../reducers/VideosContextReducer';
import { Videos } from '../testData/Videos';
import { GetAllLatestVideoService } from "../services/VideoServices";
import { VideosInitialState } from "../constants/VideosInitialState";

export const VideosContext = createContext();
const initialState = VideosInitialState;

const VideosContextProvider = (props) => {
    const [VideosContextState, VideosContextDispatch] = useReducer(VideosContextReducer,initialState);

    useEffect(() => {
        // GetAllLatestVideoService({pageNo: 0}).then(result => {
        //     if (result.data.success === 1) {
        //         VideosContextDispatch({ type: "SET_INIT_VIDEOS", payload: result.data });
        //     } 
        // }).catch(err => {
        //     console.log("catch GetAllLatestVideoService",err);
        // });
    }, [])

    return (
        <VideosContext.Provider value={{VideosContextState, VideosContextDispatch}}>
            {props.children}
        </VideosContext.Provider>
    );
}

export default VideosContextProvider;