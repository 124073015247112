import React, { createContext, useReducer, useEffect } from "react";
import { TagContextReducer } from '../reducers/TagContextReducer';
import { TagInitialState } from "../constants/TagInitialState";

export const TagContext = createContext();
const initialState = TagInitialState;

const TagContextProvider = (props) => {
    const [TagContextState, TagContextDispatch] = useReducer(TagContextReducer,initialState);

    useEffect(() => {
    }, [])

    return (
        <TagContext.Provider value={{TagContextState, TagContextDispatch}}>
            {props.children}
        </TagContext.Provider>
    );
}

export default TagContextProvider;