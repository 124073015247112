import axios from 'axios';
import { VersionInitialState } from "../constants/VersionInitialState";
const versionApiNumber = VersionInitialState.versionApiNumber;

export async function UploadImageService(objData,userId,authToken) {
    const data = await axios.post(
        `/api/${versionApiNumber}/upload/image/${userId}`,
        objData,
        {
            headers: {
                'Authorization': `Bearer ${authToken}` 
            }
        }
    );
    return data;
}

export async function UploadImageMoodService(objData,userId,authToken) {
    const data = await axios.post(
        `/api/${versionApiNumber}/upload/image/mood/${userId}`,
        objData,
        {
            headers: {
                'Authorization': `Bearer ${authToken}` 
            }
        }
    );
    return data;
}

export async function DeleteImageService(objData,userId,authToken) {
    const data = await axios.post(
        `/api/${versionApiNumber}/upload/image/delete/${userId}`,
        objData,
        {
            headers: {
                'Authorization': `Bearer ${authToken}` 
            }
        }
    );
    return data;
}

export async function DeleteImageMoodService(objData,userId,authToken) {
    const data = await axios.post(
        `/api/${versionApiNumber}/upload/image/mood/delete/${userId}`,
        objData,
        {
            headers: {
                'Authorization': `Bearer ${authToken}` 
            }
        }
    );
    return data;
}

export async function UploadVideoService(objData,userId,authToken) {
    const data = await axios.post(
        `/api/${versionApiNumber}/upload/video/${userId}`,
        objData,
        {
            headers: {
                'Authorization': `Bearer ${authToken}` 
            }
        }
    );
    return data;
}

export async function DeleteVideoService(objData,userId,authToken) {
    const data = await axios.post(
        `/api/${versionApiNumber}/upload/video/delete/${userId}`,
        objData,
        {
            headers: {
                'Authorization': `Bearer ${authToken}` 
            }
        }
    );
    return data;
}

export async function UploadContentService(objData,userId,authToken) {
    const data = await axios.post(
        `/api/${versionApiNumber}/upload/content/${userId}`,
        objData,
        {
            headers: {
                'Authorization': `Bearer ${authToken}` 
            }
        }
    );
    return data;
}

export async function DeleteContentService(objData,userId,authToken) {
    const data = await axios.post(
        `/api/${versionApiNumber}/upload/content/delete/${userId}`,
        objData,
        {
            headers: {
                'Authorization': `Bearer ${authToken}` 
            }
        }
    );
    return data;
}