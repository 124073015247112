import React, { useRef, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { feedVideoFloatElement, feedVideoElement, feedElementCover } from '../styles/defaultFeedWrapperStyles';

const useStyles = makeStyles((theme) => ({
  feedVideoFloatElement: feedVideoFloatElement,
  feedElementCover: feedElementCover,
  feedVideoElement: feedVideoElement
}));

let timer = 0;
let delay = 500;

function FeedVideoItem(props) {
  const classes = useStyles();
  // const itemRef = useRef();
  const { setToggleLike=null, itemRef=null, posterSource=null, source=null, firstItem=false, toggleSound=null, isMuted=true, userClick=false, isCover=false } = props; 
  
  useEffect(() => {
    const vid = itemRef.current;
    if (source) {      
      if (vid !== null) { 
        if (vid.src !== source) {
          // console.log('posterSource', posterSource);
          console.log('source', source)
          // vid.poster = posterSource;
          vid.src = source;
          // const timer = setTimeout(() => {          
          //   try {           
          //     vid.play();
          //   } catch (err) {
          //     console.log('err onPlaying', err, source)
          //   } 
          // }, 500);
          // return () => clearTimeout(timer); 
        }
      }  
    } else {
      vid.src = null;
      console.log('vid.parentElement',vid.parentElement);
      if (vid.parentElement) {
        vid.parentElement.classList.remove('showVideo');
      }
      
      // vid.poster = null;
    }   
  }, [source, posterSource])   
  
  const onPlaying = (e) => {
    console.log('onPlaying', e);
    const vid = itemRef.current;
    if (vid.parentElement) {
      vid.parentElement.classList.add('showVideo');
    }
    // console.log('onPlaying vid', vid.muted, userClick, isMuted);
    if (vid !== null) {
      if (userClick && vid.muted && !isMuted) { 
        // console.log('onPlaying muting', vid.muted, userClick, isMuted);  
        const timer = setTimeout(() => {          
          try {           
            vid.muted = false; 
          } catch (err) {
            console.log('err onPlaying', err, source)
          } 
        }, 250);
        return () => clearTimeout(timer); 
      }
    }  
  }

  const onClickElem = (e) => {
    console.log('onClickElem', e.detail);
    
    switch(e.detail) {
      case 1:
        let me = this;
        timer = setTimeout(function() {         
          try {
            const vid = itemRef.current;
            if (vid !== null) {                 
                vid.muted = !vid.muted;
            }       
          } catch(err) {
              console.log('toggleMute single tap err', err)
          }
      
          if (isMuted) {            
              toggleSound(false);
          } else {
              toggleSound(true);
          }  
        }, delay);
        return () => clearTimeout(timer); 
        // break;
      case 2:        
        console.log('like trigger');
        clearTimeout(timer);
        setToggleLike(true);
        return;
      default:
    }
  } 

  return (
    <div className={ classes.feedVideoFloatElement }>
      <video
        ref={itemRef}
        loop
        muted    
        // autoPlay     
        playsInline 
        preLoad={"auto"}
        id={"feedVideoPlayer"}
        onPlaying={(e) => onPlaying(e)}
        controlsList={"nodownload"}           
        type={"video/mp4"}
        onClick={(e) => onClickElem(e)}
      />
    </div>
  );
}

export default React.memo(FeedVideoItem);