import React from 'react';

export default class CloseCircleGray extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                <g id="Group_3345" data-name="Group 3345" transform="translate(-321 -330)">
                    <circle id="Ellipse_146" data-name="Ellipse 146" cx="7.5" cy="7.5" r="7.5" transform="translate(321 330)" fill="#ccc"/>
                    <path id="Path_676" data-name="Path 676" d="M3725.223,477.412l-5.664,5.664" transform="translate(-3394 -143)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                    <path id="Path_677" data-name="Path 677" d="M3725.223,477.412l-5.664,5.664" transform="translate(808.635 -3385.146) rotate(90)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                </g>
            </svg>       
        )
    }
}