import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import FormErrorContextProvider from "../contexts/FormErrorContext";
import SwipeSearchContextProvider from '../contexts/SwipeSearchContext';

const ProtectedPageRoute = ({ swipeSearch: swipeSearch, formError: formError, initialLoad: initialLoad, isLoading: isLoading, isAuth: isAuth, componentName: Component, ...rest }) => {
    return (
        <Route {...rest} render={(props) => {
            if (isAuth === true && isLoading === false) {
                if (swipeSearch === true) {
                    if (formError === true) {
                        return (<SwipeSearchContextProvider><FormErrorContextProvider><Component {...props} /></FormErrorContextProvider></SwipeSearchContextProvider>)  
                    } else {
                        return (<SwipeSearchContextProvider><Component {...props} /></SwipeSearchContextProvider>) 
                    }
                } else {
                    if (formError === true) {
                        return (<FormErrorContextProvider><Component {...props} /></FormErrorContextProvider>)  
                    } else {
                        return <Component {...props} /> 
                    }
                }                                
            } else {
                if (!initialLoad) {
                    return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                } else {        
                    return null;
                }
            }
            
        }} />
    );
}

export default ProtectedPageRoute;
