// framer support for grid container
export const ErrorTextStyle = {
  color: "rgba(244,67,54,1)",
  padding: "0",
  fontSize: "14px"
};

export const ErrorTextContainerStyle = {
  padding: "0px 10px !important",
  display: "block",
  minHeight: "19px"
}