import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { FramerContext } from '../contexts/FramerContext';
import { defaultLink, whiteLink } from '../styles/defaultLinkStyles.2';

const useStyles = makeStyles((theme) => ({
  defaultLink: defaultLink,
  whiteLink: whiteLink,
  underline: {
    textDecoration: 'underline',
  },
  noUnderline: {
    textDecoration: 'none',
  }
}));

export default function LinkText(props) {
  const { toggleDirection } = useContext(FramerContext);
  const { url="/", children="", directionBool=true, classLinkName="defaultLink", underline=false, callbackHandler=null, callbackValue="" } = props;
  const classes = useStyles();

  const clickHanlder = (e) => {    
    if (callbackHandler) {
      callbackHandler(e, callbackValue);
    }
     toggleDirection(directionBool,true);
  }

  return (
    <Link to={url} className={(classLinkName === "whiteLink" ? classes.whiteLink : classes.defaultLink) + ' ' + (underline ? classes.underline : classes.noUnderline)} onClick={(e) => clickHanlder(e)}>
      {children}
    </Link>
  );
}
