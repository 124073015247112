export const textareaDefault = {
  fontSize: "16px",
  color: "rgba(0,0,0,1)",
  height: "auto",
  padding: "0",
  '&::placeholder': {
    fontSize: "16px",
    color: "rgba(0,0,0,0.2)",
  } 
}

export const textareaUnderline = {
  '&::before': {
    borderBottom: "1px solid rgba(0, 0, 0, 0.2)"
  }
}