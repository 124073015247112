export const SearchInitialState = {
    houseTagVideo: "",
    houseTagVideoResult: [],
    houseTagSearchCount: 0,
    houseTagSearchPage: 0,
    houseTagSearchPageCount: 0,
    houseTagPageNo: 0,
    hashTagVideo: "",
    hashTagVideoResult: [],
    hashTagSearchCount: 0,
    hashTagSearchPage: 0,
    hashTagSearchPageCount: 0,
    hashTagPageNo: 0, 
    people: "",
    peopleResult: [],
    usernameOnly: false,
    peopleSearchCount: 0,
    peopleSearchPage: 0,
    peopleSearchPageCount: 0,
    peoplePageNo: 0,    
    video: "",
    videoResult: [],
    hashOnly: false,
    videoSearchCount: 0,
    videoSearchPage: 0,
    videoSearchPageCount: 0,
    location: "",
    locationResult: [],
    locationSearchCount: 0,
    locationSearchPage: 0,
    locationSearchPageCount: 0,
    locationPageNo: 0,
    searchCount: 0,
    searchPage: 0,
    searchPageCount: 0,
    pageNo: 0,
    itemPeoplePerPage: 10,
    itemLocationPerPage: 10,
    itemVideoPerPage: 10,
    itemHashTagVideoPerPage: 10,
    itemHouseTagVideoPerPage: 10,
    loadNext: false,
    loadNextType: '',
    isLoading: false,
    resultLoaded: false,
    searchAllStr: "",
    searchAll: false,
    searchAllVideosStr: "",
    searchAllVideosType: "",
    searchAllVideos: false,
    searchHistory: null,
    searchHistoryResult: [],
    searchHistoryCount: 0,
    searchHistoryPage: 0,       
    searchHistoryPageCount: 0,
    searchHistoryPerPage: 10,
    videoDiscovery: [],
    videoDiscoveryResult: [],                
    videoDiscoverySearchCount: 0,
    videoDiscoverySearchPage: 0,
    videoDiscoverySearchPageCount: 0,
    searchDiscoveryAllVideosType: "",
    searchDiscoveryAllVideosStr: "",
    searchDiscoveryAllVideos: false,
    isFocus: false
}
