import React, { useState, useContext, useEffect, useRef }  from 'react';
import { CloudinaryContext, Image, Transformation } from "cloudinary-react";
import Moment from 'react-moment';
import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import FavoriteBorderRoundedIcon from '@material-ui/icons/FavoriteBorderRounded';
import FavoriteRoundedIcon from '@material-ui/icons/FavoriteRounded';

import ImageAvatar from './ImageAvatar.2';
import DialogModal from "./DialogModal";
import TrashGray from './svg/TrashGray';
import HeartSmallPink from './svg/HeartSmallPink';
import HeartSmallBorderGray from './svg/HeartSmallBorderGray';
import { containerStyle, containerGridStyle } from '../styles/defaultStyles';
import { gridItemWrapper } from '../styles/defaultGridStyles';
import { commentItemAvatar } from '../styles/defaultAvatarStyles.2'; 
import { defaultBtn, defaultLabelBtn, defaultActiveBtn } from '../styles/defaultButtonStyles';
import { listWrapper } from '../styles/defaultListStyles';
import { listText, listTextBold } from '../styles/defaultTextStyles.2';
import { CloudinaryInfo } from "../constants/CloudinaryInfo";
import { convertCountToString } from '../utils/CommonUtil';
import { GlobalContext } from '../contexts/GlobalContext';
import { AuthContext } from '../contexts/AuthContext';
import { DrawerCommentContext } from '../contexts/DrawerCommentContext';

const useStyles = makeStyles((theme) => ({  
  containerGridStyle: containerGridStyle,
  containerStyle: containerStyle,
  gridItemWrapper: gridItemWrapper,
  commentItemAvatar: commentItemAvatar,
  listWrapper: listWrapper,
  listText: listText,
  listTextBold: listTextBold,
  defaultBtn: defaultBtn,
  defaultLabelBtn: defaultLabelBtn,
  defaultActiveBtn: defaultActiveBtn,
  commentItemContainer: {
    padding: "10px 15px",
    cursor: "pointer",
    alignItems: "flex-start"
  },
  commentItemTextContainer: {
    margin: "6px 10px"
  },
  iconStyle: {
    fontSize: "20px",
    color: "rgba(153,153,153,1)",
    top: "5px",
    right: "5px",
    position: "relative"
  },
  iconStyleActive: {
    fontSize: "20px",
    color: " rgba(253,45,85,1)",
    top: "5px",
    right: "5px",
    position: "relative"
  },
  iconLabelStyle: {
    position: "absolute",
    top: "35px",
    right: "10px",
    color: "rgba(153,153,153,1)",
    fontSize: "14px",
    textAlign: "center",
    display: "block",
    width: "40px"
  },
  buttonIcon: {
    background: "none",
    border: "none",
    margin: "0",
    padding: "0",
    top: "5px",
    right: "8px",
    position: "relative",
    display: "inline-block"
  },
  buttonDeleteIcon: {
    background: "none",
    border: "none",
    margin: "0",
    padding: "0",
    top: "5px",
    right: "9px",
    position: "relative",
    display: "inline-block",
    "& > svg": {
      width: "12px"
    }
  },
  deleteIconSVG: {
    width: "12px"
  }
}));

export default function CommentItem(props) {
  const classes = useStyles();  
  const { AuthContextState } = useContext(AuthContext);
  const { isAuthenticated, authToken, userId } = AuthContextState;  
  const { GlobalContextDispatch } = useContext(GlobalContext);
  const { DrawerCommentContextState, DrawerCommentContextDispatch } = useContext(DrawerCommentContext);
  const { videoId = "", currentIndex = null } = DrawerCommentContextState;
  const { data, index } = props;  
  const { _id="", comment="", user={}, video="", user_information={}, date_created="", date_updated="", isLike=false, like=0 } = data;
  const userPlaceHolder = { _id: "", handlename: "", username: "", userIsPlaceHodler: true };  
  const { _id: user_id, handlename="", username="", userIsPlaceHodler=false } = user || userPlaceHolder;  
  const userInformationPlaceHolder = { user_image_profile: "", userInformationIsPlaceHodler: true };
  const { user_image_profile="", userInformationIsPlaceHodler=false } = user_information || userInformationPlaceHolder;
  const [ deleteModal, setDeleteModal ] = useState(false);
  const [ likeItem, setLikeItem ] = useState(isLike || false);
  const [ likeCount, setLikeCount ] = useState(like);
  const [ isDelete, setIsDelete ] = useState(false);

  const deleteClickHandler = (e) => {
    setDeleteModal(true);
  }

  const likeCommentHandler = (e) => {
    DrawerCommentContextDispatch({ type: "LIKE_COMMENT", payload: { commentId: _id, videoId: video, likeComment: true } })
    setLikeItem(true);
    setLikeCount(likeCount + 1);
  }

  const unLikeCommentHandler = (e) => {
    DrawerCommentContextDispatch({ type: "UNLIKE_COMMENT", payload: { commentId: _id, videoId: video, unLikeComment: true } })
    setLikeItem(false);
    setLikeCount(likeCount - 1);
  }

  const deleteCommentHandler = () => {
    // console.log("deleteCommentHandler");
    DrawerCommentContextDispatch({ type: "DELETE_COMMENT", payload: { commentId: _id, videoId: video, deleteComment: true } });
    DrawerCommentContextDispatch({ type: "SUBSTRACT_COMMENT", payload: { currentIndex: currentIndex, isAdd: false} });
    GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : true }});
    setIsDelete(true);
    setDeleteModal(false);
  }

  const cancelDeleteCommentHandler = () => {
    // console.log("cancelDeleteCommentHandler");
    setDeleteModal(false);
  }

  const handleClick = (e, item) => {
    // e.preventDefault();
    // console.log('handleClick',item);   
  }

  if (!isDelete) {
    return (
      <> 
        <ListItem key={"comment-item-" + index} classes={{ root: classes.commentItemContainer }}>
          <ListItemAvatar classes={{ root: classes.marginRight5 }}>
            {
              user_image_profile !== "" && user_image_profile !== null
              ?
                <ImageAvatar image={user_image_profile} alt={username} />
              :
                <ImageAvatar letter={true} alt={handlename} />
            }
          </ListItemAvatar>
          <ListItemText 
            primary={"@" + handlename} 
            secondary={
              <>
                {comment}
                <span><Moment fromNow>{date_updated}</Moment></span>                
              </>
            } 
            classes={{
              root: classes.commentItemTextContainer,
              primary: classes.listTextBold,
              secondary: classes.listText, 
            }}
          />   
          {
            userId === user_id
            ?
              <button className={classes.buttonDeleteIcon} onClick={(e) => deleteClickHandler(e)}>
                <TrashGray />
                {/* <img src={"/svg/trash-gray.svg"} alt={'Delete'} className={ classes.deleteIconSVG } /> */}
              </button>
            :
              likeItem
              ?
                <>
                  <button className={classes.buttonIcon} onClick={(e) => unLikeCommentHandler(e)}>
                    <HeartSmallPink />  
                    {/* <img src={"/svg/heart-small-pink.svg"} alt={'Unlike'} /> */}
                  </button>  
                  <Typography variant={"body2"} align="center" classes={{ root: classes.iconLabelStyle }}>
                      {convertCountToString(likeCount)}
                  </Typography>
                </>
              :
                <>
                  <button className={classes.buttonIcon} onClick={(e) => likeCommentHandler(e)}>
                    <HeartSmallBorderGray /> 
                    {/* <img src={"/svg/heart-small-border-gray.svg"} alt={'Like'} /> */}
                  </button>
                  <Typography variant={"body2"} align="center" classes={{ root: classes.iconLabelStyle }}>
                      {convertCountToString(likeCount)}
                  </Typography>
                </>
          }          
        </ListItem>
        <DialogModal methodSubmit={deleteCommentHandler} methodCancel={cancelDeleteCommentHandler} openDialog={deleteModal} titleText={"Delete comment?"} contentText={"Are you sure?"} cancelText={"cancel"} methodText={"delete"} /> 
      </>   
    );
  } else {
    return false
  }  
}