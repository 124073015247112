export const ContentInitialState = {
    newDescription: null,
    checkedFacebook: false,
    checkedInstagram: false,
    checkedTwitter: false,
    checkedPublic: false,
    checkedPrivate: false,
    checkedActive: false,
    newChange: false,
    newChangeId: null
}
