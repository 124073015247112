export const HousesObj = [
    { "title": "Aja" },
    { "title": "Avalon" },
    { "title": "Baga" },
    { "title": "Bennetton" },
    { "title": "Brooks" },
    { "title": "Blac" },
    { "title": "Blades" },
    { "title": "Boate" },
    { "title": "Bobo" },
    { "title": "C-Pher" },
    { "title": "Destruction" },
    { "title": "Diamond" },
    { "title": "Durango" },
    { "title": "Edwards" },
    { "title": "Galore" },
    { "title": "Hall" },
    { "title": "Haunt" },
    { "title": "Hytes" },
    { "title": "Locas" },
    { "title": "Ice" },
    { "title": "LaQueer" },
    { "title": "Mateo" },
    { "title": "Michaels" },
    { "title": "Michelle" },
    { "title": "Minj" },
    { "title": "Montrese" },
    { "title": "Mess" },
    { "title": "O'Hara" },
    { "title": "Purchase" },
    { "title": "Schatzi" },
    { "title": "Stupi" },
    { "title": "Taylor" },
    { "title": "The Drag Queen" },
    { "title": "Y Ya" }
]