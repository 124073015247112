import React, {useState, useContext, useEffect} from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';

import SearchIcon from './svg/SearchIcon';
import CloseCircleGray from './svg/CloseCircleGray';

import { SearchContext } from '../contexts/SearchContext'; 
import { GlobalTopBarContext  } from '../contexts/GlobalTopBarContext';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: "100%",
    height: "40px",
    backgroundColor: "rgba(241,241,241,1)"
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  searchIconButton: {
    margin: "10px",
    padding: "10px",
    height: "20px",
    width: "20px",
    borderRadius: "20px",
    "& > span": {
      width: "inherit"
    }
  },
  clearIconButton: {
    margin: "10px",
    padding: "10px",
    height: "20px",
    width: "20px",
    borderRadius: "20px",
    backgroundColor: "rgba(204,204,204,1)",
    "& > span": {
      width: "inherit"
    }
  }
}));

export default function SearchPageInput(props) {
  const classes = useStyles();
  let history = useHistory();
  const { GlobalTopBarContextState, GlobalTopBarContextDispatch } = useContext(GlobalTopBarContext);
  const { searchDiscovery=false } = GlobalTopBarContextState;
  const { SearchContextState, SearchContextDispatch } = useContext(SearchContext);
  const { isFocus=false, searchHistoryResult=[], itemPeoplePerPage, itemHouseTagVideoPerPage, itemHashTagVideoPerPage, itemVideoPerPage, itemLocationPerPage, searchAllStr="", searchAllVideosStr="", searchAllVideos=false, searchAllVideosType="" } = SearchContextState;
  const [ search, setSearch ] = useState(searchAllVideosStr);
  const [ isFocusBoolean, setIsFocusBoolean ] = useState(false);
  const { label="Search", searchType=null, action=() => {} } = props;

  const handleFocus = (e) => {
    // console.log("handleFocus", searchHistoryResult, isFocus)
    if (!isFocus) {
      // setIsFocusBoolean(true);
      SearchContextDispatch({ type: "SET_IS_FOCUS", payload: { isFocus : true } });
    }
  }

  const handleSearch = (e) => {
    // console.log("handleSearch", e.target.value, e.target.value.length, searchType)
    setSearch(e.target.value);
    SearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading : false} });
    SearchContextDispatch({ type: "RESET_RESULT_LOADED", payload: { isLoading : false} });
    SearchContextDispatch({ type: "RESET_LOADNEXT" });
    if (e.target.value.length !== 0) {
      if (searchDiscovery) {
        SearchContextDispatch({ type: "SET_SEARCH_ALL_VIDEOS", payload: { searchAllVideosStr: e.target.value, searchAllVideosType: searchAllVideosType, itemPerPage: 3, searchAll: false, searchAllVideos: true, hashOnly: false, usernameOnly: false } });
      } else {
        if ((e.target.value.charAt(0) === '@' || e.target.value.charAt(0) === '#' || e.target.value.charAt(0) === '^' && searchType === null) || searchType === null) {
          switch(e.target.value.charAt(0)) {
            case '@':
              // console.log("@ search", itemPeoplePerPage)            
              if (e.target.value.length > 1) {
                SearchContextDispatch({ type: "SET_SEARCH_USERNAME", payload: { searchAllStr: e.target.value, people: e.target.value, itemPerPage: 3, usernameOnly: true } });
              }
              if (e.target.value.length === 0) {
                SearchContextDispatch({ type: "RESET_SEARCH_INFO" });
              } 
              break;
            case '#':
              // console.log("# search", itemHashTagVideoPerPage)
              // SEARCH EACH VIDEO FOR HASH
              // SEARCH DISTINCT HASH IN VIDEO
              if (e.target.value.length > 1) {               
                SearchContextDispatch({ type: "SET_SEARCH_HASH_TAG_VIDEO", payload: { searchAllStr: e.target.value, hashTagVideo: e.target.value, itemPerPage: itemHashTagVideoPerPage, hashOnly: true } });
              }
              if (e.target.value.length === 1) {
                SearchContextDispatch({ type: "RESET_SEARCH_INFO" });
              } 
              break;
            case '^':
              // console.log("^ search", itemHouseTagVideoPerPage)
              // SEARCH EACH VIDEO FOR HOUSE
              if (e.target.value.length > 1) {               
                SearchContextDispatch({ type: "SET_SEARCH_HOUSE_TAG_VIDEO", payload: { searchAllStr: e.target.value, houseTagVideo: e.target.value, itemPerPage: itemHouseTagVideoPerPage, hashOnly: true } });
              }
              if (e.target.value.length === 1) {
                SearchContextDispatch({ type: "RESET_SEARCH_INFO" });
              } 
              break;  
            default:
              // console.log("search all",e.target.value,e.target.value.length)
              if (e.target.value.length !== 0) {
                SearchContextDispatch({ type: "SET_SEARCH_ALL", payload: { searchAllStr: e.target.value, people: e.target.value, houseTagVideo: e.target.value, hashTagVideo: e.target.value, video: e.target.value, location: e.target.value, itemPerPage: 5, hashOnly: false, usernameOnly: false } });
              }
              if (e.target.value.length === 0) {
                SearchContextDispatch({ type: "RESET_SEARCH_INFO" });
              }
          } 
        }
      }
    } else {   
      if (e.target.value.length === 0) {
        SearchContextDispatch({ type: "RESET_SEARCH_INFO" });
      } 
    }
  }

  const handleClear = (e) => {    
    // console.log("handleClear",e.target.value)
    e.preventDefault();
    setSearch("");
    SearchContextDispatch({ type: "RESET_SEARCH_INFO" });
    SearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading : false } });    
    SearchContextDispatch({ type: "RESET_RESULT_LOADED", payload: { isLoading : false} });
    SearchContextDispatch({ type: "RESET_LOADNEXT" });
  }

  useEffect(() => {
    SearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading : false} });
    SearchContextDispatch({ type: "RESET_RESULT_LOADED", payload: { isLoading : false} });
    SearchContextDispatch({ type: "RESET_LOADNEXT" });
  }, [])

  useEffect(() => {
    if (search !== searchAllVideosStr) {
      setSearch(searchAllVideosStr);
    }  
  }, [searchAllVideosStr])

  const handleEnter = (e) => {
    // console.log("handleEnter", e, e.keyCode);    
    if (e.keyCode === 13) {
      e.preventDefault();      
      history.push('/search/discovery');
    }
  }

  return (
    <Paper component="form" className={classes.root} elevation={0} onKeyDown={(e) => handleEnter(e)}>
      <IconButton className={classes.searchIconButton} aria-label="search">
        <SearchIcon />
      </IconButton>
      <InputBase
        className={classes.input}
        placeholder={label}
        inputProps={{ 'aria-label': label }}
        value={search}
        onChange={(e) => handleSearch(e)}
        onFocus={(e) => handleFocus(e)}
      />
      {
        search !== ""
        ?
          <IconButton type="button" className={classes.clearIconButton} aria-label="clear" onClick={(e) => handleClear(e)}>             
            <CloseCircleGray />
          </IconButton>
        :
          null
      }      
    </Paper>
  );
}
