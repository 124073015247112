//https://reactjs.org/docs/hooks-reference.html#usereducer
import React, { createContext, useReducer, useEffect, useContext } from "react";
import { useSnackbar } from 'notistack';
import { DrawerShareContextReducer } from '../reducers/DrawerShareContextReducer';
import { DrawerShareInitialState } from "../constants/DrawerShareInitialState";
// import { GetCommentVideo } from "../services/VideoServices"
// import { PostCommentVideo, DeleteCommentVideo, LikeCommentVideo, UnLikeCommentVideo } from "../services/UserActionsServices";
import { AuthContext } from './AuthContext';
import { GlobalContext } from './GlobalContext';

export const DrawerShareContext = createContext();

const initialState = DrawerShareInitialState;

const DrawerShareContextProvider = (props) => {
    const { AuthContextState } = useContext(AuthContext);
    const { isAuthenticated, authToken, userId } = AuthContextState; 
    const { GlobalContextDispatch } = useContext(GlobalContext);
    const [DrawerShareContextState, DrawerShareContextDispatch] = useReducer(DrawerShareContextReducer,initialState);
    const { 
        initialShareLoad=true,
        showDrawerShare=false,    
        share="",
        shareId="",
        videoId="",
        deleteShare=false,
        post=false,
        get=false,
        getInfinite=false,
        shares=[],
        pageNo=0,
        itemPerPage=10,
        shareCount=0,
        pageCount=0,
        currentIndex=null,
        indexUpdateShareCount=[] 
    } = DrawerShareContextState;
    const { enqueueSnackbar } = useSnackbar();

    // useEffect(() => {
    //     if (post && share !== "" && videoId !== "") {
    //         const objData = { comment: comment, videoId: videoId };                        
    //         PostShareVideo(objData, authToken).then((data) => {
    //             // DrawerCommentContextDispatch({ type: "RESET_COMMENT" })                    
    //             if (data.data.success === 1) {
    //                 DrawerShareContextDispatch({ type: "POST_SHARE", payload: { share: data.data.share } })
    //                 enqueueSnackbar('Share posted', { variant: 'default', autoHideDuration: 3000 });
    //             } else {
    //                 enqueueSnackbar('Post share unsuccessful', { variant: 'error', autoHideDuration: 3000 });
    //             }                 
    //         })
    //     }
    // }, [post, share, videoId])

    return (
        <DrawerShareContext.Provider value={{DrawerShareContextState, DrawerShareContextDispatch}}>
            {props.children}
        </DrawerShareContext.Provider>
    );
}
export default DrawerShareContextProvider;