import { useEffect, useRef } from "react";

// https://codesandbox.io/s/28ivn?file=/src/useScroll.js:0-591
export const useFeedScrollItem = (parentRef, childRef, callback, callback2, callback3, margin, firstItem, type) => {
  const observer = useRef();

  useEffect(() => {
    const options = {
      root: parentRef.current,
      rootMargin: margin || "0px",
      threshold: 0.5
    };
    const currentChildRef = childRef.current;

    observer.current = new IntersectionObserver(([target]) => {  
      console.log('useFeedScrollItem type',type)
      if (type === "video") {
        if (target.isIntersecting) {
          console.log("target.isIntersecting video true");
          callback();
        } else if (!target.isIntersecting) {
          console.log("target.isIntersecting video false");
          callback2();
        }
      } else {
        if (target.isIntersecting) {
          console.log("target.isIntersecting image true");
          callback3();
        }        
      }     
    }, options);

    observer.current.observe(currentChildRef);

    return () => observer.current.unobserve(currentChildRef);
  }, [callback, parentRef, childRef]);
}