import React from 'react';

export default class HeartSmallBorderWhite extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="12.634" height="11.405" viewBox="0 0 12.634 11.405">
                <path id="Path_82" data-name="Path 82" d="M11.23,5.914a3.118,3.118,0,0,0-4.655.275,3.119,3.119,0,1,0-4.655,4.136l4.528,4.528a.18.18,0,0,0,.254,0l4.528-4.528A3.122,3.122,0,0,0,11.23,5.914Z" transform="translate(-0.259 -4.25)" fill="none" stroke="#fff" stroke-width="1.5"/>
            </svg>   
        )
    }
}