export const defaultdBtn = {
  width: "120px",
  height: "45px",
  margin: "0",
  display: "inline-block",
  fontSize: "14px",
  textTransform: "capitalize"
}

export const defaultFormButton = {
  position: "relative", 
  padding: "20px", 
  display: "block",
  color: "rgba(150,148,152,1)",
  border: "none",
  background: "transparent",
  outline: "none",
  cursor: "pointer"
}

export const defaultVideoButton = {
  position: "relative", 
  padding: "0px", 
  margin: "auto",
  display: "block",
  color: "rgba(150,148,152,1)",
  border: "none",
  background: "transparent",
  outline: "none",
  cursor: "pointer"
}

export const defaultOpacityVideoButton = {
  position: "relative", 
  padding: "0px", 
  margin: "auto",
  display: "block",
  color: "rgba(150,148,152,1)",
  border: "none",
  background: "transparent",
  outline: "none",
  cursor: "pointer",
  opacity: "0"
}

export const defaultFormIconButton = {    
  backgroundColor: "rgba(0, 0, 0, 0.2)",
  color: "rgba(255, 255, 255, 1)",
  borderRadius: "25px",
  padding: "10px",
  height: "50px",
  width: "50px",
  cursor: "not-allowed",
  "&.active" : {    
    backgroundColor: "rgba(253,45,85,1)",
    color: "rgba(255, 255, 255, 1)",
    borderRadius: "25px",
    padding: "10px",
    height: "50px",
    width: "50px",
    cursor: "pointer",
  }
}

export const defaultVideoIconButton = {    
  backgroundColor: "transparent",
  color: "rgba(255, 255, 255, 1)",
  borderRadius: "25px",
  padding: "10px",
  height: "50px",
  width: "50px",
  cursor: "not-allowed",
  "&.active" : {    
    backgroundColor: "transparent",
    color: "rgba(255, 255, 255, 1)",
    borderRadius: "25px",
    padding: "10px",
    height: "50px",
    width: "50px",
    cursor: "pointer",
  }
}

export const defaultVideoStartRecordIconButton = {    
  backgroundColor: "rgba(253,45,85,1)",
  color: "rgba(255, 255, 255, 1)",
  borderRadius: "40px",
  padding: "10px",
  height: "80px",
  width: "80px",
  border: "15px solid rgba(253,45,85,1)",
  cursor: "not-allowed",
  "&.active" : {    
    backgroundColor: "rgba(253,45,85,1)",
    color: "rgba(255, 255, 255, 1)",
    borderRadius: "40px",
    padding: "10px",
    height: "80px",
    width: "80px",
    border: "15px solid rgba(253,45,85,1)",
    cursor: "pointer",
  }
}

export const defaultVideoStopRecordIconButton = {    
  backgroundColor: "transparent",
  color: "rgba(255, 255, 255, 1)",
  borderRadius: "40px",
  padding: "10px",
  height: "80px",
  width: "80px",
  border: "15px solid rgba(253,45,85,0.5)",
  cursor: "not-allowed",
  "&.active" : {    
    backgroundColor: "transparent",
    color: "rgba(255, 255, 255, 1)",
    borderRadius: "40px",
    padding: "10px",
    height: "80px",
    width: "80px",
    border: "15px solid rgba(253,45,85,0.5)",
    cursor: "pointer",
  }
}

export const defaultVideoStopButton = {    
  backgroundColor: "transparent",
  color: "rgba(255, 255, 255, 1)",
  borderRadius: "40px",
  padding: "10px",
  height: "80px",
  width: "80px",
  border: "15px solid rgba(253,45,85,0.5)",
  cursor: "not-allowed",
  "&.active" : {    
    backgroundColor: "transparent",
    color: "rgba(255, 255, 255, 1)",
    borderRadius: "40px",
    padding: "10px",
    height: "80px",
    width: "80px",
    border: "15px solid rgba(253,45,85,0.5)",
    cursor: "pointer",
  }
}

export const defaultVideoStopIconButton = {    
  backgroundColor: "rgba(255, 255, 255, 0.2)",
  color: "rgba(255, 255, 255, 1)",
  borderRadius: "25px",
  padding: "10px",
  height: "50px",
  width: "50px",
  cursor: "not-allowed",
  "&.active" : {    
    backgroundColor: "rgba(255, 255, 255, 1)",
    color: "rgba(253,45,85,1)",
    borderRadius: "25px",
    padding: "10px",
    height: "50px",
    width: "50px",
    cursor: "pointer",
  }
}

export const defaultVideoPlayIconButton = {    
  backgroundColor: "rgba(255, 255, 255, 0.2)",
  color: "rgba(255, 255, 255, 1)",
  borderRadius: "25px",
  padding: "10px",
  height: "50px",
  width: "50px",
  cursor: "not-allowed",
  "&.active" : {    
    backgroundColor: "rgba(255, 255, 255, 1)",
    color: "rgba(253,45,85,1)",
    borderRadius: "25px",
    padding: "10px",
    height: "50px",
    width: "50px",
    cursor: "pointer",
  }
}

export const backBtn = {
  position: "relative", 
  padding: "0", 
  display: "block",
  color: "rgba(19,24,34,1)", 
  width: "auto",
  outline: "none",
  backgroundColor: "rgba(0,0,0,0)"
};

export const backTransparentBtn = {
  position: "relative", 
  padding: "0", 
  display: "block",
  color: "rgba(255,255,255,1)", 
  width: "auto",
  outline: "none",
  backgroundColor: "transparent"
};

export const followBtn = {
  fontSize: "12px",
  textTransform: "capitalize",
  width: "90px",
  height: "29px",
  borderWidth: "1px",
  borderStyle: "solid",
  boxShadow: "none",
  '&:hover, &:focus, &:active': {
    color: "#FFFFFF",
    backgroundColor : "#FE004F",
    borderColor: "#FE004F",
    boxShadow: "none",
  }
}

export const dashboardBtn = {
  width: "120px",
  height: "45px",
  margin: "0",
  display: "inline-block",
  fontSize: "14px",
  textTransform: "capitalize"
}

export const linkBtn = {
  width: "160px",
  height: "45px",
  margin: "20px auto",
  display: "block",
  fontSize: "14px",
  textTransform: "none"
}

export const continueBtn = {
  height: "auto",
  width: "auto",
  color: "rgba(255,255,255,1)",
  backgroundColor: "rgba(253,45,85,1)",
  border: "none",
  margin: "20px auto",
  display: "block",
  fontSize: "14px",
  textTransform: "none",
  '&:hover, &:focus, &:active': {
    color: "rgba(253,45,85,1)",
    backgroundColor: "rgba(255,255,255,1)",
  }
}

export const defaultBtn = {
  fontSize: "12px",
  textTransform: "capitalize",
  width: "90px",
  height: "29px",
  borderWidth: "1px",
  borderStyle: "solid",
  boxShadow: "none",
  '&:hover': {
    color: "#FFFFFF",
    backgroundColor : "#FE004F",
    borderColor: "#FE004F",
    boxShadow: "none",
  }
}

export const defaultActiveBtn = {
  fontSize: "12px",
  textTransform: "capitalize",
  width: "90px",
  height: "29px",
  borderWidth: "1px",
  borderStyle: "solid",
  boxShadow: "none",
  color: "#FFFFFF",
  backgroundColor : "#FE004F",
  borderColor: "#FE004F",
  boxShadow: "none",
  '&:hover': {
    color: "#FE004F",
    backgroundColor : "#FFFFFF",
    borderColor: "#FE004F",
    boxShadow: "none",
  }
};

export const defaultLabelBtn = {
  fontWeight: "400"
}

export const followActiveBtn = {
  fontSize: "12px",
  textTransform: "capitalize",
  width: "90px",
  height: "29px",
  borderWidth: "1px",
  borderStyle: "solid",
  boxShadow: "none",
  '&:hover, &:focus, &:active': {
    color: "#FE004F",
    backgroundColor : "#FFFFFF",
    borderColor: "#FE004F",
    boxShadow: "none",
  }
};

export const followLabelBtn = {
  fontWeight: "400"
}

export const messageBtn = {
  fontSize: "12px",
  textTransform: "capitalize",
  width: "90px",
  height: "29px",
  borderWidth: "1px",
  borderStyle: "solid",
  boxShadow: "none",
  '&:hover, &:focus, &:active': {
    color: "rgba(255,255,255,1)",
    backgroundColor : "rgba(253,45,85,1)",
    borderColor: "rgba(253,45,85,1)",
    boxShadow: "none",
  }
};

export const defaultPinkBtn = {
  fontSize: "14px",
  textTransform: "capitalize",
  width: "100%",
  height: "45px",
  borderWidth: "1px",
  borderStyle: "solid",
  color: "rgba(255,255,255,1)",
  backgroundColor : "rgba(253,45,85,1)",
  borderColor: "rgba(253,45,85,1)",
  boxShadow: "none",
  margin: "10px auto",
  borderRadius: "10px",
  '&:hover, &:focus, &:active': {
    color: "rgba(253,45,85,1)",
    backgroundColor : "rgba(255,255,255,1)",
    borderColor: "rgba(253,45,85,1)",
    boxShadow: "none",
  }
};

export const defaultWhiteBtn = {
  fontSize: "14px",
  textTransform: "capitalize",
  width: "100%",
  height: "45px",
  borderWidth: "0px",
  borderStyle: "solid",
  color: "rgba(253,45,85,1)",
  backgroundColor : "transparent",
  borderColor: "transparent",
  boxShadow: "none",
  margin: "10px auto",
  borderRadius: "10px",
  '&:hover, &:focus, &:active': {
    color: "rgba(253,45,85,1)",
    backgroundColor : "transparent",
    borderColor: "transparent",
    boxShadow: "none",
  }
};

export const messageActiveBtn = {
  fontSize: "12px",
  textTransform: "capitalize",
  width: "90px",
  height: "29px",
  borderWidth: "1px",
  borderStyle: "solid",
  boxShadow: "none",
  '&:hover, &:focus, &:active': {
    color: "rgba(253,45,85,1)",
    backgroundColor : "rgba(255,255,255,1)",
    borderColor: "rgba(253,45,85,1)",
    boxShadow: "none",
  }
};

export const messageLabelBtn = {
  fontWeight: "400"
}