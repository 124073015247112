import React from 'react';
import CameraWhite from './svg/CameraWhite';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  default : {
    width: "20px",
    display: "inline-block",
    top: "2px",
    position: "relative",
    "& > svg": {
      width: "inherit",
      height: "14px"
    }
  }
}));

export default function CameraIcon(props) {
  const classes = useStyles();
  return (
    <span className={classes.default}>
        <CameraWhite />
    </span>
  );
}