import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';

const useStyles = makeStyles((theme) => ({
  dialog : {
    backgroundColor: "rgba(255,255,255,1)",
    color: "rgba(0,0,0,1)"
  },
  textColor: {
    color: "rgba(98,74,251,1)"
  },
  marginAuto: {
    margin: "auto"
  }  
}));

const PaperComponent = (props) => {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function DialogModal(props) {
  const classes = useStyles();
  const { methodSubmit=null, methodCancel=null, openDialog=false, titleText="", contentText="", cancelText="cancel", methodText="submit" } = props;
  
  const handleDialogClose = (e) => {
    if (methodCancel) {
        methodCancel();
    }
  };

  const handleMethod = (e) => {
    if (methodSubmit) {
        methodSubmit();
    }
  }

  return (
    <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        classes={{root: classes.diaolog}}
    >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            {titleText}
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                {contentText}
            </DialogContentText>
        </DialogContent>
        <DialogActions classes={{ root: classes.marginAuto }}>
            <Button autoFocus onClick={handleDialogClose} color="primary" classes={{ root: classes.textColor }}>
                {cancelText}
            </Button>
            <Button onClick={handleMethod} color="primary" classes={{ root: classes.textColor }}>
                {methodText}
            </Button>
        </DialogActions>
    </Dialog>
  )
}
