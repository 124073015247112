import React from 'react';

export default class HamburgerDotBlack extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="4" viewBox="0 0 16 4">
                <g id="Group_3342" data-name="Group 3342" transform="translate(-344 -28)">
                    <circle id="Ellipse_28" data-name="Ellipse 28" cx="2" cy="2" r="2" transform="translate(344 28)" fill="#141822"/>
                    <circle id="Ellipse_29" data-name="Ellipse 29" cx="2" cy="2" r="2" transform="translate(350 28)" fill="#141822"/>
                    <circle id="Ellipse_30" data-name="Ellipse 30" cx="2" cy="2" r="2" transform="translate(356 28)" fill="#141822"/>
                </g>
            </svg>      
        )
    }
}