import React from 'react';

export default class FollowBigPurple extends React.Component {
    render() {
        return (
            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
                <g id="invisible_box" data-name="invisible box">
                    <rect id="Rectangle_1512" data-name="Rectangle 1512" width="42" height="42" fill="none"/>
                    <rect id="Rectangle_1513" data-name="Rectangle 1513" width="42" height="42" fill="none"/>
                    <rect id="Rectangle_1514" data-name="Rectangle 1514" width="42" height="42" fill="none"/>
                </g>
                <g id="icons_Q2" data-name="icons Q2" transform="translate(1.75 1.75)">
                    <g id="Group_3167" data-name="Group 3167">
                    <path id="Path_2207" data-name="Path 2207" d="M21.25,2A19.163,19.163,0,1,0,34.888,7.612,19.25,19.25,0,0,0,21.25,2Zm0,35A15.75,15.75,0,1,1,37,21.25,15.75,15.75,0,0,1,21.25,37Z" transform="translate(-2 -2)" fill="#624afb"/>
                    <path id="Path_2208" data-name="Path 2208" d="M31.25,20.75h-7v-7a1.75,1.75,0,0,0-3.5,0v7h-7a1.75,1.75,0,0,0,0,3.5h7v7a1.75,1.75,0,0,0,3.5,0v-7h7a1.75,1.75,0,0,0,0-3.5Z" transform="translate(-3.25 -3.25)" fill="#624afb"/>
                    </g>
                </g>
            </svg>        
        )
    }
}