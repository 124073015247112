import React from 'react';

export default class SendGray extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="31.539" height="31.539" viewBox="0 0 31.539 31.539">
                <g id="Layer_2" data-name="Layer 2" transform="translate(0 12.704) rotate(-34)">
                    <g id="invisible_box" data-name="invisible box">
                    <rect id="Rectangle_1553" data-name="Rectangle 1553" width="22.719" height="22.719" fill="none"/>
                    </g>
                    <g id="icons_Q2" data-name="icons Q2" transform="translate(1.903 2.379)">
                    <path id="Path_2243" data-name="Path 2243" d="M23.457,13.658,5.38,5.1,4.951,5A.951.951,0,0,0,4,5.951l2.854,8.563L4,23.077a.951.951,0,0,0,.951.951l.428-.1,18.077-8.563a.951.951,0,0,0,0-1.713ZM6.616,21.222l1.9-5.756h5a.951.951,0,0,0,0-1.9h-5l-1.9-5.756,14.176,6.708Z" transform="translate(-4 -5)" fill="#e3e3e3"/>
                    </g>
                </g>
            </svg>       
        )
    }
}