import React, { createContext, useReducer, useEffect } from "react";
import { MessageServiceContextReducer } from '../reducers/MessageServiceContextReducer';
import { MessageServiceInitialState } from "../constants/MessageServiceInitialState";

export const MessageServiceContext = createContext();
const initialState = MessageServiceInitialState;

const MessageServiceContextProvider = (props) => {
    const [MessageServiceContextState, MessageServiceContextDispatch] = useReducer(MessageServiceContextReducer,initialState);

    useEffect(() => {
    }, [])

    return (
        <MessageServiceContext.Provider value={{MessageServiceContextState, MessageServiceContextDispatch}}>
            {props.children}
        </MessageServiceContext.Provider>
    );
}

export default MessageServiceContextProvider;