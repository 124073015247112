export const DrawerSignUpContextReducer = (state, action) => {
    switch(action.type){
        case 'SHOW_DRAWER':
            return { 
                ...state,
                showDrawer: true
            }
        case 'HIDE_DRAWER':
            return {
                ...state, 
                showDrawer: false
            }
        case 'SHOW_UPGRADE_DRAWER':
            return { 
                ...state,
                showUpgradeDrawer: true
            }
        case 'HIDE_UPGRADE_DRAWER':
            return {
                ...state, 
                showUpgradeDrawer: false
            }
        case 'SHOW_SUBSCRIBE_DRAWER':
            return { 
                ...state,
                showSubscribeDrawer: true,
                subscribeName: action.payload.subscribeName
            }
        case 'HIDE_SUBSCRIBE_DRAWER':
            return {
                ...state, 
                showSubscribeDrawer: false,
                subscribeName: ""
            }
        case 'SHOW_ONE_ON_ONE_DRAWER':
            return { 
                ...state,
                showOneOnOneDrawer: true,
                subscribeName: action.payload.subscribeName
            }
        case 'HIDE_ONE_ON_ONE_DRAWER':
            return {
                ...state, 
                showOneOnOneDrawer: false,
                subscribeName: ""
            }    
        case 'HIDE_ALL_DRAWER':
            return {
                ...state,
                showDrawer: false,
                showUpgradeDrawer: false, 
                showSubscribeDrawer: false,
                showOneOnOneDrawer: false,
                subscribeName: ""
            }                
        default:
            return state    
    }
}