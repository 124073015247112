import React, { useContext, useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";

import { Container, Grid } from '@material-ui/core';
import { makeStyles, withStyles  } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import Box from '@material-ui/core/Box';

import { containerStyleWhite, formGridStyle3, formGridButtonSubmit, headerDefaultStyle, inputReadOnly, bodyDefaultStyle, bodyDefaultStyle3, bodyDefaultStyle5, bodyDefaultStyle6 } from '../../styles/defaultStyles.2';
import { ErrorTextStyle, ErrorTextContainerStyle } from '../../styles/defaultErrorStyles';
import { inputDefault, inputDefault2, inputUnderline, arrowIcon, arrowActiveIcon } from '../../styles/defaultInput';
import { gridItemHeaderWrapper } from '../../styles/defaultGridStyles';

import HelmetComponent from '../../components/HelmetComponent';
import SubmitButton from '../../components/SubmitButton.2';
import EditButton from '../../components/EditButton.2';
import CloseButton from '../../components/CloseButton.2';
import { FramerContext } from '../../contexts/FramerContext';
import { FormErrorContext } from '../../contexts/FormErrorContext';
import { AuthContext } from '../../contexts/AuthContext';
import { GlobalContext } from '../../contexts/GlobalContext';
import { useSnackbar } from 'notistack';

import { UpdateUserPayoutInformationService } from '../../services/UserPayoutInformationServices';
import { UpgradeUserService } from '../../services/UserServices';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  containerStyleWhite: containerStyleWhite, 
  formGridStyle3: formGridStyle3, 
  formGridButtonSubmit: formGridButtonSubmit, 
  headerDefaultStyle: headerDefaultStyle, 
  inputReadOnly: inputReadOnly, 
  bodyDefaultStyle: bodyDefaultStyle,
  bodyDefaultStyle3: bodyDefaultStyle3,
  bodyDefaultStyle5: bodyDefaultStyle5,
  bodyDefaultStyle6: bodyDefaultStyle6,
  ErrorTextStyle: ErrorTextStyle, 
  ErrorTextContainerStyle: ErrorTextContainerStyle,
  inputDefault: inputDefault,
  inputDefault2: inputDefault2,
  inputUnderline: inputUnderline,
  gridItemHeaderWrapper: gridItemHeaderWrapper,
  arrowIcon: arrowIcon,
  arrowActiveIcon: arrowActiveIcon,
  borderTop: {
    margin: '20px 0 0',
    borderTop: '1px solid rgba(0, 0, 0, 0.2)'
  },
  marginTop: {
    marginTop: "20px"
  }
}));

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 50,
    height: 25,
    padding: 0,
    display: 'flex',
    float: 'right',
    top: '-3px',
    position: 'relative'
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(25px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 21,
    height: 21,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 25 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

export default function AccountPayoutInformation() {
  const classes = useStyles();
  let history = useHistory();
  const { GlobalContextState, GlobalContextDispatch } = useContext(GlobalContext);
  const { isPwaMobile } = GlobalContextState;
  const { AuthContextState, AuthContextDispatch } = useContext(AuthContext);
  const { authToken, userId, registerFlow, upgradeFlow } = AuthContextState;
  const { FormErrorContextState, FormErrorContextDispatch } = useContext(FormErrorContext);  
  const { requestError } = FormErrorContextState;
  const { toggleDirection } = useContext(FramerContext);
  const { enqueueSnackbar } = useSnackbar();
  
  const [isEdit, setIsEdit] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isActive, setIsActive] = useState(true);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [stateAddress, setStateAddress] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [birthMonth, setBirthMonth] = useState('');
  const [birthDay, setBirthDay] = useState('');
  const [birthYear, setBirthYear] = useState('');
  const [socialNumber, setSocialNumber] = useState('');
  const [bankRouting, setBankRouting] = useState('');
  const [bankAccount, setBankAccount] = useState('');
  const [state, setState] = React.useState({
    directDepositStripe: false,
    paypal: false
  });

  useEffect(() => {    
    // if (!isSubmitted) {
      
    // } 

    if (isSubmitted) {
      const data = {
        activateDirectDepositStripe: state.directDepositStripe,
        activatePaypal: state.paypal,
        fisrt_name: firstName,
        last_name: lastName,
        middle_name: '',
        address: address,
        city: city,
        state: stateAddress,
        zipcode: zipcode,
        dob: `${birthMonth}-${birthDay}-${birthYear}`,
        bankRouting: bankRouting,
        bankAccount: bankAccount,
        company_name: '',
        company_address: '',
        company_city: '',
        company_state: '',
        company_zipcode: '',
        mailing_name: '',
        mailing_address: '',
        mailing_city: '',
        mailing_state: '',
        mailing_zipcode: '',
        billing_name: '',
        billing_address: '',
        billing_city: '',
        billing_state: '',
        billing_zipcode: '',
        shipping_name: '',
        shipping_address: '',
        shipping_city: '',
        shipping_state: '',
        shipping_zipcode: ''        
      }

      UpdateUserPayoutInformationService(data,userId,authToken).then(result => {
         toggleDirection(true,true);
        if (upgradeFlow) {
          UpgradeUserService({userType: 'creator'},userId,authToken).then(result => {
            AuthContextDispatch({ type: "UPDATE_USER_TYPE", payload: { userType: 'creator', authToken: result.data.token }}); 
            history.push('/account/welcome');
          }).catch(err => {
            console.log("catch",err.response.data,err.response.status);
            FormErrorContextDispatch({ type: "REQUEST_ERROR", payload: err.response.data });          
            GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});         
            setIsActive(false);
            setIsSubmitted(false);
            setIsEdit(false);
          })
        } else {
          if (registerFlow) {
            history.push('/account/welcome');
          } else {
            enqueueSnackbar('Update successful', { variant: 'default', autoHideDuration: 3000 });
            GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
            setIsActive(false);
            setIsSubmitted(false);
            setIsEdit(false);
          } 
        }
      }).catch(err => {
        console.log("catch",err.response.data,err.response.status);
        FormErrorContextDispatch({ type: "REQUEST_ERROR", payload: err.response.data });          
        GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});         
        setIsActive(false);
        setIsSubmitted(false);
        setIsEdit(false);
      })
    }
  }, [isSubmitted])

  const handleSubmit = (e) => {    
    e.preventDefault();
    setIsSubmitted(true); 
    if (!registerFlow && !upgradeFlow) {
      GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : true }}); 
    }    
  }

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  }

  const handleEditClick = (e) => {
    e.preventDefault();
    setIsEdit(true);
  }

  const handleCloseClick = (e) => {
    e.preventDefault();
    setIsEdit(false);
  }

  const handleEditResetClick = (e) => {
    e.preventDefault();
  }

  return (
    <>
      <HelmetComponent title={"DTF - Payout information"} />      
      <Container classes={{ root: classes.containerStyleWhite }} className={isPwaMobile ? " pwaMobile" : ""}>
        <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid 
            container
            justify="flex-start"
            direction="column"
            spacing={0}
            classes={{ root: classes.formGridStyle3 }}
          >
            {
              registerFlow || upgradeFlow
              ?
                <>
                  <Grid item>
                    <Typography variant={"h1"} classes={{ root: classes.headerDefaultStyle }}>
                      Set up Payout
                    </Typography>   
                  </Grid>
                  <Grid item classes={{ root: classes.gridItemHeaderWrapper }}> 
                    <Typography classes={{ root: classes.bodyDefaultStyle }}>
                      {/* Lorem ipsum dolor eset sim artun */}
                    </Typography> 
                  </Grid>
                </>
              :                
                <>
                  <Grid item>
                    <Typography variant={"h1"} classes={{ root: classes.headerDefaultStyle }}>
                      Payout
                    </Typography>   
                  </Grid>
                  <Grid item classes={{ root: classes.gridItemHeaderWrapper }}> 
                    <Typography classes={{ root: classes.bodyDefaultStyle }}>
                      {/* Lorem ipsum dolor eset sim artun */}
                    </Typography> 
                  </Grid>
                </>
            }            
            <Grid 
              item 
              container 
              justify="center"
              alignItems="center"
              spacing={2}
            >              
              <Grid item xs={12}> 
                <Typography classes={{ root: classes.bodyDefaultStyle3 }}>
                  Direct Deposit via Stipe <AntSwitch checked={state.directDepositStripe} onChange={handleChange} name="directDepositStripe" />
                </Typography>
                <Typography classes={{ root: classes.bodyDefaultStyle5 }} className={" grayItalic"}>
                  Payout fee is USD $0.25 per payout
                </Typography>
                <Box borderTop={1} classes={{ root: classes.borderTop }}></Box>
              </Grid>
              <Grid item container xs={12} spacing={1} classes={{ root: classes.gridItemInputWrapper }}>
                <Grid item xs={6}>    
                  <Input type="text" classes={{ root: classes.inputDefault2, underline: classes.inputUnderline }} fullWidth={false} placeholder="First Name" error={requestError.error} inputProps={{ 'aria-label': 'First Name' }} value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                </Grid>
                <Grid item xs={6}>
                  <Input type="text" classes={{ root: classes.inputDefault2, underline: classes.inputUnderline }} fullWidth={false} placeholder="Last Name" error={requestError.error} inputProps={{ 'aria-label': 'Last Name' }} value={lastName} onChange={(e) => setLastName(e.target.value)} />
                </Grid>
              </Grid>
              <Grid item container xs={12} spacing={1} classes={{ root: classes.gridItemInputWrapper }}>
                <Grid item xs={12}>
                  <Input type="text" classes={{ root: classes.inputDefault2, underline: classes.inputUnderline }} fullWidth={true} placeholder="Address" error={requestError.error} inputProps={{ 'aria-label': 'Address' }} value={address} onChange={(e) => setAddress(e.target.value)} />
                </Grid>
              </Grid>
              <Grid item container xs={12} spacing={1} classes={{ root: classes.gridItemInputWrapper }}>
                <Grid item xs={6}>    
                  <Input type="text" classes={{ root: classes.inputDefault2, underline: classes.inputUnderline }} fullWidth={false} placeholder="City" error={requestError.error} inputProps={{ 'aria-label': 'City' }} value={city} onChange={(e) => setCity(e.target.value)} />
                </Grid>
                <Grid item xs={2}>
                  <Input type="text" classes={{ root: classes.inputDefault2, underline: classes.inputUnderline }} fullWidth={false} placeholder="State" error={requestError.error} inputProps={{ 'aria-label': 'State' }} value={stateAddress} onChange={(e) => setStateAddress(e.target.value)} />
                </Grid>
                <Grid item xs={4}>
                  <Input type="text" classes={{ root: classes.inputDefault2, underline: classes.inputUnderline }} fullWidth={false} placeholder="Zipcode" error={requestError.error} inputProps={{ 'aria-label': 'Zipcode' }} value={zipcode} onChange={(e) => setZipcode(e.target.value)} />
                </Grid>
              </Grid>
              <Grid item container xs={12} spacing={1} classes={{ root: classes.gridItemInputWrapper }}>
                <Grid item xs={4}>    
                  <Input type="text" classes={{ root: classes.inputDefault2, underline: classes.inputUnderline }} fullWidth={false} placeholder="Birth Month" error={requestError.error} inputProps={{ 'aria-label': 'Birth Month' }} value={birthMonth} onChange={(e) => setBirthMonth(e.target.value)} />
                </Grid>
                <Grid item xs={4}>
                  <Input type="text" classes={{ root: classes.inputDefault2, underline: classes.inputUnderline }} fullWidth={false} placeholder="Birth Day" error={requestError.error} inputProps={{ 'aria-label': 'Birth Day' }} value={birthDay} onChange={(e) => setBirthDay(e.target.value)} />
                </Grid>
                <Grid item xs={4}>
                  <Input type="text" classes={{ root: classes.inputDefault2, underline: classes.inputUnderline }} fullWidth={false} placeholder="Birth Year" error={requestError.error} inputProps={{ 'aria-label': 'Birth Year' }} value={birthYear} onChange={(e) => setBirthYear(e.target.value)} />
                </Grid>
              </Grid>
              <Grid item container xs={12} spacing={1} classes={{ root: classes.gridItemInputWrapper }}>
                <Grid item xs={6}>    
                  <Typography classes={{ root: classes.bodyDefaultStyle6 }}>
                    Bank Routing Number#
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Input type="text" classes={{ root: classes.inputDefault2, underline: classes.inputUnderline }} fullWidth={false} placeholder="" error={requestError.error} inputProps={{ 'aria-label': 'Bank Routing' }} value={bankRouting} onChange={(e) => setBankRouting(e.target.value)} />
                </Grid>
              </Grid>
              <Grid item container xs={12} spacing={1} classes={{ root: classes.gridItemInputWrapper }}>
                <Grid item xs={6}>    
                  <Typography classes={{ root: classes.bodyDefaultStyle6 }}>
                    Bank Account Number#
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Input type="text" classes={{ root: classes.inputDefault2, underline: classes.inputUnderline }} fullWidth={false} placeholder="" error={requestError.error} inputProps={{ 'aria-label': 'Bank Account' }} value={bankAccount} onChange={(e) => setBankAccount(e.target.value)} />
                </Grid>
              </Grid>
              <Grid item xs={12} classes={{ root: classes.marginTop }}>                
                <Typography classes={{ root: classes.bodyDefaultStyle3 }}>
                  PayPal <AntSwitch checked={state.paypal} onChange={handleChange} name="paypal" />
                </Typography>
                <Typography classes={{ root: classes.bodyDefaultStyle5 }}  className={" grayItalic"}>
                  PayPal fee is 1% of the Amount transffered
                </Typography>
              </Grid>   
              <Grid item container xs={12} spacing={1} classes={{ root: classes.gridItemInputWrapper }}>
                <Grid item xs={12}>
                  <Input type="text" classes={{ root: classes.inputDefault2, underline: classes.inputUnderline }} fullWidth={true} placeholder="Username or email" error={requestError.error} inputProps={{ 'aria-label': 'Address' }} value={address} onChange={(e) => setAddress(e.target.value)} />
                </Grid>
              </Grid>
              <Grid item container xs={12} spacing={1} classes={{ root: classes.gridItemInputWrapper }}>
                <Grid item xs={12}>
                  <Input type="text" classes={{ root: classes.inputDefault2, underline: classes.inputUnderline }} fullWidth={true} placeholder="Password" error={requestError.error} inputProps={{ 'aria-label': 'Address' }} value={address} onChange={(e) => setAddress(e.target.value)} />
                </Grid>
              </Grid>
              {
                requestError.error
                ?
                  <Grid item xs={12} classes={{ root: classes.ErrorTextContainerStyle }}>
                    <Typography variant="body1" classes={{ root: classes.ErrorTextStyle }}>
                      { requestError.message }
                    </Typography>               
                  </Grid>
                :
                  null
              }                
            </Grid>
            {
              registerFlow || upgradeFlow
              ?
                <Grid container item direction="row" justify="center" alignItems="flex-end" classes={{ root: classes.formGridButtonSubmit }}>
                  <SubmitButton isActive={isActive} />
                </Grid>
              :
                <Grid container item direction="row" justify="center" alignItems="flex-end" classes={{ root: classes.formGridButtonSubmit }}>
                  {
                    isEdit
                    ?
                      <>
                        <CloseButton isActive={true} handleCloseClick={handleCloseClick} />
                        <SubmitButton isActive={isActive} />
                      </>  
                    :
                      <EditButton isActive={true} handleEditClick={handleEditClick} />
                  }                  
                </Grid>
            }
          </Grid>
        </form>
      </Container>
    </>
  );
}
