import React, { useContext, useState, useEffect, useRef }  from 'react';
import { useHistory } from "react-router-dom";

import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import { swipeContainerStyle } from '../styles/defaultStyles.2';

import SwipeTopBar from './SwipeTopBar.2';
import SwipeSearchInput from './SwipeSearchInput.2';
import SwipeSubscriptionsItem from './SwipeSubscriptionsItem';
import InfiniteScroll from "./InfiniteScroll";
import SwipeNullCase from "./SwipeNullCase";

import { GlobalContext } from '../contexts/GlobalContext';
import { AuthContext } from '../contexts/AuthContext';
import { SwipeSearchContext } from '../contexts/SwipeSearchContext';
import { GetSwipeSearch } from '../services/UserActionsServices';

const useStyles = makeStyles((theme) => ({
  swipeContainerStyle: swipeContainerStyle,
  searchWrapper: {
    position: "fixed",
    top: "50px",
    width: "100vw",
    padding: "20px",
    background: "rgba(255,255,255,1)",
    zIndex: "10",
    "&.pwaMobile": {
      top: "90px"
    }    
  },
  resultContainer: {
    padding: "140px 0px 70px",
    overflowX: "hidden",
    overflowY: "auto",
    position: "relative",
    "&.pwaMobile": {
      padding: "180px 0px 70px"
    },
    "&.profile": {
      padding: "140px 0px 70px",
      "&.pwaMobile": {
        padding: "180px 0px 70px"
      }
    }
  },
  overlayGradientTop: {
    width: "100%",
    top: "50px",
    height: "50px",
    position: "fixed",
    background: "linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))",
    zIndex: "1",
    "&.pwaMobile": {
      top: "90px"
    },    
    "&.profile": {
      top: "130px",
      "&.pwaMobile": {
        top: "160px"
      }
    }
  },
  overlayGradientBottom: {
    width: "100%",
    bottom: "0px",
    height: "50px",
    position: "fixed",
    background: "linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))",
    zIndex: "1"
  },
  swipeClass: {
    fixed: "unset"
  },
  bannerWrapper: {
    position: "relative",
    display: "block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    height: "175px",
    width: "100%",
    border: "0px solid rgba(0,0,0,1)"
  },
  itemBannerWrapper: {
    position: "relative",
    display: 'inline-block',
    width: "96%",
    height: "145px",
    margin: "10px"
  },  
  labelBtn: {
    fontWeight: '500',
    textTransform: 'none'
  },
  headerLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: 'rgba(20,24,34,1)',
    display: 'block',
    margin: '0 auto'
  },
  paragraphText: {
    display:'block',
    margin: '10px auto',
    fontSize: '14px',
    color: 'rgba(139,140,144,1)'
  }  
}));

export default function SwipeFollowing(props) {
  const classes = useStyles();
  const containerRef = useRef();
  const { GlobalContextState } = useContext(GlobalContext);
  const { isPwaMobile } = GlobalContextState;
  const { AuthContextState } = useContext(AuthContext);
  const { isAuthenticated } = AuthContextState;

  const { SwipeSearchContextState, SwipeSearchContextDispatch } = useContext(SwipeSearchContext);
  const { people='', peopleType='', profileId='', peopleResult=[], peopleResultExtract=[], itemPerPage=10, searchPageCount=0, pageNo=0, pageCount=0, initLoad=false, isLoading=false } = SwipeSearchContextState;

  const { isOwner=false, type='', pId='', anchor='right', isOpen=false, variant='temporary', onCloseHandler=()=>{} } = props;

  const [drawerState, setDrawerState] = useState(false);

  const closeDrawer = () => {
    onCloseHandler();
    SwipeSearchContextDispatch({ type: "RESET_SEARCH_INFO" });
    setDrawerState(false);
  }

  const handleTag = (data) => {
    // console.log("handleTag",data);
  }

  const handleLoadNext = () => {
    console.log('handleLoadNext',isLoading);
    if (pageNo < pageCount) {
      if (!isLoading) {
        SwipeSearchContextDispatch({ type: "SET_IS_LOADNEXT", payload: { loadNext: true } });  
        SwipeSearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading: true } });  
        SwipeSearchContextDispatch({ type: "SET_SEARCH_PEOPLE_LOADNEXT", payload: { profileId: pId, peopleType: type, people: people, itemPerPage: 10, pageNo: pageNo } });
      }    
    }
  }

  useEffect(() => {    
    if (isOpen) {
      setDrawerState(true);
      SwipeSearchContextDispatch({ type: "SET_SEARCH_PEOPLE", payload: { profileId: pId, peopleType: type, people: people, itemPerPage: 10, pageNo: 0  } });
      SwipeSearchContextDispatch({ type: "SET_IS_INITLOAD", payload: { initLoad: true } });
    }
  }, [isOpen])

  return (
    <SwipeableDrawer
      variant={variant} 
      anchor={anchor}
      open={drawerState}
      classes={{ root: classes.swipeClass }}
    >                 
      <Container classes={{ root: classes.swipeContainerStyle }} className={isPwaMobile ? " pwaMobile" : ""}> 
        <SwipeTopBar title={"Subscriptions"} closeHandler={closeDrawer} />
               <div className={classes.searchWrapper + " " + (isPwaMobile ? " pwaMobile" : "")}>
          <SwipeSearchInput profileId={pId} searchType={type} action={handleTag} />
        </div>     
        <Grid 
          container
          direction="column"  
          classes={{ root: classes.resultContainer }} 
          ref={containerRef}
          className={(isOwner ? " profile" : "") + (isPwaMobile ? " pwaMobile" : "")}
        >  
          {
            initLoad
            ?
              peopleResultExtract.length !== 0 || peopleResultExtract.length === 0 && pageNo < pageCount
              ?
                <>
                  <div className={classes.overlayGradientTop + " " + (isOwner ? " profile" : "") + (isPwaMobile ? " pwaMobile" : "")}></div>
                  {
                    peopleResultExtract.map((item) => {
                      return <SwipeSubscriptionsItem data={item} isOwner={isOwner} />
                    })
                  }
                  {
                    !isLoading 
                    ?
                      <InfiniteScroll parentRef={containerRef} method={handleLoadNext} />
                    :
                      null
                  }
                  <div className={classes.overlayGradientBottom}></div>                
                </>
              :
                !isLoading 
                ?
                  <SwipeNullCase isOwner={isOwner} messageNo={1} />
                :
                  null
            :
              null
          }          
        </Grid>
      </Container>          
    </SwipeableDrawer>
  );
}