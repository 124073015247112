import axios from 'axios';
import { VersionInitialState } from "../constants/VersionInitialState";
const versionApiNumber = VersionInitialState.versionApiNumber;

export async function GetUserService(userId,authToken) {
    const data = await axios.get(
        `/api/${versionApiNumber}/users/${userId}`,
        {
            headers: {
                'Authorization': `Bearer ${authToken}` 
            }
        }
    );
    return data;
}

//check later not working at the moment - something with req.body not iterable 
export async function UpdateUserService(objData,userId,authToken) {
    const data = await axios.post(
        `/api/${versionApiNumber}/users/${userId}`,
        objData,
        {
            headers: {
                'Authorization': `Bearer ${authToken}` 
            }
        }
    );
    return data;
}

export async function UpgradeUserService(objData,userId,authToken) {
    const data = await axios.post(
        `/api/${versionApiNumber}/users/upgrade/${userId}`,
        objData,
        {
            headers: {
                'Authorization': `Bearer ${authToken}` 
            }
        }
    );
    return data;
}

export async function DeleteUserService(userId,authToken) {
    const data = await axios.delete(
        `/api/${versionApiNumber}/users/${userId}`,
        {
            headers: {
                'Authorization': `Bearer ${authToken}` 
            }
        }
    );
    return data;
}

export async function GetUserProfileService(userId) {
    const data = await axios.get(
        `/api/${versionApiNumber}/users/userProfile/${userId}`
    );
    return data;
}

export async function GetUserProfileByHandleService(userHandle) {
    const data = await axios.get(
        `/api/${versionApiNumber}/users/userProfileByHandle/${userHandle}`
    );
    return data;
}

export async function GetUserActivitiesService(objData) {
    const data = await axios.get(
        `/api/${versionApiNumber}/users/activities/${objData.userId}/${objData.filter}/${objData.pageNo}/${objData.itemPerPage}`,
        {
            headers: {
                'Authorization': `Bearer ${objData.authToken}` 
            }
        }
    );
    return data;
}

export async function GetUserActivitiesCountsService(authToken) {
    const data = await axios.get(
        `/api/${versionApiNumber}/users/activities/counts`,
        {
            headers: {
                'Authorization': `Bearer ${authToken}` 
            }
        }
    );
    return data;
}

export async function updateUserActivitiesService(objData, authToken) {
    const data = await axios.post(
        `/api/${versionApiNumber}/users/activities/${objData.activityId}`,
        objData,
        {
            headers: {
                'Authorization': `Bearer ${authToken}` 
            }
        }
    );
    return data;
}

export async function GetUserNotificationVapIdService(objData) {
    const data = await axios.get(
        `/api/${versionApiNumber}/notifications/vapidPublicKey`,
        {
            headers: {
                'Authorization': `Bearer ${objData.authToken}` 
            }
        }
    );
    return data;
}

export async function PostUserNotificationRegisterService(objData) {
    const data = await axios.post(
        `/api/${versionApiNumber}/notifications/register`,
        objData.data,
        {
            headers: {
                'Authorization': `Bearer ${objData.authToken}` 
            }
        }
    );
    return data;
}