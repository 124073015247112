export const VersionContextReducer = (state, action) => {
    switch(action.type){
        case 'SET_NEW_VERSION':
            return { 
                ...state,
                newVersion: action.payload.newVersion,    
            }
        case 'SET_VERSION_NUMBER':
            return { 
                ...state,
                versionNumber: action.payload.versionNumber
            }
        case 'SET_NEW_VERSION_AND_NUMBER':
            return { 
                ...state,
                newVersion: action.payload.newVersion,
                versionNumber: action.payload.versionNumber,
            }
        case 'RESET':
            return { 
                ...state,
                newVersion: false,
                versionNumber: 0               
            }    
        default:
            return state    
    }
}