import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { GlobalContext } from '../contexts/GlobalContext';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    width: '100vw',
    height: '100vh',
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: '1000',
    backgroundColor: 'rgba(255,255,255,0.3)'
  },
  childSpinner: {
    position: 'relative',
    top: '45%',
    left: '45%'
  }
}));

export default function CustomBackdrop() {
  const classes = useStyles();
  const { GlobalContextState } = useContext(GlobalContext);
  const { showBackdrop } = GlobalContextState;  
  
  if (showBackdrop === true) {
    return (
      <div className={classes.root}>
        {/* <CircularProgress color="secondary" className={classes.childSpinner} /> */}
      </div>
    );
  } else {
    return null
  }
  
}