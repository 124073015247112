import React from 'react';
import { Helmet } from 'react-helmet';

function HelmetComponent(props) {
  const {title="", description="", rel="", href="", charSet="utf-8"} = props;
  return (    
      <Helmet>
          <title>{title}</title>     
          <meta charSet={charSet} />
          <meta name="description" content={description} />
          <link rel={rel} href={href} />
      </Helmet>
  );
}

export default HelmetComponent;