export const tabsContainer = {
  position: "relative",
  width: "100%",
  margin: "0",
  padding: "0px",
  overflow: "hidden"
}

export const tabsContainerFixed = {
  position: "fixed",
  top: "50px",
  width: "100%",
  margin: "0",
  padding: "0px",
  overflow: "hidden",
  backgroundColor: "rgba(255,255,255,1)",
  zIndex: "10",
  "&.pwaMobile": {
    top: "90px"
  }
}

export const tabs = {
  width: "25%",
  padding: "0px",
  minHeight: "36px",
  borderBottom: "2px solid rgba(98,74,251,0)"
}

export const tabsSelected = {
  width: "25%",
  padding: "0px",
  minHeight: "36px",
  borderBottom: "2px solid rgba(98,74,251,1)"
}

export const tabs25 = {
  width: "25%",
  padding: "0px",
  minHeight: "36px",
  borderBottom: "2px solid rgba(98,74,251,0)"  
}

export const tabs33 = {
  width: "33%",
  padding: "0px",
  minHeight: "36px",
  borderBottom: "2px solid rgba(98,74,251,0)"
}

export const tabs33Selected = {
  width: "33%",
  padding: "0px",
  minHeight: "36px",
  borderBottom: "2px solid rgba(98,74,251,1)"
}

export const tabs50 = {
  width: "50%",
  padding: "0px",
  minHeight: "36px",
  borderBottom: "2px solid rgba(98,74,251,0)"
}

export const tabs50Selected = {
  width: "50%",
  padding: "0px",
  minHeight: "36px",
  borderBottom: "2px solid rgba(98,74,251,1)"
}

export const tabs100 = {
  width: "100%",
  padding: "0px",
  minHeight: "36px"
}

export const tabsWrapper = {
  position: "relative",
  width: "100%",  
  borderTop: "1px solid rgba(192,192,192,0.6)",
  borderBottom: "1px solid rgba(192,192,192,0.6)",
  minHeight: "unset"
}

export const tabsWrapper2 = {
  position: "relative",
  width: "100%",  
  borderTop: "1px solid rgba(192,192,192,0.6)",
  borderBottom: "2px solid rgba(192,192,192,0.0)",
  minHeight: "unset"
}

export const muiTabWrapper = {
  textTransform: "none",    
  // color: "#969498",
  fontSize: "14px",
  fontWeight: "500",
  borderRight: "1px solid rgba(192,192,192,1)",
  lineHeight: "14px"
}

export const muiTabWrapperV2 = {
  textTransform: "none",    
  // color: "#969498",
  fontSize: "16px",
  fontWeight: "500",
  borderRight: "1px solid rgba(192,192,192,1)",
  lineHeight: "16px",
  display: "inline-block",
  margin: "15px 0",
  "& > sup": {
    background: "rgba(251,78,179,1)",
    padding: "1px 0px",
    borderRadius: "15px",
    display: "inline-block",
    fontSize: "9px",
    width: "14px",
    height: "14px",
    overflow: "hidden",
    textAlign: "center",
    color: "rgba(255,255,255,1)",
    position: "absolute",
    top: "5px",
    marginLeft: "2px",
    lineHeight: "12px"
  }
}

export const muiTabWrapperSelect = {
  textTransform: "none",    
  color: "rgba(98,74,251,1)",
  fontSize: "14px",
  fontWeight: "600",
  borderRight: "1px solid rgba(192,192,192,1)",
  lineHeight: "14px"
}

export const muiTabWrapperLast = {
  textTransform: "none",    
  // color: "#969498",
  fontSize: "14px",
  fontWeight: "500",
  borderRight: "0px solid rgba(192,192,192,1)",
  lineHeight: "14px"
}

export const muiTabWrapperV2Last = {
  textTransform: "none",    
  // color: "#969498",
  fontSize: "16px",
  fontWeight: "500",
  borderRight: "0px solid rgba(192,192,192,1)",
  lineHeight: "16px",
  display: "inline-block",
  "& > sup": {
    background: "rgba(251,78,179,1)",
    padding: "1px 0px",
    borderRadius: "15px",
    display: "inline-block",
    fontSize: "9px",
    width: "14px",
    height: "14px",
    overflow: "hidden",
    textAlign: "center",
    color: "rgba(255,255,255,1)",
    position: "absolute",
    top: "5px",
    marginLeft: "2px",
    lineHeight: "12px"
  }
}

export const muiTabWrapperSelectLast = {
  textTransform: "none",    
  color: "rgba(98,74,251,1)",
  fontSize: "14px",
  fontWeight: "600",
  borderRight: "0px solid rgba(192,192,192,1)",
  lineHeight: "14px"
} 