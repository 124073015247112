export const gridItemWrapper = {
  padding: "0"
};

export const gridItemHeaderWrapper = {
  margin: "0 0 30px"
}

export const gridItemHeaderWrapper2 = {
  margin: "0 0 10px"
}

export const gridCodeWrapper = {
  margin: "40% auto 0"
}

export const gridItemCodeWrapper = {
  width: "100%"
}