import React, { useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Container, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { videoTopBar } from '../styles/defaultVideoTopBarStyles';
import { backBtn } from '../styles/defaultGlobalTopBarStyles';
import BackArrowWhite from './svg/BackArrowWhite';
import { GlobalContext } from '../contexts/GlobalContext';
import { AuthContext } from '../contexts/AuthContext';

const useStyles = makeStyles((theme) => ({
    gridHeightStyles: {
        width: "100vw",
        height: "50px",
        margin: "0"        
    },
    gridItemStyles: {
        // padding: "15px !important",
        // height: "60px"
    },
    backBtn: backBtn,
    videoTopBar: videoTopBar
}));

function FeedTopBar(props) {

    const { hideBackButton=false } = props;
   
    const classes = useStyles();    
    let history = useHistory();

    const { AuthContextState } = useContext(AuthContext);
    const { GlobalContextState, GlobalContextDispatch } = useContext(GlobalContext);
    const { iOSInstaller, isPwaMobile } = GlobalContextState; 

    const handleClick = () => {
        history.goBack();
    }

    return (
        <Container classes={{ root: classes.videoTopBar }} className={(iOSInstaller ? ' hideTopBar' : '') + (isPwaMobile ? ' pwa' : '')}>
            <Grid 
                container
                justify="flex-start"
                alignItems="center"
                alignContent="center"
                direction="row"
                spacing={0}
                classes={{ root: classes.gridHeightStyles }}
            >
                {
                    hideBackButton
                    ?
                        null
                    :
                        <Grid item xs={2} align='left'>
                            <Button classes={{ root: classes.backBtn }} onClick={(e) => handleClick()}>
                                <BackArrowWhite />
                            </Button>         
                        </Grid>
                }                
            </Grid>
        </Container>
    );
}

export default React.memo(FeedTopBar);