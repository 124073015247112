export const videoWrapper = {
  position: "relative",
  height: "200px",
  overflow: "hidden",
  boxSizing: "border-box",
  borderRight: "1px solid rgba(255,255,255,1)",
  borderBottom: "1px solid rgba(255,255,255,1)",
  "&:nth-child(3n+3)": {
    borderRight: "0px solid rgba(255,255,255,1)",
  }
};

export const videoWrapper33 = {
  position: "relative",
  height: "200px",
  width: "33%",
  overflow: "hidden",
  boxSizing: "border-box",
  border: "1px solid rgba(255,255,255,1)",
  display: "inline-block"
};

export const videoImageElement = { 
  position: "relative",  
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: "0px",
  backgroundColor: "rgba(98,74,251,0.2)",
  color: "rgba(255,255,255,1)",
  fontSize: "50px",
  textAlign: "center",
  lineHeight: "200px",
  "&.facingMode": {
    transform: "rotateY(180deg)",
    "-webkit-transform": "rotateY(180deg)",
    "-moz-transform": "rotateY(180deg)"
  }  
}

export const videoImageElement2 = {
  width: "100%",
  height: "100%",
  position: "relative",
  objectFit: "cover",
  cursor: "pointer"
}

export const videoEditImageElement = { 
  position: "relative",
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: "6px",
  "&.facingMode": {
    // transform: 'scale(-1,1)',
    transform: "rotateY(180deg)",
    "-webkit-transform": "rotateY(180deg)",
    "-moz-transform": "rotateY(180deg)"
  }
}

export const videoImagePlayIconWrapperElement = { 
  textDecoration: "none",
  position: "absolute",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%"
}

export const videoImagePlayIconElement = { 
  position: "relative",
  fontSize: "60px",
  color: "rgba(255,255,255,0.75)",
  width: "50px",
  height: "50px",
  margin: "20% auto",
  display: "block"
}

export const videoFeedElement = { 
  position: "relative",
  width: "100vw",
  height: "100vh",
  objectFit: "cover",
  backgroundColor: "rgba(0,0,0,1)"
}

export const videoElement = { 
  position: "absolute",
  width: "100vw",
  height: "100vh",
  objectFit: 'cover',
  backgroundColor: "rgba(0,0,0,1)",
  pointerEvents: "none",
  "&.facingMode": {
    // transform: 'scale(-1,1)',
    transform: "rotateY(180deg)",
    "-webkit-transform": "rotateY(180deg)",
    "-moz-transform": "rotateY(180deg)"
  }  
}

export const videoElement2 = { 
  position: "absolute",
  width: "100vw",
  height: "auto",
  objectFit: 'contain',
  backgroundColor: "rgba(0,0,0,1)",
  pointerEvents: "none",
  zIndex: 1
}

export const videoElement3 = { 
  position: "absolute",
  width: "100vw",
  height: "inherit",
  objectFit: 'contain',
  backgroundColor: "transparent",
  pointerEvents: "none",
  zIndex: 1
}

export const imageElement = { 
  position: "absolute",
  width: "100vw",
  height: "auto",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  "-webkit-transform": "translate(-50%, -50%)",
  "-moz-transform": "translate(-50%, -50%)",
  backgroundColor: "rgba(0,0,0,1)",
  pointerEvents: "none",
  zIndex: 1,
  "&.facingMode": {
    transform: "translate(-50%, -50%) rotateY(180deg)",
    "-webkit-transform": "translate(-50%, -50%) rotateY(180deg)",
    "-moz-transform": "translate(-50%, -50%) rotateY(180deg)"
  }
}

export const imageElementBackground = { 
  position: "absolute",
  width: "100vw",
  height: "100vh",
  objectFit: "cover",
  filter: "blur(8px)",
  "-webkit-filter": "blur(8px)",  
  backgroundColor: "rgba(0,0,0,1)",
  pointerEvents: "none",
  zIndex: 0,
  "&.facingMode": {
    // transform: 'scale(-1,1)',
    transform: "rotateY(180deg)",
    "-webkit-transform": "rotateY(180deg)",
    "-moz-transform": "rotateY(180deg)"
  }  
}

export const videoFeedItem = {
  display: "block",
  justifyContent: "center",
  alignItems: "center",
  padding: "0",
  width: "100vw",
  height: "100vh",
  scrollSnapAlign:"start",
  overflow: "hidden",
  position: "relative",
}

export const videoFeedIframeWrapperElement = { 
  position: "relative",
  width: "100vw",
  height: "100vh",
  // paddingBottom: "56.25%",
  // paddingTop: "25px",
  // height: "100vh"
}

export const videoFeedIframeElement = { 
  position: "absolute",
  left: "0px",
  top: "0px",
  right: "0px",
  bottom: "0px",
  width: "100vw",
  height: "100vh"
}