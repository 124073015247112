import axios from 'axios';
import { VersionInitialState } from "../constants/VersionInitialState";
const versionApiNumber = VersionInitialState.versionApiNumber;

export async function GetSession() {
    // fetch data from a url endpoint
    const data = await axios.get("/api/1.0/session");
    return data;
}

export async function DestroySession(authToken) {
    // fetch data from a url endpoint
    const data = await axios.post(
        `/api/${versionApiNumber}/session/logout`, 
        {},
        {
            headers: {
                'Authorization': `Bearer ${authToken}` 
            }
        }
    );
    return data;
}

export async function GetVersion() {
    // fetch data from a url endpoint
    const data = await axios.get("/api/1.0/session/version");
    return data;
}