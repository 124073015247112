import { UserInitialState } from "../constants/UserInitialState";

export const UserContextReducer = (state, action) => {
    switch(action.type) {
        case 'INIT_USER': 
            return action.payload
        case 'SET_USER':
            return {
                ...state,
                userId: action.payload.user.user_id,
                username: action.payload.user.username,
                handleName: action.payload.user.handlename,
                userType: action.payload.user.userType,
                devices: action.payload.user.devices,
                date_created: action.payload.user.date_created,
                date_updated: action.payload.user.date_updated,
                user_information_id: action.payload.userInformation._id,
                user_description: action.payload.userInformation.user_description,
                user_fname:action.payload.userInformation.user_fname,
                user_followers: action.payload.userInformation.user_followers,
                user_following: action.payload.userInformation.user_following,
                user_house_link: action.payload.userInformation.user_house_link,
                user_house_name: action.payload.userInformation.user_house_name, 
                // user_videos: action.payload.userVideos,   
                user_image_profile: action.payload.userInformation.user_image_profile,
                user_link_profile: action.payload.userInformation.user_link_profile,
                user_lname: action.payload.userInformation.user_lname,
                user_mname: action.payload.userInformation.user_mname,
                user_moods: action.payload.userInformation.user_moods,
                user_socials: action.payload.userInformation.user_socials,
                user_subscribers: action.payload.userInformation.user_subscribers,
                user_subscriptions: action.payload.userInformation.user_subscriptions,
                user_suffix: action.payload.userInformation.user_suffix
            }
        case 'UPDATE_USER_INFORMATION':
            return {
                ...state,
                user_description: action.payload.userInformation.user_description,
                user_house_link: action.payload.userInformation.user_house_link,
                user_house_name: action.payload.userInformation.user_house_name,   
                user_image_profile: action.payload.userInformation.user_image_profile,
            }
        case 'UPDATE_USER_INFORMATION_MOOD':
            return {
                ...state,
                user_moods: action.payload.userInformation.user_moods,
            }
        case 'UPDATE_USER_INFORMATION_SOCIAL':
            return {
                ...state,
                user_moods: action.payload.userInformation.user_socials,
            }         
        case 'RESET_USER': 
            return {
                ...state
            } 
        case 'SIGN_OFF': 
            return UserInitialState;       
        case 'ADD_USER_VIDEO': 
            return {
                ...state,
                user_videos: action.payload.userVideos
            }
        case 'APPEND_USER_VIDEOS': 
            return {
                ...state,
                user_videos: {
                    ...state.user_videos,
                    videos: state.user_videos.videos.concat(action.payload.userVideos.videos)
                }
            }    
        default:
            return state    
    }
}