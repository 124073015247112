//https://reactjs.org/docs/hooks-reference.html#usereducer
import React, { createContext, useReducer, useEffect } from "react";
import { DrawerSignUpContextReducer } from '../reducers/DrawerSignUpContextReducer';

import { DrawerSignUpInitialState } from "../constants/DrawerSignUpInitialState";

export const DrawerSignUpContext = createContext();

const initialState = DrawerSignUpInitialState;

const DrawerSignUpContextProvider = (props) => {
    const [DrawerSignUpContextState, DrawerSignUpContextDispatch] = useReducer(DrawerSignUpContextReducer,initialState);

    useEffect(() => {
    }, [DrawerSignUpContextState])

    return (
        <DrawerSignUpContext.Provider value={{DrawerSignUpContextState, DrawerSignUpContextDispatch}}>
            {props.children}
        </DrawerSignUpContext.Provider>
    );
}
export default DrawerSignUpContextProvider;