import React from 'react';

export default class ScheduleBigPurple extends React.Component {
    render() {
        return (
            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
                <g id="invisible_box" data-name="invisible box">
                    <rect id="Rectangle_1507" data-name="Rectangle 1507" width="42" height="42" fill="none"/>
                </g>
                <g id="icons_Q2" data-name="icons Q2" transform="translate(1.75 1.75)">
                    <path id="Path_2088" data-name="Path 2088" d="M21.25,5.5A15.75,15.75,0,1,1,5.5,21.25,15.75,15.75,0,0,1,21.25,5.5m0-3.5A19.163,19.163,0,1,0,34.888,7.612,19.25,19.25,0,0,0,21.25,2Z" transform="translate(-2 -2)" fill="#624afb"/>
                    <path id="Path_2089" data-name="Path 2089" d="M32.5,29.5a1.488,1.488,0,0,1-.875-.262L22,23.462V13.75a1.75,1.75,0,0,1,3.5,0v7.788l7.875,4.725A1.744,1.744,0,0,1,32.5,29.5Z" transform="translate(-4.5 -3.25)" fill="#624afb"/>
                </g>
            </svg>        
        )
    }
}