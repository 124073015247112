import React from 'react';

export default class LockWhiteCirclePink extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
                <g id="Group_3342" data-name="Group 3342" transform="translate(-219 -462)">
                    <g id="Component_31_26" data-name="Component 31 – 26" transform="translate(219 462)">
                    <circle id="Ellipse_151" data-name="Ellipse 151" cx="10.5" cy="10.5" r="10.5" fill="#fb4eb3"/>
                    <g id="Layer_2" data-name="Layer 2" transform="translate(4 3)">
                        <g id="invisible_box" data-name="invisible box">
                        <rect id="Rectangle_1508" data-name="Rectangle 1508" width="14" height="14" fill="none"/>
                        </g>
                        <g id="icons_Q2" data-name="icons Q2" transform="translate(1.709 0.57)">
                        <g id="Group_3149" data-name="Group 3149">
                            <path id="Path_2112" data-name="Path 2112" d="M15.682,6.556H14.543V5.132A3.132,3.132,0,0,0,11.41,2h-.57A3.132,3.132,0,0,0,7.709,5.132V6.556H6.57a.57.57,0,0,0-.57.57V13.96a.57.57,0,0,0,.57.57h9.112a.57.57,0,0,0,.57-.57V7.126A.57.57,0,0,0,15.682,6.556ZM8.848,5.132a1.993,1.993,0,0,1,1.993-1.993h.57A1.993,1.993,0,0,1,13.4,5.132V6.556H8.848Zm6.265,8.258H7.139V7.7h7.973Z" transform="translate(-6 -2)" fill="#fff"/>
                            <path id="Path_2113" data-name="Path 2113" d="M22.178,27.065a1.139,1.139,0,1,0-1.709,1v.712a.57.57,0,0,0,1.139,0v-.712A1.139,1.139,0,0,0,22.178,27.065Z" transform="translate(-15.942 -19.12)" fill="#fff"/>
                        </g>
                        </g>
                    </g>
                    </g>
                </g>
            </svg>       
        )
    }
}