import React, { useContext, useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import HelmetComponent from "../../components/HelmetComponent";
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { videosContainerStyle } from '../../styles/defaultVideoStyles';
import InfiniteScroll from "../../components/InfiniteScroll";
import FeedVideoItem from "../../components/FeedVideoItem.2";
import FeedItem from  "../../components/FeedItem.7";
import FeedPeopleItem from  "../../components/FeedPeopleItem.3";
import FeedHouseItem from  "../../components/FeedHouseItem.3";
// import VideoItem from  "../../components/VideoItem.10";
// import VideoHouseItem from  "../../components/VideoHouseItem.5";
// import VideoPeopleItem from "../../components/VideoPeopleItem.5";
import { vh } from '../../constants/VideoSettings';
import { AuthContext } from '../../contexts/AuthContext';
import { DrawerCommentContext } from '../../contexts/DrawerCommentContext';
import { GetActiveVideoByVideoIdService } from '../../services/VideoServices';
import { GetSearchAllVideos } from "../../services/SearchServices"

const useStyles = makeStyles((theme) => ({
  videosContainerStyle: videosContainerStyle
}));

function FeedVideosDiscovery() {
  const classes = useStyles();
  const videosContainerRef = useRef();  
  const feedVideoRef = useRef();
  // const { userProfileId="", videoId=null, filter="all",  } = useParams();
  const { searchTypeStr="", searchStr="", videoId=null } = useParams();
  const { AuthContextState } = useContext(AuthContext);
  const { isAuthenticated, userId, authToken } = AuthContextState;
  const { DrawerCommentContextState } = useContext(DrawerCommentContext);
  const { indexUpdateCommentCount = [] } = DrawerCommentContextState;
  const [ videos, setVideos ] = useState([]);
  const [ pageCount, setPageCount ] = useState(1);
  const [ pageNo, setPageNo ] = useState(0);
  const [ itemPerPage, setItemPerPage ] = useState(2);  
  const [ userClick, setUserClick ] = useState(false);
  const [ isMuted, setIsMuted ] = useState(true);
  const [ currentSrc, setCurrentSrc ] = useState(null);
  const [ isCover, setIsCover ] = useState(null);

  const [ toggleLike, setToggleLike ] = useState(false);

  const toggleSound = (bool) => {
    console.log('toggleSound', bool);
    if (!userClick) {
      console.log('toggleSound1', bool);
      setIsMuted(bool, setUserClick(true));
    } else {
      console.log('toggleSound2', bool);
      setIsMuted(bool);
    }    
  }
  
  const loadNext = () => {
    if (pageCount > pageNo && videos.length !== 0) {
      const objData = { strSearch: searchStr, type: searchTypeStr, pageNo: pageNo, itemPerPage: itemPerPage, searchAllVideos: true, videoId: videoId }
      GetSearchAllVideos(objData).then((result) => {
          if (result.data.success === 1) {
            setVideos(videos.concat(result.data.search.video));
            setPageCount(result.data.search.videoPageCount);
          }
          setPageNo(pageNo + 1); 
      }).catch(err => {
        console.log("catch GetSearchAllVideos",err);
      })
    }
  }

  useEffect(() => {
    
    if (videoId !== "") {
      GetActiveVideoByVideoIdService({videoId: videoId}).then(result => {
        setVideos(result.data.userVideos.videos);          
      }).catch(err => {
          console.log("catch GetActiveVideoByVideoIdService",err);
      })  
    } else {
      const objData = { strSearch: searchStr, type: searchTypeStr, pageNo: pageNo, itemPerPage: itemPerPage, searchAllVideos: true, videoId: videoId }
      GetSearchAllVideos(objData).then((result) => {         
          if (result.data.success === 1) {
            setVideos(result.data.search.video);
            setPageCount(result.data.search.videoPageCount);
          }
          setPageNo(pageNo + 1); 
      }).catch(err => {
        console.log("catch GetSearchAllVideos",err);
      })      
    }   
  }, [])

  return (
    <>
      <HelmetComponent title={"DTF - Videos"} />
      <Container ref={videosContainerRef} classes={{ root: classes.videosContainerStyle }}>   
        {
          videos.length !== 0
          ?
            videos.map((item, index) => {
              if (searchTypeStr === "houses" && index !== 0) {
                return <FeedHouseItem setToggleLike={setToggleLike} toggleLike={toggleLike} setIsCover={setIsCover} setCurrentSrc={setCurrentSrc} itemRef={feedVideoRef} isMuted={isMuted} userClick={userClick} hideBackButton={false} indexUpdateCommentCount={indexUpdateCommentCount.length !== 0 ? indexUpdateCommentCount : []} key={'video-'+index} item={item} index={index} firstItem={index===0 ? true : false} parentRef={videosContainerRef} />
              } else if (searchTypeStr === "people" && index !== 0) {
                return <FeedPeopleItem setToggleLike={setToggleLike} toggleLike={toggleLike} setIsCover={setIsCover} setCurrentSrc={setCurrentSrc} itemRef={feedVideoRef} isMuted={isMuted} userClick={userClick} hideBackButton={false} indexUpdateCommentCount={indexUpdateCommentCount.length !== 0 ? indexUpdateCommentCount : []} key={'video-'+index} item={item} index={index} firstItem={index===0 ? true : false} parentRef={videosContainerRef} />
              } else {
                return <FeedItem setToggleLike={setToggleLike} toggleLike={toggleLike} setIsCover={setIsCover} setCurrentSrc={setCurrentSrc} itemRef={feedVideoRef} isMuted={isMuted} userClick={userClick} hideBackButton={false} indexUpdateCommentCount={indexUpdateCommentCount.length !== 0 ? indexUpdateCommentCount : []} key={'video-'+index} video={item} index={index} firstItem={index===0 ? true : false} parentRef={videosContainerRef} />
              }
            })
          :
            null            
        }
        <FeedVideoItem itemRef={feedVideoRef} source={currentSrc} toggleSound={toggleSound} setToggleLike={setToggleLike} toggleLike={toggleLike} />
        <InfiniteScroll parentRef={videosContainerRef} method={loadNext} margin={vh + "px"} />
      </Container> 
    </>  
  );
}

export default FeedVideosDiscovery;