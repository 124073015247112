import React, { useContext, useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import { CloudinaryContext, Image, Transformation } from "cloudinary-react";
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import Fab from '@material-ui/core/Fab';
import Avatar from '@material-ui/core/Avatar';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import EditIcon from '@material-ui/icons/Edit';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import PersonOutlineRoundedIcon from '@material-ui/icons/PersonOutlineRounded';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import Input from '@material-ui/core/Input';

import { containerStyle, formGridStyle, formGridButtonSubmit, headerDefaultStyle, inputReadOnly, bodyDefaultStyle, bodyDefaultStyle2 } from '../../styles/defaultStyles';
import { ErrorTextStyle, ErrorTextContainerStyle } from '../../styles/defaultErrorStyles';
import { inputDefault, inputUnderline, inputMoodDefault, inputNoUnderline } from '../../styles/defaultInput';
import { gridItemHeaderWrapper } from '../../styles/defaultGridStyles';
import { moodAvatar } from '../../styles/defaultAvatarStyles'; 
import TopNavigation from '../../components/TopNavigation';
import HelmetComponent from '../../components/HelmetComponent';
import SubmitButton from '../../components/SubmitButton.2';
import EditButton from '../../components/EditButton';
import CloseButton from '../../components/CloseButton';

import { FramerContext } from '../../contexts/FramerContext';
import { FormErrorContext } from '../../contexts/FormErrorContext';
import { AuthContext } from '../../contexts/AuthContext';
import { GlobalContext } from '../../contexts/GlobalContext';
import { UserContext } from '../../contexts/UserContext';
import { useSnackbar } from 'notistack';

import { GetToken } from "../../utils/TokenUtil";

import { UpdateUserInformationMoodsService } from '../../services/UserInformationServices';
import { UploadImageMoodService, DeleteImageMoodService } from '../../services/UploadServices';
import { photoLimitSize, photoFileExtension } from '../../constants/FileSizeConstants';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  containerStyle: containerStyle, 
  formGridStyle: formGridStyle, 
  formGridButtonSubmit: formGridButtonSubmit, 
  headerDefaultStyle: headerDefaultStyle, 
  inputReadOnly: inputReadOnly, 
  bodyDefaultStyle: bodyDefaultStyle,
  bodyDefaultStyle2: bodyDefaultStyle2,
  ErrorTextStyle: ErrorTextStyle, 
  ErrorTextContainerStyle: ErrorTextContainerStyle,
  inputDefault: inputDefault, 
  inputUnderline: inputUnderline,
  inputNoUnderline: inputNoUnderline,
  gridItemHeaderWrapper: gridItemHeaderWrapper,
  iconWrapper: {
    boxShadow: "none",
    backgroundColor: "rgba(253,45,85,0.2)",
    color: "rgba(255,255,255,1)",
    margin: "auto"
  },
  iconCloud: {
    color: "rgba(253,45,85,1)",
    width: "40px",
    height: "40px"
  },
  plusIcon: {
    fontSize: "18px",
    position: "relative",
    top: "-10px",
    color: "rgba(255,255,255,1)",
    background: "rgba(253,45,85,1)",
    borderRadius: "15px",
    border: "2px solid rgba(255,255,255,1)"
  },
  editIcon: {
    fontSize: "25px",
    position: "absolute",
    right: "20px",
    color: "rgba(255,255,255,1)",
    background: "rgba(253,45,85,1)",
    borderRadius: "15px",
    border: "2px solid rgba(255,255,255,1)",
    padding: "3px"
  },
  deleteIcon: {
    fontSize: "25px",
    position: "absolute",
    right: "20px",    
    color: "rgba(255,255,255,1)",
    background: "rgba(253,45,85,1)",
    borderRadius: "15px",
    border: "2px solid rgba(255,255,255,1)",
    padding: "3px"
  },
  activeIcon: {
    fontSize: "25px",
    position: "absolute",
    right: "-2px",
    top: "35px",
    color: "rgba(255,255,255,1)",
    background: "rgba(253,45,85,1)",
    borderRadius: "15px",
    border: "2px solid rgba(255,255,255,1)",
    padding: "3px",
    "&.active": {
      color: "rgba(253,45,85,1)",
      background: "rgba(255,255,255,1)",
      border: "1px solid rgba(253,45,85,1)",
    }
  },
  moodAvatar: moodAvatar,
  inputRootDefault: {
    height: "24px",
    margin: "20px auto"
  },
  inputMoodDefault: inputMoodDefault,
  MoodGridWrapper: {
    padding: "0px"
  },
  MoodItemWrapper: {
    position: "relative",
    "&.editMode": {
      opacity: "0.5"
    }
  }
}));

export default function AccountProfileMood() {
  const classes = useStyles();
  let history = useHistory();
  const { GlobalContextDispatch } = useContext(GlobalContext);
  const { AuthContextState, AuthContextDispatch } = useContext(AuthContext);
  const { authToken, userId, registerFlow } = AuthContextState;   
  const decodeToken = GetToken(authToken);
  const tokenUserType = (typeof decodeToken.userType !== 'undefined' ? decodeToken.userType : 'user');
  const { FormErrorContextState, FormErrorContextDispatch } = useContext(FormErrorContext);  
  const { UserContextState, UserContextDispatch } = useContext(UserContext);
  const { user_moods, user_image_profile, user_name } = UserContextState;
  const { requestError } = FormErrorContextState;
  const { toggleDirection } = useContext(FramerContext);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  
  const [isEdit, setIsEdit] = useState(false);
  const [editItem, setEditItem] = useState(-1);
  const [isAdd, setIsAdd] = useState(false);

  const [mood, setMood] = useState('');
  const [moodImage, setMoodImage] = useState('');
  const [moodImageInformation, setMoodImageInformation] = useState('');
  const [moodActive, setMoodActive] = useState(false);

  useEffect(() => {    
    if (!isSubmitted) {
      if (isEdit) {
        if ((mood.length > 0 && user_moods[editItem].mood !== mood) || (moodImage !== user_moods[editItem].image && moodImage.length > 0)) {
          setIsActive(true);      
        } else {
          setIsActive(false);
        }
      } else {
        if (isAdd) {
          if (mood.length > 0 || moodImage.length > 0) {
            setIsActive(true);      
          } else {
            setIsActive(false);
          }
        }
      }    
    }

    if (isSubmitted) {
      
      let deleteImageName = '';
      let deleteImage = false;
      let dataArr = [];

      dataArr = user_moods;
      const data = {
        mood: mood || '',
        image: moodImage || '',
        moodImageInformation: moodImageInformation || '',
        active: moodActive || false
      }

      if (isAdd) {
        dataArr.push(data)
      } 

      if (isEdit && editItem > -1) {        
        if (typeof user_moods[editItem].image !== 'undefined') {
          if (moodImage !== '' && user_moods[editItem].image !== moodImage && user_moods[editItem].image !== '' && user_moods[editItem].image !== null && !registerFlow) {
            deleteImageName = user_moods[editItem].image;
            deleteImage = true;
          }
        }
        dataArr[editItem] = data; 
      }      

      UpdateUserInformationMoodsService({moods:dataArr, deleteImage: deleteImage, deleteImageName: deleteImageName},userId,authToken).then(result => {       
        setIsSubmitted(false); 
        setIsActive(false); 
        setIsEdit(false);
        setIsAdd(false);
        UserContextDispatch({ type: "UPDATE_USER_INFORMATION_MOOD", payload: result.data });          
        setEditItem(-1);
        setMood('');
        setMoodImage('');
        setMoodImageInformation('');
        setMoodActive(false);   
        if (registerFlow) {          
           toggleDirection(true,true);
          if (tokenUserType === "creator") {
            history.push('/account/social-information');
          } else {
            AuthContextDispatch({ type: "UPDATE_REGISTRER_FLOW", payload: { registerFlow: false } });
            history.push('/account/profile');
          }
        } else {
          enqueueSnackbar('Update successful', { variant: 'default', autoHideDuration: 3000 });
          GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
        }        
      }).catch(err => {
        console.log("catch",err.response.data,err.response.status);
        setIsSubmitted(false);
        setIsActive(false);
        FormErrorContextDispatch({ type: "REQUEST_ERROR", payload: err.response.data });          
        GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
        setIsEdit(false);
        setIsAdd(false);
        setEditItem(-1);
        setMood('');
        setMoodImage('');
        setMoodImageInformation('');
        setMoodActive(false);
      })
    }
  }, [isSubmitted, mood, moodImage])

  const handleSubmit = (e) => { 
    e.preventDefault();
    setIsSubmitted(true);
    if (!registerFlow) {
      GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : true }});
    } 
  }

  const handleCloseClick = (e) => {
    e.preventDefault(); 
    
    setFileInputState('');

    const data = {
      fileName: moodImage
    }    
    if (isEdit) {
      if (typeof user_moods[editItem].image !== undefined) {
        if (moodImage !== '' && user_moods[editItem].image !== moodImage) {          
          DeleteImageMoodService(data,userId,authToken); 
        }
      }
    } else {
      if (moodImage !== '') {                
        DeleteImageMoodService(data,userId,authToken); 
      }
    }

    setIsEdit(false);
    setIsAdd(false);
    setEditItem(-1);
    setMood('');
    setMoodImage('');
    setMoodImageInformation('');
    setMoodActive(false);
  }

  const editItemObj = (e, bool, index) => {
    e.preventDefault();
    setFileInputState('');

    const data = {
      fileName: moodImage
    }    
    if (isEdit) {
      if (typeof user_moods[editItem].image !== undefined) {
        if (moodImage !== '' && user_moods[editItem].image !== moodImage) {          
          DeleteImageMoodService(data,userId,authToken); 
        }
      }
    } else {
      if (moodImage !== '') {                
        DeleteImageMoodService(data,userId,authToken); 
      }
    }

    setEditItem(index);
    setMood(user_moods[index].mood);
    setMoodImage(user_moods[index].image);
    setMoodImageInformation(user_moods[index].moodImageInformation);
    setMoodActive(user_moods[index].active);
    if (isAdd) {      
      setIsAdd(false);
    }
    setIsEdit(bool);
  }

  const deleteMood = (e) => {
    e.preventDefault();  
    GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : true }});
    const deleteImageName = user_moods[editItem].image || '';
    const deleteImage = true;

    user_moods.splice(editItem, 1);
    let dataArr = [];
    dataArr = user_moods;

    UpdateUserInformationMoodsService({moods: dataArr, deleteImage: deleteImage, deleteImageName: deleteImageName},userId,authToken).then(result => {       
      setIsSubmitted(false); 
      setIsActive(false); 
      setIsEdit(false);
      setIsAdd(false);
      UserContextDispatch({ type: "UPDATE_USER_INFORMATION_MOOD", payload: result.data });          
      setEditItem(-1);
      setMood('');
      setMoodImage('');
      setMoodImageInformation('');
      setMoodActive(false);
      setFileInputState('');
      enqueueSnackbar('Update successful', { variant: 'default', autoHideDuration: 3000 });
      GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});   
    }).catch(err => {
      console.log("catch",err.response.data,err.response.status);
      setIsSubmitted(false);
      setIsActive(false);
      FormErrorContextDispatch({ type: "REQUEST_ERROR", payload: err.response.data });          
      GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
      setIsEdit(false);
      setIsAdd(false);
      setEditItem(-1);
      setMood('');
      setMoodImage('');
      setMoodImageInformation('');
      setMoodActive(false);
      setFileInputState('');
    })
  }

  const updateActive = (e, index) => {
    e.preventDefault();
    let dataArr = [];
    dataArr = user_moods.map(function(mood) { 
      mood.active = false; 
      return mood;
    });

    if (index !== -1) {
      const data = {
        ...user_moods[index],
        active: true
      } 
      dataArr[index] = data; 
    }

    UpdateUserInformationMoodsService({moods:dataArr},userId,authToken).then(result => {       
      setFileInputState('');

      setIsSubmitted(false); 
      setIsActive(false); 
      setIsEdit(false);
      setIsAdd(false);
      UserContextDispatch({ type: "UPDATE_USER_INFORMATION_MOOD", payload: result.data });          
      setEditItem(-1);
      setMood('');
      setMoodImage('');
      setMoodImageInformation('');
      setMoodActive(false);
      enqueueSnackbar('Update successful', { variant: 'default', autoHideDuration: 3000 });
      GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});   
    }).catch(err => {
      console.log("catch",err.response.data,err.response.status);
      setFileInputState('');

      setIsSubmitted(false);
      setIsActive(false);
      FormErrorContextDispatch({ type: "REQUEST_ERROR", payload: err.response.data });          
      GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
      setIsEdit(false);
      setIsAdd(false);
      setEditItem(-1);
      setMood('');
      setMoodImage('');
      setMoodImageInformation('');
      setMoodActive(false);
    })

  }
  
  const [fileInputState, setFileInputState] = useState('');
  const handleFileInputChange = (e, index) => {
    GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : true }});
    setFileInputState(e.target.value);

    try {
      const file = e.target.files[0];
      if (!photoFileExtension.test(file.name)) {
        setFileInputState('');
        enqueueSnackbar('File format is not supported', { variant: 'default', autoHideDuration: 3000 });
        GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
        return;
      } 

      if (file.size > photoLimitSize) {
        setFileInputState('');
        enqueueSnackbar('File size limit is 10MB', { variant: 'default', autoHideDuration: 3000 });
        GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
        return;
      } 

      const fullFileName = file.name;
      const fileNameOnly = fullFileName.substr(0, fullFileName.lastIndexOf('.')) || fullFileName;
      const tempImage = window.DTF_APP.IMAGE_PATH+"public/image/users/"+userId+"/mood/"+fileNameOnly;      

      if (user_moods.find(m => m.image === tempImage)) {
        setFileInputState('');
        enqueueSnackbar('File already exists', { variant: 'default', autoHideDuration: 3000 });
        GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
        return;
      } 

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {        
        const objData = {
          data: reader.result,
          fileName: fileNameOnly
        }         
        const data = {
          fileName: moodImage
        } 
        if (index !== -1) {
          if (typeof user_moods[index].image !== 'undefined') {
            if (moodImage !== '' && user_moods[index].image !== moodImage) {                
              DeleteImageMoodService(data,userId,authToken); 
            }
          }          
        } else {
          if (moodImage !== '') {                
            DeleteImageMoodService(data,userId,authToken); 
          }
        }               
        
        UploadImageMoodService(objData,userId,authToken).then(result => {
          setMoodImage(result.data.uploadedResponse.public_id);
          setMoodImageInformation(result.data.uploadedResponse);         
          setFileInputState('');
          GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
        }).catch(err => {
          setFileInputState('');
          FormErrorContextDispatch({ type: "REQUEST_ERROR", payload: err.response.data });          
          GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }}); 
        })
      }     
    } catch (error) {
      console.log(error);
      setFileInputState('');
      GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
    }
  };

  return (
    <>
      <HelmetComponent title={"DTF - Profile mood"} />      
      <Container classes={{ root: classes.containerStyle }}>
        <TopNavigation showMenuIcon={(!registerFlow  ? true : false)} />
        <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
          <CloudinaryContext cloudName={"dtf2021"}>   
            <Grid 
              container
              justify="flex-start"
              direction="column"
              spacing={0}
              classes={{ root: classes.formGridStyle }}
            >
              {
                registerFlow
                ?
                  <>
                    <Grid item>
                      <Typography variant={"h1"} classes={{ root: classes.headerDefaultStyle }}>
                        Show users how you feel
                      </Typography>   
                    </Grid>
                    <Grid item classes={{ root: classes.gridItemHeaderWrapper }}> 
                      <Typography classes={{ root: classes.bodyDefaultStyle }}>
                        {/* Lorem ipsum dolor eset sim artun */}
                      </Typography> 
                    </Grid>
                  </>
                :                
                  <>
                    <Grid item>
                      <Typography variant={"h1"} classes={{ root: classes.headerDefaultStyle }}>
                        Profile Mood
                      </Typography>   
                    </Grid>
                    <Grid item classes={{ root: classes.gridItemHeaderWrapper }}> 
                      <Typography classes={{ root: classes.bodyDefaultStyle }}>
                        {/* Lorem ipsum dolor eset sim artun */}
                      </Typography> 
                    </Grid>
                  </>
              }            
              <Grid 
                item 
                container 
                justify="center"
                alignItems="center"
                spacing={2}
              >             
                <Grid item container xs={12} classes={{ root: classes.MoodGridWrapper }}> 
                  <Grid key={"default"} item container xs={4} classes={{ root: classes.MoodItemWrapper }}>    
                    <Avatar classes={{ root: classes.moodAvatar }} className={(user_moods.find(m => m.active === true) ? "" : "active")}>
                      <Image publicId={user_image_profile} alt={user_name} secure="true">
                        <Transformation gravity="faces:auto" height="100" width="100" crop="thumb" fetchFormat="auto" />
                      </Image>
                    </Avatar>
                    <PersonOutlineRoundedIcon classes={{ root: classes.activeIcon }} onClick={(e) => updateActive(e, -1)} />
                  </Grid>
                  {
                    user_moods.map((data, index) => (                      
                      isEdit && editItem === index
                      ?
                        <Grid key={"edit-"+""+index} item container xs={4} classes={{ root: classes.MoodItemWrapper }}> 
                          <Avatar classes={{ root: classes.moodAvatar }} aria-label="Edit" className={(data.active ? "active" : "")}>
                            <input
                              id={"icon-button-photo-"+index}
                              type="file"
                              name={"image-"+index}
                              onChange={(e) => handleFileInputChange(e,index)}
                              value={fileInputState}
                              hidden
                            />
                            <label htmlFor={"icon-button-photo-"+index}>
                              { 
                                moodImage                         
                                ?
                                  <Image publicId={moodImage} alt={user_name+" "+mood} secure="true">
                                    <Transformation gravity="faces:auto" height="100" width="100" crop="thumb" fetchFormat="auto" />
                                  </Image>
                                :
                                  <CloudUploadOutlinedIcon color={"primary"} classes={{ root: classes.iconCloud }} />
                              }
                            </label>
                          </Avatar> 
                          <DeleteRoundedIcon classes={{ root: classes.deleteIcon }} onClick={(e) => deleteMood(e)} />
                          <Input type="text" classes={{ root: classes.inputRootDefault, input: classes.inputMoodDefault, underline: classes.inputUnderline }} fullWidth={true} placeholder="enter mood" error={requestError.error} inputProps={{ 'aria-label': 'Enter mood' }} value={mood} onChange={(e) => setMood(e.target.value)} />                   
                        </Grid> 
                      :
                        <Grid key={data.mood+""+index} item container xs={4} classes={{ root: classes.MoodItemWrapper }} className={(isEdit ? "editMode" : "")}> 
                          <Avatar aria-label="upload" classes={{ root: classes.moodAvatar }} className={(data.active ? "active" : "")}>                          
                            {
                              data.image
                              ?
                                <Image publicId={data.image} alt={user_name+" "+mood} secure="true">
                                  <Transformation gravity="faces:auto" height="100" width="100" crop="thumb" fetchFormat="auto" />
                                </Image>
                              :
                                null
                            }
                          </Avatar>
                          <EditIcon classes={{ root: classes.editIcon }} onClick={(e) => editItemObj(e, true, index)} />
                          {
                            !data.active && !isEdit
                            ?
                              <PersonOutlineRoundedIcon classes={{ root: classes.activeIcon }} onClick={(e) => updateActive(e, index)} />
                            :
                              null
                          }
                          <Typography variant={"body1"}  classes={{ root: classes.bodyDefaultStyle2 }}>
                            {data.mood}
                          </Typography>
                        </Grid>
                    ))
                  }
                  {
                    isAdd
                    ?
                      <Grid key={"add"} item container xs={4} classes={{ root: classes.MoodItemWrapper }}> 
                        <Avatar classes={{ root: classes.moodAvatar }} aria-label="Add" >
                          <input
                            id="icon-button-photo"
                            type="file"
                            name="image"
                            onChange={(e) => handleFileInputChange(e,-1)}
                            value={fileInputState}
                            className="form-input"
                            hidden
                          />
                          <label htmlFor="icon-button-photo">
                            { 
                              moodImage                         
                              ?
                                <Image publicId={moodImage} alt={user_name+" "+mood} secure="true">
                                  <Transformation gravity="faces:auto" height="100" width="100" crop="thumb" fetchFormat="auto" />
                                </Image>
                              :
                                <CloudUploadOutlinedIcon color={"primary"} classes={{ root: classes.iconCloud }} />
                            }
                          </label>            
                        </Avatar>
                        <Input type="text" classes={{ root: classes.inputRootDefault, input: classes.inputMoodDefault, underline: classes.inputUnderline }} fullWidth={true} placeholder="enter mood" error={requestError.error} inputProps={{ 'aria-label': 'Enter mood' }} value={mood} onChange={(e) => setMood(e.target.value)} />                
                      </Grid>
                    :
                      !isEdit
                      ?
                        <Grid key={"add"} item container xs={4} classes={{ root: classes.MoodItemWrapper }} onClick={(e) => setIsAdd(true)}> 
                          <Avatar classes={{ root: classes.moodAvatar }} aria-label="Add" >
                            <AddRoundedIcon color={"primary"} classes={{ root: classes.iconCloud }} />
                          </Avatar>
                          <Typography variant={"body1"}  classes={{ root: classes.bodyDefaultStyle2 }}>
                            {"Add"}
                          </Typography>
                        </Grid>
                      :
                        null
                  }

                </Grid>              
                {
                  requestError.error
                  ?
                    <Grid item xs={12} classes={{ root: classes.ErrorTextContainerStyle }}>
                      <Typography variant="body1" classes={{ root: classes.ErrorTextStyle }}>
                        { requestError.message }
                      </Typography>               
                    </Grid>
                  :
                    null
                }                
              </Grid>
              {
                registerFlow
                ?
                  <Grid container item direction="row" justify="center" alignItems="flex-end" classes={{ root: classes.formGridButtonSubmit }}>
                    <SubmitButton isActive={isActive} />
                  </Grid>
                :
                  <Grid container item direction="row" justify="center" alignItems="flex-end" classes={{ root: classes.formGridButtonSubmit }}>
                    {
                      isEdit 
                      ?
                        <>
                          <CloseButton isActive={true} handleCloseClick={handleCloseClick} />
                          {
                            isActive
                            ?
                              <SubmitButton isActive={isActive} />
                            :
                              null
                          }                        
                        </>  
                      :
                        null
                    }
                    {
                      isAdd
                      ?
                        <>
                          <CloseButton isActive={true} handleCloseClick={handleCloseClick} />
                          <SubmitButton isActive={isActive} />
                        </>  
                      :
                        null
                    }                    
                  </Grid>
              }
            </Grid>
          </CloudinaryContext>
        </form>       
      </Container>
    </>
  );
}
