import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { blackLink, whiteLink } from '../styles/defaultLinkStyles.2';

const useStyles = makeStyles((theme) => ({
  blackLink: blackLink,
  whiteLink: whiteLink,
  underline: {
    textDecoration: 'underline',
    fontSize: '12px'
  },
  noUnderline: {
    textDecoration: 'none',
    fontSize: '12px'
  },
  default: {
    display: 'inline-block',
    width: '100%'
  }
}));

export default function MenuLinkText(props) {
  const { url="/", children="", directionBool=true, classLinkName="blackLink", underline=false} = props;
  const classes = useStyles();
  return (
    <Link to={url} className={classes.default + ' ' + (classLinkName === "whiteLink" ? classes.whiteLink : classes.blackLink) + ' ' + (underline ? classes.underline : classes.noUnderline)}>
      {children}
    </Link>
  );
}
