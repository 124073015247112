import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import SearchIconBigPurple from './svg/SearchIconBigPurple';
import { nullWrapper, nullHeader, nullSubHeader, nullLinkText } from '../styles/defaulTabNullCase';

const useStyles = makeStyles((theme) => ({
  nullWrapper: nullWrapper,
  nullHeader: nullHeader, 
  nullSubHeader: nullSubHeader, 
  nullLinkText: nullLinkText
}));

export default function SwipeNullCase(props) {
  const { messageNo=0, isOwner=false } = props;
  const classes = useStyles(); 
  return (
    <Grid item xs={12} key={"video-grid-null"}>
      <div className={ classes.nullWrapper }>
        <SearchIconBigPurple />
        <Typography variant={"h2"} align="center" classes={{ root: classes.nullHeader }}>
          {
            messageNo === 1 && isOwner
            ?
              "Oops, Not Following Anyone?"
            :
              messageNo === 2 && isOwner
              ?
                "Oops, Not Sunbscribe to Anyone?"
              :
                messageNo === 3 && isOwner
                ?
                  "Oops, No followers."
                :
                  "Sorry, we couldn't find any results"
          }
        </Typography>
        {
          (messageNo === 1 || messageNo === 2) && isOwner
          ?
            <>
              <Typography variant={"body1"} align="center" classes={{ root: classes.nullSubHeader }}>
                Your Favorite Queens are on Gloss!
              </Typography>
              <Typography variant={"body1"} align="center" classes={{ root: classes.nullLinkText }}>
                Start your Search
              </Typography>
            </>  
          :
            null
        }              
      </div>
    </Grid>
  );
}
