import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Container, Typography } from "@material-ui/core";
import Button from '@material-ui/core/Button';

import { inviteBtn } from '../styles/defaultButtonStyles.2';

import { isPWA } from "../utils/CommonUtil";

import { FramerContext } from '../contexts/FramerContext';

import FramerMotionContainer from '../components/FramerMotionContainer';
import LinkButton from '../components/LinkButton';

const useStyles = makeStyles((theme) => ({
  inviteBtn: inviteBtn,
  subHeader: {
    fontSize: "16px",
    marginBottom: "10px"
  },
  purpleHeader: {
    fontSize: "22px",
    fontWeight: "600",
    color: "rgba(98,74,251,1)",
    marginBottom: "10px"
  },
  pinkLine: {
    color: "#FB4EB3",
    borderBottom: "1px solid #FB4EB3",
    display: "block",
    width: "100%",
    textAlign: "center",
    overflow: "visible",
    height: "0",
    margin: "40px auto 0px"
  },
  pinkText: {
    display: "block",
    position: "relative",
    top: "-8px",
    backgroundColor: "#000000",
    margin: "auto",
    width: "68%",
    fontSize: "14px"
  },
  whiteHeader: {
    fontSize: "14px",
    margin: "0 auto",
  },
  imageStyle: {
    width: "80%",
    margin: "auto",
    display: "block",
    position: "relative",
    top: "15px"
  },
  wrapperGradient: {
    padding: "40px 20px 50px",
    background: "transparent",
    borderRadius: "10px",
    textAlign: "center"
  },
  listWrapper: {
    textAlign: "left",
    listStyle: "disc",
    color: "#FB4EB3",
    margin: "20px auto",
    padding: "10px 20px 0px",
    "&li:before": {
      color: "red",
      fontWeight: "bold",
      display: "inline-block",
      width: "1em",
      marginLeft: "-1em"
    }
  },
  listWrapperSpan : {
    fontSize: "14px",
    color: "rgba(255,255,255,1)",
    marginBottom: "10px",
    display: "block",
    lineHeight: "20px"
  },


  pageContainer: {
    padding: "0",
    backgroundColor: "rgba(0,0,0,1)"
  },
  gridMainWrapper: {
    margin: "80px 0 80px",
    "&.pwa": {
      margin: "80px 0 80px",
    }
  },
  gridItemWrapper: {
    color: "rgba(255,255,255,1)",
    padding: "0px"
  }
}));

export default function InvitePage(props) {
  const classes = useStyles();  
  let history = useHistory();
  const { toggleDirection } = useContext(FramerContext);

  useEffect(() => {
      // let timer1 = setTimeout(() => dragToFeeds(), 2000);
      // return () => {
      //   clearTimeout(timer1);
      // };
  }, []);

  const dragToFeeds = () => {
     toggleDirection(true,true);
    history.push('/videos');
  }

  return (
    <FramerMotionContainer>
      <Container className={classes.pageContainer}>
        <Grid
            container
            justify="center"
            alignItems="center"
            direction="column"
            spacing={0}
            classes={{ root: classes.gridMainWrapper }}
            className={ isPWA() === true ? " pwa" : ""}
        >           
            <Grid container direction="column" alignItems="center" justify="center" classes={{ root: classes.gridItemWrapper }}>
              <LinkButton url={"/videos"} directionBool={true}>
                <img src="//res.cloudinary.com/dtf2021/image/upload/v1646858851/dtf/logo_bk6iwd.png" alt={"GLOSS"} className={ classes.imageStyle } />        
              </LinkButton>
            </Grid>
            <Grid container direction="column" alignItems="center" justify="center"  classes={{ root: classes.gridItemWrapper }}>              
              <div className={ classes.wrapperGradient }>
                <div className={ classes.subHeader }>YOUR SPECIAL INVITE TO THE</div>
                <div className={ classes.purpleHeader }>GLOSS DRAG APP PROTOTYPE</div>
                <div className={ classes.whiteHeader }>Get a Sneak Peak of our Emerging New App!</div>

                <div className={ classes.pinkLine }>
                  <span className={ classes.pinkText }>UPCOMING FEATURES INCLUDE</span>
                </div>
                
                <ul className={ classes.listWrapper }>
                  <li><span className={ classes.listWrapperSpan }>Access to Exclusive Photos &amp; Video from the Industry's Greatest Drag Performers &amp; Drag Houses</span></li>
                  <li><span className={ classes.listWrapperSpan }>Live Streaming Performances</span></li>
                  <li><span className={ classes.listWrapperSpan }>1 on 1 Video Chats with your Favorite Queens</span></li>
                </ul>
                <LinkButton url={"/videos"} directionBool={true}>
                  <Button variant="outlined" color="primary" classes={{ root: classes.inviteBtn }}>
                    OPEN GLOSS DRAG APP
                  </Button>
                </LinkButton>
              </div>
          </Grid>           
        </Grid>
      </Container>
    </FramerMotionContainer>    
  );
}