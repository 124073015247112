export const dashboardBtn = {
  padding: "5px",
  width: "100%",
  height: "45px",
  margin: "0",
  display: "inline-block",
  fontSize: "14px",
  textTransform: "none",
  color: "rgba(255,255,255,1)",
  border: "0px solid rgba(255,255,255,1)",
  borderRadius: "5px",
  backgroundImage: "linear-gradient(to right, rgba(251,78,179,1), rgba(98,74,251,1))",
  '&:hover, &:focus, &:active': {
    color: "rgba(255,255,255,1)",
    backgroundColor : "rgba(98,74,251,1)",
    boxShadow: "none",
    border: "none"
  }
}

export const dashboardBtnLabel = {
  fontSize: "16px",
  fontWeight: "600",
  lineHeight: "16px"
}

export const dashboardBtnLabel2 = {
  fontSize: "16px",
  fontWeight: "600",
  lineHeight: "16px"
}

export const inviteBtn = {
  padding: "5px",
  width: "100%",
  height: "60px",
  margin: "0",
  display: "inline-block",
  fontSize: "16px",
  textTransform: "none",
  color: "rgba(255,255,255,1)",
  border: "0px solid rgba(255,255,255,1)",
  borderRadius: "5px",
  backgroundImage: "linear-gradient(to right, rgba(251,78,179,1), rgba(98,74,251,1))",
  '&:hover, &:focus, &:active': {
    color: "rgba(255,255,255,1)",
    backgroundColor : "rgba(98,74,251,1)",
    boxShadow: "none",
    border: "none"
  }
}

export const defaultGradientPurpleBtn = {
  width: "100%",
  height: "45px",
  margin: "5px 0",
  display: "inline-block",
  fontSize: "14px",
  textTransform: "none",
  color: "rgba(255,255,255,1)",
  border: "0px solid rgba(255,255,255,1)",
  borderRadius: "5px",
  backgroundImage: "linear-gradient(to right, rgba(251,78,179,1), rgba(98,74,251,1))",
  '&:hover, &:focus, &:active': {
    color: "rgba(255,255,255,1)",
    backgroundColor : "rgba(98,74,251,1)",
    boxShadow: "none",
    border: "none"
  }
};

export const defaultTransparentBtn = {
  fontSize: "14px",
  textTransform: "capitalize",
  width: "100%",
  height: "45px",
  borderWidth: "0px",
  borderStyle: "solid",
  color: "rgba(98,74,251,1)",
  backgroundColor : "transparent",
  borderColor: "transparent",
  boxShadow: "none",
  margin: "10px auto",
  borderRadius: "10px",
  '&:hover, &:focus, &:active': {
    color: "rgba(98,74,251,1)",
    backgroundColor : "transparent",
    borderColor: "transparent",
    boxShadow: "none",
    border: "none"
  }
};

export const defaultBtn = {
  fontSize: "12px",
  textTransform: "capitalize",
  width: "90px",
  height: "29px",
  borderWidth: "1px",
  borderStyle: "solid",
  boxShadow: "none",
  color: "rgba(98,74,251,1)",
  backgroundColor: "rgba(255,255,255,1)",
  borderColor: "rgba(98,74,251,1)",
  '&:hover, &:focus, &:active': {
    color: "rgba(98,74,251,1)",
    backgroundColor: "rgba(255,255,255,1)",
    borderColor: "rgba(98,74,251,1)",
    boxShadow: "none",
  }
}

export const defaultActiveBtn = {
  fontSize: "12px",
  textTransform: "capitalize",
  width: "90px",
  height: "31px",
  boxShadow: "none",
  color: "rgba(255,255,255,1)",
  backgroundImage: "linear-gradient(to right, rgba(251,78,179,1), rgba(98,74,251,1))", 
  boxShadow: "none",
  border: "none",
  '&:hover, &:focus, &:active': {
    color: "rgba(255,255,255,1)",
    backgroundImage: "linear-gradient(to right, rgba(251,78,179,1), rgba(98,74,251,1))",  
    boxShadow: "none",
    border: "none"
  }
};

export const defaultLabelBtn = {
  fontWeight: "400"
}

export const defaultFormButton = {
  position: "relative", 
  padding: "20px", 
  display: "block",
  color: "rgba(98,74,251,1)",
  border: "none",
  background: "transparent",
  outline: "none",
  cursor: "pointer"
}

export const defaultFormIconButton = {    
  backgroundColor: "rgba(0, 0, 0, 0.2)",
  color: "rgba(255, 255, 255, 1)",
  borderRadius: "25px",
  padding: "10px",
  height: "50px",
  width: "50px",
  cursor: "not-allowed",
  "&.active" : {    
    backgroundColor: "rgba(98,74,251,1)",
    color: "rgba(255, 255, 255, 1)",
    borderRadius: "25px",
    padding: "10px",
    height: "50px",
    width: "50px",
    cursor: "pointer",
  }
}

export const defaultFormIconButtonGreen = {    
  backgroundColor: "rgba(0, 0, 0, 0.2)",
  color: "rgba(255, 255, 255, 1)",
  borderRadius: "25px",
  padding: "10px",
  height: "50px",
  width: "50px",
  cursor: "not-allowed",
  "&.active" : {    
    backgroundColor: "rgba(67,209,111,1)",
    color: "rgba(255, 255, 255, 1)",
    borderRadius: "25px",
    padding: "10px",
    height: "50px",
    width: "50px",
    cursor: "pointer",
  }
}

export const linkBtn = {
  width: "160px",
  height: "45px",
  margin: "20px auto",
  display: "block",
  fontSize: "14px",
  textTransform: "none",
  color: "rgba(255,255,255,1)"
}

export const continueBtn = {
  height: "auto",
  width: "auto",
  color: "rgba(255,255,255,1)",
  backgroundColor: "rgba(98,74,251,1)",
  border: "none",
  margin: "20px auto",
  display: "block",
  fontSize: "14px",
  textTransform: "none",
  '&:hover, &:focus, &:active': {
    color: "rgba(98,74,251,1)",
    backgroundColor: "rgba(255,255,255,1)",
  }
}

export const backBtn = {
  position: "relative", 
  padding: "0", 
  display: "block",
  color: "rgba(19,24,34,1)", 
  minWidth: "unset",
  margin: "auto",
  outline: "none",
  backgroundColor: "rgba(0,0,0,0)",
  "& * svg": {
    margin: "auto",
    display: "block"
  }
};

export const backBtnWhiteText = {
  position: "relative", 
  padding: "0", 
  display: "block",
  color: "rgba(255,255,255,1)", 
  minWidth: "unset",
  margin: "auto",
  outline: "none",
  backgroundColor: "rgba(0,0,0,0)"
};

export const backTransparentBtn = {
  position: "relative", 
  padding: "0", 
  display: "block",
  color: "rgba(255,255,255,1)", 
  minWidth: "unset",
  margin: "auto",
  outline: "none",
  backgroundColor: "transparent"
};

export const defaultVideoButton = {
  position: "relative", 
  padding: "0px", 
  margin: "auto",
  display: "block",
  color: "rgba(150, 148, 152, 1)",
  border: "none",
  background: "transparent",
  outline: "none",
  cursor: "pointer"
}

export const closeVideoButton = {
  position: "relative", 
  padding: "0px", 
  margin: "auto",
  display: "block",
  color: "rgba(150, 148, 152, 1)",
  border: "none",
  background: "transparent",
  outline: "none",
  cursor: "pointer",  
  width: "inherit",
  height: "inherit",
  "& > svg": {
    width: "inherit",
    height: "inherit",
  }
}

export const defaultVideoStartRecordIconButton = {    
  backgroundColor: "rgba(98, 74, 251, 1)",
  color: "rgba(255, 255, 255, 1)",
  borderRadius: "40px",
  padding: "10px",
  height: "80px",
  width: "80px",
  border: "15px solid rgba(98, 74, 251, 1)",
  cursor: "not-allowed",
  "&.active" : {    
    backgroundColor: "rgba(98, 74, 251, 1)",
    color: "rgba(255, 255, 255, 1)",
    borderRadius: "40px",
    padding: "10px",
    height: "80px",
    width: "80px",
    border: "15px solid rgba(98, 74, 251, 1)",
    cursor: "pointer",
  }
}

export const defaultVideoStopRecordIconButton = {    
  backgroundColor: "transparent",
  color: "rgba(255, 255, 255, 1)",
  borderRadius: "40px",
  padding: "10px",
  height: "80px",
  width: "80px",
  border: "15px solid rgba(98, 74, 251, 0.5)",
  cursor: "not-allowed",
  "&.active" : {    
    backgroundColor: "transparent",
    color: "rgba(255, 255, 255, 1)",
    borderRadius: "40px",
    padding: "10px",
    height: "80px",
    width: "80px",
    border: "15px solid rgba(98, 74, 251, 0.5)",
    cursor: "pointer",
  }
}

export const defaultVideoPlayIconButton = {    
  backgroundColor: "rgba(255, 255, 255, 0.2)",
  color: "rgba(255, 255, 255, 1)",
  borderRadius: "25px",
  padding: "10px",
  height: "50px",
  width: "50px",
  cursor: "not-allowed",
  "&.active" : {    
    backgroundColor: "rgba(255, 255, 255, 1)",
    color: "rgba(98, 74, 251,1)",
    borderRadius: "25px",
    padding: "10px",
    height: "50px",
    width: "50px",
    cursor: "pointer",
  }
}

export const defaultVideoIconButton = {    
  backgroundColor: "transparent",
  color: "rgba(255, 255, 255, 1)",
  borderRadius: "25px",
  padding: "10px",
  height: "50px",
  width: "50px",
  cursor: "not-allowed",
  "&.active" : {    
    backgroundColor: "transparent",
    color: "rgba(255, 255, 255, 1)",
    borderRadius: "25px",
    padding: "10px",
    height: "50px",
    width: "50px",
    cursor: "pointer",
  }
}

export const defaultVideoStopIconButton = {    
  backgroundColor: "rgba(255, 255, 255, 0.2)",
  color: "rgba(98, 74, 251, 1)",
  borderRadius: "25px",
  padding: "10px",
  height: "50px",
  width: "50px",
  cursor: "not-allowed",
  "&.active" : {    
    backgroundColor: "rgba(255, 255, 255, 1)",
    color: "rgba(98, 74, 251, 1)",
    borderRadius: "25px",
    padding: "10px",
    height: "50px",
    width: "50px",
    cursor: "pointer",
  }
}