import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import LinkText from './LinkText';
import ImageVideoItem from './ImageVideoItem.2';

const useStyles = makeStyles((theme) => ({
  itemWrapper: {
    position: "relative",
    display: 'inline-block',
    width: "100px",
    height: "145px",
    margin: "5px",
    overflow: "hidden"
  }
}));

export default function PaperItem(props) {
  const classes = useStyles();
  const { item=null, elevation=0, url=null } = props;
  const { video_information=null, video_name="", _id=null } = item;

  if (video_information) {
    return (
      <Paper classes={{root: classes.itemWrapper}} elevation={elevation}>
        <LinkText url={url+"/"+_id} underline={false}>
          <ImageVideoItem image={video_information.public_id} alt={video_name} imageInfo={video_information} />
        </LinkText>
      </Paper>                      
    )
  } else {
    return null
  }
}