import React, {useContext, useState, useRef, useEffect} from 'react';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { feedContainerStyle, feedContainerStyle2 } from '../styles/defaultFeedStyles';
import FeedSource from "./FeedSource.6";
import FeedTopBar from "./FeedTopBar.3";
import FeedSideBar from "./FeedSideBar.3";
import FeedDescription from './FeedDescription.3';

const useStyles = makeStyles((theme) => ({
  feedContainerStyle: feedContainerStyle,
  feedContainerStyle2: feedContainerStyle2
}));

function FeedPeopleItem(props) {
  const classes = useStyles();
  const { toggleLike=false, setToggleLike=null, setIsCover=null, setCurrentSrc=null, itemRef=null, isMuted=true, item, parentRef, firstItem, index=0, indexUpdateCommentCount=[], hideBackButton=false, noBottomBar=false, userClick=false, videoElem=null } = props; 
  const { video, user, user_information={} } = item;
  const [ isCoverElement, setIsCoverElement ] = useState(false);

  useEffect(() => {
    try {
      if (video.video_information.height > video.video_information.width) {
        setIsCover(true);
        setIsCoverElement(true);
      } else {
        setIsCover(false);
        setIsCoverElement(false);
      }
    } catch (err) {
      console.log(err)
    }    
  }, [])

  return (
    <Container classes={{ root: classes.feedContainerStyle2 }}>
      <FeedSource userInformation={user_information} isCoverElement={isCoverElement} setCurrentSrc={setCurrentSrc} video={video} firstItem={firstItem} parentRef={parentRef} index={index} isMuted={isMuted} userClick={userClick} itemRef={itemRef} />
      <FeedTopBar hideBackButton={hideBackButton} />
      <FeedSideBar userInformation={user_information} toggleLike={toggleLike} setToggleLike={setToggleLike} noBottomBar={noBottomBar} indexUpdateCommentCount={indexUpdateCommentCount} item={video} index={index} user={user} isMuted={isMuted} itemRef={itemRef} />
      <FeedDescription noBottomBar={noBottomBar} item={video} index={index} userInformation={user_information} user={user} />
    </Container> 
  );
}

export default React.memo(FeedPeopleItem);