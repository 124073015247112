//https://reactjs.org/docs/hooks-reference.html#usereducer
import React, { createContext, useReducer, useEffect } from "react";
import { GlobalContextReducer } from '../reducers/GlobalContextReducer';
import { isPWA, checkInstallerIOS, isMobile } from "../utils/CommonUtil";

export const GlobalContext = createContext();

const initialState = {
    showBackdrop: false,
    showCircleSpinner: false, //true default
    isLoading: true,
    initialLoad: true,
    animationComplete: false,
    iOSInstaller: false,
    isPwaMobile: false,
    isPwaDesktop: false,
    isMobile: false,
    isNewActivites: false,
    newCount: 0,
    newCountMessages: 0,
    newCountLike: 0,
    newCountFollow: 0,
    newCountSubscriber: 0,
    isActivitiesIntial: true
}

const GlobalContextProvider = (props) => {
    const [GlobalContextState, GlobalContextDispatch] = useReducer(GlobalContextReducer,initialState);

    useEffect(() => {
        
        if (isMobile() === true) {
            GlobalContextDispatch({ type: "SET_MOBILE", payload: { isMobile : true }});
        }
        if (isMobile() === true && isPWA() !== false) {
            GlobalContextDispatch({ type: "SET_PWA_MOBILE", payload: { isPwaMobile : true }});
        }
        if (isMobile() === false && isPWA() !== false) {
            GlobalContextDispatch({ type: "SET_PWA_DESKTOP", payload: { isPwaDesktop : true }});
        }
    }, [])

    return (
        <GlobalContext.Provider value={{GlobalContextState, GlobalContextDispatch}}>
            {props.children}
        </GlobalContext.Provider>
    );
}
export default GlobalContextProvider;