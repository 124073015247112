import React, { createContext, useReducer, useEffect, useContext } from "react";
import { SearchContextReducer } from '../reducers/SearchContextReducer';
import { SearchInitialState } from "../constants/SearchInitialState";
import { GetSearchHashTagVideo, GetSearchHouseTagVideo, GetSearchPeople, GetSearchLocation, GetSearchVideo, GetSearchDiscovery, GetSearchAllVideos, SaveSearchHistory, GetSearchHistory, SaveSearchHistoryLoggedOut} from "../services/SearchServices"
import { AuthContext } from '../contexts/AuthContext';

export const SearchContext = createContext();
const initialState = SearchInitialState;

const SearchContextProvider = (props) => {
    
    const { AuthContextState } = useContext(AuthContext);
    const { isAuthenticated, authToken, userId } = AuthContextState; 
    const [SearchContextState, SearchContextDispatch] = useReducer(SearchContextReducer,initialState);
    const {houseTagVideo="", hashTagVideo="", people="", video="", location="", peopleResult=[], hashTagVideoResult=[], houseTagVideoResult=[], videoResult=[], videoDiscovery=[], videoDiscoveryResult=[], locationResult=[], loadNext=false, isLoading=false, loadNextType="", pageNo=0, peoplePageNo=0, videoSearchPage=0, videoDiscoverySearchCount=0, locationPageNo=0, searchPageCount=0, peopleSearchPageCount=0, videoSearchPageCount=0, videoDiscoverySearchPage=0, locationSearchPageCount=0, searchCount=0, peopleSearchCount=0, videoSearchCount=0, locationSearchCount=0, itemPerPage=10, itemPeoplePerPage=10, itemVideoPerPage=10, itemLocationPerPage=10, itemHashTagVideoPerPage=10, itemHouseTagVideoPerPage=10, hashOnly=false, usernameOnly=false, searchAll=false, searchAllVideosStr="", searchAllVideosType="", searchAllVideos=false, searchHistory=null, searchHistoryResult=[], searchDiscoveryAllVideosStr="", searchDiscoveryAllVideosType="", searchDiscoveryAllVideos=false} = SearchContextState;

    useEffect(() => {
        let searchType = loadNextType;
        
        if (loadNext && searchPageCount > pageNo) {            
            let objData = {};

            if (searchType === null) {
                if (hashTagVideoResult.length !== 0) {
                    searchType="hashTagVideo";
                } else if (houseTagVideoResult.length !== 0) {
                    searchType="hosueTagVideo";    
                } else if (peopleResult.length !== 0) {
                    searchType="people";
                }
            }

            SearchContextDispatch({ type: "RESET_LOADNEXT" });
            
            switch(searchType) {
                case 'people':
                    if (peopleResult.length < searchCount) {
                        objData = { strSearch: people, pageNo: pageNo + 1, itemPerPage: itemPeoplePerPage, usernameOnly: usernameOnly };
                        GetSearchPeople(objData).then((data) => {
                            SearchContextDispatch({ type: "INFINITE_SCROLL_SEARCH_PEOPLE_RESULT", payload: { peopleResult: data.data.search.people, pageNo: objData.pageNo, searchPage: data.data.search.page, searchCount: data.data.search.count, searchPageCount: data.data.search.pageCount, itemPerPage: data.data.search.itemPerPage } });
                            SearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading: false } });
                        })
                    }
                    break;
                case 'hashTagVideo':
                    if (hashTagVideoResult.length < searchCount) {
                        objData = { strSearch: hashTagVideo, pageNo: pageNo + 1, itemPerPage: itemHashTagVideoPerPage, hashOnly: hashOnly };
                        GetSearchHashTagVideo(objData).then((data) => {
                            SearchContextDispatch({ type: "INFINITE_SCROLL_SEARCH_HASH_TAG_VIDEO_RESULT", payload: { hashTagVideoResult: data.data.search.hashTagVideo, pageNo: objData.pageNo, searchPage: data.data.search.page, searchCount: data.data.search.count, searchPageCount: data.data.search.pageCount, itemPerPage: data.data.search.itemPerPage } });
                            SearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading: false } });
                        })
                    }
                    break; 
                case 'houseTagVideo':
                    if (houseTagVideoResult.length < searchCount) {
                        objData = { strSearch: houseTagVideo, pageNo: pageNo + 1, itemPerPage: itemHouseTagVideoPerPage, hashOnly: hashOnly };
                        GetSearchHouseTagVideo(objData).then((data) => {
                            SearchContextDispatch({ type: "INFINITE_SCROLL_SEARCH_HOUSE_TAG_VIDEO_RESULT", payload: { houseTagVideoResult: data.data.search.houseTagVideo, pageNo: objData.pageNo, searchPage: data.data.search.page, searchCount: data.data.search.count, searchPageCount: data.data.search.pageCount, itemPerPage: data.data.search.itemPerPage } });
                            SearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading: false } });
                        })
                    }
                    break;       
                case 'searchAllVideos':
                    if (videoResult.length < videoSearchCount) {
                        //revisit parseInt videoSearchPage
                        const objData = { strSearch: searchAllVideosStr, type: searchAllVideosType, pageNo: parseInt(videoSearchPage) + 1, itemPerPage: itemPerPage, searchAllVideos: true }
                        GetSearchAllVideos(objData).then((data) => {
                            SearchContextDispatch({ type: "INFINITE_SCROLL_SET_SEARCH_ALL_VIDEOS_RESULT", payload: { 
                                    videoDiscovery: data.data.search.video,     
                                    videoSearchCount: data.data.search.videoCount,
                                    videoSearchPage: data.data.search.videoPage,       
                                    videoSearchPageCount: data.data.search.videoPageCount,
                                    itemVideoPerPage: data.data.search.videoItemPerPage,
                                    itemPerPage: data.data.search.videoItemPerPage,
                                } 
                            });
                            SearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading: false } });
                        })
                    }
                    break; 
                case 'searchDiscoveryAllVideos':
                    if (videoDiscoveryResult.length < videoDiscoverySearchCount) {
                        //revisit parseInt videoSearchPage
                        const objData = { strSearch: searchAllVideosStr, type: searchAllVideosType, pageNo: parseInt(videoDiscoverySearchPage) + 1, itemPerPage: itemPerPage, searchAllVideos: true }
                        GetSearchAllVideos(objData).then((data) => {
                            SearchContextDispatch({ type: "INFINITE_SCROLL_SET_SEARCH_DISCOVERY_ALL_VIDEOS_RESULT", payload: { 
                                    videoDiscoveryResult: data.data.search.video,     
                                    videoDiscoverySearchCount: data.data.search.videoCount,
                                    videoDiscoverySearchPage: data.data.search.videoPage,       
                                    videoDiscoverySearchPageCount: data.data.search.videoPageCount,
                                    itemVideoPerPage: data.data.search.videoItemPerPage,
                                    itemPerPage: data.data.search.videoItemPerPage,
                                } 
                            });
                            SearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading: false } });
                        })
                    }
                    break;             
                // case 'video':
                //     if (videoResult.length < searchCount) {
                //         objData = { strSearch: video, pageNo: pageNo + 1, itemPerPage: itemVideoPerPage, hashOnly: hashOnly };
                //         GetSearchVideo(objData).then((data) => {
                //             console.log('loadNext GetSearchVideo',data.data.search.video);
                //             SearchContextDispatch({ type: "INFINITE_SCROLL_SEARCH_VIDEO_RESULT", payload: { videoResult: data.data.search.video, pageNo: objData.pageNo, searchPage: data.data.search.page, searchCount: data.data.search.count, searchPageCount: data.data.search.pageCount, itemPerPage: data.data.search.itemPerPage } });
                //             SearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading: false } }); 
                //         })
                //     }
                //     break;    
                // case 'location':
                //     if (locationResult.length < searchCount) {
                //         objData = { strSearch: location, pageNo: pageNo + 1, itemPerPage: itemLocationPerPage };
                //         GetSearchLocation(objData).then((data) => {
                //             console.log('loadNext GetSearchLocation',data);
                //             SearchContextDispatch({ type: "INFINITE_SCROLL_SEARCH_LOCATION_RESULT", payload: { locationResult: data.data.search.location, pageNo: objData.pageNo, searchPage: data.data.search.page, searchCount: data.data.search.count, searchPageCount: data.data.search.pageCount, itemPerPage: data.data.search.itemPerPage } });
                //             SearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading: false } });
                //         })
                //     }
                //     break;
                default:    
                    // SearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading: false } });
            }
        }
        
    }, [loadNext])

    useEffect(() => {
        if (people !== "" && !searchAll && !searchAllVideos && !searchDiscoveryAllVideos) {
            const objData = { strSearch: people, pageNo: 0, itemPerPage: itemPeoplePerPage, usernameOnly: usernameOnly }
            SearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading: true } });
            GetSearchPeople(objData).then((data) => {
                SearchContextDispatch({ type: "SET_SEARCH_PEOPLE_RESULT", payload: { peopleResult: data.data.search.people, pageNo: objData.pageNo, searchPage: data.data.search.page, searchCount: data.data.search.count, searchPageCount: data.data.search.pageCount, itemPerPage: data.data.search.itemPerPage } });
                SearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading: false } });
            })
        }
    }, [people])
    
    useEffect(() => {
        if (location !== "" && !searchAll && !searchAllVideos && !searchDiscoveryAllVideos) {
            const objData = { strSearch: location, pageNo: 0, itemPerPage: itemLocationPerPage };
            SearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading: true } });
            GetSearchLocation(objData).then((data) => {
                SearchContextDispatch({ type: "SET_SEARCH_LOCATION_RESULT", payload: { locationResult: data.data.search.location, pageNo: objData.pageNo, searchPage: data.data.search.page, searchCount: data.data.search.count, searchPageCount: data.data.search.pageCount, itemPerPage: data.data.search.itemPerPage } });
                SearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading: false } });
            })
        }
    }, [location])

    useEffect(() => {
        if (video !== "" && !searchAll && !searchAllVideos && !searchDiscoveryAllVideos) {
            const objData = { strSearch: video, pageNo: 0, itemPerPage: itemVideoPerPage, hashOnly: hashOnly }
            SearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading: true } });
            GetSearchVideo(objData).then((data) => {
                SearchContextDispatch({ type: "SET_SEARCH_VIDEO_RESULT", payload: { videoResult: data.data.search.video, pageNo: objData.pageNo, searchPage: data.data.search.page, searchCount: data.data.search.count, searchPageCount: data.data.search.pageCount, itemPerPage: data.data.search.itemPerPage } });
                SearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading: false } });
            })
        }
    }, [video])

    useEffect(() => {
        if (houseTagVideo !== "" && !searchAll && !searchAllVideos && !searchDiscoveryAllVideos) {
            const objData = { strSearch: houseTagVideo, pageNo: 0, itemPerPage: itemHouseTagVideoPerPage, hashOnly: hashOnly }
            SearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading: true } });
            GetSearchHouseTagVideo(objData).then((data) => {
                SearchContextDispatch({ type: "SET_SEARCH_HOUSE_TAG_VIDEO_RESULT", payload: { houseTagVideoResult: data.data.search.houseTagVideo, pageNo: objData.pageNo, searchPage: data.data.search.page, searchCount: data.data.search.count, searchPageCount: data.data.search.pageCount, itemPerPage: data.data.search.itemPerPage } });
                SearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading: false } });
            })
        }
    }, [houseTagVideo]) 

    useEffect(() => {
        if (hashTagVideo !== "" && !searchAll && !searchAllVideos && !searchDiscoveryAllVideos) {
            const objData = { strSearch: hashTagVideo, pageNo: 0, itemPerPage: itemHashTagVideoPerPage, hashOnly: hashOnly }
            SearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading: true } });
            GetSearchHashTagVideo(objData).then((data) => {
                SearchContextDispatch({ type: "SET_SEARCH_HASH_TAG_VIDEO_RESULT", payload: { hashTagVideoResult: data.data.search.hashTagVideo, pageNo: objData.pageNo, searchPage: data.data.search.page, searchCount: data.data.search.count, searchPageCount: data.data.search.pageCount, itemPerPage: data.data.search.itemPerPage } });
                SearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading: false } });
            })
        }
    }, [hashTagVideo])    

    useEffect(() => {
        if (people !== "" && houseTagVideo !== "" && hashTagVideo !== "" && video !== "" && location !== "" && searchAll && !searchAllVideos && !searchDiscoveryAllVideos) {
            const objData = { strSearch: video, pageNo: 0, itemPerPage: itemVideoPerPage, hashOnly: false, usernameOnly: false, searchAll: true }
            SearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading: true } });
            GetSearchDiscovery(objData).then((data) => {
                SearchContextDispatch({ type: "SET_SEARCH_ALL_RESULT", payload: { 
                        houseTagVideoResult: data.data.search.houseTagVideo,
                        houseTagVideoSearchCount: data.data.search.houseTagVideoSearchCount,
                        houseTagVideoSearchPage: data.data.search.houseTagVideoSearchPage,
                        houseTagVideoSearchPageCount: data.data.search.houseTagVideoSearchPageCount,
                        houseTagVideoPageNo: data.data.search.houseTagVideoPageNo,
                        itemHouseTagVideoPerPage: data.data.search.houseTagVideoItemPerPage,
                        peopleResult: data.data.search.people,
                        peopleSearchCount: data.data.search.peopleSearchCount,
                        peopleSearchPage: data.data.search.peopleSearchPage,
                        peopleSearchPageCount: data.data.search.peopleSearchPageCount,
                        peoplePageNo: data.data.search.peoplePageNo,
                        itemPeoplePerPage: data.data.search.peopleItemPerPage,
                        hashTagVideoResult: data.data.search.hashTagVideo,
                        hashTagVideoSearchCount: data.data.search.hashTagVideoSearchCount,
                        hashTagVideoSearchPage: data.data.search.hashTagVideoSearchPage,
                        hashTagVideoSearchPageCount: data.data.search.hashTagVideoSearchPageCount,
                        hashTagVideoPageNo: data.data.search.hashTagVideoPageNo,
                        itemHashTagVideoPerPage: data.data.search.hashTagVideoItemPerPage,
                        videoResult: data.data.search.video,
                        videoSearchCount: data.data.search.videoSearchCount,
                        videoSearchPage: data.data.search.videoSearchPage,
                        videoSearchPageCount: data.data.search.videoSearchPageCount,
                        itemVideoPerPage: data.data.search.videoItemPerPage,
                        locationResult: data.data.search.location, 
                        locationSearchCount: data.data.search.locationSearchCount,
                        locationSearchPage: data.data.search.locationSearchPage,
                        locationSearchPageCount: data.data.search.locationSearchPageCount,
                        locationPageNo: data.data.search.locationPageNo,
                        itemLocationPerPage: data.data.search.locationItemPerPage,
                        pageNo: objData.pageNo, 
                        searchPage: data.data.search.locationPage, 
                        searchCount: data.data.search.locationCount, 
                        searchPageCount: data.data.search.locationPageCount, 
                        itemPerPage: data.data.search.itemPerPage 
                    } 
                });
                // SearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading: false } });
            })
        }
    }, [people, video, location, hashTagVideo, houseTagVideo])

    useEffect(() => {
       if (searchAllVideosStr !== "" && searchAllVideos && !searchDiscoveryAllVideos && !searchAll && searchAllVideosType !== "") {
            const objData = { strSearch: searchAllVideosStr, type: searchAllVideosType, pageNo: 0, itemPerPage: itemPerPage, searchAllVideos: true }
            SearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading: true } });
            GetSearchAllVideos(objData).then((data) => {
                SearchContextDispatch({ type: "SET_SEARCH_ALL_VIDEOS_RESULT", payload: { 
                        videoDiscovery: data.data.search.video,     
                        videoSearchCount: data.data.search.videoCount,
                        videoSearchPage: data.data.search.videoPage,       
                        videoSearchPageCount: data.data.search.videoPageCount,
                        itemVideoPerPage: data.data.search.videoItemPerPage,
                        itemPerPage: data.data.search.videoItemPerPage,
                    } 
                });
                SearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading: false } });
            })
        }
    }, [searchAllVideosStr, searchAllVideos, searchAllVideosType])

    useEffect(() => {
        if (searchDiscoveryAllVideosStr !== "" && searchDiscoveryAllVideos && !searchAllVideos && !searchAll) {
            const objData = { strSearch: searchDiscoveryAllVideosStr, type: searchDiscoveryAllVideosType, pageNo: 0, itemPerPage: itemPerPage, searchAllVideos: true }
            
            SearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading: true } });
            GetSearchAllVideos(objData).then((data) => {
                SearchContextDispatch({ type: "SET_SEARCH_DISCOVERY_ALL_VIDEOS_RESULTS", payload: { 
                        videoDiscoveryResult: data.data.search.video,     
                        videoDiscoverySearchCount: data.data.search.videoCount,
                        videoDiscoverySearchPage: data.data.search.videoPage,       
                        videoDiscoverySearchPageCount: data.data.search.videoPageCount,
                        itemVideoPerPage: data.data.search.videoItemPerPage,
                        itemPerPage: data.data.search.videoItemPerPage,
                    } 
                });
                SearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading: false } });
            })
        }
    }, [searchDiscoveryAllVideosStr, searchDiscoveryAllVideos])

    useEffect(() => {
        if (searchHistory !== null ) {
            const objData = searchHistory;
            if (isAuthenticated) {                
                SaveSearchHistory(objData, authToken).then((data) => {
                })
            } else {
                SaveSearchHistoryLoggedOut(objData).then((data) => {
                })
            }            
        }
    }, [searchHistory])  

    return (
        <SearchContext.Provider value={{SearchContextState, SearchContextDispatch}}>
            {props.children}
        </SearchContext.Provider>
    );
}

export default SearchContextProvider;