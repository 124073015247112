import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom"; //https://dev.to/projectescape/programmatic-navigation-in-react-3p1l

import { Container, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';

import { containerStyleWhite2, formGridStyle, formGridButtonSubmit, headerDefaultStyle } from '../../styles/defaultStyles.2';
import { ErrorTextStyle, ErrorTextContainerStyle } from '../../styles/defaultErrorStyles';

import SubmitButton from '../../components/SubmitButton.2';
import HelmetComponent from "../../components/HelmetComponent";

import { RegistrationContext } from '../../contexts/RegistrationContext';
import { FormErrorContext } from '../../contexts/FormErrorContext';
import { FramerContext } from '../../contexts/FramerContext';
import { GlobalContext } from '../../contexts/GlobalContext';

import { VerifyEmailService } from "../../services/RegistrationServices"

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },  
  terms: {
    color: "rgba(146, 147, 151, 1)",
    fontSize: "0.8rem",
    padding: "10px 0"
  }  
}));

export default function VerifyEmail() {
  const classes = useStyles();  
  let history = useHistory();
  const { tokenExpired, registerType } = useParams();
  const tokenBoolean = ((tokenExpired === 'expired') ? true : false);
  const type = ((registerType === 'creator') ? registerType : 'user');
  const { toggleDirection } = useContext(FramerContext);
  const { FormErrorContextState, FormErrorContextDispatch } = useContext(FormErrorContext);
  const { emailError, requestError } = FormErrorContextState;
  const { RegistraionContextDispatch } = useContext(RegistrationContext);
  const { GlobalContextDispatch } = useContext(GlobalContext);
  const [isActive, setIsActive] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (!isSubmitted) {
      if (email.length > 0) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
      if (emailError.error || requestError.error) {
        FormErrorContextDispatch({ type: "RESET_ERRORS" })
      }
    }    
    if (!emailError.error && email.length !== 0 && isSubmitted) {
      const data = {
        email: email,
        type: type 
      }      
      VerifyEmailService(data).then(result => {
        if (result.status === 200) {
          if (result.data.success === 1) {
            setIsActive(true)      
            RegistraionContextDispatch({ type: "SEND_EMAIL_VERIFICATION_LINK", payload: data})        
            toggleDirection(true,true);
            history.push('/registration/email-sent')
          } else {
            FormErrorContextDispatch({ type: "REQUEST_ERROR", payload: result.data});
            setIsActive(false); 
            setIsSubmitted(false);
          }
        } else {
          setIsActive(false); 
          setIsSubmitted(false);
        }
        GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
      }).catch(err => {
        console.log("catch",err.response.data,err.response.status);
        FormErrorContextDispatch({ type: "REQUEST_ERROR", payload: err.response.data });  
        GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
        // if (err.response.data.success === 0) {
        //   FormErrorContextDispatch({ type: "REQUEST_ERROR", payload: err.response.data});
        // }        
        setIsActive(false);
        setIsSubmitted(false);
      })
    } else {
      if (isSubmitted) {
        GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
        setIsSubmitted(false);
      }
    }   
  }, [email, emailError])

  const handleSubmit = (e) => {
    e.preventDefault();
    GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : true }});
    FormErrorContextDispatch({ type: "VALIDATE_EMAIL", payload: email });
    setIsSubmitted(true);    
  }

  return (
    <>
      <HelmetComponent title={"DTF - Verify Email"} />    
      <Container style={containerStyleWhite2}>
        <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid 
            container
            justify="flex-start"
            alignItems="center"
            direction="column"
            spacing={1}
            style={formGridStyle}
          >
            <Grid item container direction="row">
              <Typography variant={"h1"} style={headerDefaultStyle}>                  
                { 
                  tokenBoolean 
                    ? 
                      'Your verification link has expired, please verify your email again.'
                    : 
                      'Verify Email'
                }
              </Typography>   
            </Grid>
            <Grid item container direction="row" spacing={3} style={{padding: "20px 4px 4px"}}>
              <Grid item xs={12} style={{paddingBottom: "5px"}}>
                  <Input type="email" fullWidth={true} placeholder="xxxxx@email.com" error={emailError.error || requestError.error} inputProps={{ 'aria-label': 'Email' }} value={email} onChange={(e) => setEmail(e.target.value.trim())} />             
              </Grid>
              <Grid item xs={12} style={ErrorTextContainerStyle}>
                <Typography variant="body1" style={ErrorTextStyle}>
                {
                  emailError.error && emailError.message !== ''
                  ?
                    emailError.message 
                  :
                    requestError.error && requestError.message !== ''
                    ?
                      requestError.message
                    :
                      null
                }
                </Typography>            
              </Grid>                          
            </Grid> 
            <Grid item>      
              <Typography variant="body1" className={classes.terms}>
                By signing up, you confirm that you agree to our Terms of Use and have read and understood our Privacy Policy.  You will receive an email to confirm your email.
              </Typography>
            </Grid>
            <Grid container item direction="row" justify="center" alignItems="flex-end" style={formGridButtonSubmit}>
              <SubmitButton isActive={isActive} />
            </Grid>
          </Grid>   
        </form>       
      </Container>
    </>
  );
}