export const UserProfileInitialState = {
    profile_user_id: "",
    user: "",
    username: "",
    handleName: "",
    userType: null,
    isFollow: false,
    isSubscribe: false,
    devices: [],
    user_information_id: "",
    user_description: "",
    user_fname: "",
    user_followers: 0,
    user_following: 0,
    user_house_link: null,
    user_house_name: "" ,   
    user_image_circle_profile: null,
    user_image_profile: null,
    user_link_profile: null,
    user_lname: "",
    user_mname: "",
    user_moods: [{mood: "default", image: "", active: true}],
    user_socials: [
        {facebookUrl: null, facebookUsername: null, facebookPassword: null},
        {twitterUrl: null, twitterUsername: null, twitterPassword: null},
        {instagramUrl: null, instagramUsername: null, instagramPassword: null}
    ],
    user_subscribers: 0,
    user_subscriptions: 0,
    user_suffix: "",
    user_videos: {
        count: 0,
        videos: []
    },
    user_events: {
        count: 0,
        events: []
    },
}
