import React, { useContext, useEffect } from 'react';
import { Redirect } from "react-router-dom";

import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { containerStyleWhite2, formGridStyle, headerDefaultStyle, bodyDefaultStyle } from '../../styles/defaultStyles.2';
import { gridItemHeaderWrapper } from '../../styles/defaultGridStyles';
import { RegistrationContext } from '../../contexts/RegistrationContext';

import HelmetComponent from "../../components/HelmetComponent";
import LinkButton from '../../components/LinkButton';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    }    
  },
  containerStyleWhite2: containerStyleWhite2, 
  formGridStyle: formGridStyle,
  headerDefaultStyle: headerDefaultStyle,
  gridItemHeaderWrapper: gridItemHeaderWrapper,
  bodyDefaultStyle: bodyDefaultStyle
}));

export default function EmailSent() {
  const classes = useStyles();
  const { RegistrationContextState } = useContext(RegistrationContext);
  const { forgotPasswordEmail, preRegisterEmail } = RegistrationContextState;

  return (
    <>
      <HelmetComponent title={"DTF - Email Sent"} />  
      <Container classes={{ root: classes.containerStyleWhite2 }}>        
        <Grid
          container
          direction="column"
          spacing={0}
          classes={{ root: classes.formGridStyle }}
        >
          <Grid item classes={{ root: classes.gridItemHeaderWrapper }}>
            <Typography variant={"h1"} classes={{ root: classes.headerDefaultStyle }}>
              Email Sent Successfully
            </Typography>   
          </Grid>
          <Grid item > 
            <Typography classes={{ root: classes.bodyDefaultStyle }}>
            {
                forgotPasswordEmail !== ""
                ? 
                  <>
                    Please, check your inbox ({preRegisterEmail || forgotPasswordEmail}).
                  </>
                :
                  <>
                    Check your inbox to complete signup. We sent a singup link to you at {preRegisterEmail || forgotPasswordEmail}.
                  </>
            }
            </Typography> 
          </Grid>
          <Grid item> 
            <Typography classes={{ root: classes.bodyDefaultStyle }}>
              {
                forgotPasswordEmail !== ""
                ? 
                  <>
                    Try again? <LinkButton url={"/forgot-password"} directionBool={true}>Get help signing in</LinkButton>.
                  </>
                :
                  ""
              }
            </Typography> 
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
