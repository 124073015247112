export const listAvatarWrapper = {
  minWidth: "45px"
}

export const listAvatar = {
  width: "50px",
  height: "50px"
}

export const tagAvatar = {
  width: "50px",
  height: "50px"
}

export const searchItemAvatar = {
  width: "50px",
  height: "50px"
}

export const commentItemAvatar = {
  width: "50px",
  height: "50px"
}

export const searchItemHashAvatar = {
  width: "50px",
  height: "50px",
  color: "rgba(0,0,0,1)",
  background: "none",
  fontSize: "50px",
  fontWeight: "300"
}

export const feedAvatar = {
  width: "50px",
  height: "50px",
  position: "relative",
  // left: "-9px"
}

export const moodAvatar = {
  width: "100px",
  height: "100px",
  position: "relative",
  backgroundColor: "rgba(253,45,85,0.2)",
  border: "0px solid rgba(253,45,85,0)",
  boxSizing: "border-box",
  margin: "0 auto",
  "&.active": {
    border: " 4px solid rgba(253,45,85,1)"
  }
}

export const profileAvatar = {
  width: "100px",
  height: "100px",
  position: "relative",
  backgroundColor: "rgba(253,45,85,0.2)",
  border: "0px solid rgba(253,45,85,0.0)",
  margin: "0 auto",
  boxSizing: "border-box",
  "&.active": {
    border: " 4px solid rgba(253,45,85,1)"
  }
}