import React, {useContext, useState, useRef, useEffect} from 'react';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { feedContainerStyle, feedContainerStyle2 } from '../styles/defaultFeedStyles';
import FeedSource from "./FeedSource.6";
import FeedTopBar from "./FeedTopBar.3";
import FeedSideBar from "./FeedSideBar.3";
import FeedDescription from './FeedDescription.3';

const useStyles = makeStyles((theme) => ({
  feedContainerStyle: feedContainerStyle,
  feedContainerStyle2: feedContainerStyle2
}));

// let timer = 0;
// let delay = 500;
// let prevent = false;

function FeedItem(props) {
  const classes = useStyles();
  const { toggleLike=false, setToggleLike=null, setIsCover=null, setCurrentPosterSrc=null, setCurrentSrc=null, itemRef=null, isMuted=true, video=null, parentRef, firstItem, index=0, indexUpdateCommentCount=[], hideBackButton=false, noBottomBar=false, userClick=false, videoElem=null } = props; 
  const { user, user_information={}, isLike = false } = video;
  const [ isCoverElement, setIsCoverElement ] = useState(false);

  // const onClickElem = (e) => {
  //   console.log('onClickElem', e.detail);
    
  //   switch(e.detail) {
  //     case 1:
  //       let me = this;
  //       timer = setTimeout(function() {
  //         // if (!prevent) {
  //           // me.doClickAction();            
  //           try {
  //             const vid = itemRef.current;
  //             if (vid !== null) {                 
  //                 vid.muted = !vid.muted;
  //             }       
  //           } catch(err) {
  //               console.log('toggleMute single tap err', err)
  //           }
        
  //           if (isMuted) {            
  //               toggleSound(false);
  //           } else {
  //               toggleSound(true);
  //           }  
  //         // }
  //         // prevent = false;
  //       }, delay);
  //       break;
  //     case 2:        
  //       console.log('like trigger', toggleLike);
  //       clearTimeout(timer);
  //       setToggleLike(true);
  //       // prevent = true; 
  //       break;
  //     default:
  //   }
  // }  

  useEffect(() => {
    try {
      if (video.video_information) {
        if (video.video_information.height > video.video_information.width) {
          setIsCover(true);
          setIsCoverElement(true);
        } else {
          setIsCover(false);
          setIsCoverElement(false);
        }
      }
    } catch (err) {
      console.log(err)
    }    
  }, [])

  return (
    <Container id={"feedItem"+index} className={ classes.feedContainerStyle2 }>
      <FeedSource userInformation={user_information} isCoverElement={isCoverElement} setCurrentPosterSrc={setCurrentPosterSrc} setCurrentSrc={setCurrentSrc} video={video} firstItem={firstItem} parentRef={parentRef} index={index} isMuted={isMuted} userClick={userClick} itemRef={itemRef} />
      <FeedTopBar hideBackButton={hideBackButton} />
      <FeedSideBar userInformation={user_information} toggleLike={toggleLike} setToggleLike={setToggleLike} noBottomBar={noBottomBar} indexUpdateCommentCount={indexUpdateCommentCount} item={video} index={index} user={user} isMuted={isMuted} itemRef={itemRef} />
      <FeedDescription noBottomBar={noBottomBar} item={video} index={index} userInformation={user_information} user={user} />
    </Container> 
  );
}

export default React.memo(FeedItem);