export const defaultGridWrapper = {    
    position: "fixed", 
    top: "0px",
    width: "100vw",
    height: "auto",
    backgroundColor: "rgba(0,0,0,1)",
    borderBottom: "1px solid rgba(192,192,192,1)",
    zIndex: "1000",
    // transition: "all 100ms linear",
    // "-webkit-transition": "all 100ms linear",
    // "-moz-transition": "all 100ms linear",
    // "-o-transition": "all 100ms linear",
    '&.hideTopBar': {
        top: "-100px"
    },
    '&.hideTopBar.pwa': {
        top: "-100px"
    },
    "&.bgTransparent": {
        background: "transparent",
        border: "none"
    },
    "&.borderWhite": {
        borderBottom: "0px solid rgba(255,255,255,1)",
    }
}

export const defaultGrid = { 
    marginTop: "0px", 
    height: "50px",
    transition: "all 100ms linear",
    "-webkit-transition": "all 100ms linear",
    "-moz-transition": "all 100ms linear",
    "-o-transition": "all 100ms linear", 
    "&.whiteBar": {
        backgroundColor: "rgba(255,255,255,1)"        
    },
    "&.blackBar": {
        backgroundColor: "rgba(0,0,0,1)",
    },
    "&.bgTransparent": {
        background: "transparent",
        border: "none"
    }   
}
  
export const defaultGridApp = { 
    marginTop: "40px", 
    height: "50px", 
    "&.whiteBar": {
        backgroundColor: "rgba(255,255,255,1)"
    },
    "&.blackBar": {
        backgroundColor: "rgba(0,0,0,1)",
    },
    "&.bgTransparent": {
        background: "transparent",
        border: "none"
    }  
}

export const backBtnWhite = {
    position: "relative", 
    padding: "0", 
    display: "block",
    color: "rgba(255,255,255,1)", 
    width: "auto",
    outline: "none",
    backgroundColor: "rgba(0,0,0,0)",
    "& * svg": {
        margin: "auto",
        display: "inherit"
    }
};

export const backBtnBlack = {
    position: "relative", 
    padding: "0", 
    display: "block",
    color: "rgba(0,0,0,1)", 
    width: "auto",
    outline: "none",
    backgroundColor: "rgba(255,255,255,0)",
    "& * svg": {
        margin: "auto",
        display: "inherit"
    }
};

export const backBtn = {
    position: "relative", 
    padding: "0", 
    display: "block",
    width: "auto",
    outline: "none",
    backgroundColor: "rgba(255,255,255,0)",
    "& * svg": {
        margin: "auto",
        display: "inherit"
    }
};

export const titleWhite = {
    fontWeight: "600",
    color: "rgb(255,255,255,1)",
    fontSize: "16px",
    // letterSpacing: "0.5px",
    width: "inherit",
    display: "inline-block"
}

export const titleBlack = {
    fontWeight: "600",
    color: "rgb(0,0,0,1)",
    fontSize: "16px",
    // letterSpacing: "0.5px",
    width: "inherit",
    display: "inline-block"
}

export const titleUserName = {
    fontWeight: "600",
    color: "rgba(51,51,51,1)",
    fontSize: "16px",
    // letterSpacing: "0.5px",
    width: "inherit",
    display: "block"
}

export const titleUserNameWhite = {
    fontWeight: "600",
    color: "rgba(255,255,255,1)",
    fontSize: "16px",
    // letterSpacing: "0.5px",
    width: "inherit",
    display: "block"
}

export const titleUserNameBlack = {
    fontWeight: "500",
    color: "rgba(0,0,0,1)",
    fontSize: "16px",
    letterSpacing: "0.5px",
    width: "inherit",
    display: "block"
}
