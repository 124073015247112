import React, { createContext, useReducer, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { GlobalTopBarContextReducer } from '../reducers/GlobalTopBarContextReducer';

export const GlobalTopBarContext = createContext();

const initialState = {
    themeColor: 'white',
    hideTopBar: true,
    hideTopBarBorder: false,
    bgTransparent: false, 
    showBackButton: false,
    showMenuIcon: true,
    showActionIcon: false,
    showSearch: false,
    showTitle: false,
    title: "",
    showUserName: false,
    showGreenIcon: false, 
    showHouse: false,
    house: "",
    actionText: "",
    actionMethod: null,
    searchDiscovery: false


    // activeState: "home",
    // themeColor: 'black',    
    // showGreen = false, 
    // url=false, 
    // showSearchType=""
}

const GlobalTopBarContextProvider = (props) => {
    const [GlobalTopBarContextState, GlobalTopBarContextDispatch] = useReducer(GlobalTopBarContextReducer,initialState);
    const location = useLocation();

    useEffect(() => {
        // console.log('location change',location)

        // GlobalTopBarContextDispatch({ type: "RESET_TITLE" });
        // GlobalTopBarContextDispatch({ type: "RESET_HOUSE_NAME" });

        let hideTopBar = true;
        let hideTopBarBorder = false;
        let bgTransparent = false;
        let showMenu = false; 
        let showActionButton = false;       
        let showTitle = false;
        let stateColor = "white";
        let showBackButton = false;
        let showSearchBar = false;
        let showGreenDot = false;
        let showHouseName = false;
        let showUser = false;        
        let titleText = "";                   
        let searchDiscovery = false;

        // GlobalTopBarContextDispatch({ type: "RESET_ACTION" });
        
        if (location.pathname === "/") {
            stateColor = "black";
            hideTopBar = true;
            hideTopBarBorder = false;
            bgTransparent = false;
            showMenu = false;
            showActionButton = false;
            showTitle = false;
            showBackButton = false;
            showSearchBar = false;
            showGreenDot = false;
            showHouseName = false;
            showUser = false;
            GlobalTopBarContextDispatch({ type: "RESET_ACTION" });
            GlobalTopBarContextDispatch({ type: "RESET_TITLE" });
            GlobalTopBarContextDispatch({ type: "RESET_HOUSE_NAME" });
            GlobalTopBarContextDispatch({ type: "SET_ACTIVE_STATE", payload: { searchDiscovery: searchDiscovery, themeColor : stateColor, hideTopBar: hideTopBar, hideTopBarBorder: hideTopBarBorder, bgTransparent: bgTransparent, showMenuIcon: showMenu, showActionIcon: showActionButton, showTitle: showTitle, showBackButton: showBackButton, showSearch: showSearchBar, showGreenIcon: showGreenDot, showHouse: showHouseName, showUserName: showUser }}); 
        } else if (/^\/(videos|public|invite|edit\/preview)/.test(location.pathname)) {
            stateColor = "black";
            hideTopBar = true;
            hideTopBarBorder = false;
            bgTransparent = false;
            showMenu = false;
            showActionButton = false;
            showTitle = false;
            showBackButton = false;
            showSearchBar = false;
            showGreenDot = false;
            showHouseName = false;
            showUser = false;
            GlobalTopBarContextDispatch({ type: "RESET_ACTION" });
            GlobalTopBarContextDispatch({ type: "RESET_TITLE" });
            GlobalTopBarContextDispatch({ type: "RESET_HOUSE_NAME" });
            GlobalTopBarContextDispatch({ type: "SET_ACTIVE_STATE", payload: { searchDiscovery: searchDiscovery, themeColor : stateColor, hideTopBar: hideTopBar, hideTopBarBorder: hideTopBarBorder, bgTransparent: bgTransparent, showMenuIcon: showMenu, showActionIcon: showActionButton, showTitle: showTitle, showBackButton: showBackButton, showSearch: showSearchBar, showGreenIcon: showGreenDot, showHouse: showHouseName, showUserName: showUser }});
        } else if (/^\/(list\/videos)/.test(location.pathname)) {
            console.log('List Videos global top bar')
            stateColor = "black";
            hideTopBar = false;
            hideTopBarBorder = false;
            bgTransparent = true;
            showMenu = false;
            showActionButton = false;
            showTitle = false;
            showBackButton = true;
            showSearchBar = false;
            showGreenDot = false;
            showHouseName = false;
            showUser = false;
            GlobalTopBarContextDispatch({ type: "RESET_ACTION" });
            GlobalTopBarContextDispatch({ type: "RESET_TITLE" });
            GlobalTopBarContextDispatch({ type: "RESET_HOUSE_NAME" });
            GlobalTopBarContextDispatch({ type: "SET_ACTIVE_STATE", payload: { searchDiscovery: searchDiscovery, themeColor : stateColor, hideTopBar: hideTopBar, hideTopBarBorder: hideTopBarBorder, bgTransparent: bgTransparent, showMenuIcon: showMenu, showActionIcon: showActionButton, showTitle: showTitle, showBackButton: showBackButton, showSearch: showSearchBar, showGreenIcon: showGreenDot, showHouse: showHouseName, showUserName: showUser }});
        } else if (/^\/(search|account|user|house|comingsoon|support|disclaimer|faqs|terms-of-use|privacy-policy)/.test(location.pathname)) {    
            stateColor = "white";
            hideTopBar = false;
            hideTopBarBorder = false;
            bgTransparent = false;
            showMenu = true;
            showActionButton = false;
            showBackButton = true; 
            GlobalTopBarContextDispatch({ type: "RESET_ACTION" });
            if (/^\/search\/discovery/.test(location.pathname)) {
                showSearchBar = true;
                showTitle = false;
                showGreenDot = false;
                showHouseName = false;
                showUser = false;
                searchDiscovery = true;
                GlobalTopBarContextDispatch({ type: "RESET_TITLE" });
                GlobalTopBarContextDispatch({ type: "RESET_HOUSE_NAME" });
            } else if (/^\/account\/messages/.test(location.pathname)) {
                showSearchBar = true;
                showTitle = false;
                showGreenDot = false;
                showHouseName = false;
                showUser = false;
                searchDiscovery = false;
                GlobalTopBarContextDispatch({ type: "RESET_TITLE" });
                GlobalTopBarContextDispatch({ type: "RESET_HOUSE_NAME" });    
            } else if (/^\/search/.test(location.pathname)) { 
                showSearchBar = true;
                showTitle = false;
                showGreenDot = false;
                showHouseName = false;
                showUser = false;
                searchDiscovery = false;
                GlobalTopBarContextDispatch({ type: "RESET_TITLE" });
                GlobalTopBarContextDispatch({ type: "RESET_HOUSE_NAME" });
            } else if (/^\/(account\/profile|user|house)/.test(location.pathname)) {  
                if (/^\/(user|house)/.test(location.pathname)) {
                    GlobalTopBarContextDispatch({ type: "RESET_TITLE" });
                    GlobalTopBarContextDispatch({ type: "RESET_HOUSE_NAME" });
                }
                showSearchBar = false;
                showTitle = true;
                showGreenDot = true;
                showHouseName = true; 
                showUser = true;
            } else if (/^\/(comingsoon|support|disclaimer|faqs|terms-of-use|privacy-policy)/.test(location.pathname)) {                
                stateColor = "black";
                showSearchBar = false;
                showTitle = true;
                showGreenDot = false;
                showHouseName = false;
                showUser = false;
                switch(location.pathname) {
                    case "/comingsoon":
                        titleText = "Coming Soon";
                        break;
                    case "/support":
                        titleText = "Support";
                        break;
                    case "/disclaimer":
                        titleText = "Disclaimer";
                        break;
                    case "/faqs":
                        titleText = "FAQs";
                        break; 
                    case "/terms-of-use":
                        titleText = "Terms of Use";
                        break;
                    case "/privacy-policy":
                        titleText = "Privacy Policy";
                        break;    
                    default:
                }
                console.log('title', titleText)
                GlobalTopBarContextDispatch({ type: "RESET_TITLE" });
                GlobalTopBarContextDispatch({ type: "RESET_HOUSE_NAME" });
                GlobalTopBarContextDispatch({ type: "SET_TITLE", payload: { title: titleText } });  
            } else {
                showSearchBar = false;
                showTitle = true;
                showGreenDot = false;
                showHouseName = false;   
                showUser = false;                         
            } 
            GlobalTopBarContextDispatch({ type: "SET_ACTIVE_STATE", payload: { searchDiscovery: searchDiscovery, themeColor : stateColor, hideTopBar: hideTopBar, hideTopBarBorder: hideTopBarBorder, bgTransparent: bgTransparent, showMenuIcon: showMenu, showActionIcon: showActionButton, showTitle: showTitle, showBackButton: showBackButton, showSearch: showSearchBar, showGreenIcon: showGreenDot, showHouse: showHouseName, showUserName: showUser }});
        } else if (/^\/(login|registration|forgot-password|reset-password)/.test(location.pathname)) {
            stateColor = "white";
            hideTopBar = false;
            hideTopBarBorder = true;
            bgTransparent = false;
            showMenu = false;            
            showActionButton = false;
            showTitle = false;
            showBackButton = true;
            showSearchBar = false
            showGreenDot = false;
            showHouseName = false;
            showUser = false;  
            // switch(location.pathname) {
            //     case "/login":
            //         if (/^\/registration\/verify-contact/.test(location.pathname)) {
            //             titleText = "Validate Contact";
            //         } else {
            //             titleText = "Login";
            //         }
            //         break;
            //     case "/registration":
            //         if (/^\/registration\/verify-contact/.test(location.pathname)) {
            //             titleText = "Verify Contact";
            //         } else if (/^\/registration\/verify-email/.test(location.pathname)) {
            //             titleText = "Verify Email";
            //         } else if (/^\/registration\/validate-contact/.test(location.pathname)) {
            //             titleText = "Validate Contact";
            //         } else if (/^\/registration\/email-sent/.test(location.pathname)) {
            //             titleText = "Email Sent";
            //         // } else if (/^\/registration\/email-profile/.test(location.pathname)) {
            //         //     titleText = "Registration";
            //         } else {  
            //             titleText = "Registration";
            //         }                    
            //         break;
            //     case "/forgot-password":
            //         titleText = "Forgot Password";
            //         break;
            //     case "/reset-password":
            //         titleText = "Reset Password";
            //         break;
            //     default:
            // }             
            GlobalTopBarContextDispatch({ type: "RESET_ACTION" });
            GlobalTopBarContextDispatch({ type: "RESET_TITLE" });
            // GlobalTopBarContextDispatch({ type: "SET_TITLE", payload: { title: titleText } });
            GlobalTopBarContextDispatch({ type: "SET_ACTIVE_STATE", payload: { searchDiscovery: searchDiscovery, themeColor : stateColor, hideTopBar: hideTopBar, hideTopBarBorder: hideTopBarBorder, bgTransparent: bgTransparent, showMenuIcon: showMenu, showActionIcon: showActionButton, showTitle: showTitle, showBackButton: showBackButton, showSearch: showSearchBar, showGreenIcon: showGreenDot, showHouse: showHouseName, showUserName: showUser }});
        } else if (/^\/(create\/content|edit\/content)/.test(location.pathname)) {
            stateColor = "white";
            hideTopBar = false;
            hideTopBarBorder = false;
            bgTransparent = false;
            showMenu = false; 
            showActionButton = false;
            showTitle = true;
            showBackButton = true;
            showSearchBar = false
            showGreenDot = false;
            showHouseName = false;
            showUser = false;
            GlobalTopBarContextDispatch({ type: "RESET_TITLE" });
            if (/^\/(create\/content)/.test(location.pathname)) {
                titleText = "Create Content"
            } 
            if (/^\/(edit\/content)/.test(location.pathname)) {
                titleText = "Edit Content"
            }
            GlobalTopBarContextDispatch({ type: "SET_TITLE", payload: { title: titleText } }); 
            GlobalTopBarContextDispatch({ type: "SET_ACTIVE_STATE", payload: { searchDiscovery: searchDiscovery, themeColor : stateColor, hideTopBar: hideTopBar, hideTopBarBorder: hideTopBarBorder, bgTransparent: bgTransparent, showMenuIcon: showMenu, showActionIcon: showActionButton, showTitle: showTitle, showBackButton: showBackButton, showSearch: showSearchBar, showGreenIcon: showGreenDot, showHouse: showHouseName, showUserName: showUser }});
        
        } else {
            stateColor = "white";
            hideTopBar = false;
            hideTopBarBorder = false;
            hideTopBarBorder = false;
            bgTransparent = false;
            showMenu = false;            
            showActionButton = false;
            showTitle = false;
            showBackButton = true;
            showSearchBar = false
            showGreenDot = false;
            showHouseName = false;   
            showUser = false;
            GlobalTopBarContextDispatch({ type: "RESET_ACTION" });
            GlobalTopBarContextDispatch({ type: "SET_ACTIVE_STATE", payload: { searchDiscovery: searchDiscovery, themeColor : stateColor, hideTopBar: hideTopBar, hideTopBarBorder: hideTopBarBorder, bgTransparent: bgTransparent, showMenuIcon: showMenu, showActionIcon: showActionButton, showTitle: showTitle, showBackButton: showBackButton, showSearch: showSearchBar, showGreenIcon: showGreenDot, showHouse: showHouseName, showUserName: showUser }});
        }        
    }, [location])

    return (
        <GlobalTopBarContext.Provider value={{GlobalTopBarContextState, GlobalTopBarContextDispatch}}>
            {props.children}
        </GlobalTopBarContext.Provider>
    );
}
export default GlobalTopBarContextProvider;