import React, { useContext, useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinkButton from '../../components/LinkButton';
import CheckIcon from '@material-ui/icons/Check';

import { containerStyleWhite, formGridStyle3, formGridButtonSubmit, headerDefaultStyle, headerUpgradeStyle, inputReadOnly, bodyDefaultStyle } from '../../styles/defaultStyles.2';
import { ErrorTextStyle, ErrorTextContainerStyle } from '../../styles/defaultErrorStyles';
import { inputDefault, inputUnderline, eyeIcon } from '../../styles/defaultInput';
import { gridItemHeaderWrapper } from '../../styles/defaultGridStyles';

import HelmetComponent from '../../components/HelmetComponent';
import { linkBtn, continueBtn } from '../../styles/defaultButtonStyles.2';

import { FramerContext } from '../../contexts/FramerContext';
import { FormErrorContext } from '../../contexts/FormErrorContext';
import { UserContext } from '../../contexts/UserContext';
import { AuthContext } from '../../contexts/AuthContext';
import { VideoServiceContext } from '../../contexts/VideoServiceContext';
import { GlobalContext } from '../../contexts/GlobalContext';

import { GetUserService } from '../../services/UserServices';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  containerStyleWhite: containerStyleWhite, 
  formGridStyle3: formGridStyle3, 
  formGridButtonSubmit: formGridButtonSubmit, 
  headerDefaultStyle: headerDefaultStyle, 
  headerUpgradeStyle: headerUpgradeStyle, 
  inputReadOnly: inputReadOnly, 
  bodyDefaultStyle: bodyDefaultStyle,
  ErrorTextStyle: ErrorTextStyle, 
  ErrorTextContainerStyle: ErrorTextContainerStyle,
  inputDefault: inputDefault, 
  inputUnderline: inputUnderline,
  gridItemHeaderWrapper: gridItemHeaderWrapper,
  linkBtn: linkBtn,
  continueBtn: continueBtn,
  icon:{
    margin: '80px auto',    
    // backgroundColor: "rgba(253,45,85,1)",
    backgroundColor: "rgba(98,74,251,1)",    
    color: "rgba(255, 255, 255, 1)",
    borderRadius: "25px",
    padding: "10px",
    height: "50px",
    width: "50px",
    cursor: "pointer",
    // margin: "100px auto 200px",
    // margin: 'auto',
    // marginTop: '10rem',
    borderRadius: '50%',
    'webkit-animation': '$ripple 2s linear infinite',
    '-moz-animation': '$ripple 2s linear infinite',
    '-o-animation': '$ripple 2s linear infinite',
    animation: '$ripple 2s linear infinite',
    animationDelay: "0s"
  },
  // ripple: {
  //   margin: 'auto',
  //   marginTop: '1rem',
  //   backgroundColor: 'rgba(253,45,85,1)',
  //   height: "1rem",
  //   width: "1rem",
  //   borderRadius: '50%',
  //   animation: '$ripple 5s linear infinite',
  //   animationDelay: "0s"
  // },
  // "@keyframes ripple": {
  //   "0%": {
  //     boxShadow: "0 0 0 0 rgba(253,45,85, 0.2), 0 0 0 1rem rgba(253,45,85, 0.2), 0 0 0 2rem rgba(253,45,85, 0.2), 0 0 0 3rem rgba(253,45,85, 0.2), 0 0 0 4rem rgba(253,45,85, 0.2)"
  //   },
  //   "100%": {
  //     boxShadow: "0 0 0 1rem rgba(253,45,85, 0.2), 0 0 0 2rem rgba(253,45,85, 0.2), 0 0 0 3rem rgba(253,45,85, 0.2), 0 0 0 4rem rgba(253,45,85, 0.2), 0 0 0 5rem rgba(253,45,85, 0)"
  //   }
  // },
  "@keyframes ripple": {
    "0%": {
      boxShadow: "0 0 0 0 rgba(98,74,251, 0.2), 0 0 0 1rem rgba(98,74,251, 0.2), 0 0 0 2rem rgba(98,74,251, 0.2)"
    },
    "100%": {
      boxShadow: "0 0 0 1rem rgba(98,74,251, 0.2), 0 0 0 2rem rgba(98,74,251, 0.2), 0 0 0 3rem rgba(98,74,251, 0.0)"
    }
  },
  rippleWrapper: {
    width: "200px",
    height: "200px",
    textAlign: "center",
    marginBottom: '50px',
    marginTop: '30%'
  }
}));

export default function AccountProfileMood() {
  const classes = useStyles();
  let history = useHistory();
  const { GlobalContextState } = useContext(GlobalContext); 
  const { isPwaMobile } = GlobalContextState;
  const { AuthContextState, AuthContextDispatch } = useContext(AuthContext);
  const { authToken, userId, registerFlow, upgradeFlow } = AuthContextState; 
  const { FormErrorContextState, FormErrorContextDispatch } = useContext(FormErrorContext);
  const { requestError } = FormErrorContextState;
  const { UserContextState, UserContextDispatch } = useContext(UserContext);
  const { handleName } = UserContextState;
  const { toggleDirection } = useContext(FramerContext);
  const { VideoServiceContextState, VideoServiceContextDispatch } = useContext(VideoServiceContext);  
  const { videoId } = VideoServiceContextState;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [isRegisterFlow, setIsRegisterFlow] = useState(false);
  const [isUpgradeFlow, setIsUpgradeFlow] = useState(false);
  useEffect(() => {
    GetUserService(userId,authToken).then(result => {
      if (result.data.success === 1) {
          UserContextDispatch({ type: "SET_USER", payload: result.data });
          if (registerFlow) {
            setIsRegisterFlow(true);
            AuthContextDispatch({ type: "UPDATE_REGISTRER_FLOW", payload: { registerFlow: false } });
          }
          if (upgradeFlow) {
            setIsUpgradeFlow(true);
            AuthContextDispatch({ type: "UPDATE_UPGRADE_FLOW", payload: { upgradeFlow: false } });
          }
          
      } 
    }).catch(err => {
        console.log("catch GetUserService",err.response.data,err.response.status); 
    });
  }, [])

  return (
    <>
      <HelmetComponent title={"DTF - Welcome " + handleName} />      
      <Container classes={{ root: classes.containerStyleWhite }} className={isPwaMobile ? " pwaMobile" : ""}>
        <Grid 
          container
          justify="center"
          alignContent="center"
          alignItems="center"
          direction="column"
          spacing={0}
          classes={{ root: classes.formGridStyle3 }}
        >
          <Grid item classes={{ root: classes.rippleWrapper }}>
            {/* <div className={clsx(classes.ripple)}></div> */}
            <CheckIcon classes={{ root: classes.icon }} />
          </Grid>
          <Grid item>
            <Typography variant={"h1"} classes={isUpgradeFlow ? { root: classes.headerUpgradeStyle } : { root: classes.headerDefaultStyle } }>
              {
                isUpgradeFlow
                ?
                  <>Congratulations {handleName}! You are now a Gloss creator.</>
                :
                  <>Welcome {handleName}!</>  
              }              
            </Typography>   
          </Grid>
          <Grid item classes={{ root: classes.gridItemHeaderWrapper }}> 

            {
                !isUpgradeFlow
                ?    
                  <Typography classes={{ root: classes.bodyDefaultStyle }}>
                    Lorem ipsum dolor eset sim artun
                  </Typography>
                :
                  null
            }  
            {
              isUpgradeFlow
              ?
                videoId !== null
                ?
                  <LinkButton url={`/edit/content/${videoId}/true`} directionBool={true}>
                    <Button variant="outlined" color="primary" classes={{ root: classes.continueBtn }}>
                      Continue Uploading video
                    </Button>
                  </LinkButton>
                :
                  <LinkButton url={`/account/profile`} directionBool={true}>
                    <Button variant="outlined" color="primary" classes={{ root: classes.continueBtn }}>
                      Continue
                    </Button>
                  </LinkButton>
              :
                // isRegisterFlow
                // ?
                  <LinkButton url={"/account/profile"} directionBool={true}>
                    <Button variant="outlined" color="primary" classes={{ root: classes.linkBtn }}>
                      Let's Get Started
                    </Button>
                  </LinkButton>
                // :
                //   null
            }            
          </Grid>
          <Grid 
            item 
            container 
            justify="center"
            alignItems="center"
            spacing={2}
          >
            {/* <Grid item xs={12}>              
              <Input type="text" classes={{ root: classes.inputDefault, underline: classes.inputUnderline }} fullWidth={true} placeholder="Page Description (300 characters max)" error={requestError.error} inputProps={{ 'aria-label': 'Page description' }} value={description} onChange={(e) => setDescription(e.target.value.trim())} />
            </Grid> */}
            {
              requestError.error
              ?
                <Grid item xs={12} classes={{ root: classes.ErrorTextContainerStyle }}>
                  <Typography variant="body1" classes={{ root: classes.ErrorTextStyle }}>
                    { requestError.message }
                  </Typography>               
                </Grid>
              :
                null
            }                
          </Grid> 
        </Grid>
      </Container>
    </>
  );
}
