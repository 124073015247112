//https://reactjs.org/docs/hooks-reference.html#usereducer
import React, { createContext, useReducer, useEffect, useContext } from "react";
import { useSnackbar } from 'notistack';
import { DrawerMessageContextReducer } from '../reducers/DrawerMessageContextReducer';
import { DrawerMessageInitialState } from "../constants/DrawerMessageInitialState";
// import { GetCommentVideo } from "../services/VideoServices"
// import { PostCommentVideo, DeleteCommentVideo, LikeCommentVideo, UnLikeCommentVideo } from "../services/UserActionsServices";
import { AuthContext } from './AuthContext';
import { GlobalContext } from './GlobalContext';

export const DrawerMessageContext = createContext();

const initialState = DrawerMessageInitialState;

const DrawerMessageContextProvider = (props) => {
    const { AuthContextState } = useContext(AuthContext);
    const { isAuthenticated, authToken, userId } = AuthContextState; 
    const { GlobalContextDispatch } = useContext(GlobalContext);
    const [DrawerMessageContextState, DrawerMessageContextDispatch] = useReducer(DrawerMessageContextReducer,initialState);
    const { 
        initialMessageLoad=true,
        showDrawerMessage=false,    
        message="",
        messageId="",
        videoId="",
        deleteMessage=false,
        post=false,
        get=false,
        getInfinite=false,
        messages=[],
        pageNo=0,
        itemPerPage=10,
        messageCount=0,
        pageCount=0,
        currentIndex=null,
        indexUpdateMessageCount=[] 
    } = DrawerMessageContextState;
    const { enqueueSnackbar } = useSnackbar();

    // useEffect(() => {
    //     if (post && share !== "" && videoId !== "") {
    //         const objData = { comment: comment, videoId: videoId };                        
    //         PostShareVideo(objData, authToken).then((data) => {
    //             // DrawerCommentContextDispatch({ type: "RESET_COMMENT" })                    
    //             if (data.data.success === 1) {
    //                 DrawerShareContextDispatch({ type: "POST_SHARE", payload: { share: data.data.share } })
    //                 enqueueSnackbar('Share posted', { variant: 'default', autoHideDuration: 3000 });
    //             } else {
    //                 enqueueSnackbar('Post share unsuccessful', { variant: 'error', autoHideDuration: 3000 });
    //             }                 
    //         })
    //     }
    // }, [post, share, videoId])

    return (
        <DrawerMessageContext.Provider value={{DrawerMessageContextState, DrawerMessageContextDispatch}}>
            {props.children}
        </DrawerMessageContext.Provider>
    );
}
export default DrawerMessageContextProvider;