// export function isPWA() {  
//   if(window.navigator.standalone || window.matchMedia('(display-mode:standalone)').matches ) return true
//   return false
// }

export function isPWA() {  
  if(window.navigator.fullscreen || window.matchMedia('(display-mode:fullscreen)').matches || window.navigator.standalone || window.matchMedia('(display-mode:standalone)').matches) return true
  return false
}

export function isMobile() {
  if((/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) || (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.platform))) return true
  return false
}

export function getBrowserName() {                 
  let userAgent = navigator.userAgent;
  let browserName;
  
  if(userAgent.match(/edg/i)) {
    browserName = "edge";
  } else if(userAgent.match(/chrome|chromium|crios/i)){
    browserName = "chrome";
  } else if(userAgent.match(/firefox|fxios/i)){
    browserName = "firefox";
  } else if(userAgent.match(/safari/i)){
    browserName = "safari";
  } else if(userAgent.match(/opr\//i)){
    browserName = "opera";
  } else{
    browserName="default";
  }
  return browserName;        
}

export function checkInstallerIOS() {
  if(navigator.userAgent && !navigator.userAgent.match('CriOS') && !navigator.userAgent.match(/chrome/i) && navigator.userAgent.match(/iPhone|iPad|iPod/i)) return true
  return false
}

export function checkOjectExistById(arr,obj) {
  return arr.some(function(item) {
    return item._id === obj._id;
  }); 
}

export function convertCountToString(num) {
  if (typeof num !== "undefined") {
    let countString = num.toString().trim();
    switch(countString.length) {
      case 1:
      case 2:
      case 3:
        return countString;
      case 4:
      case 5:
        return ((countString.slice(0, -2) * 0.10).toFixed(1) + "k");  
      case 6:  
        return countString.slice(0, -3) + "k";
      case 7:
      case 8:
        return ((countString.slice(0, -5) * 0.10).toFixed(1) + "m");
      case 9:    
        return countString.slice(0, -6) + "m";
      case 10:
      case 11:
        return ((countString.slice(0, -8) * 0.10).toFixed(1) + "b");
      case 12:    
        return countString.slice(0, -9) + "b" ;
      case 13:
      case 14:    
        return ((countString.slice(0, -11) * 0.10).toFixed(1) + "t"); 
      default:
        return countString.slice(0, -12) + "t";         
    }
  }
  return 0;  
}

export function convertCountToString2(num) {
  if (typeof num !== "undefined") {
    let countString = num.toString().trim();
    switch(countString.length) {
      case 1:
      case 2:
      case 3:
        return countString;
      case 4:
      case 5:
      case 6:  
        return countString.slice(0, -3) + "k";
      case 7:
      case 8:
      case 9:    
        return countString.slice(0, -6) + "m";
      case 10:
      case 11:
      case 12:    
        return countString.slice(0, -9) + "b" ;
      case 13:
      case 14:
      case 15:    
        return countString.slice(0, -12) + "t" ; 
      default:
        // nothing    
    }
  }
  return 0;  
}

export function urlBase64ToUint8Array(base64String) {
  var padding = '='.repeat((4 - base64String.length % 4) % 4);
  var base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');

  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}