// core
import React, { useContext, useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";

// contexts
import FormErrorContextProvider from "../contexts/FormErrorContext";
import FollowContextProvider from '../contexts/FollowContext';
import { AuthContext } from '../contexts/AuthContext';
import { GlobalContext } from '../contexts/GlobalContext';
import UserProfileContextProvider from '../contexts/UserProfileContext';
import DrawerMessageContextProvider from '../contexts/DrawerMessageContext';
import SwipeSearchContextProvider from '../contexts/SwipeSearchContext';

// components
// import PrimarySearchAppBar from "./components/PrimarySearchAppBar";
import ProtectedPageRoute from "./ProtectedPageRoute";
import PublicPageRoute from "./PublicPageRoute";

// pages
import Login from "./registrations/Login.5";
import ValidateContactLogin from "./registrations/ValidateContactLogin.3";
import ValidateContactForgotPassword from "./registrations/ValidateContactForgotPassword";
import ForgotPassword from "./registrations/ForgotPassword.4";
// import ResetPassword from "./registrations/ResetPassword.3";
import ResetPassword from "./registrations/ResetPassword.4";
import EmailSent from "./registrations/EmailSent.3";
import VerifyContact from "./registrations/VerifyContact.3";
import VerifyEmail from "./registrations/VerifyEmail.3";
import ValidateContact from "./registrations/ValidateContact.3";
import Registration from "./registrations/Registration.3";
// import UserProfileSignUp from "./registrations/UserProfileSignUp.2";

// protected pages
import AccountDashboard from "./accounts/AccountDashboard";
import AccountProfile from "./accounts/AccountProfile.6";
import AccountProfileInformation from "./accounts/AccountProfileInformation.3";
import AccountProfileMood from "./accounts/AccountProfileMood.2";
import AccountSocialInformation from "./accounts/AccountSocialInformation.3";
import AccountMonetizationInformation from "./accounts/AccountMonetizationInformation.3";
import AccountPayoutInformation from "./accounts/AccountPayoutInformation.3";
import AccountProfileWelcome from "./accounts/AccountProfileWelcome.3";
import AccountMessages from "./accounts/AccountMessages.3";
// import AccountEvents from "./accounts/AccountEvents";
// import AccountSubscription from "./accounts/AccountSubscription";
// import AccountWallet from "./accounts/AccountWallet";
// import AccountSchedule from "./accounts/AccountSchedule";

// create videos and post content
// import ListVideos from "./private/ListVideos";
import EditContent from "./private/EditContent.9";
import PreviewVideo from "./private/PreviewVideo.2";
// import CreateContent from "./private/CreateContent.7";
// import ChatOneOnOne from "./private/ChatOneOnOne";

// public pages
import Feeds from "./public/Feeds.7";
import FeedPublicListVideos from "./public/FeedPublicListVideos.3";
import FeedVideosDiscovery from "./public/FeedVideosDiscovery.3";
import Profile from "./public/Profile.7";
// import PublicListVideos from "./public/PublicListVideos.3";
import Search from "./public/Search.3";
import SearchDiscovery from "./public/SearchDiscovery.3";
// import Faqs from "./public/Faqs";
// import Support from "./public/Support";
// import Disclaimer from "./public/Disclaimer";
// import PrivacyPolicy from "./public/PrivacyPolicy";
// import TermsOfUse from "./public/TermsOfUse";
import InvitePage from "./InvitePage";
import ComingSoon from "./ComingSoon.3";
// import ComingSoon2 from "./ComingSoon.2";

// tracking
import { PageView, initGA } from '../utils/GaTrackingUtil';

// withRouter auto scroll up on location change
import ScrollToTop from '../components/ScrollToTop';

function Routes() {
    const location = useLocation();
    const { AuthContextState } = useContext(AuthContext);
    const { isAuthenticated } = AuthContextState;    
    const { GlobalContextState } = useContext(GlobalContext);
    const { isLoading, initialLoad } = GlobalContextState;

    useEffect(() => {
        try {
            initGA(window.DTF_APP.UA_ID)
        } catch (e) {
            console.log('err', e)
        }   
    }, [])

    useEffect(() => {
        PageView();
    }, [location])

    return (    
        <>  
            <ScrollToTop />
            <Switch location={location} key={location.pathname}> 
                                
                <ProtectedPageRoute exact swipeSearch={false} formError={true} initialLoad={initialLoad} isLoading={isLoading} isAuth={isAuthenticated} path="/account/information" componentName={AccountProfileInformation} />
                <ProtectedPageRoute exact swipeSearch={false} formError={true} initialLoad={initialLoad} isLoading={isLoading} isAuth={isAuthenticated} path="/account/mood" componentName={AccountProfileMood} />
                <ProtectedPageRoute exact swipeSearch={false} formError={true} initialLoad={initialLoad} isLoading={isLoading} isAuth={isAuthenticated} path="/account/social-information" componentName={AccountSocialInformation} />
                <ProtectedPageRoute exact swipeSearch={false} formError={true} initialLoad={initialLoad} isLoading={isLoading} isAuth={isAuthenticated} path="/account/monetization-information" componentName={AccountMonetizationInformation} />
                <ProtectedPageRoute exact swipeSearch={false} formError={true} initialLoad={initialLoad} isLoading={isLoading} isAuth={isAuthenticated} path="/account/payout-information" componentName={AccountPayoutInformation} />
                <ProtectedPageRoute exact swipeSearch={false} formError={true} initialLoad={initialLoad} isLoading={isLoading} isAuth={isAuthenticated} path="/account/welcome" componentName={AccountProfileWelcome} />
                
                
                {/* <ProtectedPageRoute exact swipeSearch={false} formError={true} initialLoad={initialLoad} isLoading={isLoading} isAuth={isAuthenticated} path="/account/events" componentName={AccountEvents} />  */}
                <ProtectedPageRoute exact swipeSearch={false} formError={true} initialLoad={initialLoad} isLoading={isLoading} isAuth={isAuthenticated} path="/account/dashboard" componentName={AccountDashboard} /> 
                <ProtectedPageRoute exact swipeSearch={false} formError={true} initialLoad={initialLoad} isLoading={isLoading} isAuth={isAuthenticated} path="/account/messages" componentName={AccountMessages} /> 
                <ProtectedPageRoute exact swipeSearch={false} formError={true} initialLoad={initialLoad} isLoading={isLoading} isAuth={isAuthenticated} path="/account/events" componentName={ComingSoon} />     

                <ProtectedPageRoute exact swipeSearch={true} formError={true} initialLoad={initialLoad} isLoading={isLoading} isAuth={isAuthenticated} path="/account/profile" componentName={AccountProfile} />
                {/* <ProtectedPageRoute exact swipeSearch={false} formError={true} initialLoad={initialLoad} isLoading={isLoading} isAuth={isAuthenticated} path="/account/subscription" componentName={AccountSubscription} /> 
                <ProtectedPageRoute exact swipeSearch={false} formError={true} initialLoad={initialLoad} isLoading={isLoading} isAuth={isAuthenticated} path="/account/wallet" componentName={AccountWallet} />                    
                <ProtectedPageRoute exact swipeSearch={false} formError={true} initialLoad={initialLoad} isLoading={isLoading} isAuth={isAuthenticated} path="/account/schedule" componentName={AccountSchedule} />     */}
                <ProtectedPageRoute exact swipeSearch={false} formError={true} initialLoad={initialLoad} isLoading={isLoading} isAuth={isAuthenticated} path="/account/subscription" componentName={ComingSoon} /> 
                <ProtectedPageRoute exact swipeSearch={false} formError={true} initialLoad={initialLoad} isLoading={isLoading} isAuth={isAuthenticated} path="/account/wallet" componentName={ComingSoon} />                    
                <ProtectedPageRoute exact swipeSearch={false} formError={true} initialLoad={initialLoad} isLoading={isLoading} isAuth={isAuthenticated} path="/account/schedule" componentName={ComingSoon} />   

                <ProtectedPageRoute exact swipeSearch={false} formError={true} initialLoad={initialLoad} isLoading={isLoading} isAuth={isAuthenticated} path="/edit/content/:videoId/:isNew?" componentName={EditContent} />
                <ProtectedPageRoute exact swipeSearch={false} formError={true} initialLoad={initialLoad} isLoading={isLoading} isAuth={isAuthenticated} path="/edit/preview/:videoId" componentName={PreviewVideo} />
                {/* <ProtectedPageRoute exact swipeSearch={false} formError={true} initialLoad={initialLoad} isLoading={isLoading} isAuth={isAuthenticated} path="/show/videos/:type?" componentName={ShowVideos} /> */}
                {/* <ProtectedPageRoute exact swipeSearch={false} formError={true} initialLoad={initialLoad} isLoading={isLoading} isAuth={isAuthenticated} path="/list/videos/:videoId?/:filterType?" componentName={ListVideos} /> */}
                {/* <ProtectedPageRoute exact swipeSearch={false} formError={true} initialLoad={initialLoad} isLoading={isLoading} isAuth={isAuthenticated} path="/create/content" componentName={CreateContent} /> */}

                {/* <ProtectedPageRoute exact swipeSearch={false} formError={true} initialLoad={initialLoad} isLoading={isLoading} isAuth={isAuthenticated} path="/live/chat" componentName={ChatOneOnOne} />
                <ProtectedPageRoute exact swipeSearch={false} formError={true} initialLoad={initialLoad} isLoading={isLoading} isAuth={isAuthenticated} path="/live/group" componentName={ChatOneOnOne} />
                <ProtectedPageRoute exact swipeSearch={false} formError={true} initialLoad={initialLoad} isLoading={isLoading} isAuth={isAuthenticated} path="/live/event" componentName={ChatOneOnOne} /> */}

                {/* <Route exact path="/registration/verify-contact/:registerType" 
                render={ props => 
                    <FormErrorContextProvider>
                    <VerifyContact data={props} />
                    </FormErrorContextProvider>
                } 
                /> */}
                
                <PublicPageRoute exact isAuth={isAuthenticated} initialLoad={initialLoad} isLoading={isLoading} path="/registration/verify-contact/:registerType/:tokenExpired?" componentName={VerifyContact} />
                <PublicPageRoute exact isAuth={isAuthenticated} initialLoad={initialLoad} isLoading={isLoading} path="/registration/verify-email/:registerType/:tokenExpired?" componentName={VerifyEmail} /> 
                <PublicPageRoute exact isAuth={isAuthenticated} initialLoad={initialLoad} isLoading={isLoading} path="/registration/validate-contact/:registerType" componentName={ValidateContact} /> 
                <PublicPageRoute exact isAuth={isAuthenticated} initialLoad={initialLoad} isLoading={isLoading} path="/registration/email-sent" componentName={EmailSent} /> 
                {/* <PublicPageRoute exact isAuth={isAuthenticated} initialLoad={initialLoad} isLoading={isLoading} path="/registration/user-profile/:user" componentName={UserProfileSignUp} /> */}
                {/* <PublicPageRoute exact isAuth={isAuthenticated} initialLoad={initialLoad} isLoading={isLoading} path="/registration/:registerType/:token" componentName={Registration} /> */}
                <Route exact path="/registration/:registerType/:token" 
                    render={ props => 
                        <FormErrorContextProvider>
                            <Registration data={props} />
                        </FormErrorContextProvider>
                    } 
                /> 
                
                <PublicPageRoute exact isAuth={isAuthenticated} initialLoad={initialLoad} isLoading={isLoading} path="/login/validate-contact/:userType/:contactType/:contact" componentName={ValidateContactLogin} />                      
                <PublicPageRoute exact isAuth={isAuthenticated} initialLoad={initialLoad} isLoading={isLoading} path="/login" componentName={Login} />
                

                {/* <PublicPageRoute exact isAuth={isAuthenticated} initialLoad={initialLoad} isLoading={isLoading} path="/forgot-password/validate-contact" componentName={ValidateContact} />  */}
                <PublicPageRoute exact isAuth={isAuthenticated} initialLoad={initialLoad} isLoading={isLoading} path="/forgot-password/validate-contact" componentName={ValidateContactForgotPassword} />
                {/* <Route exact path="/forgot-password/email-sent" component={EmailSent} />                          */}
                {/* <Route exact path="/forgot-password/:tokenExpired?" 
                    render={ props => 
                        <FormErrorContextProvider>
                            <ForgotPassword data={props} />
                        </FormErrorContextProvider>
                    } 
                /> */}
                {/* <Route exact isAuth={isAuthenticated} initialLoad={initialLoad} isLoading={isLoading} path="/forgot-password/validate-contact" componentName={ValidateContactForgotPassword} />  */}
                {/* <Route exact path="/forgot-password/validate" 
                    render={ props => 
                        <FormErrorContextProvider>
                            <ForgotPassword data={props} />
                        </FormErrorContextProvider>
                    } 
                /> */}
                <Route exact path="/forgot-password/:tokenExpired?" 
                    render={ props => 
                        <FormErrorContextProvider>
                            <ForgotPassword data={props} />
                        </FormErrorContextProvider>
                    } 
                />                
                <Route exact path="/reset-password/:userType/:token" 
                    render={ props => 
                        <FormErrorContextProvider>
                            <ResetPassword />
                        </FormErrorContextProvider>
                    } 
                />
                
                <Route exact path="/house/:houseName" 
                    render={ props => 
                        <FollowContextProvider>
                            <ComingSoon />
                        </FollowContextProvider>
                    } 
                />            

                <Route exact path="/user/:userHandle" 
                    render={ props =>                             
                        <UserProfileContextProvider>
                            <DrawerMessageContextProvider>
                                <SwipeSearchContextProvider>
                                    <Profile />
                                </SwipeSearchContextProvider>
                            </DrawerMessageContextProvider>
                        </UserProfileContextProvider>
                    } 
                />

                <Route exact path="/public/list/videos/:userProfileId?/:videoId?/:filterType?" 
                    render={ props => 
                        <FeedPublicListVideos />
                    } 
                />

                <Route exact path="/videos/discovery/:searchTypeStr?/:searchStr?/:videoId?" 
                    render={ props => 
                        <FeedVideosDiscovery />
                    } 
                /> 

                <Route exact path="/videos/:filter?/:videoId?" 
                    render={ props => 
                        <Feeds />
                    } 
                /> 

                <Route exact path="/search/discovery/:searchTypeStr?/:searchStr?" 
                    render={ props => 
                        <FormErrorContextProvider>
                            <SearchDiscovery />
                        </FormErrorContextProvider>
                    } 
                />

                <Route exact path="/search" 
                    render={ props => 
                        <FormErrorContextProvider>
                            <Search />
                        </FormErrorContextProvider>
                    } 
                />  
                
                <Route exact path="/faqs" 
                    render={ props => 
                        <ComingSoon />
                    } 
                />  

                <Route exact path="/support" 
                    render={ props => 
                        <ComingSoon />
                    } 
                />

                <Route exact path="/disclaimer" 
                    render={ props => 
                        <ComingSoon />
                    } 
                />  

                <Route exact path="/privacy-policy" 
                    render={ props => 
                        <ComingSoon />
                    } 
                />  

                <Route exact path="/terms-of-use" 
                    render={ props => 
                        <ComingSoon />
                    } 
                />

                <Route exact path="/comingsoon" 
                    render={ props => 
                        <ComingSoon />
                    } 
                />

                <Route exact path="/invite" 
                    render={ props => 
                        <InvitePage />
                    } 
                />      

                <Route exact path="/" 
                    render={ props => 
                        <Feeds />
                    } 
                />
            </Switch>   
        </>  
    );
}

export default Routes;