import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { videoImageElement } from '../styles/defaultVideoWrapperStyles'; 

const useStyles = makeStyles((theme) => ({
    videoImageElement: videoImageElement
}));

export default function ImageVideoItem(props) {
    const { image="", alt="", isVideo=true, isFacingMode=false } = props;    
    const classes = useStyles(); 
    return (        
        <img src={"/dtf2021/" + (isVideo ? "video" : "image") + "/upload/h_200/v1/" + image + '.jpg'} loading="lazy" alt={alt}  className={ classes.videoImageElement + (isFacingMode ? " facingMode" : "") } />     
    );
}
