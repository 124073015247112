import React, { useContext, useState, useEffect, useRef }  from 'react';
import { useParams } from "react-router-dom";

import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { containerStyleWhite3 } from '../../styles/defaultStyles.2';
import { tabs33, tabsContainerFixed, tabsWrapper, muiTabWrapper, muiTabWrapperSelect } from '../../styles/defaultTabsStyles.2';

import HelmetComponent from '../../components/HelmetComponent';
import SearchDiscoveryItem from '../../components/SearchDiscoveryItem';
import SearchDiscoveryPeopleItem from '../../components/SearchDiscoveryPeopleItem';
import SearchDiscoveryHouseItem from '../../components/SearchDiscoveryHouseItem';
import InfiniteScroll from '../../components/InfiniteScroll';
import { GlobalContext } from '../../contexts/GlobalContext';
import { AuthContext } from '../../contexts/AuthContext';
import { SearchContext } from '../../contexts/SearchContext';

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    padding: "0px 20px",
    position: "absolute",
    top: "95px",
    left: "0",
    bottom: "75px",
    overflow: "hidden auto",
    width: "100vw"
  },
  bannerWrapper: {
    position: "relative",
    display: "block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    height: "175px",
    width: "100%",
    border: "0px solid rgba(0,0,0,1)"
  },
  itemBannerWrapper: {
    position: "relative",
    display: 'inline-block',
    width: "96%",
    height: "145px",
    margin: "10px"
  },
  containerStyleWhite3: containerStyleWhite3,
  resultContainer: {
    padding: "0px",
    overflowX: "hidden",
    overflowY: "auto",
  },
  overlayGradientTop: {
    width: "100%",
    top: "100px",
    height: "25px",
    position: "fixed",
    background: "linear-gradient(to bottom, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0))",
    zIndex: "1"
  },
  overlayGradientBottom: {
    width: "100%",
    bottom: "0px",
    height: "25px",
    position: "fixed",
    background: "linear-gradient(to top, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0))",
    zIndex: "1"
  },
  searchHeaderTitle: {
    fontSize: "16px",
    marginTop: "25px",
    marginLeft: "10px"
  },
  tabs33: tabs33,
  tabsWrapper: tabsWrapper,
  muiTabWrapper: muiTabWrapper,
  muiTabWrapperSelect: muiTabWrapperSelect,
  tabsContainerFixed: tabsContainerFixed
}));

export default function SearchDiscovery() {
  const classes = useStyles();
  const { searchTypeStr="", searchStr="" } = useParams();
  const containerRef = useRef();
  const { AuthContextState } = useContext(AuthContext);
  const { isAuthenticated } = AuthContextState;
  const { GlobalContextState } = useContext(GlobalContext);
  const { isPwaMobile } = GlobalContextState;
  const { SearchContextState, SearchContextDispatch } = useContext(SearchContext);
  const { video="", videoDiscovery=[], searchPageCount=0, pageNo=0, searchAll=false, searchAllVideosStr="", searchAllVideosType="", searchAllVideos=false, resultLoaded=false, isLoading=false, loadNext=false } = SearchContextState;
  const [ currentSearchStr, setCurrentSearchStr ] = useState((searchStr !== "") ? searchStr : searchAllVideosStr);
  const [ tabValues, setTabValues ] = useState(0);
  const [ tabStrValues, setStrTabValues ] = useState("");
  const [ initLoad, setInitLoad ] = useState(false);

  const handleLoadNext = () => {
    if (!isLoading && videoDiscovery.length !== 0)  {
      SearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading: true, searchType: "searchAllVideos" } });
      SearchContextDispatch({ type: "LOADNEXT", payload: { loadNext: true, searchType: "searchAllVideos" } });
    }    
  }
  
  useEffect(() => {
      if (searchAllVideosStr !== "") {
        setCurrentSearchStr(searchAllVideosStr);
      }      
  }, [searchAllVideosStr])

  useEffect(() => {
    if (videoDiscovery.length !== 0) {
      if (!resultLoaded) {
        SearchContextDispatch({ type: "RESULT_LOADED" });
      }
    }
  }, [videoDiscovery])

  useEffect(() => {
    let switchType = searchAllVideosType !== "" ? searchAllVideosType : searchTypeStr;
    // SearchContextDispatch({ type: "RESET_SEARCH_ALL_VIDEOS_RESULT" })
    switch(switchType) {
      case "people":
        setTabValues(0);
        setStrTabValues("people");
        SearchContextDispatch({ type: "SET_SEARCH_ALL_VIDEOS", payload: { searchAllVideosStr: currentSearchStr, searchAllVideosType: "people", itemPerPage: 12, searchAll: false, searchAllVideos: true, hashOnly: false, usernameOnly: false } });
        setInitLoad(true);
        break;
      case "houses":
        setTabValues(1);
        setStrTabValues("houses");
        SearchContextDispatch({ type: "SET_SEARCH_ALL_VIDEOS", payload: { searchAllVideosStr: currentSearchStr, searchAllVideosType: "houses", itemPerPage: 12, searchAll: false, searchAllVideos: true, hashOnly: false, usernameOnly: false } });
        setInitLoad(true);
        break;
      case "hashtags":
        setTabValues(2);
        setStrTabValues("hashtags");
        SearchContextDispatch({ type: "SET_SEARCH_ALL_VIDEOS", payload: { searchAllVideosStr: currentSearchStr, searchAllVideosType: "hashtags", itemPerPage: 12, searchAll: false, searchAllVideos: true, hashOnly: false, usernameOnly: false } });
        setInitLoad(true);
        break;     
      default:
        setTabValues(2);
        setStrTabValues("hashtags");
        SearchContextDispatch({ type: "SET_SEARCH_ALL_VIDEOS", payload: { searchAllVideosStr: currentSearchStr, searchAllVideosType: "hashtags", itemPerPage: 12, searchAll: false, searchAllVideos: true, hashOnly: false, usernameOnly: false } });
        setInitLoad(true);
    }
    if (searchAllVideosStr !== "" && searchAllVideosStr !== "@" && searchAllVideosStr !== "#" && searchAllVideosStr !== "^") {
      SearchContextDispatch({ type: "SET_SEARCH_HISTORY", payload: { searchHistory: { searchStr: currentSearchStr, searchType: searchTypeStr } } });    
    }
  }, [])

  const handleChange = (event, value) => {
    event.preventDefault();
    SearchContextDispatch({ type: "RESET_SEARCH_INFO" });
    setTabValues(value)
    switch(value) {
      case 0:
        setStrTabValues("people");
        SearchContextDispatch({ type: "SET_SEARCH_ALL_VIDEOS", payload: { searchAllVideosStr: searchAllVideosStr, searchAllVideosType: "people", itemPerPage: 12, searchAll: false, searchAllVideos: true, hashOnly: false, usernameOnly: false } });
        break;
      case 1:
        setStrTabValues("houses");
        SearchContextDispatch({ type: "SET_SEARCH_ALL_VIDEOS", payload: { searchAllVideosStr: searchAllVideosStr, searchAllVideosType: "houses", itemPerPage: 12, searchAll: false, searchAllVideos: true, hashOnly: false, usernameOnly: false } });
        break;
      case 2:
        setStrTabValues("hashtags");
        SearchContextDispatch({ type: "SET_SEARCH_ALL_VIDEOS", payload: { searchAllVideosStr: searchAllVideosStr, searchAllVideosType: "hashtags", itemPerPage: 12, searchAll: false, searchAllVideos: true, hashOnly: false, usernameOnly: false } });
        break;     
      default:
        setTabValues(2);
        setStrTabValues("hashtags");
        SearchContextDispatch({ type: "SET_SEARCH_ALL_VIDEOS", payload: { searchAllVideosStr: searchAllVideosStr, searchAllVideosType: "hashtags", itemPerPage: 12, searchAll: false, searchAllVideos: true, hashOnly: false, usernameOnly: false } });
    }
    return true;
  }  

  return (
    <>
      <HelmetComponent title={"DTF - Search"} />                      
      <Container classes={{ root: classes.containerStyleWhite3 }} className={isPwaMobile ? " pwaMobile" : ""}>    
        <div className={ classes.tabsContainerFixed + " " + (isPwaMobile ? " pwaMobile" : "") }>
          <Tabs
            value={tabValues}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
            classes={{ root: classes.tabsWrapper }}
          >
            <Tab label="People" value={0} selected={(tabValues === 0) ? true : false} classes={{ root: classes.tabs33, wrapper: classes.muiTabWrapper }} />
            <Tab label="Houses" value={1} selected={(tabValues === 1) ? true : false} classes={{ root: classes.tabs33, wrapper: classes.muiTabWrapper }} />
            <Tab label="Hashtags" value={2} selected={(tabValues === 2) ? true : false} classes={{ root: classes.tabs33, wrapper: classes.muiTabWrapper }} />
          </Tabs>
        </div>
        <div className={ classes.resultContainer } ref={containerRef}>
          {/* <div className={classes.overlayGradientTop}></div>   */}
          <Grid 
            container
            direction="row"   
          >  
            <Grid item container xs={12}>

              {             
                initLoad
                ?
                  videoDiscovery.map((item, index) => {
                    if (tabStrValues === 'houses') {
                      return <SearchDiscoveryHouseItem dataItem={item} index={index} searchType={"houses"} searchStr={currentSearchStr} />
                    } else if (tabStrValues === 'people') {
                      return <SearchDiscoveryPeopleItem dataItem={item} index={index} searchType={"people"} searchStr={currentSearchStr} />
                    } else {
                      return <SearchDiscoveryItem dataItem={item} index={index} searchType={"hashtags"} searchStr={currentSearchStr} />  
                    }                  
                  })
                :
                  null 
              }
              {
                resultLoaded && !isLoading && videoDiscovery.length !== 0 && initLoad
                ?
                  <InfiniteScroll parentRef={containerRef} method={handleLoadNext} />
                :
                  null
              }
            </Grid>
          </Grid>
          {/* <div className={classes.overlayGradientBottom}></div>  */}
        </div>  
      </Container>          
    </>
  );
}