export const MessageServiceContextReducer = (state, action) => {
    switch(action.type){
        case 'SET_INIT_DATA': 
            return {
                ...state,
                messages: action.payload.messages,
                activities: action.payload.activities,
                notifications: action.payload.notifications
            }
        case 'SET_MESSAGES_DATA':
            return { 
                ...state,
                messages: action.payload.messages,
                activities: action.payload.activities,
                notifications: action.payload.notifications
            }
        default:
            return state    
    }
}