export const listText = {
  fontSize: "14px",
  fontWeight: "400"
};

export const listTextNew = {
  fontSize: "14px",
  fontWeight: "700",
  "&.viewed": {
    fontSize: "14px",
    fontWeight: "400"
  }
};

export const listTextBold = {
  fontSize: "14px",
  fontWeight: "500"
};

export const listTextBoldNew = {
  fontSize: "14px",
  fontWeight: "800",
  "&.viewed": {
    fontSize: "14px",
    fontWeight: "500"
  }
};  