import React, { useState, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { containerStyle, containerGridStyle } from '../styles/defaultStyles';
import { gridItemWrapper } from '../styles/defaultGridStyles';
import { defaultBtn, defaultLabelBtn, defaultActiveBtn } from '../styles/defaultButtonStyles.2';
import { listWrapper } from '../styles/defaultListStyles';
import { listText, listTextBold, listTextNew, listTextBoldNew } from '../styles/defaultTextStyles';
// import { listText, listTextBold, listTextNew, listTextBoldNew } from '../styles/defaultTextStyles.2';
import ImageAvatar from './ImageAvatar.2';
import { updateUserActivitiesService } from '../services/UserServices';
import { AuthContext } from '../contexts/AuthContext';
import { ScrollItem } from '../utils/ScrollItem';

// import { useFeedScrollItem } from '../utils/useFeedScrollItem.2';

const useStyles = makeStyles((theme) => ({  
  containerGridStyle: containerGridStyle,
  containerStyle: containerStyle,
  gridItemWrapper: gridItemWrapper,
  listWrapper: listWrapper,
  listText: listText,
  listTextBold: listTextBold,
  listTextNew: listTextNew,
  listTextBoldNew: listTextBoldNew,
  defaultBtn: defaultBtn,
  defaultLabelBtn: defaultLabelBtn,
  defaultActiveBtn: defaultActiveBtn,
  itemWrapper: {
    cursor: "pointer",
    padding: "10px 20px",
    minHeight: "85px"
  },
  margin0: {
    margin: "0px"
  },
  marginRight5: {
    marginRight: "5px"
  },
  videoImage: {
    width: "30px",
    height: "auto",
    float: "right",    
    display: "inline-block",
    margin: "0 0 0 10px"
  },
  listTextWrapper: {
    "&.bubble > span": {
      background: "rgba(98,74,251,0.15)",
      padding: "10px",
      borderRadius: "10px",      
    },
    "&.bubble.user > span": {
      background: "rgba(241,241,241,0.55)"
    }     
  } 
}));

// ['POST_COMMENT','LIKE_COMMENT','UNLIKE_COMMENT','FOLLOW','UNFOLLOW','LIKE','UNLIKE','SUBSCRIBE','UNSUBSCRIBE']

function ActivityItem(props) {
  const classes = useStyles();  
  const itemRef = useRef();
  const itemWrapperRef = useRef();
  const { AuthContextState } = useContext(AuthContext);
  const { isAuthenticated, authToken, userId } = AuthContextState;
  const { dataItem, sessionUserId="", template=0, tabValues=0, setItemNewCount=null, itemNewCount=0, parentRef=null } = props;  
  const { user, user_information, item_user, item_user_information, date_updated, is_new=false, comment_item=null, video_item=null, activityType } = dataItem;  
  const videoItemPlaceHolder = { video_item_id: "", video_src: "", video_information: "", videoPlaceHolder: true };
  const { _id:video_item_id="", video_src="", video_information=null, videoPlaceHolder=false } = video_item || videoItemPlaceHolder;  
  const videoInformationPlaceHolder = { resource_type: "", videoInformationPlaceHolder: true };
  const { resource_type="" } = video_information || videoInformationPlaceHolder;
  const userPlaceholder = { _id:"", username: "", handlename: false, userType: "", userPlaceHolder: true };
  const { _id="", username="", handlename="", userType="", userPlaceHolder=false } = user || userPlaceholder;
  const userInformationPlaceHolder = { user_image_profile: "", isPlaceHolder: true };
  const { user_image_profile="", isPlaceHolder=false } = user_information || userInformationPlaceHolder;
  const itemUserPlaceholder = { item_id: "", item_username: "", item_handlename: false, item_userType: "", item_userPlaceHolder: true };
  const { _id:item_id="", username:item_username="", handlename:item_handlename="", userType:item_userType="", userPlaceHolder:item_userPlaceHolder=false } = item_user || itemUserPlaceholder;
  const itemUserInformationPlaceHolder = { item_user_image_profile: "", item_isPlaceHolder: true };
  const { user_image_profile:item_user_image_profile="", isPlaceHolder:item_isPlaceHolder=false } = item_user_information || itemUserInformationPlaceHolder;
  const [ isUpdated, setIsUpdated ] = useState(false);

  // const updateIsNew = (e) => {

  //   console.log("updateIsNew", item_id, sessionUserId, _id)

  //   if (is_new && !isUpdated) {
  //     setIsUpdated(true);
  //     const activityItem = itemRef.current;
  //     if (activityItem) {
  //       try {
  //         activityItem.children[0].classList.add("viewed");
  //         activityItem.children[1].classList.add("viewed");
  //       } catch (err) {
  //         console.log('err', err)
  //       }
  //     }
  
  //     const objData = {
  //       activityId: dataItem._id
  //     }
  //     updateUserActivitiesService(objData, authToken).then(result => {
  //       console.log('result',result);
  //       if (result.data.success === 1 && sessionUserId !== _id) {
  //         const newCount = itemNewCount - 1;
  //         setItemNewCount(newCount);
  //       }
  //     }).catch(err => {
  //       console.log("catch updateUserActivitiesService",err);
  //     });
  //   }    
  // }
  
  const getActivityText = () => {
    let text = '';
    let objectText = (sessionUserId !== _id ? '@' + handlename + ' has ' : 'You have ')
    let objectText2 = (sessionUserId !== _id ? '@' + handlename + ' ' : 'You ')
    let objectText3 = (sessionUserId !== item_id ? '@' + item_handlename + ((item_handlename.charAt(item_handlename.length - 1) === "s") ? "'" : "'s") + ' ' : 'your ')
    let objectText4= (resource_type === 'video' ? ' video' : ' picture')
    let objectText5 = (sessionUserId !== item_id ? ' @' + item_handlename : ' you')
    switch (activityType) {
      case 'POST_COMMENT':
        text = objectText2 + 'posted a comment on ' + objectText3 + objectText4;
        break;
      case 'LIKE_COMMENT':
        text = objectText2 + 'liked ' + objectText3 + ' comment';
        break;        
      case 'UNLIKE_COMMENT':
        text = objectText2 + 'unliked ' + objectText3 + ' comment';
        break;
      case 'FOLLOW':
        text = objectText + 'started following' + objectText5;
        break;
      case 'UNFOLLOW':
        text = objectText + 'stopped following' + objectText5;
        break;
      case 'LIKE':
        text = objectText2 + 'liked ' + objectText3 + objectText4;
        break;
      case 'UNLIKE':
        text = objectText2 + 'unliked ' + objectText3 + objectText4;
        break;
      case 'SUBSCRIBE':
        text = objectText + 'subscribed to ' + objectText3 + ' channel';
        break;
      case 'UNSUBSCRIBE':
        text = objectText + 'unsubscribed to ' + objectText3 + ' channel';
        break;
      default:
    }
    return (text)
  }

  const inView = () => {    
    console.log('inView')
    if (is_new && !isUpdated && sessionUserId !== _id) {
      setIsUpdated(true);
      const objData = {
        activityId: dataItem._id
      }
      updateUserActivitiesService(objData, authToken).then(result => {
        console.log('result',result);        
      }).catch(err => {
        console.log("catch updateUserActivitiesService",err);
      });
    }    
  }

  if (!userPlaceHolder) {

    const listener = ScrollItem(parentRef, itemWrapperRef, () => inView(), "0px");
  
    if (template === 2) {
      return (
        <> 
          <ListItem key={"people-tag-" + _id} className={ classes.itemWrapper } ref={itemWrapperRef}>            
            <ListItemText 
              primary={
                <>                               
                  {getActivityText()}
                </>
              } 
              secondary={<span><Moment fromNow>{date_updated}</Moment></span>} 
              classes={{
                primary: is_new ? classes.listTextBoldNew : classes.listTextBold,
                secondary: is_new ? classes.listTextNew : classes.listText, 
              }}
              className={classes.listTextWrapper + (tabValues === 2 ? " bubble" + (sessionUserId === item_id ? " user" : "") : "")}
              // onClick={(e) => updateIsNew(e)}
              ref={itemRef}
            />
            <ListItemAvatar classes={{ root: classes.margin0 }}>
              <Link to={`/user/${handlename}`}>
                {
                  user_image_profile !== "" && user_image_profile !== null
                  ?
                    <ImageAvatar image={user_image_profile} alt={username} template={2} tabValues={tabValues} />
                  :
                    <ImageAvatar letter={true} alt={handlename} template={2} tabValues={tabValues} />
                }
              </Link>
            </ListItemAvatar>          
          </ListItem>
        </>   
      )
    } else {
      return (
        <> 
          <ListItem key={"people-tag-" + _id} className={ classes.itemWrapper } ref={itemWrapperRef}>
            <ListItemAvatar classes={{ root: classes.marginRight5 }}>
              <Link to={`/user/${handlename}`}>
                {
                  user_image_profile !== "" && user_image_profile !== null
                  ?
                    <ImageAvatar image={user_image_profile} alt={username} tabValues={tabValues} />
                  :
                    <ImageAvatar letter={true} alt={handlename} tabValues={tabValues} />
                }
              </Link>
            </ListItemAvatar>
            <ListItemText 
              primary={
                <>
                  {
                    video_src && video_src !== "" && resource_type !== "" && tabValues !== 2
                    ?
                      <Link to={`/videos/latest/${video_item_id}`}>
                        <img 
                          src={`/dtf2021/${resource_type}/upload/so_0/v1/${video_src}.jpg`}
                          className={ classes.videoImage }
                        />
                      </Link>
                      
                    :
                      null
                  }                
                  {getActivityText()}
                </>
              } 
              secondary={<span><Moment fromNow>{date_updated}</Moment></span>} 
              classes={{
                primary: is_new && sessionUserId !== _id ? classes.listTextBoldNew : classes.listTextBold,
                secondary: is_new && sessionUserId !== _id ? classes.listTextNew : classes.listText, 
              }}
              className={classes.listTextWrapper + (tabValues === 2 ? " bubble" + (sessionUserId === item_id ? " user" : "") : "")}
              // onClick={(e) => updateIsNew(e)}              
              ref={itemRef}
            />
          </ListItem>
        </>   
      )
    }    
  } else {
    return null
  }
}

export default React.memo(ActivityItem);