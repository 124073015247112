export const SearchContextReducer = (state, action) => {
    switch(action.type){
        case 'SET_INIT_SEARCH_DATA': 
            return {
                ...state,
                houseTagVideo: action.payload.houseTagVideo,
                houseTagVideoResult: action.payload.houseTagVideoResult,
                houseTagSearchCount: action.payload.houseTagSearchCount,
                houseTagSearchPage: action.payload.houseTagSearchPage,
                houseTagSearchPageCount: action.payload.houseTagSearchPageCount,
                houseTagPageNo: action.payload.houseTagPageNo, 
                houseTagVideo: action.payload.houseTagVideo,
                hashTagVideo: action.payload.hashTagVideo,
                hashTagVideoResult: action.payload.hashTagVideoResult,
                hashTagSearchCount: action.payload.hashTagSearchCount,
                hashTagSearchPage: action.payload.hashTagSearchPage,
                hashTagSearchPageCount: action.payload.hashTagSearchPageCount,
                hashTagPageNo: action.payload.hashTagPageNo, 
                people: action.payload.people,
                peopleResult: action.payload.peopleResult,
                peopleSearchCount: action.payload.peopleSearchCount,
                peopleSearchPage: action.payload.peopleSearchPage,
                peopleSearchPageCount: action.payload.peopleSearchPageCount,
                peoplePageNo: action.payload.peoplePageNo, 
                video: action.payload.video,
                videoResult: action.payload.videoResult,
                videoSearchCount: action.payload.videoSearchCount,
                videoSearchPage: action.payload.videoSearchPage,
                videoSearchPageCount: action.payload.videoSearchPageCount,
                location: action.payload.location,
                locationResult: action.payload.locationResult,
                locationSearchCount: action.payload.locationSearchCount,
                locationSearchPage: action.payload.locationSearchPage,
                locationSearchPageCount: action.payload.locationSearchPageCount,
                locationPageNo: action.payload.locationPageNo,
                searchCount: action.payload.searchCount,
                searchPage: action.payload.searchPage,
                searchPageCount: action.payload.searchPageCount,
                pageNo: action.payload.pageNo,
                itemPerPage: action.payload.itemPerPage,
                itemPeoplePerPage: action.payload.itemPeoplePerPage,
                itemLocationPerPage: action.payload.itemLocationPerPage,
                itemVideoPerPage: action.payload.itemVideoPerPage,
                itemHashTagVideoPerPage: action.payload.itemHashTagVideoPerPage,
                itemHouseTagVideoPerPage: action.payload.itemHouseTagVideoPerPage,
                loadNext: action.payload.loadNext,
                loadNextType: action.payload.loadNextType,
                isLoading: action.payload.isLoading, 
                resultLoaded: action.payload.resultLoaded,
                usernameOnly: action.payload.usernameOnly,
                hashOnly: action.payload.hashOnly,
                searchAllStr: action.payload.searchAllStr,
                searchAll: action.payload.searchAll,
                searchAllVideosStr: action.payload.searchAllVideosStr,
                searchAllVideosType: action.payload.searchAllVideosType,
                searchAllVideos: action.payload.searchAllVideos,
                searchHistory: action.payload.searchHistory,
                searchHistoryResult: action.payload.searchHistoryResult,
                searchHistoryCount: action.payload.searchHistoryCount,
                searchHistoryPage: action.payload.searchHistoryPage,       
                searchHistoryPageCount: action.payload.searchHistoryPageCount,
                searchHistoryPerPage: action.payload.searchHistoryPerPage ,
                videoDiscovery: action.payload.videoDiscovery, 
                videoDiscoveryResult: action.payload.videoDiscoveryResult,                
                videoDiscoverySearchCount: action.payload.videoDiscoverySearchCount,
                videoDiscoverySearchPage: action.payload.videoDiscoverySearchPage,
                videoDiscoverySearchPageCount:action.payload.videoDiscoverySearchPageCount,
                searchDiscoveryAllVideosType: action.payload.searchDiscoveryAllVideosType,
                searchDiscoveryAllVideosStr: action.payload.searchDiscoveryAllVideosStr,
                searchDiscoveryAllVideos: action.payload.searchDiscoveryAllVideos,        
                isFocus: action.payload.isFocus,                        
            }
        case 'SET_SEARCH_PEOPLE':
            return { 
                ...state,
                people: action.payload.people,
                itemPeoplePerPage: action.payload.itemPerPage,
                usernameOnly: action.payload.usernameOnly,
                searchAll: false,
                searchAllVideos: false,
                searchDiscoveryAllVideos: false
            }         
        case 'RESET_SEARCH_PEOPLE':
            return { 
                ...state,
                people: "",
                peopleResult: [],
                usernameOnly: false
            } 
        case 'SET_SEARCH_USERNAME':
            return { 
                ...state,
                people: action.payload.people,
                itemPeoplePerPage: action.payload.itemPerPage,
                usernameOnly: action.payload.usernameOnly,
                searchAllStr: action.payload.searchAllStr,
                searchAll: false,
                searchAllVideos: false,
                searchDiscoveryAllVideos: false,
                searchAllVideosStr: action.payload.people
            }  
        case 'RESET_SEARCH_USERNAME':
            return { 
                ...state,
                people: "",
                peopleResult: [],
                usernameOnly: false,
                searchAllVideosStr: ""
            }        
        case 'SET_SEARCH_VIDEO':
            return { 
                ...state,
                video: action.payload.video,
                itemVideoPerPage: action.payload.itemPerPage,
                searchAll: false,
                searchAllVideos: false,
                searchDiscoveryAllVideos: false
            }       
        case 'RESET_SEARCH_VIDEO':
            return { 
                ...state,
                video: "",
                videoResult: []
            }
        case 'SET_SEARCH_VIDEO_HASH':
            return { 
                ...state,
                video: action.payload.video,
                itemVideoPerPage: action.payload.itemPerPage,
                hashOnly: action.payload.hashOnly,    
                searchAllStr: action.payload.searchAllStr,        
                searchAll: false,
                searchAllVideos: false,
                searchDiscoveryAllVideos: false
            }       
        case 'RESET_SEARCH_VIDEO_HASH':
            return { 
                ...state,
                video: "",
                videoResult: [],
                hashOnly: false
            }
        case 'SET_SEARCH_HOUSE_TAG_VIDEO':
            return { 
                ...state,
                houseTagVideo: action.payload.houseTagVideo,
                itemHouseTagVideoPerPage: action.payload.itemPerPage,
                hashOnly: action.payload.hashOnly,            
                searchAllStr: action.payload.searchAllStr,
                searchAll: false,
                searchAllVideos: false,
                searchDiscoveryAllVideos: false,
                searchAllVideosStr: action.payload.houseTagVideo
            } 
        case 'RESET_SEARCH_HOUSE_TAG_VIDEO':
            return { 
                ...state,
                houseTagVideo: "",
                houseTagVideoResult: [],
                hashOnly: false,
                searchAllVideosStr: ""
            }       
        case 'SET_SEARCH_HASH_TAG_VIDEO':
            return { 
                ...state,
                hashTagVideo: action.payload.hashTagVideo,
                itemHashTagVideoPerPage: action.payload.itemPerPage,
                hashOnly: action.payload.hashOnly,            
                searchAllStr: action.payload.searchAllStr,
                searchAll: false,
                searchAllVideos: false,
                searchDiscoveryAllVideos: false,
                searchAllVideosStr: action.payload.hashTagVideo
            }       
        case 'RESET_SEARCH_HASH_TAG_VIDEO':
            return { 
                ...state,
                hashTagVideo: "",
                hashTagVideoResult: [],
                hashOnly: false,
                searchAllVideosStr: ""
            }    
        case 'SET_SEARCH_LOCATION':
            return { 
                ...state,
                location: action.payload.location,
                itemLocationPerPage: action.payload.itemPerPage,
                searchAll: false,
                searchAllVideos: false,
                searchDiscoveryAllVideos: false
            }
        case 'RESET_SEARCH_LOCATION':
            return { 
                ...state,
                location: "",
                locationResult: [],
            }    
        case 'SET_SEARCH_ALL':
            return { 
                ...state,
                houseTagVideo: action.payload.houseTagVideo,
                itemHouseTagVideoPerPage: action.payload.itemPerPage,
                people: action.payload.people,
                itemPeoplePerPage: action.payload.itemPerPage,
                hashTagVideo: action.payload.hashTagVideo,
                itemHashTagVideoPerPage: action.payload.itemPerPage,
                video: action.payload.video,
                itemVideoPerPage: action.payload.itemPerPage,
                location: action.payload.location,
                itemLocationPerPage: action.payload.itemPerPage,
                searchAllStr: action.payload.searchAllStr,
                searchAll: true,
                searchAllVideosStr: action.payload.people,
                searchAllVideos: false,
                searchDiscoveryAllVideos: false
            }     
        case 'SET_SEARCH_ALL_VIDEOS':
            return { 
                ...state,
                itemVideoPerPage: action.payload.itemPerPage,
                itemPerPage: action.payload.itemPerPage,
                searchAllVideosType: action.payload.searchAllVideosType,
                searchAllVideosStr: action.payload.searchAllVideosStr,
                searchAllVideos: true,
                searchAll: false,
                searchDiscoveryAllVideos: false
            }  
        case 'SET_SEARCH_ALL_VIDEOS_RESULT':
            return { 
                ...state,                    
                videoDiscovery: action.payload.videoDiscovery,                
                videoSearchCount: action.payload.videoSearchCount,
                videoSearchPage: action.payload.videoSearchPage,
                videoSearchPageCount: action.payload.videoSearchPageCount,
                itemVideoPerPage: action.payload.itemVideoPerPage,                
                itemPerPage: action.payload.itemVideoPerPage,                
                searchPageCount: action.payload.videoSearchPageCount,
                pageNo: action.payload.videoSearchPage
            } 
        case 'RESET_SEARCH_ALL_VIDEOS_RESULT':
            return { 
                ...state,                    
                videoDiscovery: [],
                videoSearchCount: 0,
                videoSearchPage: 0,
                videoSearchPageCount: 0,
                searchPageCount: 0,
                pageNo: 0
            }     
        case 'INFINITE_SCROLL_SET_SEARCH_ALL_VIDEOS_RESULT':
            return { 
                ...state,                    
                videoDiscovery: state.videoDiscovery.concat(action.payload.videoDiscovery),              
                videoSearchCount: action.payload.videoSearchCount,
                videoSearchPage: action.payload.videoSearchPage,
                videoSearchPageCount: action.payload.videoSearchPageCount,
                itemVideoPerPage: action.payload.itemVideoPerPage,                
                itemPerPage: action.payload.itemVideoPerPage,
                searchPageCount: action.payload.videoSearchPageCount,
                pageNo: action.payload.videoSearchPage
            }
        case 'SET_SEARCH_DISCOVERY_ALL_VIDEOS':
            return { 
                ...state,
                itemVideoPerPage: action.payload.itemPerPage,
                itemPerPage: action.payload.itemPerPage,
                searchDiscoveryAllVideosType: action.payload.searchDiscoveryAllVideosType,
                searchDiscoveryAllVideosStr: action.payload.searchDiscoveryAllVideosStr,
                searchDiscoveryAllVideos: true,
                searchAllVideos: false,
                searchAll: false
            }    
        case 'SET_SEARCH_DISCOVERY_ALL_VIDEOS_RESULTS':
            return { 
                ...state,                    
                videoDiscoveryResult: action.payload.videoDiscoveryResult,                
                videoDiscoverySearchCount: action.payload.videoDiscoverySearchCount,
                videoDiscoverySearchPage: action.payload.videoDiscoverySearchPage,
                videoDiscoverySearchPageCount: action.payload.videoDiscoverySearchPageCount,
                itemVideoPerPage: action.payload.itemVideoPerPage,                
                itemPerPage: action.payload.itemVideoPerPage,                
                searchPageCount: action.payload.videoDiscoverySearchPageCount,
                pageNo: action.payload.videoDiscoverySearchPage
            }
        case 'INFINITE_SCROLL_SET_SEARCH_DISCOVERY_ALL_VIDEOS_RESULT':
            return { 
                ...state,                    
                videoDiscoveryResult: state.videoDiscoveryResult.concat(action.payload.videoDiscoveryResult),              
                videoDiscoverySearchCount: action.payload.videoDiscoverySearchCount,
                videoDiscoverySearchPage: action.payload.videoDiscoverySearchPage,
                videoDiscoverySearchPageCount: action.payload.videoDiscoverySearchPageCount,
                itemVideoPerPage: action.payload.itemVideoPerPage,                
                itemPerPage: action.payload.itemVideoPerPage,
                searchPageCount: action.payload.videoDiscoverySearchPageCount,
                pageNo: action.payload.videoDiscoverySearchPage
            }                        
        case 'SET_SEARCH_PEOPLE_RESULT':
            return { 
                ...state,
                peopleResult: action.payload.peopleResult,
                pageNo: action.payload.pageNo,
                itemPeoplePerPage: action.payload.itemPerPage,
                searchCount: action.payload.searchCount,
                searchPage: action.payload.searchPage,
                searchPageCount: action.payload.searchPageCount
            }
        case 'INFINITE_SCROLL_SEARCH_PEOPLE_RESULT':
            return {
                ...state,
                peopleResult: state.peopleResult.concat(action.payload.peopleResult),
                pageNo: action.payload.pageNo,
                itemPeoplePerPage: action.payload.itemPerPage,
                searchCount: action.payload.searchCount,
                searchPage: action.payload.searchPage,
                searchPageCount: action.payload.searchPageCount
            }    
        case 'SET_SEARCH_VIDEO_RESULT':
            return { 
                ...state,
                videoResult: action.payload.videoResult,
                pageNo: action.payload.pageNo,
                itemVideoPerPage: action.payload.itemPerPage,
                searchCount: action.payload.searchCount,
                searchPage: action.payload.searchPage,
                searchPageCount: action.payload.searchPageCount
            }           
        case 'INFINITE_SCROLL_SEARCH_VIDEO_RESULT':
            return {
                ...state,
                videoResult: state.videoResult.concat(action.payload.videoResult),
                pageNo: action.payload.pageNo,
                itemVideoPerPage: action.payload.itemPerPage,
                searchCount: action.payload.searchCount,
                searchPage: action.payload.searchPage,
                searchPageCount: action.payload.searchPageCount
            }
        case 'SET_SEARCH_HOUSE_TAG_VIDEO_RESULT':
            return { 
                ...state,
                houseTagVideoResult: action.payload.houseTagVideoResult,
                pageNo: action.payload.pageNo,
                itemHouseTagVideoPerPage: action.payload.itemPerPage,
                searchCount: action.payload.searchCount,
                searchPage: action.payload.searchPage,
                searchPageCount: action.payload.searchPageCount
            }
        case 'INFINITE_SCROLL_SEARCH_HOUSE_TAG_VIDEO_RESULT':
            return {
                ...state,
                houseTagVideoResult: state.houseTagVideoResult.concat(action.payload.houseTagVideoResult),
                pageNo: action.payload.pageNo,
                itemHouseTagVideoPerPage: action.payload.itemPerPage,
                searchCount: action.payload.searchCount,
                searchPage: action.payload.searchPage,
                searchPageCount: action.payload.searchPageCount
            }          
        case 'SET_SEARCH_HASH_TAG_VIDEO_RESULT':
            return { 
                ...state,
                hashTagVideoResult: action.payload.hashTagVideoResult,
                pageNo: action.payload.pageNo,
                itemHashTagVideoPerPage: action.payload.itemPerPage,
                searchCount: action.payload.searchCount,
                searchPage: action.payload.searchPage,
                searchPageCount: action.payload.searchPageCount
            }           
        case 'INFINITE_SCROLL_SEARCH_HASH_TAG_VIDEO_RESULT':
            return {
                ...state,
                hashTagVideoResult: state.hashTagVideoResult.concat(action.payload.hashTagVideoResult),
                pageNo: action.payload.pageNo,
                itemHashTagVideoPerPage: action.payload.itemPerPage,
                searchCount: action.payload.searchCount,
                searchPage: action.payload.searchPage,
                searchPageCount: action.payload.searchPageCount
            }    
        case 'SET_SEARCH_LOCATION_RESULT':
            return { 
                ...state,
                locationResult: action.payload.locationResult,
                pageNo: action.payload.pageNo,
                itemLocationPerPage: action.payload.itemPerPage,
                searchCount: action.payload.searchCount,
                searchPage: action.payload.searchPage,
                searchPageCount: action.payload.searchPageCount
            }
        case 'INFINITE_SCROLL_SEARCH_LOCATION_RESULT':
            return {
                ...state,
                locationResult: state.locationResult.concat(action.payload.locationResult),
                pageNo: action.payload.pageNo,
                itemLocationPerPage: action.payload.itemPerPage,
                searchCount: action.payload.searchCount,
                searchPage: action.payload.searchPage,
                searchPageCount: action.payload.searchPageCount
            }
        case 'SET_SEARCH_ALL_RESULT':
            return { 
                ...state,
                houseTagVideo: action.payload.houseTagVideo,
                houseTagVideoResult: action.payload.houseTagVideoResult,
                itemHouseTagVideoPerPage: action.payload.itemHouseTagVideoPerPage,
                houseTagSearchCount: action.payload.houseTagSearchCount,
                houseTagSearchPage: action.payload.houseTagSearchPage,
                houseTagSearchPageCount: action.payload.houseTagSearchPageCount,
                houseTagPageNo: action.payload.houseTagPageNo,
                houseTagVideo: action.payload.houseTagVideo,
                hashTagVideoResult: action.payload.hashTagVideoResult,
                itemHashTagVideoPerPage: action.payload.itemHashTagVideoPerPage,
                hashTagSearchCount: action.payload.hashTagSearchCount,
                hashTagSearchPage: action.payload.hashTagSearchPage,
                hashTagSearchPageCount: action.payload.hashTagSearchPageCount,
                hashTagPageNo: action.payload.hashTagPageNo,
                peopleResult: action.payload.peopleResult,
                itemPeoplePerPage: action.payload.itemPeoplePerPage,
                peopleSearchCount: action.payload.peopleSearchCount,
                peopleSearchPage: action.payload.peopleSearchPage,
                peopleSearchPageCount: action.payload.peopleSearchPageCount,
                peoplePageNo: action.payload.peoplePageNo,
                videoResult: action.payload.videoResult,
                itemVideoPerPage: action.payload.itemVideoPerPage,
                videoSearchCount: action.payload.videoSearchCount,
                videoSearchPage: action.payload.videoSearchPage,
                videoSearchPageCount: action.payload.videoSearchPageCount,
                locationResult: action.payload.locationResult,                
                itemLocationPerPage: action.payload.itemLocationPerPage,
                locationSearchCount: action.payload.locationSearchCount,
                locationSearchPage: action.payload.locationSearchPage,
                locationSearchPageCount: action.payload.locationSearchPageCount,
                locationPageNo: action.payload.locationPageNo,
                itemPerPage: action.payload.itemPerPage,
                pageNo: action.payload.pageNo,
                searchCount: action.payload.searchCount,
                searchPage: action.payload.searchPage,
                searchPageCount: action.payload.searchPageCount  
            }
        case 'RESULT_LOADED': 
            return { 
                ...state,
                resultLoaded: true
            }  
        case 'RESET_RESULT_LOADED': 
            return { 
                ...state,
                resultLoaded: false
            }            
        case 'RESET_SEARCH_INFO':
            return {
                ...state,
                houseTagVideo: "",
                houseTagVideoResult: [],
                houseTagSearchCount: 0,
                houseTagSearchPage: 0,
                houseTagSearchPageCount: 0,
                houseTagPageNo: 0, 
                houseTagVideo: "",
                hashTagVideoResult: [],
                hashTagSearchCount: 0,
                hashTagSearchPage: 0,
                hashTagSearchPageCount: 0,
                hashTagPageNo: 0, 
                people: "",
                peopleResult: [],
                peopleSearchCount: 0,
                peopleSearchPage: 0,
                peopleSearchPageCount: 0,
                peoplePageNo: 0,  
                video: "",
                videoResult: [],
                videoSearchCount: 0,
                videoSearchPage: 0,
                videoSearchPageCount: 0,
                location: "",
                locationResult: [],
                locationSearchCount: 0,
                locationSearchPage: 0,
                locationSearchPageCount: 0,
                locationPageNo: 0,
                searchCount: 0,
                searchPage: 0,
                searchPageCount: 0,
                pageNo: 0,
                loadNext: false,
                loadNextType: '',
                isLoading: false, 
                resultLoaded: false,
                itemPerPage: 10,
                itemPeoplePerPage: 10,
                itemVideoPerPage: 10,
                itemLocationPerPage: 10,
                itemHashTagVideoPerPage: 10,
                itemHouseTagVideoPerPage: 10,
                usernameOnly: false,
                hashOnly: false,
                searchAllStr: "",
                searchAll: false,
                searchAllVideosStr: "",
                // searchAllVideosType: "",
                searchAllVideos: false,
                videoDiscovery: [],
                videoDiscoveryResult: [],                
                videoDiscoverySearchCount: 0,
                videoDiscoverySearchPage: 0,
                videoDiscoverySearchPageCount: 0,   
                searchDiscoveryAllVideosType: "",
                searchDiscoveryAllVideosStr: "",
                searchDiscoveryAllVideos: false,
                isFocus: false    
            }
        case 'RESET_SEARCH_TYPE':
            return {
                ...state,
                searchAllVideosType: "",
            }
        case 'LOADNEXT':
            return {
                ...state,
                loadNext: action.payload.loadNext,
                loadNextType: action.payload.searchType        
            }
        case 'SET_IS_LOADING':
            return {
                ...state,
                isLoading: action.payload.isLoading      
            }    
        case 'RESET_LOADNEXT':
            return {
                ...state,
                loadNext: false,
                loadNextType: '',  
            }
        case 'SET_SEARCH_HISTORY':
            return {
                ...state,
                searchHistory: action.payload.searchHistory      
            } 
        case 'RESET_SEARCH_HISTORY':
            return {
                ...state,
                searchHistory: null     
            }
        case 'SET_SEARCH_HISTORY_RESULT':
            return {
                ...state,
                searchHistoryResult: action.payload.searchHistoryResult, 
                searchHistoryCount: action.payload.searchHistoryCount,
                searchHistoryPage: action.payload.searchHistoryPage,       
                searchHistoryPageCount: action.payload.searchHistoryPageCount,
                searchHistoryPerPage: action.payload.searchHistoryPerPage         
            }
        case 'RESET_SEARCH_HISTORY_RESULT':
            return {
                ...state,
                searchHistoryResult: [],
                searchHistoryCount: 0,
                searchHistoryPage: 0,       
                searchHistoryPageCount: 0,
                searchHistoryPerPage: 10       
            }
        case 'SET_IS_FOCUS': 
            return { 
                ...state,
                isFocus:  action.payload.isFocus
            }                        
        default:
            return state    
    }
}