import React from 'react';

export default class ButtonScheduleWhite extends React.Component {
    render() {
        return (
            <svg id="icons_Q2" data-name="icons Q2" xmlns="http://www.w3.org/2000/svg" width="17.776" height="17.776" viewBox="0 0 17.776 17.776">
                <path id="Path_2088" data-name="Path 2088" d="M10.888,3.616a7.272,7.272,0,1,1-7.272,7.272,7.272,7.272,0,0,1,7.272-7.272m0-1.616a8.848,8.848,0,1,0,6.3,2.591A8.888,8.888,0,0,0,10.888,2Z" transform="translate(-2 -2)" fill="#fff"/>
                <path id="Path_2089" data-name="Path 2089" d="M26.848,20.08a.687.687,0,0,1-.4-.121L22,17.292V12.808a.808.808,0,1,1,1.616,0v3.6l3.636,2.182a.805.805,0,0,1-.4,1.495Z" transform="translate(-13.92 -7.96)" fill="#fff"/>
            </svg>      
        )
    }
}