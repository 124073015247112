import React, { useContext, useEffect, useState, useRef, createRef } from 'react';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import LoopRoundedIcon from '@material-ui/icons/LoopRounded';
import { containerStyleWhite } from '../../styles/defaultStyles.2';
import { videoWrapper } from '../../styles/defaultVideoWrapperStyles';
import { iconHeart, iconLike, iconView, premiumLock, premiumLockWrapper } from '../../styles/defaultIconStyles.2';
import { tabs, tabs50, tabsWrapper, muiTabWrapper, muiTabWrapperSelect, muiTabWrapperLast, muiTabWrapperSelectLast } from '../../styles/defaultTabsStyles.2';
import { dashboardBtn, dashboardBtnLabel, dashboardBtnLabel2 } from '../../styles/defaultButtonStyles.2';
import HelmetComponent from "../../components/HelmetComponent";
import LinkButton from '../../components/LinkButton';
import ImageLinkWrapper from '../../components/ImageLinkWrapper';
import TabNullCase from '../../components/TabNullCase';

//contexts
import { GlobalContext } from '../../contexts/GlobalContext';
import { GlobalTopBarContext } from '../../contexts/GlobalTopBarContext';
import { UserContext } from '../../contexts/UserContext';
import { AuthContext } from '../../contexts/AuthContext';
import { DrawerSignUpContext } from '../../contexts/DrawerSignUpContext';

//components
import InfiniteScroll from "../../components/InfiniteScroll";
import SwipeFollowers from '../../components/SwipeFollowers.2';
import SwipeFollowing from '../../components/SwipeFollowing.2';
import SwipeSubscribers from '../../components/SwipeSubscribers.2';
import SwipeSubscriptions from '../../components/SwipeSubscriptions';
import DrawerUpgradeSignUp from "../../components/DrawerUpgradeSignUp.3";
import ButtonScheduleWhite from '../../components/svg/ButtonScheduleWhite';
import ButtonMessageWhite from '../../components/svg/ButtonMessageWhite';
import ButtonChartWhite from '../../components/svg/ButtonChartWhite';
import PencilWhite from '../../components/svg/PencilWhite';
import EyeWhiteBorder from '../../components/svg/EyeWhiteBorder';
import HeartSmallBorderWhite from '../../components/svg/HeartSmallBorderWhite';
import LockWhiteCirclePink from '../../components/svg/LockWhiteCirclePink';
import ImageProfileAvatar from '../../components/ImageProfileAvatar';
import ImageVideoItem from '../../components/ImageVideoItem';
import ImageProfileItem from '../../components/ImageProfileItem';
// import HornWhite from '../../components/svg/HornWhite';

//tabs
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

//services
import { GetAllLatestVideoByUserIdServiceWithFilters } from '../../services/VideoServices';
import { GetUserService } from "../../services/UserServices";
import { GetSubscriptions, GetFollowing } from '../../services/UserActionsServices';

//util
import { convertCountToString } from '../../utils/CommonUtil';
import { vh } from '../../constants/VideoSettings';

const useStyles = makeStyles((theme) => ({  
  containerStyleWhite: containerStyleWhite,
  videoWrapper: videoWrapper,
  iconHeart: iconHeart,
  iconLike: iconLike,
  iconView: iconView,
  iconWrapper: {    
    width: "98%",
    position: "absolute",
    left: "0px",
    bottom: "0px",
    display: "inline-block",
    height: "30px",
    fontSize: "12px",
    fontWeight: "600",
    color: "rgba(255,255,255,1)",
  },
  iconLeftWrapper: {    
    position: "relative",
    width: "50%",
    textAlign: "center",
    display: "inline-block"
  },
  iconRightWrapper: {    
    position: "relative",
    width: "50%",
    textAlign: "center",
    display: "inline-block"
  },
  iconText: {    
    position: "relative",
    top: "-3px",
    left: "3px",
    textShadow: "1px 1px rgb(0,0,0,0.5)"
  },
  paddingWrapper: {
    padding: "0 0 200px"
  },
  tabs: tabs,
  tabs50: tabs50,
  tabsWrapper: tabsWrapper,
  muiTabWrapper: muiTabWrapper,
  muiTabWrapperSelect: muiTabWrapperSelect,
  muiTabWrapperLast: muiTabWrapperLast, 
  muiTabWrapperSelectLast: muiTabWrapperSelectLast,
  description : {
    fontSize: "14px",
    color: "rgba(150,148,152,1)",
    textAlign: "center",
    padding: "0 50px",
    fontWeight: "500"
  },
  dashboardAvatar: {
    width: "100px",
    height: "100px",
    fontSize: "52px"
  },
  dashboardBackgroundAvatar: {
    backgroundColor: "rgba(253,45,85,0.7)"
  },
  dashboardBtn: dashboardBtn,
  dashboardBtnLabel: dashboardBtnLabel,  
  dashboardBtnLabel2: dashboardBtnLabel2,
  // dashboardIcon: {
  //   color: "rgba(255,255,255,1)",
  //   fontSize: "20px",
  //   position: "relative",
  //   top: "5px",
  //   marginRight: "5px",
  // },
  dashboardIcon: {
    color: "rgba(255,255,255,1)",
    position: "relative",
    top: "3px",
    marginRight: "5px",
    display: "inline-block",
    height: "18px",
    "& > svg": {
      height: "inherit"
    }
  },
  dashboardValue: {
    fontSize: "16px",
    fontWeight: "900",
    textDecoration: "none",
    color: "rgba(20,24,34,1)",
    top: "-16px",
    position: "relative"
  },
  dashboardLabel: {
    fontSize: "14px",
    fontWeight: "500",
    textDecoration: "none",
    color: "rgba(150,148,152,1)",
    top: "-16px",
    position: "relative"
  },
  dashboardLabelLink: {
    textDecoration: "none",
  },
  premiumLockWrapper: premiumLockWrapper,
  premiumLock: premiumLock,
  marginTop: {
    marginTop: "10px"
  },
  marginTopBottomDefault: {
    margin: "30px 0 30px"
  },
  marginTopBottom: {
    margin: "50px 0 30px",
    height: "16px",    
    borderRight: "1px solid rgba(192,192,192,1)"
  },
  marginTopBottomNoBorder: {
    margin: "50px 0 30px",
    height: "16px"
  },
  marginTopBottomPaddingLeft: {
    paddingLeft: "50px",
    margin: "30px 0",
    height: "16px",
    borderRight: "1px solid rgba(192,192,192,1)"
  },
  marginTopBottomPaddingRight: {
    paddingRight: "50px",
    margin: "30px 0",
    height: "16px",
  },
  buttonWrapper: {
    padding: "0px 5px",
    margin: "0"
  },
  buttonWrapperLeft: {
    padding: "0px 0px 0px 15px",
    margin: "0"
  },
  buttonWrapperRight: {
    padding: "0px 15px 0px 0px",
    margin: "0"
  },
  buttonWrapper2: {
    padding: "0px 15px",
    margin: "0"
  },
  padding15: {
    padding: "15px"
  },
  editWrapper: {
    position: "absolute",
    top: "0px",
    color: "rgba(255,255,255,1)",
    textAlign: "center",
    width: "100%",
    height: "100%",
    display: "block",
    backgroundColor: "rgba(0,0,0,0.3)",
    padding: "65% 0 0",
    overflow: "hidden"
  },
  tabItemWrapper: {
    maxHeight: 'calc(100vh - 175px)',
    overflow: 'hidden auto',
    display: 'flex',
    "&.pwaMobile" : {
      height: 'calc(100vh - 225px)',
    }
  },
  itemWrapper: {
    height: "100%"
  },
  "@keyframes rotation": {
    "from": {
      transform: "rotate(359deg)"
    },
    "to": {
      transform: "rotate(0deg)"
    }
  },
  "@keyframes bganimate": {
    "0%": {
        backgroundColor: "rgba(192,192,192,1)"
    },
    "50%": {
        backgroundColor: "rgba(150,148,152,1)"
    },
    "100%": {
        backgroundColor: "rgba(192,192,192,1)"
    }
  },
  videoImageUploadIconElement: { 
    position: "relative",
    fontSize: "60px",
    color: "rgba(255,255,255,0.3)",
    width: "50px",
    height: "50px",
    top: "35%",
    margin: "auto",
    display: "block",
    'webkit-animation': '$rotation 2s linear infinite',
    '-moz-animation': '$rotation 2s linear infinite',
    '-o-animation': '$rotation 2s linear infinite',
    animation: '$rotation 2s linear infinite',
    animationDelay: "0s"
  } 
}));

function AccountProfile() {
  const classes = useStyles();
  const containerRef = useRef();

  const { GlobalContextState } = useContext(GlobalContext);
  const { isPwaMobile } = GlobalContextState;
  const { GlobalTopBarContextDispatch } = useContext(GlobalTopBarContext);
  const { DrawerSignUpContextState, DrawerSignUpContextDispatch } = useContext(DrawerSignUpContext);
  const { UserContextState, UserContextDispatch } = useContext(UserContext);
  const { username, handleName, userType, user_description, user_videos, user_house_link, user_house_name, user_followers, user_following, user_subscribers, user_subscriptions, user_image_profile, user_moods } = UserContextState;
  // const { videos=[], count=0, premiumCount=0, publicCount=0, draftCount=0 } = user_videos;
  const { AuthContextState } = useContext(AuthContext);
  const { isAuthenticated, userId, authToken } = AuthContextState;
  const [ videoItems, setVideoItems ] = useState([]);
  const [ tabValues, setTabValues ] = useState(0);
  const [ videos, setVideos ] = useState([]);
  const [ personItems, setPersonItems ] = useState([]);
  const [ pageCount, setPageCount ] = useState(0);
  const [ pageNo, setPageNo ] = useState(0);
  const [ itemCount, setItemCount ] = useState(0)
  const [ itemPerPage, setItemPerPage ] = useState(6);
  const [ filterType, setFilterType ] = useState('all');
  const [ isLoading, setIsLoading ] = useState(true);
  const [ isLoaded, setIsLoaded ] = useState(false);

  const [ template, setTemplate ] = useState(null);

  const handleChange = (event, value) => {
    event.preventDefault();    
    switch(value) {
      case 0:
        if (template === "creator") {
          resetData(value, 'all');
          getData('all',0);
        } else {          
          resetData(value); 
          getData('following',0); 
        }
        break;
      case 1:
        if (template === "creator") {
          resetData(value, 'premium');
          getData('premium',0);
        } else {    
          resetData(value); 
          getData('subscriptions',0);    
        }                
        break;
      case 2:
        resetData(value, 'public');
        getData('public',0);
        break;
      case 3:        
        resetData(value, 'drafts');        
        getData('drafts',0);
        break;       
      default:
          //nothing
    }
    return true;
  };

  const resetData = (val, type) => {
    setTabValues(val);
    if (template === "creator") {
      setFilterType(type);
      setVideos([]);        
      setPageCount(0);
      setPageNo(0);
    } else {
      setPersonItems([]); 
    }    
  }

  const handleUpgrade = (event,item) => {    
    DrawerSignUpContextDispatch({ type: "SHOW_UPGRADE_DRAWER", payload: true });  
  }

  const getData = (filter, page) => {
    setIsLoading(true);
    setIsLoaded(false);
    if (template === "creator") {
      GetAllLatestVideoByUserIdServiceWithFilters({userId: userId, pageNo: page, itemPerPage: itemPerPage, filterType: filter, authToken: authToken}).then(result => {
        if (result.data.success === 1) {
          // UserContextDispatch({ type: "APPEND_USER_VIDEOS", payload: result.data });
          setVideos(result.data.userVideos.videos);
          setPageCount(result.data.userVideos.pageCount);
          setItemCount(result.data.userVideos.count);     
        }
        setPageNo(page + 1, setIsLoading(false)); 
        setIsLoaded(true);      
        return true;
      }).catch(err => {
          console.log("catch GetAllLatestVideoByUserIdServiceWithFilters",err);
      });
    } else {
      const objData = { profile_user_id: userId, itemPerPage: 10, pageNo: 0 };
      if (filter === "following") {
        GetFollowing(objData).then(result => {
          if (result.data.success === 1) {
            setPersonItems(result.data.people);
            setPageCount(result.data.pageCount);          
          }             
          setPageNo(1, setIsLoading(false));
          setIsLoaded(true);  
        }).catch(err => {
            console.log("catch GetFollowing",err);
        });
      } else {
        GetSubscriptions(objData).then(result => {
          if (result.data.success === 1) {           
            setPersonItems(result.data.people);
            setPageCount(result.data.pageCount); 
          }
          setPageNo(1, setIsLoading(false));
          setIsLoaded(true);   
        }).catch(err => {
            console.log("catch GetSubscriptions",err);
        });
      }
    }    
  }

  const loadNext = () => {
    if (template === "creator") {
      if (pageCount > pageNo && videos.length < itemCount && videos.length !== 0) {
        if (!isLoading) {
          setIsLoading(true);
          GetAllLatestVideoByUserIdServiceWithFilters({userId: userId, pageNo: pageNo, itemPerPage: itemPerPage, filterType: filterType, authToken: authToken}).then(result => {
            if (result.data.success === 1) {
              setVideos(videos.concat(result.data.userVideos.videos), setPageNo(pageNo + 1, setIsLoading(false)));
            } else {
              setIsLoading(false)
            }
          }).catch(err => {
              setIsLoading(false);
              console.log("catch GetAllLatestVideoByUserIdServiceWithFilters",err);
          });
        }
      }
    } else {
      if (pageCount > pageNo & personItems.length !== 0) {
        if (!isLoading) {
          setIsLoading(true);
          const objData = { profile_user_id: userId, itemPerPage: 10, pageNo: pageNo };
          if (tabValues === 0) {
            GetFollowing(objData).then(result => {
              if (result.data.success === 1) {
                setPersonItems(personItems.concat(result.data.people), setPageNo(pageNo + 1, setIsLoading(false)));                    
              } else {
                setIsLoading(false);
              }
            }).catch(err => {
                setIsLoading(false);
                console.log("catch GetFollowing",err);
            });
          } else {
            GetSubscriptions(objData).then(result => {
              if (result.data.success === 1) {           
                setPersonItems(personItems.concat(result.data.people), setPageNo(pageNo + 1, setIsLoading(false))); 
              } else {
                setIsLoading(false);
              }
            }).catch(err => {
                console.log("catch GetSubscriptions",err);
            });
          }
        }
      }
    }    
  }

  useEffect(() => {
    if (template !== null) {
      // get latest
        GetUserService(userId,authToken).then(result => {
          if (result.data.success === 1) {
              UserContextDispatch({ type: "SET_USER", payload: result.data });
          } 
        }).catch(err => {
            console.log("catch GetUserService",err.response.data,err.response.status);
        });

      if (template === "creator") {
        GetAllLatestVideoByUserIdServiceWithFilters({userId: userId, pageNo: pageNo, itemPerPage: itemPerPage, filterType: filterType, authToken: authToken}).then(result => {
          if (result.data.success === 1) {
            setVideos(result.data.userVideos.videos);
            setPageCount(result.data.userVideos.pageCount);
            setItemCount(result.data.userVideos.count);
          }
          setPageNo(pageNo + 1); 
          setIsLoading(false);
          setIsLoaded(true);  
        }).catch(err => {
            console.log("catch GetAllLatestVideoByUserIdService",err);
        });
      } else {
        const objData = { profile_user_id: userId, itemPerPage: 10, pageNo: 0 };        
        if (tabValues === 0) {
          GetFollowing(objData).then(result => {
            if (result.data.success === 1) {
              setPersonItems(result.data.people);
              setPageCount(result.data.pageCount);           
            }
            setPageNo(pageNo + 1); 
            setIsLoading(false);
            setIsLoaded(true);  
          }).catch(err => {
              console.log("catch GetFollowing",err);
          });
        } else {
          GetSubscriptions(objData).then(result => {
            if (result.data.success === 1) {           
              setPersonItems(result.data.people);
              setPageCount(result.data.pageCount);   
            }
            setPageNo(pageNo + 1); 
            setIsLoading(false);
            setIsLoaded(true);  
          }).catch(err => {
              console.log("catch GetSubscriptions",err);
          });
        }        
      }      
    }
    
  }, [template]);

  useEffect(() => {
    if (handleName !== "") {            
        GlobalTopBarContextDispatch({ type: "SET_TITLE", payload: { title: handleName } });  
        GlobalTopBarContextDispatch({ type: "SET_HOUSE_NAME", payload: { house: user_house_name } });
    }     
  }, [handleName])  

  useEffect(() => {
    
    if (userType && template === null) {
      setTemplate(userType)
    }    
  }, [userType]) 

  const [anchor, setAnchor] = useState('right');
  const [followersBool, setFollowersBool] = useState(false);
  const [followingBool, setFollowingBool] = useState(false);
  const [subscribersBool, setSubscribersBool] = useState(false);  
  const [subscriptionsBool, setSubscriptionsBool] = useState(false);

  const onCloseFollowers = () => {
    setFollowersBool(false)
  }

  const onCloseFollowing = () => {
    setFollowingBool(false)
  }
  
  const onCloseSubscribers = () => {
    setSubscribersBool(false)
  } 

  const onCloseSubscriptions = () => {
    setSubscriptionsBool(false)
  }   

  return (
    <>
      <HelmetComponent title={"DTF - Profile Page - " + handleName} description={user_description} />                        
      <Container classes={{ root: classes.containerStyleWhite }} className={isPwaMobile ? " pwaMobile" : ""} ref={containerRef}>
        {
          template
          ?
            <Grid 
              container
              direction="column"            
            >
              <Grid 
                container
                direction="row"
              > 
                <Grid item xs={12} align="center">   
                  {
                    user_image_profile !== "" && user_image_profile !== null
                    ?
                      <ImageProfileAvatar image={user_image_profile} alt={handleName} /> 
                    :
                      <ImageProfileAvatar letter={true} alt={handleName} />
                  }
                </Grid>
                {
                  template === "creator"
                  ?
                    <>
                      <Grid item xs={4} classes={{ root: classes.marginTopBottom }} onClick={() => setFollowingBool(true)}>  
                        <Typography variant={"body1"} align="center" classes={{ root: classes.dashboardValue }}>{ convertCountToString(user_following) }</Typography>
                        <Typography variant={"body2"} align="center" classes={{ root: classes.dashboardLabel }}>Following</Typography>
                      </Grid>
                      <Grid item xs={4} classes={{ root: classes.marginTopBottom }} onClick={() => template === "creator" ? setFollowersBool(true) : setSubscriptionsBool(true) }>
                        <Typography variant={"body1"} align="center" classes={{ root: classes.dashboardValue }}>{ convertCountToString(user_followers) }</Typography>
                        <Typography variant={"body2"} align="center" classes={{ root: classes.dashboardLabel }}>{ "Follower" + (user_followers === 1 ? "" : "s") }</Typography>
                      </Grid>
                      <Grid item xs={4} classes={{ root: classes.marginTopBottomNoBorder }} onClick={() => setSubscribersBool(true)}>
                        <Typography variant={"body1"} align="center" classes={{ root: classes.dashboardValue }}>{ convertCountToString(user_subscribers) }</Typography>
                        <Typography variant={"body2"} align="center" classes={{ root: classes.dashboardLabel }}>Subscriber{user_subscribers === 1 ? '' : 's'}</Typography>
                      </Grid>
                      <Grid item xs={4} align="center" classes={{ root: classes.buttonWrapperLeft }}>
                        <LinkButton url={"/account/events"} directionBool={true}>                  
                          <Button variant="outlined" color="primary" classes={{ root: classes.dashboardBtn, label: classes.dashboardBtnLabel }}>
                            <span className={classes.dashboardIcon}>
                                <ButtonScheduleWhite />
                            </span>   
                            {/* Events
                            <span className={classes.dashboardIcon}>
                              <HornWhite />
                            </span>   */}
                            Holla
                          </Button>
                        </LinkButton>
                      </Grid> 
                      <Grid item xs={4} align="center" classes={{ root: classes.buttonWrapper }}>
                        <LinkButton url={"/account/messages"} directionBool={true}>
                          <Button variant="outlined" color="primary" classes={{ root: classes.dashboardBtn, label: classes.dashboardBtnLabel }}>
                            <span className={classes.dashboardIcon}>
                                <ButtonMessageWhite />
                            </span>  
                            Chat
                          </Button>
                        </LinkButton>
                      </Grid> 
                      <Grid item xs={4} align="center" classes={{ root: classes.buttonWrapperRight }}>
                        <LinkButton url={"/account/dashboard"} directionBool={true}>
                          <Button variant="outlined" color="primary" classes={{ root: classes.dashboardBtn, label: classes.dashboardBtnLabel }}>
                            <span className={classes.dashboardIcon}>
                                <ButtonChartWhite />
                            </span>  
                            Dashboard
                          </Button>
                        </LinkButton>
                      </Grid>
                    </>
                  :
                    <>
                      <Grid item xs={6} classes={{ root: classes.marginTopBottomPaddingLeft }}>  
                        <Typography variant={"body1"} align="center" classes={{ root: classes.dashboardValue }}>{ convertCountToString(user_following) }</Typography>
                        <Typography variant={"body2"} align="center" classes={{ root: classes.dashboardLabel }}>Following</Typography>
                      </Grid>
                      <Grid item xs={6} classes={{ root: classes.marginTopBottomPaddingRight }}>
                        <Typography variant={"body1"} align="center" classes={{ root: classes.dashboardValue }}>{ convertCountToString(user_subscriptions) }</Typography>
                        <Typography variant={"body2"} align="center" classes={{ root: classes.dashboardLabel }}>{ "Subscription" + (user_subscriptions === 1 ? "" : "s") }</Typography>
                      </Grid>
                      <Grid item xs={12} align="center" classes={{ root: classes.buttonWrapper2 }}>
                        <Button variant="outlined" color="primary" classes={{ root: classes.dashboardBtn, label: classes.dashboardBtnLabel2 }} onClick={(e) => handleUpgrade(e)} >
                          Become a Creator
                        </Button>
                      </Grid>
                    </>
                }           
                <Grid item xs={12} classes={{ root: classes.marginTopBottomDefault }}>
                  <Typography variant={"body1"} classes={{ body1: classes.description }}>
                    {user_description}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {
                    template === "creator"
                    ?
                      <Tabs
                        value={tabValues}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                        classes={{ root: classes.tabsWrapper }}
                        disableFocusRipple={true}
                        disableRipple={true}
                      >
                        <Tab label="All" value={0} selected={(tabValues === 0) ? true : false} classes={{ root: classes.tabs, wrapper: (tabValues === 0) ? classes.muiTabWrapperSelect : classes.muiTabWrapper }} />
                        <Tab label="Premium" value={1} selected={(tabValues === 1) ? true : false} classes={{ root: classes.tabs, wrapper: (tabValues === 1) ? classes.muiTabWrapperSelect : classes.muiTabWrapper }} />
                        <Tab label="Free" value={2} selected={(tabValues === 2) ? true : false} classes={{ root: classes.tabs, wrapper: (tabValues === 2) ? classes.muiTabWrapperSelect : classes.muiTabWrapper }} />
                        <Tab label="Drafts" value={3} selected={(tabValues === 3) ? true : false} classes={{ root: classes.tabs, wrapper: (tabValues === 3) ? classes.muiTabWrapperSelectLast : classes.muiTabWrapperLast }} />
                      </Tabs>
                    :
                      <Tabs
                        value={tabValues}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                        classes={{ root: classes.tabsWrapper }}
                        disableFocusRipple={true}
                        disableRipple={true}
                      >
                        <Tab label="Following" value={0} selected={(tabValues === 0) ? true : false} classes={{ root: classes.tabs50, wrapper: (tabValues === 0) ? classes.muiTabWrapperSelect : classes.muiTabWrapper }} />
                        <Tab label="Subscriptions" value={1} selected={(tabValues === 1) ? true : false} classes={{ root: classes.tabs50, wrapper: (tabValues === 1) ? classes.muiTabWrapperSelectLast : classes.muiTabWrapperLast }} />
                      </Tabs>
                  }
                </Grid>
                <Grid item container xs={12} className={classes.itemWrapper}>
                  {
                    template === "creator"
                    ?
                      videos.length !== 0
                      ?      
                        videos.map((item, index) => {
                            return (
                              <Grid item xs={4} key={"video-grid-"+index} className={ classes.videoWrapper }>
                                {                                                                 
                                  item.active
                                  ?                                    
                                    <>        
                                      <ImageLinkWrapper url={"/edit/content/"+item._id}>
                                        {
                                          item.video_src
                                          ?
                                            <ImageVideoItem isFacingMode={item.isFacingMode} image={item.video_src} alt={item.video_name} isVideo={item.video_information ? item.video_information.resource_type === "video" ? true : false : false} />
                                          :
                                            <LoopRoundedIcon classes={{root: classes.videoImageUploadIconElement}} />
                                        }                                           
                                      </ImageLinkWrapper>
                                      {
                                        item.premium
                                        ?
                                          <div className={ classes.premiumLockWrapper }>
                                            <span className={ classes.premiumLock }>
                                              <LockWhiteCirclePink />
                                            </span>
                                          </div>
                                        :
                                          null
                                      }
                                      <div className={ classes.iconWrapper }>
                                        <div className={ classes.iconLeftWrapper }>
                                          <span className={ classes.iconLike }>
                                            <HeartSmallBorderWhite />
                                          </span>
                                          <span className={ classes.iconText }>{convertCountToString(item.likes)}</span>   
                                        </div>
                                        <div className={ classes.iconRightWrapper }>
                                          <span className={ classes.iconView }>
                                            <EyeWhiteBorder />
                                          </span>
                                          <span className={ classes.iconText }>{convertCountToString(item.views)}</span>                               
                                        </div>
                                      </div>
                                    </>
                                  :                                  
                                    <ImageLinkWrapper url={"/edit/content/"+item._id}>  

                                      {
                                        item.video_src
                                        ?
                                          <ImageVideoItem isFacingMode={item.isFacingMode} image={item.video_src} alt={item.video_name} isVideo={item.video_information ? item.video_information.resource_type === "video" ? true : false : false} />
                                        :
                                          <LoopRoundedIcon classes={{root: classes.videoImageUploadIconElement}} />
                                      }
                                      <div className={ classes.editWrapper }>
                                        <PencilWhite />
                                        <div>Edit Post</div>
                                      </div>
                                    </ImageLinkWrapper>
                                }     
                              </Grid>
                            )                                        
                        })     
                      :
                        isLoaded && !isLoading
                        ?
                          <TabNullCase isOwner={true} />
                        :
                          null
                    :
                      personItems.length !== 0
                      ?      
                        personItems.map((item, index) => {
                          if (tabValues === 0) {
                            if (item.follow_user !== null) {
                              return (
                                <Grid item xs={4} key={"video-grid-"+index} className={ classes.videoWrapper }>       
                                  <ImageLinkWrapper url={"/user/"+item.follow_user.handlename}>  
                                    <ImageProfileItem image={item.follow_user_information.user_image_profile} alt={item.follow_user.username} />
                                  </ImageLinkWrapper>
                                </Grid>
                              ) 
                            } else {
                              return null
                            }    
                          } else {
                            if (item.subscribe_user !== null) {
                              console.log('subscribe_user', item, index)
                              return (
                                <Grid item xs={4} key={"video-grid-"+index} className={ classes.videoWrapper }>    
                                  <ImageLinkWrapper url={"/user/"+item.subscribe_user.handlename}>  
                                    <ImageProfileItem image={item.subscribe_user_information.user_image_profile} alt={item.subscribe_user.username} />
                                  </ImageLinkWrapper>
                                </Grid>
                              )
                            } else {
                              return null
                            }
                          }                            
                        })
                      :
                        isLoaded && !isLoading 
                        ?
                          <TabNullCase isOwner={true} />
                        :
                          null                      
                  }                              
                </Grid>                             
              </Grid>                  
            </Grid>
          :
            null
        }   
        {
          template === "creator"
          ?
            <>
              <SwipeFollowers isOwner={true} type={"followers"} pId={userId} anchor={anchor} isOpen={followersBool} onCloseHandler={onCloseFollowers} />      
              <SwipeFollowing isOwner={true} type={"following"} pId={userId} anchor={anchor} isOpen={followingBool} onCloseHandler={onCloseFollowing} />      
              <SwipeSubscribers isOwner={true} type={"subscribers"} pId={userId} anchor={anchor} isOpen={subscribersBool} onCloseHandler={onCloseSubscribers} /> 
              <SwipeSubscriptions isOwner={true} type={"subscriptions"}  pId={userId} anchor={anchor} isOpen={subscriptionsBool} onCloseHandler={onCloseSubscriptions} />       
            </>
          :
            null
        }
        <DrawerUpgradeSignUp />
        <InfiniteScroll parentRef={containerRef} method={loadNext} margin={vh + "px"} />    
      </Container>          
    </>
  );
}

export default AccountProfile;