import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import { defaultFormButton, defaultFormIconButton } from '../styles/defaultButtonStyles';

const useStyles = makeStyles((theme) => ({
  defaultFormButton: defaultFormButton,
  defaultFormIconButton: defaultFormIconButton
}));

export default function CloseButton(props) {
  const classes = useStyles();
  if (props.isActive) {
    return (    
      <button type="button" className={classes.defaultFormButton} onClick={(e) => props.handleCloseClick(e)}>
        <ClearIcon classes={{ root: classes.defaultFormIconButton }} className={(props.isActive ? "active" : "")} />
      </button>
    )
  } else {
    return (    
      <button type="button" className={classes.defaultFormButton} disabled>
        <ClearIcon classes={{ root: classes.defaultFormIconButton }} className={(props.isActive ? "active" : "")} />
      </button>
    )
  }
  
}