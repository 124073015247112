import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AuthContext } from '../contexts/AuthContext';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'auto',
    height: 'auto',
    position: 'relative',
    margin: 'auto',
    textAlign: 'center'
  },
  childSpinner: {
    color: 'rgba(98,74,251,1)',
    position: 'relative'
  }
}));

export default function CircleSpinnerInline() {
  const classes = useStyles();  
  return (
    <div className={classes.root}>
      <CircularProgress color="primary" className={classes.childSpinner} />
    </div>
  );
  
}