import React from 'react';

export default class TrashGray extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="14.957" height="20.81" viewBox="0 0 14.957 20.81">
                <path id="_ionicons_svg_ios-trash" d="M118.818,67.468,120.1,83.222a1.587,1.587,0,0,0,1.588,1.588h7.641a1.587,1.587,0,0,0,1.588-1.588l1.263-15.753Zm3.338,14.361L121.793,69.2h1l.374,12.627Zm3.81,0h-.975V69.2h.975Zm2.834,0h-1.008l.368-12.627h1Zm2.531-16.312H129.38l-1.425-1.246A1.1,1.1,0,0,0,127.24,64h-3.512a1.116,1.116,0,0,0-.726.271l-1.425,1.246h-1.951c-.954,0-1.626.455-1.626,1.409h14.957C132.957,65.973,132.285,65.517,131.331,65.517Z" transform="translate(-118 -64)" fill="#9c9c9c"/>
            </svg> 
        )
    }
}