//https://reactjs.org/docs/hooks-reference.html#usereducer
import React, { createContext, useReducer, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { AuthContextReducer } from '../reducers/AuthContextReducer';
import { GetSession } from "../services/AuthServices";
import { GetUserActivitiesCountsService } from "../services/UserServices";
import { GlobalContext } from './GlobalContext';
import { AuthInitialState } from "../constants/AuthInitialState";

export const AuthContext = createContext();

const initialState = AuthInitialState;

const AuthContextProvider = (props) => {
    const location = useLocation();
    const [AuthContextState, AuthContextDispatch ] = useReducer(AuthContextReducer,initialState);
    const { isAuthenticated, authToken } = AuthContextState;
    const { GlobalContextState, GlobalContextDispatch } = useContext(GlobalContext);   
    
    useEffect(() => {
        // console.log('useEffect AuthContextState hook ran', AuthContextState)
        //check auth on load and set data
        GetSession().then(result => {
            console.log('result.data', result.data)
            if (result.data.success === 1) {
                AuthContextDispatch({ type: "SIGN_ON", payload: { isAuthenticated: result.data.isAuth, authToken: result.data.token, userId: result.data.userId, userType: result.data.userType }});
                if (result.data.isAuth) {
                    GetUserActivitiesCountsService(result.data.token).then(result2 => {
                        console.log('result2',result2);
                        console.log('typeof result.data.data.newCount', typeof result2.data.data.newCount);
                        console.log('typeof result.data.data.newCountMessages', typeof result2.data.data.newCountMessages)
                        console.log('result.data.data.newCount', result2.data.data.newCount);
                        console.log('result.data.data.newCountMessages', result2.data.data.newCountMessages)
                        if (result2.data.success === 1) {
                            try {
                                if (result2.data.data.newCount > 0 || result2.data.data.newCountMessages > 0) {
                                    GlobalContextDispatch({ type: "NEW_ACTIVITIES", payload: result2.data.data });
                                } else {
                                    GlobalContextDispatch({ type: "NO_NEW_ACTIVITIES" });
                                }
                            } catch (err) {
                                console.log('err', err)
                            }
                        }
                    });
                }
            }
            GlobalContextDispatch({ type: "TOGGLE_IS_LOADING", payload: { isLoading : false }});
            GlobalContextDispatch({ type: "SET_INITIAL_LOAD", payload: { initialLoad : false }});  
        });
    }, [])
    
    useEffect(() => {
        if (isAuthenticated && authToken) {
            GetUserActivitiesCountsService(authToken).then(result => {
                console.log('result',result);
                console.log('typeof result.data.data.newCount', typeof result.data.data.newCount);
                console.log('typeof result.data.data.newCountMessages', typeof result.data.data.newCountMessages)
                console.log('result.data.data.newCount', result.data.data.newCount);
                console.log('result.data.data.newCountMessages', result.data.data.newCountMessages)
                if (result.data.success === 1) {
                    try {
                        if (result.data.data.newCount > 0 || result.data.data.newCountMessages > 0) {
                            GlobalContextDispatch({ type: "NEW_ACTIVITIES", payload: result.data.data });
                        } else {
                            GlobalContextDispatch({ type: "NO_NEW_ACTIVITIES" });
                        }
                    } catch (err) {
                        console.log('err', err)
                    }
                }
            });
        }              
    },[location])

    return (
        <AuthContext.Provider value={{AuthContextState, AuthContextDispatch}}>
            {props.children}
        </AuthContext.Provider>
    );
}

export default AuthContextProvider;