//https://reactjs.org/docs/hooks-reference.html#usereducer
import React, { createContext, useReducer, useEffect, useContext } from "react";
import { useSnackbar } from 'notistack';
import { DrawerContentContextReducer } from '../reducers/DrawerContentContextReducer';
import { DrawerContentInitialState } from "../constants/DrawerContentInitialState";
import { AuthContext } from './AuthContext';
import { GlobalContext } from './GlobalContext';

export const DrawerContentContext = createContext();

const initialState = DrawerContentInitialState;

const DrawerContentContextProvider = (props) => {
    const { AuthContextState } = useContext(AuthContext);
    const { isAuthenticated, authToken, userId, userType } = AuthContextState; 
    const { GlobalContextDispatch } = useContext(GlobalContext);
    const [DrawerContentContextState, DrawerContentContextDispatch] = useReducer(DrawerContentContextReducer,initialState);
    const { 
        contentId=null,
        showContentDrawer=false
    } = DrawerContentContextState;
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        // console.log('DrawerContentContextProvider userType', isAuthenticated, userId, userType);
        // console.log('DrawerContentContextProvider authToken', authToken);
    }, [isAuthenticated, userId, userType])

    return (
        <DrawerContentContext.Provider value={{DrawerContentContextState, DrawerContentContextDispatch}}>
            {props.children}
        </DrawerContentContext.Provider>
    );
}
export default DrawerContentContextProvider;