import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
// import { FramerContext } from '../contexts/FramerContext';

const useStyles = makeStyles((theme) => ({ 
  linkDefault: {
    backgroundColor: "transparent",
    textDecoration: "none",
    width:"inherit",
    height: "inherit",
    display: "inline-block",
    "&:hover, &:focus, &:active": {
      backgroundColor: "transparent",
    },
    "& > img": {
      width: "45px",
      height: "40px",
      display: "inline-block"
    }
  }
}));

export default function GlobalBottomBarMainButton(props) {
  const classes = useStyles();
  const { url=null, children="", directionBool=true, callbackHandler=null, callbackValue="" } = props;
  // const { toggleDirection } = useContext(FramerContext);

  const clickHanlder = (e) => {  
    if (callbackHandler && url === null) {
      e.preventDefault();
      callbackHandler();
    }
    //  toggleDirection(directionBool,true);
  }

  return (
    <Link to={url} onClick={(e) => clickHanlder(e)} className={classes.linkDefault}>
      { children }
    </Link>
  );
}
