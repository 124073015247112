// const vw = Math.max(document.documentElement.clientWidth || 1280, window.screen.width || 1280);
// const vh = Math.max(document.documentElement.clientHeight || 720, window.screen.height || 720);
export const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
export const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

export const videoSettings = { 
    audio: true,
    video: { 
        facingMode: "user",
        width: { min: 360, ideal: 480, max: 600 },
        height: { min: 480, ideal: 640, max: 800 },
        frameRate: { max: 60 }
    } 
}; 

export const videoRecordUser = { 
    audio: true,
    video: { 
        facingMode: "user",
        width: { min: 480, ideal: 1280, max: 1920 },
        height: { min: 360, ideal: 720, max: 1080 },
        frameRate: { max: 60 }
    } 
}; 

export const videoRecordEnvironment = { 
    audio: true,
    video: { 
        facingMode: "environment",
        width: { min: 480, ideal: 1280, max: 1920 },
        height: { min: 360, ideal: 720, max: 1080 },
        frameRate: { max: 60 }
    } 
}; 

export const transformSettings = { 
    transformVw: "720", 
    transformVh: "1280",
    transformDpr: window.devicePixelRatio.toFixed(1) || 1.0
};

export const cloudinarySettings = { 
    srcDomain: "https://res.cloudinary.com/dtf2021/video/upload/",
    srcTransformation: "b_blurred:1000,c_pad,h_1280,w_720/",
    srcDpr: "dpr_" + ((window.devicePixelRatio) ? window.devicePixelRatio.toFixed(1) : "1.0") + "/",
    srcDu: "du_10/",
    srcVersion: "v1/",
    srcMovieType: ".mp4",
    srcImageType: ".jpg",
    cloudName: "dtf2021"
};

export const deviceInfo = {
    vw: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
    vh: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
    dpr: window.devicePixelRatio.toFixed(1) || 1.0
}