import React, { useContext, useEffect, useState, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { containerStyleWhite } from '../../styles/defaultStyles.2';
import { imageWrapper, imageElement } from '../../styles/defaultImageWrapperStyles';
import { videoWrapper } from '../../styles/defaultVideoWrapperStyles';
import { iconHeart, iconLike, iconView, premiumLock, premiumLockWrapper } from '../../styles/defaultIconStyles.2';
import { tabs33, tabs50, tabsWrapper, muiTabWrapper, muiTabWrapperSelect, muiTabWrapperLast, muiTabWrapperSelectLast } from '../../styles/defaultTabsStyles.2';
import { dashboardBtn, dashboardBtnLabel } from '../../styles/defaultButtonStyles.2';
import HelmetComponent from "../../components/HelmetComponent";
import LinkButton from '../../components/LinkButton';

//tabs
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

//contexts
import { GlobalContext } from '../../contexts/GlobalContext';
import { GlobalTopBarContext } from '../../contexts/GlobalTopBarContext';
import { UserProfileContext } from '../../contexts/UserProfileContext';
import { AuthContext } from '../../contexts/AuthContext';
import { DrawerSignUpContext } from '../../contexts/DrawerSignUpContext';
import { DrawerMessageContext } from '../../contexts/DrawerMessageContext';

//components
import InfiniteScroll from "../../components/InfiniteScroll";
import DrawerMessage from "../../components/DrawerMessage";
import SwipeFollowers from '../../components/SwipeFollowers.2';
import SwipeFollowing from '../../components/SwipeFollowing.2';
import SwipeSubscribers from '../../components/SwipeSubscribers.2';
import SwipeSubscriptions from '../../components/SwipeSubscriptions';
import ScheduleBigPurple from '../../components/svg/ScheduleBigPurple';
import FollowBigPurple from '../../components/svg/FollowBigPurple';
import PencilWhite from '../../components/svg/PencilWhite';
import HornWhite from '../../components/svg/HornWhite';
import LockedWhite from '../../components/svg/LockedWhite';
import EyeWhiteBorder from '../../components/svg/EyeWhiteBorder';
import HeartSmallBorderWhite from '../../components/svg/HeartSmallBorderWhite';
import LockWhiteCirclePink from '../../components/svg/LockWhiteCirclePink';
import TabNullCase from '../../components/TabNullCase';
import ImageProfileAvatar from '../../components/ImageProfileAvatar';
import ImageVideoItem from '../../components/ImageVideoItem';
import ImageProfileItem from '../../components/ImageProfileItem';

//services
import { GetAllLatestVideoByUserIdServiceWithFiltersPublic } from '../../services/VideoServices';
import { FollowService, UnfollowService, subscribeService, UnsubscribeService, GetSubscriptions, GetFollowing } from '../../services/UserActionsServices';

//util
import { convertCountToString } from '../../utils/CommonUtil';
import { vh } from '../../constants/VideoSettings';

const useStyles = makeStyles((theme) => ({  
  containerStyleWhite: containerStyleWhite,
  videoWrapper: videoWrapper,
  imageWrapper: imageWrapper,
  imageElement: imageElement,
  iconHeart: iconHeart,
  iconView: iconView,
  iconLike: iconLike,
  iconWrapper: {    
    width: "98%",
    position: "absolute",
    left: "0px",
    bottom: "0px",
    display: "inline-block",
    height: "30px",
    fontSize: "12px",
    fontWeight: "600",
    color: "rgba(255,255,255,1)",
  },
  iconLeftWrapper: {    
    position: "relative",
    width: "50%",
    textAlign: "center",
    display: "inline-block"
  },
  iconRightWrapper: {    
    position: "relative",
    width: "50%",
    textAlign: "center",
    display: "inline-block"
  },
  iconText: {    
    position: "relative",
    top: "-3px",
    left: "3px",
    textShadow: "1px 1px rgb(0,0,0,0.5)"
  },
  paddingWrapper: {
    padding: "0 0 200px"
  },
  tabs33: tabs33,
  tabs50: tabs50,
  tabsWrapper: tabsWrapper,
  muiTabWrapper: muiTabWrapper,
  muiTabWrapperSelect: muiTabWrapperSelect,
  muiTabWrapperLast: muiTabWrapperLast, 
  muiTabWrapperSelectLast: muiTabWrapperSelectLast,
  description : {
    fontSize: "14px",
    color: "rgba(150,148,152,1)",
    textAlign: "center",
    padding: "0 50px",
    fontWeight: "500"
  },
  dashboardAvatar: {
    width: "100px",
    height: "100px",    
    fontSize: "52px"
  },
  dashboardBtn: dashboardBtn,
  dashboardBtnLabel: dashboardBtnLabel,  
  dashboardIcon: {
    color: "rgba(255,255,255,1)",
    position: "relative",
    top: "3px",
    marginRight: "5px",
    display: "inline-block",
    height: "18px",
    "& > svg": {
      height: "inherit"
    }
  },
  dashboardIcon2: {
    color: "rgba(255,255,255,1)",
    position: "relative",
    top: "3px",
    marginRight: "5px",
    display: "inline-block",
    height: "18px",
    "& > svg": {
        height: "inherit",
        width: "inherit"
    }
  },    
  dashboardValue: {
    fontSize: "16px",
    fontWeight: "900",
    textDecoration: "none",
    color: "rgba(20,24,34,1)",
    top: "-16px",
    position: "relative"
  },
  dashboardLabel: {
    fontSize: "14px",
    fontWeight: "500",
    textDecoration: "none",
    color: "rgba(150,148,152,1)",
    top: "-16px",
    position: "relative"
  },
  dashboardLabelLink: {
    textDecoration: "none",
  },
  premiumLockWrapper: premiumLockWrapper,
  premiumLock: premiumLock,
  marginTop: {
    marginTop: "10px"
  },
  marginTopBottomDefault: {
    margin: "30px 0 30px",
  },
  marginTopBottom: {
    margin: "50px 0 30px",
    height: "16px",    
    borderRight: "1px solid rgba(192,192,192,1)"
  },
  marginTopBottomNoBorder: {
    margin: "50px 0 30px",
    height: "16px"
  },
  marginTopBottomPaddingLeft: {
    paddingLeft: "50px",
    margin: "30px 0",
    height: "16px", 
    borderRight: "1px solid rgba(192,192,192,1)"
  },
  marginTopBottomPaddingRight: {
    paddingRight: "50px",
    height: "16px", 
    margin: "30px 0"
  },
  padding15: {
    padding: "15px"
  },
  bottomSpacer: {
    height: "65px"
  },  
  buttonWrapper: {
    padding: "0px 5px",
    margin: "0"
  },
  buttonWrapperLeft: {
    padding: "0px 0px 0px 15px",
    margin: "0"
  },
  buttonWrapperRight: {
    padding: "0px 15px 0px 0px",
    margin: "0"
  },
  buttonWrapper2: {
    padding: "0px 15px",
    margin: "0"
  },
  itemWrapper: {
    height: "100%"
  },
  height100: {
    height: "100px"
  },
  buttonFollow: {
    fontSize: "28px",
    fontWeight: "300",
    color: "rgba(255,255,255,1)",
    backgroundColor: "rgba(251,78,179,1)",
    border: "1px solid rgba(251,78,179,1)",
    width: "28px",
    height: "28px",
    lineHeight: "28px",
    boxSizing: "content-box",
    display: "inline-block",
    borderRadius: "15px",
    textAlign: "center",
    position: "relative",
    // left: "27px",
    top: "-15px",
    "&.followed": {
        color: "rgba(251,78,179,1)",
        backgroundColor: "rgba(255,255,255,1)",
    }
  }
}));

function Profile() {
  
  let history = useHistory();
  const classes = useStyles();  
  const containerRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  
  const { GlobalContextState } = useContext(GlobalContext);
  const { isPwaMobile } = GlobalContextState;
  const { GlobalTopBarContextDispatch } = useContext(GlobalTopBarContext);
  const { UserProfileContextState, UserProfileContextDispatch } = useContext(UserProfileContext);
  const { userType, isFollow, isSubscribe, profile_user_id, user_information_id, username, handleName, user_description, user_videos, user_house_link, user_house_name, user_followers, user_following, user_subscribers, user_subscriptions, user_image_profile="" } = UserProfileContextState;
  const { DrawerSignUpContextState, DrawerSignUpContextDispatch } = useContext(DrawerSignUpContext);
  const { DrawerMessageContextState, DrawerMessageContextDispatch } = useContext(DrawerMessageContext);
  const { AuthContextState } = useContext(AuthContext);
  const { isAuthenticated, userId, authToken } = AuthContextState;
  const [ tabValues, setTabValues ] = useState(0);
  const [ videos, setVideos ] = useState([]);
  const [ personItems, setPersonItems ] = useState([]);
  const [ pageCount, setPageCount ] = useState(0);
  const [ pageNo, setPageNo ] = useState(0);
  const [ itemCount, setItemCount ] = useState(0)
  const [ itemPerPage, setItemPerPage ] = useState(6);
  const [ filterType, setFilterType ] = useState('all');
  const [ isFollowed, setIsFollowed ] = useState(isFollow)
  const [ isSubscribed, setIsSubscribed ] = useState(isSubscribe)
  const [ followerCount, setFollowerCount ] = useState(user_followers);
  const [ subscriberCount, setSubscriberCount ] = useState(user_subscribers);
  const [ subscriptionsCount, setSubscriptionsCount ] = useState(user_subscriptions);
  const [ followingCount, setFollowingCount ] = useState(user_following);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ isLoaded, setIsLoaded ] = useState(false);
  const [ template, setTemplate ] = useState(null);
  
  const handleChange = (event, value) => {
    event.preventDefault();    
    switch(value) {
      case 0:
        if (template === "creator") {
          resetData(value, 'all');
          getData('all',0);
        } else {          
          resetData(value); 
          getData('following',0); 
        }
        break;
      case 1:
        if (template === "creator") {
          resetData(value, 'premium');
          getData('premium',0);
        } else {            
          resetData(value); 
          getData('subscriptions',0);   
        } 
        break;
      case 2:
        resetData(value, 'public');
        getData('public',0);
        break;     
      default:
          //nothing
    }
    return true;
  };

  const resetData = (val, type) => {
    setTabValues(val);
    if (template === "creator") {
      setFilterType(type);
      setVideos([]);        
      setPageCount(0);
      setPageNo(0);
    } else {
      setPersonItems([]); 
    }
  }

  const followClick = (e) => {
    e.preventDefault()
    const objData = {
      profile_user_id: profile_user_id,
      user_information_id: user_information_id
    }
    FollowService(objData, authToken).then(result => {
      if (result.data.success === 1) {
        setIsFollowed(true)
        setFollowerCount(followerCount + 1) 
      }
      return true;
    }).catch(err => {
        console.log("catch FollowService",err);
    });
  }

  const unFollowClick = (e) => {
    e.preventDefault()
    const objData = {
      profile_user_id: profile_user_id,
      user_information_id: user_information_id
    }
    UnfollowService(objData, authToken).then(result => {
      if (result.data.success === 1) {
        setIsFollowed(false)
        setFollowerCount(followerCount - 1)
      }
      return true;
    }).catch(err => {
        console.log("catch UnfollowService",err);
    });
  }

  const subscribeClick = (e) => {
    e.preventDefault();
    const objData = {
      profile_user_id: profile_user_id,
      user_information_id: user_information_id
    }
    subscribeService(objData, authToken).then(result => {
      if (result.data.success === 1) {
        setIsSubscribed(true)
        setSubscriberCount(subscriberCount + 1)    
      }
      return true;
    }).catch(err => {
        console.log("catch SubscribeService",err);
    });
  }

  const unSubscribeClick = (e) => {
    e.preventDefault();
    const objData = {
      profile_user_id: profile_user_id,
      user_information_id: user_information_id
    }
    UnsubscribeService(objData, authToken).then(result => {
      if (result.data.success === 1) {
        setIsSubscribed(false)
        setSubscriberCount(subscriberCount - 1)    
      }
      return true;
    }).catch(err => {
        console.log("catch UnsubscribeService",err);
    });
  }

  const clickComingSoon = (e) => {
    history.push('/comingsoon');
  }

  const showDrawerClick = (e) => {
    DrawerSignUpContextDispatch({ type: "SHOW_DRAWER" });
  }

  const showDrawerSubscribeClick = (e) => {
    DrawerSignUpContextDispatch({ type: "SHOW_SUBSCRIBE_DRAWER", payload: { subscribeName: handleName } });
  }

  const showDrawerOneOnOneClick = (e) => {
    DrawerSignUpContextDispatch({ type: "SHOW_ONE_ON_ONE_DRAWER", payload: { subscribeName: handleName } });
  }

  const showDrawerMessageClick = (e) => {
    DrawerMessageContextDispatch({ type: "SHOW_DRAWER" });
  }

  const getData = (filter, page) => {
    console.log('getData', filter, page);
    if (profile_user_id !== "") {
      if (template === "creator") {
        setIsLoading(true);
        setIsLoaded(false);
        GetAllLatestVideoByUserIdServiceWithFiltersPublic({userId: profile_user_id, pageNo: page, itemPerPage: itemPerPage, filter: filter, authToken: authToken}).then(result => {
          if (result.data.success === 1) {
            setVideos(result.data.userVideos.videos);
            setPageCount(result.data.userVideos.pageCount);
            setItemCount(result.data.userVideos.count);     
          }
          setPageNo(page + 1, setIsLoading(false)); 
          setIsLoaded(true);
          return true;
        }).catch(err => {
            console.log("catch GetAllLatestVideoByUserIdServiceWithFiltersPublic",err);
        });
      } else {       
        const objData = { profile_user_id: profile_user_id, itemPerPage: 10, pageNo: 0 };
        if (filter === "following") {
          GetFollowing(objData).then(result => {
            if (result.data.success === 1) {
              setPersonItems(result.data.people);
              setPageCount(result.data.pageCount);             
            }
            setPageNo(1, setIsLoading(false));
            setIsLoaded(true);
          }).catch(err => {
              console.log("catch GetFollowing",err);
          });
        } else {
          GetSubscriptions(objData).then(result => {
            if (result.data.success === 1) {           
              setPersonItems(result.data.people);
              setPageCount(result.data.pageCount);   
            }
            setPageNo(1, setIsLoading(false));
            setIsLoaded(true);
          }).catch(err => {
              console.log("catch GetSubscriptions",err);
          });
        }
      }
    }
  }

  const loadNext = () => {
    if (template === "creator") {
      if (pageCount > pageNo && videos.length < itemCount && profile_user_id !== "" && videos.length !== 0) {      
        if (!isLoading) {
          setIsLoading(true);
          GetAllLatestVideoByUserIdServiceWithFiltersPublic({userId: profile_user_id, pageNo: pageNo, itemPerPage: itemPerPage, filter: filterType, authToken: authToken}).then(result => {
            if (result.data.success === 1) {
              setVideos(videos.concat(result.data.userVideos.videos), setPageNo(pageNo + 1, setIsLoading(false)));
            }
            setIsLoading(false);
          }).catch(err => {
              setIsLoading(false);
              console.log("catch GetAllLatestVideoByUserIdServiceWithFiltersPublic",err);
          });
        }
      }
    } else {
      if (pageCount > pageNo && profile_user_id !== "" && personItems.length !== 0) {      
        if (!isLoading) {
          setIsLoading(true);
          const objData = { profile_user_id: profile_user_id, itemPerPage: 10, pageNo: pageNo };
          if (tabValues === 0) {
            GetFollowing(objData).then(result => {
              if (result.data.success === 1) {
                setPersonItems(personItems.concat(result.data.people), setPageNo(pageNo + 1, setIsLoading(false)));                    
              }
              setIsLoading(false);              
            }).catch(err => {
                console.log("catch GetFollowing",err);
            });
          } else {
            GetSubscriptions(objData).then(result => {
              if (result.data.success === 1) {           
                setPersonItems(personItems.concat(result.data.people), setPageNo(pageNo + 1, setIsLoading(false))); 
              }
              setIsLoading(false);              
            }).catch(err => {
                console.log("catch GetSubscriptions",err);
            });
          }
        }
      }  
    }
  }

  useEffect(() => {
    console.log('useEffect GetAllLatestVideoByUserIdServiceWithFiltersPublic');
    if (profile_user_id !== "" && template !== null) {
      if (template === "creator") {
        setIsLoading(true);
        setIsLoaded(false);
        GetAllLatestVideoByUserIdServiceWithFiltersPublic({userId: profile_user_id, pageNo: pageNo, itemPerPage: itemPerPage, filter: filterType, authToken: authToken}).then(result => {
          if (result.data.success === 1) {
            setVideos(result.data.userVideos.videos);
            setPageCount(result.data.userVideos.pageCount);
            setItemCount(result.data.userVideos.count);
          }
          setPageNo(pageNo + 1); 
          setIsLoading(false);
          setIsLoaded(true);
        }).catch(err => {
            console.log("catch GetAllLatestVideoByUserIdServiceWithFiltersPublic",err);
        });
      } else {
        const objData = { profile_user_id: profile_user_id, itemPerPage: 10, pageNo: 0 };        
        if (tabValues === 0) {
          setIsLoading(true);
          setIsLoaded(false);
          GetFollowing(objData).then(result => {
            if (result.data.success === 1) {
              setPersonItems(result.data.people);
              setPageCount(result.data.pageCount);             
            }
            setPageNo(pageNo + 1); 
            setIsLoading(false);
            setIsLoaded(true);
          }).catch(err => {
              console.log("catch GetFollowing",err);
          });
        } else {
          setIsLoading(true);
          setIsLoaded(false);
          GetSubscriptions(objData).then(result => {
            if (result.data.success === 1) {           
              setPersonItems(result.data.people);
              setPageCount(result.data.pageCount);   
            }
            setPageNo(pageNo + 1); 
            setIsLoading(false);
            setIsLoaded(true);
          }).catch(err => {
              console.log("catch GetSubscriptions",err);
          });
        } 
      }
    }
  }, [profile_user_id, template])

  useEffect(() => {
    setFollowerCount(user_followers);
  }, [user_followers])

  useEffect(() => {
    setSubscriberCount(user_subscribers);
  }, [user_subscribers])

  useEffect(() => {
    setSubscriptionsCount(user_subscriptions);
  }, [user_subscriptions])
  
  useEffect(() => {
    setFollowingCount(user_following);
  }, [user_following])

  useEffect(() => {
    setIsFollowed(isFollow);
  }, [isFollow])

  useEffect(() => {
    setIsSubscribed(isSubscribe);
  }, [isSubscribe])

  useEffect(() => {
    console.log('setTemplate userType', userType, userType && template !== null)
    if (userType && template === null) {
      setTemplate(userType);
    }    
  }, [userType])

  useEffect(() => {
    if (handleName !== "") {
      GlobalTopBarContextDispatch({ type: "SET_TITLE", payload: { title: handleName } });
      GlobalTopBarContextDispatch({ type: "SET_HOUSE_NAME", payload: { house: user_house_name } });
    }    
  }, [handleName]) 

  const [anchor, setAnchor] = useState('right');
  const [followersBool, setFollowersBool] = useState(false);
  const [followingBool, setFollowingBool] = useState(false);
  const [subscribersBool, setSubscribersBool] = useState(false);
  const [subscriptionsBool, setSubscriptionsBool] = useState(false);

  const onCloseFollowers = () => {
    setFollowersBool(false)
  }

  const onCloseFollowing = () => {
    setFollowingBool(false)
  }
  
  const onCloseSubscribers = () => {
    setSubscribersBool(false)
  }  

  const onCloseSubscriptions = () => {
    setSubscriptionsBool(false)
  } 
  
  return (
    <>
      <HelmetComponent title={"DTF - Profile Page - " + handleName} description={user_description} />                      
      <Container classes={{ root: classes.containerStyleWhite }} className={isPwaMobile ? " pwaMobile" : ""} ref={containerRef} >
        {
          template !== null
          ?
            <Grid 
              container
              direction="column"
            >
              <Grid 
                container
                direction="row"
              > 
                <Grid item xs={4} align="center" classes={{ root: classes.padding15 }}>                 
                </Grid>
                <Grid item xs={4} align="center" classes={{ root: classes.height100 }}>
                  {
                    user_image_profile !== "" && user_image_profile !== null
                    ?
                      <ImageProfileAvatar image={user_image_profile} alt={handleName} /> 
                    :
                      <ImageProfileAvatar letter={true} alt={handleName} />
                  }
                  {                       
                    template === "creator"
                    ?
                      isAuthenticated 
                      ?
                        userId !== profile_user_id
                        ?
                          isFollowed
                          ?
                            <div className={classes.buttonFollow + " followed"} onClick={(e) => unFollowClick(e)}>
                              +
                            </div>
                          :      
                            <div className={classes.buttonFollow} onClick={(e) => followClick(e)}>
                              +
                            </div>
                        :
                          null
                      :
                        <div className={classes.buttonFollow} onClick={(e) => showDrawerClick(e)}>
                          +
                        </div>   
                    :
                      null     
                  } 
                </Grid>
                <Grid item xs={4} align="center" classes={{ root: classes.padding15 }}>
                </Grid>
                {
                  template === "creator"
                  ? 
                    <>
                      <Grid item xs={4} classes={{ root: classes.marginTopBottom }} onClick={() => setFollowingBool(true)}> 
                        <Typography variant={"body1"} align="center" classes={{ root: classes.dashboardValue }}>{ convertCountToString(followingCount) }</Typography>
                        <Typography variant={"body2"} align="center" classes={{ root: classes.dashboardLabel }}>Following</Typography>
                      </Grid>
                      <Grid item xs={4} classes={{ root: classes.marginTopBottom }} onClick={() => setFollowersBool(true)}>
                        <Typography variant={"body1"} align="center" classes={{ root: classes.dashboardValue }}>{ convertCountToString(followerCount) }</Typography>
                        <Typography variant={"body2"} align="center" classes={{ root: classes.dashboardLabel }}>Follower{followerCount === 1 ? '' : 's'}</Typography>
                      </Grid>
                      <Grid item xs={4} classes={{ root: classes.marginTopBottomNoBorder }} onClick={() => setSubscribersBool(true)}>
                        <Typography variant={"body1"} align="center" classes={{ root: classes.dashboardValue }}>{ convertCountToString(subscriberCount) }</Typography>
                        <Typography variant={"body2"} align="center" classes={{ root: classes.dashboardLabel }}>Subscriber{subscriberCount === 1 ? '' : 's'}</Typography>
                      </Grid>
                    </> 
                  :
                    <>
                      <Grid item xs={6} classes={{ root: classes.marginTopBottomPaddingLeft }}> 
                        <Typography variant={"body1"} align="center" classes={{ root: classes.dashboardValue }}>{ convertCountToString(followingCount) }</Typography>
                        <Typography variant={"body2"} align="center" classes={{ root: classes.dashboardLabel }}>Following</Typography>
                      </Grid>
                      <Grid item xs={6} classes={{ root: classes.marginTopBottomPaddingRight }}>
                        <Typography variant={"body1"} align="center" classes={{ root: classes.dashboardValue }}>{ convertCountToString(subscriptionsCount) }</Typography>
                        <Typography variant={"body2"} align="center" classes={{ root: classes.dashboardLabel }}>Subscription{subscriptionsCount === 1 ? '' : 's'}</Typography>
                      </Grid>
                    </>
                }               
                { 
                  userId !== profile_user_id
                  ?
                    <>
                      <Grid item xs={template === "creator" ? 4 : 12} align="center" classes={{ root: template === "creator" ? classes.buttonWrapperLeft : classes.buttonWrapper2 }}>                
                        { 
                          isAuthenticated
                          ?
                            <Button variant="outlined" color="primary" classes={{ root: classes.dashboardBtn, label: classes.dashboardBtnLabel }} onClick={(e) => showDrawerMessageClick(e)}>
                              <span className={classes.dashboardIcon}>
                                <PencilWhite />
                              </span>   
                              Message
                            </Button>
                          :
                            <Button variant="outlined" color="primary" classes={{ root: classes.dashboardBtn, label: classes.dashboardBtnLabel }} onClick={(e) => showDrawerClick(e)}>
                              <span className={classes.dashboardIcon}>
                                <PencilWhite />
                              </span>
                              Message
                            </Button>
                        }
                      </Grid>
                      {
                        template === "creator"
                        ?
                          <>
                            <Grid item xs={4} align="center" classes={{ root: classes.buttonWrapper }}>
                              { 
                                isAuthenticated
                                ?
                                    isSubscribed
                                    ?
                                      <Button variant="outlined" color="primary" classes={{ root: classes.dashboardBtn, label: classes.dashboardBtnLabel }} onClick={(e) => unSubscribeClick(e)}>
                                        UNVIP?
                                      </Button>
                                    :
                                      <Button variant="outlined" color="primary" classes={{ root: classes.dashboardBtn, label: classes.dashboardBtnLabel }} onClick={(e) => subscribeClick(e)}>
                                        <span className={classes.dashboardIcon2}>
                                          <LockedWhite />
                                        </span>  
                                        VIP
                                      </Button>
                                :
                                  <Button variant="outlined" color="primary" classes={{ root: classes.dashboardBtn, label: classes.dashboardBtnLabel }} onClick={(e) => showDrawerClick(e)}>                            
                                    <span className={classes.dashboardIcon2}>
                                      <LockedWhite />
                                    </span>  
                                    VIP
                                  </Button>
                              }
                            </Grid>
                            <Grid item xs={4} align="center" classes={{ root: classes.buttonWrapperRight }}>
                              { 
                                isAuthenticated
                                ?
                                  <Button variant="outlined" color="primary" classes={{ root: classes.dashboardBtn, label: classes.dashboardBtnLabel }} onClick={(e) => showDrawerOneOnOneClick(e)}>
                                    <span className={classes.dashboardIcon2}>
                                      <HornWhite />
                                    </span>  
                                    Holla
                                  </Button>
                                :
                                  <Button variant="outlined" color="primary" classes={{ root: classes.dashboardBtn, label: classes.dashboardBtnLabel }} onClick={(e) => showDrawerClick(e)}>
                                    <span className={classes.dashboardIcon2}>
                                      <HornWhite />
                                    </span>  
                                    Holla
                                  </Button>
                              }                
                            </Grid>
                          </>
                        :
                          null
                      }
                    </>
                  :
                    null
                }
                <Grid item xs={12} classes={{ root: classes.marginTopBottomDefault }}>
                  <Typography variant={"body1"} classes={{ body1: classes.description }}>                  
                    {user_description}
                  </Typography>
                </Grid>  
                <Grid item xs={12}>
                  {
                    template === "creator"
                    ?
                      <Tabs
                        value={tabValues}
                        onChange={handleChange}
                        centered
                        classes={{ root: classes.tabsWrapper }}
                        disableFocusRipple={true}
                        disableRipple={true}
                      >
                        <Tab label="All" selected={(tabValues === 0) ? true : false} value={0} classes={{ root: classes.tabs33, wrapper: (tabValues === 0) ? classes.muiTabWrapperSelect : classes.muiTabWrapper }} />
                        <Tab label="Premium" selected={(tabValues === 1) ? true : false} value={1} classes={{ root: classes.tabs33, wrapper: (tabValues === 1) ? classes.muiTabWrapperSelect : classes.muiTabWrapper }} />
                        <Tab label="Free" selected={(tabValues === 2) ? true : false} value={2} classes={{ root: classes.tabs33, wrapper: (tabValues === 2) ? classes.muiTabWrapperSelectLast : classes.muiTabWrapperLast }} />
                      </Tabs>
                    :
                      <Tabs
                        value={tabValues}
                        onChange={handleChange}
                        centered
                        classes={{ root: classes.tabsWrapper }}
                        disableFocusRipple={true}
                        disableRipple={true}
                      >
                        <Tab label="Following" selected={(tabValues === 0) ? true : false} value={0} classes={{ root: classes.tabs50, wrapper: (tabValues === 0) ? classes.muiTabWrapperSelect : classes.muiTabWrapper }} />
                        <Tab label="Subscriptions" selected={(tabValues === 1) ? true : false} value={1} classes={{ root: classes.tabs50, wrapper: (tabValues === 1) ? classes.muiTabWrapperSelectLast : classes.muiTabWrapperLast }} />
                      </Tabs>
                  }
                </Grid>
                <Grid item container xs={12} className={classes.itemWrapper}>   
                  {
                    template === "creator"
                    ?                      
                      videos.length !== 0
                      ?      
                        videos.map((item, index) => {
                            return (
                              <Grid item xs={4} key={"image-"+index} className={ classes.imageWrapper }>
                                <LinkButton url={"/public/list/videos/"+profile_user_id+"/"+item._id+"/"+filterType} directionBool={true}>  
                                  <ImageVideoItem isFacingMode={item.isFacingMode} image={item.video_src} alt={item.video_name} isVideo={item.video_information ? item.video_information.resource_type === "video" ? true : false : false} />
                                </LinkButton>
                                {
                                  item.premium === true
                                  ?
                                    <div className={ classes.premiumLockWrapper }>
                                      <span className={ classes.premiumLock + (isSubscribed ? " subscribed" : "")}>
                                        <LockWhiteCirclePink />
                                      </span>                              
                                    </div>
                                  :
                                    null
                                }                          
                                <div className={ classes.iconWrapper }>
                                  <div className={ classes.iconLeftWrapper }>
                                    <span className={ classes.iconLike }>
                                      <HeartSmallBorderWhite />
                                    </span>
                                    <span className={ classes.iconText }>{convertCountToString(item.likes)}</span>   
                                  </div>
                                  <div className={ classes.iconRightWrapper }>      
                                    <span className={ classes.iconView }>
                                      <EyeWhiteBorder />
                                    </span>
                                    <span className={ classes.iconText }>{convertCountToString(item.views)}</span>                               
                                  </div>
                                </div>  
                              </Grid>
                            )                                        
                        })     
                      :
                        isLoaded && !isLoading 
                        ?
                          <TabNullCase />
                        :
                          null
                    :
                      personItems.length !== 0
                      ?      
                        personItems.map((item, index) => {
                          if (tabValues === 0) {
                            if (item.follow_user !== null) {
                              return (
                                <Grid item xs={4} key={"video-grid-"+index} className={ classes.videoWrapper }>      
                                  <LinkButton url={"/user/"+item.follow_user.handlename} directionBool={true}>  
                                    <ImageProfileItem image={item.follow_user_information.user_image_profile} alt={item.follow_user.username} />
                                  </LinkButton>
                                </Grid>
                              ) 
                            } else {
                              return null
                            }    
                          } else {
                            if (item.subscribe_user !== null) {
                              console.log('subscribe_user', item, index)
                              return (
                                <Grid item xs={4} key={"video-grid-"+index} className={ classes.videoWrapper }>     
                                  <LinkButton url={"/user/"+item.subscribe_user.handlename} directionBool={true}>  
                                    <ImageProfileItem image={item.subscribe_user_information.user_image_profile} alt={item.subscribe_user.username} />
                                  </LinkButton>   
                                </Grid>
                              )
                            } else {
                              return null
                            }
                          }                                                       
                        })
                      :
                        isLoaded && !isLoading
                        ?
                          <TabNullCase />
                        :
                          null
                  }
                </Grid>                          
              </Grid> 
            </Grid>
          :
            null
        }        
        {
          isAuthenticated
          ?
            <DrawerMessage />
          :
            null
        }
        {
          template === "creator"
          ?
            <>
              <SwipeFollowers isOwner={false} type={"followers"} pId={profile_user_id} anchor={anchor} isOpen={followersBool} onCloseHandler={onCloseFollowers} />      
              <SwipeFollowing isOwner={false} type={"following"} pId={profile_user_id} anchor={anchor} isOpen={followingBool} onCloseHandler={onCloseFollowing} />      
              <SwipeSubscribers isOwner={false} type={"subscribers"} pId={profile_user_id} anchor={anchor} isOpen={subscribersBool} onCloseHandler={onCloseSubscribers} />      
              <SwipeSubscriptions isOwner={true} type={"subscriptions"} pId={profile_user_id} anchor={anchor} isOpen={subscriptionsBool} onCloseHandler={onCloseSubscriptions} />
            </>
          :
            null
        }     
        <InfiniteScroll parentRef={containerRef} method={loadNext} margin={vh + "px"} />
      </Container>          
    </>
  );
}

export default Profile;