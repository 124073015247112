import React, { createContext, useReducer, useEffect } from "react";
import { FollowContextReducer } from '../reducers/FollowContextReducer';
import { Users } from '../testData/Users';

export const FollowContext = createContext();

const initialState = {
    count: 0,
    followers: []
}

const FollowContextProvider = (props) => {
    const [FollowContextState, FollowContextDispatch] = useReducer(FollowContextReducer,initialState);

    useEffect(() => {
        FollowContextDispatch({ type: "SET_INIT_FOLLOWERS", payload: { count: Users.length, followers: Users }})
    }, [])

    return (
        <FollowContext.Provider value={{FollowContextState, FollowContextDispatch}}>
            {props.children}
        </FollowContext.Provider>
    );
}

export default FollowContextProvider;