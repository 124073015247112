import React, { useContext, useRef } from 'react';
import { motion } from "framer-motion";
import { FramerContext } from '../contexts/FramerContext';
import { GlobalContext } from '../contexts/GlobalContext';

const FramerMotionContainer = (props) => {
  const { state, changePageLoadInitial } = useContext(FramerContext);
  const { GlobalContextState, GlobalContextDispatch } = useContext(GlobalContext);
  const { showCircleSpinner, animationComplete } = GlobalContextState;

  // const pageDirection = (framerContext.pageDirection ? framerContext.pageLtr : framerContext.pageRtl);
  const pageDirection = (state.pageLoadInitial ? state.pageDefualt : state.pageDirection ? state.pageLtr : state.pageRtl);
  
  function onComplete() {
    changePageLoadInitial(true);
    if (showCircleSpinner === true) {
      GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
    }   
    GlobalContextDispatch({ type: "SET_ANIMATION_COMPLETE", payload: { animationComplete : true }}); 
    const timer = setTimeout(() => {
      GlobalContextDispatch({ type: "SET_ANIMATION_COMPLETE", payload: { animationComplete : false }});
    }, 3000);
    return () => clearTimeout(timer); 
  }

  return (
    <motion.div
      style={state.pageStyle}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageDirection}
      transition={state.pageTransition}
      onAnimationComplete={onComplete}
    >      
    { props.children }
    </motion.div>
  );
}

export default FramerMotionContainer;