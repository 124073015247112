import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import LinkText from "./LinkText";
import LinkButton from './LinkButton';
import { defaultGradientPurpleBtn, defaultTransparentBtn } from '../styles/defaultButtonStyles.2';
import { DrawerSignUpContext } from '../contexts/DrawerSignUpContext';
import { AuthContext } from '../contexts/AuthContext';
import { VideoServiceContext } from '../contexts/VideoServiceContext';
import { FramerContext } from '../contexts/FramerContext';

const useStyles = makeStyles((theme) => ({  
  defaultGradientPurpleBtn: defaultGradientPurpleBtn,
  defaultTransparentBtn: defaultTransparentBtn, 
  image: {
    top: "50%",
    left: "50%",
    minHeight: "100%",
    minWidth: "100%",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    zIndex: "-1"
  },
  paperAnchorBottom: {
    padding: "20px 20px 40px",
    borderTopRightRadius: "20px",
    borderTopLeftRadius: "20px"
  },
  headerLabel: {
    fontSize: '20px',
    fontWeight: '600',
    color: 'rgba(20,24,34,1)',
    display: 'block',
    margin: '0'
  },
  headerLabelPurlpe: {
    fontSize: '20px',
    fontWeight: '600',
    color: 'rgba(98,74,251,1)'
  },
  subText: {
    display:'block',
    margin: '20px auto',
    fontSize: '16px',
    color: 'rgba(139,140,144,1)'
  },
  labelBtn: {
    fontSize: '16px',
    fontWeight: '600',
    textTransform: 'none'
  },
  closeIcon: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    fontSize: '18px',
    color: 'rgba(0,0,0,1)'
  }
}));

function DrawerUpgradeSignUp(props) {
  const classes = useStyles();
  let history = useHistory();
  const { AuthContextState, AuthContextDispatch } = useContext(AuthContext);
  const { isAuthenticated } = AuthContextState;
  const { DrawerSignUpContextState, DrawerSignUpContextDispatch } = useContext(DrawerSignUpContext);
  const { showUpgradeDrawer } = DrawerSignUpContextState;
  const { VideoServiceContextState, VideoServiceContextDispatch } = useContext(VideoServiceContext);  
  const { videoId } = VideoServiceContextState;
  const { toggleDirection } = useContext(FramerContext);
  const { fromCreateVideo=false, continueUrl="/account/profile" } = props;
  
  useEffect(() => {
  }, [showUpgradeDrawer]) 

  const handleClick = (e) => {    
    DrawerSignUpContextDispatch({ type: "HIDE_UPGRADE_DRAWER", payload: false });
     toggleDirection(true,true);
    if (fromCreateVideo) {
      history.push(continueUrl);
    } else {
      if (videoId !== null) {
        history.push(`/edit/content/${videoId}/true`);
      }
    }    
  }

  const handleUpgradeClick = () => {
    DrawerSignUpContextDispatch({ type: "HIDE_UPGRADE_DRAWER", payload: false });    
    AuthContextDispatch({ type: "UPDATE_UPGRADE_FLOW", payload: { upgradeFlow: true } });
     toggleDirection(true,true);
    history.push('/account/social-information');
  }

  return (    
    <Drawer classes={{ paperAnchorBottom: classes.paperAnchorBottom }} transitionDuration={{ enter: 100, exit: 100 }} anchor={'bottom'} open={showUpgradeDrawer}>
        <CloseIcon classes={{ root: classes.closeIcon }} onClick={(e) => handleClick(e)} />
        <Typography variant={"body2"} component={"span"} classes={{ root: classes.headerLabel }}>
          Become a Creator
        </Typography>
        <Typography variant={"body2"} component={"span"} classes={{ root: classes.subText }}>
          Signup as a GLOSS creator to post Full Video
        </Typography>
        <Button variant="outlined" color="primary" classes={{ root: classes.defaultGradientPurpleBtn, label: classes.labelBtn }}  onClick={(e) => handleUpgradeClick(e)}>
          Get a GLOSS Creator Account
        </Button>        
    </Drawer>
  );
}

export default DrawerUpgradeSignUp;