export const ContentContextReducer = (state, action) => {
    switch(action.type){
        case 'SET_NEW_CHANGE':
            return {
                newChange: action.payload.newChange,
                newChangeId: action.payload.newChangeId
            }
        case 'SET_INIT_CONTENT_DATA': 
            console.log('SET_INIT_CONTENT_DATA', action.payload.newDescription)
            return {
                ...state,
                newDescription: action.payload.newDescription,
                checkedFacebook: action.payload.checkedFacebook,
                checkedInstagram: action.payload.checkedInstagram,
                checkedTwitter: action.payload.checkedTwitter,
                checkedPublic: action.payload.checkedPublic,
                checkedPrivate: action.payload.checkedPrivate,
                checkedActive: action.payload.checkedActive
            }
        case 'SET_DESCRIPTION_DATA':
            return { 
                ...state,
                newDescription: action.payload.newDescription,
            }
        case 'SET_PUBLIC_CHECK':
            return { 
                ...state,
                checkedPrivate: action.payload.checkedPrivate,
                checkedPublic: action.payload.checkedPublic,
                checkedActive: action.payload.checkedActive
            }
        case 'SET_PRIVATE_CHECK':
            return { 
                ...state,
                checkedPrivate: action.payload.checkedPrivate,
                checkedPublic: action.payload.checkedPublic,
                checkedActive: action.payload.checkedActive
            }
        case 'SET_ACTIVE_CHECK':
            return { 
                ...state,
                checkedPrivate: action.payload.checkedPrivate,
                checkedPublic: action.payload.checkedPublic,
                checkedActive: action.payload.checkedActive
            }
        case 'SET_FACEBOOK_CHECK':
            return { 
                ...state,
                checkedFacebook: action.payload.checkedFacebook
            }
        case 'SET_INSTAGRAM_CHECK':
            return { 
                ...state,
                checkedInstagram: action.payload.checkedInstagram
            }
        case 'SET_TWITTER_CHECK':
            return { 
                ...state,
                checkedTwitter: action.payload.checkedTwitter
            }    
        case 'RESET_CONTENT_DATA':
            return { 
                ...state,
                newDescription: null,
                checkedFacebook: false,
                checkedInstagram: false,
                checkedTwitter: false,
                checkedPublic: false,
                checkedPrivate: false,
                checkedActive: false,
                newChange: false,
                newChangeId: null
            }       
        default:
            return state    
    }
}