import React, { useState, useContext } from 'react';
import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { feedDescriptionWrapper, feedDescriptionText, feedButtonIcon, feedArrowActiveIcon, feedAvatarName, feedFollowButton } from '../styles/defaultFeedDescriptionStyles';
import { DrawerSignUpContext } from '../contexts/DrawerSignUpContext';
import { GlobalContext } from '../contexts/GlobalContext';
import { AuthContext } from '../contexts/AuthContext';
import { FollowService } from '../services/UserActionsServices';
import CameraIcon from './CameraIcon.3';
import LinkButton from './LinkButton';
import FeedImageAvatar from './FeedImageAvatar.2';

const useStyles = makeStyles((theme) => ({    
    userNameText: {
        fontSize: "12px",
        color: "rgba(255,255,255,1)",
        textShadow: "rgb(0 0 0) 1px 1px",
        position: "relative"
    },
    buttonIcon: {
        background: "none",
        border: "none",
        color: "rgba(255,255,255,1)",
        margin: "0",
        padding: "0",
    },
    feedAvatarWrapper: {
        position: "relative",
        overflow: "hidden",
        height: "auto"
    },
    feedAvatarName: feedAvatarName,
    feedFollowButton: feedFollowButton,
    feedDescriptionText: feedDescriptionText,
    feedArrowActiveIcon: feedArrowActiveIcon,
    feedButtonIcon: feedButtonIcon,
    feedDescriptionWrapper: feedDescriptionWrapper
}));

function FeedDescription(props) {
    const { user, userInformation={}, noBottomBar=false, isSideBarWhite=false, item, isPlaying=false, handleMuteClick=()=>{}, isMuted=true } = props;
    const { video_description = null, video_information = null } = item;
    const classes = useStyles();
    const { DrawerSignUpContextState, DrawerSignUpContextDispatch } = useContext(DrawerSignUpContext);
    const { showDrawer } = DrawerSignUpContextState;
    const { AuthContextState } = useContext(AuthContext);
    const { isAuthenticated, authToken, userId: authUserId } = AuthContextState;
    const { GlobalContextState, GlobalContextDispatch } = useContext(GlobalContext);
    const { iOSInstaller, isPwaMobile, isMobile } = GlobalContextState; 
    const { _id = "", comments = 0, likes = 0, isLike = false } = user;
    const { username = "", userType = "user", handlename = "", _id: userId } = user;
    const userInformationPlaceHolder = { _id: "", isFollow: false, isSubscribe: false, user_image_profile: "", isPlaceHodler: true };
    const { _id: userInfoId, isFollow=false, isSubscribe=false, user_image_profile="", isPlaceHodler=false } = userInformation || userInformationPlaceHolder;
    const [ isFollowed, setIsFollowed ] = useState(isFollow);
    const [ descriptionBool, setDescriptionBool ] = useState(false);

    const showDrawerClick = (e) => {
        DrawerSignUpContextDispatch({ type: "SHOW_DRAWER", payload: true });
    }

    const followClick = (e) => {
        e.preventDefault()
        const objData = {
            profile_user_id: userId,
            user_information_id: userInfoId
        }
        FollowService(objData, authToken).then(result => {
            if (result.data.success === 1) {
                setIsFollowed(true)
            }
            return true;
        }).catch(err => {
            console.log("catch FollowService",err);
        });
    }

    return (
        <Container classes={{ root: classes.feedDescriptionWrapper }} className={(isMobile ? " mobile" : "") + (isPwaMobile ? " pwa" : " not-pwa") + (iOSInstaller ? " hideVideoBottomBar" : "")}>
            <div className={ classes.feedAvatarWrapper }>
                <LinkButton url={`/user/${handlename}`} directionBool={true} classes={classes.buttonIcon}>
                    {
                        userInformation
                        ?
                            userInformation.user_image_profile !== "" && userInformation.user_image_profile !== null
                            ?                                   
                                <FeedImageAvatar image={user_image_profile} alt={handlename} />
                            :
                                <FeedImageAvatar letter={true} alt={handlename} />
                        :
                            <FeedImageAvatar letter={true} alt={handlename} />
                    }
                    <div className={ classes.feedAvatarName }>
                        @{handlename}
                    </div>
                </LinkButton>    
                {
                    !isPlaceHodler
                    ?
                        isAuthenticated
                        ?
                            userId !== authUserId
                            ?
                                isFollowed
                                ?
                                    null
                                :    
                                    <div className={classes.feedFollowButton} onClick={(e) => followClick(e)}>
                                        Follow
                                    </div>
                            :
                                null
                        :
                            <div className={classes.feedFollowButton} onClick={(e) => showDrawerClick(e)}>
                                Follow
                            </div>
                    : 
                        null      
                }                
            </div>
            { 
                isAuthenticated
                ?
                    <>
                        <Typography variant={"body2"} align="left" classes={{ root: classes.feedDescriptionText }} className={ descriptionBool ? " show" : ""}>                           
                            {
                                video_information
                                ?
                                    video_information.resource_type === "image"
                                    ?
                                        <>
                                            <CameraIcon />&nbsp;
                                        </>
                                    :
                                        null
                                :
                                    null
                            }                            
                            {video_description}
                        </Typography>
                        {
                            video_description && video_description.length >= 95
                            ?
                                <IconButton
                                    aria-label="Show full description"
                                    onClick={() => setDescriptionBool(!descriptionBool)}
                                    onMouseDown={(e) => { e.preventDefault() }}
                                    classes={{ root: classes.feedArrowActiveIcon }}
                                >
                                    {
                                            descriptionBool
                                        ?
                                            <KeyboardArrowDownIcon classes={{ root: classes.feedArrowActiveIcon }} />
                                        :
                                            <ChevronRightIcon classes={{ root: classes.feedArrowActiveIcon }} />
                                    }               
                                </IconButton>
                            :
                                null 
                        }
                    </>
                :                         
                    <>
                        <Typography variant={"body2"} align="left" classes={{ root: classes.feedDescriptionText }}  className={ descriptionBool ? " show" : ""} onClick={(e) => showDrawerClick(e)}>
                            {
                                video_information
                                ?
                                    video_information.resource_type === "image"
                                    ?
                                        <>
                                            <CameraIcon />&nbsp;
                                        </>
                                    :
                                        null
                                :
                                    null
                            }
                            {video_description}
                        </Typography>
                        {
                            video_description && video_description.length >= 95
                            ?
                                <IconButton
                                    aria-label="Show full description"
                                    onClick={() => setDescriptionBool(!descriptionBool)}
                                    onMouseDown={(e) => { e.preventDefault() }}
                                    classes={{ root: classes.feedArrowActiveIcon }}
                                >
                                    {
                                            descriptionBool
                                        ?
                                            <KeyboardArrowDownIcon classes={{ root: classes.feedArrowActiveIcon }} />
                                        :
                                            <ChevronRightIcon classes={{ root: classes.feedArrowActiveIcon }} />
                                    }               
                                </IconButton>
                            :
                                null 
                        }                        
                    </>
            }          
        </Container>
    );
}

export default React.memo(FeedDescription);