import React, { createContext, useReducer, useEffect, useContext } from "react";
import { useHistory, useParams, Redirect } from "react-router-dom";
import { UserProfileContextReducer } from '../reducers/UserProfileContextReducer';
import { GlobalContext } from './GlobalContext';
import { GetUserProfileByHandleService } from "../services/UserServices";
import { UserProfileInitialState } from "../constants/UserProfileInitialState";

export const UserProfileContext = createContext();

const initialState = UserProfileInitialState;

const UserProfileContextProvider = (props) => {
    const { userHandle } = useParams();   
    const { GlobalContextState } = useContext(GlobalContext);
    const { isLoading, initialLoad } = GlobalContextState;
    const [UserProfileContextState, UserProfileContextDispatch] = useReducer(UserProfileContextReducer,initialState);

    useEffect(() => {        
        GetUserProfileByHandleService(userHandle).then(result => {
            if (result.data.success === 1) {
                UserProfileContextDispatch({ type: "SET_USER_PROFILE", payload: result.data });
            } 
        }).catch(err => {
            console.log("catch GetUserProfileByHandleService",err.response.data,err.response.status);
        });
    }, [])

    return (
        <UserProfileContext.Provider value={{UserProfileContextState, UserProfileContextDispatch}}>
            {props.children}
        </UserProfileContext.Provider>
    );
}

export default UserProfileContextProvider;