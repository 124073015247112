import { useEffect, useRef } from "react";

// https://codesandbox.io/s/28ivn?file=/src/useScroll.js:0-591
export const useScroll = (parentRef, childRef, callback, callback2, margin) => {
  const observer = useRef();

  useEffect(() => {
    const options = {
      root: parentRef.current,
      rootMargin: margin || "0px",
      threshold: 0.5
    };
    const currentChildRef = childRef.current;

    observer.current = new IntersectionObserver(([target]) => {  
      if (target.isIntersecting) {
        callback();
      }
    }, options);

    observer.current.observe(currentChildRef);

    return () => observer.current.unobserve(currentChildRef);
  }, [callback, parentRef, childRef]);
}