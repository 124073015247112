import React from 'react';

export default class ButtonChartWhite extends React.Component {
    render() {
        return (
            <svg id="Chart" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                <path id="Combined-Shape" d="M12.923,0C15.96,0,18,2.132,18,5.3V12.7c0,3.172-2.04,5.3-5.077,5.3H5.077C2.04,18,0,15.868,0,12.7V5.3C0,2.132,2.04,0,5.077,0Zm0,1.256H5.077c-2.321,0-3.821,1.588-3.821,4.048V12.7c0,2.46,1.5,4.048,3.821,4.048h7.846c2.322,0,3.821-1.588,3.821-4.048V5.3C16.744,2.844,15.245,1.256,12.923,1.256Zm-7.8,5.611a.628.628,0,0,1,.628.628v5.743a.628.628,0,0,1-1.256,0V7.5A.628.628,0,0,1,5.125,6.867ZM9.032,4.118a.628.628,0,0,1,.628.628v8.492a.628.628,0,1,1-1.256,0V4.746A.628.628,0,0,1,9.032,4.118ZM12.875,9.9a.628.628,0,0,1,.628.628v2.708a.628.628,0,1,1-1.256,0V10.53A.628.628,0,0,1,12.875,9.9Z" fill="#fff" fill-rule="evenodd"/>
            </svg>     
        )
    }
}