import React from 'react';

export default class FlipIcon extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="33" viewBox="0 0 38 33">
                <defs>
                    <clipPath id="clip-path">
                    <rect id="Rectangle_9" data-name="Rectangle 9" width="38" height="33" transform="translate(38 512) rotate(180)" fill="#fff"/>
                    </clipPath>
                </defs>
                <g id="Mask_Group_3" data-name="Mask Group 3" transform="translate(0 -479)" clip-path="url(#clip-path)">
                    <g id="Layer_2" data-name="Layer 2" transform="translate(2.103 479)">
                    <g id="invisible_box" data-name="invisible box">
                        <rect id="Rectangle_1535" data-name="Rectangle 1535" width="33" height="33" transform="translate(-0.103)" fill="none"/>
                    </g>
                    <g id="icons_Q2" data-name="icons Q2" transform="translate(2.779 1.374)">
                        <path id="Path_2235" data-name="Path 2235" d="M56.933,29.021a15.287,15.287,0,0,1-28.977,6.74,1.385,1.385,0,1,1,2.432-1.32,12.369,12.369,0,0,0,11.257,7.088,12.508,12.508,0,1,0-9.311-20.846h3.683a1.459,1.459,0,0,1,1.459,1.181,1.39,1.39,0,0,1-1.39,1.6H29.138a1.39,1.39,0,0,1-1.39-1.39V15.193a1.459,1.459,0,0,1,1.181-1.459,1.39,1.39,0,0,1,1.6,1.39v3.4A15.287,15.287,0,0,1,56.933,29.021Z" transform="translate(-27.748 -13.718)" fill="#fff"/>
                    </g>
                    </g>
                </g>
            </svg>    
        )
    }
}