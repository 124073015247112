export const DrawerCommentContextReducer = (state, action) => {
    switch(action.type){
        case 'SHOW_DRAWER':
            return { 
                ...state,
                videoId: action.payload.videoId,
                currentIndex: action.payload.currentIndex,
                showDrawerComment: true
            }
        case 'HIDE_DRAWER':
            return {
                ...state,
                videoId: "", 
                currentIndex: null,
                showDrawerComment: false
            } 
        case 'POST_COMMENT':
            return {
                ...state,
                comment: action.payload.comment,
                videoId: action.payload.videoId,
                post: action.payload.post
            } 
        case 'RESET_POST_COMMENT':
            return {
                ...state,
                comment: "",
                videoId: "",
                post: false
            } 
        case 'DELETE_COMMENT':
            return {
                ...state,
                commentId: action.payload.commentId,
                videoId: action.payload.videoId,
                deleteComment: action.payload.deleteComment
            }
        case 'ADD_COMMENT':
            return {
                ...state,
                commentsCount: parseInt(state.commentsCount) + 1,
                indexUpdateCommentCount: state.indexUpdateCommentCount.concat([{
                    index: action.payload.currentIndex,
                    isAdd: true
                }])
            }    
        case 'SUBSTRACT_COMMENT':
            return {
                ...state,
                commentsCount: parseInt(state.commentsCount) - 1,
                indexUpdateCommentCount: state.indexUpdateCommentCount.concat([{
                    index: action.payload.currentIndex,
                    isAdd: false
                }])
            } 
        case 'RESET_INDEX_UPDATE_COMMENT_COUNT':
            return {
                ...state,
                indexUpdateCommentCount: []
            }    
        case 'RESET_DELETE_COMMENT':
            return {
                ...state,
                commentId: "",
                videoId: "",
                deleteComment: false
            } 
        case 'LIKE_COMMENT':
            return {
                ...state,
                commentId: action.payload.commentId,
                videoId: action.payload.videoId,
                likeComment: action.payload.likeComment
            }  
        case 'RESET_LIKE_COMMENT':
            return {
                ...state,
                commentId: "",
                videoId: "",
                likeComment: false
            }
        case 'UNLIKE_COMMENT':
            return {
                ...state,
                commentId: action.payload.commentId,
                videoId: action.payload.videoId,
                unLikeComment: action.payload.unLikeComment
            }  
        case 'RESET_UNLIKE_COMMENT':
            return {
                ...state,
                commentId: "",
                videoId: "",
                unLikeComment: false
            }        
        case 'GET_COMMENT':
            return {
                ...state,
                get: true,
                pageNo: action.payload.pageNo,
                itemPerPage: action.payload.itemPerPage
            } 
        case 'SET_COMMENTS':   
            return {
                ...state,
                comments: action.payload.comments,
                pageNo: action.payload.pageNo,
                itemPerPage: action.payload.itemPerPage,
                commentsCount: action.payload.commentsCount,
                pageCount: action.payload.pageCount,
                initialCommnentLoad: false
            }
        case 'RESET_GET_COMMENT':
            return {
                ...state,
                get: false
            }             
        case 'ADD_SET_COMMENT':   
            return {
                ...state,
                comments: action.payload.comments.concat(state.comments)
            }
            // return {
            //     ...state,
            //     comments: state.comments.concat(action.payload.comments)
            // }         
        case 'INFINITE_GET_COMMENT':
            return {
                ...state,
                getInfinite: true,
                pageNo: action.payload.pageNo,
                itemPerPage: action.payload.itemPerPage
            } 
        case 'INFINITE_SET_COMMENTS':   
            return {
                ...state,
                comments: state.comments.concat(action.payload.comments),
                pageNo: action.payload.pageNo,
                itemPerPage: action.payload.itemPerPage,
                commentsCount: action.payload.commentsCount,
                pageCount: action.payload.pageCount
            }    
        case 'RESET_INFINITE_GET_COMMENT':
            return {
                ...state,
                getInfinite: false
            }            
        case 'RESET_COMMENT':
            return {
                ...state, 
                initialCommnentLoad: true,
                showDrawerComment: false,    
                currentIndex: null,
                comment: "",
                commentId: "",
                videoId: "",
                likeComment: false,
                unLikeComment: false,
                deleteComment: false,
                post: false,
                get: false,
                comments: [],
                pageNo: 0,
                itemPerPage: 10,
                commentsCount: 0,
                pageCount: 0
            }       
        default:
            return state    
    }
}