//https://reactjs.org/docs/hooks-reference.html#usereducer
import React, { createContext, useReducer, useEffect, useContext } from "react";
import { useSnackbar } from 'notistack';
import { DrawerCommentContextReducer } from '../reducers/DrawerCommentContextReducer';
import { DrawerCommentInitialState } from "../constants/DrawerCommentInitialState";
import { GetCommentVideo } from "../services/VideoServices"
import { PostCommentVideo, DeleteCommentVideo, LikeCommentVideo, UnLikeCommentVideo } from "../services/UserActionsServices";
import { AuthContext } from '../contexts/AuthContext';
import { GlobalContext } from '../contexts/GlobalContext';

export const DrawerCommentContext = createContext();

const initialState = DrawerCommentInitialState;

const DrawerCommentContextProvider = (props) => {
    const { AuthContextState } = useContext(AuthContext);
    const { isAuthenticated, authToken, userId } = AuthContextState; 
    const { GlobalContextDispatch } = useContext(GlobalContext);
    const [DrawerCommentContextState, DrawerCommentContextDispatch] = useReducer(DrawerCommentContextReducer,initialState);
    const { indexUpdateCommentCount = [], currentIndex = null, comment="", comments=[], commentId="", videoId="", post=false, get=false, getInfinite=false, likeComment=false, unLikeComment=false, deleteComment=false, pageNo=0, itemPerPage=10 } = DrawerCommentContextState;
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (post && comment !== "" && videoId !== "") {
            const objData = { comment: comment, videoId: videoId };                        
            PostCommentVideo(objData, authToken).then((data) => {
                // DrawerCommentContextDispatch({ type: "RESET_COMMENT" })                    
                if (data.data.success === 1) {
                    DrawerCommentContextDispatch({ type: "ADD_SET_COMMENT", payload: { comments: [data.data.comment] } })
                    // enqueueSnackbar('Comment posted', { variant: 'default', autoHideDuration: 3000 });
                } else {
                    // enqueueSnackbar('Post comment unsuccessful', { variant: 'error', autoHideDuration: 3000 });
                }                 
            })
        }
    }, [post, comment, videoId])


    useEffect(() => {
        if (deleteComment && commentId !== "" && videoId !== "") {
            const objData = { commentId: commentId, videoId: videoId };                        
            DeleteCommentVideo(objData, authToken).then((data) => {
                DrawerCommentContextDispatch({ type: "RESET_DELETE_COMMENT" }) 
                GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
                if (data.data.success === 1) {
                    // enqueueSnackbar('Comment deleted', { variant: 'default', autoHideDuration: 3000 });
                } else {
                    // enqueueSnackbar('Delete comment unsuccessful', { variant: 'default', autoHideDuration: 3000 });
                }                
            })
        }
    }, [deleteComment])

    useEffect(() => {
        if (likeComment && commentId !== "" && videoId !== "") {
            const objData = { commentId: commentId, videoId: videoId };                        
            LikeCommentVideo(objData, authToken).then((data) => {
                DrawerCommentContextDispatch({ type: "RESET_LIKE_COMMENT" }) 
                GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
                if (data.data.success === 1) {
                    // enqueueSnackbar('Comment Liked', { variant: 'default', autoHideDuration: 3000 });
                } else {
                    // enqueueSnackbar('Like comment unsuccessful', { variant: 'default', autoHideDuration: 3000 });
                }                
            })
        }
    }, [likeComment])

    useEffect(() => {
        if (unLikeComment && commentId !== "" && videoId !== "") {
            const objData = { commentId: commentId, videoId: videoId };                        
            UnLikeCommentVideo(objData, authToken).then((data) => {
                DrawerCommentContextDispatch({ type: "RESET_LIKE_COMMENT" }) 
                GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
                if (data.data.success === 1) {
                    enqueueSnackbar('Comment Unliked', { variant: 'default', autoHideDuration: 3000 });
                } else {
                    enqueueSnackbar('Unlike comment unsuccessful', { variant: 'default', autoHideDuration: 3000 });
                }                
            })
        }
    }, [unLikeComment])

    useEffect(() => {
        if (get && videoId !== "") {
            DrawerCommentContextDispatch({ type: "RESET_GET_COMMENT" });
            const objData = { comment: comment, videoId: videoId, pageNo: 0, itemPerPage: 10 };                        
            GetCommentVideo(objData, authToken).then((data) => {
                DrawerCommentContextDispatch({ type: "SET_COMMENTS", payload: { comments: data.data.comments, commentsCount: data.data.commentsCount, itemPerPage: data.data.itemPerPage, pageCount: data.data.pageCount, pageNo: data.data.pageNo }}) 
            })
        }
    }, [get])

    useEffect(() => {
        if (getInfinite && videoId !== "") {
            DrawerCommentContextDispatch({ type: "RESET_INFINITE_GET_COMMENT" });
            const objData = { comment: comment, videoId: videoId, pageNo: pageNo, itemPerPage: 10 };                        
            GetCommentVideo(objData, authToken).then((data) => {
                DrawerCommentContextDispatch({ type: "INFINITE_SET_COMMENTS", payload: { comments: data.data.comments, commentsCount: data.data.commentsCount, itemPerPage: data.data.itemPerPage, pageCount: data.data.pageCount, pageNo: data.data.pageNo }})
            })
        }
    }, [getInfinite])

    return (
        <DrawerCommentContext.Provider value={{DrawerCommentContextState, DrawerCommentContextDispatch}}>
            {props.children}
        </DrawerCommentContext.Provider>
    );
}
export default DrawerCommentContextProvider;