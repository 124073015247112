import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Container, Typography } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';

import { FramerContext } from '../contexts/FramerContext';
import { GlobalContext } from '../contexts/GlobalContext';

import { containerStyle } from '../styles/defaultStyles.2';
import { inviteBtn } from '../styles/defaultButtonStyles.2';

const useStyles = makeStyles((theme) => ({    
  containerStyle: containerStyle,
  inviteBtn: inviteBtn,
  subHeader: {
    fontSize: "18px",
    color: "rgba(255,255,255,1)",
    fontWeight: "400"
  },
  pinkHeader: {
    fontSize: "18px",
    fontWeight: "600",
    color: "#FB4EB3",
    padding: "20px"
  },
  pinkLine: {
    color: "#FB4EB3",
    borderBottom: "1px solid #FB4EB3",
    display: "block",
    width: "100%",
    textAlign: "center",
    overflow: "visible",
    height: "0",
    margin: "10px auto"
  },
  pinkText: {
    display: "block",
    position: "relative",
    top: "-8px",
    backgroundColor: "#000000",
    margin: "auto",
    width: "60%",
    fontSize: "13px"
  },  
  imageStyle: {
    width: "auto",
    position: "relative",
    top: "15px"
  },
  wrapperGradient: {
    padding: "50px 20px 0",
    background: "transparent",
    borderRadius: "10px",
    textAlign: "center"
  },
  listWrapper: {
    textAlign: "left",
    listStyle: "disc",
    color: "#FB4EB3",
    margin: "20px auto",
    padding: "10px 20px 0",
    "&li:before": {
      color: "red",
      fontWeight: "bold",
      display: "inline-block",
      width: "1em",
      marginLeft: "-1em"
    }
  },
  listWrapperSpan : {
    fontSize: "12px",
    color: "rgba(255,255,255,1)",
    marginBottom: "10px",
    display: "block"
  },
  exitButton: {
    "-webkit-transform": "scaleX(-1)",
    transform: "scaleX(-1)",
    position: "absolute",
    left: "10px"
  },  
  // gridMainWrapper: {
  //   margin: "60px 0 80px",
  //   "&.pwa": {
  //     margin: "80px 0 80px",
  //   }
  // },
  gridItemWrapper: {
    color: "rgba(255,255,255,1)",
    padding: "0px"
  }
}));

export default function ComingSoon(props) {
  const classes = useStyles();  
  let history = useHistory();
  const { GlobalContextState } = useContext(GlobalContext);
  const { isPwaMobile } = GlobalContextState;
  const { toggleDirection } = useContext(FramerContext);
  const handleClick = (td) => {
    td(false,true);
    history.goBack();
  }  

  return (
    <>
      <Container classes={{ root: classes.containerStyle }} className={isPwaMobile ? " pwaMobile" : ""}>
        <Grid
            container
            justify="center"
            alignItems="center"
            direction="column"
            spacing={0}
        >                           
            <Grid container direction="column" alignItems="center" justify="center" classes={{ root: classes.gridItemWrapper }}>
                <div className={ classes.pinkHeader }>
                  GLOSS is Working <span className={ classes.subHeader }>on Bringing the Best Features for Queens &amp; Fans</span>
                </div>
                <img src="//res.cloudinary.com/dtf2021/image/upload/v1646858852/dtf/image-phone_nymop8.png" alt={"GLOSS"} className={ classes.imageStyle } />   
            </Grid>
            <Grid container direction="column" alignItems="center" justify="center" classes={{ root: classes.gridItemWrapper }}>              
              <div className={ classes.wrapperGradient }>

                <div className={ classes.pinkLine }>
                  <span className={ classes.pinkText }>UPCOMING FEATURES INCLUDE</span>
                </div>
                
                <ul className={ classes.listWrapper }>
                  <li><span className={ classes.listWrapperSpan }>Access to Exclusive Photos &amp; Video from the Industry's Greatest Drag Performers &amp; Drag Houses</span></li>
                  <li><span className={ classes.listWrapperSpan }>Live Streaming Performances</span></li>
                  <li><span className={ classes.listWrapperSpan }>1 on 1 Video Chats with your Favorite Queens</span></li>
                </ul>
                <Button variant="outlined" color="primary" classes={{ root: classes.inviteBtn }} onClick={(e) => handleClick(toggleDirection)}>
                  <ExitToAppOutlinedIcon classes={{ root: classes.exitButton }} />
                  Go Back to Previous Screen
                </Button>
              </div>
          </Grid>           
        </Grid>
      </Container>
    </>    
  );
}