import React from 'react';

export default class FacePurple extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <g id="Group_2301" data-name="Group 2301" transform="translate(-304 -746)">
                    <g id="Rectangle_51" data-name="Rectangle 51" transform="translate(304 746)" fill="none" stroke="#624afb" stroke-width="1.5">
                    <rect width="20" height="20" rx="10" stroke="none"/>
                    <rect x="0.75" y="0.75" width="18.5" height="18.5" rx="9.25" fill="none"/>
                    </g>
                    <g id="Group_2300" data-name="Group 2300" transform="translate(-1 -1)">
                    <g id="Group_2299" data-name="Group 2299" transform="translate(311 753)">
                        <g id="Ellipse_33" data-name="Ellipse 33" fill="#624afb" stroke="#624afb" stroke-width="1">
                        <circle cx="1" cy="1" r="1" stroke="none"/>
                        <circle cx="1" cy="1" r="0.5" fill="none"/>
                        </g>
                        <g id="Ellipse_34" data-name="Ellipse 34" transform="translate(6)" fill="#624afb" stroke="#624afb" stroke-width="1">
                        <circle cx="1" cy="1" r="1" stroke="none"/>
                        <circle cx="1" cy="1" r="0.5" fill="none"/>
                        </g>
                    </g>
                    </g>
                    <path id="Path_89" data-name="Path 89" d="M6,2C6,3.1,4.657,4,3,4S0,3.1,0,2,6,.9,6,2Z" transform="translate(311 756)" fill="#624afb"/>
                </g>
            </svg>       
        )
    }
}