import React, { useContext, useEffect, useState, useRef, createRef } from 'react';
import Moment from 'react-moment';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { containerStyleWhite } from '../../styles/defaultStyles.2';
import HelmetComponent from "../../components/HelmetComponent";

//contexts
import { GlobalContext } from '../../contexts/GlobalContext';
import { GlobalTopBarContext } from '../../contexts/GlobalTopBarContext';
import { UserContext } from '../../contexts/UserContext';
import { AuthContext } from '../../contexts/AuthContext';
import { DrawerSignUpContext } from '../../contexts/DrawerSignUpContext';

//components
import SwipeFollowers from '../../components/SwipeFollowers.2';
import SwipeFollowing from '../../components/SwipeFollowing.2';
import SwipeSubscribers from '../../components/SwipeSubscribers.2';
import SwipeSubscriptions from '../../components/SwipeSubscriptions';

//services
import { GetUserService } from "../../services/UserServices";
import { GetSubscriptions, GetFollowing } from '../../services/UserActionsServices';

//util
import { convertCountToString } from '../../utils/CommonUtil';

const useStyles = makeStyles((theme) => ({  
  boxes: {
    display: "table",
    fontSize: "16px",
    fontWeight: "600",
    width: "100%",
    height: "auto",
    color: "rgba(255,255,255,1)",
    margin: "0 0 15px 0",
    padding: "0px 7px 0px 15px",
    "&.even": {
      padding: "0px 15px 0px 7px"
    },
    "& > div": {
      display: "table-cell",
      width: "100%",
      height: "120px",
      backgroundColor: "rgba(98,74,251,1)",
      verticalAlign: "middle"
    }
  },
  headerGridWrapper: {
    margin: "auto 15px",
    width: "auto",
    height: "30px"
  },
  blackText: {
    fontSize: "18px",
    fontWeight: "500",
    color: "rgba(0,0,0,1)",
    "&.revenue > span": {
      color: "rgba(251,78,179,1)"
    }
  },
  pinkText: {
    fontSize: "18px",
    fontWeight: "500",
    color: "rgba(251,78,179,1)"
  },
  grayItalics: {
    color: "rgba(150,151,151,1)",
    fontSize: "14px",
    fontStyle: "italic"
  },
  arrowActiveIcon: {
    color: "rgba(0,0,0,1)",
    padding: "0px"
  },
  iconWrapper: {
    height: "40px",
    width: "40px",
    borderRadius: "20px",
    backgroundColor: "rgba(255,255,255,1)"
  },
  iconLabel: {
    display: "block",
    color: "rgba(255,255,255,1)",
    fontSize: "15px",
    fontWeight: "400",
    lineHeight: "15px"
  },
  iconCount: {
    display: "block",
    color: "rgba(255,255,255,1)",
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "35px"
  },
  hrLine: {
    border: "1px solid rgba(192,192,192,1)",
    height: "1px",
    display: "block",
    width: "auto",
    margin: "15px"
  },
  containerStyleWhite: containerStyleWhite
}));

function AccountDashboard() {
  const classes = useStyles();
  const containerRef = useRef();

  const { GlobalContextState } = useContext(GlobalContext);
  const { isPwaMobile } = GlobalContextState;
  const { GlobalTopBarContextDispatch } = useContext(GlobalTopBarContext);
  const { DrawerSignUpContextState, DrawerSignUpContextDispatch } = useContext(DrawerSignUpContext);
  const { UserContextState, UserContextDispatch } = useContext(UserContext);
  const { username, handleName, userType, user_description, user_videos, user_house_link, user_house_name, user_followers, user_following, user_subscribers, user_subscriptions, user_image_profile, user_moods, date_created } = UserContextState;
  // const { videos=[], count=0, premiumCount=0, publicCount=0, draftCount=0 } = user_videos;
  const { AuthContextState } = useContext(AuthContext);
  const { isAuthenticated, userId, authToken } = AuthContextState;

  const [showRevenue, setShowRevenue] = useState(false);
  const [showMessages, setShowMessages] = useState(false);

  useEffect(() => {           
    GlobalTopBarContextDispatch({ type: "SET_TITLE", payload: { title: 'Dashboard' } });
  }, []) 
 


  return (
    <>
      <HelmetComponent title={"DTF - Dashboard - " + handleName} description={user_description} />                        
      <Container classes={{ root: classes.containerStyleWhite }} className={isPwaMobile ? " pwaMobile" : ""} ref={containerRef}>        
        <Grid 
          container
          direction="column"            
        >
          <Grid 
            container
            direction="row"
            classes={{ root: classes.headerGridWrapper }}
          >
            <Grid align="left" item xs={6}>
              <Typography variant={"div"} align="left" classes={{ root: classes.pinkText }}> 
                {
                  "@"+handleName
                }
              </Typography>
            </Grid>  
            <Grid align="right" item xs={6}>
              <Typography variant={"div"} align="right" classes={{ root: classes.grayItalics }}>
                active since: <Moment format="ll">{date_created}</Moment>
              </Typography>
            </Grid>  
          </Grid>
          <Grid 
            container
            direction="row"
          > 
            <Grid item xs={6} align="center" classes={{ root: classes.boxes }} className={" odd"}>   
              <div>
                <div className={classes.iconWrapper}></div>                
                <span className={classes.iconCount}>{convertCountToString(0)}</span>
                <span className={classes.iconLabel}>Posts</span>
              </div>
            </Grid>
            <Grid item xs={6} align="center" classes={{ root: classes.boxes }} className={" even"}>   
              <div>
                <div className={classes.iconWrapper}></div>
                <span className={classes.iconCount}>{convertCountToString(0)}</span>
                <span className={classes.iconLabel}>Followers</span>
              </div>
            </Grid>
            <Grid item xs={6} align="center" classes={{ root: classes.boxes }} className={" odd"}>   
              <div>
                <div className={classes.iconWrapper}></div>
                <span className={classes.iconCount}>{convertCountToString(0)}</span>
                <span className={classes.iconLabel}>Total Likes</span>
              </div>
            </Grid>
            <Grid item xs={6} align="center" classes={{ root: classes.boxes }} className={" even"}>   
              <div>
                <div className={classes.iconWrapper}></div>
                <span className={classes.iconCount}>{convertCountToString(0)}</span>
                <span className={classes.iconLabel}>Total Comments</span>
              </div>
            </Grid>
          </Grid> 

          <hr className={ classes.hrLine } />

          <Grid 
            container
            direction="row"
            classes={{ root: classes.headerGridWrapper }}
          >
            <Grid align="left" item xs={6}>
              <Typography variant={"div"} align="left" classes={{ root: classes.blackText }} className={"revenue"}> 
                Revenue ( <span>$0</span> )
              </Typography>
            </Grid>
            <Grid align="right" item xs={6}>
              <IconButton
                  aria-label="Show social accounts"
                  onClick={(e) => (setShowRevenue(!showRevenue))}
                  onMouseDown={(e) => (e.preventDefault())}
                  classes={{ root: classes.arrowActiveIcon }}
                >
                {
                  showRevenue
                  ?
                    <KeyboardArrowDownIcon classes={{ root: classes.arrowActiveIcon }} />
                  :
                    <ChevronRightIcon classes={{ root: classes.arrowActiveIcon }} />
                }  
                </IconButton>
            </Grid> 
          </Grid>
          {
            showRevenue
            ?
              <Grid 
                container
                direction="row"
              > 
                <Grid item xs={6} align="center" classes={{ root: classes.boxes }} className={" odd"}>   
                  <div>
                    <div className={classes.iconWrapper}></div>
                    <span className={classes.iconCount}>$0</span>
                    <span className={classes.iconLabel}>Subscribers</span>
                  </div>
                </Grid>
                <Grid item xs={6} align="center" classes={{ root: classes.boxes }} className={" even"}>   
                  <div>
                    <div className={classes.iconWrapper}></div>
                    <span className={classes.iconCount}>$0</span>
                    <span className={classes.iconLabel}>Subscriptions</span>
                  </div>
                </Grid>
                <Grid item xs={6} align="center" classes={{ root: classes.boxes }} className={" odd"}>   
                  <div>
                    <div className={classes.iconWrapper}></div>
                    <span className={classes.iconCount}>$0</span>
                    <span className={classes.iconLabel}>Tips</span>
                  </div>
                </Grid>
                <Grid item xs={6} align="center" classes={{ root: classes.boxes }} className={" even"}>   
                  <div>
                    <div className={classes.iconWrapper}></div>
                    <span className={classes.iconCount}>$0</span>
                    <span className={classes.iconLabel}>Chat</span>
                  </div>
                </Grid>
              </Grid>
            :
              null
          }

          <hr className={ classes.hrLine } />

          <Grid 
            container
            direction="row"
            classes={{ root: classes.headerGridWrapper }}
          >
            <Grid align="left" item xs={6}>
              <Typography variant={"div"} align="left" classes={{ root: classes.blackText }}> 
                Messages
              </Typography>
            </Grid>
            <Grid align="right" item xs={6}>
              <IconButton
                aria-label="Show social accounts"
                onClick={(e) => (setShowMessages(!showMessages))}
                onMouseDown={(e) => (e.preventDefault())}
                classes={{ root: classes.arrowActiveIcon }}
              >
                  {
                showMessages
                  ?
                    <KeyboardArrowDownIcon classes={{ root: classes.arrowActiveIcon }} />
                  :
                    <ChevronRightIcon classes={{ root: classes.arrowActiveIcon }} />
                }
              </IconButton>
            </Grid> 
          </Grid>
          {
            showMessages
            ?
              <Grid 
                container
                direction="row"
              > 
                <Grid item xs={6} align="center" classes={{ root: classes.boxes }} className={" odd"}>   
                  <div>
                    box 1
                  </div>
                </Grid>
                <Grid item xs={6} align="center" classes={{ root: classes.boxes }} className={" even"}>   
                  <div>
                    box 2
                  </div>
                </Grid>
                <Grid item xs={6} align="center" classes={{ root: classes.boxes }} className={" odd"}>   
                  <div>
                    box 3
                  </div>
                </Grid>
                <Grid item xs={6} align="center" classes={{ root: classes.boxes }} className={" even"}>   
                  <div>
                    box 4
                  </div>
                </Grid>
              </Grid>
            :
              null
          }
        </Grid>     
      </Container>          
    </>
  );
}

export default AccountDashboard;