import React from 'react';

export default class BackArrowBlack extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="11.276" height="19.724" viewBox="0 0 11.276 19.724">
                <path d="M3421.117,317.617l-8.448,8.448,8.448,8.448" transform="translate(-3411.255 -316.203)" fill="none" stroke="#141822" stroke-linecap="round" stroke-width="2"/>
            </svg>       
        )
    }
}