export const SwipeSearchContextReducer = (state, action) => {
    switch(action.type){
        case 'SET_INIT_SEARCH_DATA': 
            return {
                ...state,
                peopleType: action.payload.peopleType,         
                profileId: action.payload.profileId,
                peopleResult: action.payload.peopleResult,   
                peopleResultExtract: action.payload.peopleResultExtract,    
                people: action.payload.people,
                pageNo: action.payload.pageNo,
                pageCount: action.payload.pageCount,
                itemPerPage: action.payload.itemPerPage,   
                initLoad: action.payload.initLoad,             
                loadNext: action.payload.loadNext,
                loadNextType: action.payload.loadNextType,
                isLoading: action.payload.isLoading, 
                resultLoaded: action.payload.resultLoaded,                                   
            }
        case 'SET_IS_INITLOAD':
            return {
                ...state,
                initLoad: action.payload.initLoad      
            }            
        case 'SET_IS_LOADNEXT':
            return {
                ...state,
                loadNext: action.payload.loadNext      
            }
        case 'SET_IS_LOADING':
            return {
                ...state,
                isLoading: action.payload.isLoading      
            }
        case 'RESET_RESULT_LOADED': 
            return { 
                ...state,
                resultLoaded: false
            }     
        case 'RESET_LOADNEXT':
            return {
                ...state,
                loadNext: false,
                loadNextType: '',  
            }    
        case 'SET_SEARCH_PEOPLE':
            return { 
                ...state,
                peopleType: action.payload.peopleType,
                profileId: action.payload.profileId,
                people: action.payload.people,
                itemPerPage: action.payload.itemPerPage,
                pageNo: action.payload.pageNo
            }
        case 'SET_SEARCH_PEOPLE_LOADNEXT':
            return { 
                ...state,
                peopleType: action.payload.peopleType,
                profileId: action.payload.profileId,
                people: action.payload.people,
                itemPerPage: action.payload.itemPerPage,
                pageNo: action.payload.pageNo
            }       
        case 'SET_SEARCH_PEOPLE_RESULT':
            return { 
                ...state,
                peopleResult: action.payload.peopleResult,
                peopleResultExtract: action.payload.peopleResultExtract,
                pageNo: action.payload.pageNo,
                pageCount: action.payload.pageCount, 
                itemPerPage: action.payload.itemPerPage
            }  
        case 'SET_SEARCH_PEOPLE_LOADNEXT_RESULT':
            return { 
                ...state,
                peopleResult: state.peopleResult.concat(action.payload.peopleResult),
                peopleResultExtract: state.peopleResultExtract.concat(action.payload.peopleResultExtract),
                pageNo: action.payload.pageNo,
                pageCount: action.payload.pageCount, 
                itemPerPage: action.payload.itemPerPage
            }
        case 'RESET_SEARCH_INFO':
            return {
                ...state,
                peopleType: '',
                profileId: '',
                peopleResult: [],
                peopleResultExtract: [],
                people: "",
                pageNo: 0,
                pageCount: 0,
                itemPerPage: 10,
                initLoad: false,
                loadNext: false,
                loadNextType: '',
                isLoading: false, 
                resultLoaded: false, 
            }               
        default:
            return state    
    }
}