import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Button from '@material-ui/core/Button';
import { containerStyle, containerGridStyle } from '../styles/defaultStyles';
import { gridItemWrapper } from '../styles/defaultGridStyles';
import { defaultBtn, defaultLabelBtn, defaultActiveBtn } from '../styles/defaultButtonStyles.2';
import { listWrapper } from '../styles/defaultListStyles';
import { listText, listTextBold } from '../styles/defaultTextStyles';
import { FollowService, UnfollowService } from '../services/UserActionsServices';
import { AuthContext } from '../contexts/AuthContext';
import ImageAvatar from '../components/ImageAvatar.2';
import LinkText from '../components/LinkText.6';

const useStyles = makeStyles((theme) => ({  
  containerGridStyle: containerGridStyle,
  containerStyle: containerStyle,
  gridItemWrapper: gridItemWrapper,
  listWrapper: listWrapper,
  listText: listText,
  listTextBold: listTextBold,
  defaultBtn: defaultBtn,
  defaultLabelBtn: defaultLabelBtn,
  defaultActiveBtn: defaultActiveBtn,
  itemWrapper: {
    padding: "10px 20px",
  },
  marginRight5: {
    marginRight: "5px"
  }
}));

export default function SwipeFollowingItem(props) {
  const classes = useStyles();
  const { AuthContextState } = useContext(AuthContext);
  const { authToken } = AuthContextState;
  const { data, isOwner=false } = props;  
  const { follow_user, follow_user_information } = data;  
  const follow_user_placeholder = { username: "", handlename: false, userType: "", followUserPlaceHodler: true };
  const { _id: followUserId, username="", handlename="", userType="", followUserPlaceHodler=false } = follow_user || follow_user_placeholder;
  const follow_user_information_placeholder = { user_image_profile: "", isFollow: true, isPlaceHodler: true };
  const { _id: followUserInformationId, user_image_profile="", isFollow=true, isPlaceHodler=false } = follow_user_information || follow_user_information_placeholder;
  
   const [ isFollowed, setIsFollowed ] = useState(isFollow)

  const handleClick = (e) => {
    // console.log("handleClick",e);
    e.preventDefault();
  }

  const followClick = (e) => {
    e.preventDefault()
    const objData = {
      profile_user_id: followUserId,
      user_information_id: followUserInformationId
    }
    FollowService(objData, authToken).then(result => {
      if (result.data.success === 1) {
        setIsFollowed(true)
      }
      return true;
    }).catch(err => {
        console.log("catch FollowService",err);
    });
  }

  const unFollowClick = (e) => {
    e.preventDefault()
    const objData = {
      profile_user_id: followUserId,
      user_information_id: followUserInformationId
    }
    UnfollowService(objData, authToken).then(result => {
      if (result.data.success === 1) {
        setIsFollowed(false)
      }
      return true;
    }).catch(err => {
        console.log("catch UnfollowService",err);
    });
  }
  
  if (!followUserPlaceHodler) {
    return (
      <LinkText url={`/user/${handlename}`} underline={false}> 
        <ListItem key={"people-tag-" + followUserId} className={ classes.itemWrapper }>
          <ListItemAvatar classes={{ root: classes.marginRight5 }}>
            {
              user_image_profile !== "" && user_image_profile !== null
              ?
                <ImageAvatar image={user_image_profile} alt={username} />
              :
                <ImageAvatar letter={true} alt={handlename} />
            }
          </ListItemAvatar>
          <ListItemText 
            primary={username} 
            secondary={"@" + handlename} 
            classes={{
              primary: classes.listTextBold,
              secondary: classes.listText, 
            }}
          />
          {
            isOwner
            ?
              isFollowed
              ?
                <Button variant="outlined" color={ "default" } classes={ { root: classes.defaultActiveBtn, label: classes.defaultLabelBtn } }  onClick={(e) => unFollowClick(e)}>
                  Following
                </Button>
              :
                <Button variant="outlined" color={ "primary" } classes={ { root: classes.defaultBtn, label: classes.defaultLabelBtn } }  onClick={(e) => followClick(e)}>
                  Follow
                </Button>
            :
              null  
          }
        </ListItem>
      </LinkText>   
    )
  } else {
    return null
  }  
}