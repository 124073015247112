import React, { useState, useContext, useEffect }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Button from '@material-ui/core/Button';
import { containerStyle, containerGridStyle } from '../styles/defaultStyles';
import { gridItemWrapper } from '../styles/defaultGridStyles';
import { tagAvatar } from '../styles/defaultAvatarStyles'; 
import { defaultBtn, defaultLabelBtn, defaultActiveBtn } from '../styles/defaultButtonStyles.2';
import { listWrapper } from '../styles/defaultListStyles';
import { listText, listTextBold } from '../styles/defaultTextStyles';
import { checkOjectExistById } from '../utils/CommonUtil';
import ImageAvatar from '../components/ImageAvatar.2';
import { TagContext } from '../contexts/TagContext';

const useStyles = makeStyles((theme) => ({  
  containerGridStyle: containerGridStyle,
  containerStyle: containerStyle,
  gridItemWrapper: gridItemWrapper,
  tagAvatar: tagAvatar,
  listWrapper: listWrapper,
  listText: listText,
  listTextBold: listTextBold,
  defaultBtn: defaultBtn,
  defaultLabelBtn: defaultLabelBtn,
  defaultActiveBtn: defaultActiveBtn,
  paddingRightLeft20: {
    paddingRight: "20px",
    paddingLeft: "20px"
  }
}));

export default function SearchPeopleTagItem(props) {
  const classes = useStyles();
  const { data, index } = props;  
  const { _id="", username="", handlename="", user_information="" } = data;  
  const user_information_placeholder = { user_image_profile: "", isPlaceHodler: true };
  const { user_image_profile="", isPlaceHodler=false } = user_information || user_information_placeholder;
  const { TagContextState, TagContextDispatch } = useContext(TagContext);
  const { taggedPeople } = TagContextState;
  const [ tag, setTag ] = useState(false);

  const handleUntaggedClick = (e, item) => {   
    setTag(false);
    TagContextDispatch({ type: "REMOVE_TAG_PEOPLE", payload: { taggedPeople : item }});    
  }

  const handleTagClick = (e, item) => {
    setTag(true);
    TagContextDispatch({ type: "ADD_TAG_PEOPLE", payload: { taggedPeople :item }});    
  }

  return (
    <ListItem key={"people-tag-" + _id} classes={{ root: classes.paddingRightLeft20 }}>
      <ListItemAvatar>
        {
          user_image_profile !== "" && user_image_profile !== null
          ?
            <ImageAvatar image={user_image_profile} alt={username} />
          :
            <ImageAvatar letter={true} alt={handlename} />
        }
      </ListItemAvatar>
      <ListItemText 
        primary={username} 
        secondary={"@" + handlename} 
        classes={{
          primary: classes.listTextBold,
          secondary: classes.listText, 
        }}
      />
      {
        checkOjectExistById(taggedPeople, data)
        ?
          <Button variant="outlined" color={ "default" } classes={ { root: classes.defaultActiveBtn, label: classes.defaultLabelBtn } }  onClick={(e) => handleUntaggedClick(e, data)}>
            Untagged
          </Button>
        :
          <Button variant="outlined" color={ "primary" } classes={ { root: classes.defaultBtn, label: classes.defaultLabelBtn } }  onClick={(e) => handleTagClick(e, data)}>
            Tag
          </Button>
      }    
    </ListItem>
  );
}