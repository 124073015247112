import React from 'react';

export default class ButtonMessageWhite extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="17.041" height="16.791" viewBox="0 0 17.041 16.791">
                <g id="message-square" transform="translate(-2 -2)">
                    <path id="Path_9" data-name="Path 9" d="M18.041,12.861A1.658,1.658,0,0,1,16.37,14.5H6.342L3,17.791V4.643A1.658,1.658,0,0,1,4.671,3h11.7a1.658,1.658,0,0,1,1.671,1.643Z" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                </g>
            </svg>     
        )
    }
}