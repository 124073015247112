import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { backBtn, backTransparentBtn } from '../styles/defaultButtonStyles';
import SearchPageInput from "./SearchPageInput";
import SearchDiscoveryInput from "./SearchDiscoveryInput";
import Menu from "./Menu.3";
import { FramerContext } from '../contexts/FramerContext';
import { isPWA, checkInstallerIOS, isMobile } from "../utils/CommonUtil";

const useStyles = makeStyles((theme) => ({
  default : {
    position: "fixed", 
    width: "100vw",
    height: "auto",
    backgroundColor: "rgba(0,0,0,1)",
    borderBottom: "2px solid rgba(230,230,230,1)",
    zIndex: "1000"
  },
  defaultGrid: { 
    marginTop: "0px", 
    height: "50px", 
    backgroundColor: "rgba(255,255,255,1)" 
  },
  defaultGridApp: { 
    marginTop: "40px", 
    height: "50px", 
    backgroundColor: "rgba(255,255,255,1)" 
  },
  defaultTransparent : {
    position: "fixed", 
    width: "100vw",
    height: "auto",
    backgroundColor: "transparent",
    borderBottom: "0px solid rgba(112,112,112,0.10)",
    zIndex: "1000"
  },
  defaultGridTransparent: { 
    marginTop: "0px", 
    height: "50px", 
    backgroundColor: "transparent" 
  },
  defaultGridAppTransparent: { 
    marginTop: "40px", 
    height: "50px", 
    backgroundColor: "transparent" 
  },
  title : {
    fontWeight: "500",
    color: "#141822",
    fontSize: "16px",
    letterSpacing: "0.5px",
    width: "inherit",
    display: "inline-block"
  },
  titleWhite : {
    fontWeight: "500",
    color: "rgb(255,255,255,1)",
    fontSize: "16px",
    letterSpacing: "0.5px",
    width: "inherit",
    display: "inline-block"
  },
  backBtn : backBtn,
  backTransparentBtn: backTransparentBtn,
  ArrowBackIosIcon: {
    lineHeight: "50px",
    height: "50px",
    display: "block",
    margin: "auto"
  },
  MoreHorizIcon: {
    lineHeight: "50px",
    display: "block"
  },
  dotIcon: {
    fontSize: "14px",
    top: "2px",
    position: "relative",
    color: "rgba(27,183,127,1)"
  },
  doneButton: {
    textTransform: "none",
    color: "rgba(253,45,85,0.8)"
  }
}));

export default function TopNavigation(props) {
  const classes = useStyles();
  let history = useHistory();

  const { showGreen = false, showMenuIcon = false, title = "", url=false, defaultTransparent=false, showSearch=false, showDone=false, showSearchType="" } = props;
  const { state, toggleDirection } = useContext(FramerContext); 
  
  const [ menuDrawer, setMenuDrawer ] = useState(false);

  const toggleMenuDrawer = (bool) => (event) => {
    setMenuDrawer(bool);
  };

  const handleClick = (td) => {
    td(false,true);
    history.goBack();
  }

  return (
    <div className={(defaultTransparent) ? classes.defaultTransparent : classes.default}>
      <Grid 
        container
        justify="flex-start"
        alignItems="center"
        alignContent="center"
        direction="row"
        spacing={0}
        className={(defaultTransparent) ? (isMobile() === true && isPWA() !== false) ? classes.defaultGridAppTransparent : classes.defaultGridTransparent : (isMobile() === true && isPWA() !== false) ? classes.defaultGridApp : classes.defaultGrid}
      >
        <Grid item xs={2} align='left'>
          <Button classes={{ root: ((defaultTransparent) ? classes.backTransparentBtn : classes.backBtn) }} onClick={(e) => handleClick(toggleDirection)}>
            <ArrowBackIosIcon classes={{ root: classes.ArrowBackIosIcon }} />
          </Button>         
        </Grid>
        <Grid item xs={8} align='center'>
          {
            showSearch
            ?
              showSearchType === "discovery"
              ?
                <SearchDiscoveryInput />
              :
                <SearchPageInput />
            :
              <Typography variant={"h1"} classes={{ h1: defaultTransparent ? classes.titleWhite : classes.title }}>
              { 
                showGreen
                ?
                  <>
                    <FiberManualRecordIcon classes={{ root: classes.dotIcon }} /> {title}
                  </>
                :
                  title
              }             
              </Typography>
          }
        </Grid>
        <Grid item xs={2} align='center'>
          {
            showMenuIcon
            ?
              <>
                <MoreHorizIcon onClick={toggleMenuDrawer(true)} classes={{ root: classes.MoreHorizIcon }} />
                <Menu open={menuDrawer} handleToggle={toggleMenuDrawer} />
              </>  
            :
              showDone
              ?
                <Button classes={{ label: classes.doneButton }} onClick={(e) => handleClick(toggleDirection)}>
                  Done
                </Button>  
              :
                null
          }             
        </Grid>
      </Grid>
    </div>
  );
}
