import React from 'react';

export default class DotGreen extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <g id="Group_3348" data-name="Group 3348" transform="translate(-125 -14)">
                    <g id="Group_3347" data-name="Group 3347">
                    <path id="Path_2349" data-name="Path 2349" d="M8,0A8,8,0,1,1,0,8,8,8,0,0,1,8,0Z" transform="translate(125 14)" fill="#1bb770"/>
                    </g>
                </g>
            </svg>
        )
    }
}