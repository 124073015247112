import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FlipIcon from './svg/FlipIcon';
import { defaultVideoButton } from '../styles/defaultButtonStyles.2';

const useStyles = makeStyles((theme) => ({
  defaultVideoButton: defaultVideoButton
}));

export default function SwitchCameraButton(props) {
  const { handleClick = (e) => { }, isActive=false, id="", facingMode='user', isStartRecordingCount=false } = props;
  const classes = useStyles();
  if (isActive) {
    return (    
      <button type="button" id={id} className={classes.defaultVideoButton} onClick={(e) => handleClick(e)}>
        {
          facingMode !== 'user'
          ?
            <FlipIcon />
          :
            <FlipIcon />
        }        
      </button>
    )
  } else {
    return (    
      <button type="button" id={id} className={classes.defaultVideoButton} disabled>
        {
          facingMode !== 'user'
          ?
            <FlipIcon />
          :
            <FlipIcon />
        }
      </button>
    )
  }  
}