import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { containerStyle, containerGridStyle } from '../styles/defaultStyles';
import { gridItemWrapper } from '../styles/defaultGridStyles';
import { defaultBtn, defaultLabelBtn, defaultActiveBtn } from '../styles/defaultButtonStyles.2';
import { listWrapper } from '../styles/defaultListStyles';
import { listText, listTextBold } from '../styles/defaultTextStyles';
import ImageAvatar from '../components/ImageAvatar.2';
import LinkText from '../components/LinkText.6';

const useStyles = makeStyles((theme) => ({  
  containerGridStyle: containerGridStyle,
  containerStyle: containerStyle,
  gridItemWrapper: gridItemWrapper,
  listWrapper: listWrapper,
  listText: listText,
  listTextBold: listTextBold,
  defaultBtn: defaultBtn,
  defaultLabelBtn: defaultLabelBtn,
  defaultActiveBtn: defaultActiveBtn,
  itemWrapper: {
    padding: "10px 20px",
  },
  marginRight5: {
    marginRight: "5px"
  }
}));

export default function SwipeFollowersItem(props) {
  const classes = useStyles();
  const { data } = props;  
  const { user, user_information } = data;  
  const userPlaceholder = { username: "", handlename: false, userType: "", userPlaceHolder: true };
  const { _id="", username="", handlename="", userType="", userPlaceHolder=false } = user || userPlaceholder;
  const userInformationPlaceHolder = { user_image_profile: "", isPlaceHolder: true };
  const { user_image_profile="", isPlaceHolder=false } = user_information || userInformationPlaceHolder;

  if (!userPlaceHolder) {
    return (
      <LinkText url={`/user/${handlename}`} underline={false}> 
        <ListItem key={"people-tag-" + _id} className={ classes.itemWrapper }>
          <ListItemAvatar classes={{ root: classes.marginRight5 }}>
            {
              user_image_profile !== "" && user_image_profile !== null
              ?
                <ImageAvatar image={user_image_profile} alt={username} />
              :
                <ImageAvatar letter={true} alt={handlename} />
            }
          </ListItemAvatar>
          <ListItemText 
            primary={username} 
            secondary={"@" + handlename} 
            classes={{
              primary: classes.listTextBold,
              secondary: classes.listText, 
            }}
          />
        </ListItem>
      </LinkText>   
    );
  } else {
    return null
  }
  
}