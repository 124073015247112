import React, { useContext, useEffect, useState, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { FormErrorContext } from '../contexts/FormErrorContext';
import { AuthContext } from '../contexts/AuthContext';
import { GlobalContext } from '../contexts/GlobalContext';
import { UserContext } from '../contexts/UserContext';
import { VideoServiceContext } from '../contexts/VideoServiceContext';
import { DrawerSignUpContext } from '../contexts/DrawerSignUpContext';
import { DrawerContentContext } from '../contexts/DrawerContentContext';

import { UploadContentService } from '../services/UploadServices';
import { videoRecordUser, videoRecordEnvironment } from '../constants/VideoSettings';

import StartRecordingCountButton from './StartRecordingCountButton.3';
import StopRecordingButton from './StopRecordingButton.3';
import SnapIconButton from './SnapIconButton';
import SwitchCameraButton from './SwitchCameraButton.3';
import CloseCameraButton from './CloseCameraButton.2';
import SmallCloseCameraButton from './CloseCameraButton.3';
import SnapPictureButton from './SnapPictureButton';
import AlbumButton from './AlbumButton';
import RecordVideoProgressBar from './RecordVideoProgressBar.2';
import StartRecordCounter from "./StartRecordCounter.2";

import { videosCaptureContainerStyle } from '../styles/defaultStyles.2';
import { defaultVideoButton, defaultVideoIconButton, backTransparentBtn } from '../styles/defaultButtonStyles';

import { accessCamera } from '../utils/VideoUtil';

let mediaRecorder = null;
let chunks = [];
let streamObj = null;
let facingMode = false;
let isSwitching = false;
let videoLengthSeconds = 0;

const useStyles = makeStyles((theme) => ({  
  videosCaptureContainerStyle: videosCaptureContainerStyle,
  defaultVideoButton: defaultVideoButton,
  defaultVideoIconButton: defaultVideoIconButton,  
  backTransparentBtn: backTransparentBtn,
  linkButton: {
    display: "block",
    margin: "auto"
  },
  uploadFileAction: {
    position: 'fixed',
    bottom: '5%',
    zIndex: '110'
  },
  videoActions: {
    position: 'fixed',
    bottom: '5%',
    zIndex: '110',
    display: 'flex',
    width: '100vw',
    justifyContent: 'center',
    alignContent: 'center',
    zIndex: '125'
  },
  video: {
    backgroundColor: "rgba(0,0,0,1)",
    position: "relative",
    width: '100%',
    height: '100%',
    zIndex: '110',
    "&.isMobile": {
      objectFit: 'cover',
    },
    "&.facingMode": {
      transform: "rotateY(180deg)",
      "-webkit-transform": "rotateY(180deg)",
      "-moz-transform": "rotateY(180deg)"
    }  
  },
  canvas: {
    backgroundColor: "rgba(0,0,0,1)",
    position: "relative",
    width: '100%',
    height: 'auto',
    zIndex: '110',
    "&.isMobile": {
      objectFit: 'cover',
    },
    "&.facingMode": {
      // transform: 'scale(-1,1)',
      transform: "rotateY(180deg)",
      "-webkit-transform": "rotateY(180deg)",
      "-moz-transform": "rotateY(180deg)"
    } 
  },
  image: {
    backgroundColor: "rgba(0,0,0,1)",
    position: "relative",
    width: '100%',
    height: '100%',
    zIndex: '110',
    "&.isMobile": {
      objectFit: 'cover',
    },
    "&.facingMode": {
      // transform: 'scale(-1,1)'
      transform: "rotateY(180deg)",
      "-webkit-transform": "rotateY(180deg)",
      "-moz-transform": "rotateY(180deg)"
    },
    "& > img": {
      width: 'inherit',
      top: '50%',
      transform: 'translateY(-50%) !important',
      position: 'absolute'
    } 
  },
  videoHidden: {
    display: 'none',
    zIndex: '100'
  },
  progressBarWrapper: {
    display: "block",
    width: "90%",
    position: "fixed",
    top: "100px",
    left: "0px",
    right: "0px",
    margin: "auto",
    overflow: "hidden",
    display: "none",
    overflow: 'visible',
    zIndex: "115",
    "&.isRecording" : {
      display: "block"
    }
  },
  closeButtonWrapper: {
    width: "20px",
    height: "20px",
    color: "rgba(255,255,255,1)",
    display: "block",
    position: "absolute",
    top: "20px",
    right: "20px",
    zIndex: "115",
    display: "block",
    overflow: "hidden",
    "&.pwa": {
      top: "60px"
    }
  },
  flashWrapper: {
    width: '100%',
    height: '100%',
    position: "absolute",
    top: "0px",
    zIndex: '115',
    background: "rgba(255,255,255,1)",
    opacity: "0",
    "&.flash" : {
      'webkit-animation': '$flashAnimation 500s radial forwards',
      '-moz-animation': '$flashAnimation 500s radial forwards',
      '-o-animation': '$flashAnimation 500s radial forwards',
      animation: '$flashAnimation 500s radial forwards',
      animationDelay: "250s"
    }    
  },
  "@keyframes flashAnimation": {
    "0%": {
      opacity: "0",
    },
    "50%": {
      opacity: "0.7",
    },
    "100%": {
      opacity: "0",
    }
  }
}));

function CreateContent() {
  const classes = useStyles();
  let history = useHistory();
  const { GlobalContextState, GlobalContextDispatch } = useContext(GlobalContext);
  const { animationComplete, iOSInstaller, isPwaMobile, isMobile } = GlobalContextState;
  const { AuthContextState } = useContext(AuthContext);  
  const { authToken, userId, userType } = AuthContextState; 
  const { FormErrorContextState, FormErrorContextDispatch } = useContext(FormErrorContext);  
  const { requestError } = FormErrorContextState;
  const { UserContextState, UserContextDispatch } = useContext(UserContext);
  const { user_information_id } = UserContextState;
  const { VideoServiceContextState, VideoServiceContextDispatch } = useContext(VideoServiceContext);  

  const { DrawerSignUpContextState, DrawerSignUpContextDispatch } = useContext(DrawerSignUpContext);
  const { showDrawer } = DrawerSignUpContextState;
  
  const { DrawerContentContextState, DrawerContentContextDispatch } = useContext(DrawerContentContext);
  const { showContentDrawer } = DrawerContentContextState;

  const [ maxFreeVideoLength, setFreeMaxVideoLength ] = useState(15);
  const [ videoLength, setVideoLength ] = useState(0);
  const [ isStartRecordingCount, setIsStartRecordingCount ] = useState(false);
  const [ isRecording, setIsRecording ] = useState(false);
  const [ isSnap, setIsSnap ] = useState(false);
  
  const [ showFlash, setShowFlash] = useState(false);
  const [ pictureObj, setPictureObj ] = useState(null);

  const [ isFacingModeAvailable, setIsFacingModeAvailable ] = useState(false);
  const [ init, setInit ] = useState(false);

  const videoRecorderRef = useRef();  
  const canvasRef = useRef();   
  
  const stopStream = async () => {
    if (streamObj) {
      streamObj.getTracks().forEach(mediaTrack => {
          mediaTrack.stop();
      });
    }
    return true;
  }

  const goToCreateContent = async (objData) => {
    console.log('goToCreateContent1',streamObj)
    // if (streamObj) {
      // console.log('goToCreateContent2',streamObj)
      // const stopStreamResponse = await stopStream();
    // }
    preUploadData(objData);
  }
  
  const handleCloseClick = async () => {
    console.log('handleCloseClick',streamObj)
    // if (streamObj) {
      const stopStreamResponse = await stopStream();
      DrawerContentContextDispatch({ type: "HIDE_DRAWER" });
    // } else {
      // DrawerContentContextDispatch({ type: "HIDE_DRAWER" });      
    // }
  }

  const getMediaRecorder = async (sObj) => {
    try {
      mediaRecorder = new MediaRecorder(sObj);
      console.log('mediaRecorder',mediaRecorder);

      mediaRecorder.ondataavailable = (ev) => {
        console.log("ondataavailable",ev);  
        chunks.push(ev.data);
      }

      mediaRecorder.onstop = async (ev) => {
        console.log('mediaRecorder.onstopchunks',chunks,chunks.length);
        console.log('mediaRecorder onstop');
        if (!isSwitching) {
          let blob = new Blob(chunks, {type : 'video/mp4' });
          console.log('blob',blob);
          console.log('blob.length',blob.length); 
          const objData = {
            data: blob,
            fileName: `video-${Date.now()}`,
            isPrivate: false,
            isVideo: true
          } 
          console.log('onstop objData',objData);
          const stopStreamResponse = await stopStream();
          chunks = [];
          isSwitching = false;
          preUploadData(objData); 
        } else {
          isSwitching = false;
        }
      }
      return true;

    } catch (err) {
      console.log('getCamera',err);
      return false;
    }
  }

  const getCamera = async (config) => {
    console.log('config.video.facingMode', config.video.facingMode)
    try {
      if (config.video.facingMode === 'user') {
        facingMode = true;
      } else {
        facingMode = false;
      }
      console.log('facingMode', config.video.facingMode)      
      streamObj = await accessCamera(config);
      console.log('getCamera mediaRecorder',streamObj);

      const video = videoRecorderRef.current; 
      // get the active track of the stream
      const track = streamObj.getVideoTracks()[0];      
      function onCapabilitiesReady(capabilities) {
        console.log('capabilities');
        console.log(capabilities);
        console.log('getSettings');
        console.log(track.getSettings());
      }

      //connect the media stream to the first video element
      if ("srcObject" in video) {
          video.srcObject = streamObj;
      } else {
          //old version
          video.src = window.URL.createObjectURL(streamObj);
      }
      
      video.onloadedmetadata = function(ev) {
          // show in the video element what is being captured by the webcam
          // video.play();

          if (mediaRecorder) {
            if (mediaRecorder.state === "paused") {
              console.log("mediaRecorder paused - resuming",mediaRecorder.state)
              mediaRecorder.resume();
            }
          }

          window.setTimeout(() => (
            onCapabilitiesReady(track.getCapabilities())
          ), 500);
      }
      return true;
    } catch (err) {
      console.log('getCamera',err);
      return false;
    }
  }

  const preUploadData = (dataObj) => {
    GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : true }});
    try {
      console.log('dataObj',dataObj);
      const reader = new FileReader();
      
      reader.readAsDataURL(dataObj.data);
      reader.onloadend = () => {       
        
        const data = {
          user_id: userId,
          userInformation_id: user_information_id,
          video_src: null,
          video_information: null,
          video_description: "",
          isVideo: dataObj.isVideo,
          isFacingMode: facingMode
        } 

        UploadContentService(data,userId,authToken).then(result => { 
          console.log("UploadContentService",result);
           
          //PRESET DATA FOR UPLOAD
          const file = reader.result; 
          const objData = {
            data: file,
            fileName: dataObj.fileName,
            isPrivate: dataObj.isPrivate,
            videoId: result.data.userVideos.videos[0]._id,
            isVideo: dataObj.isVideo, 
            isUploading: false
          } 
          VideoServiceContextDispatch({ type: "SET_VIDEO_DATA", payload: objData });
          UserContextDispatch({ type: "ADD_USER_VIDEO", payload: result.data });   
          GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
          // console.log("userType",userType);          
          // console.log("videoLength",videoLength, maxFreeVideoLength, videoLengthSeconds);
          DrawerContentContextDispatch({ type: "HIDE_DRAWER" });   
          history.push(`/edit/content/${result.data.userVideos.videos[0]._id}/true`); 
                     
        }).catch(err => {
          FormErrorContextDispatch({ type: "REQUEST_ERROR", payload: err.response.data });          
          GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }}); 
        });
      }      
    } catch (error) {
      console.log('catch',error);
      GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
    }
  }

  const videoStartRecordCount = async (e) => {
    setIsStartRecordingCount(true);
  }

  const videoStartRecord = async (e) => {
    if (streamObj) {
      const video = videoRecorderRef.current; 
      if (!mediaRecorder || mediaRecorder.state === "inactive") {
        const getMediaRecorderResponse = await getMediaRecorder(streamObj); 
        mediaRecorder.start();     
      }
  
      if (video.paused) {
        video.play();
      }

      if (!isRecording) {
        setIsRecording(true);  
      }  
    }
  }

  const switchCamera = async (e) => {
    console.log('facingMode', facingMode)
    if (mediaRecorder) {
      if (mediaRecorder.state === "recording") {
        console.log("mediaRecorder recording - pausing",mediaRecorder.state);
        isSwitching = true;
        mediaRecorder.stop();    
        const getCamera1 = await getCamera(!facingMode ? videoRecordUser : videoRecordEnvironment); 
        videoStartRecord();
      }
    } else  {
      const getCamera2 = await getCamera(!facingMode ? videoRecordUser : videoRecordEnvironment);  
    }
  } 

  const toggleSnap = (e) => {
    setIsSnap(!isSnap);
  }

  const snapPicture = async (e) => {
    console.log('snap');
    setShowFlash('true');
    const video = videoRecorderRef.current;
    // const canvas = canvasRef.current;
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.height = video.videoHeight;
    canvas.width = video.videoWidth;
    ctx.drawImage(video, 0, 0);
    canvas.toBlob((blob) => {
      if (blob === null) {
        console.log("Failed to convert canvas to blob");
        return;
      }
      const objData = {
        data: blob,
        fileName: `picture-${Date.now()}`,
        isPrivate: false,
        isVideo: false
      } 
      preUploadData(objData);
    });

    let dataUrl = canvas.toDataURL('image/png');
    let imageWrapperElement = document.querySelector('#image-1')
    let image = document.createElement("img");

    if (imageWrapperElement !== null) {
      image.src = dataUrl;
      imageWrapperElement.append(image);
    }
    console.log('dataUrl',dataUrl);
  }  

  const videoStopRecord = async () => {
    setIsRecording(false);
    if (showFlash) {
      setShowFlash(false);
    }
    
    const video = videoRecorderRef.current;
    if (!video.paused) {
      video.pause();
    }
    if (mediaRecorder.state !== 'inactive') {
      mediaRecorder.stop();   
    }   
  }

  const handleSetVideoLength = (sec) => {
    console.log('handleSetVideoLength',sec);
    setVideoLength(sec);
  }

  useEffect(() => {    
    // console.log('showContentDrawer', showContentDrawer)
    if (showContentDrawer && !init) {
      setInit(true);
      const supports = navigator.mediaDevices.getSupportedConstraints();
      if( supports['facingMode'] === true ) {
        setIsFacingModeAvailable(true);
      }
      const cameraResponse = getCamera(facingMode ? videoRecordUser : videoRecordEnvironment);
    }
  }, [showContentDrawer]);

  useEffect(() => {    
    console.log('set new videoLength',videoLength)
    videoLengthSeconds = videoLength;
  }, [videoLength]);

  return (               
    <Container classes={{ root: classes.videosCaptureContainerStyle }}> 
      {
        isStartRecordingCount
        ?
          <StartRecordCounter startCount={true} handleNext={videoStartRecord} />
        :
          null
      }

      {
        !isStartRecordingCount && !isRecording && !isSnap
        ?
          <div className={classes.closeButtonWrapper + (isPwaMobile ? " pwa" : "")}>
            <SmallCloseCameraButton isActive={true} handleClick={(e) => handleCloseClick()} /> 
          </div>
        :
          null
      } 
      
      <div className={classes.progressBarWrapper + " " + (isRecording ? "isRecording" : "")}>      
          <RecordVideoProgressBar handleAutoStop={videoStopRecord} isRecording={isRecording} handleSetVideoLength={handleSetVideoLength} /> 
      </div>      
      
      <div className={classes.videoActions}>
        {
          isStartRecordingCount || isRecording || isSnap
          ?
            <>
              {
                isFacingModeAvailable && isMobile
                ?
                  <SwitchCameraButton isActive={true} handleClick={switchCamera} isStartRecordingCount={isStartRecordingCount} />
                :
                  null
              }
              {
                isSnap
                ?
                  <>
                    <SnapIconButton isActive={true} handleClick={snapPicture} isRecording={isRecording} />
                    <CloseCameraButton isActive={true} handleClick={toggleSnap} /> 
                  </>
                :
                  <>
                    <StopRecordingButton isActive={true} handleClick={videoStopRecord} isRecording={isRecording} />
                    <CloseCameraButton isActive={true} handleClick={videoStopRecord} />
                  </>
              }              
            </>
          :
            <>
              {
                isFacingModeAvailable && isMobile
                ?
                  <SwitchCameraButton isActive={true} handleClick={switchCamera} isStartRecordingCount={isStartRecordingCount} />
                :
                  null
              }
              <StartRecordingCountButton isActive={true} handleClick={videoStartRecordCount} />
              <SnapPictureButton isActive={true} handleClick={toggleSnap} />
              <AlbumButton isActive={true} handleClick={goToCreateContent} handleStopClick={goToCreateContent} />
            </>
        }      
      </div>

      <video ref={videoRecorderRef} id="video-1" autoPlay playsInline muted className={classes.video + (isMobile ? " isMobile" : "") + (facingMode ? " facingMode" : "")} />

      <div id="image-1" className={classes.image + (isMobile ? " isMobile" : "") + (facingMode ? " facingMode" : "")} />      
    </Container>          
  );
}

export default CreateContent;