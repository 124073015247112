import React, { useState, useContext, useEffect }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';

import LinkText from './LinkText.2';
import { containerStyle, containerGridStyle } from '../styles/defaultStyles';
import { gridItemWrapper } from '../styles/defaultGridStyles';
import { searchItemHashAvatar, searchItemAvatar } from '../styles/defaultAvatarStyles'; 
import { defaultBtn, defaultLabelBtn, defaultActiveBtn } from '../styles/defaultButtonStyles';
import { listWrapper } from '../styles/defaultListStyles';
import { listText, listTextBold } from '../styles/defaultTextStyles';
import { CloudinaryInfo } from "../constants/CloudinaryInfo";

const useStyles = makeStyles((theme) => ({  
  containerGridStyle: containerGridStyle,
  containerStyle: containerStyle,
  gridItemWrapper: gridItemWrapper,
  searchItemHashAvatar: searchItemHashAvatar,
  searchItemAvatar: searchItemAvatar,
  listWrapper: listWrapper,
  listText: listText,
  listTextBold: listTextBold,
  defaultBtn: defaultBtn,
  defaultLabelBtn: defaultLabelBtn,
  defaultActiveBtn: defaultActiveBtn,
  searchItemContainer: {
    padding: "10px"
  },
  searchItemTextContainer: {
    margin: "6px 10px"
  }
}));

export default function SearchHistoryItem(props) {
  const classes = useStyles();
  const { data, index } = props;  
  const { _id="", searchStr="", searchType="" } = data;
  
  const handleClick = (e, item) => {
    // e.preventDefault();
    // console.log('handleClick',item);   
  }

  return (
    <LinkText url={`/search/discovery/${searchType}/${searchStr}`} underline={false}>
      <ListItem key={"video-item-" + _id} classes={{ root: classes.searchItemContainer }}>
        <ListItemAvatar>
          {
            searchType === 'hashtags'
            ?
              <Avatar classes={{ root: classes.searchItemHashAvatar }}>
                #            
              </Avatar>
            :
              <Avatar classes={{ root: classes.searchItemAvatar }}>
                <SearchRoundedIcon />
              </Avatar>
          }          
        </ListItemAvatar>
        <ListItemText 
          primary={searchStr}
          classes={{
            root: classes.searchItemTextContainer,
            primary: classes.listTextBold,
            secondary: classes.listText, 
          }}
        /> 
      </ListItem>
    </LinkText>
  );
}