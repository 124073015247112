import React, { useContext, useEffect } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { GlobalBottomBarContext } from '../contexts/GlobalBottomBarContext';
import { GlobalContext } from '../contexts/GlobalContext';
import { DrawerSignUpContext } from '../contexts/DrawerSignUpContext';
import { DrawerContentContext } from '../contexts/DrawerContentContext';
import { AuthContext } from '../contexts/AuthContext';
import { GlobalBottomBarDefault, GridBottomBarDefault, GridItemBottomBarDefault, fontSize25White, fontSize25Black, fontSize25Active, plusIcon, messageDot, messageTextWrapper } from '../styles/defaultGlobalBottomBarStyles';
import GlobalBottomBarButton from './GlobalBottomBarButton';
import GlobalBottomBarMainButton from './GlobalBottomBarMainButton';
import HomeIcon from './buttons/HomeIcon';
import HomeIconWhite from './buttons/HomeIconWhite';
import HomeIconActive from './buttons/HomeIconActive';

import SearchIcon from './buttons/SearchIcon';
import SearchIconWhite from './buttons/SearchIconWhite';
import SearchIconActive from './buttons/SearchIconActive';

import MessageIcon from './buttons/MessageIcon';
import MessageIconWhite from './buttons/MessageIconWhite';
import MessageIconActive from './buttons/MessageIconActive';

import ProfileIcon from './buttons/ProfileIcon';
import ProfileIconWhite from './buttons/ProfileIconWhite';
import ProfileIconActive from './buttons/ProfileIconActive';

import VideoIconActive from './buttons/VideoIconActive';
import DollarSignActive from './buttons/DollarSignActive';

let timer = null;

const useStyles = makeStyles((theme) => ({ 
    GlobalBottomBarDefault: GlobalBottomBarDefault,
    GridBottomBarDefault: GridBottomBarDefault,
    GridItemBottomBarDefault: GridItemBottomBarDefault,
    fontSize25White: fontSize25White,
    fontSize25Black: fontSize25Black,
    fontSize25Active: fontSize25Active,
    plusIcon: plusIcon,
    messageDot: messageDot,
    messageTextWrapper: messageTextWrapper
}));

function GlobalBottomBar() {
    const classes = useStyles();    
    const location = useLocation();
    const history = useHistory();
    const { AuthContextState } = useContext(AuthContext);
    const { isAuthenticated, userType } = AuthContextState;
    const { GlobalContextState, GlobalContextDispatch } = useContext(GlobalContext);
    const { isNewActivites=false, newCount=0, newCountMessages=0, newCountLike=0, newCountFollow=0, newCountSubscriber=0, isActivitiesIntial=true, iOSInstaller, isPwaMobile } = GlobalContextState;     
    const { DrawerSignUpContextDispatch } = useContext(DrawerSignUpContext);
    const { DrawerContentContextDispatch } = useContext(DrawerContentContext);
    const { GlobalBottomBarContextState, GlobalBottomBarContextDispatch } = useContext(GlobalBottomBarContext);
    const { activeState='home', hideBottomBar=true, themeColor='balck' } = GlobalBottomBarContextState;
    
    const showDrawerClick = (e) => {
        DrawerSignUpContextDispatch({ type: "SHOW_DRAWER", payload: true });
    }

    const showContentDrawerClick = (e) => {
        DrawerContentContextDispatch({ type: "SHOW_DRAWER" });
    }
    
    const NewActivitiesText = (cb) => {
        cb();
        return (
            <div className={ classes.messageTextWrapper } onClick={(e) => RemoveMessageBubble(e)}>
                <div className={"messageText"}>
                    {
                        newCountMessages !== 0
                        ?
                            newCountMessages + " message" + (newCountMessages === 1 ? " " : "s ")
                        :
                            null                    
                    }
                    {
                        newCountLike !== 0
                        ?
                            newCountLike + " like" + (newCountLike === 1 ? " " : "s ")
                        :
                            null                    
                    }
                    {
                        newCountFollow !== 0
                        ?
                            newCountFollow + " follower" + (newCountFollow === 1 ? " " : "s ")
                        :
                            null                    
                    }
                    {
                        newCountSubscriber !== 0
                        ?
                            newCountSubscriber + " subscriber" + (newCountSubscriber === 1 ? " " : "s ")
                        :
                            null                    
                    }
                </div>
                <div className={"messageArrow" + ( isPwaMobile ? " pwa" : "")}></div>
            </div>
        );
    }
    
    const RemoveMessageBubble = () => {
        if (timer) {
            clearTimeout(timer)
        }
        GlobalContextDispatch({ type: "UPDATE_ACTIVITIES_INITIAL", payload: { isActivitiesIntial: false } });
        history.push('/account/messages');
        return true;
    }
    
    const RemoveMessageTimer = () => {
        console.log('RemoveMessageTimer')
        timer = setTimeout(() => {      
            GlobalContextDispatch({ type: "UPDATE_ACTIVITIES_INITIAL", payload: { isActivitiesIntial: false } });
        }, 15000);
        return () => clearTimeout(timer); 
    }

    return (
        <Container maxWidth={false} classes={{root : classes.GlobalBottomBarDefault }} className={(hideBottomBar || iOSInstaller ? " hideBar" : "") + (themeColor === "black" ? " blackBar" : " whiteBar")}>
            <Grid 
                container
                justify="center"
                alignItems="center"
                direction="row"
                spacing={3}
                classes={{root : classes.GridBottomBarDefault}}
            >
                <Grid item xs={2} align="center" classes={{root : classes.GridItemBottomBarDefault}}>
                    <GlobalBottomBarButton url={"/videos"} directionBool={true}>
                        {
                            activeState === "home"
                            ?
                                <HomeIconActive />
                            :
                                themeColor === "black"
                                ?
                                    <HomeIconWhite />
                                :
                                    <HomeIcon />
                        }
                    </GlobalBottomBarButton>
                </Grid>
                <Grid item xs={2} align="center" classes={{root : classes.GridItemBottomBarDefault}}>
                    <GlobalBottomBarButton url={"/search"} directionBool={true}>
                        {
                            activeState === "search"
                            ?
                                <SearchIconActive />
                            :
                                themeColor === "black"
                                ?
                                    <SearchIconWhite />
                                :
                                    <SearchIcon />
                        }
                    </GlobalBottomBarButton>
                </Grid>
                <Grid item xs={3} align="center" classes={{root : classes.GridItemBottomBarDefault}}>
                    {
                        isAuthenticated
                        ?
                            userType === "user"
                            ?
                                <GlobalBottomBarMainButton url={"/search"} directionBool={true}>
                                    <DollarSignActive />
                                </GlobalBottomBarMainButton>
                            :
                                <GlobalBottomBarMainButton callbackHandler={showContentDrawerClick}>
                                    <VideoIconActive />
                                </GlobalBottomBarMainButton>
                        :
                            <GlobalBottomBarMainButton callbackHandler={showDrawerClick}>
                                <VideoIconActive />
                            </GlobalBottomBarMainButton>
                    }              
                </Grid>
                <Grid item xs={2} align="center" classes={{root : classes.GridItemBottomBarDefault}}>
                    {
                        (newCountMessages !== 0 || newCountLike !== 0 || newCountFollow !== 0 || newCountSubscriber !== 0) && isActivitiesIntial
                        ?
                            NewActivitiesText(RemoveMessageTimer)
                        :
                            null
                    }
                    {
                        isAuthenticated
                        ?
                            <GlobalBottomBarButton url={"/account/messages"} directionBool={true}> 
                                {
                                    isNewActivites && !isActivitiesIntial
                                    ?
                                        <div className={ classes.messageDot } /> 
                                    :
                                        null
                                }                                                            
                                {
                                    activeState === "messages"
                                    ?
                                        <MessageIconActive />
                                    :
                                        themeColor === "black"
                                        ?
                                            <MessageIconWhite />
                                        :
                                            <MessageIcon />
                                }
                            </GlobalBottomBarButton>
                        :
                            <GlobalBottomBarButton callbackHandler={showDrawerClick}>                              
                                {
                                    activeState === "messages"
                                    ?
                                        <MessageIconActive />
                                    :
                                        themeColor === "black"
                                        ?
                                            <MessageIconWhite />
                                        :
                                            <MessageIcon />
                                }
                            </GlobalBottomBarButton>                    
                    }                    
                </Grid>
                <Grid item xs={2} align="center" classes={{root : classes.GridItemBottomBarDefault}}>
                    {
                        isAuthenticated
                        ?
                            <GlobalBottomBarButton url={"/account/profile"} directionBool={true}>
                                {
                                    activeState === "profile"
                                    ?
                                        <ProfileIconActive />
                                    :
                                        themeColor === "black"
                                        ?
                                            <ProfileIconWhite />
                                        :
                                            <ProfileIcon />
                                }
                            </GlobalBottomBarButton>
                        :
                            <GlobalBottomBarButton callbackHandler={showDrawerClick}>
                                {
                                    activeState === "profile"
                                    ?
                                        <ProfileIconActive />
                                    :
                                        themeColor === "black"
                                        ?
                                            <ProfileIconWhite />
                                        :
                                            <ProfileIcon />
                                }                                
                            </GlobalBottomBarButton>
                    }
                </Grid>
            </Grid>
        </Container>      
    );
}

export default React.memo(GlobalBottomBar);