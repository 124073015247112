export const DrawerMessageInitialState = {
    initialMessageLoad: true,
    showDrawerMessage: false,    
    message: "",
    messageeId: "",
    videoId: "",
    deleteMessage: false,
    post: false,
    get: false,
    getInfinite: false,
    messages: [],
    pageNo: 0,
    itemPerPage: 10,
    messageCount: 0,
    pageCount: 0,
    currentIndex: null,
    indexUpdateMessageCount: []    
}