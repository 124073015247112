import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import LinkText from "./LinkText.3";
import LinkButton from './LinkButton';
import { defaultGradientPurpleBtn } from '../styles/defaultButtonStyles.2';
import { DrawerSignUpContext } from '../contexts/DrawerSignUpContext';
import { AuthContext } from '../contexts/AuthContext';

const useStyles = makeStyles((theme) => ({  
  defaultGradientPurpleBtn: defaultGradientPurpleBtn, 
  image: {
    top: "50%",
    left: "50%",
    minHeight: "100%",
    minWidth: "100%",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    zIndex: "-1"
  },
  paperAnchorBottom: {
    padding: "20px 20px 40px",
    borderTopRightRadius: "20px",
    borderTopLeftRadius: "20px"
  },
  headerLabel: {
    fontSize: '20px',
    fontWeight: '600',
    color: 'rgba(20,24,34,1)',
    display: 'block',
    margin: '0 auto 10px'
  },
  headerLabelPurlpe: {
    fontSize: '20px',
    fontWeight: '600',
    color: 'rgba(98,74,251,1)'
  },
  privacyText: {
    display:'block',
    margin: '20px auto',
    fontSize: '14px',
    color: 'rgba(139,140,144,1)'
  },
  text: {
    fontSize: '14px',
    fontWeight: '500',
    color: 'rgba(20,24,34,1)'
  },
  labelBtn: {
    fontSize: '16px',
    fontWeight: '600',
    textTransform: 'none'
  },
  closeIcon: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    fontSize: '18px',
    color: 'rgba(0,0,0,1)'
  }
}));

function DrawerSignUp(props) {
  const classes = useStyles();
  const { AuthContextState } = useContext(AuthContext);
  const { isAuthenticated } = AuthContextState;
  const { DrawerSignUpContextState, DrawerSignUpContextDispatch } = useContext(DrawerSignUpContext);
  const { showDrawer } = DrawerSignUpContextState;
 
  const handleClick = (e) => {    
    DrawerSignUpContextDispatch({ type: "HIDE_DRAWER" });
  }

  return (    
    <Drawer classes={{ paperAnchorBottom: classes.paperAnchorBottom }} transitionDuration={{ enter: 100, exit: 100 }} anchor={'bottom'} open={showDrawer}>
        <CloseIcon classes={{ root: classes.closeIcon }}  onClick={(e) => handleClick(e)}/>
        <Typography variant={"body2"} component={"span"} align="center" classes={{ root: classes.headerLabel }}>
            Signup to <Typography variant={"body2"} component={"span"} align="center" classes={{ root: classes.headerLabelPurlpe }}>GLOSS</Typography> to continue
        </Typography>
        <LinkButton url={"/registration/verify-contact/user"} directionBool={true} callbackHandler={handleClick}>
          <Button variant="outlined" color="primary" classes={{ root: classes.defaultGradientPurpleBtn, label: classes.labelBtn }}>
              Signup as a GLOSS User
          </Button>
        </LinkButton>
        <LinkButton url={"/registration/verify-contact/creator"} directionBool={true} callbackHandler={handleClick}>
          <Button variant="outlined" color="primary" classes={{ root: classes.defaultGradientPurpleBtn, label: classes.labelBtn }}>
              Signup as a GLOSS Creator
          </Button>
        </LinkButton>
        <Typography variant={"body2"} component={"span"} align="center" classes={{ root: classes.privacyText }}>
            By signing up, you confirm that you agree to our Terms of Use and have read and understood our Privacy Policy.
        </Typography>
        <Typography variant={"body2"} component={"span"} align="center" classes={{ root: classes.text }}>
            Already have an account? <LinkText url={"/login"}>Login here</LinkText>
        </Typography>
    </Drawer>
  );
}

export default DrawerSignUp;