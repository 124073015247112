import React from 'react';

export default class PencilWhite extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="15.773" height="15.32" viewBox="0 0 15.773 15.32">
                <g id="Iconly_Light-Outline_Edit" data-name="Iconly/Light-Outline/Edit" transform="translate(-2 -3)">
                    <g id="Edit" transform="translate(2 3)">
                    <path id="Combined-Shape" d="M15.167,14.106a.607.607,0,1,1,0,1.213H9.3a.607.607,0,1,1,0-1.213ZM11.418.529l1.394,1.083a2.282,2.282,0,0,1,1.022,1.436,2.315,2.315,0,0,1-.3,1.78l-.012.018-.006.008c-.055.072-.293.373-1.494,1.878a.478.478,0,0,1-.038.056.606.606,0,0,1-.066.073l-.259.326-.184.231L10.109,9.128l-.275.344L5.782,14.543a1.979,1.979,0,0,1-1.526.739l-2.943.037H1.3a.607.607,0,0,1-.59-.467L.052,12.045A1.918,1.918,0,0,1,.424,10.4L8.044.868,8.052.857A2.43,2.43,0,0,1,11.418.529ZM7.194,3.872,1.371,11.156a.711.711,0,0,0-.138.611L1.784,14.1l2.458-.031a.767.767,0,0,0,.593-.285L7.451,10.51l.337-.422h0l.338-.423,2.515-3.148ZM8.987,1.631,7.952,2.924,11.4,5.57c.663-.831,1.1-1.378,1.14-1.43a1.1,1.1,0,0,0,.115-.812,1.141,1.141,0,0,0-.527-.717c-.057-.04-1.42-1.1-1.462-1.131A1.211,1.211,0,0,0,8.987,1.631Z" fill="#fff" fill-rule="evenodd"/>
                    </g>
                </g>
            </svg>
        )
    }
}