import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { defaultVideoButton, defaultVideoIconButton } from '../styles/defaultButtonStyles';

const useStyles = makeStyles((theme) => ({
  defaultVideoButton: defaultVideoButton,
  defaultVideoIconButton: defaultVideoIconButton,
  closeVideoButton: {
    display: "block"
  }
}));

export default function CloseVideoButton(props) {
  const { handleClick = (e) => { }, isActive=false, id="", facingMode='user' } = props;
  const classes = useStyles();
  if (isActive) {
    return (    
      <button type="button" id={id} className={classes.closeVideoButton + " " + classes.defaultVideoButton} onClick={(e) => handleClick(e)}>
        <CloseRoundedIcon classes={{ root: classes.defaultVideoIconButton }} className={"active"} />      
      </button>
    )
  } else {
    return (    
      <button type="button" id={id} className={classes.closeVideoButton + " " + classes.defaultVideoButton} disabled>
        <CloseRoundedIcon classes={{ root: classes.defaultVideoIconButton }} />
      </button>
    )
  }  
}