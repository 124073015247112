import React from 'react';

export default class SearchIcon extends React.Component {
    render() {
        return (
            <svg id="Search-alt-1" xmlns="http://www.w3.org/2000/svg" width="22.004" height="21.999" viewBox="0 0 22.004 21.999">
                <path id="Path_5" data-name="Path 5" d="M9.565,19.129a9.565,9.565,0,1,1,9.565-9.565A9.565,9.565,0,0,1,9.565,19.129Zm0-17.217a7.652,7.652,0,1,0,7.652,7.652A7.652,7.652,0,0,0,9.565,1.913Z"/>
                <path id="Path_6" data-name="Path 6" d="M21.726,22.683a.956.956,0,0,1-.679-.277l-5.06-5.06a.96.96,0,1,1,1.358-1.358l5.06,5.05a.966.966,0,0,1-.679,1.645Z" transform="translate(-0.684 -0.684)"/>
            </svg>         
        )
    }
}