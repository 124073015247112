export const GlobalContextReducer = (state, action) => {
    switch(action.type){
        case 'SHOW_CIRCLE_SPINNER':
            return { 
                ...state,
                showCircleSpinner: action.payload.showCircleSpinner
            }
        case 'SHOW_BACKDROP':
            return { 
                ...state,
                showBackdrop: action.payload.showBackdrop
            }
        case 'TOGGLE_IS_LOADING':
            return {
                ...state, 
                isLoading: action.payload.isLoading
            }
        case 'SET_INITIAL_LOAD':
            return { 
                ...state,
                initialLoad: action.payload.initialLoad
            }
        case 'SET_MOBILE':
            return { 
                ...state,
                isMobile: action.payload.isMobile
            }    
        case 'SET_PWA_MOBILE':
            return { 
                ...state,
                isPwaMobile: action.payload.isPwaMobile
            }
        case 'SET_PWA_DESKTOP':
            return { 
                ...state,
                isPwaDesktop: action.payload.isPwaDesktop
            }         
        case 'SET_ANIMATION_COMPLETE':
            return { 
                ...state,
                animationComplete: action.payload.animationComplete
            }
        case 'SHOW_ISO_INSTALLER':
            return { 
                ...state,
                iOSInstaller: true
            }
        case 'HIDE_ISO_INSTALLER':
            return { 
                ...state,
                iOSInstaller: false
            }
        case 'UPDATE_ACTIVITIES_INITIAL':
            return {
                ...state,
                isActivitiesIntial: action.payload.isActivitiesIntial
            }
        case 'UPDATE_ACTIVITIES_NEW_COUNT':
            return { 
                ...state,
                newCount: action.payload.newCount
            }
        case 'UPDATE_ACTIVITIES_NEW_COUNT_MESSAGES':
            return { 
                ...state,
                newCountMessages: action.payload.newCountMessages
            }       
        case 'UPDATE_ACTIVITIES_NEW_LIKE':
            return { 
                ...state,
                newCountLike: action.payload.newCountLike
            }  
        case 'UPDATE_ACTIVITIES_NEW_COUNT_FOLLOW':
            return { 
                ...state,
                newCountFollow: action.payload.newCountFollow
            } 
        case 'UPDATE_ACTIVITIES_NEW_COUNT_SUBSCRIBER':
            return { 
                ...state,
                newCountSubscriber: action.payload.newCountSubscriber
            }          
        case 'NEW_ACTIVITIES':
            return { 
                ...state,
                isNewActivites: true,
                newCount: action.payload.newCount,
                newCountMessages: action.payload.newCountMessages,
                newCountLike: action.payload.newCountLike,
                newCountFollow: action.payload.newCountFollow,
                newCountSubscriber: action.payload.newCountSubscriber
            } 
        case 'NO_NEW_ACTIVITIES':
            return { 
                ...state,
                isNewActivites: false
            }
        case 'NO_NEW_ACTIVITIES_ALL':
            return { 
                ...state,
                isNewActivites: false,
                newCount: 0,
                newCountMessages: 0,
                newCountLike: 0,
                newCountFollow: 0,
                newCountSubscriber: 0
            }                     
        default:
            return state    
    }
}