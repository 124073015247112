// framer support for grid container
export const loginContainerStyle = {
  padding: "0",
  width: "100vw",
  height: "100vh",
  backgroundColor : "rgba(255,255,255,1)"
};

export const containerStyle = {
  padding: "0",
  // width: "100vw",
  // height: "100vh",
  overflow: "hidden auto",
  backgroundColor : "rgba(255,255,255,1)",
  color: "rgba(0,0,0,1)",
  boxSizing: "inherit",
  position: "relative",
  zIndex: "0"
};

export const containerGridStyle = {
  width: "100vw", 
  height: "100vh", 
  padding: "100px 0 0", 
  margin: "0"
}

export const containerGridStyle2 = {
  width: "100vw", 
  height: "100vh", 
  padding: "110px 0 0", 
  margin: "0"
}

export const videosContainerStyle = {
  // padding: "0 0 62px",
  // width: "100vw",
  // height: "100vh",
  overflow: "hidden",
  // backgroundColor : "rgba(0,0,0,1)",
  // color: "rgba(0,0,0,1)",
  // boxSizing: "inherit",
  // position: "relative",
  // zIndex: "0"
  position: "relative",
  width: "100vw",
  height: "100vh",
  scrollSnapType: "y mandatory",
  overflowY: "scroll",
  padding: "0",

}

export const videosCaptureContainerStyle = {
  overflow: "hidden",
  position: "relative",
  width: "100vw",
  height: "100vh",
  padding: "0",
}

export const subContainerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0",
  width: "100vw",
  height: "100vh",
  scrollSnapAlign:"start",
  overflow: "hidden",
  position: "relative",
};

export const subContainerStyle2 = {
  width: "100vw",
  height: "calc(100vh - 75px)"
}

export const subContainerStyle3 = {
  minWidth: "100%", 
  minHeight: "100%",
  width: "auto",
  height: "auto",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
}

export const subItemContainerStyle = {
  padding: "0",
  width: "100vw",
  height: "auto",
  overflow: "hidden",
  backgroundColor : "rgba(255,255,255,1)",
  color: "rgba(0,0,0,1)",
  boxSizing: "inherit",
  position: "relative",
  zIndex: "0"
};

export const containerBlackStyle = {
  padding: "0",
  width: "100vw",
  height: "100vh",
  backgroundColor : "rgba(0,0,0,1)",
  color: "rgba(255,255,255,1)",
  boxSizing: "inherit",
  position: "relative",
  zIndex: "0"
};

export const splashContainerStyle = {
  padding: "0",
  // width: "100vw",
  // height: "100vh",
  backgroundColor : "rgba(0,0,0,1)"
};

export const formGridStyle = {
  // height: "100vh", 
  height: "100%", 
  padding: "120px 20px 10px", 
  margin: "0"
}

export const formGridStyle2 = {
  height: "100vh",
  padding: "0px", 
  margin: "0"
}

export const formGridButtonSubmit = {
  // position: "absolute", 
  // bottom: "calc(100vh - 100vh)", 
  // right: "0px"
  position: "relative",
  margin: "20px auto"
}

export const inputReadOnly = {
  color: "rgba(100,100,100,1)"
}

export const headerDefaultStyle = {
  color: "rgba(10,10,10,1)",
  fontWeight: "700",
  fontSize: "22px"
}

export const headerUpgradeStyle = {
  color: "rgba(10,10,10,1)",
  fontWeight: "700",
  fontSize: "22px",
  width: "auto",
  margin: "auto 30px",
  textAlign: "center"
}

export const bodyDefaultStyle = {
  fontSize: "14px",
  color: "rgba(60,60,60,1)",
  fontWeight: "400"
}

export const bodyFormDefaultStyle = {
  fontSize: "16px",
  color: "rgba(0,0,0,1)",
  fontWeight: "400",
  lineHeight: "30px",
  padding: "6px 0 7px"
}

export const bodyFormDefaultStyle2 = {
  fontSize: "16px",
  color: "rgba(0,0,0,1)",
  fontWeight: "400",
  height: "auto",
  lineHeight: "35px",
  padding: "6px 0 7px"
}

export const bodyDefaultStyle2 = {
  fontSize: "16px",
  color: "rgba(60,60,60,1)",
  fontWeight: "400",
  display: "block",
  width: "inherit",
  textAlign: "center",
  margin: "20px auto"
}

export const bodyDefaultStyle3 = {
  fontSize: "14px",
  color: "rgba(0,0,0,1)",
  fontWeight: "400"
}

export const bodyDefaultStyle4 = {
  fontSize: "14px",
  color: "rgba(112,112,112,1)",
  fontWeight: "400",
  margin: "5px 0 0 0"
}

export const bodyDefaultStyle5 = {
  fontSize: "12px",
  color: "rgba(0,0,0,1)",
  fontWeight: "400"
}

export const bodyDefaultStyle6 = {
  fontSize: "14px",
  lineHeight: "30px",
  color: "rgba(0,0,0,1)",
  fontWeight: "400"
}

export const topNavigationDefaultStyle = {
  color: "rgba(60,60,60,1)",
  fontWeight: "200"
}

export const formGridMarginContent = {
  margin: "20px 0"
}

// export const imgCoverStyle = {
//     display: "block",
//     width: "100vw",
//     height: "100vh",
//     objectFit: "cover"
// }

export const imgCoverStyle = {
  top: "50%",
  left: "50%",
  minHeight: "100%",
  minWidth: "100%",
  position: "absolute",
  transform: "translate(-50%,-50%)",
  zIndex: "-1"
}

export const imgContainStyle = {
  display: "block",
  width: "100vw",
  height: "100vh",
  objectFit: "contain"
}

export const desciptionBar = {
  bottom: "85px",
  left: "0px",
  color: "rgba(255,255,255,1)",
  width: "80vw",
  height: "auto",
  maxHeight: "30vh",
  position: "absolute",
  backgroundColor: "transparent",
  overflow: "hidden"
}

export const sideBar = {
  padding: "0",
  margin: "0",
  color: "rgba(0,0,0,1)", 
  position: "absolute", 
  top: "0px", 
  right: "0px", 
  height: "auto", 
  width: "65px",
  zIndex: "1", 
  backgroundColor: "transparent",
  transition: "all 100ms linear",
  "-webkit-transition": "all 100ms linear",
  "-moz-transition": "all 100ms linear",
  "-o-transition": "all 100ms linear",
  "&.hideSideBar": {
    right: "-75px"
  }
}

export const sideBarWhite = {
  padding: "0",
  margin: "0",
  color: "rgba(255,255,255,1)", 
  position: "absolute", 
  top: "50px", 
  left: "0px", 
  height: "auto", 
  width: "100vw",
  zIndex: "1",
  backgroundColor: "transparent",
  transition: "all 100ms linear",
  "-webkit-transition": "all 100ms linear",
  "-moz-transition": "all 100ms linear",
  "-o-transition": "all 100ms linear",
  "&.hideSideBar": {
    right: "-75px"
  }
}

export const topBar = {
  padding: "0px",
  margin: "0",
  color: "rgba(0,0,0,1)", 
  position: "absolute", 
  top: "50px", 
  left: "0px", 
  height: "auto", 
  width: "100vw",
  zIndex: "2", 
  backgroundColor: "transparent",
  transition: "all 100ms linear",
  "-webkit-transition": "all 100ms linear",
  "-moz-transition": "all 100ms linear",
  "-o-transition": "all 100ms linear",
  "&.hideTopBar": {
    top: "-100px"
  }
}

export const topBarWhite = {
  padding: "0px",
  margin: "0",
  color: "rgba(255,255,255,1)", 
  position: "absolute", 
  top: "20px", 
  right: "0px", 
  height: "auto", 
  width: "65px", 
  zIndex: "2",
  backgroundColor: "transparent",
  transition: "all 100ms linear",
  "-webkit-transition": "all 100ms linear",
  "-moz-transition": "all 100ms linear",
  "-o-transition": "all 100ms linear",
  "&.hideTopBar": {
    top: "-100px"
  }
}

export const textShadow = {
  textShadow: "2px 2px rgba(0,0,0,1)"
}

export const textShadowBold = {
  textShadow: "2px 2px rgba(0,0,0,1)",
  fontWeight: "700"
}

export const textLink = {
  fontSize: "14px",
  textDecoration: "none"
}

export const textBoldLink = {
  fontSize: "14px",
  fontWeight: "700",
  textDecoration: "none"
}