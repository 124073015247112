export const feedAvatarWrapper = {
  display: "inline-block"
}

export const feedAvatar = {
  width: "50px",
  height: "50px",
  position: "absolute",
  top: "12px",
  "&.top22" : {
    top: "22px"
  }
}

export const feedAvatar2 = {
  width: "50px",
  height: "50px",
  position: "absolute",
  top: "12px",
  right: "15px",
  "&.top22" : {
    top: "22px"
  }
}

export const feedImageAvatar = {
  width: "40px",
  height: "40px",
  position: "absolute",
  top: "12px"
}

export const feedImageAvatar2 = {
  width: "40px",
  height: "40px",
  position: "absolute",
}

export const feedAvatarName = {
  // height: "50px",
  // lineHeight: "50px",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  display: "inline-block",
  width: "100%",
  paddingLeft: "50px",
  textShadow: "rgba(0, 0, 0, 1) 1px 1px",
  fontSize: "12px",
  fontWeight: "600",
  position: "relative",
  top: "3px"
}

export const feedAvatarName2 = {
  // height: "50px",
  lineHeight: "40px",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  display: "inline-block",
  width: "auto",
  paddingLeft: "50px",
  textShadow: "rgba(0, 0, 0, 1) 1px 1px",
  fontSize: "14px",
  fontWeight: "600",
  position: "relative"
}

export const feedFollowButton = {
  // height: "50px",
  lineHeight: "10px",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  display: "inline-block",
  width: "auto",
  padding: "10px 15px",
  textShadow: "rgba(0, 0, 0, 0) 1px 1px",
  fontSize: "14px",
  fontWeight: "400",
  position: "relative",
  margin: "5px 10px",
  border: "1px solid rgba(255,255,255,1)",
  borderRadius: "5px"
}

export const profileAvatar = {
  width: "100px",
  height: "100px",
  position: "relative",
  backgroundColor: "rgba(192,192,192,1)",
  border: "0px solid rgba(192,192,192,0)",
  margin: "0 auto",
  boxSizing: "border-box",
  fontSize: "50px",
  'webkit-animation': '$bganimate 2s infinite',
  '-moz-animation': '$bganimate 2s infinite',
  '-o-animation': '$bganimate 2s infinite',
  animation: "$bganimate 2s infinite",
  "&.active": {
    border: " 4px solid rgba(98,74,251,1)"
  }  
}

export const commentItemAvatar = {
  width: "50px",
  height: "50px",
  fontSize: " 20px",
  margin: "0 auto"
}

// export const listAvatarWrapper = {
//   minWidth: "45px"
// }

// export const listAvatar = {
//   width: "50px",
//   height: "50px"
// }

// export const tagAvatar = {
//   width: "50px",
//   height: "50px"
// }

// export const searchItemAvatar = {
//   width: "50px",
//   height: "50px"
// }

// export const searchItemHashAvatar = {
//   width: "50px",
//   height: "50px",
//   color: "rgba(0,0,0,1)",
//   background: "none",
//   fontSize: "50px",
//   fontWeight: "300"
// }



// export const moodAvatar = {
//   width: "100px",
//   height: "100px",
//   position: "relative",
//   backgroundColor: "rgba(253,45,85,0.2)",
//   border: "0px solid rgba(253,45,85,0)",
//   boxSizing: "border-box",
//   margin: "0 auto",
//   "&.active": {
//     border: " 4px solid rgba(253,45,85,1)"
//   }
// }

