import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom"; //https://dev.to/projectescape/programmatic-navigation-in-react-3p1l

import { Container, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { containerStyleWhite2, formGridStyle, formGridButtonSubmit, headerDefaultStyle, bodyDefaultStyle, formGridMarginContent } from '../../styles/defaultStyles.2';
import { ErrorTextStyle, ErrorTextContainerStyle } from '../../styles/defaultErrorStyles';
import { inputDefault, inputUnderline, inputCountryCodeDefault, inputPhoneWithAreaCodeDefault, inputPhoneUnderline, areaCodeInputhWidth } from '../../styles/defaultInput';
import { gridItemHeaderWrapper } from '../../styles/defaultGridStyles';

import LinkText from '../../components/LinkText.3';
import SubmitButton from '../../components/SubmitButton.2';
import HelmetComponent from "../../components/HelmetComponent";

import { FormErrorContext } from '../../contexts/FormErrorContext';
import { FramerContext } from '../../contexts/FramerContext';
import { GlobalContext } from '../../contexts/GlobalContext';
import { AuthContext } from '../../contexts/AuthContext';
import { DrawerSignUpContext } from '../../contexts/DrawerSignUpContext';

import { LoginService } from "../../services/RegistrationServices";
import { CountryCode } from "../../constants/CountryCode";

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    '& .Mui-focused': {
      '& .MuiSelect-root': {
        backgroundColor: 'rgba(255,255,255,1)'       
      }      
    },
    '& .MuiSelect-root': inputCountryCodeDefault,
    '& .MuiInput-underline': {
      '&::before': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.2)'
      }  
    }   
  },  
  terms: {
    color: "rgba(146, 147, 151, 1)",
    fontSize: "0.8rem",
    padding: "10px 0"
  },
  buttonGridWrapper: {
    margin: "0 auto 30px",
    border: "1px solid rgba(241,241,241,1)"
  },
  defaultButtonStyles: {
    width: "50%",
    backgroundColor: "rgba(241,241,241,1)",
    border: "none !important"
  },
  defaultButtonActiveStyles: {
    width: "50%",
    backgroundColor: "rgba(255,255,255,1)",
    border: "none !important"
  },
  defaultButtonLabelActiveStyle: {    
    fontSize: "16px",
    fontWeight: "600",
    color: "rgba(0,0,0,1)"
  },
  defaultButtonLabelStyle: {
    fontSize: "16px",
    fontWeight: "600",
    color: "rgba(175,175,179,1)"
  },
  containerStyleWhite2: containerStyleWhite2,
  gridItemHeaderWrapper: gridItemHeaderWrapper, 
  formGridStyle: formGridStyle, 
  formGridButtonSubmit: formGridButtonSubmit, 
  formGridMarginContent: formGridMarginContent,
  headerDefaultStyle: headerDefaultStyle,
  bodyDefaultStyle: bodyDefaultStyle, 
  ErrorTextStyle: ErrorTextStyle, 
  ErrorTextContainerStyle: ErrorTextContainerStyle,
  inputDefault: inputDefault, 
  inputUnderline: inputUnderline, 
  inputCountryCodeDefault: inputCountryCodeDefault,
  inputPhoneWithAreaCodeDefault: inputPhoneWithAreaCodeDefault,  
  inputPhoneUnderline: inputPhoneUnderline,
  areaCodeInputhWidth: areaCodeInputhWidth
}));

export default function Login() {
  const classes = useStyles();  
  let history = useHistory();
  const { GlobalContextDispatch } = useContext(GlobalContext);
  const { AuthContextState, AuthContextDispatch } = useContext(AuthContext);
  const { DrawerSignUpContextState, DrawerSignUpContextDispatch } = useContext(DrawerSignUpContext);
  const { showDrawer } = DrawerSignUpContextState;
  // const { tokenExpired, registerType } = useParams();
  // const tokenBoolean = ((tokenExpired === 'expired') ? true : false);
  // const type = ((registerType === 'creator') ? registerType : 'user');
  const { toggleDirection } = useContext(FramerContext);
  const { FormErrorContextState, FormErrorContextDispatch } = useContext(FormErrorContext);
  const { phoneError, emailError, passwordError, requestError } = FormErrorContextState;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [active, setActive] = useState('phone');
  const [isActive, setIsActive] = useState(false);
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [value, setValue] = useState(0);
  const [countryCode, setCountryCode] = React.useState('+1');

  const [password, setPassword] = useState('');  
  const [showPassword, setShowPassword] = useState(false);

  const handleChangeSelect = (event) => {
    setCountryCode(event.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  }

  useEffect(() => {
    if (showDrawer) {
      DrawerSignUpContextDispatch({ type: "HIDE_DRAWER", payload: false });    
    }    
  }, [])

   useEffect(() => {
    if (!isSubmitted) {
      if (password.length > 0 || email.length > 0 || phone.length > 0) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
      if (emailError.error || passwordError.error || requestError.error || phoneError.error) {
        FormErrorContextDispatch({ type: "RESET_ERRORS" })
      } 
    }    
    if ((!emailError.error && email.length !== 0 || !phoneError.error && phone.length !== 0) && !passwordError.error && password.length !== 0 && isSubmitted) {
      const data = {
        contact: (active === "phone" ? (countryCode.toString() + phone.toString()) : email),
        active: active,
        password: password
      }  
      LoginService(data).then(result => {
        if (result.data.success && result.data.token) {
          AuthContextDispatch({ type: "SIGN_ON", payload: { isAuthenticated: true, authToken: result.data.token, userId: result.data.userId, userType: result.data.userType }});
          if (result.data.userType === 'creator') {
            history.push('/account/profile');
          } else {
            history.push('/videos');
          } 
           toggleDirection(true,true);
        } else {
          history.push(`/login/validate-contact/${result.data.userType}/${active}/${data.contact}`)
        }
        GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});         
      }).catch(err => {
        console.log("catch",err.response.data,err.response.status);
        FormErrorContextDispatch({ type: "REQUEST_ERROR", payload: err.response.data });          
        GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});         
        setIsActive(false);
        setIsSubmitted(false);
      })
    } else {
      if (isSubmitted) {
        GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
        setIsSubmitted(false);
      } 
    }
  }, [email, password, emailError, passwordError])
  
  const handleSubmit = (e) => {
    e.preventDefault();
    GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : true }});
    switch(active) {
      case 'phone':
        FormErrorContextDispatch({ type: "VALIDATE_PHONE", payload: countryCode.toString() + phone.toString() });
        break;
      case 'email':
        FormErrorContextDispatch({ type: "VALIDATE_EMAIL", payload: email });
        break;
      default:  
    }  
    FormErrorContextDispatch({ type: "VALIDATE_PASSWORD", payload: password });
    setIsSubmitted(true);    
  }

  const handleChange = (value) => {
    setValue(value);
    setActive(value === 0 ? 'phone' : 'email');
    setPhone('');
    setEmail('');
    setIsActive(false);
    FormErrorContextDispatch({ type: "RESET_ERRORS" });
  }

  return (
    <>
      <HelmetComponent title={"DTF - Log in to DragToFans"} />   
      <Container classes={{ root: classes.containerStyleWhite2 }}>  
        <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid 
            container
            direction="column"
            spacing={0}
            classes={{ root: classes.formGridStyle }}
          >  
            <Grid item container classes={{ root: classes.buttonGridWrapper }}>
              <ButtonGroup disableElevation fullWidth={true} variant="contained">
                <Button color={"default"} classes={active !== "phone" ? { root: classes.defaultButtonStyles, label: classes.defaultButtonLabelStyle } : { root: classes.defaultButtonActiveStyles, label: classes.defaultButtonLabelActiveStyle }}onClick={(e) => handleChange(0)}>Phone</Button>
                <Button color={"default"} classes={active !== "email" ? { root: classes.defaultButtonStyles, label: classes.defaultButtonLabelStyle } : { root: classes.defaultButtonActiveStyles, label: classes.defaultButtonLabelActiveStyle }} onClick={(e) => handleChange(1)}>Email</Button>
              </ButtonGroup>
            </Grid> 
            <Grid 
              item 
              container 
              justify="center"
              alignItems="center"
              spacing={2}
            > 
              <Grid item xs={12}>
                {
                  value === 0
                  ?
                    <>
                      <TextField
                        id="country-code"
                        select
                        label=""
                        value={countryCode}
                        onChange={handleChangeSelect}
                        helperText=""
                        classes={{ root: classes.areaCodeInputhWidth }}
                      >
                        {CountryCode.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Input type="phone" classes={{ root: classes.inputPhoneWithAreaCodeDefault, underline: classes.inputPhoneUnderline }} fullWidth={true} placeholder="Phone Number" error={phoneError.error} inputProps={{ 'aria-label': 'Phone number' }} value={phone} name="phone" onChange={(e) => setPhone(e.target.value.trim())} />
                    </>
                  :
                    <Input type="email" classes={{ root: classes.inputDefault, underline: classes.inputUnderline }} fullWidth={true} placeholder="xxxxx@email.com" error={emailError.error} inputProps={{ 'aria-label': 'Email' }} value={email} name="email" onChange={(e) => setEmail(e.target.value.trim())} />  
                }                
              </Grid>
              <Grid item xs={12}>
                <Input
                  classes={{ root: classes.inputDefault, underline: classes.inputUnderline }} 
                  fullWidth={true} 
                  placeholder="password" 
                  id="standard-adornment-password"
                  error={passwordError.error} 
                  inputProps={{ 'aria-label': 'Password' }}
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value.trim())}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        classes={{ root: (password.length !== 0 ? classes.eyeActiveIcon : classes.eyeIcon) }}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
              {
                emailError.error
                ?
                  <Grid item xs={12} classes={{ root: classes.ErrorTextContainerStyle }}>
                    <Typography variant="body1" classes={{ root: classes.ErrorTextStyle }}>
                      { emailError.message }
                    </Typography>               
                  </Grid>
                :
                  null
              }
              {
                phoneError.error
                ?
                  <Grid item xs={12} classes={{ root: classes.ErrorTextContainerStyle }}>
                    <Typography variant="body1" classes={{ root: classes.ErrorTextStyle }}>
                      { phoneError.message }
                    </Typography>               
                  </Grid>
                :
                  null
              }
              {
                requestError.error
                ?
                  <Grid item xs={12} classes={{ root: classes.ErrorTextContainerStyle }}>
                    <Typography variant="body1" classes={{ root: classes.ErrorTextStyle }}>
                      { requestError.message }
                    </Typography>               
                  </Grid>
                :
                  null
              } 
            </Grid>
            
            <Grid item classes={{ root: classes.formGridMarginContent }}> 
              <Typography classes={{ root: classes.bodyDefaultStyle }}>
                Forgot password? <LinkText url={"/forgot-password"} directionBool={true}>Get help signing in</LinkText>.
              </Typography> 
            </Grid>
            <Grid container item direction="row" justify="center" alignItems="flex-end" classes={{ root: classes.formGridButtonSubmit }}>
              <SubmitButton isActive={isActive} />
            </Grid>
          </Grid>   
        </form>       
      </Container>
    </>
  );
}