import React, { useContext, useState, useEffect, useRef }  from 'react';
import { useParams, useHistory } from "react-router-dom";
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { Container, Grid } from '@material-ui/core';
import { makeStyles, withStyles  } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import LoopRoundedIcon from '@material-ui/icons/LoopRounded';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';

import { containerStyleWhite, formGridStyle3, formGridButtonSubmit, bodyDefaultStyle, bodyDefaultStyle3, bodyDefaultStyle4, actionBottom, actionBottomWrapper, actionButton, actionTextButton } from '../../styles/defaultStyles.2';
import { ErrorTextStyle, ErrorTextContainerStyle } from '../../styles/defaultErrorStyles';
import { arrowActiveIcon } from '../../styles/defaultInput';
import { textareaDefault, textareaUnderline } from '../../styles/defaultTextarea';
import { videoEditImageElement, videoImagePlayIconElement, videoImagePlayIconWrapperElement } from '../../styles/defaultVideoWrapperStyles';

import HelmetComponent from '../../components/HelmetComponent';
import LinkButton from '../../components/LinkButton';
import DialogModal from '../../components/DialogModal';
import SwipeTagLocation from '../../components/SwipeTagLocation.3';
import SwipeTagPeople from '../../components/SwipeTagPeople.2';
import ImageVideoItem from '../../components/ImageVideoItem.3';
import ImageProfileItem from '../../components/ImageProfileItem.2';
import ImageVideoOverlay from '../../components/ImageVideoOverlay';

import { FormErrorContext } from '../../contexts/FormErrorContext';
import { AuthContext } from '../../contexts/AuthContext';
import { GlobalContext } from '../../contexts/GlobalContext';
import { UserContext } from '../../contexts/UserContext';
import { VideoServiceContext } from '../../contexts/VideoServiceContext';
import { ContentContext } from '../../contexts/ContentContext';
import { TagContext } from '../../contexts/TagContext';
import { GlobalTopBarContext } from '../../contexts/GlobalTopBarContext';

import { UpdateVideoByUserIdAndVideoIdService, UploadVideoByUserIdAndVideoIdService, GetVideoByUserIdAndVideoIdService, DeleteVideoByUserIdAndVideoIdService } from '../../services/VideoServices';

import { VideoServiceInitialState } from "../../constants/VideoServiceInitialState";

const useStyles = makeStyles((theme) => ({
  containerStyleWhite: containerStyleWhite, 
  formGridStyle3: formGridStyle3, 
  formGridButtonSubmit: formGridButtonSubmit, 
  bodyDefaultStyle: bodyDefaultStyle,
  bodyDefaultStyle3: bodyDefaultStyle3,
  bodyDefaultStyle4: bodyDefaultStyle4,  
  actionBottom: actionBottom,
  actionBottomWrapper: actionBottomWrapper,
  actionButton: actionButton,
  actionTextButton: actionTextButton,    
  ErrorTextStyle: ErrorTextStyle, 
  ErrorTextContainerStyle: ErrorTextContainerStyle,
  arrowActiveIcon: arrowActiveIcon,
  videoEditImageElement: videoEditImageElement,
  videoImagePlayIconElement: videoImagePlayIconElement,
  videoImagePlayIconWrapperElement: videoImagePlayIconWrapperElement,
  vidoeGrid: {
    position: "relative",
    margin: "0",
    width: "100%",
    height: "100px"
  },
  videoIconCloud: {
    color: "rgba(253,45,85,1)",
    display: "block",
    margin: "auto",
    width: "50px",
    height: "50px"
  },
  videoLabel: {
    width: "100%",
    display: "block",
    backgroundColor: "rgba(253,45,85,0.2)",
    textAlign: "center",
    border: "2px dashed rgba(253,45,85,0.5)",
    height: "84px",
    borderRadius: "5px"
  },
  videoText: {
    color: "rgba(253,45,85,1)",
    fontWeight: "500",
    fontSize: "12px",
    fontFamily: "inherit"
  },
  textareaDefault: textareaDefault,
  textareaUnderline: textareaUnderline,
  formHelperText: {
    position: "absolute",
    right: "20px"
  },
  videoTag: {
    width: "100%",
    height: "240px"
  },
  childSpinnerWrapper: {
    height: "76px",
    width: "auto",
    margin: "auto",
    position: "relative"
  },
  childSpinner: {
    height: "76px",
    width: "auto",
    margin: "auto",
    position: "relative"
  },
  textField: {
    fontSize: "16px"
  },
  noUnderline: {
    border: "none",
    "&:hover" : {
      border: "none",
      "&:before" : {
        border: "none !important",
      }
    },  
    "&:before" : {
      border: "none",
    },
    "&:after" : {
      border: "none",
    }
  },
  tagContent: {
    borderBottom: "1px solid rgba(174,175,178,0.2)",
    paddingBottom: "10px",
    fontSize: "14px",
    color: "rgba(112,112,112,1)",
    fontWeight: "400",
    margin: "5px 0 0 0"
  },
  videoUploadWrapperElement: {
    textDecoration: "none",
    position: "absolute",
    top: "8px",
    left: "8px",
    right: "8px",
    bottom: "8px",
    backgroundColor: "rgba(0,0,0,0.6)",    
    borderRadius: "6px"
  },
  videoUploadPercentWrapperElement: {
    color: "rgba(255,255,255,1)",
    position: "absolute",
    top: "32px",
    left: "47%"
  },
  videoImageUploadIconElement: { 
    position: "relative",
    fontSize: "60px",
    color: "rgba(255,255,255,0.3)",
    width: "50px",
    height: "50px",
    margin: "16% auto",
    display: "block",
    'webkit-animation': '$rotation 2s linear infinite',
    '-moz-animation': '$rotation 2s linear infinite',
    '-o-animation': '$rotation 2s linear infinite',
    animation: '$rotation 2s linear infinite',
    animationDelay: "0s"
  }, 
  "@keyframes rotation": {
    "from": {
      transform: "rotate(359deg)"
    },
    "to": {
      transform: "rotate(0deg)"
    }
  },
  chipWrapper:{
    margin: "0 10px 10px 0"
  },
  actionBottom: {
    position: "fixed",
    bottom: "0px",
    width: "100vw",
    borderTop: "1px solid rgba(174,175,178,1)"
  }
}));

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 50,
    height: 25,
    padding: 0,
    display: 'flex',
    float: 'right',
    top: '-3px',
    position: 'relative'
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(25px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 21,
    height: 21,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 25 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {}
}))(Switch);

export default function EditContent() {
  const classes = useStyles();
  let history = useHistory();

  const videosRef = useRef();

  const { videoId=null, isNew=false } = useParams();  
  const { GlobalContextState, GlobalContextDispatch } = useContext(GlobalContext);
  const { isPwaMobile } = GlobalContextState;
  const { AuthContextState } = useContext(AuthContext);
  const { isAuthenticated, authToken, userId } = AuthContextState;  
  const { UserContextState, UserContextDispatch } = useContext(UserContext);
  const { VideoServiceContextState, VideoServiceContextDispatch } = useContext(VideoServiceContext);  
  const { data: dataContext, fileName: fileNameContext, isPrivate: isPrivateContext, isVideo: isVideoContext, videoId: videoIdContext, isUploading: isUploadingContext } = VideoServiceContextState;  

  const { FormErrorContextState, FormErrorContextDispatch } = useContext(FormErrorContext);  
  const { requestError } = FormErrorContextState;
  
  const { ContentContextState, ContentContextDispatch } = useContext(ContentContext);
  const { newChangeId, newChange, newDescription, checkedFacebook, checkedInstagram, checkedTwitter, checkedPublic, checkedPrivate, checkedActive } = ContentContextState;

  const { TagContextState, TagContextDispatch } = useContext(TagContext);
  const { taggedPeople, taggedLocation, taggedPeopleUpdate, taggedLocationUpdate, taggedVideoId } = TagContextState;
  
  const { GlobalTopBarContextState, GlobalTopBarContextDispatch } = useContext(GlobalTopBarContext);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [showTypeOfPost, setShowTypeOfPost] = useState(true);
  const [showSocial, setShowSocial] = useState(false);
  
  // const [description, setDescription] = useState('');
  const [currentDescription, setCurrentDescription] = useState('');
  
  const [videoItem, setVideoItem] = useState('');
  const [isFacingMode, setIsFacingMode] = useState(false);
  const [video, setVideo] = useState(null);
  const [videoInformation, setVideoInformation] = useState(null);
  const [videoUploadPercent, setVideoUploadPercent] = useState(0);
  
  const { enqueueSnackbar } = useSnackbar();
  const [ deleteModal, setDeleteModal ] = useState(false);
 
  const [ changeInProgress, setChangeInProgress ] = useState(newChangeId === videoId);


  const [locationAnchor, setLocationAnchor] = useState('right');
  const [locationBool, setLocationBool] = useState(false);
  
  const [peopleAnchor, setPeopleAnchor] = useState('right');
  const [peopleBool, setPeopleBool] = useState(false);  

  const onClosePeople = () => {
    setPeopleBool(false)
  }

  const onCloseLocation = () => {
    setLocationBool(false)
  }

  useEffect(() => {   
        
    console.log('newChangeId 1', newChangeId);
    console.log('videoId 1', videoId);
    console.log('changeInProgress 1', changeInProgress);
    // reset data if no changes are in progress    
    if (!changeInProgress) {
      console.log('changeInProgress 2', changeInProgress);
      ContentContextDispatch({ type: "RESET_CONTENT_DATA" });
      TagContextDispatch({ type: "RESET_TAG_DATA" });
    }

    GlobalTopBarContextDispatch({ type: "SET_ACTION", payload: { actionText: isNew ? "Post" : "Update", actionMethod: handleSubmit } });

    GetVideoByUserIdAndVideoIdService({userId: userId, videoId: videoId, authToken: authToken}).then(async (result) => {
      if (result.data.success === 1) {
        // UserContextDispatch({ type: "APPEND_USER_VIDEOS", payload: result.data });
        setVideoItem(result.data.userVideos.videos[0]);
        // setDescription(result.data.userVideos.videos[0].video_description);
        // setState({
        //   checkedFacebook: false,
        //   checkedInstagram: false,
        //   checkedTwitter: false,
        //   checkedPublic: !result.data.userVideos.videos[0].premium && result.data.userVideos.videos[0].active,
        //   checkedPrivate: result.data.userVideos.videos[0].premium && result.data.userVideos.videos[0].active,
        //   checkedActive: result.data.userVideos.videos[0].active
        // });

        console.log('newChangeId', newChangeId);
        console.log('videoId', videoId);
        console.log('changeInProgress', changeInProgress);

        if (changeInProgress) {
          console.log('changeInProgress is true', newDescription)
          ContentContextDispatch({ 
            type: "SET_INIT_CONTENT_DATA", 
            payload: { 
              newDescription: newDescription,
              checkedFacebook: checkedFacebook,
              checkedInstagram: checkedInstagram,
              checkedTwitter: checkedTwitter,
              checkedPublic: checkedPublic,
              checkedPrivate: checkedPrivate,
              checkedActive: checkedActive 
            } 
          })
          setCurrentDescription(newDescription);
        } else {
          console.log('changeInProgress is false', result.data.userVideos.videos[0].video_description)
          ContentContextDispatch({ 
            type: "SET_INIT_CONTENT_DATA", 
            payload: { 
              newDescription: result.data.userVideos.videos[0].video_description,
              checkedFacebook: false,
              checkedInstagram: false,
              checkedTwitter: false,
              checkedPublic: !result.data.userVideos.videos[0].premium && result.data.userVideos.videos[0].active,
              checkedPrivate: result.data.userVideos.videos[0].premium && result.data.userVideos.videos[0].active,
              checkedActive: result.data.userVideos.videos[0].active 
            } 
          })
          setCurrentDescription(result.data.userVideos.videos[0].video_description);
        }
        
        if (result.data.taggedPeople.length !== 0 && taggedPeople.length === 0) {
          const taggedPeopleResults = await result.data.taggedPeople.map(function(item){ 
            return { 
              _id: item.user._id, 
              username: item.user.username, 
              handlename: item.user.handlename,
              user_information: {
                _id: item.user_information._id,
                user_image_profile: item.user_information.user_image_profile
              } 
            }
          })       
          TagContextDispatch({ type: "SET_TAG_PEOPLE_DATA", payload: { taggedPeople: taggedPeopleResults, taggedVideoId: videoId } });
        }
        if (result.data.taggedLocation.length !== 0 && taggedLocation.length === 0) {
          const taggedLocationResults = await result.data.taggedLocation.map(function(item){ 
            return { 
              _id: item.location_information._id,
              id: item.location_information.id,
              city: item.location_information.city, 
              city_ascii: item.location_information.city_ascii, 
              state_id: item.location_information.state_id, 
              state_name: item.location_information.state_name
            }            
          })
          TagContextDispatch({ type: "SET_TAG_LOCATION_DATA", payload: { taggedLocation: taggedLocationResults, taggedVideoId: videoId } });
        }

        if (result.data.userVideos.videos[0].video_src) {
          setIsFacingMode(result.data.userVideos.videos[0].isFacingMode);
          setVideoInformation({
            video_information: result.data.userVideos.videos[0].video_information
          }, setVideo(result.data.userVideos.videos[0].video_src));
        } else {
          if (dataContext !== null && fileNameContext !== null && !isUploadingContext) {
            VideoServiceContextDispatch({ type: "UPDATE_VIDEO_DATA_IS_UPLOADING", payload: { isUploading: true } });  
            
            const objData = {
              data: dataContext,              
              fileName: fileNameContext,
              isPrivate: isPrivateContext,
              isVideo: isVideoContext,
              videoId: videoId
            } 
            
            axios.request({
              method: "post", 
              url: `/api/1.0/upload/video/${userId}`, 
              data: objData, 
              headers: {
                'Authorization': `Bearer ${authToken}`
              }
            }).then(result => {
              console.log('result.data.uploadedResponse', result.data.uploadedResponse)
              setVideo(result.data.uploadedResponse.public_id);
              setVideoInformation({
                video_information: result.data.uploadedResponse
              });
              VideoServiceContextDispatch({ type: "SET_INIT_VIDEO_DATA", payload: VideoServiceInitialState });
            });             
          }
        }
      }
    }).catch(err => {
        console.log("catch GetVideoByUserIdAndVideoIdService",err);
    });

  }, []);

  useEffect(() => {    
    // if (!isSubmitted) {
    //   if (video) {
    //     setIsActive(true);
    //   } else {
    //     setIsActive(false);
    //   }
    // }
    
    if (isSubmitted) {
      const data = {
        video_description: newDescription,
        premium: checkedPrivate || false,
        active: checkedActive || false,
        tempVideo: false,
        taggedPeople: taggedPeople,
        taggedLocation: taggedLocation,
        taggedPeopleUpdate: taggedPeopleUpdate,
        taggedLocationUpdate: taggedLocationUpdate,
        hashTagsUpdate: newDescription !== currentDescription ? true : false,
        hashPeopleTagsUpdate: newDescription !== currentDescription ? true : false
      } 
      UpdateVideoByUserIdAndVideoIdService({data: data, userId : userId, videoId: videoId, authToken: authToken}).then(result => {
        UserContextDispatch({ type: "ADD_USER_VIDEO", payload: result.data });
        TagContextDispatch({ type: "RESET_TAG_DATA" });
        enqueueSnackbar('Update successful', { variant: 'default', autoHideDuration: 3000 });
        history.push('/account/profile');
        ContentContextDispatch({ type: "RESET_CONTENT_DATA" }); 
        GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
        setIsSubmitted(false);
      }).catch(err => {
        FormErrorContextDispatch({ type: "REQUEST_ERROR", payload: err.response.data });  
        ContentContextDispatch({ type: "RESET_CONTENT_DATA" });        
        GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }}); 
        setIsSubmitted(false);
      })
    }
  }, [isSubmitted])

  const handleSubmit = (e) => {    
    console.log('e',e, typeof e)
    if (e) {
      e.preventDefault();
    }   
    setIsSubmitted(true);
    GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : true }}); 
  }

  const handleClickShowTypeOfPost = () => {
    setShowTypeOfPost(!showTypeOfPost);    
  }

  const handleClickShowSocial = () => {
    setShowSocial(!showSocial);    
  }

  const handleMouseDown = (event) => {
    event.preventDefault();
  }  

  const handleClose = (element) => {
    history.push('/account/profile'); 
  }

  const handleChange = (event) => {
    switch(event.target.name) {
      case 'checkedActive':
        if (!checkedActive) {
          ContentContextDispatch({ type: "SET_ACTIVE_CHECK", payload: { checkedActive: true, checkedPublic: true, checkedPrivate: false } })
          // setState({ ...state, checkedActive: true, checkedPublic: true, checkedPrivate: false}); 
        } else {
          ContentContextDispatch({ type: "SET_ACTIVE_CHECK", payload: { checkedActive: false, checkedPublic: false, checkedPrivate: false } })
          // setState({ ...state, checkedActive: false, checkedPublic: false, checkedPrivate: false });  
        }        
        break;
      case 'checkedPublic':
        if (!checkedPublic) {
          ContentContextDispatch({ type: "SET_PUBLIC_CHECK", payload: { checkedActive: true, checkedPublic: true, checkedPrivate: false } })
          // setState({ ...state, checkedActive: true, checkedPublic: true, checkedPrivate: false }); 
        } else {
          ContentContextDispatch({ type: "SET_PUBLIC_CHECK", payload: { checkedActive: true, checkedPublic: false, checkedPrivate: true } })
          // setState({ ...state, checkedActive: true, checkedPublic: false, checkedPrivate: true });  
        }
        break;
      case 'checkedPrivate':
        if (!checkedPrivate) {
          ContentContextDispatch({ type: "SET_PRIVATE_CHECK", payload: { checkedActive: true, checkedPublic: false, checkedPrivate: true } })
          // setState({ ...state, checkedActive: true, checkedPublic: false, checkedPrivate: true }); 
        } else {
          ContentContextDispatch({ type: "SET_PRIVATE_CHECK", payload: { checkedActive: true, checkedPublic: true, checkedPrivate: false } })
          // setState({ ...state, checkedActive: true, checkedPublic: true, checkedPrivate: false  });  
        }
        break;
      case 'checkedFacebook':  
        ContentContextDispatch({ type: "SET_FACEBOOK_CHECK", payload: { checkedFacebook: event.target.checked } })
        break;
      case 'checkedInstagram':  
        ContentContextDispatch({ type: "SET_INSTAGRAM_CHECK", payload: { checkedInstagram: event.target.checked } })
        break;
      case 'checkedTwitter':  
        ContentContextDispatch({ type: "SET_TWITTER_CHECK", payload: { checkedTwitter: event.target.checked } })  
        break;
      default:
        // ContentContextDispatch({ type: "SET_SOCIAL_CHECK", payload: { [event.target.name]: event.target.checked } })
        // setState({ ...state, [event.target.name]: event.target.checked });
    }
    if (newChangeId !== videoId) {
      ContentContextDispatch({ type: "SET_NEW_CHANGE", payload: { newChange: true, newChangeId: videoId } })
    }
  }

  const deleteClickHandler = (e) => {
    e.preventDefault();
    setDeleteModal(true);
  }

  const deleteVideoHandler = () => {
    GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : true }});
    const objData = {
        userId: userId,
        authToken: authToken,
        videoId: videoItem._id,
        fileName: video
    }
    DeleteVideoByUserIdAndVideoIdService(objData).then(() => {  
        history.push('/account/profile'); 
        GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
    }).catch(() => {
        GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
        enqueueSnackbar('Error deleting video', { variant: 'default', autoHideDuration: 3000 });
    });

    setDeleteModal(false);
  }

  const cancelDeleteVideoHandler = () => {
      setDeleteModal(false);
  }

  const handleUntaggedPeopleClick = (e, item) => {   
    // e.preventDefault();
    TagContextDispatch({ type: "REMOVE_TAG_PEOPLE", payload: { taggedPeople : item }});    
  }

  const handleUntaggedLocationClick = (e, item) => {   
    // e.preventDefault();
    TagContextDispatch({ type: "REMOVE_TAG_LOCATION", payload: { taggedLocation : item }});    
  }

  const updateNewDescription = (value) => {
    ContentContextDispatch({ type: "SET_DESCRIPTION_DATA", payload: { newDescription: value } })
    if (newChangeId !== videoId) {
      ContentContextDispatch({ type: "SET_NEW_CHANGE", payload: { newChange: true, newChangeId: videoId } })
    }
  }
    
  return (
    <>
      <HelmetComponent title={"DTF - Edit Video"} />      
      <Container classes={{ root: classes.containerStyleWhite }} className={isPwaMobile ? " pwaMobile" : ""}>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            
          <Grid 
            container
            justify="flex-start"
            direction="column"
            spacing={0}
            classes={{ root: classes.formGridStyle3 }}
          >       
            <Grid 
              item 
              container 
              justify="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={4} classes={{ root: classes.vidoeGrid }}>  
                {
                  video
                  ?
                    <>
                      <ImageVideoItem isFacingMode={isFacingMode}  image={video} imageInfo={videoInformation} />                      
                      <LinkButton url={"/edit/preview/"+videoId} directionBool={true} classes={classes.videoImagePlayIconWrapperElement}>
                        <ImageVideoOverlay imageInfo={videoInformation} />
                        {/* <PlayCircleOutlineRoundedIcon classes={{root: classes.videoImagePlayIconElement}} /> */}
                      </LinkButton>                  
                    </>                      
                  :
                    dataContext !== null && fileNameContext !== null
                    ?
                      <div className={classes.videoUploadWrapperElement}>
                        <LoopRoundedIcon classes={{root: classes.videoImageUploadIconElement}} />
                      </div>   
                    :
                      <div className={classes.videoUploadWrapperElement}>
                        Upload Video
                      </div>       
                }
              </Grid>    
              <Grid item xs={8}>   
                <TextField
                  fullWidth
                  placeholder="Write a caption ..."
                  multiline
                  rows={4}
                  rowsMax={4}
                  value={newDescription} 
                  onChange={(e) => updateNewDescription(e.target.value)}
                  InputProps={{
                    classes: {
                      input: classes.textField,
                      underline: classes.noUnderline 
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}> 
                <Typography classes={{ root: classes.bodyDefaultStyle3 }}>
                  Tag People
                  <IconButton
                    aria-label="Tag people"
                    onClick={() => setPeopleBool(true)}
                    onMouseDown={handleMouseDown}
                    classes={{ root: classes.arrowActiveIcon }}
                  >
                    <ChevronRightIcon classes={{ root: classes.arrowActiveIcon }} />                 
                  </IconButton>
                </Typography>
                <Typography classes={{ root: classes.tagContent }}>
                  {
                    taggedPeople.length !== 0
                    ?
                      taggedPeople.map((item) => {
                        if (typeof item.user_information !== "undefined") {
                          if (item.user_information !== null) {
                            if (item.user_information.user_image_profile !== "" && item.user_information.user_image_profile !== null) {
                              return <Chip classes={{ root: classes.chipWrapper }}
                                avatar={
                                  <Avatar>
                                    <ImageProfileItem image={item.user_information.user_image_profile} />
                                  </Avatar>
                                }
                                label={item.username}
                                onDelete={(e) => handleUntaggedPeopleClick(e, item)}
                              />  
                            } else {
                              return <Chip classes={{ root: classes.chipWrapper }} avatar={<Avatar>{item.handlename.charAt(0).toUpperCase()}</Avatar>} label={item.username} onDelete={(e) => handleUntaggedPeopleClick(e, item)} />
                            }
                          } else {
                            return <Chip classes={{ root: classes.chipWrapper }} avatar={<Avatar>{item.handlename.charAt(0).toUpperCase()}</Avatar>} label={item.username} onDelete={(e) => handleUntaggedPeopleClick(e, item)} />
                          }    
                        } else {
                          return <Chip classes={{ root: classes.chipWrapper }} avatar={<Avatar>{item.handlename.charAt(0).toUpperCase()}</Avatar>} label={item.username} onDelete={(e) => handleUntaggedPeopleClick(e, item)} />
                        }                                                  
                      })
                    :
                      null
                  }
                </Typography>                  
              </Grid>

              <Grid item xs={12}> 
                <Typography classes={{ root: classes.bodyDefaultStyle3 }}>
                  Add Location
                  <IconButton
                    aria-label="Tag location"
                    onClick={() => setLocationBool(true)}
                    onMouseDown={handleMouseDown}
                    classes={{ root: classes.arrowActiveIcon }}
                  >
                    <ChevronRightIcon classes={{ root: classes.arrowActiveIcon }} />                    
                  </IconButton>
                </Typography> 
                <Typography classes={{ root: classes.tagContent }}>
                  {
                      taggedLocation.length !== 0
                      ?
                        taggedLocation.map((item) => {
                          return <Chip classes={{ root: classes.chipWrapper }}
                            label={item.city_ascii + ", " + item.state_name}
                            onDelete={(e) => handleUntaggedLocationClick(e, item)}
                          />  
                        })    
                      :
                        null
                    }
                </Typography>                 
              </Grid>

              <Grid item xs={12}> 
                <Typography classes={{ root: classes.bodyDefaultStyle3 }}>
                  Choose Type of Post
                  <IconButton
                    aria-label="Show type of post"
                    onClick={handleClickShowTypeOfPost}
                    onMouseDown={handleMouseDown}
                    classes={{ root: classes.arrowActiveIcon }}
                  >
                    {
                      showTypeOfPost
                      ?
                        <KeyboardArrowDownIcon classes={{ root: classes.arrowActiveIcon }} />
                      :
                        <ChevronRightIcon classes={{ root: classes.arrowActiveIcon }} />
                    }                    
                  </IconButton>
                </Typography>                  
              </Grid>

              {
                showTypeOfPost
                ?                  
                  <>
                    <Grid item xs={12}> 
                      <Typography classes={{ root: classes.bodyDefaultStyle4 }}>
                        Draft <AntSwitch checked={!checkedActive} onChange={handleChange} name="checkedActive" />
                      </Typography>
                    </Grid>
                    <Grid item xs={12}> 
                      <Typography classes={{ root: classes.bodyDefaultStyle4 }}>
                        Public <AntSwitch checked={checkedPublic} onChange={handleChange} name="checkedPublic" />
                      </Typography>
                    </Grid>
                    <Grid item xs={12}> 
                      <Typography classes={{ root: classes.bodyDefaultStyle4 }}>
                        For your Subcribers only <AntSwitch checked={checkedPrivate} onChange={handleChange} name="checkedPrivate" />
                      </Typography>
                    </Grid>                      
                  </>  
                :
                  null
              } 
            
              <Grid item xs={12}> 
                <Typography classes={{ root: classes.bodyDefaultStyle3 }}>
                  Post to other accounts
                  <IconButton
                    aria-label="Show social accounts"
                    onClick={handleClickShowSocial}
                    onMouseDown={handleMouseDown}
                    classes={{ root: classes.arrowActiveIcon }}
                  >
                    {
                      showSocial
                      ?
                        <KeyboardArrowDownIcon classes={{ root: classes.arrowActiveIcon }} />
                      :
                        <ChevronRightIcon classes={{ root: classes.arrowActiveIcon }} />
                    }                    
                  </IconButton>
                </Typography>                  
              </Grid>

              {
                showSocial
                ?                  
                  <>
                    <Grid item xs={12}> 
                      <Typography classes={{ root: classes.bodyDefaultStyle4 }}>
                        Facebook <AntSwitch checked={checkedFacebook} onChange={handleChange} name="checkedFacebook" />
                      </Typography>
                    </Grid>
                    <Grid item xs={12}> 
                      <Typography classes={{ root: classes.bodyDefaultStyle4 }}>
                        Instagram <AntSwitch checked={checkedInstagram} onChange={handleChange} name="checkedInstagram" />
                      </Typography>
                    </Grid>
                    <Grid item xs={12}> 
                      <Typography classes={{ root: classes.bodyDefaultStyle4 }}>
                        Twitter <AntSwitch checked={checkedTwitter} onChange={handleChange} name="checkedTwitter" />
                      </Typography>
                    </Grid>
                  </>  
                :
                  null
              } 

              {
                requestError.error
                ?
                  <Grid item xs={12} classes={{ root: classes.ErrorTextContainerStyle }}>
                    <Typography variant="body1" classes={{ root: classes.ErrorTextStyle }}>
                      { requestError.message }
                    </Typography>               
                  </Grid>
                :
                  null
              }  

              <Grid item xs={12} classes={{ root: classes.actionBottom }}>
                <div className={ classes.actionBottomWrapper }>
                  <Button classes={{ root: classes.actionButton, label: classes.actionTextButton }} onClick={(e) => deleteClickHandler(e)}>
                    Delete
                  </Button>         
                </div> 
                <div className={ classes.actionBottomWrapper }>
                  <Button classes={{ root: classes.actionButton, label: classes.actionTextButton }} onClick={(e) => handleSubmit(e)}>
                    { isNew ? "Post" : "Update" }
                  </Button>         
                </div>       
              </Grid>              
            </Grid>
          </Grid>
        </form>
        <SwipeTagLocation locationAnchor={locationAnchor} locationBool={locationBool} onCloseHandler={onCloseLocation} />
        <SwipeTagPeople peopleAnchor={peopleAnchor} peopleBool={peopleBool} onCloseHandler={onClosePeople} />
        <DialogModal methodSubmit={deleteVideoHandler} methodCancel={cancelDeleteVideoHandler} openDialog={deleteModal} titleText={"Delete Video"} contentText={"Are you sure?"} cancelText={"cancel"} methodText={"delete"} /> 
      </Container>
    </>
  );
}