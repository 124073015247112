import React, { useContext, useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from "react-router-dom";
import HelmetComponent from "../../components/HelmetComponent";
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { AuthContext } from '../../contexts/AuthContext';
import { videosContainerStyle } from '../../styles/defaultVideoStyles';
import FeedVideoItem from "../../components/FeedVideoItem.2";
import FeedItem from  "../../components/FeedItem.7";
import InfiniteScroll from "../../components/InfiniteScroll";
import { DrawerCommentContext } from '../../contexts/DrawerCommentContext';
import { vh } from '../../constants/VideoSettings';
import { GetActiveVideoByVideoIdService, GetAllLatestVideoService } from '../../services/VideoServices';

const useStyles = makeStyles((theme) => ({
  videosContainerStyle: videosContainerStyle
}));

function Feeds() {
  const classes = useStyles();
  const videosContainerRef = useRef();  
  const feedVideoRef = useRef();
  const { filter="", videoId="" } = useParams();
  const { AuthContextState } = useContext(AuthContext);
  const { isAuthenticated } = AuthContextState;
  const { DrawerCommentContextState } = useContext(DrawerCommentContext);
  const { indexUpdateCommentCount = [] } = DrawerCommentContextState;
  const [ videos, setVideos ] = useState([]);
  const [ pageCount, setPageCount ] = useState(1);
  const [ pageNo, setPageNo ] = useState(0);
  const [ itemPerPage, setItemPerPage ] = useState(6);  
  const [ currentIndex, setCurrentIndex ] = useState(0);  
  const [ userClick, setUserClick ] = useState(false);
  const [ isMuted, setIsMuted ] = useState(true);
  const [ currentSrc, setCurrentSrc ] = useState(null);
  const [ currentPosterSrc, setCurrentPosterSrc ] = useState(null);
  const [ isCover, setIsCover ] = useState(null);
  const [ toggleLike, setToggleLike ] = useState(false);
  const [ hideBackButton, setHideBackButton ] = useState(filter === "" ? true : false);

  const toggleSound = (bool) => {
    console.log('toggleSound', bool);
    if (!userClick) {
      console.log('toggleSound1', bool);
      setIsMuted(bool, setUserClick(true));
    } else {
      console.log('toggleSound2', bool);
      setIsMuted(bool);
    }    
  }

  const loadNext = () => {    
    if (pageCount > pageNo && videos.length !== 0) {
      GetAllLatestVideoService({pageNo: pageNo, itemPerPage: itemPerPage, filter: filter, videoId: videoId}).then(result => {
        if (result.data.success === 1) {
          setPageCount(result.data.videos.pageCount);
          setVideos(videos.concat(result.data.videos.videos));
        }
        setPageNo(pageNo + 1); 
      }).catch(err => {
          console.log("catch GetAllLatestVideoService",err);
      });
    }
  }

  useEffect(() => {
    
    if (videoId !== "") {
      GetActiveVideoByVideoIdService({videoId: videoId}).then(result => {
        setVideos(result.data.userVideos.videos);
      }).catch(err => {
          console.log("catch GetActiveVideoByVideoIdService",err);
      })  
    } else {

      GetAllLatestVideoService({pageNo: pageNo, itemPerPage: itemPerPage, filter: filter, videoId: videoId}).then(result => {
        if (result.data.success === 1) {
          setVideos(result.data.videos.videos);
          setPageCount(result.data.videos.pageCount);
        }
        setPageNo(pageNo + 1); 
      }).catch(err => {
          console.log("catch GetAllLatestVideoService",err);
      })
    }
  }, [])  

  return (
    <>
      <HelmetComponent title={"DTF - Videos"} />
      <Container ref={videosContainerRef} classes={{ root: classes.videosContainerStyle }}>   
        {
          videos.length !== 0
          ?
            videos.map((item, index) => {
              return (
                <FeedItem setToggleLike={setToggleLike} toggleLike={toggleLike} setIsCover={setIsCover} setCurrentPosterSrc={setCurrentPosterSrc} setCurrentSrc={setCurrentSrc} itemRef={feedVideoRef} isMuted={isMuted} userClick={userClick} hideBackButton={hideBackButton} indexUpdateCommentCount={indexUpdateCommentCount.length !== 0 ? indexUpdateCommentCount : []} key={'video-'+index} video={item} index={index} firstItem={index===0 ? true : false} parentRef={videosContainerRef} />
              )
            })
          :
            null            
        }
        <FeedVideoItem itemRef={feedVideoRef} posterSource={currentPosterSrc} source={currentSrc} toggleSound={toggleSound} setToggleLike={setToggleLike} toggleLike={toggleLike} />
        <InfiniteScroll parentRef={videosContainerRef} method={loadNext} margin={vh + "px"} />
      </Container> 
    </>
  );
}

export default Feeds;