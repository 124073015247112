export const DrawerContentContextReducer = (state, action) => {
    switch(action.type){
        case 'SHOW_DRAWER':
            return { 
                ...state,
                contentId: null,
                showContentDrawer: true
            }
        case 'HIDE_DRAWER':
            return {
                ...state,
                contentId: null,
                showContentDrawer: false
            } 
        default:
            return state    
    }
}