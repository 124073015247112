export const DrawerMessageContextReducer = (state, action) => {
    switch(action.type){
        case 'SHOW_DRAWER':
            return { 
                ...state,
                showDrawerMessage: true
            }
        case 'HIDE_DRAWER':
            return {
                ...state,
                currentIndex: null,
                showDrawerMessage: false
            } 
        case 'POST_MESSAGE':
            return {
                ...state,
                message: action.payload.message,
                post: action.payload.post
            } 
        case 'RESET_POST_COMMENT':
            return {
                ...state,
                message: "",
                post: false
            } 
        case 'DELETE_MESSAGE':
            return {
                ...state,
                messageId: action.payload.messageId,
                videoId: action.payload.videoId
            }   
        default:
            return state    
    }
}