import React, { createContext, useReducer, useEffect } from "react";
import { VideoServiceContextReducer } from '../reducers/VideoServiceContextReducer';
import { VideoServiceInitialState } from "../constants/VideoServiceInitialState";

export const VideoServiceContext = createContext();
const initialState = VideoServiceInitialState;

const VideoServiceContextProvider = (props) => {
    const [VideoServiceContextState, VideoServiceContextDispatch] = useReducer(VideoServiceContextReducer,initialState);

    useEffect(() => {
    }, [])

    return (
        <VideoServiceContext.Provider value={{VideoServiceContextState, VideoServiceContextDispatch}}>
            {props.children}
        </VideoServiceContext.Provider>
    );
}

export default VideoServiceContextProvider;