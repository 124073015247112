import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { profileAvatar } from '../styles/defaultAvatarStyles.2'; 

const useStyles = makeStyles((theme) => ({
    profileAvatar: profileAvatar,
    "@keyframes bganimate": {
        "0%": {
            backgroundColor: "rgba(192,192,192,1)"
        },
        "50%": {
            backgroundColor: "rgba(150,148,152,1)"
        },
        "100%": {
            backgroundColor: "rgba(192,192,192,1)"
        }
    }
}));

export default function ImageProfileAvatar(props) {
    const { image="", alt="", letter=false } = props;    
    const classes = useStyles();    

    if (letter === true) {
        return (
            <Avatar classes={{ root: classes.profileAvatar }}>{alt.charAt(0).toUpperCase()}</Avatar>
        )
    } else {
        return (                    
            <Avatar classes={{ root: classes.profileAvatar }}>
                <img src={"/dtf2021/image/upload/c_thumb,g_face,h_100,w_100/v1/" + image} loading="lazy" alt={alt} />
            </Avatar>        
        );
    }    
}
