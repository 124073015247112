import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AlbumIcon from './svg/AlbumIcon';
import { defaultVideoButton } from '../styles/defaultButtonStyles.2';

import { photoLimitSize, photoFileExtension, fileExtension, videoLimitSize, videoFileExtension } from '../constants/FileSizeConstants';

import { AuthContext } from '../contexts/AuthContext';
import { UserContext } from '../contexts/UserContext';

const useStyles = makeStyles((theme) => ({
  defaultVideoButton: defaultVideoButton
}));

export default function AlbumButton(props) {
  const { handleClick = (e) => { }, isActive=false, id="", facingMode='user', isRecording=false } = props;
  const classes = useStyles();
  const [fileInputState, setFileInputState] = useState('');

  const { AuthContextState } = useContext(AuthContext);  
  const { UserContextState } = useContext(UserContext);
  const { user_image_profile } = UserContextState;
  const { userId } = AuthContextState;   

  const handleFileInputChange = (e) => {
    // GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : true }});
    setFileInputState(e.target.value);

    try {
      const file = e.target.files[0];
      if (!fileExtension.test(file.name)) {
        setFileInputState('');
        console.log('File format is not supported');
        alert('File format is not supported');
        // enqueueSnackbar('File format is not supported', { variant: 'default', autoHideDuration: 3000 });
        // GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
        return;
      } 

      if (file.size > photoLimitSize) {
        setFileInputState('');
        console.log('File size limit is 10MB');
        alert('File size limit is 10MB');
        // enqueueSnackbar('File size limit is 10MB', { variant: 'default', autoHideDuration: 3000 });
        // GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
        return;
      } 

      const fullFileName = file.name;
      const fileNameOnly = fullFileName.substr(0, fullFileName.lastIndexOf('.')) || fullFileName;
      const tempImage = window.DTF_APP.IMAGE_PATH+"public/image/users/"+userId+"/"+fileNameOnly;      

      if (user_image_profile === tempImage) {
        setFileInputState('');
        console.log('File already exists');
        alert('File already exists');
        // enqueueSnackbar('File already exists', { variant: 'default', autoHideDuration: 3000 });
        // GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
        return;
      } 

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = async () => {        
        // const objData = {
        //   data: reader.result,
        //   fileName: fileNameOnly
        // } 
        
        const base64Response = await fetch(reader.result);
        const blobData = await base64Response.blob();

        const objData = {
          data: blobData,
          fileName: `picture-${Date.now()}`,
          isPrivate: false,
          isVideo: false
        } 

        console.log('objData 1', objData)
        handleClick(objData);
        // deleteImageFromServer();
        
        // UploadImageService(objData,userId,authToken).then(result => {
        //   if (!registerFlow) {
        //     setDescription(user_description);
        //     setHouseOf({title: user_house_name});
        //     setIsEdit(true);
        //   }
        //   setImageProfile(result.data.uploadedResponse.public_id);
        //   setImageProfileInformation(result.data.uploadedResponse);
        //   setFileInputState('');
        //   GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
        // }).catch(err => {
        //   setFileInputState('');
        //   FormErrorContextDispatch({ type: "REQUEST_ERROR", payload: err.response.data });          
        //   GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }}); 
        // })
      }     
    } catch (error) {
      console.log('catch',error);
      setFileInputState('');
      // GlobalContextDispatch({ type: "SHOW_CIRCLE_SPINNER", payload: { showCircleSpinner : false }});
    }
  };

  if (props.isActive) {
    return (    
      <button type="button" className={classes.defaultVideoButton}>
        <input
          id="icon-button-content-upload"
          type="file"
          name="image"
          accept="image/*, video/*"
          onChange={handleFileInputChange}
          value={fileInputState}
          className="form-input"
          hidden
        />
        {/* <button type="button" className={classes.defaultVideoButton} onClick={(e) => handleClick(e)}>
          <AlbumIcon />
        </button> */}
        <label htmlFor="icon-button-content-upload">
          <AlbumIcon />
        </label> 
      </button>
    )
  } else {
    return (    
      // <button type="button" className={classes.defaultVideoButton} disabled>
      //   <AlbumIcon />
      // </button>
      <button type="button" className={classes.defaultVideoButton}>
        <label htmlFor="icon-button-content-upload">
          <AlbumIcon />
        </label> 
      </button>
    )
  }
}