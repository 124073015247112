import React, { useContext, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import { defaultPinkBtn } from '../styles/defaultButtonStyles';
import { DrawerShareContext } from '../contexts/DrawerShareContext';
import { AuthContext } from '../contexts/AuthContext';
import ComingSoon from './ComingSoon'

const useStyles = makeStyles((theme) => ({  
  defaultPinkBtn: defaultPinkBtn, 
  image: {
    top: "50%",
    left: "50%",
    minHeight: "100%",
    minWidth: "100%",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    zIndex: "-1"
  },
  paperAnchorBottom: {
    padding: "0px",
    borderTopRightRadius: "20px",
    borderTopLeftRadius: "20px"
  },
  headerLabel: {
    fontSize: '16px',
    fontWeight: '400',
    color: 'rgba(20,24,34,1)',
    display: 'block',
    margin: '0 auto 10px',
    backgroundColor: 'rgba(227,227,227,1)',
    padding: '10px 20px',
    borderRadius: '20px'
  },
  headerLabelPink: {
    fontSize: '20px',
    fontWeight: '600',
    color: 'rgba(253,45,85,1)'
  },
  privacyText: {
    display:'block',
    margin: '20px auto',
    fontSize: '12px',
    color: 'rgba(139,140,144,1)'
  },
  text: {
    fontSize: '14px',
    fontWeight: '500',
    color: 'rgba(20,24,34,1)'
  },
  labelBtn: {
    fontWeight: '300',
    textTransform: 'none'
  },
  closeIcon: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    fontSize: '18px',
    color: 'rgba(0,0,0,1)'
  },
  commentWrapper: {
    position: 'relative',
    width: '100%',
    minHeight: 'calc(100vh / 7)',
    maxHeight: 'calc(100vh / 1.50)',
    height: 'auto',
    display: 'block',
    overflowX: 'hidden',
    overflowY: 'auto'
  },
  circleSpinnerInlineWrapper: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    overflow: 'hidden',
    margin: '10% 0'
  },
  shareWrapper: {
    width: '100vw',
    height: '200px'
  }
}));

function DrawerShare(props) {
  const classes = useStyles();  
  const itemRef = useRef();
  const { AuthContextState } = useContext(AuthContext);
  const { isAuthenticated } = AuthContextState;
  const { DrawerShareContextState, DrawerShareContextDispatch } = useContext(DrawerShareContext);
  const { 
    initialShareLoad=true,
    showDrawerShare=false,    
    share="",
    shareId="",
    videoId="",
    deleteShare=false,
    post=false,
    get=false,
    getInfinite=false,
    shares=[],
    pageNo=0,
    itemPerPage=10,
    shareCount=0,
    pageCount=0,
    currentIndex=null,
    indexUpdateShareCount=[] 
  } = DrawerShareContextState;

  const handleClick = (e) => {    
    DrawerShareContextDispatch({ type: "HIDE_DRAWER", payload: false });
  }
  
  return (    
    <Drawer classes={{ paperAnchorBottom: classes.paperAnchorBottom }} transitionDuration={{ enter: 100, exit: 100 }} anchor={'bottom'} open={showDrawerShare}>
        <CloseIcon classes={{ root: classes.closeIcon }}  onClick={(e) => handleClick(e)}/>
        {/* <Typography variant={"body2"} component={"span"} align="center" classes={{ root: classes.headerLabel }}>
            Share
        </Typography> */}
        <div className={ classes.shareWrapper }>
          <ComingSoon />
        </div>
        {/* {
            comments.length !== 0 && initialCommnentLoad === false
            ? 
              <Typography variant={"body2"} component={"span"} align="center" classes={{ root: classes.headerLabel }}>
                {commentsCount} {commentsCount === 1 ? "Comment" : "Comments"}
              </Typography>
            :
              comments.length === 0 && initialCommnentLoad === false
              ?
                <Typography variant={"body2"} component={"span"} align="center" classes={{ root: classes.headerLabel }}>
                  NO COMMENTS HEADER
                </Typography> 
              : 
                null
        }    */}
        {/* <div className={classes.commentWrapper} ref={itemRef}>
          <OverlayGradient isTop={true} />

          {
            comments.length !== 0 && initialCommnentLoad === false
            ?
              comments.map((comment, index) => {
                  return <CommentItem data={comment} index={index} />
              })
            :
              comments.length === 0 && initialCommnentLoad === false
              ?
                <div className={classes.circleSpinnerInlineWrapper}>
                  NO COMMENTS - NEED STYLES or COPY
                </div>
              :  
                null
          }
          {
            initialCommnentLoad
            ?
              <div className={classes.circleSpinnerInlineWrapper}>
                <CircleSpinnerInline />
              </div>
            :
              null
          }
          {
            !initialCommnentLoad && comments.length !== 0
            ?
              <InfiniteScroll parentRef={itemRef} method={handleLoadNext} />
            :
              null
          }          
          <OverlayGradient isTop={false} />
        </div>  */}
    </Drawer>
  );
}

export default DrawerShare;