export const UserInitialState = {
    userId: "",
    username: "",
    handleName: "",
    userType: null,
    devices: [],
    date_created: "",
    date_updated: "",
    user_information_id: "",
    user_description: "",
    user_fname: "",
    user_followers: 0,
    user_following: 0,
    user_house_link: null,
    user_house_name: "" ,
    user_image_profile: null,
    user_link_profile: null,
    user_lname: "",
    user_mname: "",
    user_moods: [],
    user_socials: [],
    user_subscribers: 0,
    user_subscriptions: 0,
    user_suffix: "",
    user_videos: {
        draftCount: 0,
        premiumActiveCount: 0,
        premiumInactiveCount: 0,
        publicActiveCount: 0,
        publicInactiveCount: 0,
        page: 0,
        pageCount: 0,
        count: 0,
        videos: []
    },
    user_events: {
        count: 0,
        events: []
    },
}