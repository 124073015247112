export const TagContextReducer = (state, action) => {
    switch(action.type){
        case 'SET_INIT_TAG_DATA': 
            return {
                ...state,
                taggedVideoId: action.payload.taggedVideoId,
                taggedPeople: action.payload.taggedPeople,
                taggedLocation: action.payload.taggedLocation,
                taggedPeopleUpdate: action.payload.taggedPeopleUpdate,
                taggedLocationUpdate: action.payload.taggedLocationUpdate
            }
        case 'SET_TAG_DATA':
            return { 
                ...state,
                taggedPeople: action.payload.taggedPeople,
                taggedLocation: action.payload.taggedLocation
            }
        case 'SET_TAG_PEOPLE_DATA':
            return { 
                ...state,
                taggedPeople: action.payload.taggedPeople,
                taggedVideoId: action.payload.taggedVideoId
            }
        case 'SET_TAG_LOCATION_DATA':
            return { 
                ...state,
                taggedLocation: action.payload.taggedLocation,
                taggedVideoId: action.payload.taggedVideoId
            }
        case 'RESET_TAG_DATA':
            return { 
                ...state,
                taggedVideoId: "",
                taggedPeople: [],
                taggedLocation: [],
                taggedPeopleUpdate: false,
                taggedLocationUpdate: false
            }
        case 'RESET_UPDATE_PEOPLE_TAG':
            return { 
                ...state,
                taggedPeopleUpdate: false,
            }
        case 'RESET_UPDATE_LOCATION_TAG':
            return { 
                ...state,
                taggedLocationUpdate: false,
            }           
        case 'ADD_TAG_PEOPLE':
            return { 
                ...state,
                taggedPeople: [...state.taggedPeople, action.payload.taggedPeople],
                taggedPeopleUpdate: true
            }
        case 'REMOVE_TAG_PEOPLE':
            return { 
                ...state,
                taggedPeople: state.taggedPeople.filter(item => item._id !== action.payload.taggedPeople._id),
                taggedPeopleUpdate: true
            }   
        case 'ADD_TAG_LOCATION':
            return { 
                ...state,
                taggedLocation: [...state.taggedLocation, action.payload.taggedLocation],
                taggedLocationUpdate: true
            }
        case 'REMOVE_TAG_LOCATION':
            return { 
                ...state,
                taggedLocation: state.taggedLocation.filter(item => item._id !== action.payload.taggedLocation._id),
                taggedLocationUpdate: true
            }        
        default:
            return state    
    }
}