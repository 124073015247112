export const feedSideBar = {
  padding: "0",
  margin: "0",
  color: "rgba(0,0,0,1)", 
  position: "absolute", 
  bottom: "10px", 
  right: "0px", 
  height: "auto", 
  textAlign: "center",
  width: "60px",
  zIndex: "10", 
  backgroundColor: "transparent",
  transition: "all 100ms linear",
  "-webkit-transition": "all 100ms linear",
  "-moz-transition": "all 100ms linear",
  "-o-transition": "all 100ms linear",
  "&.mobile.not-pwa": {
    bottom: "170px"
  },
  "&.pwa": {
    bottom: "60px"
  },
  "&.hideSideBar": {
    right: "-75px"
  }
}