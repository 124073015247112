import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  seconds: {
    color: "rgba(255,255,255,1)"
  },
  barWrapper: {
    position: 'relative',
    marginTop: '-17px',
    overflow: 'visible'
  },  
  barLines: {
    position: 'relative',
    overflow: 'visible'
  },
  barLine1: {
    position: 'relative',
    top: '6px',
    left: '-3px',
    display: 'inline-block',
    width: '3px',
    height: '10px',
    backgroundColor: 'rgba(255,255,255,1)'
  },  
  barText1: {
    position: 'relative',  
    left: '-8px',
    display: 'inline-block',    
    color: 'rgba(255,255,255,1)'
  },
  barLine3: {
    position: 'relative',  
    top: '6px',
    left: '99%',
    display: 'inline-block',
    width: '3px',
    height: '10px',
    backgroundColor: 'rgba(255,255,255,1)'
  },
  barText3: {
    position: 'relative',
    left: '91%',
    display: 'inline-block',
    color: 'rgba(255,255,255,1)'
  },
});

export default function RecordVideoProgressBar(props) {
  const { handleAutoStop=()=>{}, handleSetVideoLength=()=>{}, limitSeconds=180, isRecording=false } = props;
  const classes = useStyles();
  const [startProgress, setStartProgress] = useState(false);
  const [progress, setProgress] = useState(0);
  const [maxSeconds, setMaxSeconds] = useState(limitSeconds);

  useEffect(() => {
    // console.log('startProgress',startProgress);
    // console.log('isRecording',isRecording)
    if(!startProgress && isRecording) {
      setStartProgress(true);
    }    
  }, [isRecording])

  useEffect(() => {
    if (startProgress) {
      const timer = setInterval(() => {
        // console.log('RecordVideoProgressBar1 timer', isRecording);  
        setProgress((oldProgress) => {
          if (isRecording) {
            handleSetVideoLength(oldProgress);
          }          
          if (oldProgress >= maxSeconds || !isRecording) {
            clearInterval(timer);
            handleAutoStop();
            return maxSeconds;
          }
          // console.log('oldProgress1',oldProgress, isRecording);
          return (maxSeconds > oldProgress) ? oldProgress + 1 : maxSeconds;
        });
      }, 1000);
  
      return () => {
        clearInterval(timer);
      };
    }
  }, [startProgress, isRecording]);

  return (
    <div className={classes.root}>
      <LinearProgress variant="determinate" value={progress / maxSeconds * 100} />
      <div className={classes.barWrapper}>
        <div className={classes.barLines}>
          <div className={classes.barLine1}></div>
          <div className={classes.barLine3}></div>
        </div>
        <div className={classes.barText}>
          <div className={classes.barText1}>0s</div>
          <div className={classes.barText3}>180s</div>
        </div>
      </div>
    </div>
  );
}
