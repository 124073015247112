import React, { createContext, useReducer, useEffect, useContext } from "react";
import { SwipeSearchContextReducer } from '../reducers/SwipeSearchContextReducer';
import { SwipeSearchInitialState } from "../constants/SwipeSearchInitialState";
import { GetSwipeSearch } from "../services/UserActionsServices";
import { AuthContext } from './AuthContext';

export const SwipeSearchContext = createContext();
const initialState = SwipeSearchInitialState;

const SwipeSearchContextProvider = (props) => {
    
    const { AuthContextState } = useContext(AuthContext);
    const { isAuthenticated, authToken, userId } = AuthContextState; 
    const [ SwipeSearchContextState, SwipeSearchContextDispatch ] = useReducer(SwipeSearchContextReducer,initialState);
    const { profileId='', peopleType='', peopleResult=[], peopleResultExtract=[], people="", pageNo=0, pageCount=0, itemPerPage=10, initLoad=false, loadNext=false, isLoading=false, loadNextType="" } = SwipeSearchContextState;


    // useEffect(() => {
    //     console.log('init people', people)
    //     const objData = { profileId: profileId, peopleType: peopleType, searchStr: people, pageNo: 0, itemPerPage: itemPerPage, usernameOnly: true }
    //     GetFollowing(objData).then((data) => {
    //         SwipeSearchContextDispatch({ type: "SET_SEARCH_PEOPLE_RESULT", payload: { peopleResult: data.data.search.people, pageNo: objData.pageNo, searchPage: data.data.search.page, searchCount: data.data.search.count, searchPageCount: data.data.search.pageCount, itemPerPage: data.data.search.itemPerPage } });
    //     })
    // }, [])  

    useEffect(() => {
        if (profileId !== "" && initLoad && loadNext === false) {
            const objData = { profile_user_id: profileId, peopleType: peopleType, searchStr: people, pageNo: pageNo, itemPerPage: itemPerPage, usernameOnly: true }
            SwipeSearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading: true } });
            GetSwipeSearch(objData).then((data) => {
                // Extract null from objData for presentation and count - swipe search only
                // subscriptions - subscribe_user
                // following - follow_user
                // followers - user
                // subscribers - user
                console.log('peopleType',peopleType)
                let peopleExtractFromResult = [];

                switch(peopleType) {
                    case "subscriptions":
                        peopleExtractFromResult = data.data.people.filter(function(obj) {
                            return obj.subscribe_user !== null;
                        });
                        break;
                    case "following":
                        peopleExtractFromResult = data.data.people.filter(function(obj) {
                            return obj.follow_user !== null;
                        });
                        break;      
                    case "followers":
                    case "subscribers":
                        peopleExtractFromResult = data.data.people.filter(function(obj) {
                            return obj.user !== null;
                        });                     
                        break; 
                    default:
                        //nothing   
                }

                SwipeSearchContextDispatch({ type: "SET_SEARCH_PEOPLE_RESULT", payload: { peopleResult: data.data.people, peopleResultExtract: peopleExtractFromResult, pageNo: data.data.pageNo, pageCount: data.data.pageCount, itemPerPage: objData.itemPerPage } });
                SwipeSearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading: false } });
            })
        }
    }, [people, initLoad])    


    useEffect(() => {
        if (profileId !== "" && loadNext) {
            console.log('swipe context loadNext is loading');
            SwipeSearchContextDispatch({ type: "SET_IS_LOADNEXT", payload: { loadNext: false } }); 
            SwipeSearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading: true } });
            const objData = { profile_user_id: profileId, peopleType: peopleType, searchStr: people, pageNo: pageNo, itemPerPage: itemPerPage, usernameOnly: true }
            GetSwipeSearch(objData).then((data) => {

                // Extract null from objData for presentation and count - swipe search only
                // subscriptions - subscribe_user
                // following - follow_user
                // followers - user
                // subscribers - user
                console.log('peopleType',peopleType)
                let peopleExtractFromResult = [];

                switch(peopleType) {
                    case "subscriptions":
                        peopleExtractFromResult = data.data.people.filter(function(obj) {
                            return obj.subscribe_user !== null;
                        });
                        break;
                    case "following":
                        peopleExtractFromResult = data.data.people.filter(function(obj) {
                            return obj.follow_user !== null;
                        });    
                        break;   
                    case "followers":
                    case "subscribers":
                        peopleExtractFromResult = data.data.people.filter(function(obj) {
                            return obj.user !== null;
                        });                     
                        break; 
                    default:
                        //nothing   
                }

                SwipeSearchContextDispatch({ type: "SET_SEARCH_PEOPLE_LOADNEXT_RESULT", payload: { peopleResult: data.data.people, peopleResultExtract: peopleExtractFromResult, pageNo: data.data.pageNo, pageCount: data.data.pageCount, itemPerPage: objData.itemPerPage } });
                SwipeSearchContextDispatch({ type: "SET_IS_LOADING", payload: { isLoading: false } });
            })
        }
    }, [loadNext])  

    return (
        <SwipeSearchContext.Provider value={{SwipeSearchContextState, SwipeSearchContextDispatch}}>
            {props.children}
        </SwipeSearchContext.Provider>
    );
}

export default SwipeSearchContextProvider;