import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  defaultLink: {
    textDecoration: "none",
    display: "block",
    height: "100%",
    width: "100%",
    backgroundColor: "rgba(192,192,192,1)"
  }
}));

export default function ImageLinkWrapper(props) {
  const classes = useStyles();
  const { url="/", children="", directionBool=true, callbackHandler=null, callbackValue="" } = props;

  const clickHanlder = (e) => {    
    if (callbackHandler) {
      callbackHandler(e, callbackValue);
    }
  }
  
  return (
    <Link to={url} onClick={(e) => clickHanlder(e)} style={{ textDecoration: "none" }} className={classes.defaultLink}>
      { children }
    </Link>
  );
}
