import axios from 'axios';
import { VersionInitialState } from "../constants/VersionInitialState";
const versionApiNumber = VersionInitialState.versionApiNumber;

export async function UpdateUserPayoutInformationService(objData,userId,authToken) {
    const data = await axios.post(
        `/api/${versionApiNumber}/users/userPayoutInformation/${userId}`, 
        objData,
        {
            headers: {
                'Authorization': `Bearer ${authToken}` 
            }
        }
    );
    return data;
}