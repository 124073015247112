import React, { useContext, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import { defaultPinkBtn } from '../styles/defaultButtonStyles';
import { DrawerCommentContext } from '../contexts/DrawerCommentContext';
import { AuthContext } from '../contexts/AuthContext';
import CommentInput from './CommentInput.2'
import CommentItem from './CommentItem.3';
import CommentOverlayGradient from './CommentOverlayGradient';
import CircleSpinner from './CircleSpinner.5';
import InfiniteScroll from './InfiniteScroll';

const useStyles = makeStyles((theme) => ({  
  defaultPinkBtn: defaultPinkBtn, 
  image: {
    top: "50%",
    left: "50%",
    minHeight: "100%",
    minWidth: "100%",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    zIndex: "-1"
  },
  paperAnchorBottom: {
    padding: "20px 0 0 0",
    borderTopRightRadius: "20px",
    borderTopLeftRadius: "20px"
  },
  headerLabel: {
    fontSize: '12px',
    fontWeight: '400',
    color: 'rgba(20,24,34,1)',
    display: 'block',
    margin: '0 auto 10px',
    backgroundColor: 'rgba(227,227,227,1)',
    padding: '10px 20px',
    borderRadius: '20px',
    borderBottom: "1px solid rgba(227,227,227,1)"
  },
  headerNullLabel: {
    fontSize: '14px',
    fontWeight: '400',
    color: 'rgba(20,24,34,1)',
    display: 'block',
    margin: '0 auto 10px',
    backgroundColor: 'transparent',
    padding: '10px 20px',
    borderRadius: '20px',
    borderBottom: "0px solid rgba(227,227,227,1)",
    boxShadow: "none"
  },
  headerLabelPink: {
    fontSize: '20px',
    fontWeight: '600',
    color: 'rgba(253,45,85,1)'
  },
  privacyText: {
    display:'block',
    margin: '20px auto',
    fontSize: '12px',
    color: 'rgba(139,140,144,1)'
  },
  text: {
    fontSize: '14px',
    fontWeight: '500',
    color: 'rgba(20,24,34,1)'
  },
  labelBtn: {
    fontWeight: '300',
    textTransform: 'none'
  },
  closeIcon: {
    position: 'absolute',
    top: '30px',
    right: '20px',
    fontSize: '18px',
    color: 'rgba(0,0,0,1)'
  },
  commentWrapper: {
    position: 'relative',
    width: '100%',
    maxHeight: 'calc(100vh / 1.50)',
    height: 'auto',
    display: 'block',
    overflowX: 'hidden',
    overflowY: 'auto',
    borderTop: "1px solid rgba(227,227,227,1)"
  },
  circleSpinnerInlineWrapper: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    overflow: 'hidden',
    margin: '10% 0'
  }
}));

function DrawerComment(props) {
  const classes = useStyles();  
  const itemRef = useRef();
  const { AuthContextState } = useContext(AuthContext);
  const { isAuthenticated } = AuthContextState;
  const { DrawerCommentContextState, DrawerCommentContextDispatch } = useContext(DrawerCommentContext);
  const { initialCommnentLoad = true, showDrawerComment = false, videoId = "", currentIndex=null, comments=[], commentsCount=0, pageCount=0, pageNo=0 } = DrawerCommentContextState;
  
  // useEffect(() => {
  //   DrawerCommentContextDispatch({ type: "GET_COMMENT", payload: false, pageNo: 0, itemPerPage: 10 });
  // }, []) 

  useEffect(() => {
    if (showDrawerComment && videoId !== "") {
      DrawerCommentContextDispatch({ type: "GET_COMMENT", payload: { videoId: videoId, pageNo: 0, itemPerPage: 10 }});
    }    
  }, [showDrawerComment]) 

  // useEffect(() => {
  //   if (comments.length !== 0) {
  //     console.log("comments",comments)
  //   }    
  // }, [comments])

  const handleClick = (e) => {    
    // DrawerCommentContextDispatch({ type: "HIDE_DRAWER", payload: false });
    // reset all data when close
    DrawerCommentContextDispatch({ type: "RESET_COMMENT", payload: false });
  }

  const handleLoadNext = () => {
    // console.log('handleLoadNext', initialCommnentLoad, comments.length);
    if (!initialCommnentLoad && comments.length !== 0 && pageCount > pageNo)  {
      DrawerCommentContextDispatch({ type: "INFINITE_GET_COMMENT", payload: { videoId: videoId, pageNo: parseInt(pageNo) + 1, itemPerPage: 10 }});
    }    
  }

  return (    
    <Drawer variant={"persistent"} classes={{ paperAnchorBottom: classes.paperAnchorBottom }} transitionDuration={{ enter: 100, exit: 100 }} anchor={'bottom'} open={showDrawerComment}>
      {
        initialCommnentLoad
        ?
          <CircleSpinner />
        :
          null
      }     
      <CloseIcon classes={{ root: classes.closeIcon }}  onClick={(e) => handleClick(e)}/>
      {
          comments.length !== 0 && initialCommnentLoad === false
          ? 
            <Typography variant={"body2"} component={"span"} align="center" classes={{ root: classes.headerLabel }}>
              {commentsCount} {commentsCount === 1 ? "Comment" : "Comments"}
            </Typography>
          :
            comments.length === 0 && initialCommnentLoad === false
            ?
              <Typography variant={"body2"} component={"span"} align="center" classes={{ root: classes.headerNullLabel }}>
                Be the First to Comment
              </Typography> 
            : 
              null
      }   
      <div className={classes.commentWrapper} ref={itemRef}>
        <CommentOverlayGradient isTop={true} />
        {
          comments.length !== 0 && initialCommnentLoad === false
          ?
            comments.map((comment, index) => {
                return <CommentItem data={comment} index={index} />
            })
          :              
            null
        }
        {
          !initialCommnentLoad && comments.length !== 0
          ?
            <InfiniteScroll parentRef={itemRef} method={handleLoadNext} />
          :
            null
        }          
        <CommentOverlayGradient isTop={false} />
      </div> 
      <CommentInput />
    </Drawer>
  );
}

export default DrawerComment;