import React, { useContext, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import { defaultPinkBtn } from '../styles/defaultButtonStyles';
import { DrawerContentContext } from '../contexts/DrawerContentContext';
import { AuthContext } from '../contexts/AuthContext';
import FormErrorContextProvider from "../contexts/FormErrorContext";

import CreateContent from "./CreateContent";

const useStyles = makeStyles((theme) => ({  
  defaultPinkBtn: defaultPinkBtn, 
  image: {
    top: "50%",
    left: "50%",
    minHeight: "100%",
    minWidth: "100%",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    zIndex: "-1"
  },
  paperAnchorBottom: {
    padding: "0px",
  },
  headerLabel: {
    fontSize: '16px',
    fontWeight: '400',
    color: 'rgba(20,24,34,1)',
    display: 'block',
    margin: '0 auto 10px',
    backgroundColor: 'rgba(227,227,227,1)',
    padding: '10px 20px',
    borderRadius: '20px'
  },
  headerLabelPink: {
    fontSize: '20px',
    fontWeight: '600',
    color: 'rgba(253,45,85,1)'
  },
  privacyText: {
    display:'block',
    margin: '20px auto',
    fontSize: '12px',
    color: 'rgba(139,140,144,1)'
  },
  text: {
    fontSize: '14px',
    fontWeight: '500',
    color: 'rgba(20,24,34,1)'
  },
  labelBtn: {
    fontWeight: '300',
    textTransform: 'none'
  },
  closeIcon: {
    position: 'absolute',
    top: '60px',
    right: '20px',
    fontSize: '18px',
    color: 'rgba(0,0,0,1)'
  },
  commentWrapper: {
    position: 'relative',
    width: '100%',
    minHeight: 'calc(100vh / 7)',
    maxHeight: 'calc(100vh / 1.50)',
    height: 'auto',
    display: 'block',
    overflowX: 'hidden',
    overflowY: 'auto'
  },
  circleSpinnerInlineWrapper: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    overflow: 'hidden',
    margin: '10% 0'
  },
  shareWrapper: {
    width: '100vw',
    height: '100vh'
  }
}));

function DrawerContent(props) {
  const classes = useStyles();  
  const itemRef = useRef();
  const { AuthContextState } = useContext(AuthContext);
  const { isAuthenticated } = AuthContextState;
  const { DrawerContentContextState, DrawerContentContextDispatch } = useContext(DrawerContentContext);
  const { 
    contentId=null,
    showContentDrawer=false
  } = DrawerContentContextState;
  
  return (    
    <Drawer classes={{ paperAnchorBottom: classes.paperAnchorBottom }} variant={'temporary'} transitionDuration={{ enter: 100, exit: 100 }} anchor={'bottom'} open={showContentDrawer}>
        <FormErrorContextProvider>
          <CreateContent />
        </FormErrorContextProvider>
    </Drawer>
  );
}

export default DrawerContent;